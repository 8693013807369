import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ListInvoiceModule from "../../../modules/Admin/Finance/ListInvoiceModule";
import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";

import { getFinanceOrderAction } from "../../../../redux/action/financeAction";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

const ListInvoince = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { financeOrderParams } = useSelector((state) => state.finance);
  const { users } = useSelector((state) => state.auth);
  const isFinance = users?.user?.type === "finance";

  useEffect(() => {
    history.push(`/admin/${isFinance ? "finance" : "pm"}/list-invoice`);
    dispatch(
      getFinanceOrderAction({
        financeOrderParams,
      })
    );
    titlePage({
      title: "IRIS - Manajemen Invoice",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [financeOrderParams]);

  return isFinance ? (
    <DashboardFinanceLayout title="Manajemen Invoice" menu={"invoice"}>
      <ListInvoiceModule isFinance={isFinance} />
    </DashboardFinanceLayout>
  ) : (
    <DashboardLayout title="Manajemen Invoice" menu={"invoice"}>
      <ListInvoiceModule isFinance={isFinance} />
    </DashboardLayout>
  );
};

export default ListInvoince;
