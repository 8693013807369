import React from "react";
import ContentLoader from "react-content-loader";

const ContentsLoader = (props) => (
  <ContentLoader
    width={"80%"}
    height={300}
    viewBox="0 0 100% 300"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x="0" y="9" rx="2" ry="2" width={"99%"} height="11" />
    <rect x="0" y="30" rx="2" ry="2" width={"99%"} height="11" />
    <rect x={"0%"} y="70" rx="2" ry="2" width={"24%"} height="250" />
    <rect x={"25%"} y="70" rx="2" ry="2" width={"24%"} height="250" />
    <rect x={"50%"} y="70" rx="2" ry="2" width={"24%"} height="250" />
    <rect x={"75%"} y="70" rx="2" ry="2" width={"24%"} height="250" />
  </ContentLoader>
);

export default ContentsLoader;
