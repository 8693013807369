/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/id";
import NumberFormat from "react-number-format";
import { useHistory, Link } from "react-router-dom";

import iconDownload from "../../../assets/img/download-blue.svg";
import ButtonDefault from "../../../components/button/ButtonDefault";
import FullContent from "../../../components/loading/fullContentLoader";

import { getToken } from "../../../../redux/service/firebase/auth";

function InvoiceModule(props) {
  const history = useHistory();
  const { dataGetSettingSite } = useSelector((state) => state.landingpage);
  const { dataDetailOrderClient, loadingDetailOrderClient } = useSelector(
    (state) => state.order
  );
  let statusInvoice;
  if (dataDetailOrderClient?.status === "payment_waiting") {
    statusInvoice = { name: "Belum Dibayar", className: "text-danger" };
  } else if (dataDetailOrderClient?.status === "confirmation_waiting") {
    statusInvoice = { name: "Menunggu Konfirmasi", className: "text-warning" };
  } else if (dataDetailOrderClient?.status === "confirmed") {
    statusInvoice = { name: "Terkonfirmasi", className: "text-success" };
  } else {
    statusInvoice = {
      name: "Ditolak",
      className: "text-danger",
    };
  }

  const downloadFile = async () => {
    const token = await getToken();
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1/client/orders/${props.orderId}/invoice?token=${token}`,
      "_blank"
    );
  };

  return (
    <div>
      <div className="card card-custom mb-4 payment-confirmation">
        <div className="card-header border-0 py-5">
          <h3 className="card-title p-d-flex p-ai-center">
            <Link to="/client/my-order/process" className="button-back">
              <span className="icon-back"></span>
            </Link>
            <span className="card-label font-weight-bolder text-dark ml-3">
              {`Invoice Nomor ${dataDetailOrderClient?.invoiceNumber}`}
            </span>
          </h3>
          <a
            className="btn-download"
            onClick={() => downloadFile()}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={iconDownload} alt="logo" />
            Unduh Invoice
          </a>
        </div>
        <div className="card-body">
          {loadingDetailOrderClient ? (
            <>
              <FullContent />
              <FullContent />
              <FullContent />
            </>
          ) : (
            <>
              <div className="invoice-header">
                <div className="iris-logo">
                  <img src={dataGetSettingSite?.logo} alt="IRIS" />
                  <h1>Invoice</h1>
                </div>
                <div className="payment-status">
                  <span>Status Pembayaran</span>
                  <h4 className={statusInvoice.className}>
                    {statusInvoice?.name}
                  </h4>
                </div>
              </div>
              <div className="address-section">
                <div className="invoice-address">
                  <h3>{dataDetailOrderClient?.clientName}</h3>
                  <h4>{dataDetailOrderClient?.companyName}</h4>
                  <h4>{dataDetailOrderClient?.companyAddress}</h4>
                  <h4>{`${dataDetailOrderClient?.clientEmail ||
                    "-"} / ${dataDetailOrderClient?.phone?.replace(
                    "+62",
                    "0"
                  )}`}</h4>
                </div>
                <div className="invoice-number">
                  <h3>Nomor Tagihan</h3>
                  <h4>{dataDetailOrderClient?.invoiceNumber}</h4>
                </div>
              </div>
              <div className="invoice-table">
                <h4>Detail Order</h4>
                <div className="p-d-flex p-jc-between table-header">
                  <h5>DESKRIPSI PESANAN</h5>
                  <h5>HARGA TOTAL</h5>
                </div>
                <div className="invoice-content">
                  <div className="item">
                    <p>
                      <strong>{`${dataDetailOrderClient?.productName} (x ${dataDetailOrderClient?.quantity})`}</strong>
                    </p>
                    <p>{dataDetailOrderClient?.productDetail}</p>
                  </div>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={
                      dataDetailOrderClient?.productPrice *
                      dataDetailOrderClient?.quantity
                    }
                    renderText={(value) => <strong>{value}</strong>}
                  />
                </div>
                <div className="invoice-content">
                  <span>PPN 11%</span>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={dataDetailOrderClient?.ppn}
                    renderText={(value) => <strong>{value}</strong>}
                  />
                </div>
                <div className="table-footer p-d-flex p-jc-between">
                  <span>TOTAL TAGIHAN</span>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={dataDetailOrderClient?.totalPrice}
                    renderText={(value) => <strong>{value}</strong>}
                  />
                </div>
                <div className="invoice-content last p-d-flex p-jc-between">
                  <span>Tenggat Waktu</span>
                  <strong>
                    {`${dayjs(dataDetailOrderClient?.paymentDeadline?.toDate())
                      .locale("id")
                      .format("DD MMMM YYYY, HH:mm")} WIB`}
                  </strong>
                </div>
              </div>
            </>
          )}
        </div>
        {dataDetailOrderClient?.status === "payment_waiting" && (
          <div className="card-footer">
            <ButtonDefault
              type="rounded"
              label="BAYAR TAGIHAN"
              className="p-button p-button-primary"
              disabled={loadingDetailOrderClient}
              onClick={() => history.push(`/client/payment/${props.orderId}`)}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default InvoiceModule;
