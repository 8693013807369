import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Header from "../components/header/Header";
import HeaderMobileLanding from "../components/header/HeaderMobileLanding";
import HeaderDashboardUser from "../components/header/HeaderDashboardUser";
import Footer from "../components/footer/Footer";
import { usePageTracking } from "../helpers/pageTracking";
import { checkDevice } from "../helpers/deviceDetect";
import { getSettingSiteAction } from "../../redux/action/landingpageAction";

const StaticLayout = ({ children, title }) => {
  usePageTracking();
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(checkDevice());
  const { users, isLoggedIn } = useSelector((state) => state.auth);
  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    dispatch(getSettingSiteAction());
    setIsMobile(checkDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="static-page">
      {isMobile ? (
        <HeaderMobileLanding
          dataSite={dataGetSettingSite}
          loading={loadingGetSettingSite}
        />
      ) : (
        <>
          {isLoggedIn ? (
            <HeaderDashboardUser
              dataSite={dataGetSettingSite}
              loading={loadingGetSettingSite}
              users={users}
            />
          ) : (
            <Header
              dataSite={dataGetSettingSite}
              loading={loadingGetSettingSite}
            />
          )}
        </>
      )}
      {children}
      <Footer dataSite={dataGetSettingSite} loading={loadingGetSettingSite} />
    </div>
  );
};

export default StaticLayout;
