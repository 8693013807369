import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";

import DashboardLayout from "../../../layouts/dashboardLayout";

import { getListArticlesPmAction } from "../../../../redux/action/projectAction";
import { titlePage } from "../../../helpers/titleHead";
import ListArticlePMModule from "../../../modules/Admin/ProjectPM/ListArticlePMModule";
import { useLocation } from "react-router-dom";

const ListArticlePM = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const { projectParams } = useSelector((state) => state.project);
  const { page, perpage } = projectParams;

  useEffect(() => {
    dispatch(
      getListArticlesPmAction({
        page: parsed.page ? parsed.page : page,
        perpage,
        orderDir: "desc",
      })
    );
    titlePage({
      title: "IRIS - Manajemen Tulisan",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectParams]);

  return (
    <DashboardLayout title="Manajemen Tulisan" menu="article">
      <ListArticlePMModule />
    </DashboardLayout>
  );
};

export default ListArticlePM;
