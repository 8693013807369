import firebase from "./firebase";
import firebaseSecond from "./firebaseStrapi";

const firestore = firebase.firestore();
const firestoreStrapi =
  process.env.REACT_APP_TYPE_ENV === "staging"
    ? firebaseSecond.firestore()
    : firebase.firestore();

export const getBanners = async () =>
  await firestoreStrapi.collection("banners").get();

export const getHowDoesItWork = async () =>
  await firestoreStrapi
    .collection("how-does-it-work")
    .doc("default")
    .get();

export const getBenefit = async () =>
  await firestoreStrapi
    .collection("benefit")
    .doc("default")
    .get();

export const getTestimoni = async () =>
  await firestoreStrapi.collection("testimonials").get();

export const getSetting = async () =>
  await firestoreStrapi
    .collection("setting")
    .doc("default")
    .get();

export const getTopWriters = async () =>
  await firestoreStrapi
    .collection("top-writer")
    .doc("default")
    .get();

export const getArticles = async () =>
  await firestore
    .collection("articles")
    .where("status", "==", "publish")
    .get();

export const getDetailArticles = async (id) =>
  await firestore
    .collection("articles")
    .doc(id)
    .get();

export const getFooter = async () =>
  await firestoreStrapi
    .collection("footer")
    .doc("default")
    .get();

export const aboutUs = async () =>
  await firestoreStrapi
    .collection("about-us")
    .doc("default")
    .get();

export const termCondition = async () =>
  await firestoreStrapi
    .collection("term-and-condition")
    .doc("default")
    .get();

export const privacyPolicy = async () =>
  await firestoreStrapi
    .collection("privacy-policy")
    .doc("default")
    .get();

export const faq = async () =>
  await firestoreStrapi.collection("frequently-asked-questions").get();

export const faqSearch = async (query) =>
  await firestoreStrapi
    .collection("frequently-asked-questions")
    .where("question", ">=", query)
    .where("question", "<=", query + "\uf8ff")
    .get();

export const companyBusiness = async () =>
  await firestoreStrapi
    .collection("company-business-field")
    .doc("default")
    .get();

export const typeProduct = async () =>
  await firestoreStrapi
    .collection("product")
    .orderBy("createdAt", "desc")
    .get();

export const getStaticPage = async (slug) => {
  return await firestoreStrapi
    .collection("page")
    .where("slug", "==", slug)
    .get();
};
