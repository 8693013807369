import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import MediaShareListModule from "../../../modules/Admin/MediaShare/MediaShareListModule";
import {
  getWinnerReleaseAction,
  statsReleaseAction,
} from "../../../../redux/action/releaseAction";

const MediaSharePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Media Distribusi",
    });
    history.push("/admin/pm/list-release");
    const params = {
      page: 1,
      perpage: 10,
    };
    dispatch(getWinnerReleaseAction(params));
    dispatch(statsReleaseAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Media Distribusi" menu="distribution">
      <MediaShareListModule />
    </DashboardLayout>
  );
};

export default MediaSharePage;
