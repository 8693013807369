import {
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_DETAIL_FAILURE,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_REQUEST,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  POST_ORDER_CLIENT_REQUEST,
  POST_ORDER_CLIENT_FAILURE,
  POST_ORDER_CLIENT_SUCCESS,
  RESET_ORDER_STATE_REQUEST,
  ORDER_CLIENT_DETAIL_REQUEST,
  ORDER_CLIENT_DETAIL_FAILURE,
  ORDER_CLIENT_DETAIL_SUCCESS,
  UPDATE_ORDER_REQUEST,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  POST_ORDER_BRIEF_REQUEST,
  POST_ORDER_BRIEF_FAILURE,
  POST_ORDER_BRIEF_SUCCESS,
  GET_PROJECT_CLIENT_REQUEST,
  GET_PROJECT_CLIENT_FAILURE,
  GET_PROJECT_CLIENT_SUCCESS,
  // POST_SAVE_CLIENT_REQUEST,
  // POST_SAVE_CLIENT_SUCCESS,
  // POST_SAVE_CLIENT_FAILURE,
} from "../constant/orderConstant";

const initialStateOrder = {
  orderParams: {
    perpage: 10,
    page: 1,
  },
  totalPage: null,

  loadingGetOrder: false,
  dataGetOrder: null,
  errGetOrder: null,

  loadingDetailOrder: false,
  dataDetailOrder: null,
  errDetailOrder: null,

  loadingPostOrderClient: false,
  successPostOrderClient: false,
  dataPostOrderClient: null,
  errPostOrderClient: null,

  loadingDetailOrderClient: false,
  dataDetailOrderClient: null,
  errDetailOrderClient: null,

  loadingUpdateOrder: false,
  successUpdateOrder: false,
  errUpdateOrder: null,

  loadingPostOrderBrief: false,
  successPostOrderBrief: false,
  errPostOrderBrief: null,

  // loadingSaveOrderBrief: false,
  // successSaveOrderBrief: false,
  // errSaveOrderBrief: null,

  loadingGetProjectClient: false,
  dataGetProjectClient: null,
  errGetProjectClient: null,
};

export const orderReducer = (state = initialStateOrder, action) => {
  switch (action.type) {
    case RESET_ORDER_STATE_REQUEST:
      return {
        ...state,
        loadingPostOrderClient: false,
        successPostOrderClient: false,
        dataPostOrderClient: null,
        errPostOrderClient: null,
        loadingUpdateOrder: false,
        successUpdateOrder: false,
        errUpdateOrder: null,
        loadingPostOrderBrief: false,
        successPostOrderBrief: false,
        // successSaveOrderBrief: false,
        // loadingSaveOrderBrief: false,
        // errSaveOrderBrief: null,
        errPostOrderBrief: null,
      };

    case GET_ORDER_REQUEST:
      return {
        ...state,
        loadingGetOrder: true,
        errGetOrder: null,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        loadingGetOrder: false,
        dataGetOrder: action.data.data,
        errGetOrder: null,
        totalPage: action.data.totalpage,
      };
    case GET_ORDER_FAILURE:
      return {
        ...state,
        loadingGetOrder: false,
        errGetOrder: action.data,
      };

    case GET_ORDER_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetailOrder: true,
        errDetailOrder: null,
      };
    case GET_ORDER_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetailOrder: false,
        dataDetailOrder: action.data,
        errDetailOrder: null,
      };
    case GET_ORDER_DETAIL_FAILURE:
      return {
        ...state,
        loadingDetailOrder: false,
        errDetailOrder: action.err,
      };

    case POST_ORDER_CLIENT_REQUEST:
      return {
        ...state,
        loadingPostOrderClient: true,
        successPostOrderClient: false,
        errPostOrderClient: null,
      };

    // case POST_SAVE_CLIENT_REQUEST:
    //   return {
    //     ...state,
    //     loadingSaveOrderClient: true,
    //     successSaveOrderClient: false,
    //     errSaveOrderClient: null,
    //   };
    // case POST_SAVE_CLIENT_SUCCESS:
    //   return {
    //     ...state,
    //     loadingPostOrderBrief: false,
    //     successPostOrderBrief: true,
    //     errPostOrderBrief: null,
    //   };
    // case POST_SAVE_CLIENT_FAILURE:
    //   return {
    //     ...state,
    //     loadingPostOrderBrief: false,
    //     successPostOrderBrief: false,
    //     errPostOrderBrief: action.data,
    //   };
  
    case POST_ORDER_CLIENT_SUCCESS:
      return {
        ...state,
        loadingPostOrderClient: false,
        successPostOrderClient: true,
        dataPostOrderClient: action.data.data,
        errPostOrderClient: null,
      };
    case POST_ORDER_CLIENT_FAILURE:
      return {
        ...state,
        loadingPostOrderClient: false,
        successPostOrderClient: false,
        errPostOrderClient: action.data,
      };

    case ORDER_CLIENT_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetailOrderClient: true,
        errDetailOrderClient: null,
      };
    case ORDER_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetailOrderClient: false,
        dataDetailOrderClient: action.data,
        errDetailOrderClient: null,
      };
    case ORDER_CLIENT_DETAIL_FAILURE:
      return {
        ...state,
        loadingDetailOrderClient: false,
        errDetailOrderClient: action.err,
      };

    case UPDATE_ORDER_REQUEST:
      return {
        ...state,
        loadingUpdateOrder: true,
        successUpdateOrder: false,
        errUpdateOrder: null,
      };
    case UPDATE_ORDER_SUCCESS:
      return {
        ...state,
        loadingUpdateOrder: false,
        successUpdateOrder: true,
        errUpdateOrder: null,
      };
    case UPDATE_ORDER_FAILURE:
      return {
        ...state,
        loadingUpdateOrder: false,
        successUpdateOrder: false,
        errUpdateOrder: action.data,
      };

    case POST_ORDER_BRIEF_REQUEST:
      return {
        ...state,
        loadingPostOrderBrief: true,
        successPostOrderBrief: false,
        errPostOrderBrief: null,
      };
    case POST_ORDER_BRIEF_SUCCESS:
      return {
        ...state,
        loadingPostOrderBrief: false,
        successPostOrderBrief: action.data,
        errPostOrderBrief: null,
      };
    case POST_ORDER_BRIEF_FAILURE:
      return {
        ...state,
        loadingPostOrderBrief: false,
        successPostOrderBrief: false,
        errPostOrderBrief: action.data,
      };

    case GET_PROJECT_CLIENT_REQUEST:
      return {
        ...state,
        loadingGetProjectClient: true,
        errGetProjectClient: null,
      };
    case GET_PROJECT_CLIENT_SUCCESS:
      return {
        ...state,
        loadingGetProjectClient: false,
        dataGetProjectClient: action.data.data,
        errGetProjectClient: null,
        totalPage: action.data.totalpage,
      };
    case GET_PROJECT_CLIENT_FAILURE:
      return {
        ...state,
        loadingGetProjectClient: false,
        errGetProjectClient: action.data,
      };

    default:
      return state;
  }
};
