import {
  GET_FINANCE_ORDER_REQUEST,
  UPDATE_FINANCE_ORDER_REQUEST,
  GET_FINANCE_PROJECT_REQUEST,
  APPROVE_PAYOUT_REQUEST,
  APPROVE_ALL_PAYOUT_REQUEST,
  RESET_FINANCE_STATE,
} from "../constant/financeConstant";

export const getFinanceOrderAction = (params) => {
  return {
    type: GET_FINANCE_ORDER_REQUEST,
    params,
  };
};

export const getFinanceProjectAction = (params) => {
  return {
    type: GET_FINANCE_PROJECT_REQUEST,
    params,
  };
};

export const updateFinanceOrderAction = (id, data) => {
  return {
    type: UPDATE_FINANCE_ORDER_REQUEST,
    id,
    data,
  };
};

export const approvePayoutAction = (id, data) => {
  return {
    type: APPROVE_PAYOUT_REQUEST,
    id,
    data,
  };
};

export const approveAllPayoutAction = () => {
  return {
    type: APPROVE_ALL_PAYOUT_REQUEST,
  };
};

export const resetStateFinanceAction = () => {
  return {
    type: RESET_FINANCE_STATE,
  };
};
