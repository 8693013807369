export const SEND_CHAT_REQUEST = "SEND_CHAT_REQUEST";
export const SEND_CHAT_SUCCESS = "SEND_CHAT_SUCCESS";
export const SEND_CHAT_FAILURE = "SEND_CHAT_FAILURE";

export const SEND_NOTIF_REQUEST = "SEND_NOTIF_REQUEST";
export const SEND_NOTIF_SUCCESS = "SEND_NOTIF_SUCCESS";
export const SEND_NOTIF_FAILURE = "SEND_NOTIF_FAILURE";

export const DOWNLOAD_ARTICLE_REQUEST = "DOWNLOAD_ARTICLE_REQUEST";
export const DOWNLOAD_ARTICLE_SUCCESS = "DOWNLOAD_ARTICLE_SUCCESS";
export const DOWNLOAD_ARTICLE_FAILURE = "DOWNLOAD_ARTICLE_FAILURE";

export const UNPIN_CHAT_REQUEST = "UNPIN_CHAT_REQUEST";
export const UNPIN_CHAT_SUCCESS = "UNPIN_CHAT_SUCCESS";
export const UNPIN_CHAT_FAILURE = "UNPIN_CHAT_FAILURE";

export const SEND_CHAT_CLIENT_REQUEST = "SEND_CHAT_CLIENT_REQUEST";
export const SEND_CHAT_CLIENT_SUCCESS = "SEND_CHAT_CLIENT_SUCCESS";
export const SEND_CHAT_CLIENT_FAILURE = "SEND_CHAT_CLIENT_FAILURE";

export const SEND_CHAT_CLIENT_FS_REQUEST = "SEND_CHAT_CLIENT_FS_REQUEST";
export const SEND_CHAT_CLIENT_FS_SUCCESS = "SEND_CHAT_CLIENT_FS_SUCCESS";
export const SEND_CHAT_CLIENT_FS_FAILURE = "SEND_CHAT_CLIENT_FS_FAILURE";
