/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import Form from "react-bootstrap/Form";
import { Button } from "primereact/button";

import ButtonAdminLoader from "../../components/button/ButtonAdminLoader";
import HeaderLoginAdmin from "../../components/header/HeaderLoginAdmin";
import DialogAlert from "../../components/dialog/DialogAlert";

import { formEmail } from "../../helpers/email";
import { titlePage } from "../../helpers/titleHead";

import {
  checkEmailAction,
  resetPasswordAction,
  resetStateAuthAction,
} from "../../../redux/action/authAction";

const ForgotAdmin = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState({ value: "", error: null });
  const [dialogAlert, setDialogAlert] = useState({
    dailog: false,
    type: null,
    title: null,
    text: null,
  });

  const {
    loadingCheckEmail,
    dataCheckEmail,
    loadingResetPassword,
    successResetPassword,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    titlePage({
      title: "IRIS - Forgot Password Admin",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataCheckEmail !== null && dataCheckEmail?.exists) {
      dispatch(resetPasswordAction({ email: email.value }));
    } else if (dataCheckEmail !== null && !dataCheckEmail?.exists) {
      setEmail({ ...email, error: "Email belum terdaftar" });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dataCheckEmail]);

  useEffect(() => {
    if (successResetPassword) {
      setDialogAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Reset Password",
        text: "Tautan untuk mengganti password telah dikirimkan ke email anda",
      });
    }
  }, [successResetPassword]);

  const sendEmail = () => {
    if (!formEmail(email?.value)) {
      setEmail({
        ...email,
        error: "Email tidak valid",
      });
    } else if (email?.value?.length < 1) {
      setEmail({
        ...email,
        error: "Email harus diisi",
      });
    } else {
      dispatch(checkEmailAction({ email: email.value }));
    }
  };

  const closeDialog = () => {
    setDialogAlert({
      dailog: false,
      type: null,
      title: null,
      text: null,
    });
    dispatch(resetStateAuthAction());
    history.push(`/admin/login`);
  };

  return (
    <div className="auth-admin">
      <HeaderLoginAdmin />
      <div className="auth-admin-content justify-content-center">
        <div className="content-wrapp">
          <div className="login-form text-center">
            <h2>Lupa Password</h2>
            <Form>
              <Form.Group controlId="formBasicEmail" className="text-left">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  onChange={(e) =>
                    setEmail({ value: e.target.value, error: null })
                  }
                  type="text"
                  value={email.value}
                  isInvalid={email.error !== null}
                  autoComplete="off"
                />
                <Form.Control.Feedback type="invalid">
                  {email.error}
                </Form.Control.Feedback>
              </Form.Group>
            </Form>

            <div className="p-d-flex p-jc-center">
              <Link
                to="/admin/login"
                className="p-button p-button-outlined p-mr-4"
              >
                Batal
              </Link>

              {loadingCheckEmail || loadingResetPassword ? (
                <ButtonAdminLoader />
              ) : (
                <Button
                  className="p-button-primary"
                  onClick={() => sendEmail()}
                >
                  Kirim
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </div>
  );
};

export default ForgotAdmin;
