import {
  WRITER_WITHDRAW_REQUEST,
  WRITER_TRANSACTION_REQUEST,
  LIST_WRITER_PAYMENT_REQUEST,
  DETAIL_WRITER_PAYMENT_REQUEST,
  LIST_PAYOUT_FINANCE_REQUEST,
  RESET_STATE_TRANSACTION,
} from "../constant/transactionConstant";

export const writerWithdrawAction = (data) => {
  return {
    type: WRITER_WITHDRAW_REQUEST,
    data,
  };
};

export const writerTransactionAction = (params) => {
  return {
    type: WRITER_TRANSACTION_REQUEST,
    params,
  };
};

export const listWriterPaymentAction = (params) => {
  return {
    type: LIST_WRITER_PAYMENT_REQUEST,
    params,
  };
};

export const detailWriterPaymentAction = (id) => {
  return {
    type: DETAIL_WRITER_PAYMENT_REQUEST,
    id,
  };
};

export const listPayoutFinanceAction = (params) => {
  return {
    type: LIST_PAYOUT_FINANCE_REQUEST,
    params,
  };
};

export const resetStateTransactionAction = (data) => {
  return {
    type: RESET_STATE_TRANSACTION,
    data,
  };
};
