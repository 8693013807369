/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";

import { TabView, TabPanel } from "primereact/tabview";

import iconDownload from "../../../assets/img/download-blue.svg";
import CoinImage from "../../../assets/img/icon-coin.svg";
import UsersImage from "../../../assets/img/icon-users.svg";
import ClockImage from "../../../assets/img/icon-clock.svg";
import WinnerImage from "../../../assets/img/winner.svg";
import ImgInvite from "../../../assets/img/invite_writer.svg";
import ImgClose from "../../../assets/img/close_project.svg";
import ImgPublish from "../../../assets/img/publish_project.svg";

import DialogUsers from "./Components/PopupListUser";

import DialogAlert from "../../../components/dialog/DialogAlert";

import TabsUserProject from "../../../components/tabs/TabsUserProject";
import ButtonIconLeft from "../../../components/button/ButtonIconLeft";
import LinkEdit from "../../../components/button/LinkEdit";
import {
  TitleLoad,
  DetailPageLoad,
} from "../../../components/loading/loadingContent";
import StatusBadge from "../../../components/badge/StatusBadge";

import {
  resetStateProjectAction,
  userInvitableAction,
  getDetailProjectAction,
  updateProjectPmStatusAction,
} from "../../../../redux/action/projectAction";
import { resetStateUserAction } from "../../../../redux/action/userAction";
import firebase from "../../../../redux/service/firebase/firebase";
import DialogPm from "./Components/PopupListPm";

const DetailProjectPmModule = (props) => {
  const QueryParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const firestore = firebase.firestore();

  const [openNotice, setOpenNotice] = useState(false);
  const [projectList, setProjectList] = useState([]);
  const [typeStatus, setTypeStatus] = useState("");
  const [openDialog, setOpenDialog] = useState(null);
  const [openDialogPm, setOpenDialogPm] = useState(false);
  const [assignPm, setAssignPm] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    dialog: false,
    type: null,
    title: null,
    desc: null,
  });

  const {
    dataGetDetailProject,
    loadingGetDetailProject,
    successAssignProject,
    loadingUpdateProject,
    successUpdateProject,
    errUpdateProject,
    userInvitableParams,
    successUpdateProjectPmStatus,
  } = useSelector((state) => state.project);

  const { successInviteUser, errInviteUser } = useSelector(
    (state) => state.user
  );
  const { users } = useSelector((state) => state.auth);
  const isAssigned =
    users?.user?.type === "pm" || dataGetDetailProject?.pmId === users?.uid;
  const pdfRef = createRef();

  const statusOrder = (status) => {
    switch (status) {
      case "published":
        return "1";
      case "winner":
        return "2";
      case "approved":
        return "3";
      case "sent":
        return "4";
      case "revision":
        return "5";
      case "ongoing":
        return "6";
      case "reject":
        return "7";
      case "removed":
        return "8";
      default:
        return "9";
    }
  };

  const getListProject = async () => {
    return await firestore
      .collection("writerProjects")
      .where("projectId", "==", props.projectId)
      .orderBy("updatedAt", "desc")
      .onSnapshot(
        (docs) => {
          const temp = [];
          docs.forEach((doc) =>
            temp.push({
              ...doc.data(),
              id: doc.id,
            })
          );
          // eslint-disable-next-line no-unused-expressions
          const sortedList = temp
            ?.map((users) => {
              return {
                ...users,
                statusOrder: statusOrder(users?.status),
              };
            })
            .sort((a, b) => b?.updatedAt - a?.updatedAt);

          setProjectList(sortedList);
        },
        (error) => {
          console.error("error", error);
        }
      );
  };

  useEffect(() => {
    if (props.projectId) {
      getListProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId]);

  useEffect(() => {
    if (dataGetDetailProject && !dataGetDetailProject?.price && isAssigned) {
      setOpenNotice(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetDetailProject, dataGetDetailProject?.price]);

  useEffect(() => {
    if (successAssignProject) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Mengundang Penulis",
        desc: "Sistem akan mengirimkan notifikasi ke email penulis",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successAssignProject]);

  useEffect(() => {
    if (successInviteUser) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Undang Penulis",
        desc: "Sistem akan mengirimkan notifikasi ke email penulis",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successInviteUser]);

  useEffect(() => {
    if (successUpdateProjectPmStatus) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title:
          typeStatus === "publish"
            ? "Project Telah Diterbitkan"
            : "Project Telah Ditutup",
        desc:
          typeStatus === "publish"
            ? "Penulis dapat melihat project ini dari halaman project"
            : "Project telah selesai",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateProjectPmStatus]);

  useEffect(() => {
    if (successUpdateProject && assignPm) {
      setAssignPm(false);
      setOpenAlert({
        dialog: true,
        type: "success",
        title: "Assign Project Manajer Berhasil",
        desc: "Project Manajer berhasil ditambah",
      });
      setAssignPm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateProject]);

  useEffect(() => {
    if (errUpdateProject) {
      setOpenAlert({
        dialog: true,
        type: "error",
        title:
          typeStatus === "publish"
            ? "Project Gagal Diterbitkan"
            : "Project Gagal Ditutup",
        desc:
          typeStatus === "publish"
            ? "Gagal menerbitkan project"
            : "Gagal menutup project",
      });
    }
    if (errUpdateProject && assignPm) {
      setAssignPm(false);
      setOpenAlert({
        dialog: true,
        type: "error",
        title: "Assign Project Manajer Gagal",
        desc: "Project Manajer gagal ditambah",
      });
      setAssignPm(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errUpdateProject]);

  useEffect(() => {
    if (errInviteUser) {
      setOpenAlert({
        dialog: true,
        type: "error",
        title: "Gagal Undang Penulis",
        desc: "Penulis gagal diundang",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errInviteUser]);

  const closeAlert = () => {
    setOpenAlert({
      dialog: false,
      type: null,
      title: null,
      desc: null,
      actionDialog: null,
      actionText: null,
      cancelText: null,
    });
    setOpenDialog(null);
    dispatch(resetStateProjectAction());
    dispatch(resetStateUserAction());
    if (openAlert?.type === "success" && typeStatus?.length > 0) {
      setTypeStatus("");
      dispatch(getDetailProjectAction(QueryParams.projectId));
    } else {
      setTypeStatus("");
    }
  };

  const handleCloseProject = () => {
    const pmCloseProject = () => {
      const data = {
        status: "done",
      };
      dispatch(updateProjectPmStatusAction(QueryParams.projectId, data));
    };
    setOpenAlert({
      dialog: true,
      type: "warning",
      title: "Anda yakin ingin menutup project ini?",
      desc: "Penulis tidak akan bisa mengirim tulisan di project ini lagi",
      actionDialog: () => pmCloseProject(),
      actionText: "TUTUP",
      cancelText: "BATAL",
    });
  };

  const handlePublish = () => {
    const publishAction = () => {
      const data = {
        status: "ongoing",
      };
      dispatch(updateProjectPmStatusAction(QueryParams.projectId, data));
    };
    setTypeStatus("publish");
    setOpenAlert({
      dialog: true,
      type: "warning",
      title: "Terbitkan project ini?",
      desc: "Harap pastikan data project sudah benar",
      actionDialog: () => publishAction(),
      actionText: "TERBITKAN",
      cancelText: "BATAL",
    });
  };

  const closeDialogInvite = () => {
    setOpenDialog(null);
    const params = {
      ...userInvitableParams,
      q: "",
    };
    dispatch(userInvitableAction(QueryParams.projectId, params));
  };

  const openPm = () => {
    setAssignPm(true);
    setOpenDialogPm(true);
  };

  const closePm = () => {
    setAssignPm(false);
    setOpenDialogPm(false);
  };

  const openEditPage = () => {
    setOpenNotice(false);
    history.push(`/admin/pm/edit-project/${QueryParams?.projectId}`);
  };

  const closeDialogBrief = () => {
    setOpenNotice(false);
    history.goBack();
  };

  let categoryName;
  if (dataGetDetailProject?.type === "pr") {
    categoryName = "Press Release";
  } else if (dataGetDetailProject?.type === "distribution_media") {
    categoryName = "Media Distribusi";
  } else {
    categoryName = "Artikel";
  }

  const keywords = dataGetDetailProject?.searchKeyword?.split(",");

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-header">
          {loadingGetDetailProject ? (
            <TitleLoad />
          ) : (
            <>
              <div className="card-title">
                <div
                  className="card-title-back"
                  onClick={() => history.goBack()}
                >
                  <span className="icon-back"></span>
                </div>
                <h2 className="card-title-text">Detail Project</h2>
              </div>
              <div className="card-header-right">
                {!isAssigned && <StatusBadge status="only_view" />}

                {dataGetDetailProject?.status !== "done" && isAssigned && (
                  <LinkEdit
                    label="edit"
                    link={`/admin/pm/edit-project/${QueryParams?.projectId}`}
                    className="ml-2"
                  />
                )}
              </div>
            </>
          )}
        </div>
        <div className="card-body px-0">
          {loadingGetDetailProject ? (
            <div className="project-info-wrapp">
              <DetailPageLoad />
            </div>
          ) : (
            <>
              <div className="project-detail-top">
                <div className="project-info-wrapp">
                  <div className="project-image">
                    <img src={dataGetDetailProject?.thumbnail} alt="tumbnail" />
                  </div>
                  <div className="project-info">
                    <div className="info-row-title">
                      <h3>{dataGetDetailProject?.title}</h3>
                      <div className="d-flex align-items-center">
                        <h6>
                          Nomor Pekerjaan: {dataGetDetailProject?.jobNumber}
                        </h6>
                        <StatusBadge
                          status={dataGetDetailProject?.status}
                          className="ml-3"
                        />
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={CoinImage} alt="" />
                        <NumberFormat
                          prefix={"Rp "}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetDetailProject?.price}
                          renderText={(value) => <p>{value}</p>}
                        />
                      </div>
                      <div className="info">
                        <img src={ClockImage} alt="" />
                        <p>
                          Deadline{" "}
                          {dayjs(dataGetDetailProject?.deadlineDate?.toDate())
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={UsersImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetDetailProject?.nWriter}
                          renderText={(value) => (
                            <p>
                              <strong> {value} </strong> Penulis Bergabung
                            </p>
                          )}
                        />
                      </div>
                      <div className="info">
                        <img src={WinnerImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={
                            dataGetDetailProject?.totalWinner
                              ? dataGetDetailProject?.totalWinner
                              : 0
                          }
                          renderText={(value) => <p>{value}/</p>}
                        />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetDetailProject?.winnerQuota}
                          renderText={(value) => (
                            <p>
                              <strong>{value}</strong> Tulisan Terpilih
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="project-description">
                    <p>{dataGetDetailProject?.description}</p>
                  </div>
                  <div className="project-action">
                    <div className="action-left">
                      {dataGetDetailProject?.briefUrl && (
                        <a
                          className="btn-download brief"
                          href={dataGetDetailProject?.briefUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={iconDownload} />
                          Unduh Ringkasan
                        </a>
                      )}
                    </div>
                    <div className="action-right">
                      {isAssigned && (
                        <>
                          {((dataGetDetailProject?.status === "published" &&
                            (!dataGetDetailProject?.totalWinner ||
                              dataGetDetailProject?.totalWinner <
                                dataGetDetailProject?.winnerQuota)) ||
                            (dataGetDetailProject?.status === "ongoing" &&
                              (!dataGetDetailProject?.totalWinner ||
                                dataGetDetailProject?.totalWinner <
                                  dataGetDetailProject?.winnerQuota))) && (
                            <>
                              {isAssigned && (
                                <ButtonIconLeft
                                  className="ml-2"
                                  label="UNDANG PENULIS"
                                  image={ImgInvite}
                                  onClick={() => setOpenDialog("users")}
                                />
                              )}
                            </>
                          )}
                          {dataGetDetailProject?.status === "new" && (
                            <>
                              {(!dataGetDetailProject?.pmName ||
                                !dataGetDetailProject?.pmId) && (
                                <ButtonIconLeft
                                  className="ml-2"
                                  label="ASSIGN PM"
                                  image={ImgInvite}
                                  onClick={() => openPm()}
                                />
                              )}
                              <ButtonIconLeft
                                className="ml-2"
                                label="TERBITKAN"
                                image={ImgPublish}
                                onClick={() => handlePublish()}
                                loading={loadingUpdateProject}
                              />
                            </>
                          )}
                          {(dataGetDetailProject?.status === "published" ||
                            dataGetDetailProject?.status === "ongoing") && (
                            <ButtonIconLeft
                              className="ml-2"
                              label="TUTUP PROJECT"
                              color="danger"
                              image={ImgClose}
                              onClick={() => handleCloseProject()}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-detail-bottom">
                <div className="tab-project" ref={pdfRef}>
                  <TabView>
                    <TabPanel header="Ringkasan Project">
                      <div className="accordion-project-wrapper">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <h3>Informasi Project</h3>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Jenis Project</h6>
                            <div className="box-text">{categoryName}</div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Kategori Project</h6>
                            <div className="box-text">
                              {dataGetDetailProject?.category}
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Manajer Project</h6>
                            <div className="box-text">
                              {dataGetDetailProject?.pmName ||
                                users?.user?.name}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12">
                            <hr />
                          </div>
                          <div className="col-12 col-sm-12">
                            <h3>Informasi Klien</h3>
                          </div>
                          <div className="col-12 col-sm-12">
                            <h2>{dataGetDetailProject?.clientCompany?.name}</h2>
                          </div>
                          <div className="col-12 col-sm-12">
                            <p>
                              {
                                dataGetDetailProject?.clientCompany
                                  ?.companyBackground
                              }
                            </p>
                          </div>
                          <div className="col-12 col-sm-6">
                            <h6>Bidang Usaha</h6>
                            <div className="box-text">
                              {
                                dataGetDetailProject?.clientCompany
                                  ?.businessField
                              }
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <h6>Kata Kunci Pencarian</h6>
                            <div className="d-flex flex-wrap">
                              {keywords?.map((item) => (
                                <div className="box-text list">{item}</div>
                              ))}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12">
                            <hr />
                          </div>
                          <div className="col-12 col-sm-12">
                            <h3>Target Pembaca</h3>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Usia</h6>
                            <div className="box-text">
                              {dataGetDetailProject?.target?.age}
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Jenis kelamin</h6>
                            <div className="box-text">
                              {dataGetDetailProject?.target?.sex}
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Stakeholder</h6>
                            <div className="d-flex flex-wrap">
                              {dataGetDetailProject?.target?.stakeholders?.map(
                                (item) => (
                                  <div className="box-text list">{item}</div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12">
                            <hr />
                          </div>
                          <div className="col-12 col-sm-12">
                            <h3>Ruang Lingkup Rilis</h3>
                          </div>
                          {dataGetDetailProject?.type === "pr" && (
                            <>
                              <div className="col-12 col-sm-4">
                                <h6>Kategori Rilis</h6>
                                <div className="box-text mb-5">
                                  {dataGetDetailProject?.releaseCategory}
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-12 col-sm-12">
                            <h6>Objek Komunitas yang Ingin Dicapai</h6>
                            <p>{dataGetDetailProject?.objective}</p>
                            <h6>
                              Atribut Quote dan Pesan yang Ingin Disampaikan
                            </h6>
                            <p>{dataGetDetailProject?.attribution}</p>
                            <h6>
                              Hal yang Diperhatikan dalam Penulisan Release
                            </h6>
                            <p>{dataGetDetailProject?.releaseNote}</p>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Daftar Tulisan">
                      <TabsUserProject
                        userType={users?.user?.type}
                        writerList={projectList}
                        link={{
                          writer: `/admin/pm/detail-project/${QueryParams.projectId}/preview?writer=`,
                          pic: `/admin/pm/detail-project/${QueryParams.projectId}/article?id=`,
                        }}
                      />
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <DialogAlert
        type="warning"
        visible={openNotice}
        actionDialog={() => openEditPage()}
        actionText="LENGKAPI DATA"
        closeDialog={() => closeDialogBrief()}
        title="Data Project Belum Lengkap"
        text="Silahkan lengkapi data terlebih dahulu"
        cancelText="TUTUP"
      />

      <DialogPm
        dialogPm={openDialogPm}
        closeDialog={() => closePm()}
        projectId={props.projectId}
      />

      <DialogUsers
        dialogUser={openDialog}
        openDialogInvite={() => setOpenDialog("invite")}
        openDialogUsers={() => setOpenDialog("users")}
        closeDialog={() => closeDialogInvite()}
      />

      <DialogAlert
        type={openAlert.type}
        visible={openAlert.dialog}
        closeDialog={() => closeAlert()}
        title={openAlert.title}
        text={openAlert.desc}
        actionDialog={openAlert?.actionDialog}
        actionText={openAlert?.actionText}
        cancelText={openAlert?.cancelText}
        loading={loadingUpdateProject}
      />
    </>
  );
};

export default DetailProjectPmModule;
