import {
  RESET_STATE_ADMIN,
  GET_LIST_INFO_FAILURE,
  GET_LIST_INFO_REQUEST,
  GET_LIST_INFO_SUCCESS,
  POST_INFO_FAILURE,
  POST_INFO_REQUEST,
  POST_INFO_SUCCESS,
  GET_DETAIL_INFO_FAILURE,
  GET_DETAIL_INFO_REQUEST,
  GET_DETAIL_INFO_SUCCESS,
  UPDATE_INFO_FAILURE,
  UPDATE_INFO_REQUEST,
  UPDATE_INFO_SUCCESS,
  DELETE_INFO_FAILURE,
  DELETE_INFO_REQUEST,
  DELETE_INFO_SUCCESS,
} from "../constant/adminConstant";

const initialStateAdmin = {
  totalPage: 0,

  loadingListInfo: false,
  dataListInfo: null,
  errorListInfo: null,

  loadingPostInfo: false,
  successPostInfo: false,
  errorPostInfo: null,

  loadingDetailInfo: false,
  dataDetailInfo: null,
  errorDetailInfo: null,

  loadingUpdateInfo: false,
  successUpdateInfo: false,
  errorUpdateInfo: null,

  loadingDeleteInfo: false,
  successDeleteInfo: false,
  errorDeleteInfo: null,
};

export const adminReducer = (state = initialStateAdmin, action) => {
  switch (action.type) {
    case RESET_STATE_ADMIN:
      return {
        ...state,
        loadingPostInfo: false,
        successPostInfo: false,
        errorPostInfo: null,
        loadingUpdateInfo: false,
        successUpdateInfo: false,
        errorUpdateInfo: null,
        loadingDeleteInfo: false,
        successDeleteInfo: false,
        errorDeleteInfo: null,
      };

    case GET_LIST_INFO_REQUEST:
      return {
        ...state,
        loadingListInfo: true,
        errorListInfo: null,
      };
    case GET_LIST_INFO_SUCCESS:
      return {
        ...state,
        totalPage: action.data.totalpage,
        loadingListInfo: false,
        dataListInfo: action.data.data,
        errorListInfo: null,
      };
    case GET_LIST_INFO_FAILURE:
      return {
        ...state,
        loadingListInfo: false,
        errorListInfo: action.data,
      };

    case POST_INFO_REQUEST:
      return {
        ...state,
        loadingPostInfo: true,
        errorPostInfo: null,
      };
    case POST_INFO_SUCCESS:
      return {
        ...state,
        loadingPostInfo: false,
        successPostInfo: true,
        errorPostInfo: null,
      };
    case POST_INFO_FAILURE:
      return {
        ...state,
        loadingPostInfo: false,
        errorPostInfo: action.data,
      };

    case GET_DETAIL_INFO_REQUEST:
      return {
        ...state,
        loadingDetailInfo: true,
        errorDetailInfo: null,
      };
    case GET_DETAIL_INFO_SUCCESS:
      return {
        ...state,
        loadingDetailInfo: false,
        dataDetailInfo: action.data,
        errorDetailInfo: null,
      };
    case GET_DETAIL_INFO_FAILURE:
      return {
        ...state,
        loadingDetailInfo: false,
        errorDetailInfo: action.data,
      };

    case UPDATE_INFO_REQUEST:
      return {
        ...state,
        loadingUpdateInfo: true,
        errorUpdateInfo: null,
      };
    case UPDATE_INFO_SUCCESS:
      return {
        ...state,
        loadingUpdateInfo: false,
        successUpdateInfo: true,
        errorUpdateInfo: null,
      };
    case UPDATE_INFO_FAILURE:
      return {
        ...state,
        loadingUpdateInfo: false,
        errorUpdateInfo: action.data,
      };

    case DELETE_INFO_REQUEST:
      return {
        ...state,
        loadingDeleteInfo: true,
        errorDeleteInfo: null,
      };
    case DELETE_INFO_SUCCESS:
      return {
        ...state,
        loadingDeleteInfo: false,
        successDeleteInfo: true,
        errorDeleteInfo: null,
      };
    case DELETE_INFO_FAILURE:
      return {
        ...state,
        loadingDeleteInfo: false,
        errorDeleteInfo: action.data,
      };

    default:
      return state;
  }
};
