import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// User
import LandingPage from "./pages/user/LandingPage";
import RegisterInvitationPage from "./pages/user/RegisterInvitationPage";
import RegisterFormPage from "./pages/auth/RegisterForm";
import RegisterSelectRolePage from "./pages/user/RegisterSelectRolePage";
import DashboardUser from "./pages/user/Dashboard";
import WritePageUser from "./pages/user/WritePage";
import LoginPage from "./pages/user/LoginPage";
import LoginEmailPage from "./pages/auth/LoginEmail";
import NotFound from "./pages/NotFoundPage";
import ProjectDetail from "./pages/user/ProjectDetail";
import ProjectPage from "./pages/user/ProjectPage";
import ProjectInvitationPage from "./pages/user/ProjectInvitationPage";
import DetailMyProjectPage from "./pages/user/DetailMyProjectPage";
import DetailArticlePage from "./pages/user/DetailArticlePage";
import EditProjectPM from "./pages/admin/PM/EditProjectPmPage ";
import SettingPage from "./pages/user/SettingsPage";
import ProfilePage from "./pages/user/ProfilePage";
import ProfileAddPortfolio from "./pages/user/ProfileAddPortfolio";
import ChatPage from "./pages/user/ChatPage";
import AccountRegisterPage from "./pages/user/AccountRegisterPage";
import ProfileEdit from "./pages/user/ProfileEdit";
import ProfileEditPortfolio from "./pages/user/ProfileEditPortfolio";
import VerifyEmailWriterPage from "./pages/user/VerifyUpdateEmail";
import AboutUsPage from "./pages/user/AboutUs";
import PrivacyPolicy from "./pages/user/PrivacyPolicyPage";
import Terms from "./pages/user/TermsandConditionPage";
import ForgotWriterPage from "./pages/user/ForgotWriterPage";
import PreviewProjectWriter from "./pages/user/PreviewProjectWriter";
import CreateArticlePage from "./pages/user/CreateArticlePage";
import FAQPage from "./pages/user/FAQPage";
import ResetPasswordPage from "./pages/auth/ResetPasswordPage";
import BalancePage from "./pages/user/BalancePage";
import WithdrawPage from "./pages/user/WithdrawPage";

// Admin
import LoginAdmin from "./pages/admin/LoginAdminPage";
import ListProjectPM from "./pages/admin/PM/ListProjectPmPage";
import DetailProjectPM from "./pages/admin/PM/DetailProjectPmPage";
import ListUserPM from "./pages/admin/PM/ListUserVerifiedPage";
import ListUserInvited from "./pages/admin/PM/ListUserInvitedPage";
import AddProjectPM from "./pages/admin/PM/AddProjectPmPage";
import AddUser from "./pages/admin/PM/AddUserPage";
import ListApplicants from "./pages/admin/PM/ListUserApplicantsPage";
import ListClients from "./pages/admin/PM/ListUserClientPage";
import ListVerified from "./pages/admin/PM/ListVerifiedPage";
import UserDetail from "./pages/admin/PM/UserDetailPage";
import EditUser from "./pages/admin/PM/EditUserPage";
import ChatPMPage from "./pages/admin/PM/ChatPMPage";
import ProfilePmPage from "./pages/admin/PM/ProfilePmPage";
import EditProfilePmPage from "./pages/admin/PM/EditProfilePmPage";
import SettingPmPage from "./pages/admin/PM/SettingPmPage";
import VerifyEmailPmPage from "./pages/admin/PM/VerifyUpdateEmail";
import ForgotAdmin from "./pages/admin/ForgotAdminPage";
import PreviewProjectPage from "./pages/admin/PM/PreviewProjectPage";
import ChangeDetailPmPage from "./pages/admin/PM/ChangeDetailPm";
import CompanyDetailPage from "./pages/admin/PM/CompanyDetailPage";
import CompanyDetailEditPage from "./pages/admin/PM/CompanyDetailEditPage";
import AddCompanyPage from "./pages/admin/PM/AddCompanyPage";
import ListPMPage from "./pages/admin/PM/ListPMPage";
import DetailPMPage from "./pages/admin/PM/DetailPMPage";
import AddPMPage from "./pages/admin/PM/AddPMPage";
import ListProductPage from "./pages/admin/PM/ListProductPage";
import ProductDetailPage from "./pages/admin/PM/ProductDetailPage";
import EditProductPage from "./pages/admin/PM/EditProductPage";
import AddProductPage from "./pages/admin/PM/AddProductPage";
import ListInfoPage from "./pages/admin/PM/ListInfoPage";
import CreateInfoPage from "./pages/admin/PM/CreateInfoPage";
import DetailInfoPage from "./pages/admin/PM/DetailInfoPage";
import ListTicketPMPage from "./pages/admin/PM/ListTicketPMPage";
import WriterProjectPmPage from "./pages/admin/PM/WriterProjectPmPage";
import AddArticlePage from "./pages/admin/PM/AddArticlePage";
import MediaSharePage from "./pages/admin/PM/MediaSharePage";
import MediaShareDetailPage from "./pages/admin/PM/MediaShareDetailPage";
import MediaShareAddPage from "./pages/admin/PM/MediaShareAddPage";
import MediaShareEditPage from "./pages/admin/PM/MediaShareEditPage";

//Client
import ClientRegisterPage from "./pages/client/ClientRegisterPage";
import ServicePage from "./pages/client/ServicePage";
import ClientProfilePage from "./pages/client/ClientProfilePage";
import ClientEditProfilePage from "./pages/client/ClientEditProfilePage";
import MyReleasePage from "./pages/client/MyReleasePage";
import MyOrderPage from "./pages/client/MyOrderPage";
import SettingClientPage from "./pages/client/SettingPage";
import VerifyAccountPage from "./pages/client/VerifyAccountPage";
import LandingPageClient from "./pages/client/LandingpageClient";
import DashboardClientPage from "./pages/client/DashboardPage";
import LoginClientPage from "./pages/client/LoginClientPage";
import ClientCreateProject from "./pages/client/ClientCreateProject";
import PaymentConfirmationPage from "./pages/client/PaymentConfirmationPage";
import InvoicePage from "./pages/client/InvoicePage";
import PaymentMethodPage from "./pages/client/PaymentMethodPage";
import PaymentDetailsPage from "./pages/client/PaymentDetailsPage";
import PaymentSuccessPage from "./pages/client/PaymentUploadPage";
import DetailMyReleasePage from "./pages/client/DetailMyReleasePage";
import ClientPreviewArticlePage from "./pages/client/PreviewArticlePage";
import PICListPage from "./pages/client/PICListPage";
import PICDetailPage from "./pages/client/PICDetailPage";
import EditPICPage from "./pages/client/EditPICPage";
import AddPICPage from "./pages/client/AddPICPage";
import ProductPage from "./pages/client/ProductPage";
import AssignPICPage from "./pages/client/AssignPICPage";
import HelpPage from "./pages/client/HelpPage";
import HelpDetailPage from "./pages/client/HelpDetailPage";
import UpdateSettingClient from "./pages/client/ChangeDetailPage";
import AddHelpPage from "./pages/client/AddHelpPage";
import DetailMyOrderPage from "./pages/client/DetailMyOrderPage";
import VerifyEmailClientPage from "./pages/client/VerifyUpdateEmailClient";
import ClientMediaSharePage from "./pages/client/ClientMediaSharePage";
import ClientMediaShareDetailPage from "./pages/client/ClientMediaShareDetailPage";
import CampaignPage from './pages/client/CampaignPage'

//finance
import ListInvoice from "./pages/admin/finance/ListInvoice";
import DetailInvoice from "./pages/admin/finance/DetailInvoice";
import ListReqPayout from "./pages/admin/finance/ListReqPayout";
import CompanyManagementPage from "./pages/admin/PM/CompanyManagementPage";
import ListArticlePM from "./pages/admin/PM/ListArticlesPmPage";
import ChangeDetailPage from "./pages/user/ChangeDetailPage";
import FinanceSettingPage from "./pages/admin/finance/SettingPage";
import AssignPMPage from "./pages/admin/PM/AssignPMPage";
import PaymentPage from "./pages/admin/finance/PaymentPage";
import FinancePaymentDetailPage from "./pages/admin/finance/PaymentDetailPage";
import ListProjectFinance from "./pages/admin/finance/ProjectFinancePage";
import DetailProjectFinance from "./pages/admin/finance/DetailProjectFinancePage";
import StaticPage from "./pages/StaticPage";
import MyDraftPage from "./pages/client/MyDraftPage";
import DetailDraftMyReleasePage from "./pages/client/DetailMyDraftReleasePage";
import BriefReleasePM from "./pages/admin/PM/BriefReleasePM";
import DetailBriefReleasePM from "./pages/admin/PM/DetailBriefReleasePmPage";
import ClientCreateIdeation from "./pages/client/ClientCreateIdeation";
import ClientUpdateProject from "./pages/client/ClientUpdateProject";

export function Routes() {
  return (
    <Switch>
      {/* Admin */}
      <Route path="/admin/login" component={LoginAdmin} />
      <Route path="/admin/pm/list-project" component={ListProjectPM} />
      <Route
        path="/admin/pm/detail-project/:projectId/writers"
        component={ListUserInvited}
      />
      <Route
        path="/admin/pm/detail-project/:projectId/chat"
        component={ChatPMPage}
      />
      <Route
        path="/admin/pm/detail-project/:projectId/preview"
        component={PreviewProjectPage}
      />
      <Route
        path="/admin/pm/detail-project/:projectId/assign-pm"
        component={AssignPMPage}
      />
      <Route
        path="/admin/pm/detail-project/:projectId/article"
        component={WriterProjectPmPage}
      />
      <Route
        path="/admin/pm/detail-project/:projectId"
        component={DetailProjectPM}
      />
      <Route path="/admin/pm/add-project" component={AddProjectPM} />
      <Route path="/admin/pm/list-user/verified" component={ListUserPM} />
      <Route path="/admin/pm/add-user" component={AddUser} />
      <Route path="/admin/pm/list-user/applicants" component={ListApplicants} />
      <Route path="/admin/pm/list-user/clients" component={ListClients} />
      <Route path="/admin/pm/verified" component={ListVerified} />
      <Route path="/admin/pm/detail-user/:userId" component={UserDetail} />
      <Route path="/admin/pm/edit-user/:userId" component={EditUser} />
      <Route
        path="/admin/pm/edit-project/:projectId"
        component={EditProjectPM}
      />
      <Route path="/admin/pm/profile/edit" component={EditProfilePmPage} />
      <Route path="/admin/pm/profile" component={ProfilePmPage} />
      <Route
        exact
        path="/admin/pm/settings/:changedDetail"
        component={ChangeDetailPmPage}
      />
      <Route path="/admin/pm/settings" component={SettingPmPage} />
      <Route path="/admin/verify-email" component={VerifyEmailPmPage} />
      <Route path="/admin/forgot-password" component={ForgotAdmin} />
      <Route path="/admin/pm/list-articles" component={ListArticlePM} />
      <Route path="/admin/pm/company-list" component={CompanyManagementPage} />
      <Route
        path="/admin/pm/company-detail/:companyId"
        component={CompanyDetailPage}
      />
      <Route
        path="/admin/pm/edit-company/:companyId"
        component={CompanyDetailEditPage}
      />
      <Route path="/admin/pm/add-company" component={AddCompanyPage} />
      <Route path="/admin/pm/list-pm" component={ListPMPage} />
      <Route path="/admin/pm/pm-detail/:userId" component={DetailPMPage} />
      <Route path="/admin/pm/add-pm" component={AddPMPage} />
      <Route path="/admin/pm/list-product" component={ListProductPage} />
      <Route
        path="/admin/pm/detail-product/:productId"
        component={ProductDetailPage}
      />
      <Route
        path="/admin/pm/edit-product/:productId"
        component={EditProductPage}
      />
      <Route path="/admin/pm/add-product" component={AddProductPage} />
      <Route path="/admin/pm/list-information" component={ListInfoPage} />
      <Route path="/admin/pm/create-information" component={CreateInfoPage} />
      <Route
        path="/admin/pm/information/:articleId"
        component={DetailInfoPage}
      />
      <Route path="/admin/pm/list-ticket" component={ListTicketPMPage} />
      <Route path="/admin/pm/list-request-payout" component={ListReqPayout} />
      <Route path="/admin/pm/list-invoice" component={ListInvoice} />
      <Route
        path="/admin/pm/detail-invoice/:invoiceId"
        component={DetailInvoice}
      />
      <Route path="/admin/pm/list-payment" component={PaymentPage} />
      <Route
        path="/admin/pm/detail-payment/:paymentId"
        component={FinancePaymentDetailPage}
      />
      <Route path="/admin/pm/create-article" component={AddArticlePage} />
      <Route path="/admin/pm/list-release" component={MediaSharePage} />
      <Route
        path="/admin/pm/detail-release/:releaseId/update/:mediaId"
        component={MediaShareEditPage}
      />
      <Route
        path="/admin/pm/detail-release/:releaseId"
        component={MediaShareDetailPage}
      />
      <Route
        path="/admin/pm/add-release/:releaseId"
        component={MediaShareAddPage}
      />
      <Route
        path="/admin/pm/brief-release-client"
        component={BriefReleasePM}
      />
      <Route
        path="/admin/pm/detail-brief-release-client/:projectId"
        component={DetailBriefReleasePM}
      />

      <Redirect exact from="/admin" to="/admin/login" />

      {/* Finance */}
      <Route path="/admin/finance/list-invoice" component={ListInvoice} />
      <Route path="/admin/finance/projects" component={ListProjectFinance} />
      <Route
        path="/admin/finance/detail-invoice/:invoiceId"
        component={DetailInvoice}
      />
      <Route
        path="/admin/finance/list-request-payout"
        component={ListReqPayout}
      />
      <Route path="/admin/finance/settings" component={FinanceSettingPage} />
      <Route path="/admin/finance/list-payment" component={PaymentPage} />
      <Route
        path="/admin/finance/detail-payment/:paymentId"
        component={FinancePaymentDetailPage}
      />
      <Route
        path="/admin/finance/list-project"
        component={ListProjectFinance}
      />
      <Route
        path="/admin/finance/detail-project/:projectId"
        component={DetailProjectFinance}
      />

      {/* User */}
      <Route exact path="/" component={LandingPage} />
      <Route path="/register" component={RegisterInvitationPage} />
      <Route path="/create-account" component={RegisterSelectRolePage} />
      <Route path="/dashboard" component={DashboardUser} />
      <Route path="/write/:projectId" component={WritePageUser} />
      <Route path="/login" component={LoginPage} />
      <Route path="/login-email" component={LoginEmailPage} />
      <Route path="/project/detail/:projectId" component={ProjectDetail} />
      <Route path="/project" component={ProjectPage} />
      <Route path="/registerForm" component={RegisterFormPage} />
      <Route path="/not-found" component={NotFound} />
      <Route path="/project-invitation" component={ProjectInvitationPage} />
      <Route
        path="/my-project/:projectId/article"
        component={CreateArticlePage}
      />
      <Route path="/my-project/:projectId" component={DetailMyProjectPage} />
      <Route path="/preview/:projectId" component={PreviewProjectWriter} />
      <Route path="/article/:articleId" component={DetailArticlePage} />
      <Route
        exact
        path="/settings/:changedDetail"
        component={ChangeDetailPage}
      />
      <Route path="/settings" component={SettingPage} />
      <Route path="/profile/add-portfolio" component={ProfileAddPortfolio} />
      <Route
        path="/profile/edit-portfolio/:portfolioId"
        component={ProfileEditPortfolio}
      />
      <Route path="/profile/edit" component={ProfileEdit} />
      <Route path="/profile" component={ProfilePage} />
      <Route path="/chat" component={ChatPage} />
      <Route path="/register-writer" component={AccountRegisterPage} />
      <Route path="/verify-email" component={VerifyEmailWriterPage} />
      <Route path="/about-us" component={AboutUsPage} />
      <Route path="/privacy-policy" component={PrivacyPolicy} />
      <Route path="/terms" component={Terms} />
      <Route path="/faq" component={FAQPage} />
      <Route path="/forgot-password" component={ForgotWriterPage} />
      <Route path="/reset-password" component={ResetPasswordPage} />
      <Route path="/balance" component={BalancePage} />
      <Route path="/withdraw" component={WithdrawPage} />

      {/* Client */}
      <Route path="/service" component={ProductPage} />
      <Route path="/register-client" component={ClientRegisterPage} />
      <Route exact path="/client/order" component={ServicePage} />
      <Route
        path="/client/create-brief/:productId"
        component={ClientCreateProject}
      />
      <Route
        path="/client/update-brief/:projectId"
        component={ClientUpdateProject}
      />
      <Route
        path="/client/ideation"
        component={ClientCreateIdeation}
      />
      <Route
        exact
        path="/client/profile/edit"
        component={ClientEditProfilePage}
      />
      <Route exact path="/client/profile" component={ClientProfilePage} />
      <Route
        path="/client/my-release/assign-pic/:projectId"
        component={AssignPICPage}
      />
      <Route
        path="/client/order-detail/process/:projectId"
        component={DetailMyOrderPage}
      />
      <Route
        path="/client/order-detail/confirm/:projectId"
        component={DetailMyReleasePage}
      />
      <Route
        path="/client/order-detail/draft/:projectId"
        component={DetailDraftMyReleasePage}
      />
      <Route
        path="/client/article/:articleId"
        component={ClientPreviewArticlePage}
      />
      <Route path="/client/my-order/process" component={MyOrderPage} />
      <Route path="/client/my-order/confirm" component={MyReleasePage} />
      <Route path="/client/my-order/draft" component={MyDraftPage} />
      <Route
        path="/client/settings/:changedDetail"
        component={UpdateSettingClient}
      />
      <Route path="/client/settings" component={SettingClientPage} />

      <Route path="/client/verify-account" component={VerifyAccountPage} />
      <Route path="/client/dashboard" component={DashboardClientPage} />
      <Route path="/client/login" component={LoginClientPage} />
      <Route path="/client/forgot-password" component={ForgotWriterPage} />
      <Route
        path="/client/order-checkout/:productId"
        component={PaymentConfirmationPage}
      />
      <Route
        path="/client/payment/:orderId/confirm"
        component={PaymentSuccessPage}
      />
      <Route path="/client/invoice/:orderId" component={InvoicePage} />
      <Route path="/client/payment/:orderId" component={PaymentMethodPage} />
      <Route
        path="/client/payment/payment-details"
        component={PaymentDetailsPage}
      />
      <Route
        path="/client/payment/:orderId/confirm"
        component={PaymentSuccessPage}
      />
      <Route path="/client/pic/detail/:picId" component={PICDetailPage} />
      <Route path="/client/pic/edit-pic/:picId" component={EditPICPage} />
      <Route path="/client/pic/add-pic" component={AddPICPage} />
      <Route path="/client/pic-list" component={PICListPage} />
      <Route path="/client/help/detail/:ticketId" component={HelpDetailPage} />
      <Route path="/client/help/add" component={AddHelpPage} />
      <Route path="/client/help" component={HelpPage} />
      <Route path="/client/verify-email" component={VerifyEmailClientPage} />
      <Route path="/client/verify-email" component={VerifyEmailClientPage} />
      <Route path="/client/list-release" component={ClientMediaSharePage} />
      <Route
        path="/client/detail-release/:releaseId"
        component={ClientMediaShareDetailPage}
      />
      <Route path="/headline-generator" component={CampaignPage} />

      <Route path="/client" component={LandingPageClient} />

      <Route path="/static-page/:slug" component={StaticPage} />
      <Route component={NotFound} />
    </Switch>
  );
}
