/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import infoIcon from "../../assets/img/info-icon.svg";

import PMListProject from "./Components/PMListProject";
import PMDetailProject from "./Components/PMDetailProject";
import PMDetailWriter from "./Components/PMDetailWriter";

import BoxLoader from "../../components/loading/boxLoader";
import ChatEmpty from "../../components/emptyState/chatEmpty";
import Discussion from "./Components/Discussion.js";

import { getArticleAction } from "../../../redux/action/projectAction";

const ChatBoxPM = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const [openWriter, setOpenWriter] = useState(false);

  const parsed = queryString.parse(location.search);
  const writerId = parsed.writer;

  const { loadingGetArticle, dataGetArticle } = useSelector(
    (state) => state.project
  );

  useEffect(() => {
    if (writerId !== undefined) {
      dispatch(getArticleAction(writerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [writerId]);

  return (
    <div className="chat-wrapper chat-pm full-page ">
      <div className="p-grid p-nogutter">
        <div className="p-col-3">
          <PMListProject
            projectIdUrl={props.projectIdUrl}
            writerId={writerId}
          />
        </div>
        {writerId === undefined ? (
          <div className="p-col-9">
            <div className="box-info empty-state">
              <ChatEmpty />
            </div>
          </div>
        ) : (
          <>
            <div className="p-col-5">
              <div className="box-info">
                <div className="header-box umkm-chat p-d-flex p-ai-center">
                  {loadingGetArticle ? (
                    <BoxLoader />
                  ) : (
                    <div className="p-d-flex p-jc-between p-ai-center w-100">
                      <div className="p-d-flex p-ai-center">
                        <div className="pict">
                          <div className="chat-avatar">
                            {dataGetArticle?.userData?.name?.substring(0, 1)}
                          </div>
                        </div>
                        <h3 className="p-mb-0">
                          {dataGetArticle?.userData?.name || "Belum Tersedia"}
                        </h3>
                      </div>
                      <button
                        className="btn-info-chat"
                        onClick={() => setOpenWriter(true)}
                      >
                        <img src={infoIcon} alt="info" />
                      </button>
                    </div>
                  )}
                </div>
                <Discussion chatId={writerId} />
              </div>
            </div>
            <div className="p-col-4">
              {openWriter ? (
                <PMDetailWriter closeSection={() => setOpenWriter(false)} />
              ) : (
                <PMDetailProject writerId={writerId} />
              )}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatBoxPM;
