// import dayjs from 'dayjs';
import React from 'react'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import NumberFormat from 'react-number-format';
import { clientDataProjectAction } from '../../../../../redux/action/utilsAction';
import dayjs from 'dayjs';
import ButtonDefault from '../../../../components/button/ButtonDefault';
import CloseIcon from "@material-ui/icons/Close";

// function ProjectConfirmation(props) {
const ProjectConfirmation = (props) => {
  const dispatch = useDispatch();
  const [qty, setQty] = useState({ value: 1, error: null });
  const { dataAddProjectClient } = useSelector((state) => state.utils);
  const { dataDetailProduct } = useSelector(
    (state) => state.service
  );
  
  const {
    dataGetProjectDetailClient
  } = useSelector((state) => state.project);

  const [isShowHint, setIsShowHint] = useState(true);

  const [isPrice] = useState(dataGetProjectDetailClient?.price ? dataGetProjectDetailClient?.price : dataDetailProduct.price)

  // const paymentDeadline = new Date();
  // paymentDeadline.setDate(paymentDeadline.getDate() + 1);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      keyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: dataAddProjectClient.storyAngles,
      title: dataAddProjectClient.title,
      category: dataAddProjectClient.category,
      type: dataAddProjectClient.type,
      quota: dataAddProjectClient.quota,
      desc: dataAddProjectClient.desc,
      deadline: dayjs(dataAddProjectClient?.deadline).toDate(),
      releaseCategory: dataAddProjectClient?.releaseCategory,
      objective: dataAddProjectClient?.objective,
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient.releaseNote,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
      quantity: qty.value
    };
    const dataArticle = {
      keyword: dataAddProjectClient.keyword,
      title: dataAddProjectClient.title,
      category: dataAddProjectClient.category,
      type: dataAddProjectClient.type,
      quota: dataAddProjectClient.quota,
      desc: dataAddProjectClient.desc,
      deadline: dayjs(dataAddProjectClient?.deadline).toDate(),
      objective: dataAddProjectClient?.objective,
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient.releaseNote,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
      quantity: qty.value
    };
    if (dataDetailProduct?.category === "pr") {
      dispatch(clientDataProjectAction(data));
    } else if (dataDetailProduct?.category === "article") {
      dispatch(clientDataProjectAction(dataArticle));
    }
    props.onSubmit()
  }

  const handleSave = (e) => {
    e.preventDefault();
    const data = {
      keyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: dataAddProjectClient.storyAngles,
      title: dataAddProjectClient.title,
      category: dataAddProjectClient.category,
      type: dataAddProjectClient.type,
      quota: dataAddProjectClient.quota,
      desc: dataAddProjectClient.desc,
      deadline: dayjs(dataAddProjectClient?.deadline).toDate(),
      releaseCategory: dataAddProjectClient?.releaseCategory,
      objective: dataAddProjectClient?.objective,
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient.releaseNote,
      // releaseCategoryQuestion: dataAddProjectClient.releaseCategoryQuestion,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
      quantity: qty.value
    };
    dispatch(clientDataProjectAction(data));
    dataGetProjectDetailClient ? props.onSubmit() : props.onSave()
  }
  return (
    <div className="card card-custom mb-4 payment-confirmation">
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label order-confirmation text-dark">
            Review Pesanan
          </span>
        </h3>
      </div>
      <div className="">
        <div className="confirmation-list">
          <div className="confirmation-title">Detail Project</div>
          <div>
            <div className="confirmation-label">Judul Project</div>
            <div className="confirmation-info">{dataAddProjectClient?.title}</div>
          </div>
          <div className="row">
            <div className='col'>
              <div className="confirmation-label">Jenis Project</div>
              <div className="confirmation-info">{dataAddProjectClient?.releaseCategory}</div>
            </div>
            <div className='col'>
              <div className="confirmation-label">Kategori Project</div>
              <div className="confirmation-info">{dataAddProjectClient?.category}</div>
            </div>
            {/* <div className='col'>
              <div className="confirmation-label">Tanggal deadline</div>
              <div className="confirmation-info">{dayjs(dataAddProjectClient?.deadline).toDate()}</div>
            </div> */}
          </div>
          <div className="confirmation-label">Deskripsi Project</div>
          <div className="confirmation-info">{dataAddProjectClient?.desc}</div>
        </div>
        <div className="card-body">
          <h3> Detail Pesanan</h3>
            <>
              <div className="order-detail">
                <div className="item">
                  <label>NAMA PRODUK</label>
                  <br />
                  <div className="divider"></div>
                  <p>{dataDetailProduct?.name}</p>
                </div>
                <div className="item">
                  <label>KATEGORI PRODUK</label>
                  <br />
                  <p className="category">{dataDetailProduct?.category === "pr" ? "Press Release" : "Artikel"}</p>
                </div>
                <div className="item">
                  <label>HARGA PRODUK</label>
                  <br />
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={isPrice}
                    renderText={(value) => <p>{value}</p>}
                  />
                </div>
              </div>
              <div className="table-content item p-d-flex">
                <span>{`Jumlah Press Release yang ingin dipesan`}</span>
                <div className="item-total p-d-flex">
                  <button
                    className={`icon-wrapper ${qty.value > 1 ? "active" : ""}`}
                    onClick={() =>
                      setQty({ value: qty.value - 1, error: null })
                    }
                    disabled={qty.value <= 1}
                  >
                    <RemoveIcon />
                  </button>
                  <input
                    className="input-number-qty"
                    type="number"
                    value={qty.value}
                    onChange={(e) =>
                      setQty({ value: parseInt(e.target.value), error: null })
                    }
                    min="0"
                    max="100"
                  />
                  <button
                    className={`icon-wrapper ${
                      qty.value < 100 ? "active" : ""
                    }`}
                    onClick={() =>
                      setQty({ value: qty.value + 1, error: null })
                    }
                    disabled={qty.value >= 100}
                  >
                    <AddIcon />
                  </button>
                </div>
                <div className={`hint ${!isShowHint ? "d-none" : ""}`}>
                  <div className="tail"></div>
                  <p>{`Tentukan jumlah Press Release yang Anda butuhkan`}</p>
                  <CloseIcon onClick={() => setIsShowHint(!isShowHint)} />
                </div>
              </div>
              <div className="table-footer p-d-flex p-jc-between">
                <span>TOTAL HARGA</span>
                <NumberFormat
                  prefix={"Rp "}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={isPrice * qty.value}
                  renderText={(value) => <span>{value}</span>}
                />
              </div>
              <div className="table-footer p-d-flex p-jc-between mt-2">
                <span>PPN (11%)</span>
                <NumberFormat
                  prefix={"Rp "}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={Math.ceil(isPrice * qty.value * 0.11)}
                  renderText={(value) => <span>{value}</span>}
                />
              </div>
              <div className="table-footer p-d-flex p-jc-between mt-2">
                <span>
                  <strong>TOTAL TAGIHAN</strong>
                </span>
                <NumberFormat
                  prefix={"Rp "}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={Math.ceil(
                    isPrice * qty.value +
                      isPrice * qty.value * 0.11
                  )}
                  renderText={(value) => <strong>{value}</strong>}
                />
              </div>
            </>
          {" "}
        </div>
      </div>
      <div className="footer-form" >
        <div className="form-add-ideation px-8 p-d-flex p-jc-between p-ai-center">
          <ButtonDefault
            label="Kembali"
            onClick={props.goBack}
            type="rounded"
            color="grey"
            className="p-button p-button-secondary"
          />
          <div className='p-d-flex'>
            {dataDetailProduct?.category === "pr" ? (
              <ButtonDefault
                onClick={handleSave}
                label={ dataGetProjectDetailClient ? "Create Order" : "Simpan"}
                type="rounded"
                className={`p-button ${dataGetProjectDetailClient ? "p-button-primary" : "mr-3 p-button-outlined"}`}
              />
            ) : null}
            {!dataGetProjectDetailClient ? (
              <ButtonDefault
                onClick={handleSubmit}
                label="Create Order"
                type="rounded"
                className="p-button p-button-primary"
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProjectConfirmation
