/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import DialogPortfolio from "./Components/PopupPortfolio";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { UploadThumbnail } from "../../../components/upload/uploadThumbnail";
import ButtonAdminLoader from "../../../components/button/ButtonAdminLoader";
import {
  formattedPhoneNumber,
  formPhoneNumber,
} from "../../../helpers/phoneNumber";
import { formUrl } from "../../../helpers/url";

import { getDistrictAction } from "../../../../redux/action/utilsAction";
import {
  getUsersAction,
  inviteUserAction,
  resetStateUserAction,
} from "../../../../redux/action/userAction";

const DEFAULT_IMAGE = process.env.REACT_APP_IMAGE_USER;

const AddUserModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogPortfolio, setDialogPortfolio] = useState(false);

  const [emailState, setEmailState] = useState({ value: "", error: null });
  const [phoneState, setPhoneState] = useState({ value: "", error: null });
  const [nameState, setNameState] = useState({ value: "", error: null });
  const [addressState, setAddressState] = useState({ value: "", error: null });
  const [provinceState, setProvinceState] = useState({
    value: null,
    error: null,
  });
  const [districtState, setDistrictState] = useState({
    value: null,
    error: null,
  });
  const [aboutState, setAboutState] = useState({ value: "", error: null });
  const [imageState, setImageState] = useState({ value: null, error: null });
  const [linkedInState, setLinkedInState] = useState({
    value: "",
    error: null,
  });
  const [instagramState, setInstagramState] = useState({
    value: "",
    error: null,
  });
  const [provinceValue, setProvinceValue] = useState("");
  const [portfolioValue, setPortfolioValue] = useState([]);

  const { userParams } = useSelector((state) => state.user);
  const {
    dataGetProvince,
    dataGetDistrict,
    dataUploadImage,
    successUploadImage,
  } = useSelector((state) => state.utils);

  const { loadingInviteUser, successInviteUser, errInviteUser } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (errInviteUser) {
      setDialogError(true);
    }
  }, [errInviteUser]);

  useEffect(() => {
    if (dataGetProvince !== null) {
      const dropdownItems = dataGetProvince?.map((item) => ({
        name: item.name,
        code: item.id,
      }));
      setProvinces(dropdownItems);
    }
  }, [dataGetProvince]);

  useEffect(() => {
    if (dataGetDistrict !== null) {
      const dropdownItems = dataGetDistrict?.map((item) => ({
        name: item.name,
        code: item.name,
      }));
      setDistricts(dropdownItems);
    }
  }, [dataGetDistrict]);

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ value: dataUploadImage, error: null });
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (successInviteUser) {
      setTimeout(() => {
        setDialogSuccess(true);
        dispatch(getUsersAction(userParams));
      }, 1500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successInviteUser]);

  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(resetStateUserAction());
    setDialogSuccess(false);
    history.push("/admin/pm/list-user/verified");
  };

  const closeError = () => {
    dispatch(resetStateUserAction());
    setDialogError(false);
  };

  const handleProvince = (data) => {
    dispatch(getDistrictAction(parseInt(data.code)));
    setProvinceState({ value: data, error: null });
    setProvinceValue(data?.name);
  };

  const submitData = () => {
    let data = {
      email: emailState.value,
      name: nameState.value,
      domicile: {
        province: provinceValue,
        cities: districtState.value,
        address: addressState.value,
      },
      biography: aboutState.value,
      portofolio: portfolioValue,
      photo: DEFAULT_IMAGE,
    };

    const isPhoneNumber =
      phoneState.value.length > 0 && phoneState.error === null;
    const isInstagram =
      instagramState.value.length > 0 && instagramState.error === null;
    const isLinkedIn =
      linkedInState.value.length > 0 && linkedInState.error === null;
    const isImages = imageState?.value !== null;

    if (phoneState.value.length > 0 && !formPhoneNumber(phoneState.value)) {
      setPhoneState({ ...phoneState, error: "Nomor telepon tidak valid" });
    }
    if (linkedInState.value.length > 0 && !formUrl(linkedInState.value)) {
      setLinkedInState({ ...linkedInState, error: "Url tidak valid" });
    }
    if (instagramState.value.length > 0 && !formUrl(instagramState.value)) {
      setInstagramState({ ...instagramState, error: "Url tidak valid" });
    }

    if (isPhoneNumber) {
      data = { ...data, phone: formattedPhoneNumber(phoneState.value) };
    }
    if (isPhoneNumber && isInstagram) {
      data = {
        ...data,
        phone: formattedPhoneNumber(phoneState.value),
        instagram: instagramState.value,
      };
    }
    if (isPhoneNumber && isInstagram && isLinkedIn) {
      data = {
        ...data,
        phone: formattedPhoneNumber(phoneState.value),
        instagram: instagramState.value,
        linkedIn: linkedInState.value,
      };
    }
    if (isPhoneNumber && isLinkedIn) {
      data = {
        ...data,
        phone: formattedPhoneNumber(phoneState.value),
        linkedIn: linkedInState.value,
      };
    }
    if (isPhoneNumber && isImages) {
      data = {
        ...data,
        phone: formattedPhoneNumber(phoneState.value),
        photo: imageState.value,
      };
    }
    if (isImages && isInstagram) {
      data = {
        ...data,
        photo: imageState.value,
        instagram: instagramState.value,
      };
    }
    if (isImages && isLinkedIn) {
      data = {
        ...data,
        photo: imageState.value,
        linkedIn: linkedInState.value,
      };
    }
    if (isImages && isInstagram && isLinkedIn) {
      data = {
        ...data,
        photo: imageState.value,
        instagram: instagramState.value,
        linkedIn: linkedInState.value,
      };
    }
    if (isImages && isInstagram && isLinkedIn && isPhoneNumber) {
      data = {
        ...data,
        photo: imageState.value,
        instagram: instagramState.value,
        linkedIn: linkedInState.value,
        phone: formattedPhoneNumber(phoneState.value),
      };
    }

    dispatch(inviteUserAction(data));
  };

  return (
    <>
      <div className="card card-custom mb-4">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <Link to="/admin/pm/list-user/verified">
              <span className="card-label font-weight-bolder text-dark">
                <span className="icon-back mr-2"></span>Tambah Pengguna
              </span>
            </Link>
          </h3>
        </div>
        <div className="card-body pt-0 pb-4">
          <div className="form-add-project">
            <div className="p-field">
              <label className="p-d-block">Nama Lengkap*</label>
              <InputText
                className="p-d-block"
                placeholder="Masukan Nama"
                value={nameState.value}
                onChange={(e) =>
                  setNameState({ value: e.target.value, error: null })
                }
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">Email*</label>
              <InputText
                className="p-d-block"
                type="email"
                placeholder="Masukan Email"
                value={emailState.value}
                onChange={(e) =>
                  setEmailState({ value: e.target.value, error: null })
                }
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">Nomor Telepon</label>
              <InputText
                className={`${phoneState.error !== null &&
                  "p-invalid"} p-d-block`}
                placeholder="Masukkan Nomor Telepon"
                value={phoneState.value}
                onChange={(e) =>
                  setPhoneState({ value: e.target.value, error: null })
                }
              />
              {phoneState.error !== null && (
                <small id="phone-number" className="p-error p-d-block">
                  {phoneState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label>Foto Profil</label>
              <UploadThumbnail
                attachment={imageState.value}
                deleteImage={() => setImageState({ value: null, error: null })}
                path={"user-data"}
                errorText={imageState.error}
              />
            </div>

            <div className="p-field">
              <label className="p-d-block">Alamat</label>
              <InputText
                className="p-d-block"
                placeholder="Masukan Alamat"
                value={addressState.value}
                onChange={(e) =>
                  setAddressState({ value: e.target.value, error: null })
                }
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">Provinsi</label>
              <Dropdown
                options={provinces}
                value={provinceState.value}
                optionLabel="name"
                onChange={(e) => handleProvince(e.value)}
                placeholder="Pilih Provinsi"
              />
            </div>

            <div className="p-field">
              <label className="p-d-block">Kota / Kabupaten</label>
              <Dropdown
                options={districts}
                value={districtState.value}
                onChange={(e) =>
                  setDistrictState({ value: e.target.value, error: null })
                }
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Kota / Kabupaten"
                disabled={districts?.length < 1}
              />
            </div>

            <div className="p-field p-grid">
              <label className="p-d-block p-col-12">Link Sosial Media</label>
              <div className="p-col-6">
                <InputText
                  className={`${linkedInState.error !== null &&
                    "p-invalid"} p-d-block`}
                  placeholder="LinkedIn"
                  type="link"
                  value={linkedInState.value}
                  onChange={(e) =>
                    setLinkedInState({ value: e.target.value, error: null })
                  }
                />
                {linkedInState.error !== null && (
                  <small id="phone-number" className="p-error p-d-block">
                    {linkedInState.error}
                  </small>
                )}
              </div>
              <div className="p-col-6">
                <InputText
                  className={`${instagramState.error !== null &&
                    "p-invalid"} p-d-block`}
                  placeholder="Instagram"
                  type="link"
                  value={instagramState.value}
                  onChange={(e) =>
                    setInstagramState({ value: e.target.value, error: null })
                  }
                />
                {instagramState.error !== null && (
                  <small id="phone-number" className="p-error p-d-block">
                    {instagramState.error}
                  </small>
                )}
              </div>
              <span className="p-col-12 text-helper-italic">
                *Silahkan masukan url, contoh:
                https://media-sosialmu.domain/nickname
              </span>
            </div>

            <div className="p-field">
              <label className="p-d-block">Biografi</label>
              <InputTextarea
                rows={5}
                cols={30}
                value={aboutState.value}
                onChange={(e) =>
                  setAboutState({ value: e.target.value, error: null })
                }
              />
            </div>

            <div className="p-field p-mt-5 portfolio-list">
              <p className="count-folio">
                Portofolio({portfolioValue?.length})
                <span className="ml-3" onClick={() => setDialogPortfolio(true)}>
                  <span className="icon-add-o"></span>
                </span>
              </p>
              {portfolioValue?.map((item) => (
                <div className="p-d-flex p-jc-between">
                  <div className="p-mb-3">
                    <label className="p-d-block folio-name">{item.title}</label>
                    <span className="folio-year">{item.year}</span>
                  </div>
                  <a
                    href={item.portofolioLink}
                    // eslint-disable-next-line react/jsx-no-target-blank
                    target="_blank"
                    className=" p-button btn-wide p-button-outlined p-button-rounded sm-rounded text-uppercase p-px-3"
                  >
                    Lihat Portfolio
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="footer-form">
            <div className="form-add-project p-d-flex p-jc-end p-ai-center">
              <Link
                to="/admin/pm/list-user/verified"
                className="p-button p-button-outlined p-button-secondary mr-3"
              >
                Batal
              </Link>
              {loadingInviteUser ? (
                <ButtonAdminLoader />
              ) : (
                <Button
                  onClick={() => submitData()}
                  label="Simpan"
                  className="btn-wide"
                  disabled={
                    emailState?.value.length < 1 && nameState?.value.length < 1
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="normal-dialog no-close">
        <DialogPortfolio
          visible={dialogPortfolio}
          closeDialog={() => setDialogPortfolio(false)}
          setData={setPortfolioValue}
          data={portfolioValue}
        />
      </div>

      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={closeSuccess}
        title={"Berhasil Menyimpan Data"}
        text={
          "Data user berhasil disimpan dan tautan undangan terkirim ke email user"
        }
      />

      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={() => closeError()}
        title={"Gagal Menyimpan Data"}
        text={"Email atau nomor telepon sudah digunakan"}
      />
    </>
  );
};

export default AddUserModule;
