import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import EditProjectModule from "../../../modules/Admin/ProjectPM/EditProjectModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getAgeAction,
  getGenderAction,
  getCategoryReleaseAction,
  getSesAction,
  getStakeholderAction,
} from "../../../../redux/action/utilsAction";
import { getCompaniesRegisterAction } from "../../../../redux/action/clientAction";
import { getDetailProjectAction } from "../../../../redux/action/projectAction";
import { getUsersAction } from "../../../../redux/action/userAction";

const EditProjectPM = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();

  useEffect(() => {
    const params = {
      page: 1,
      type: "general_pm",
      records: "all",
    };

    dispatch(getAgeAction());
    dispatch(getGenderAction());
    dispatch(getCategoryReleaseAction());
    dispatch(getSesAction());
    dispatch(getStakeholderAction());
    dispatch(getDetailProjectAction(QueryParams.projectId));
    dispatch(getCompaniesRegisterAction());
    dispatch(getUsersAction(params));

    titlePage({
      title: "IRIS - Edit Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Project" menu="project">
      <EditProjectModule projectId={QueryParams.projectId} />
    </DashboardLayout>
  );
};

export default EditProjectPM;
