import api from "./api";

export const addPic = async (token, data) => {
  return api.post("/client/users/pic", data.data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getPicList = async (token, params) => {
  return api.get("/client/users/", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deletePic = async (token, id) => {
  return api.delete(
    `/client/users/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updatePic = async (token, id, data) => {
  return api.put(`/client/users/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const assignPic = async (token, id, data) => {
  return api.post(`/client/projects/${id}/assign-pic`, data, { 
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};