/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import { getProjectPmAction } from "../../../../../redux/action/projectAction";

const FilterUserInvited = (props) => {
  const dispatch = useDispatch();

  const { projectParam } = useSelector((state) => state.project);

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter"></div>

      <Button
        onClick={props.setDialogUser}
        className="p-button-primary button-link"
      >
        Undang Penulis
      </Button>
    </div>
  );
};

export default FilterUserInvited;
