import React, { useEffect } from "react";
import { titlePage } from "../../helpers/titleHead";
import LandingLayout from "../../layouts/landingLayout";
import CampaignModule from "../../modules/Client/Campaign/CampaignModule";
import { useDispatch } from "react-redux";
import {
  getAgeAction,
  getBusinessAction,
  getCategoryReleaseAction,
  getGenderAction,
  getSesAction,
  getStakeholderAction,
} from "../../../redux/action/utilsAction";
import { getCompaniesRegisterAction } from "../../../redux/action/clientAction";

const CampaignPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    titlePage({
      title: "Homepage Campaign",
    });
    dispatch(getAgeAction());
    dispatch(getGenderAction());
    dispatch(getCategoryReleaseAction());
    dispatch(getSesAction());
    dispatch(getStakeholderAction());
    dispatch(getCompaniesRegisterAction());
    dispatch(getBusinessAction());
  }, [dispatch]);

  return (
    <LandingLayout title="Campaign">
      <CampaignModule />
    </LandingLayout>
  );
};

export default CampaignPage;
