/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import { uploadImageAction } from "../../../redux/action/utilsAction";

import EditIcon from "../../assets/img/edit.svg";
import DeleteIcon from "../../assets/img/trash.svg";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

export const UploadAndEditThumbnail = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { users } = useSelector((state) => state.auth);
  const { loadingUploadImage } = useSelector((state) => state.utils);

  useEffect(() => {
    if (props.errorText) {
      setError(props.errorText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.errorText]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/png, image/jpg, image/jpeg",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 1000000) {
        setError("Ukuran gambar lebih dari 1MB");
      } else if (acceptedFiles.length > 1) {
        setError("Silakan pilih satu gambar");
      } else {
        setError("");

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/${props.path}/${times}/${users.uid}/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
      }
    },
  });

  return (
    <div className="editor-wrapp">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <aside style={thumbsContainer}>
          {props.attachment !== null ? (
            <div className="image-thumb-wrapp and-edit mb-3">
              <div className="photo-profile-warp and-edit">
                {!props.displayOnly && (
                  <div className="button-wrap">
                    <button disabled={loadingUploadImage} onClick={open}>
                      <img src={EditIcon} alt="" />
                    </button>
                    <button
                      disabled={loadingUploadImage}
                      onClick={props.deleteImage}
                    >
                      <img src={DeleteIcon} alt="" />
                    </button>
                  </div>
                )}
                <div
                  className={`image-thumb and-edit ${
                    props.displayOnly ? "display-only" : ""
                  }`}
                >
                  <img src={props.attachment} alt={props.attachment} />
                </div>
              </div>
            </div>
          ) : (
            <button
              className={`button-upload-image mb-3 ${
                loadingUploadImage ? "disabled" : ""
              }`}
              type="button"
              onClick={open}
              disabled={loadingUploadImage}
            >
              <span className="icon-plus"></span>
              <span>
                Tambah <br />
                Gambar
              </span>
            </button>
          )}
        </aside>
        {!props.displayOnly && (
          <span
            className={`box-input-file-error justify-content-start mb-3 upload-image ${
              error.length > 0 ? "danger" : ""
            }`}
          >
            {error.length > 0
              ? error
              : "Catatan: Format gambar harus JPG, JPEG, atau PNG dan ukuran maksimal 1MB"}
          </span>
        )}
      </div>
    </div>
  );
};
