import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ListDetailInvoiceModule from "../../../modules/Admin/Finance/ListDetailInvoiceModule";
import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";
import DashboardLayout from "../../../layouts/dashboardLayout";

import { orderClientDetailAction } from "../../../../redux/action/orderAction";
import { titlePage } from "../../../helpers/titleHead";

const DetailInvoince = () => {
  const dispatch = useDispatch();
  const queryParam = useParams();

  const { users } = useSelector((state) => state.auth);
  const isFinance = users?.user?.type === "finance";

  useEffect(() => {
    dispatch(orderClientDetailAction(queryParam?.invoiceId));
    titlePage({
      title: "IRIS - Detail Invoice",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFinance ? (
    <DashboardFinanceLayout title="Manajemen Invoice" menu={"invoice"}>
      <ListDetailInvoiceModule invoiceId={queryParam?.invoiceId} />
    </DashboardFinanceLayout>
  ) : (
    <DashboardLayout title="Manajemen Invoice" menu={"invoice"}>
      <ListDetailInvoiceModule invoiceId={queryParam?.invoiceId} />
    </DashboardLayout>
  );
};

export default DetailInvoince;
