export const GET_PIC_LIST_REQUEST = "GET_PIC_LIST_REQUEST";
export const GET_PIC_LIST_SUCCESS = "GET_PIC_LIST_SUCCESS";
export const GET_PIC_LIST_FAILURE = "GET_PIC_LIST_FAILURE";

export const GET_PIC_DETAIL_REQUEST = "GET_PIC_DETAIL_REQUEST";
export const GET_PIC_DETAIL_SUCCESS = "GET_PIC_DETAIL_SUCCESS";
export const GET_PIC_DETAIL_FAILURE = "GET_PIC_DETAIL_FAILURE";

export const ADD_PIC_REQUEST = "ADD_PIC_REQUEST";
export const ADD_PIC_SUCCESS = "ADD_PIC_SUCCESS";
export const ADD_PIC_FAILURE = "ADD_PIC_FAILURE";

export const UPDATE_PIC_REQUEST = "UPDATE_PIC_REQUEST";
export const UPDATE_PIC_SUCCESS = "UPDATE_PIC_SUCCESS";
export const UPDATE_PIC_FAILURE = "UPDATE_PIC_FAILURE";

export const DELETE_PIC_REQUEST = "DELETE_PIC_REQUEST";
export const DELETE_PIC_SUCCESS = "DELETE_PIC_SUCCESS";
export const DELETE_PIC_FAILURE = "DELETE_PIC_FAILURE";

export const ASSIGN_PIC_REQUEST = "ASSIGN_PIC_REQUEST";
export const ASSIGN_PIC_SUCCESS = "ASSIGN_PIC_SUCCESS";
export const ASSIGN_PIC_FAILURE = "ASSIGN_PIC_FAILURE";

export const RESET_PIC_STATE = "RESET_PIC_STATE";