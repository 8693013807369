/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { avatarLabel, isDefaultPhoto } from "../../../helpers/profileHelper";

const ProfilePMModule = (props) => {
  const { users } = useSelector((state) => state.auth);

  return (
    <div className="card card-custom-detail w-full">
      <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <h3 className="card-title align-items-start flex-column ml-3">
            <span className="card-label font-weight-bolder text-dark">
              PROFIL
            </span>
          </h3>
        </div>

        <div>
          <Link to={`/admin/pm/profile/edit`} className="btn px-0 py-o ml-3 text-primary action-span">
            <strong>Edit</strong>
          </Link>
        </div>
      </div>
      <div className="card-body pt-0 pb-3">
        <div className="d-flex justify-content-between">
          <div className="user-info-box pm">
            <div className="brief-wrapper">
              <div className="pict">
                {
                  isDefaultPhoto(users?.user?.photo) || !users?.user?.photo ?
                    <div className="letter-pics">
                      <span>{avatarLabel(users)}</span>
                    </div> :
                    <img
                      src={users?.user?.photo}
                      alt="photo"
                    />
                }
              </div>
            </div>
            <div className="desc-info">
              <ul>
                <li>
                  <h3>{users?.user?.name}</h3>
                </li>
                <li>
                  <span className="icon-line-message" />
                  {users?.user?.email}
                </li>
                <li>
                  <span className="icon-line-call" />
                  {users?.user?.phone || "-"}
                </li>
              </ul>
            </div>
          </div>
          <div className="user-attr" />
        </div>
      </div>
    </div>
  );
};

export default ProfilePMModule;
