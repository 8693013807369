export const RESET_RELEASE_STATE_REQUEST = "RESET_RELEASE_STATE_REQUEST";

export const GET_WINNER_RELEASE_REQUEST = "GET_WINNER_RELEASE_REQUEST";
export const GET_WINNER_RELEASE_SUCCESS = "GET_WINNER_RELEASE_SUCCESS";
export const GET_WINNER_RELEASE_FAILURE = "GET_WINNER_RELEASE_FAILURE";

export const DETAIL_RELEASE_REQUEST = "DETAIL_RELEASE_REQUEST";
export const DETAIL_RELEASE_SUCCESS = "DETAIL_RELEASE_SUCCESS";
export const DETAIL_RELEASE_FAILURE = "DETAIL_RELEASE_FAILURE";

export const ADD_MEDIA_RELEASE_REQUEST = "ADD_MEDIA_RELEASE_REQUEST";
export const ADD_MEDIA_RELEASE_SUCCESS = "ADD_MEDIA_RELEASE_SUCCESS";
export const ADD_MEDIA_RELEASE_FAILURE = "ADD_MEDIA_RELEASE_FAILURE";

export const PUT_MEDIA_RELEASE_REQUEST = "PUT_MEDIA_RELEASE_REQUEST";
export const PUT_MEDIA_RELEASE_SUCCESS = "PUT_MEDIA_RELEASE_SUCCESS";
export const PUT_MEDIA_RELEASE_FAILURE = "PUT_MEDIA_RELEASE_FAILURE";

export const DELETE_MEDIA_RELEASE_REQUEST = "DELETE_MEDIA_RELEASE_REQUEST";
export const DELETE_MEDIA_RELEASE_SUCCESS = "DELETE_MEDIA_RELEASE_SUCCESS";
export const DELETE_MEDIA_RELEASE_FAILURE = "DELETE_MEDIA_RELEASE_FAILURE";

export const FS_MEDIA_REQUEST = "FS_MEDIA_REQUEST";
export const FS_MEDIA_SUCCESS = "FS_MEDIA_SUCCESS";
export const FS_MEDIA_FAILURE = "FS_MEDIA_FAILURE";

export const FS_DETAIL_MEDIA_REQUEST = "FS_DETAIL_MEDIA_REQUEST";
export const FS_DETAIL_MEDIA_SUCCESS = "FS_DETAIL_MEDIA_SUCCESS";
export const FS_DETAIL_MEDIA_FAILURE = "FS_DETAIL_MEDIA_FAILURE";

export const STATS_RELEASE_REQUEST = "STATS_RELEASE_REQUEST";
export const STATS_RELEASE_SUCCESS = "STATS_RELEASE_SUCCESS";
export const STATS_RELEASE_FAILURE = "STATS_RELEASE_FAILURE";

export const STATS_MEDIA_RELEASE_REQUEST = "STATS_MEDIA_RELEASE_REQUEST";
export const STATS_MEDIA_RELEASE_SUCCESS = "STATS_MEDIA_RELEASE_SUCCESS";
export const STATS_MEDIA_RELEASE_FAILURE = "STATS_MEDIA_RELEASE_FAILURE";
