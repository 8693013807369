import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { formEmail } from "../../../../helpers/email";
import { checkEmailAction, sendUpdateEmailWriterAction } from "../../../../../redux/action/authAction";
import ButtonLoader from "../../../../components/button/ButtonLoader";

const DialogEmail = (props) => {
  const dispatch = useDispatch();
  const [emailState, setEmailState] = useState({
    value: "",
    error: null,
  });

  const { users, loadingSendUpdateEmailWriter, loadingCheckEmail, dataCheckEmail } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if(dataCheckEmail !== null && !dataCheckEmail?.exists) {
      dispatch(sendUpdateEmailWriterAction({ newEmail: emailState.value }));
    } else if (dataCheckEmail?.exists) {
      setEmailState({...emailState, error: "Email telah terdaftar dengan akun lainnya"})
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  },[dataCheckEmail])

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="BATAL"
          onClick={props.closeDialog}
          className="p-button-secondary p-button-outlined p-button-rounded mr-3"
          disabled={loadingSendUpdateEmailWriter}
        />
        {loadingSendUpdateEmailWriter || loadingCheckEmail ? (
          <ButtonLoader />
        ) : (
          <Button
            label="UBAH"
            onClick={() => submitEmail()}
            className="p-button-primary p-button-rounded"
            autoFocus
            disabled={loadingSendUpdateEmailWriter}
          />
        )}
      </div>
    );
  };

  const submitEmail = () => {
    if (!formEmail(emailState?.value)) {
      setEmailState({
        ...emailState,
        error: "Email tidak valid",
      });
    } else if (emailState?.value?.length < 1) {
      setEmailState({
        ...emailState,
        error: "Email harus diisi",
      });}
    else if (emailState?.value === users?.user?.email) {
      setEmailState({
        ...emailState,
        error: "Email baru harus berbeda dengan email lama",
      });
    } else {
      dispatch(checkEmailAction({email: emailState.value}))
    }
  };

  return (
    <div className="normal-dialog no-border-footer rounded">
      <Dialog
        header="Ubah Email"
        visible={props.visible}
        className="update-setting-dialog"
        // style={{ width: "40vw" }}
        footer={renderFooter()}
        onHide={props.closeDialog}
      >
        <div className="form-setting p-px-4">
          <div className="p-field">
            <label className="p-d-block">Email Sekarang</label>
            <InputText
              type="email"
              placeholder="Email Lama"
              className="p-d-block"
              value={users?.user?.email}
              disabled
            />
          </div>
          <div className="p-field">
            <label className="p-d-block">Email Baru</label>
            <InputText
              type="email"
              placeholder="Email Baru"
              className={`p-d-block ${emailState?.error && "p-invalid"}`}
              value={emailState.value}
              onChange={(e) =>
                setEmailState({ value: e.target.value, error: null })
              }
            />
            {emailState.error && (
              <smal className="p-error p-d-block p-text-right">
                {emailState.error}
              </smal>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogEmail;
