import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { myProfileAction } from "../../../redux/action/authAction";
import { detailClientAction } from "../../../redux/action/clientAction";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import ProfileModule from "../../modules/Client/Profile/ProfileModule";

function ClientProfilePage() {
  const dispatch = useDispatch()
  const { users } = useSelector(state => state.auth)
  useEffect(() => {
    titlePage({
      title: "IRIS - Profile",
    });
    dispatch(detailClientAction(users?.user?.companyId))
    dispatch(myProfileAction(users.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Profile">
      <ProfileModule />
    </DashboardClientLayout>
  );
}

export default ClientProfilePage;
