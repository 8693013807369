import React, { useEffect } from "react";

import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { formEmail } from "../../../../helpers/email";
import { formPhoneNumber } from "../../../../helpers/phoneNumber";
import { useHistory, useLocation, useParams } from "react-router";
import ButtonDefault from "../../../../components/button/ButtonDefault";
import ListLoader from "../../../../components/loading/listLoader";
import { getDetailProject } from "../../../../../redux/service/firebase/project";
import {
  addPICAction,
  deletePICAction,
  updatePICAction,
} from "../../../../../redux/action/clientPicAction";
import DialogAlert from "../../../../components/dialog/DialogAlert";

function PICDetailForm(props) {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const location = useLocation().pathname?.split("/")[3];
  const history = useHistory();
  const { dataGetOrder, loadingGetOrder } = useSelector((state) => state.order);
  const {
    loadingAddPIC,
    loadingGetDetailPIC,
    loadingDeletePIC,
    loadingUpdatePIC,
    errDeletePIC,
    successDeletePIC,
  } = useSelector((state) => state.clientPic);
  const { data, isDisabled } = props;
  const [projectState, setProjectState] = useState({ value: "", error: null });
  const [nameState, setNameState] = useState({ value: "", error: null });
  const [emailState, setEmailState] = useState({ value: "", error: null });
  const [phoneState, setPhoneState] = useState({ value: "", error: null });
  const [handledProjects, setHandledProjects] = useState([""]);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    /*eslint-disable no-unused-expressions*/
    let projectStateValues = {};
    for (let i = 0; i < data?.handledProject?.length; i++) {
      projectStateValues = {
        ...projectStateValues,
        [`project${i + 1}`]: data?.handledProject[i],
      };
    }
    setProjectState({ ...projectState, value: projectStateValues });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, dataGetOrder, location]);

  useEffect(() => {
    if (data) {
      if (location === "detail") {
        let dataDetails = [];
        const detail = async (id) => {
          const res = await getDetailProject(id);
          dataDetails.push(res.data());
        };
        data?.handledProject?.map((projectId) => detail(projectId));
        setHandledProjects(dataDetails);
      }
      setNameState({ value: data?.name, error: null });
      setPhoneState({ value: data?.phone?.replace("+62", "0"), error: null });
      setEmailState({ value: data?.email, error: null });
    }
  }, [data, location]);

  useEffect(() => {
    if (errDeletePIC || successDeletePIC) {
      setShowWarning(false);
    }
  }, [errDeletePIC, successDeletePIC]);

  const submitPIC = () => {
    const data = {
      name: nameState.value,
      email: emailState.value,
      phone: phoneState.value?.includes("+")
        ? phoneState.value
        : `+62${phoneState.value?.substring(1)}`,
      projectId: Object.values(projectState.value),
    };
    if (!nameState?.value) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    }
    if (!formEmail(emailState?.value)) {
      setEmailState({
        ...emailState,
        error: "Email tidak valid",
      });
    }
    if (!emailState?.value) {
      setEmailState({ ...emailState, error: "Email harus diisi" });
    }
    if (!formPhoneNumber(phoneState?.value)) {
      setPhoneState({ ...phoneState, error: "Format nomor telepon salah" });
    }
    if (!phoneState?.value) {
      setPhoneState({ ...phoneState, error: "No. Handphone harus diisi" });
    }

    if (
      nameState.value &&
      projectState.value &&
      emailState.value &&
      formEmail(emailState.value) &&
      formPhoneNumber(phoneState.value)
    ) {
      if (location === "edit-pic") {
        dispatch(updatePICAction(QueryParams.picId, data));
      } else {
        data.projectId = "";
        dispatch(addPICAction({ data }));
      }
    }
  };
  const handleInputPhone = (e) => {
    e.target.value.length < 13 &&
      setPhoneState({ value: e.target.value.toString(), error: null });
  };

  const handleKeyInputPhone = (e) => {
    const allowedKeys = "1234567890+".split("");
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const deletePIC = () => {
    dispatch(deletePICAction(QueryParams.picId));
  };

  const closeWarning = () => {
    setShowWarning(false);
  };

  return (
    <div>
      {(loadingGetDetailPIC || loadingGetOrder) && location !== "add-pic" ? (
        <div>
          <ListLoader />
          <ListLoader />
          <ListLoader />
          <ListLoader />
          <ListLoader />
        </div>
      ) : (
        <div>
          <div className="card-body pt-0 p-d-flex p-jc-center">
            <div className="form-detail-pic col-12">
              <div className="p-field">
                <label className="p-d-block">Nama</label>
                <InputText
                  type="text"
                  className="p-d-block"
                  placeholder="Masukkan Nama"
                  defaultValue={data?.name}
                  disabled={isDisabled}
                  onChange={(e) =>
                    setNameState({ value: e.target.value, error: null })
                  }
                />
                {nameState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {nameState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">Email</label>
                <InputText
                  className="p-d-block"
                  type="email"
                  placeholder="Masukkan Email"
                  defaultValue={data?.email}
                  disabled={props.type === "edit" || props.isDisabled}
                  onChange={(e) =>
                    setEmailState({ value: e.target.value, error: null })
                  }
                />
                {emailState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {emailState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">No. Handphone</label>
                <InputText
                  type="text"
                  className="p-d-block"
                  placeholder="Masukkan Nomor HP"
                  defaultValue={data?.phone}
                  value={phoneState.value}
                  disabled={isDisabled}
                  onChange={(e) => handleInputPhone(e)}
                  onKeyPress={(e) => handleKeyInputPhone(e)}
                />
                {phoneState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {phoneState.error}
                  </small>
                )}
              </div>
              {isDisabled && (
                <>
                  <div className="p-field">
                    <label className="p-d-block">List Project</label>
                    {handledProjects?.map((project, key) => (
                      <div key={key} className="pic-project">
                        <span>{project?.title}</span>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="footer-form-client pm p-d-flex p-jc-center">
            {location === "add-pic" && (
              <div className="footer-buttons col-lg-12 col-md-12 col-sm-12">
                <Button
                  className="p-button-outlined p-button-secondary p-button"
                  label="BATAL"
                  onClick={() => history.goBack()}
                ></Button>
                <ButtonDefault
                  onClick={() => submitPIC()}
                  label="SIMPAN"
                  className="ml-4 p-button-primary p-button"
                  loading={loadingAddPIC}
                  type="rounded"
                />
              </div>
            )}
            {location === "detail" && (
              <div className="footer-buttons col-lg-12 col-md-12 col-sm-12">
                <Button
                  className="p-button-outlined p-button-danger"
                  label="HAPUS"
                  onClick={() => setShowWarning(true)}
                ></Button>
                <Button
                  onClick={() =>
                    history.push(`/client/pic/edit-pic/${QueryParams.picId}`)
                  }
                  label="EDIT"
                  className="p-button-outlined"
                />
              </div>
            )}
            {location === "edit-pic" && (
              <div className="footer-buttons col-lg-12 col-md-12 col-sm-12">
                <Button
                  className="p-button-outlined p-button p-button-secondary"
                  label="BATAL"
                  onClick={() => history.goBack()}
                ></Button>
                <ButtonDefault
                  onClick={() => submitPIC()}
                  label="SIMPAN"
                  loading={loadingUpdatePIC}
                  className="ml-4 p-button-primary p-button"
                />
              </div>
            )}
          </div>
        </div>
      )}
      <DialogAlert
        type="warning"
        actionDialog={deletePIC}
        actionText="HAPUS"
        closeDialog={closeWarning}
        visible={showWarning}
        title="Anda yakin ingin menghapus Tim?"
        loading={loadingDeletePIC}
        cancelText="BATAL"
      />
    </div>
  );
}

export default PICDetailForm;
