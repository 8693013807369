/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import ProfileEditPMModule from "../../../modules/Admin/Profile/ProfileEditPmModule";
import DashboardLayout from "../../../layouts/dashboardLayout";

import { titlePage } from "../../../helpers/titleHead";

const EditProfilePmPage = () => {
  useEffect(() => {
    titlePage({
      title: "IRIS - Edit Profile",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Profile">
      <ProfileEditPMModule />
    </DashboardLayout>
  );
};

export default EditProfilePmPage;
