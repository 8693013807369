import React from "react";
import ContentLoader from "react-content-loader";

const LogoLoader = () => {
  const CircleLoader = () => {
    return (
      <ContentLoader
        height={36}
        width={36}
        backgroundColor="#c7c7c7"
        foregroundColor="#fafafa"
        backgroundOpacity={0.6}
        foregroundOpacity={0.8}
        style={{ width: "100%" }}
      >
        <circle cx="18" cy="18" r="18" />
      </ContentLoader>
    );
  };
  return (
    <>
      <CircleLoader />
    </>
  );
};

export default LogoLoader;
