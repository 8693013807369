import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Menubar } from "primereact/menubar";

const HeaderLoginAdmin = () => {
  const { dataGetSettingSite } = useSelector((state) => state.landingpage);

  const start = (
    <Link to="/">
      <img
        alt="logo"
        src={dataGetSettingSite?.logo}
        onError={(e) => (e.target.src = "/")}
        height="40"
        className="p-mr-2"
      />
    </Link>
  );

  return (
    <div className="menu-auth">
      <div className="card">
        <Menubar start={start} />
      </div>
    </div>
  );
};

export default HeaderLoginAdmin;
