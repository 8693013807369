import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import queryString from "query-string";
import _ from "lodash";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import { checkDevice } from "../../../helpers/deviceDetect";
import {
  HeaderContentLoad,
  TableLoad,
} from "../../../components/loading/loadingContent";
import NumberBody from "../../../components/datatableBody/NumberBody";
import TextBody from "../../../components/datatableBody/TextBody";
import KpiBody from "../../../components/datatableBody/KpiBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import { getWinnerReleaseAction } from "../../../../redux/action/releaseAction";

const MediaShareListModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const [isMobile, setIsMobile] = useState(checkDevice());
  const [chart, setChart] = useState(null);
  const {
    dataWinnerRelease,
    loadingWinnerRelease,
    dataStatsRelease,
    loadingStatsRelease,
  } = useSelector((state) => state.release);

  useEffect(() => {
    setIsMobile(checkDevice);
  }, []);

  useEffect(() => {
    if (!loadingStatsRelease && dataStatsRelease !== null) {
      const { tier } = dataStatsRelease;
      const info = [
        { name: "Tier 1", value: tier?.tier1 || 0, color: "#0099FF" },
        { name: "Tier 2", value: tier?.tier2 || 0, color: "#006BB3" },
        { name: "Tier 3", value: tier?.tier3 || 0, color: "#0CCAE8" },
      ];

      setChart(info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStatsRelease]);

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
      };

      history.push(`/client/list-release?search=${search || ""}&page=1`);
      dispatch(getWinnerReleaseAction(params));
    }, 1000)
  ).current;

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getWinnerReleaseAction(params));

    history.push(
      `/client/list-release?search=${parsed?.search || ""}&page=${page + 1}`
    );
  };

  const kpiBody = (item) => {
    return <KpiBody number={item?.output || 0} />;
  };
  const prValueBody = (item) => {
    return <NumberBody number={item?.prValue || 0} isPrice />;
  };
  const titleBody = (item) => {
    return <TextBody text={`${item?.title}`} />;
  };
  const actionBody = (item) => {
    return (
      <div className="action-table">
        <Link to={`/client/detail-release/${item._id}`} className="link">
          Lihat Release
        </Link>
      </div>
    );
  };

  const labelBody = ({ active, payload }) => {
    if (active && payload && payload?.length) {
      return (
        <div className="label-box">
          <div
            className="label-color"
            style={{ backgroundColor: `${payload[0]?.payload?.color}` }}
          />
          <div className="label-text">
            <div className="label-name">{payload[0]?.name}</div>
            <div className="label-value">{`${payload[0]?.value?.toFixed(
              2
            )}%`}</div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-body">
          {loadingStatsRelease && !dataStatsRelease ? (
            <HeaderContentLoad />
          ) : (
            <div className="media-share-upper">
              <div className="media-share-text">
                <div className="media-share-box">
                  <p>Total News</p>
                  <h3>{dataStatsRelease?.totalNews}</h3>
                </div>
                <div className="media-share-box">
                  <p>Total PR Value</p>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={dataStatsRelease?.totalPRValue}
                    renderText={(value) => <h3>{value}</h3>}
                  />
                </div>
              </div>
              <div className="media-share-chart">
                <PieChart
                  width={isMobile ? 130 : 200}
                  height={isMobile ? 130 : 200}
                >
                  <Pie
                    data={chart}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    fill="#0099FF"
                  >
                    {chart?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip content={labelBody} />
                </PieChart>
              </div>
              <div className="media-share-legend">
                <p>Akumulatif Media Tier</p>
                {chart?.map((item) => (
                  <div className="legend-box" key={item.name}>
                    <div
                      className="legend-color"
                      style={{ backgroundColor: `${item.color}` }}
                    />
                    <div className="legend-value">{`${item.value.toFixed(
                      2
                    )}%`}</div>
                    <div className="legend-name">{item.name}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="card card-dashboard mt-4">
        <div className="card-header">
          <div className="card-title">
            <h2 className="card-title-text">List Media Release</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="table-attribute-wrapp">
            <div className="filter">
              <div className="filter-field">
                <InputText
                  id="search"
                  aria-describedby="search-help"
                  className="p-d-block search-inputtext"
                  placeholder="Cari"
                  onChange={(e) => searchingHandler(e.target.value)}
                />
                <p>
                  Cari berdasarkan <span>Judul Media Release</span>
                </p>
              </div>
            </div>
          </div>
          {loadingWinnerRelease ? (
            <TableLoad />
          ) : (
            <div className="table-content-wrapp">
              {dataWinnerRelease?.length < 1 || dataWinnerRelease === null ? (
                <DataEmpty />
              ) : (
                <DataTable value={dataWinnerRelease} className="table-release">
                  <Column
                    headerClassName="release-title"
                    header="Judul Release"
                    body={titleBody}
                  />
                  <Column header="PR Value" body={prValueBody} />
                  <Column header="Output Media" body={kpiBody} />
                  <Column body={actionBody} />
                </DataTable>
              )}
            </div>
          )}
          {dataWinnerRelease?.total > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={dataWinnerRelease?.total}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default MediaShareListModule;
