/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import ProfileEditModule from "../../modules/User/Profile/ProfileEditModule";
import { titlePage } from "../../helpers/titleHead";

import { getProvincesAction } from "../../../redux/action/utilsAction";

const ProfileEdit = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProvincesAction());
    titlePage({
      title: "IRIS - Edit Profile",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardUserLayout>
      <div className="p-d-flex p-justify-center p-mt-5 profile-module">
        <div className="p-grid p-justify-center">
          <ProfileEditModule />
        </div>
      </div>
    </DashboardUserLayout>
  );
};

export default ProfileEdit;
