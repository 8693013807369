/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OtpInput from "react-otp-input";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../../helpers/phoneNumber";
import {
  checkUpdatePhoneAction,
  updatePhoneAction,
  checkPhoneAction,
  editWriterProfileAction,
} from "../../../../../redux/action/authAction";
import ButtonLoader from "../../../../components/button/ButtonLoader";

const DialogPhone = (props) => {
  const dispatch = useDispatch();
  const [phoneState, setPhoneState] = useState({
    value: "",
    error: null,
  });
  const [otpState, setOtpState] = useState({
    value: "",
    error: null,
  });
  const [layout, setLayout] = useState("phone");
  const [timeLeft, setTimeLeft] = useState(61);

  const {
    users,
    dataCheckPhone,
    loadingCheckPhone,
    loadingCheckUpdatePhone,
    successCheckUpdatePhone,
    verificationPhoneNumber,
    loadingUpdatePhone,
    successUpdatePhone,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (props.visible) {
      if (dataCheckPhone !== null && !dataCheckPhone?.exists) {
        dispatch(
          checkUpdatePhoneAction(
            window.recaptchaVerifier,
            formattedPhoneNumber(phoneState.value)
          )
        );
      }
      if (dataCheckPhone !== null && dataCheckPhone?.exists) {
        setPhoneState({ ...phoneState, error: "Nomor telepon sudah terdaftar, silakan gunakan nomor lainnya" });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCheckPhone, props.visible]);

  useEffect(() => {
    if (successCheckUpdatePhone) {
      setLayout("otp");
    } else {
      setLayout("phone")
    }
  }, [successCheckUpdatePhone]);

  useEffect(() => {
    if (successUpdatePhone) {
      dispatch(
        editWriterProfileAction({
          phone: formattedPhoneNumber(phoneState.value),
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdatePhone]);

  useEffect(() => {
    if (layout === "otp") {
      const intervalId = setInterval(() => {
        timeLeft > 1 && setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, timeLeft]);

  const submitPhone = () => {
    if (!formPhoneNumber(phoneState?.value)) {
      setPhoneState({
        ...phoneState,
        error: "Nomor Telepon tidak valid",
      });
    } else if (phoneState?.value?.length < 1) {
      setPhoneState({
        ...phoneState,
        error: "Nomor Telepon harus diisi",
      });
    } else {
      dispatch(
        checkPhoneAction({ phone: formattedPhoneNumber(phoneState.value) })
      );
    }
  };

  const resendOtpHandler = (e) => {
    e.preventDefault();
    setTimeLeft(61);
    dispatch(
      checkUpdatePhoneAction(
        window.recaptchaVerifier,
        formattedPhoneNumber(phoneState.value)
      )
    );
  };

  const submitOtp = () => {
    dispatch(updatePhoneAction(verificationPhoneNumber, otpState.value));
  };

  return (
    <div className="normal-dialog no-border-footer rounded">
      <Dialog
        header="Ubah Nomor Telepon"
        visible={props.visible}
        className="update-setting-dialog"
        onHide={props.closeDialog}
      >
        {layout === "phone" ? (
          <>
            <div className="form-setting p-px-4">
              <div className="p-field">
                <label className="p-d-block">Nomor Telepon Sekarang</label>
                <InputText
                  placeholder="Nomor Telepon Sekarang"
                  className="p-d-block"
                  value={users?.user?.phone?.replace("+62", "0")}
                  disabled
                />
              </div>
              <div className="p-field">
                <label className="p-d-block">Nomor Telepon Baru</label>
                <InputText
                  placeholder="Nomor Telepon Baru"
                  className={`p-d-block ${phoneState?.error && "p-invalid"}`}
                  value={phoneState.value}
                  onChange={(e) =>
                    setPhoneState({ value: e.target.value, error: null })
                  }
                />
                {phoneState.error && (
                  <smal className="p-error p-d-block p-text-right">
                    {phoneState.error}
                  </smal>
                )}
              </div>
            </div>
            <div id="grecaptcha-button" />
            <div className="p-d-flex p-jc-end p-dialog-footer phone">
              <Button
                label="BATAL"
                onClick={props.closeDialog}
                className="p-button-secondary p-button-outlined p-button-rounded mr-3"
              />
              {
                loadingCheckPhone || loadingCheckUpdatePhone ?
                  <ButtonLoader /> :
                  <Button
                    label="UBAH"
                    onClick={() => submitPhone()}
                    className="p-button-primary p-button p-button-rounded"
                    disabled={loadingCheckPhone || loadingCheckUpdatePhone}
                  />
              }
            </div>
          </>
        ) : (
          <div className="form-setting p-px-4">
            <div className="otp-wrapper text-center">
              <p>
                Silakan masukan kode OTP yang kami kirimkan ke nomor{" "}
                <strong>
                  {phoneState?.value?.length > 0 &&
                    "*".repeat(phoneState?.value?.length - 4) +
                    phoneState?.value
                      ?.replace(/-/g, "")
                      .replace(/_/g, "")
                      .slice(-3)}
                </strong>
              </p>
              <form onSubmit={submitOtp}>
                <div className="p-field otp-code">
                  <OtpInput
                    value={otpState?.value}
                    onChange={(value) =>
                      setOtpState({ value: value, error: null })
                    }
                    numInputs={6}
                    containerStyle="otp-wrapp"
                    inputStyle="p-inputtext"
                    isInputNum
                  />
                </div>
                <div className="p-field">
                  <Button
                    label="Konfirmasi"
                    className="p-button-primary p-button-rounded"
                    onClick={() => submitOtp()}
                    disabled={loadingUpdatePhone}
                    type="submit"
                  />
                  <div className="p-py-3 p-text-center">
                    <span className="time">
                      Tersisa <strong>{timeLeft} detik</strong> lagi
                    </span>
                  </div>
                </div>
                <div id="grecaptcha-button" />
                <div className="footer-otp">
                  <p>
                    Tidak menerima kode OTP?{" "}
                    <a href="#" onClick={resendOtpHandler}>
                      Kirim Ulang
                    </a>
                  </p>
                </div>
              </form>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default DialogPhone;
