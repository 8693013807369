import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import OtpInput from "react-otp-input";
import firebase from "firebase/app";
import { useHistory } from "react-router-dom";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getStorage } from "../../../../../_metronic/_helpers/LocalStorageHelpers";

import {
  checkPhoneAction,
  loginVerifyOtpAction,
  phoneLoginAction,
} from "../../../../../redux/action/authAction";
import ButtonLoader from "../../../../components/button/ButtonLoader";
import DialogAlert from "../../../../components/dialog/DialogAlert";

const OTPConfirmation = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [layout, setLayout] = useState("alert");
  const [otpState, setOtpState] = useState({
    value: "",
    error: null,
  });
  const [timeLeft, setTimeLeft] = useState(61);

  const {
    users,
    loadingPhoneLogin,
    dataCheckPhone,
    loadingCheckUpdatePhone,
    verificationOtp,
    successPhoneLogin,
    successOtpLogin,
    errOtpLogin,
    loadingOtpLogin,
    loadingCheckPhone,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successOtpLogin) {
      props.closeDialog();
      setOtpState({ ...otpState, value: "" });
    }
    if (errOtpLogin) {
      setOtpState({
        ...otpState,
        error: "OTP yang anda masukan tidak valid atau kedaluwarsa",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successOtpLogin, errOtpLogin]);

  useEffect(() => {
    if (dataCheckPhone !== null && dataCheckPhone?.exists) {
      const data = { phone_number: users?.user?.phone.replace("+62", "0") };
      dispatch(phoneLoginAction(window.recaptchaVerifier, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCheckPhone]);

  useEffect(() => {
    if (successPhoneLogin && verificationOtp !== null) {
      setLayout("otp");
    } else {
      setLayout("alert");
    }
  }, [successPhoneLogin, verificationOtp]);

  useEffect(() => {
    if (props.visible && !window.recaptchaVerifier) {
      window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
        "grecaptcha-button",
        {
          size: "invisible",
        }
      );
    }
  }, [props.visible]);

  useEffect(() => {
    if (layout === "otp") {
      const intervalId = setInterval(() => {
        timeLeft > 0 && setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [timeLeft, layout]);

  const alertAction = () => {
    const data = { phone: users?.user?.phone };
    dispatch(checkPhoneAction(data));
  };

  const resendOtpHandler = (e) => {
    e.preventDefault();
    setTimeLeft(61);
    const data = { phone_number: users?.user?.phone.replace("+62", "0") };
    dispatch(phoneLoginAction(window.recaptchaVerifier, data));
  };

  const submitOtp = () => {
    const dataInput = { phone_number: users?.user.phone?.replace("+62", "0") };
    dispatch(loginVerifyOtpAction(verificationOtp, otpState.value, dataInput));
  };

  return (
    <>
      <div id="grecaptcha-button" />
      {layout === "alert" ? (
        <DialogAlert
          type={"warning"}
          visible={props.visible}
          closeDialog={() => history.push("/settings")}
          title={"Konfirmasi Nomor HP Anda"}
          text={"Kami akan mengirimkan kode OTP ke nomor handphone anda"}
          actionDialog={
            getStorage("login-mode") === "email"
              ? props.closeDialog
              : () => alertAction()
          }
          actionText="KIRIM OTP"
          loading={
            loadingPhoneLogin || loadingCheckUpdatePhone || loadingCheckPhone
          }
        />
      ) : (
        <div className="normal-dialog no-border-footer rounded">
          <Dialog
            header="Konfirmasi Nomor HP Anda"
            visible={props.visible}
            className="update-setting-dialog confirmation"
            onHide={props.closeDialog}
            closable={false}
          >
            <div className="form-setting p-px-4">
              <div className="otp-wrapper text-center">
                <p>
                  Silakan masukan kode OTP yang kami kirimkan ke nomor{" "}
                  <strong>
                    {users?.user?.phone?.length > 0 &&
                      "*".repeat(users?.user?.phone?.length - 4) +
                        users?.user?.phone
                          ?.replace(/-/g, "")
                          .replace(/_/g, "")
                          .slice(-3)}
                  </strong>{" "}
                  untuk melanjutkan
                </p>
                <form onSubmit={submitOtp}>
                  <div className="p-field otp-code">
                    <OtpInput
                      value={otpState?.value}
                      onChange={(value) =>
                        setOtpState({ value: value, error: null })
                      }
                      numInputs={6}
                      containerStyle="otp-wrapp"
                      inputStyle="p-inputtext"
                      isInputNum
                    />

                    <smal className="p-error p-d-block p-text-right">
                      {otpState.error}
                    </smal>
                  </div>

                  <div className="p-field">
                    {loadingOtpLogin ? (
                      <ButtonLoader />
                    ) : (
                      <Button
                        label="Konfirmasi"
                        className="p-button-primary p-button-rounded"
                        onClick={() => submitOtp()}
                        disabled={loadingOtpLogin}
                        type="submit"
                      />
                    )}
                    <div className="p-py-3 p-text-center">
                      <span className="time">
                        Tersisa <strong>{timeLeft} detik</strong> lagi
                      </span>
                    </div>
                  </div>
                  <div id="grecaptcha-button" />
                  <div className="footer-otp">
                    {timeLeft === 0 && (
                      <p>
                        Tidak menerima kode OTP?{" "}
                        {/* eslint-disable-next-line  jsx-a11y/anchor-is-valid*/}
                        <a href="#" onClick={resendOtpHandler}>
                          Kirim Ulang
                        </a>
                      </p>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </Dialog>
        </div>
      )}
    </>
  );
};

export default OTPConfirmation;
