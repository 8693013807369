import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";
import { getPICListAction } from "../../../../../redux/action/clientPicAction";

const FilterPIC = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
      };

      history.push(`/client/pic-list?search=${search || ""}&page=1`);
      dispatch(getPICListAction(params));
    }, 1000)
  ).current;

  return (
    <div className="pic-filter">
      <div className="filter">
        <div className="filter-wrapp list-company">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <small id="search-help" className="p-d-block">
            Masukkan Nama atau Email Tim
          </small>
        </div>
      </div>
      <Link
        to="/client/pic/add-pic"
        className="p-button p-button-primary button-link"
      >
        Tambah Tim
      </Link>
    </div>
  );
};

export default FilterPIC;
