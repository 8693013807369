import { call, put } from "redux-saga/effects";
import {
  GET_ORDER_DETAIL_FAILURE,
  GET_ORDER_DETAIL_SUCCESS,
  GET_ORDER_FAILURE,
  GET_ORDER_SUCCESS,
  ORDER_CLIENT_DETAIL_FAILURE,
  ORDER_CLIENT_DETAIL_SUCCESS,
  POST_ORDER_BRIEF_FAILURE,
  POST_ORDER_BRIEF_SUCCESS,
  POST_ORDER_CLIENT_FAILURE,
  POST_ORDER_CLIENT_SUCCESS,
  UPDATE_ORDER_FAILURE,
  UPDATE_ORDER_SUCCESS,
  GET_PROJECT_CLIENT_FAILURE,
  GET_PROJECT_CLIENT_SUCCESS,
  POST_SAVE_CLIENT_SUCCESS,
  POST_SAVE_CLIENT_FAILURE,
  GET_SAVE_CLIENT_SUCCESS,
  GET_SAVE_CLIENT_FAILURE,
  GET_ORDER_SAVE_DETAIL_SUCCESS,
  GET_ORDER_SAVE_DETAIL_FAILURE,
  ORDER_CLIENT_SAVE_DETAIL_FAILURE,
  ORDER_CLIENT_SAVE_DETAIL_SUCCESS,
} from "../constant/orderConstant";
import { getToken } from "../service/firebase/auth";
import { getDetailProject, getDraftProject } from "../service/firebase/project";
import { getOrderDetail, getOrderSaveDetail, updateOrder } from "../service/firebase/order";
import { getProjectClient, getProjectSaveClient, postProjectSave } from "../service/api/projectsApi";
import {
  postClientBrief,
  postClientOrder,
  getOrders,
} from "../service/api/orderApi";

export function* getOrderSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getOrders, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_ORDER_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_ORDER_FAILURE, data });
  }
}

export function* getOrderDetailSaga(action) {
  try {
    const snapshot = yield call(getDetailProject, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_ORDER_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_ORDER_DETAIL_FAILURE, err });
  }
}

export function* getOrderSaveDetailSaga(action) {
  try {
    const snapshot = yield call(getDraftProject, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_ORDER_SAVE_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_ORDER_SAVE_DETAIL_FAILURE, err });
  }
}

export function* postOrderClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(postClientOrder, token, action.data);
  if (ok) {
    yield put({ type: POST_ORDER_CLIENT_SUCCESS, data });
  } else {
    yield put({ type: POST_ORDER_CLIENT_FAILURE, data });
  }
}

export function* postProjectSaveClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(postProjectSave, token, action.data, action.id);
  if (ok) {
    yield put({ type: POST_SAVE_CLIENT_SUCCESS, data });
  } else {
    yield put({ type: POST_SAVE_CLIENT_FAILURE, data });
  }
}

export function* getOrderDetailClientSaga(action) {
  try {
    const snapshot = yield call(getOrderDetail, action.id);
    const data = snapshot.data();
    yield put({
      type: ORDER_CLIENT_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: ORDER_CLIENT_DETAIL_FAILURE, err });
  }
}

export function* getOrderSaveDetailClientSaga(action) {
  try {
    const snapshot = yield call(getOrderSaveDetail, action.id);
    const data = snapshot.data();
    yield put({
      type: ORDER_CLIENT_SAVE_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: ORDER_CLIENT_SAVE_DETAIL_FAILURE, err });
  }
}

export function* updateOrderSaga(action) {
  try {
    yield call(updateOrder, action.id, action.data);
    yield put({ type: UPDATE_ORDER_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_ORDER_FAILURE, err });
  }
}

export function* postOrderBriefSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    postClientBrief,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: POST_ORDER_BRIEF_SUCCESS, data });
  } else {
    yield put({ type: POST_ORDER_BRIEF_FAILURE, data });
  }
}

export function* getProjectClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectClient, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_PROJECT_CLIENT_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_PROJECT_CLIENT_FAILURE, data });
  }
}

export function* getSaveProjectClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectSaveClient, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_SAVE_CLIENT_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_SAVE_CLIENT_FAILURE, data });
  }
}
