import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import ChatBoxPM from "../../../modules/Chat/ChatPMModule";

import { titlePage } from "../../../helpers/titleHead";

import {
  getMyProjectAction,
  getDetailProjectAction,
  getProjectUserAction,
} from "../../../../redux/action/projectAction";

const ChatPMPage = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();

  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMyProjectAction(users?.uid));
    dispatch(getProjectUserAction(QueryParams.projectId));
    dispatch(getDetailProjectAction(QueryParams.projectId));

    titlePage({
      title: "IRIS - Message",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Project">
      <ChatBoxPM projectIdUrl={QueryParams.projectId} />
    </DashboardLayout>
  );
};

export default ChatPMPage;
