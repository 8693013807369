/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Form from "react-bootstrap/Form";

import { Button } from "primereact/button";

import ButtonAdminLoader from "../../../components/button/ButtonAdminLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";

import { formEmail } from "../../../helpers/email";
import {
  loginEmailAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";

const LoginAdminModule = (props) => {
  const dispatch = useDispatch();
  const [emailState, setEmailState] = useState({ value: "", error: null });
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: null,
  });
  const [dialogAlert, setDialogAlert] = useState({
    dailog: false,
    type: null,
    title: null,
    text: null,
  });

  const { loadingEmailLogin, errEmailLogin } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (
      errEmailLogin !== null &&
      errEmailLogin.code === "auth/user-not-found"
    ) {
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "Gagal Masuk",
        text: "Akun belum terdaftar",
      });
    }

    if (errEmailLogin !== null && errEmailLogin.code === "auth/user-disabled") {
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "Gagal Masuk",
        text: "Akun anda dinonaktifkan",
      });
    }

    if (
      errEmailLogin !== null &&
      errEmailLogin.code === "auth/wrong-password"
    ) {
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "Gagal Masuk",
        text: "Email atau kata sandi anda salah",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errEmailLogin]);

  const closeDialog = () => {
    setDialogAlert({
      dailog: false,
      type: null,
      title: null,
      text: null,
    });
    dispatch(resetStateAuthAction());
  };

  const submitData = (e) => {
    e.preventDefault();

    if (!formEmail(emailState.value)) {
      setEmailState({ ...emailState, error: "Email Tidak Valid" });
    } else {
      dispatch(loginEmailAction(emailState.value, passwordState.value));
    }
  };

  return (
    <>
      <div className="login-form text-center">
        <h2>Masuk</h2>
        <Form onSubmit={submitData}>
          <Form.Group controlId="formBasicEmail" className="text-left">
            <Form.Label>Email</Form.Label>
            <Form.Control
              onChange={(e) =>
                setEmailState({ value: e.target.value, error: null })
              }
              type="email"
              value={emailState.value}
              isInvalid={emailState.error !== null}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {emailState.error}
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formBasicPassword" className="text-left">
            <Form.Label>Kata Sandi</Form.Label>
            <Form.Control
              type="password"
              onChange={(e) =>
                setPasswordState({ value: e.target.value, error: null })
              }
              value={passwordState.value}
              isInvalid={passwordState.error !== null}
              autoComplete="off"
            />
            <Form.Control.Feedback type="invalid">
              {passwordState.error}
            </Form.Control.Feedback>
          </Form.Group>

          <div className="p-mt-3 p-mb-3 text-right">
            <Link to="/admin/forgot-password" className="p-text-primary">
              Lupa Kata Sandi?
            </Link>
          </div>

          {loadingEmailLogin ? (
            <ButtonAdminLoader />
          ) : (
            <Button className="p-button-primary sm-radius" type="submit">
              Masuk
            </Button>
          )}
        </Form>
      </div>
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
};

export default LoginAdminModule;
