/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/id";
import { Link, useHistory } from "react-router-dom";

import { Button } from "primereact/button";

import ListLoader from "../../../components/loading/listLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";

import {
  deleteUserAction,
  updateStatusApplicantAction,
  resetStateUserAction,
} from "../../../../redux/action/userAction";
import { saveLastLocation } from "../../../../_metronic/_helpers";

const DEFAULT_IMAGE = process.env.REACT_APP_IMAGE_USER;

const UserDetailModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState({
    dialog: false,
    title: "",
    text: "",
  });

  const {
    dataDetailUser,
    loadingDetailUser,
    loadingDeleteUser,
    successDeleteUser,
    loadingUpdateStatusApplicant,
    successUpdateStatusApplicant,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (successDeleteUser) {
      setDialogWarning(false);
      setDialogSuccess({
        dialog: true,
        title: "Berhasil Menghapus Data",
        text: "Data user telah dihapus",
      });
    }
  }, [successDeleteUser]);

  useEffect(() => {
    if (successUpdateStatusApplicant) {
      setDialogSuccess({
        dialog: true,
        title: "Berhasil Mengubah Status",
        text: "Status pengguna telah diubah",
      });
    }
  }, [successUpdateStatusApplicant]);

  const closeSuccess = () => {
    dispatch(resetStateUserAction());
    setDialogSuccess({
      dialog: false,
      title: "",
      text: "",
    });
    if (successUpdateStatusApplicant) {
      history.push("/admin/pm/list-user/applicants");
    } else {
      history.push("/admin/pm/list-user/verified");
    }
  };

  const deleteUser = (e) => {
    e.preventDefault();
    dispatch(deleteUserAction(props.userId));
  };

  const updateStatus = (data) => {
    dispatch(updateStatusApplicantAction(props.userId, { status: data }));
  };

  return (
    <div className="card card-custom-detail w-full">
      <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
        <div className="d-flex">
          <a href="#" onClick={() => history.goBack()} className="button-back">
            <span className="icon-back"></span>
          </a>
          <h3 className="card-title align-items-start flex-column ml-3">
            <span className="card-label font-weight-bolder text-dark">
              {dataDetailUser?.name}
            </span>
          </h3>
        </div>
        {dataDetailUser?.type !== "client" && (
          <div className="p-d-flex action-span">
            <button
              onClick={() => setDialogWarning(true)}
              disabled={dataDetailUser?.nProjectJoined > 0 || loadingDeleteUser}
              className="btn px-0 py-o mr-1 text-primary"
            >
              <strong className="text-danger">Hapus</strong>
            </button>
            <Link
              to={`/admin/pm/edit-user/${props.userId}`}
              className="btn px-0 py-o ml-3 d-flex align-items-center text-primary"
              onClick={() => saveLastLocation(history.location)}
            >
              <strong>Edit</strong>
            </Link>
          </div>
        )}
      </div>
      {loadingDetailUser ? (
        <ListLoader />
      ) : (
        <div className="card-body pt-0 pb-3">
          <div className="d-flex justify-content-between">
            <div className="user-info-box applicant">
              <div className="pict">
                <img
                  src={
                    !dataDetailUser?.photo
                      ? DEFAULT_IMAGE
                      : dataDetailUser?.photo
                  }
                  alt="photo-profile"
                />
              </div>
              <div className="desc-info">
                <ul>
                  <li>
                    <span className="icon-line-message" />
                    {dataDetailUser?.email}
                  </li>
                  <li>
                    <span className="icon-line-call" />
                    {dataDetailUser?.phone || "-"}
                  </li>
                  {dataDetailUser?.type !== "client" ? (
                    <li>
                      <span className="icon-line-location" />
                      {`${dataDetailUser?.domicile?.address}, ${dataDetailUser?.domicile?.cities}, ${dataDetailUser?.domicile?.province}`}
                    </li>
                  ) : (
                    <li>
                      <span className="icon-line-location" />
                      {`${dataDetailUser?.companyAddress}`}
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div className="user-attr">
              <div className="attr-info text-right">
                <p>
                  Tanggal Registrasi:{" "}
                  {dayjs(dataDetailUser?.createdAt?.toDate())
                    .locale("id")
                    .format("DD MMMM YYYY")}
                </p>
                {dataDetailUser?.type !== "client" && (
                  <span className={`badge wide ${dataDetailUser?.status}`}>
                    {dataDetailUser?.status}
                  </span>
                )}
              </div>
            </div>
          </div>
          {dataDetailUser?.type !== "client" ? (
            <>
              <div className="biography p-mt-5">
                <h3>Biografi</h3>
                <p>{dataDetailUser?.biography || "Biografi belum diatur"}</p>
              </div>
              {dataDetailUser?.portofolio?.map((item) => (
                <div className="p-mt-5 p-py-5 portfolio-list" key={item?.title}>
                  <div className="p-d-flex p-jc-between">
                    <div className="p-mb-3">
                      <label className="p-d-block folio-name">
                        {item?.title}
                      </label>
                      <span className="folio-year">{item?.year}</span>
                    </div>
                    <a
                      href={item?.linkPortofolio || item?.portofolioLink}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      className="btn-wide p-button p-button-outlined p-button-rounded sm-rounded text-uppercase p-px-3"
                    >
                      Lihat Portfolio
                    </a>
                  </div>
                </div>
              ))}

              {(dataDetailUser?.status === "waiting" ||
                dataDetailUser?.status === "rejected") && (
                <div className="footer-action">
                  <Button
                    label="Tolak"
                    className="p-button-secondary btn-wide p-button-outlined p-button-rounded sm-rounded text-uppercase p-px-3"
                    disabled={
                      dataDetailUser?.status === "rejected" ||
                      loadingUpdateStatusApplicant
                    }
                    onClick={() => updateStatus("rejected")}
                  />
                  <Button
                    label="Terima"
                    className="btn-wide p-button-primary p-button-rounded sm-rounded text-uppercase p-px-3"
                    onClick={() => updateStatus("verified")}
                    disabled={loadingUpdateStatusApplicant}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="biography p-mt-5">
              <h3>Detail Profil</h3>
              <p>
                {`Posisi sebagai ${dataDetailUser?.position ||
                  "-"} di Perusahaan ${dataDetailUser?.companyName}`}{" "}
              </p>
            </div>
          )}
        </div>
      )}
      <DialogAlert
        type={"warning"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={`Hapus ${dataDetailUser?.name || dataDetailUser?.email}?`}
        text={"Data yang dihapus tidak dapat dikembalikan"}
        actionDialog={deleteUser}
        loading={loadingDeleteUser}
        actionText={"HAPUS"}
      />

      <DialogAlert
        type={"success"}
        visible={dialogSuccess.dialog}
        closeDialog={() => closeSuccess()}
        title={dialogSuccess.title}
        text={dialogSuccess.text}
      />
    </div>
  );
};

export default UserDetailModule;
