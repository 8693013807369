/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import ProjectListModule from "../../modules/User/Project/ProjectListModule";

import { getProjectWriterAction } from "../../../redux/action/projectAction";
import { titlePage } from "../../helpers/titleHead";

const ProjectPage = (props) => {
  const dispatch = useDispatch();

  const { projectWriterParams } = useSelector((state) => state.project);

  useEffect(() => {
    const params = {
      ...projectWriterParams,
      orderDir: "desc",
      perpage: 10,
      category: "public",
    };
    dispatch(getProjectWriterAction(params));

    titlePage({
      title: "IRIS - Daftar Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardUserLayout>
      <div className="p-d-flex p-justify-center">
        <ProjectListModule />
      </div>
    </DashboardUserLayout>
  );
};

export default ProjectPage;
