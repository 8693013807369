import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import WithdrawModule from "../../modules/User/Withdraw/WithdrawModule";
import { titlePage } from "../../helpers/titleHead";

const DashboardUser = () => {
  const history = useHistory();
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    titlePage({
      title: "IRIS - Withdraw",
    });
  }, []);

  useEffect(() => {
    if (users && users?.user?.balance < 500000) {
      history.push("/balance");
    } else if (users && !users?.user?.payoutStatus) {
      history.push("/balance");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <DashboardUserLayout>
      <WithdrawModule />
    </DashboardUserLayout>
  );
};

export default DashboardUser;
