import React from "react";

import CampaignImage from "../../../assets/img/campaign-image.svg";

import ButtonLink from "../../../components/button/ButtonLink";

import CreateCampaignModule from "../CreateCampaign/CreateCampaignModule.js";

function CampaignModule() {
  return (
    <div className="p-d-flex p-jc-center">
      <div className="container campaign-page col-lg-10 col-md-12 col-sm-12">
        <div className="top-section">
          <div className="text-section">
            <h1>Tentang IRIS</h1>
            <p>
              Iris & Co helps brands and MSMEs enter and expand their business
              in the Indonesian market. We engage with customers and
              stakeholders through digital communication to drive the
              realization of business targets. As a digital PR and content
              creation company, Iris & Co focuses on communication, marketing,
              and digital technology services for companies and MSMEs around the
              world.
            </p>
            <ButtonLink link="/create-account" type="rounded" label="Daftar" />
          </div>
          <div className="image-section">
            <img src={CampaignImage} alt="campaign_image" />
          </div>
        </div>
        <div className="bottom-section">
          <div className="text-headline">
            <h3>headline generator</h3>
            <p>Masukkan kata kunci untuk membuat headline</p>
          </div>

          <div>
            <CreateCampaignModule />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CampaignModule;
