/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";

import {
  TitleLoad,
  DetailPageLoad,
} from "../../../components/loading/loadingContent";

import iconDownload from "../../../assets/img/download-blue.svg";
import CoinImage from "../../../assets/img/icon-coin.svg";
import ClockImage from "../../../assets/img/icon-clock.svg";
import WinnerImage from "../../../assets/img/winner.svg";
import UsersImage from "../../../assets/img/icon-users.svg";
import firebase from "../../../../redux/service/firebase/firebase";

const DetailProjectFinanceModule = (props) => {
  const history = useHistory();
  const firestore = firebase.firestore();
  const [projectList, setProjectList] = useState([]);

  const { dataGetDetailProject, loadingGetDetailProject } = useSelector(
    (state) => state.project
  );

  const pdfRef = createRef();

  const statusOrder = (status) => {
    switch (status) {
      case "published":
        return "1";
      case "winner":
        return "2";
      case "approved":
        return "3";
      case "sent":
        return "4";
      case "revision":
        return "5";
      case "ongoing":
        return "6";
      case "reject":
        return "7";
      case "removed":
        return "8";
      default:
        return "9";
    }
  };

  const getListProject = async () => {
    return await firestore
      .collection("writerProjects")
      .where("projectId", "==", props.projectId)
      .orderBy("updatedAt", "desc")
      .onSnapshot(
        (docs) => {
          const temp = [];
          docs.forEach((doc) =>
            temp.push({
              ...doc.data(),
              id: doc.id,
            })
          );
          // eslint-disable-next-line no-unused-expressions
          const sortedList = temp
            ?.map((users) => {
              return {
                ...users,
                statusOrder: statusOrder(users?.status),
              };
            })
            .sort((a, b) => a?.statusOrder - b?.statusOrder);
          setProjectList(sortedList);
        },
        (error) => {
          console.error("error", error);
        }
      );
  };
  useEffect(() => {
    if (props.projectId) {
      getListProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId]);

  let categoryName;
  if (dataGetDetailProject?.type === "pr") {
    categoryName = "Press Release";
  } else if (dataGetDetailProject?.type === "distribution_media") {
    categoryName = "Media Distribusi";
  } else {
    categoryName = "Artikel";
  }

  const winnerList = projectList.filter(
    (item) => item.status === "winner" || item.status === "published"
  );
  const keywords = dataGetDetailProject?.searchKeyword?.split(",");

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-header">
          {loadingGetDetailProject ? (
            <TitleLoad />
          ) : (
            <>
              <div className="card-title">
                <div
                  className="card-title-back"
                  onClick={() => history.goBack()}
                >
                  <span className="icon-back"></span>
                </div>
                <h2 className="card-title-text">Detail Project</h2>
              </div>
            </>
          )}
        </div>
        <div className="card-body px-0">
          {loadingGetDetailProject ? (
            <div className="project-info-wrapp">
              <DetailPageLoad />
            </div>
          ) : (
            <>
              <div className="project-detail-top">
                <div className="project-info-wrapp">
                  <div className="project-image">
                    <img src={dataGetDetailProject?.thumbnail} alt="tumbnail" />
                  </div>
                  <div className="project-info">
                    <div className="info-row-title">
                      <h3>{dataGetDetailProject?.title}</h3>
                      <div className="d-flex align-items-center">
                        <h6>
                          Nomor Pekerjaan: {dataGetDetailProject?.jobNumber}
                        </h6>
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={CoinImage} alt="" />
                        <NumberFormat
                          prefix={"Rp "}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetDetailProject?.price}
                          renderText={(value) => <p>{value}</p>}
                        />
                      </div>
                      <div className="info">
                        <img src={ClockImage} alt="" />
                        <p>
                          Deadline{" "}
                          {dayjs(dataGetDetailProject?.deadlineDate?.toDate())
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={UsersImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetDetailProject?.nWriter}
                          renderText={(value) => (
                            <p>
                              <strong> {value} </strong> Penulis Bergabung
                            </p>
                          )}
                        />
                      </div>
                      <div className="info">
                        <img src={WinnerImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={
                            dataGetDetailProject?.totalWinner
                              ? dataGetDetailProject?.totalWinner
                              : 0
                          }
                          renderText={(value) => <p>{value}/</p>}
                        />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetDetailProject?.winnerQuota}
                          renderText={(value) => (
                            <p>
                              <strong>{value}</strong> Tulisan Terpilih
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="project-description pb-0">
                    <p>{dataGetDetailProject?.description}</p>
                  </div>
                  {dataGetDetailProject?.briefUrl && (
                    <div className="project-action">
                      <div className="action-left">
                        <a
                          className="btn-download brief"
                          href={dataGetDetailProject?.briefUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={iconDownload} />
                          Unduh Ringkasan
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="project-detail-bottom">
                <div className="tab-project" ref={pdfRef}>
                  <div className="accordion-project-wrapper pt-0">
                    <div className="row">
                      <div className="col-12 col-sm-12">
                        <h3>Pemenang Project</h3>
                      </div>
                      <div className="col-12 col-sm-12">
                        {winnerList?.length > 0 ? (
                          <ol className="winner-list">
                            {winnerList?.map((item) => (
                              <li key={item?.id}>{item?.userData?.name}</li>
                            ))}
                          </ol>
                        ) : (
                          <p className="empty-winner">
                            Belum ada pemenang di project ini
                          </p>
                        )}
                      </div>
                      <div className="col-12 col-sm-12">
                        <hr />
                      </div>
                      <div className="col-12 col-sm-12">
                        <h3>Informasi Project</h3>
                      </div>
                      <div className="col-12 col-sm-4">
                        <h6>Jenis Project</h6>
                        <div className="box-text">{categoryName}</div>
                      </div>
                      <div className="col-12 col-sm-4">
                        <h6>Kategori Project</h6>
                        <div className="box-text">
                          {dataGetDetailProject?.category}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12">
                        <hr />
                      </div>
                      <div className="col-12 col-sm-12">
                        <h3>Informasi Klien</h3>
                      </div>
                      <div className="col-12 col-sm-12">
                        <h2>{dataGetDetailProject?.clientCompany?.name}</h2>
                      </div>
                      <div className="col-12 col-sm-12">
                        <p>
                          {
                            dataGetDetailProject?.clientCompany
                              ?.companyBackground
                          }
                        </p>
                      </div>
                      <div className="col-12 col-sm-6">
                        <h6>Bidang Usaha</h6>
                        <div className="box-text">
                          {dataGetDetailProject?.clientCompany?.businessField}
                        </div>
                      </div>
                      <div className="col-12 col-sm-6">
                        <h6>Kata Kunci Pencarian</h6>
                        <div className="d-flex flex-wrap">
                          {keywords?.map((item) => (
                            <div className="box-text list" key={item}>
                              {item}
                            </div>
                          ))}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12">
                        <hr />
                      </div>
                      <div className="col-12 col-sm-12">
                        <h3>Target Pembaca</h3>
                      </div>
                      <div className="col-12 col-sm-4">
                        <h6>Usia</h6>
                        <div className="box-text">
                          {dataGetDetailProject?.target?.age}
                        </div>
                      </div>
                      <div className="col-12 col-sm-4">
                        <h6>Jenis kelamin</h6>
                        <div className="box-text">
                          {dataGetDetailProject?.target?.sex}
                        </div>
                      </div>
                      <div className="col-12 col-sm-4">
                        <h6>Stakeholder</h6>
                        <div className="d-flex flex-wrap">
                          {dataGetDetailProject?.target?.stakeholders?.map(
                            (item) => (
                              <div className="box-text list" key={item}>
                                {item}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="col-12 col-sm-12">
                        <hr />
                      </div>
                      <div className="col-12 col-sm-12">
                        <h3>Ruang Lingkup Rilis</h3>
                      </div>
                      {dataGetDetailProject?.type === "pr" && (
                        <>
                          <div className="col-12 col-sm-4">
                            <h6>Kategori Rilis</h6>
                            <div className="box-text mb-5">
                              {dataGetDetailProject?.releaseCategory}
                            </div>
                          </div>
                        </>
                      )}

                      <div className="col-12 col-sm-12">
                        <h6>Objek Komunitas yang Ingin Dicapai</h6>
                        <p>{dataGetDetailProject?.objective}</p>
                        <h6>Atribut Quote dan Pesan yang Ingin Disampaikan</h6>
                        <p>{dataGetDetailProject?.attribution}</p>
                        <h6>Hal yang Diperhatikan dalam Penulisan Release</h6>
                        <p>{dataGetDetailProject?.releaseNote}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default DetailProjectFinanceModule;
