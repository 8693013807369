/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";

import { TabView, TabPanel } from "primereact/tabview";

import UsersImage from "../../../assets/img/icon-users.svg";
import ClockImage from "../../../assets/img/icon-clock.svg";
import WinnerImage from "../../../assets/img/winner.svg";
import ImgInvite from "../../../assets/img/invite_writer.svg";

import ButtonIconLeft from "../../../components/button/ButtonIconLeft";
import DialogAlert from "../../../components/dialog/DialogAlert";
import StatusBadge from "../../../components/badge/StatusBadge";

import {
  TitleLoad,
  DetailPageLoad,
} from "../../../components/loading/loadingContent";

import { resetStateProjectAction } from "../../../../redux/action/projectAction";
import { resetStateUserAction } from "../../../../redux/action/userAction";
import ButtonDefault from "../../../components/button/ButtonDefault";
import { getToken } from "../../../../redux/service/firebase/auth";

const DetailMyReleaseModuleDraft = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const QueryParams = useParams();

  const [isLoading, setLoading] = useState(false)
  const [dialogSuccessSave, setDialogSuccessSave] = useState(false);
  const [dialogWarning, setDialogWarning] = useState(false);

  const [openAlert, setOpenAlert] = useState({
    dialog: false,
    type: null,
    title: null,
    desc: null,
  });

  const { successAssignProject } = useSelector((state) => state.project);
  const { users } = useSelector((state) => state.auth);

  const {
    loadingGetProjectDetailClient,
    dataGetProjectDetailClient
  } = useSelector((state) => state.project);

  const { successInviteUser, errInviteUser } = useSelector(
    (state) => state.user
  );
  const pdfRef = createRef();

  let categoryName;
  if (dataGetProjectDetailClient?.type === "pr") {
    categoryName = "Press Release";
  } else if (dataGetProjectDetailClient?.type === "distribution_media") {
    categoryName = "Media Distribusi";
  } else {
    categoryName = "Artikel";
  }

  useEffect(() => {
    if (successAssignProject) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Assign Project",
        desc: "Sistem akan mengirimkan notifikasi ke email penulis",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successAssignProject]);

  useEffect(() => {
    if (successInviteUser) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Undang Penulis",
        desc: "Sistem akan mengirimkan notifikasi ke email penulis",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successInviteUser]);

  useEffect(() => {
    if (errInviteUser) {
      setOpenAlert({
        dialog: true,
        type: "error",
        title: "Gagal Undang Penulis",
        desc: "Penulis gagal diundang",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errInviteUser]);

  const closeAlert = () => {
    setOpenAlert({
      dialog: false,
      type: null,
      title: null,
      desc: null,
    });
    dispatch(resetStateProjectAction());
    dispatch(resetStateUserAction());
  };

  const keywords = dataGetProjectDetailClient?.searchKeyword?.split(",");

  const handleEdit = () => {
    history.push(`/client/update-brief/${dataGetProjectDetailClient?.id}`)
  }

  const handlePayment = async () => {
    setLoading(true)
    try {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_STORAGE_URL}/v1/client/orders/process-inquiry`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          orderId: dataGetProjectDetailClient?.id,
        })
      })
      const data = await response.json()
      console.log(data)
      setLoading(false)
      setDialogWarning(false)
      setDialogSuccessSave(true)
      history.push(`/client/invoice/${dataGetProjectDetailClient?.id}`);
    } catch(error) {
      console.log(error)
      setLoading(false)
      setDialogWarning(false)
      setDialogSuccessSave(true)
    }
  }

  const closeSuccessSave = () => {
    setDialogSuccessSave(false);
    setDialogWarning(false)
    history.push("/client/my-order/confirm")
  }

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-header">
          {loadingGetProjectDetailClient ? (
            <TitleLoad />
          ) : (
            <>
              <div className="card-title w-100">
                <div
                  className="card-title-back"
                  onClick={() => history.goBack()}
                  >
                  <span className="icon-back"></span>
                </div>
                <h2 className="card-title-text w-100 ml-5">Detail Draft Project</h2>
                <div className="d-flex w-100 justify-content-end">
                  <ButtonDefault
                    onClick={handleEdit}
                    label="Edit Brief"
                    type="rounded"
                    className="p-button mr-3 p-button-outlined"
                  />
                  <ButtonDefault
                    onClick={() => setDialogWarning(true)}
                    label="Create Order"
                    type="rounded"
                    className="p-button p-button-primary"
                  />
                </div>
              </div>
            </>
          )}
        </div>
        <div className="card-body px-0">
          {loadingGetProjectDetailClient ? (
            <div className="project-info-wrapp">
              <DetailPageLoad />
            </div>
          ) : (
            <>
              <div className="project-detail-top">
                <div className="project-info-wrapp">
                  <div className="project-image">
                    <img
                      src={
                        dataGetProjectDetailClient?.thumbnail
                          ? dataGetProjectDetailClient?.thumbnail
                          : process.env.REACT_APP_IMAGE_ARTICLE
                      }
                      alt="tumbnail"
                    />
                  </div>
                  <div className="project-info">
                    <div className="info-row-title">
                      <h3>{dataGetProjectDetailClient?.title}</h3>
                      <div className="d-flex align-items-center">
                        <h6>Nomor Pekerjaan: {dataGetProjectDetailClient?.jobNumber}</h6>
                        <StatusBadge
                          status={dataGetProjectDetailClient?.status}
                          className="ml-3"
                        />
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={UsersImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetProjectDetailClient?.nWriter || 0}
                          renderText={(value) => (
                            <p>
                              <strong> {value} </strong> Penulis Bergabung
                            </p>
                          )}
                        />
                      </div>
                      <div className="info">
                        <img src={WinnerImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={
                            dataGetProjectDetailClient?.totalWinner
                              ? dataGetProjectDetailClient?.totalWinner
                              : 0
                          }
                          renderText={(value) => <p>{value}/</p>}
                        />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetProjectDetailClient?.winnerQuota}
                          renderText={(value) => (
                            <p>
                              <strong>{value}</strong> Tulisan Terpilih
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={ClockImage} alt="" />
                        <p>
                          {`Deadline ${
                            dataGetProjectDetailClient?.deadlineDate
                              ? dayjs(dataGetProjectDetailClient?.deadlineDate)
                                  .locale("id")
                                  .format("DD MMMM YYYY")
                              : "belum diatur"
                          }`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="project-description">
                    <p>
                      {dataGetProjectDetailClient?.description ||
                        "Deskripsi project belum diatur"}
                    </p>
                  </div>
                  <div className="project-action">
                    <div className="action-left" />
                    <div className="action-right">
                      {!dataGetProjectDetailClient?.picId &&
                        dataGetProjectDetailClient?.status !== "draft" && (
                          <ButtonIconLeft
                            className="ml-2"
                            label="TAMBAH TIM"
                            image={ImgInvite}
                            onClick={() =>
                              history.push(
                                `/client/my-release/assign-pic/${QueryParams.projectId}`
                              )
                            }
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/* {!dataGetProjectDetailClient?.deadlineDate ? (
                <div className="project-detail-bottom">
                  {dataDetailOrderSaveClient?.status === "payment_waiting" ? (
                    <div className="project-detail-alert">
                      <h3>PEMBAYARAN BELUM TERKONFIRMASI</h3>
                      <p>
                        Silahkan periksa kembali tagihan anda untuk melanjutkan
                        project ini
                      </p>
                    </div>
                  ) : (
                    <div className="project-detail-alert">
                      <h3>BRIEF PROYEK BELUM LENGKAP</h3>
                      <p>
                        Silahkan lengkapi brief pesanan terlebih dahulu agar
                        pesanan bisa kami proses.
                      </p>
                      <Link
                        className="p-button p-button-rounded p-button-primary"
                        to={`/client/create-brief/${props.projectId}`}
                      >
                        {" "}
                        BUAT BRIEF
                      </Link>
                    </div>
                  )}
                </div>
              ) : ( */}
                <div className="project-detail-bottom">
                  <div className="tab-project" ref={pdfRef}>
                    <TabView>
                      <TabPanel header="Ringkasan Project">
                        <div className="accordion-project-wrapper">
                          <div className="row">
                            <div className="col-12 col-sm-12">
                              <h3>Informasi Project</h3>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Jenis Project</h6>
                              <div className="box-text">{categoryName}</div>
                            </div>

                            <div className="col-12 col-sm-4">
                              <h6>PIC Project</h6>
                              <div className="box-text">
                                {dataGetProjectDetailClient?.picName || users?.user?.name}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Kata Kunci Pencarian</h6>
                              <div className="d-flex flex-wrap">
                                {keywords?.map((item) => (
                                  <div className="box-text list">{item}</div>
                                ))}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <hr />
                            </div>
                            <div className="col-12 col-sm-12">
                              <h3>Target Pembaca</h3>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Usia</h6>
                              <div className="box-text">
                                {dataGetProjectDetailClient?.target?.age}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Jenis kelamin</h6>
                              <div className="box-text">
                                {dataGetProjectDetailClient?.target?.sex}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Stakeholder</h6>
                              <div className="d-flex flex-wrap">
                                {dataGetProjectDetailClient?.target?.stakeholders?.map(
                                  (item) => (
                                    <div className="box-text list">{item}</div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <hr />
                            </div>
                            <div className="col-12 col-sm-12">
                              <h3>Ruang Lingkup Rilis</h3>
                            </div>
                            {dataGetProjectDetailClient?.type === "pr" && (
                              <>
                                <div className="col-12 col-sm-4">
                                  <h6>Kategori Rilis</h6>
                                  <div className="box-text mb-5">
                                    {dataGetProjectDetailClient?.releaseCategory}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="col-12 col-sm-12">
                              <h6>Objek Komunitas yang Ingin Dicapai</h6>
                              <p>{dataGetProjectDetailClient?.objective}</p>
                              <h6>
                                Atribut Quote dan Pesan yang Ingin Disampaikan
                              </h6>
                              <p>{dataGetProjectDetailClient?.attribution || "-"}</p>
                              <h6>
                                Hal yang Diperhatikan dalam Penulisan Release
                              </h6>
                              <p>{dataGetProjectDetailClient?.releaseNote|| "-"}</p>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                    </TabView>
                  </div>
                </div>
              {/* )} */}
            </>
          )}
        </div>
      </div>
      <DialogAlert
        type={openAlert.type}
        visible={openAlert.dialog}
        closeDialog={() => closeAlert()}
        title={openAlert.title}
        text={openAlert.desc}
      />
      <DialogAlert
        type={"warning"}
        actionDialog={handlePayment}
        actionText={"KONFIMASI"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Konfirmasi Pemesanan Layanan"}
        text={isLoading ? "Draft Sedang Dibuat. Mohon Tunggu.." : "Anda yakin ingin melakukan konfirmasi pemesanan layanan?"}
        loading={isLoading}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccessSave}
        closeDialog={closeSuccessSave}
        title={"Berhasil Mengorder"}
        text={"Pesanan Layanan Berhasil Diorder"}
      />
    </>
  );
};

export default DetailMyReleaseModuleDraft;
