import {
  INVITE_USER_REQUEST,
  RE_INVITE_USER_REQUEST,
  GET_USER_REQUEST,
  RESET_STATE_USER,
  GET_DETAIL_USER_REQUEST,
  UPDATE_USER_REQUEST,
  DELETE_USER_REQUEST,
  UPLOAD_PORTFOLIO_IMAGE_REQUEST,
  UPLOAD_PORTFOLIO_IMAGE_RESET,
  GET_PORTFOLIO_REQUEST,
  UPDATE_STATUS_APPLICANT_REQUEST,
  POST_PM_REQUEST,
  UPDATE_STATUS_USER_REQUEST,
  UPLOAD_COMPANY_PROFILE_REQUEST,
  GET_WRITER_BANK_REQUEST,
  POST_WRITER_BANK_REQUEST,
  UPDATE_WRITER_BANK_REQUEST,
} from "../constant/userConstant";

export const inviteUserAction = (data) => {
  return {
    type: INVITE_USER_REQUEST,
    data,
  };
};

export const reInviteUserAction = (id) => {
  return {
    type: RE_INVITE_USER_REQUEST,
    id,
  };
};

export const getUsersAction = (params) => {
  return {
    type: GET_USER_REQUEST,
    params,
  };
};

export const resetStateUserAction = () => {
  return {
    type: RESET_STATE_USER,
  };
};

export const detailUserAction = (id) => {
  return {
    type: GET_DETAIL_USER_REQUEST,
    id,
  };
};

export const updateUserAction = (id, data) => {
  return {
    type: UPDATE_USER_REQUEST,
    id,
    data,
  };
};

export const deleteUserAction = (id) => {
  return {
    type: DELETE_USER_REQUEST,
    id,
  };
};

export const uploadPortfolioAction = (file, path) => {
  return {
    type: UPLOAD_PORTFOLIO_IMAGE_REQUEST,
    file,
    path,
  };
};

export const uploadCompanyProfileAction = (file, path) => {
  return {
    type: UPLOAD_COMPANY_PROFILE_REQUEST,
    file,
    path,
  };
};

export const resetPortfolioAction = () => {
  return {
    type: UPLOAD_PORTFOLIO_IMAGE_RESET,
  };
};

export const getPortfolioFileAction = (uid, portfolioId) => {
  return {
    type: GET_PORTFOLIO_REQUEST,
    uid,
    portfolioId,
  };
};

export const updateStatusApplicantAction = (uid, data) => {
  return {
    type: UPDATE_STATUS_APPLICANT_REQUEST,
    uid,
    data,
  };
};

export const postPmAction = (data) => {
  return {
    type: POST_PM_REQUEST,
    data,
  };
};

export const updateStatusUserAction = (uid, data) => {
  return {
    type: UPDATE_STATUS_USER_REQUEST,
    uid,
    data,
  };
};

export const postWriterBankAction = (data) => {
  return {
    type: POST_WRITER_BANK_REQUEST,
    data,
  };
};

export const getWriterBankAction = () => {
  return {
    type: GET_WRITER_BANK_REQUEST,
  };
};

export const updateWriterBankAction = (data) => {
  return {
    type: UPDATE_WRITER_BANK_REQUEST,
    data,
  };
};
