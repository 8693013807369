/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import closeIcon from "../../../assets/img/close-black.svg";

import FullContentLoader from "../../../components/loading/fullContentLoader";
import { detailUserAction } from "../../../../redux/action/userAction";

const PMDetailWriter = (props) => {
  const dispatch = useDispatch();
  const { dataGetArticle } = useSelector((state) => state.project);

  const { loadingDetailUser, dataDetailUser } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (dataGetArticle?.writerId) {
      dispatch(detailUserAction(dataGetArticle?.writerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetArticle?.writerId]);

  return (
    <>
      <div className="box-info">
        {loadingDetailUser ? (
          <div className="box-inner-right chat-pm">
            <FullContentLoader />
            <FullContentLoader />
            <FullContentLoader />
          </div>
        ) : (
          <>
            <div className="header-box client-chat bordered p-d-flex p-jc-between p-ai-center">
              <h3 className="p-mb-0 font-weight-bold">
                <span>DETAIL PENULIS</span>
              </h3>
              <button
                onClick={() => props.closeSection()}
                className="btn-info-chat"
              >
                <img alt="close" src={closeIcon} />
              </button>
            </div>
            <div className="box-inner-right chat-pm">
              <div className="detail-project">
                <div className="detail-writer">
                  <p className="font-weight-bold">Nama:</p>
                  <p>{dataDetailUser?.name}</p>
                </div>

                <div className="detail-writer">
                  <p className="font-weight-bold">Email:</p>
                  <p>{dataDetailUser?.email}</p>
                </div>

                <div className="detail-writer">
                  <p className="font-weight-bold">Nomor Telepon:</p>
                  <p>{dataDetailUser?.phone || "Belum Tersedia"}</p>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default PMDetailWriter;
