import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";
import _ from "lodash";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { ListLoad } from "../../../components/loading/loadingContent";
import { getProjectWriterAction } from "../../../../redux/action/projectAction";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import ButtonLoader from "../../../components/button/ButtonLoader";
import StatusBadge from "../../../components/badge/StatusBadge";

const ProjectListModule = () => {
  const dispatch = useDispatch();

  const {
    dataGetProjectWriter,
    loadingGetProjectWriter,
    projectWriterParams,
    totalPage,
  } = useSelector((state) => state.project);

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = { ...projectWriterParams, q: search };
      dispatch(getProjectWriterAction(params));
    }, 1000)
  ).current;

  const handleNextList = (e) => {
    e.preventDefault();
    const params = {
      ...projectWriterParams,
      perpage: dataGetProjectWriter?.length + 10,
    };
    dispatch(getProjectWriterAction(params));
  };

  window.onscroll = () => {
    stickyMobileSearch();
  };
  const stickyMobileSearch = () => {
    const searchBar = document.getElementById("search-bar");
    const listProjects = document.getElementById("list-projects");
    const searchBarOffsetTop = 118;
    if (window.pageYOffset >= searchBarOffsetTop) {
      searchBar && searchBar.classList.add("sticky");
      listProjects && listProjects.classList.add("sticky");
    } else {
      searchBar && searchBar.classList.remove("sticky");
      listProjects && listProjects.classList.remove("sticky");
    }
  };

  const projectStatus = (data) => {
    const date = new Date();
    const yesterday = date.setDate(date.getDate() - 1);
    const newState = [
      dayjs(yesterday).format("DD MM YYYY"),
      dayjs().format("DD MM YYYY"),
    ];
    const isNew = newState.includes(dayjs(data.createdAt).format("DD MM YYYY"));
    const deadline = new Date(data?.deadlineDate);
    const isExpired =
      deadline < new Date() || data?.isDone || data?.status === "done";
    let isVisible;
    let state;
    if (isNew && !isExpired) {
      state = data.isJoined ? "join" : "new";
      isVisible = true;
    } else if (data.isJoined) {
      state = "join";
      isVisible = true;
    } else if (isNew && isExpired) {
      state = "done";
      isVisible = true;
    } else if (isExpired) {
      state = "done";
      isVisible = true;
    } else {
      state = data.status;
      isVisible = false;
    }

    return isVisible && <StatusBadge status={state} user="writer" />;
  };

  return (
    <div className="col-lg-9 col-md-9 col-sm-12 col-xs-12 my-feed-wrapper">
      <div className="box-search p-mt-4">
        <span className="p-input-icon-right" id="search-bar">
          <span className="src-btn">
            <i className="pi pi-search" />
          </span>
          <InputText
            placeholder="Cari Nama Project"
            onChange={(e) => searchingHandler(e.target.value)}
          />
        </span>
      </div>
      <div
        className="box-content box-project project-list p-mb-4"
        id="list-projects"
      >
        <div className="write-wrapper">
          {loadingGetProjectWriter && !dataGetProjectWriter ? (
            <div className="write-detail">
              <div className="summary">
                <ListLoad />
              </div>
              <div className="summary">
                <ListLoad />
              </div>
              <div className="summary">
                <ListLoad />
              </div>
              <div className="summary">
                <ListLoad />
              </div>
              <div className="summary">
                <ListLoad />
              </div>
            </div>
          ) : (
            <div className="write-detail">
              {dataGetProjectWriter?.length > 0 ? (
                dataGetProjectWriter?.map((item) => (
                  <div key={item?._id} className="summary">
                    <div className="mr-auto p-2 bd-highlight project-wrapp">
                      <div className="project-img">
                        <img src={item?.thumbnail} alt="image_project" />
                      </div>
                      <div className="project-detail">
                        <div className="d-flex flex-row bd-highlight">
                          <div className="mr-auto bd-highlight author-wrapp">
                            <div className="desc">
                              <h3>
                                <Link to={`/project/detail/${item?._id}`}>
                                  {item?.title}
                                </Link>
                              </h3>
                              <span>{item?.clientName}</span>
                            </div>
                          </div>
                          <div className="bd-highlight">
                            {projectStatus(item)}
                          </div>
                        </div>
                        <div className="detail-attr">
                          <p>
                            <NumberFormat
                              prefix={"Rp "}
                              thousandSeparator={"."}
                              decimalSeparator={","}
                              displayType="text"
                              value={item?.price}
                              renderText={(value) => <span>{value}</span>}
                            />
                            {" - "}
                            Deadline{" "}
                            {dayjs(item.deadlineDate)
                              .locale("id")
                              .format("DD MMMM YYYY")}{" "}
                          </p>
                        </div>
                        <p>
                          {item?.description?.length > 200
                            ? `${item?.description?.slice(0, 200)}...`
                            : item?.description}
                        </p>
                        <div className="p-d-flex p-jc-between p-ai-center footer-attr">
                          <Link to={`/project/detail/${item?._id}`}>
                            Tampilkan Detail
                          </Link>
                          <span>
                            Diposting{" "}
                            {dayjs(item.createdAt)
                              .locale("id")
                              .format("DD MMMM YYYY")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <DataEmpty text="Project Tidak Ditemukan" />
              )}
              {dataGetProjectWriter?.length > 9 &&
                dataGetProjectWriter?.length < totalPage && (
                  <div className="footer-writer">
                    {loadingGetProjectWriter ? (
                      <ButtonLoader />
                    ) : (
                      <Button
                        onClick={handleNextList}
                        label="LIHAT PROJECT LAINNYA"
                        className="p-button-rounded p-button-outlined"
                      />
                    )}
                  </div>
                )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectListModule;
