import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { InputText } from "primereact/inputtext";
import { Link } from "react-router-dom";

import { getUsersAction } from "../../../../../../redux/action/userAction";

const FilterPM = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
        type: "general_pm",
      };

      history.push(`/admin/pm/list-pm?search=${search || ""}&page=1`);
      dispatch(getUsersAction(params));
    }, 1000)
  ).current;

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter">
        <div className="filter-field">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block search-inputtext"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <p>
            Cari berdasarkan<span> Nama</span>
          </p>
        </div>
      </div>
      <Link
        to="/admin/pm/add-pm"
        className="p-button p-button-primary button-link"
      >
        Tambah PM
      </Link>
    </div>
  );
};

export default FilterPM;
