import {
  GET_SERVICE_REQUEST,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_REQUEST,
  ADD_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_REQUEST,
  SERVICE_STATE_RESET,
} from "../constant/serviceConstant";

export const getServiceAction = () => {
  return {
    type: GET_SERVICE_REQUEST,
  };
};

export const getProductAction = (params) => {
  return {
    type: GET_PRODUCT_REQUEST,
    params,
  };
};

export const getProductDetailAction = (id) => {
  return {
    type: GET_PRODUCT_DETAIL_REQUEST,
    id,
  };
};

export const addProductAction = (data) => {
  return {
    type: ADD_PRODUCT_REQUEST,
    data,
  };
};

export const updateProductAction = (id, data) => {
  return {
    type: UPDATE_PRODUCT_REQUEST,
    id,
    data,
  };
};

export const deleteProductAction = (id) => {
  return {
    type: DELETE_PRODUCT_REQUEST,
    id,
  };
};

export const resetStateServiceAction = () => {
  return {
    type: SERVICE_STATE_RESET,
  };
};
