/* eslint-disable no-unused-vars */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive, getCurrentUrl } from "../../../../_helpers";
import MinusIcon from "../../../../../app/assets/img/minus-icon.svg";
import PlusIcon from "../../../../../app/assets/img/plus-icon.svg";

export function AsideMenuClientList({ layoutProps, menu }) {
  const location = useLocation();
  const current = getCurrentUrl(location);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const { users } = useSelector((state) => state.auth);
  const isClient = users?.user?.type === "client";

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const orderPages = ["/client/my-order/process", "/client/my-order/confirm"];

  let isProcess = false;
  let isConfirm = true;

  if (location.pathname.includes("client/my-order/process")) {
    isProcess = true;
    isConfirm = false;
  }

  const [groupActive, groupActiveSet] = useState({ name: "", isOpen: false });

  const menuProject = ["project", "draft"];

  useEffect(() => {
    if (current === "/client/my-order/confirm" || current === "/client/my-order/draft") {
      groupActiveSet({ name: "projects", isOpen: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleMenu = (data) => {
    if (groupActive.name === data && groupActive.isOpen) {
      groupActiveSet({ ...groupActive, isOpen: false });
    } else if (groupActive.name === data && !groupActive.isOpen) {
      groupActiveSet({ ...groupActive, isOpen: true });
    } else if (groupActive.name !== data && groupActive.isOpen) {
      groupActiveSet({ name: data, isOpen: true });
    } else if (groupActive.name !== data && !groupActive.isOpen) {
      groupActiveSet({ name: data, isOpen: true });
    }
  };

  useEffect(() => {
    if (
      orderPages
        .map((page) => location.pathname.includes(page))
        .find((item) => item)
    ) {
      setOpenSubmenu(true);
    }
  }, [location, orderPages]);

  const getSubmenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive(
            "/client/dashboard",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/client/dashboard">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/ic-e-cl-dashboard.svg"
                )}
              />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <>
          <li
            className={`menu-item ${getMenuItemActive("/client/order" && "/client/create-brief", true)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${menu === "product" && "active"}`}
              to="/client/order"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/ic-e-cl-new.svg"
                  )}
                />
              </span>
              <span className="menu-text">Layanan & Produk</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive("/client/ideation", false)}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${menu === "ideation"}`}
              to="/client/ideation"
            >
              <span className="menu-icon">
                <img src={current === "/client/ideation" ? "/media/svg/icons/General/Ideation-on.svg" : "/media/svg/icons/General/ideation.svg"} alt=""/>
              </span>
              <span className="menu-text">Ideation</span>
            </NavLink>
          </li>
          <li
            className={`menu-item ${getMenuItemActive(
              "/client/my-order/process",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${menu === "invoice" && "active"}`}
              to="/client/my-order/process"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/ic-f-invoice.svg"
                  )}
                />
              </span>
              <span className="menu-text">Invoice</span>
            </NavLink>
          </li>
        </>

        <li
          className={`menu-item-submenu menu-item ${groupActive ===
            "projects" && "menu-item-active menu-item-open"}`}
          aria-haspopup="true"
        >
          <a
            className={`menu-link menu-toggle ${groupActive?.isOpen &&
              groupActive?.name === "projects" &&
              "active"} `}
            onClick={() => toogleMenu("projects")}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-invoice.svg")}
              />
            </span>
            <span className="menu-text">Project</span>
            <span
              className={`menu-${
                groupActive?.isOpen && groupActive?.name === "projects"
                  ? "hide"
                  : "show"
              }`}
            >
              <SVG
                src={
                  groupActive?.isOpen && groupActive?.name === "projects"
                    ? MinusIcon
                    : PlusIcon
                }
              />
            </span>
          </a>
          <div
            className={`menu-submenu ${
              groupActive?.isOpen && groupActive?.name === "projects"
                ? "d-block"
                : "d-none"
            } `}
          >
            <span className="menu-arrow"></span>
            <ul className="menu-subnav">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${getMenuItemActive(
                  "/client/my-order/confirm",
                  false
                )}`}
              >
                <Link to="/client/my-order/confirm" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/ic-f-invoice.svg")}
                    />
                  </span>
                  <span className="menu-text">Project List</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${getMenuItemActive(
                  "/client/my-order/draft",
                  false
                )}`}
              >
                <Link to="/client/my-order/draft" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/ic-f-invoice.svg")}
                    />
                  </span>
                  <span className="menu-text">Project Draft</span>
                </Link>
              </li>
            </ul>
          </div>
        </li>
{/* 
        <li
          className={`menu-item ${getMenuItemActive(
            "/client/my-order/confirm",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className={`menu-link ${menu === "project" && "active"}`}
            to="/client/my-order/confirm"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/ic-e-cl-myrelease.svg"
                )}
              />
            </span>
            <span className="menu-text">Project</span>
          </NavLink>
        </li> */}

        <li
          aria-haspopup="true"
          data-menu-toggle="hover"
          className={`menu-item ${
            menu === "distribution" ? "menu-item-active" : ""
          }`}
        >
          <Link to="/client/list-release" className="menu-link">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/General/ic-distribution.svg"
                )}
              />
            </span>
            <span className="menu-text">Media Distribusi</span>
          </Link>
        </li>

        {isClient && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/client/pic-list",
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink
              className={`menu-link ${menu === "team" && "active"}`}
              to="/client/pic-list"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/General/ic-e-cl-approval.svg"
                  )}
                />
              </span>
              <span className="menu-text">Manajemen Tim</span>
            </NavLink>
          </li>
        )}
        {/* Hidden until next confirmation */}
        {/* <li
          className={`menu-item ${getMenuItemActive("/client/help", false)}`}
          aria-haspopup="true"
        >
          <NavLink
            className={`menu-link ${menu === "ticket" && "active"}`}
            to="/client/help"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-e-cl-help.svg")}
              />
            </span>
            <span className="menu-text">Bantuan</span>
          </NavLink>
        </li> */}
      </ul>
    </>
  );
}
