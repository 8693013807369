import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { helpCategoryAction } from "../../../redux/action/utilsAction";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import AddHelpModule from "../../modules/Client/Help/AddHelpModule";

function AddHelpPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    titlePage({
      title: "IRIS - Bantuan",
    });
    dispatch(helpCategoryAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Tiket Bantuan" menu="ticket">
      <AddHelpModule />
    </DashboardClientLayout>
  );
}

export default AddHelpPage;
