/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";
import _ from "lodash";

import { InputText } from "primereact/inputtext";
import filterIcon from "../../../assets/img/Filter-2.png";
import sortIcon from "../../../assets/img/Filter.png";

import PopupFilter from "./PopupFilter";
import PopupSort from "./PopupSort";
import AdminPaginate from "../../../components/paging/pagination";
import TextBody from "../../../components/datatableBody/TextBody";
import DateBody from "../../../components/datatableBody/DateBody";
import NumberBody from "../../../components/datatableBody/NumberBody";
import StatusBadgeBody from "../../../components/datatableBody/StatusBadgeBody";

import { getOrderAction } from "../../../../redux/action/orderAction";

import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import ResponsiveTable from "../../../components/table/ResponsiveTable";
import ResponsiveColumn from "../../../components/table/ResponsiveColumn";

const MyOrderModule = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search);

  const [showPopup, setShowPopup] = useState(false);
  const [showSorting, setShowSorting] = useState(false);
  const { dataGetOrder, loadingGetOrder, totalPage } = useSelector(
    (state) => state.order
  );

  const statusBodyTemplate = (item) => {
    return (
      <StatusBadgeBody
        status={item?.status}
        userType="client"
        link={`/client/invoice/${item?._id}`}
      />
    );
  };

  const titleBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.productName}
        link={`/client/invoice/${item?._id}`}
      />
    );
  };
  const categoryBodyTemplate = (item) => {
    let categoryName;
    if (item?.productCategory === "pr") {
      categoryName = "Press Release";
    } else if (item?.productCategory === "distribution_media") {
      categoryName = "Media Distribusi";
    } else {
      categoryName = "Artikel";
    }
    return (
      <TextBody text={categoryName} link={`/client/invoice/${item?._id}`} />
    );
  };
  const invoiceBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.invoiceNumber}
        link={`/client/invoice/${item?._id}`}
      />
    );
  };
  const dateBodyTemplate = (item) => {
    return (
      <DateBody date={item?.createdAt} link={`/client/invoice/${item?._id}`} />
    );
  };
  const winnerBodyTemplate = (item) => {
    return (
      <NumberBody
        number={item?.quantity}
        link={`/client/invoice/${item?._id}`}
      />
    );
  };
  const priceBodyTemplate = (item) => {
    return (
      <NumberBody
        number={item?.totalPrice}
        link={`/client/invoice/${item?._id}`}
        isPrice
      />
    );
  };

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        category: parsed?.category || "",
        type: parsed?.type || "",
        orderField: parsed?.order || "",
        orderDir: parsed?.order === "createdAt" ? "desc" : "asc",
        page: 1,
        perpage: 10,
      };

      history.push(
        `/client/my-order/process?search=${search}&category=${parsed?.category ||
          ""}&order=${parsed?.order || ""}&page=1`
      );
      dispatch(getOrderAction(params));
    }, 1000)
  ).current;

  const handleFilter = (e) => {
    e.preventDefault();
    setShowPopup(!showPopup);
    setShowSorting(false);
  };

  const handleSorting = (e) => {
    e.preventDefault();
    setShowPopup(false);
    setShowSorting(!showSorting);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      category: parsed?.category || "",
      type: parsed?.type || "",
      orderField: parsed?.order || "",
      orderDir: parsed?.order === "title" ? "asc" : "desc",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getOrderAction(params));

    history.push(
      `/client/my-order/process?search=${parsed?.search ||
        ""}&category=${parsed.category || ""}&order=${parsed?.order ||
        ""}&page=${page + 1}`
    );
  };

  return (
    <div className="myrelease-module">
      <div className="title">Daftar Invoice</div>

      <div className="search-myrelease">
        <div className="d-flex">
          <InputText
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <button onClick={handleFilter}>
            <img src={filterIcon} alt="filter" />
          </button>
          <button onClick={handleSorting}>
            <img src={sortIcon} alt="sort" />
          </button>
        </div>
        <p>
          Cari berdasarkan<span> Nomor Invoice</span>
        </p>
      </div>
      <div className="table-list release">
        {loadingGetOrder ? (
          <TableLoader />
        ) : (
          <>
            {dataGetOrder?.length < 1 || dataGetOrder === null ? (
              <DataEmpty />
            ) : (
              <ResponsiveTable value={dataGetOrder} mobileTitle="Nama Pesanan">
                <ResponsiveColumn
                  header="Nomor Invoice"
                  body={invoiceBodyTemplate}
                ></ResponsiveColumn>
                <ResponsiveColumn
                  header="Nama Pesanan"
                  body={titleBodyTemplate}
                ></ResponsiveColumn>
                <ResponsiveColumn
                  header="Tanggal Pesan"
                  body={dateBodyTemplate}
                ></ResponsiveColumn>
                <ResponsiveColumn
                  header="Kategori Produk"
                  body={categoryBodyTemplate}
                ></ResponsiveColumn>
                <ResponsiveColumn
                  header="Jumlah"
                  body={winnerBodyTemplate}
                ></ResponsiveColumn>
                <ResponsiveColumn
                  header="Harga"
                  body={priceBodyTemplate}
                ></ResponsiveColumn>
                <ResponsiveColumn
                  header="Status"
                  body={statusBodyTemplate}
                ></ResponsiveColumn>
              </ResponsiveTable>
            )}
          </>
        )}{" "}
      </div>
      <PopupFilter
        show={showPopup}
        parsed={parsed}
        closePopup={() => setShowPopup(false)}
      />
      <PopupSort
        show={showSorting}
        parsed={parsed}
        closePopup={() => setShowSorting(false)}
      />
      {totalPage > 1 && (
        <AdminPaginate
          active={parsed?.page || 1}
          total={totalPage}
          clicked={paginationHandler}
          perpage={10}
        />
      )}
    </div>
  );
};

export default MyOrderModule;
