/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";

import ButtonDefault from "../../../components/button/ButtonDefault";
import DeclinePopup from "./Components/DeclinePopup";
import DialogAlert from "../../../components/dialog/DialogAlert";
import DialogImagePreview from "../../../components/dialog/DialogImagePreview";
import {
  updateFinanceOrderAction,
  resetStateFinanceAction,
} from "../../../../redux/action/financeAction";
import { orderClientDetailAction } from "../../../../redux/action/orderAction";
import {
  DetailPageLoad,
  TitleLoad,
} from "../../../components/loading/loadingContent";

const ListDetailInvoiceModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dialogSuccess, setDialogSuccess] = useState({
    type: "",
    dialog: false,
    title: "",
    text: "",
  });
  const [dialogImage, setDialogImage] = useState(false);
  const [isApprove, setIsApprove] = useState(false);

  const { loadingDetailOrderClient, dataDetailOrderClient } = useSelector(
    (state) => state.order
  );
  const { loadingUpdateFinanceOrder, successUpdateFinanceOrder } = useSelector(
    (state) => state.finance
  );

  useEffect(() => {
    if (successUpdateFinanceOrder && isApprove) {
      setDialogSuccess({
        type: "success",
        dialog: true,
        title: "Pembayaran Dikonfirmasi",
        text: "Pembayaran berhasil dikonfirmasi",
      });
    }
    if (successUpdateFinanceOrder && !isApprove) {
      setDialogSuccess({
        type: "success",
        dialog: true,
        title: "Pembayaran Ditolak",
        text: "Pembayaran berhasil ditolak",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateFinanceOrder, isApprove]);

  const closeSuccess = () => {
    if (dialogSuccess?.type === "success") {
      dispatch(resetStateFinanceAction());
      dispatch(orderClientDetailAction(props.invoiceId));
    }
    setDialogSuccess({
      type: "",
      dialog: false,
      title: "",
      text: "",
    });
    setIsApprove(false);
  };

  const approveInvoice = () => {
    const approve = () => {
      const data = { status: "confirmed" };
      dispatch(updateFinanceOrderAction(props.invoiceId, data));
      setIsApprove(true);
    };
    setDialogSuccess({
      type: "warning",
      dialog: true,
      title: "YAKIN TERIMA INVOICE?",
      text: "Setelah diterima, invoice tidak bisa diubah lagi",
      loading: loadingUpdateFinanceOrder && isApprove,
      actionDialog: () => approve(),
      actionText: "TERIMA",
      cancelText: "BATAL",
    });
  };

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-header">
          {loadingDetailOrderClient ? (
            <TitleLoad />
          ) : (
            <div className="card-title">
              <div className="card-title-back" onClick={() => history.goBack()}>
                <span className="icon-back"></span>
              </div>
              <h2 className="card-title-text">{`Nomor Invoice: ${dataDetailOrderClient?.invoiceNumber}`}</h2>
            </div>
          )}
        </div>
        <div className="card-body">
          {loadingDetailOrderClient ? (
            <DetailPageLoad />
          ) : (
            <>
              <div className="p-d-flex p-jc-between">
                <div className="detail-user">
                  <ul>
                    <li>
                      <label>Nama Klien</label>
                      <span>{dataDetailOrderClient?.clientName}</span>
                    </li>
                    <li>
                      <label>Nama Perusahaan</label>
                      <span>{dataDetailOrderClient?.companyName}</span>
                    </li>
                    <li>
                      <label>Kontak Klien</label>
                      <span>{`${dataDetailOrderClient?.clientEmail ||
                        "-"} / ${dataDetailOrderClient?.phone?.replace(
                        "+62",
                        "0"
                      ) || "-"}`}</span>
                    </li>
                  </ul>
                </div>
                <div className="detail-user">
                  <ul>
                    <li>
                      <label>Nama Rekening</label>
                      <span>
                        {dataDetailOrderClient?.paymentConfirmation
                          ?.accountName || "Belum Tersedia"}
                      </span>
                    </li>
                    <li>
                      <label>Nomor Rekening (Nama Bank)</label>
                      <span>
                        {dataDetailOrderClient?.paymentConfirmation
                          ?.accountNumber
                          ? `${dataDetailOrderClient?.paymentConfirmation?.accountNumber} (${dataDetailOrderClient?.paymentConfirmation?.accountBank})`
                          : "Belum Tersedia"}
                      </span>
                    </li>
                  </ul>
                </div>
                <div className="preview-image">
                  {dataDetailOrderClient?.paymentConfirmation
                    ?.paymentAttachment && (
                    <>
                      <h4>Bukti Transfer</h4>
                      <img
                        src={
                          dataDetailOrderClient?.paymentConfirmation
                            ?.paymentAttachment
                        }
                        alt="img"
                        onClick={() => setDialogImage(true)}
                      />
                      <div>
                        <a
                          href={
                            dataDetailOrderClient?.paymentConfirmation
                              ?.paymentAttachment
                          }
                          download
                          target="_blank"
                        >
                          <div className="icon-download-green" />
                        </a>

                        <span>bukti-pembayaran.png</span>
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div className="table-invoice">
                <div className="table-row table-header">
                  <div className="table-data">Nama Produk</div>
                  <div className="table-data jc-end">Harga Produk</div>
                  <div className="table-data jc-end">Jumlah Produk</div>
                  <div className="table-data jc-end">Total</div>
                </div>
                <div className="table-row">
                  <div className="table-data">
                    {dataDetailOrderClient?.productName}
                  </div>
                  <div className="table-data jc-end">
                    <NumberFormat
                      prefix={"Rp "}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      displayType="text"
                      value={dataDetailOrderClient?.productPrice}
                    />
                  </div>
                  <div className="table-data jc-end">
                    <NumberFormat
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      displayType="text"
                      value={dataDetailOrderClient?.quantity}
                    />
                  </div>
                  <div className="table-data jc-end">
                    <NumberFormat
                      prefix={"Rp "}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      displayType="text"
                      value={
                        dataDetailOrderClient?.productPrice *
                        dataDetailOrderClient?.quantity
                      }
                    />
                  </div>
                </div>
                <div className="table-row table-footer">
                  <div className="table-data">PPN (11%)</div>
                  <div className="table-data" />
                  <div className="table-data" />
                  <div className="table-data jc-end">
                    <NumberFormat
                      prefix={"Rp "}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      displayType="text"
                      value={dataDetailOrderClient?.ppn}
                    />
                  </div>
                </div>
                <div className="table-row table-footer">
                  <div className="table-data">Total Harga</div>
                  <div className="table-data" />
                  <div className="table-data" />
                  <div className="table-data jc-end">
                    <NumberFormat
                      prefix={"Rp "}
                      thousandSeparator={"."}
                      decimalSeparator={","}
                      displayType="text"
                      value={dataDetailOrderClient?.totalPrice}
                    />
                  </div>
                </div>
              </div>

              <div className="clear-box-inner">
                <div className="method-wrapper">
                  <label className="p-d-block font-weight-bold">
                    Metode Pembayaran
                  </label>
                  <span className="font-weight-boldest text-dark">
                    Transfer Bank (Manual)
                  </span>
                </div>
              </div>
              <div className="clear-box-inner">
                <div className="button-wrapper p-d-flex p-jc-end">
                  {dataDetailOrderClient?.status === "confirmation_waiting" && (
                    <>
                      <DeclinePopup invoiceId={props.invoiceId} />
                      <ButtonDefault
                        label="Terima"
                        onClick={() => approveInvoice()}
                        loading={loadingUpdateFinanceOrder && isApprove}
                        className="p-button p-button-primary sm-radius"
                      />
                    </>
                  )}
                  {dataDetailOrderClient?.status === "rejected" && (
                    <h6 className="text-danger text-bold">Ditolak</h6>
                  )}
                  {(dataDetailOrderClient?.status === "confirmed" ||
                    dataDetailOrderClient?.status === "ongoing") && (
                    <h6 className="text-success text-bold">Diterima</h6>
                  )}
                  {dataDetailOrderClient?.status === "payment_waiting" && (
                    <h6 className="text-warning text-bold">Belum Dibayar</h6>
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <DialogAlert
        type={dialogSuccess?.type}
        visible={dialogSuccess?.dialog}
        closeDialog={() => closeSuccess()}
        title={dialogSuccess?.title}
        text={dialogSuccess?.text}
        loading={dialogSuccess?.loading}
        actionDialog={dialogSuccess?.actionDialog}
        actionText={dialogSuccess?.actionText}
        cancelText={dialogSuccess?.cancelText}
      />
      <DialogImagePreview
        visible={dialogImage}
        closeDialog={() => setDialogImage(false)}
        images={dataDetailOrderClient?.paymentConfirmation?.paymentAttachment}
      />
    </>
  );
};

export default ListDetailInvoiceModule;
