import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";

import DialogAlert from "../../../components/dialog/DialogAlert";

import {
  loginEmailAction,
  updatePasswordAction,
  resetStateAuthAction,
  logoutAction,
} from "../../../../redux/action/authAction";
import ButtonLoader from "../../../components/button/ButtonLoader";

function UpdatePassword() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [oldPasswordState, setOldPasswordState] = useState({
    value: "",
    error: null,
  });
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: null,
  });
  const [passwordConfirmState, setPasswordConfirmState] = useState({
    value: "",
    error: null,
  });
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [dialogAlert, setDialogAlert] = useState({
    type: null,
    text: null,
    title: null,
    dialog: false,
  });

  const {
    users,
    loadingEmailLogin,
    successEmailLogin,
    errEmailLogin,
    successUpdatePassword,
    errUpdatePassword,
    loadingUpdatePassword,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successEmailLogin) {
      dispatch(updatePasswordAction(passwordState.value));
    }
    if (errEmailLogin) {
      setDialogAlert({
        type: "error",
        text: "Kata sandi lama yang anda masukkan salah",
        title: "Kata Sandi Anda Salah",
        dialog: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successEmailLogin, errEmailLogin]);

  useEffect(() => {
    if (successUpdatePassword) {
      setDialogAlert({
        type: "success",
        title: "Berhasil Mengubah Kata Sandi",
        text: "Silakan login ulang dengan kata sandi baru Anda",
        dialog: true,
      });
    }
    if (errUpdatePassword) {
      setDialogAlert({
        type: "error",
        title: "Gagal Mengubah Password",
        text: "Password gagal diubah",
        dialog: true,
      });
    }
  }, [successUpdatePassword, errUpdatePassword]);

  const submitPassword = () => {
    if (oldPasswordState?.value?.length < 1) {
      setOldPasswordState({
        ...oldPasswordState,
        error: "Kata sandi lama tidak boleh kosong",
      });
    }
    if (passwordState?.value?.length < 6) {
      setPasswordState({
        ...passwordState,
        error: "Kata sandi baru minimal 6 karakter",
      });
    }
    if (passwordConfirmState?.value?.length < 1) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi tidak boleh kosong",
      });
    }
    if (passwordConfirmState?.value !== passwordState.value) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi tidak sama",
      });
    }

    if (
      oldPasswordState?.value?.length > 0 &&
      passwordState?.value?.length > 5 &&
      passwordConfirmState?.value === passwordState.value
    ) {
      dispatch(loginEmailAction(users?.user?.email, oldPasswordState.value));
    }
  };

  const closeDialog = () => {
    if (dialogAlert.type === "success") {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
      dispatch(resetStateAuthAction());
      dispatch(logoutAction());
    } else {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
    }
  };

  return (
    <>
      <div className="section bg-grey p-py-5">
        <div className="p-d-flex p-jc-center">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-6">
            <div className="card card-custom-detail setting w-full has-shadow mb-5">
              <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <h3 className="card-title flex-column update-setting">
                    <div
                      className="icon-back mr-2"
                      onClick={() => history.goBack()}
                    />
                    <span className="card-label font-weight-bolder text-dark">
                      Ubah Kata Sandi
                    </span>
                  </h3>
                </div>
              </div>
              <div className="form-update-setting">
                <div className="p-field old-password">
                  <label className="p-d-block font-weight-bolder">
                    Kata Sandi Lama
                  </label>
                  <InputText
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Masukkan kata sandi lama"
                    className={`p-d-block ${oldPasswordState?.error &&
                      "p-invalid"}`}
                    value={oldPasswordState.value}
                    onChange={(e) =>
                      setOldPasswordState({
                        value: e.target.value,
                        error: null,
                      })
                    }
                  />
                  {oldPasswordState.error && (
                    <smal className="p-error p-d-block p-text-right">
                      {oldPasswordState.error}
                    </smal>
                  )}
                </div>
                <div className="p-field">
                  <div className="new-password-label">
                    <label className="p-d-block font-weight-bolder">
                      Kata Sandi Baru
                    </label>
                    <div className="show-password">
                      <Checkbox
                        onChange={() => setIsShowPassword(!isShowPassword)}
                        checked={isShowPassword}
                      />
                      <span className="ml-2 p-mb-0">
                        Tampilkan Kata Sandi
                      </span>
                    </div>
                  </div>
                  <InputText
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Masukkan kata sandi baru"
                    className={`p-d-block ${passwordState?.error &&
                      "p-invalid"}`}
                    value={passwordState.value}
                    onChange={(e) =>
                      setPasswordState({ value: e.target.value, error: null })
                    }
                  />
                  {passwordState.error && (
                    <smal className="p-error p-d-block p-text-right">
                      {passwordState.error}
                    </smal>
                  )}
                </div>
                <div className="p-field">
                  <label className="p-d-block font-weight-bolder">
                    Konfirmasi Kata Sandi Baru
                  </label>
                  <InputText
                    type={isShowPassword ? "text" : "password"}
                    placeholder="Masukan konfirmasi kata sandi baru"
                    className={`p-d-block ${passwordConfirmState?.error &&
                      "p-invalid"}`}
                    value={passwordConfirmState.value}
                    onChange={(e) =>
                      setPasswordConfirmState({
                        value: e.target.value,
                        error: null,
                      })
                    }
                  />
                  {passwordConfirmState.error && (
                    <smal className="p-error p-d-block p-text-right">
                      {passwordConfirmState.error}
                    </smal>
                  )}
                </div>
              </div>
              <div className="udpate-setting-footer p-d-flex p-jc-end">
                <Button
                  className="p-button p-button-outlined p-button-secondary"
                  onClick={() => history.goBack()}
                >
                  BATAL
                </Button>
                {loadingUpdatePassword || loadingEmailLogin ? (
                  <ButtonLoader />
                ) : (
                  <Button
                    className="p-button p-button-primary"
                    onClick={() => submitPassword()}
                  >
                    UBAH
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
}

export default UpdatePassword;
