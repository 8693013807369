import React from "react";
import AuthLayout from "../../layouts/authLayout";
import { Button } from "primereact/button";
import IsoLogin from "../../assets/img/iso-login.svg";
import { InputText } from "primereact/inputtext";

const LoginEmailPage = (props) => {
  return (
    <AuthLayout>
      <div className="auth-wrapper login">
        <div className="container">
          <div className="p-grid">
            <div className="p-col-6 p-px-0 p-py-0">
              <div className="auth-form p-d-flex p-jc-center">
                <div className="form-reg">
                  <div className="p-text-center">
                    <h2>Selamat Datang di IRIS</h2>
                  </div>
                  <Button
                    icon="icon-google"
                    label="Masuk Menggunakan Google"
                    className="text-capitalize p-button-rounded p-button-outlined p-button-lg"
                  />
                  <div className="text-separator">
                    <span>ATAU MENGGUNAKAN NO. TELEPON</span>
                  </div>
                  <div className="p-field mt-0">
                    <InputText
                      id="phone"
                      aria-describedby="phone-number"
                      className="p-invalid p-d-block"
                      placeholder="Masukkan Nomor HP"
                    />
                    <small
                      id="phone-number"
                      className="p-error p-d-block p-text-right"
                    >
                      Phone Number is not valid.
                    </small>
                  </div>
                  <Button
                    label="Daftar"
                    className="p-button-rounded p-button-lg p-mt-3"
                  />
                  <div className="footer-form">
                    <p>
                      Belum memiliki akun? <a href="/#">Daftar Sekarang</a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col-6 p-px-0 p-py-0">
              <div className="iso-login">
                <img src={IsoLogin} alt="iso-login" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginEmailPage;
