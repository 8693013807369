/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { resetStateAuthAction } from "../../../../redux/action/authAction";

const SettingsModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { successEmailLogin } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successEmailLogin) {
      dispatch(resetStateAuthAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successEmailLogin]);

  return (
    <div className="col-lg-12 col-md-12 col-sm-12">
      <div className="card card-custom-detail setting w-full has-shadow mb-5">
        <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Ubah Email
              </span>
            </h3>
          </div>
          <div>
            <a
              className="btn px-0 py-o ml-3"
              onClick={() => history.push("/admin/pm/settings/email")}
            >
              <span className="icon-edit-o" />
            </a>
          </div>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="notif-settings">
            <div className="notif-icon">
              <span className="material-icons">check_cirlce</span>
            </div>
            <label>Email anda telah diatur</label>
            <p>
              Anda mendaftar dengan menggunakan email. Anda bisa mengubah email
              untuk login.
            </p>
          </div>
        </div>
      </div>
      <div className="card card-custom-detail setting w-full has-shadow mb-5">
        <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
          <div className="d-flex">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Ubah Kata Sandi
              </span>
            </h3>
          </div>
          <div>
            <a
              className="btn px-0 py-o ml-3"
              onClick={() => history.push("/admin/pm/settings/password")}
            >
              <span className="icon-edit-o" />
            </a>
          </div>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="notif-settings">
            <div className="notif-icon">
              <span className="material-icons">check_cirlce</span>
            </div>
            <label>Kata sandi anda telah diatur</label>
            <p>
              Anda mendaftar dengan menggunakan kata sandi. Anda bisa mengubah
              kata sandi untuk login.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModule;
