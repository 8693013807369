import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation, useHistory } from "react-router-dom";
import { HeaderLoad } from "../loading/loadingContent";
import NotificationMobile from "./NotificationMobile";

import firebase from "../../../redux/service/firebase/firebase";
import {
  logoutAction,
  myProfileAction,
} from "../../../redux/action/authAction";

const HeaderMobileLanding = (props) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const firestore = firebase.firestore();
  const { loading, dataSite } = props;
  const [mobileMenu, setMobileMenu] = useState(false);
  const [notifMenu, setNotifMenu] = useState(false);
  const [notifList, setNotifList] = useState([]);

  const { users } = useSelector((state) => state.auth);
  const counter = notifList?.filter((item) => item.isRead);
  const notifCount = counter?.length < 1 ? false : true;

  useEffect(() => {
    if (users) {
      getListNotif();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    dispatch(myProfileAction(users?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListNotif = async () => {
    return await firestore
      .collection("userNotifications")
      .where("target.value", "in", [users?.uid])
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            id: doc.id,
          })
        );
        setNotifList(temp);
      });
  };

  const changeMenu = () => {
    setMobileMenu(!mobileMenu);
    setNotifMenu(false);
  };

  const changeNotif = () => {
    setMobileMenu(false);
    setNotifMenu(!notifMenu);
  };

  const logout = (e) => {
    e.preventDefault();
    dispatch(logoutAction());
  };

  const toProfile = (e) => {
    e.preventDefault();
    if (users?.user?.type === "writer") {
      history.push("/profile");
    } else {
      history.push("/client/profile");
    }
  };

  const renderMenu = () => {
    switch (users?.user?.type) {
      case "writer":
        return (
          <div className="main-menu-mobile">
            <Link to="/dashboard">
              <span
                className={location?.pathname === "dashboard" ? "active" : ""}
              >
                Dashboard
              </span>
            </Link>
            <Link to="/project">
              <span
                className={location?.pathname === "/project" ? "active" : ""}
              >
                Project
              </span>
            </Link>
            <Link to="/balance">
              <span
                className={location?.pathname === "/balance" ? "active" : ""}
              >
                Balance
              </span>
            </Link>
            <Link to="/settings">
              <span
                className={location?.pathname === "/settings" ? "active" : ""}
              >
                Pengaturan
              </span>
            </Link>
          </div>
        );

      case "client":
        return (
          <div className="main-menu-mobile">
            <Link to="/client/dashboard">
              <span
                className={
                  location?.pathname === "/client/dashboard" ? "active" : ""
                }
              >
                Dashboard
              </span>
            </Link>
            <Link to="/client/order">
              <span
                className={
                  location?.pathname === "/client/order" ? "active" : ""
                }
              >
                Layanan & Produk
              </span>
            </Link>
            <Link to="/client/my-order/process">
              <span
                className={
                  location?.pathname === "/client/my-order/process"
                    ? "active"
                    : ""
                }
              >
                Invoice
              </span>
            </Link>
            <Link to="/client/my-order/confirm">
              <span
                className={
                  location?.pathname === "/client/my-order/confirm"
                    ? "active"
                    : ""
                }
              >
                Project
              </span>
            </Link>
            <Link to="/client/list-release">
              <span
                className={
                  location?.pathname === "/client/list-release" ? "active" : ""
                }
              >
                Media Distribusi
              </span>
            </Link>
            <Link to="/client/pic-list">
              <span
                className={
                  location?.pathname === "/client/pic-list" ? "active" : ""
                }
              >
                Manajemen Tim
              </span>
            </Link>
            <Link to="/client/settings">
              <span
                className={
                  location?.pathname === "/client/settings" ? "active" : ""
                }
              >
                Pengaturan
              </span>
            </Link>
          </div>
        );

      default:
        return (
          <div className="main-menu-mobile">
            <Link to="/">
              <span className={location?.pathname === "/" ? "active" : ""}>
                Home
              </span>
            </Link>
            <Link to="/service">
              <span
                className={location?.pathname === "/service" ? "active" : ""}
              >
                Layanan
              </span>
            </Link>
            <Link to="/about-us">
              <span
                className={location?.pathname === "/about-us" ? "active" : ""}
              >
                Tentang Kami
              </span>
            </Link>
            <Link to="/headline-generator">
              <span
                className={location?.pathname === "/headline-generator" ? "active" : ""}
              >
                Headline Generator
              </span>
            </Link>
          </div>
        );
    }
  };

  return (
    <div className="header-mobile-wrapp">
      <div className="header-mobile-content">
        {loading ? (
          <HeaderLoad />
        ) : (
          <>
            <div className="menu-toggler p-d-flex">
              <button className="btn-icon-outline" onClick={() => changeMenu()}>
                <span className="icon-menu" />
              </button>
            </div>
            <Link className="header-mobile-logo" to="/dashboard">
              <img src={dataSite?.logo} alt="logo" />
            </Link>
            <div className="header-mobile-right">
              {users && (
                <button
                  className="btn-icon-outline"
                  onClick={() => changeNotif()}
                >
                  {notifCount && <div className="dot-noty" />}
                  <span className="icon-notif" />
                </button>
              )}
            </div>
          </>
        )}
      </div>
      <div className={`header-mobile-menu ${mobileMenu ? "d-flex" : "d-none"}`}>
        {users && (
          <div className="auth-box">
            {/* <div className="auth-img">
              <img src={users?.user?.photoURL} alt="image_profile" />
            </div> */}
            <div className="auth-profile">
              <div className="auth-name">
                {users?.user?.name} 
                <span>{users?.user?.type}</span>
              </div>
              <div className="auth-link" onClick={toProfile}>
                Profil Saya
              </div>
              <div className="dot" />
              <div className="auth-link logout" onClick={logout}>
                Logout
              </div>
            </div>
          </div>
        )}
        {renderMenu()}
        {!users && (
          <div className="auth-menu-mobile">
            <Link to="/create-account">
              <span
                className={
                  location?.pathname === "/register-account" ? "active" : ""
                }
              >
                Daftar
              </span>
            </Link>
            <Link to="/login">
              <span className={location?.pathname === "/login" ? "active" : ""}>
                Login
              </span>
            </Link>
          </div>
        )}
      </div>
      {notifMenu && <NotificationMobile notifList={notifList} />}
    </div>
  );
};

export default HeaderMobileLanding;
