/* eslint-disable no-restricted-imports */
import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";
import _ from "lodash";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import ButtonDefault from "../../../../components/button/ButtonDefault";
import { listPayoutFinanceAction } from "../../../../../redux/action/transactionAction";

const FilterReqPayout = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const thisYear = dayjs().format("YYYY");
  const thisMonth = dayjs().format("M");

  const filterMonths = [
    { name: "Bulan Berjalan", code: 0 },
    { name: "Januari", code: 1 },
    { name: "Februari", code: 2 },
    { name: "Maret", code: 3 },
    { name: "April", code: 4 },
    { name: "Mei", code: 5 },
    { name: "Juni", code: 6 },
    { name: "Juli", code: 7 },
    { name: "Agustus", code: 8 },
    { name: "September", code: 9 },
    { name: "Oktober", code: 10 },
    { name: "November", code: 11 },
    { name: "Desember", code: 12 },
  ];

  const [yearList, yearListChange] = useState([]);
  const [yearState, yearStateChange] = useState(parseInt(thisYear));
  const [monthState, monthStateChange] = useState(0);

  const { users } = useSelector((state) => state.auth);
  const isFinance = users?.user?.type === "finance";

  useEffect(() => {
    generateYear();
  }, []);

  const generateYear = () => {
    const maxYear = 2040;
    const minYear = 2020;
    let years = [];
    for (let i = minYear; i <= maxYear; i++) {
      years.push({ code: i, name: i });
    }
    yearListChange(years);
  };

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
      };

      history.push(
        `/admin/${
          isFinance ? "finance" : "pm"
        }/list-request-payout?search=${search}&page=1`
      );
      dispatch(listPayoutFinanceAction(params));
    }, 1000)
  ).current;

  const filterMonth = (value) => {
    monthStateChange(value);
    if (value === 0) {
      yearStateChange(parseInt(thisYear));
    }
  };

  const filterYear = (value) => {
    yearStateChange(value);
  };

  const setFilter = () => {
    history.push(
      `/admin/${isFinance ? "finance" : "pm"}/list-request-payout?month=${
        monthState === 0 ? thisMonth : monthState
      }&year=${monthState === 0 ? thisYear : yearState}`
    );
    dispatch(
      listPayoutFinanceAction({
        page: 1,
        perpage: 10,
        year: monthState === 0 ? thisYear : yearState,
        month: monthState === 0 ? thisMonth : monthState,
      })
    );
  };

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter">
        <div className="filter-field">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block search-inputtext"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <p id="search-help" className="p-d-block">
            Cari berdasarkan <span>Nama/Nomor Rekening</span>
          </p>
        </div>
      </div>
      <div className="filter">
        <div className="filter-wrapp d-flex w-100">
          <Dropdown
            value={monthState}
            options={filterMonths}
            onChange={(e) => filterMonth(e.target.value)}
            optionLabel="name"
            optionValue="code"
            className="date-filter"
          />
          <Dropdown
            value={yearState}
            options={yearList}
            onChange={(e) => filterYear(e.target.value)}
            optionLabel="name"
            optionValue="code"
            className="ml-2 date-filter"
            disabled={monthState === 0}
          />
          <ButtonDefault
            label="Filter"
            className="ml-2 fit"
            onClick={() => setFilter()}
          />
        </div>
      </div>
    </div>
  );
};

export default FilterReqPayout;
