import { call, put } from "redux-saga/effects";
import {
  SEND_CHAT_FAILURE,
  SEND_CHAT_SUCCESS,
  SEND_NOTIF_FAILURE,
  SEND_NOTIF_SUCCESS,
  UNPIN_CHAT_FAILURE,
  UNPIN_CHAT_SUCCESS,
  SEND_CHAT_CLIENT_FAILURE,
  SEND_CHAT_CLIENT_SUCCESS,
  SEND_CHAT_CLIENT_FS_FAILURE,
  SEND_CHAT_CLIENT_FS_SUCCESS,
} from "../constant/chatConstant";
import { sendChatClient, sendNotif } from "../service/api/chat";
import {
  sendChat,
  unpinnedChat,
  sendClientChat,
} from "../service/firebase/chat";
import { getToken } from "../service/firebase/auth";

export function* sendNotifSaga(action) {
  try {
    yield call(sendChat, action.id, action.data);
    yield put({ type: SEND_CHAT_SUCCESS });
  } catch (err) {
    yield put({ type: SEND_CHAT_FAILURE, err });
  }
}

export function* sendChatSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(sendNotif, token, action.id, action.data);
  if (ok) {
    yield put({ type: SEND_NOTIF_SUCCESS });
  } else {
    yield put({ type: SEND_NOTIF_FAILURE, data });
  }
}

export function* unpinSaga(action) {
  try {
    yield call(unpinnedChat, action.projectId, action.chatId);
    yield put({ type: UNPIN_CHAT_SUCCESS });
  } catch (err) {
    yield put({ type: UNPIN_CHAT_FAILURE, err });
  }
}

export function* sendChatClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    sendChatClient,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: SEND_CHAT_CLIENT_SUCCESS });
  } else {
    yield put({ type: SEND_CHAT_CLIENT_FAILURE, data });
  }
}

export function* sendChatClientFsSaga(action) {
  try {
    yield call(sendClientChat, action.id, action.data);
    yield put({ type: SEND_CHAT_CLIENT_FS_SUCCESS });
  } catch (err) {
    yield put({ type: SEND_CHAT_CLIENT_FS_FAILURE, err });
  }
}
