import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";

export const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize(`${process.env.REACT_APP_GA_CODE}`);
    ReactGA.send(location.pathname + location.search);
  }, [location]);
};
