export const GET_FINANCE_ORDER_REQUEST = "GET_FINANCE_ORDER_REQUEST";
export const GET_FINANCE_ORDER_SUCCESS = "GET_FINANCE_ORDER_SUCCESS";
export const GET_FINANCE_ORDER_FAILURE = "GET_FINANCE_ORDER_FAILURE";

export const UPDATE_FINANCE_ORDER_REQUEST = "UPDATE_FINANCE_ORDER_REQUEST";
export const UPDATE_FINANCE_ORDER_SUCCESS = "UPDATE_FINANCE_ORDER_SUCCESS";
export const UPDATE_FINANCE_ORDER_FAILURE = "UPDATE_FINANCE_ORDER_FAILURE";

export const GET_FINANCE_PROJECT_REQUEST = "GET_FINANCE_PROJECT_REQUEST";
export const GET_FINANCE_PROJECT_SUCCESS = "GET_FINANCE_PROJECT_SUCCESS";
export const GET_FINANCE_PROJECT_FAILURE = "GET_FINANCE_PROJECT_FAILURE";

export const APPROVE_PAYOUT_REQUEST = "APPROVE_PAYOUT_REQUEST";
export const APPROVE_PAYOUT_SUCCESS = "APPROVE_PAYOUT_SUCCESS";
export const APPROVE_PAYOUT_FAILURE = "APPROVE_PAYOUT_FAILURE";

export const APPROVE_ALL_PAYOUT_REQUEST = "APPROVE_ALL_PAYOUT_REQUEST";
export const APPROVE_ALL_PAYOUT_SUCCESS = "APPROVE_ALL_PAYOUT_SUCCESS";
export const APPROVE_ALL_PAYOUT_FAILURE = "APPROVE_ALL_PAYOUT_FAILURE";

export const RESET_FINANCE_STATE = "RESET_FINANCE_STATE";
