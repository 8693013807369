import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";

import images from "../../assets/img/ellipsis-loading.gif";
import imagesSuccess from "../../assets/img/iso-success.svg";
import { titlePage } from "../../helpers/titleHead";

import { updateEmailClientAction } from "../../../redux/action/authAction";

const VerifyEmailClientPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search);
  const token = parsed.token;
  const { successUpdateEmailClient } = useSelector((state) => state.auth);

  useEffect(() => {
    titlePage({
      title: "IRIS - Verifikasi Email",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      const data = {
        token: token,
      };
      dispatch(updateEmailClientAction(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="fullpage-content">
      <div className="not-found-card">
        {!successUpdateEmailClient ? (
          <>
            <img className="loading-image" src={images} alt="loading" />
            <h4>Silahkan Tunggu..</h4>
          </>
        ) : (
          <>
            <img className="success-image" src={imagesSuccess} alt="success" />
            <h4>Email Berhasil Diubah.</h4>
            <h4>Silahkan Login Dengan Email Baru</h4>
            <Link
              className="p-button p-button-outlined p-button-rounded p-mt-4"
              to="/login"
            >
              Halaman Login
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyEmailClientPage;
