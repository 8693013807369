import React from "react";
import { LandingHeaderLoad } from "../loading/loadingContent";
import { Link, useLocation } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";

const Header = (props) => {
  const location = useLocation();
  const { loading, dataSite } = props;

  return (
    <div className="container">
      <div className="header-landing p-d-flex p-jc-between p-ai-center header-user">
        {loading ? (
          <LandingHeaderLoad />
        ) : (
          <>
            <Navbar expand="lg">
              <Navbar.Brand>
                <Link className="header-logo" to="/">
                  <img src={dataSite?.logo} alt="logo" />
                </Link>
              </Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="menu">
                  <Link to="/">
                    <span
                      className={location?.pathname === "/" ? "active" : ""}
                    >
                      Home
                    </span>
                  </Link>
                  <Link to="/service">
                    <span
                      className={
                        location?.pathname === "/service" ? "active" : ""
                      }
                    >
                      Layanan
                    </span>
                  </Link>

                  <Link to="/about-us">
                    <span
                      className={
                        location?.pathname === "/about-us" ? "active" : ""
                      }
                    >
                      Tentang Kami
                    </span>
                  </Link>
                  
                  <Link to="/headline-generator">
                    <span
                      className={
                        location?.pathname === "/headline-generator" ? "active" : ""
                      }
                    >
                      Headline Generator
                    </span>
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <div className="p-d-flex">
              <Link
                className="p-button p-button-primary mr-4 btn-wide"
                to={"/create-account"}
              >
                DAFTAR
              </Link>
              <Link
                className="p-button p-button-outlined p-button-rounded btn-wide"
                to={"/login"}
              >
                LOGIN
              </Link>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Header;
