import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import DeleteIcon from "../../assets/img/trash.svg";
import EditIcon from "../../assets/img/edit.svg";
import DialogAlert from "../dialog/DialogAlert";
import { saveLastLocation } from "../../../_metronic/_helpers";

const ActionBody = (props) => {
  const { linkEdit } = props;
  const history = useHistory();
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    if (props.isSuccess) {
      setTimeout(() => {
        setOpenDialog(false);
      }, 1500);
    }
  }, [props.isSuccess]);
  return (
    <>
      <div className="action-table">
        {!props.deleteOnly && (
          <>
            {!props.isArticleList && (
              <Link
                onClick={() => saveLastLocation(history.location)}
                to={linkEdit}
                className="btn btn-action"
              >
                <img src={EditIcon} alt="" />
              </Link>
            )}
          </>
        )}
        {props.onDelete && (
          <button
            onClick={() => setOpenDialog(true)}
            className="btn btn-action"
            disabled={props.disableButton}
          >
            <img src={DeleteIcon} alt="" />
          </button>
        )}
      </div>
      <DialogAlert
        type={"warning"}
        visible={openDialog}
        closeDialog={() => setOpenDialog(false)}
        title={`Hapus ${props.name}?`}
        text={"Data yang dihapus tidak dapat dikembalikan"}
        actionDialog={props?.onDelete}
        loading={props?.loading}
        actionText={"HAPUS"}
      />
    </>
  );
};

export default ActionBody;
