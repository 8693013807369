import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import PMDetailModule from "../../../modules/Admin/Superadmin/PMManagement/PMDetailModule";
import { titlePage } from "../../../helpers/titleHead";
import { detailUserAction } from "../../../../redux/action/userAction";

const DetailPMPage = () => {
  const dispatch = useDispatch();
  const QueryParam = useParams();
  useEffect(() => {
    dispatch(detailUserAction(QueryParam.userId));

    titlePage({
      title: "IRIS - Detail Project Manager",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Project Manager" menu="pm">
      <PMDetailModule userId={QueryParam.userId} />
    </DashboardLayout>
  );
};

export default DetailPMPage;
