import firebase from "./firebase";
import fb from "firebase/app";

const firestore = firebase.firestore();

export const getService = async () =>
  await firestore.collection("services").get();

export const createProduct = async (data) =>
  await firestore
    .collection("products")
    .doc()
    .set({ ...data, createdAt: fb.firestore.FieldValue.serverTimestamp() });

export const deleteProduct = async (id) =>
  await firestore
    .collection("products")
    .doc(id)
    .delete();

export const getProductDetail = async (id) =>
  await firestore
    .collection("products")
    .doc(id)
    .get();

export const updateProduct = async (id, data) =>
  await firestore
    .collection("products")
    .doc(id)
    .update({ ...data, updatedAt: fb.firestore.FieldValue.serverTimestamp() });
