/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";
import "dayjs/locale/id";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ImageProjectBody from "../../../components/datatableBody/ImageProjectBody";
import TitleProjectBody from "../../../components/datatableBody/TitleProjectBody";
import DateBody from "../../../components/datatableBody/DateBody";
import NumberBody from "../../../components/datatableBody/NumberBody";
import TextBody from "../../../components/datatableBody/TextBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";

import FilterListProject from "./Components/FilterListProject";
import { getFinanceProjectAction } from "../../../../redux/action/financeAction";
import StatusProject from "../../../components/badge/StatusProject";

const ListProjectModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dataTable, setDataTable] = useState([]);

  const {
    loadingGetFinanceProject,
    dataGetFinanceProject,
    totalPage,
  } = useSelector((state) => state.finance);
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    if (dataGetFinanceProject) {
      setDataTable(dataGetFinanceProject);
    }
  }, [dataGetFinanceProject]);

  const imageBodyTemplate = (item) => {
    return (
      <ImageProjectBody
        image={item?.thumbnail}
        link={`/admin/finance/detail-project/${item?._id}`}
      />
    );
  };

  const nameBodyTemplate = (item) => {
    return (
      <Link to={`/admin/finance/detail-project/${item?._id}`}>
        <TitleProjectBody
          name={item?.title}
          owner={item?.jobNumber}
          link={`/admin/finance/detail-project/${item?._id}`}
        />
      </Link>
    );
  };
  const dateBodyTemplate = (item) => {
    return (
      <DateBody
        date={item?.deadlineDate}
        link={`/admin/finance/detail-project/${item?._id}`}
      />
    );
  };

  const writerBodyTemplate = (item) => {
    return (
      <NumberBody
        number={item?.totalWinner || 0}
        link={`/admin/finance/detail-project/${item?._id}`}
      />
    );
  };

  const creatorBodyTemplate = (item) => {
    return (
      <TextBody
        text={`${item?.clientName}`}
        link={`/admin/finance/detail-project/${item?._id}`}
      />
    );
  };

  const priceBodyTemplate = (item) => {
    return (
      <NumberBody
        number={item?.price || 0}
        isPrice
        link={`/admin/finance/detail-project/${item?._id}`}
      />
    );
  };

  const statusProjectBodyTemplate = (item) => {
    const endDate = dayjs(item?.deadlineDate).toDate() < dayjs();
    const state = item?.status === "done" || endDate ? "done" : "ongoing";
    return (
      <StatusProject
        status={state}
        userType={users?.user?.type}
        link={`/admin/finance/detail-project/${item?._id}`}
      />
    );
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      status: parsed?.status || "",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getFinanceProjectAction(params));

    history.push(
      `/admin/finance/list-project?search=${parsed?.search ||
        ""}&status=${parsed?.status || ""}&page=${page + 1}`
    );
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Project
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterListProject parsed={parsed} />
          {loadingGetFinanceProject ? (
            <TableLoader />
          ) : (
            <>
              {dataTable?.length < 1 || dataTable === null ? (
                <DataEmpty />
              ) : (
                <DataTable value={dataTable} className="no-padding">
                  <Column
                    body={imageBodyTemplate}
                    headerClassName="thumbnail"
                  ></Column>
                  <Column
                    header="Nama Project"
                    body={nameBodyTemplate}
                    headerClassName="project-name"
                  ></Column>
                  <Column header="Deadline" body={dateBodyTemplate}></Column>
                  <Column
                    header="Kuota Pemenang"
                    body={writerBodyTemplate}
                  ></Column>
                  <Column
                    header="Nama Klien"
                    body={creatorBodyTemplate}
                  ></Column>
                  <Column header="Harga" body={priceBodyTemplate}></Column>
                  <Column
                    header="Status"
                    headerClassName="project-status"
                    body={statusProjectBodyTemplate}
                  ></Column>
                </DataTable>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListProjectModule;
