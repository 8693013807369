/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";

import TextBody from "../../../components/datatableBody/TextBody";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";

import FilterUserInvited from "./Components/FilterListUserInvited";
import DialogUsers from "./Components/PopupListUser";
import DialogReview from "./Components/PopupReview";

import {
  updateStatusProjectAction,
  userInvitableAction,
  resetStateProjectAction,
} from "../../../../redux/action/projectAction";

const ListUserInvitedModule = (props) => {
  const dispatch = useDispatch();
  const toast = useRef(null);

  const [openDialog, setOpenDialog] = useState(null);
  const [openReview, setOpenReview] = useState({ dialog: false, id: null });

  const {
    dataGetDetailProject,
    dataGetProjectUser,
    loadingGetProjectUser,
    loadingUpdateStatusProject,
    successAssignProject,
    userInvitableParams,
  } = useSelector((state) => state.project);
  const { successInviteUser, errInviteUser } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (successAssignProject) {
      setOpenDialog(null);
      toast.current.show({
        severity: "success",
        summary: "Berhasil!",
        detail: "Berhasil Mengundang Penulis",
      });
    }
  }, [successAssignProject]);

  useEffect(() => {
    if (successInviteUser) {
      setOpenDialog(null);
      toast.current.show({
        severity: "success",
        summary: "Berhasil!",
        detail: "Berhasil Undang Penulis",
      });
      dispatch(userInvitableAction(props.projectId, userInvitableParams));
      dispatch(resetStateProjectAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successInviteUser]);

  useEffect(() => {
    if (errInviteUser) {
      setOpenDialog(null);
      toast.current.show({
        severity: "error",
        summary: "Gagal!",
        detail: "Email Sudah Digunakan",
      });
      dispatch(resetStateProjectAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errInviteUser]);

  const textBodyTemplate = (item) => {
    return <TextBody text={item?.userData?.name || item?.writerId} />;
  };

  const actionBodyTemplate = (item) => {
    let label = "Pilih Tulisan";
    if (
      dataGetDetailProject?.category === "private" &&
      item?.status === "winner"
    ) {
      label = "Terpilih";
    }
    if (
      dataGetDetailProject?.category === "public" &&
      item?.status === "winner"
    ) {
      label = "Pemenang";
    }
    return (
      <div className="action-table">
        <button
          onClick={() => setOpenReview({ dialog: true, id: item.id })}
          className="btn btn-action text"
          disabled={item?.status === "ongoing"}
        >
          Review
        </button>
        <button
          onClick={() => selectWinner(item.id)}
          className="btn btn-action text"
          disabled={loadingUpdateStatusProject || item?.status !== "sent"}
        >
          {label}
        </button>
      </div>
    );
  };

  const selectWinner = (id) => {
    const data = { status: "winner" };
    const query = "";
    dispatch(updateStatusProjectAction(id, data, query));
  };
  return (
    <>
      <Toast ref={toast} />
      <div className="card card-custom">
        <div className="card-header border-0 py-5 start">
          <Link to="/admin/pm/list-project" className="button-back">
            <span className="icon-back"></span>
          </Link>

          <h3 className="card-title align-items-start flex-column ml-3">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Penulis Project {dataGetDetailProject?.projectTitle}
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterUserInvited
            setDialogUser={() => setOpenDialog("users")}
            privateProject={dataGetDetailProject?.category === "private"}
          />
          {loadingGetProjectUser ? (
            <TableLoader />
          ) : (
            <>
              {dataGetProjectUser?.length < 1 || dataGetProjectUser === null ? (
                <DataEmpty />
              ) : (
                <>
                  <DataTable value={dataGetProjectUser} className="no-padding">
                    <Column header="Nama" body={textBodyTemplate}></Column>
                    <Column
                      headerClassName="action text"
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <DialogUsers
        dialogUser={openDialog}
        openDialogInvite={() => setOpenDialog("invite")}
        openDialogUsers={() => setOpenDialog("users")}
        closeDialog={() => setOpenDialog(null)}
      />
      <DialogReview
        dialogReview={openReview.dialog}
        projectId={openReview.id}
        closeReview={() => setOpenReview({ dialog: false, id: null })}
      />
    </>
  );
};

export default ListUserInvitedModule;
