/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Document, Page, pdfjs } from "react-pdf";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { getToken } from "../../../../redux/service/firebase/auth";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import LinkEdit from "../../../components/button/LinkEdit";

import ImageLink from "../../../assets/img/icon-link.svg";
import { avatarLabel, isDefaultPhoto } from "../../../helpers/profileHelper";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const ProfileModule = (props) => {
  const { user } = props;
  const [dialogPreview, setDialogPreview] = useState({
    dialog: false,
    data: null,
    id: null,
  });

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const { users } = useSelector((state) => state.auth);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const downloadFile = async (id) => {
    const token = await getToken();
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1//writer/users/${users?.uid}/portofolio/${id}?token=${token}`,
      "_blank"
    );
  };

  const changePage = (offset) => {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const previousPage = () => {
    changePage(-1);
  };

  const nextPage = () => {
    changePage(1);
  };
  return (
    <>
      <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="card card-custom-detail profile w-full mb-5 border-0 has-padding-x">
          <div className="card-body py-5 p-px-0">
            <div className="d-flex justify-content-between">
              <div className="user-info-box pm">
                <div className="brief-wrapper">
                  <div className="pict-wrapper">
                    <div className="pict">
                      {isDefaultPhoto(user?.photoURL) || !user?.photoURL ? (
                        <span>{avatarLabel(users)}</span>
                      ) : (
                        <img src={user?.photoURL || user?.photo} alt="photo" />
                      )}
                    </div>
                  </div>
                  <div className="desc-info">
                    <ul>
                      <li>
                        <h3>{users?.user?.name}</h3>
                      </li>
                      <li>
                        <span className="icon-line-message" />
                        {user?.email}
                      </li>
                      <li>
                        <span className="icon-line-call" />
                        {user?.phone || "-"}
                      </li>
                      {user?.type !== "client" ? (
                        <li>
                          <span className="icon-line-location" />
                          {`${user?.domicile?.address}, ${user?.domicile?.cities}, ${user?.domicile?.province}`}
                        </li>
                      ) : (
                        <li>
                          <span className="icon-line-location" />
                          {`${user?.companyAddress}`}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="user-attr writer">
                <div>
                  <LinkEdit link="/profile/edit" label="Edit Profil" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="card card-custom-detail profile w-full mb-5 border-0 has-padding-x">
          <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center p-px-0">
            <div className="d-flex">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Biografi
                </span>
              </h3>
            </div>
          </div>
          <div className="card-body pt-0 pb-3 p-px-0">
            <div className="biography-text">
              <p>{user?.biography || "Biografi masih belum diatur"}</p>
            </div>

            <div className="p-py-5 portfolio-list user">
              <p className="count-folio">
                Portofolio ({user?.portofolio?.length})
                <Link className="ml-3" to={"/profile/add-portfolio"}>
                  <span className="icon-add-o"></span>
                </Link>
              </p>
              {user?.portofolio?.map((item, i) => (
                <div
                  className="p-d-flex p-jc-between folio-box"
                  key={item?.title}
                >
                  <div className="p-mb-3">
                    <label className="p-d-block folio-name">
                      {item?.title}
                    </label>
                    <span className="folio-year">{item?.year}</span>
                  </div>
                  <a
                    href="#"
                    onClick={() =>
                      setDialogPreview({
                        dialog: true,
                        data: item,
                        id: i,
                      })
                    }
                    className="btn-wide p-button p-button-outlined p-button-rounded sm-rounded text-uppercase p-px-3"
                  >
                    Lihat Portfolio
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="normal-dialog">
        <Dialog
          className="dialog-preview-portfolio"
          header={dialogPreview?.data?.title}
          visible={dialogPreview.dialog}
          style={{ width: "700px" }}
          onHide={() =>
            setDialogPreview({
              dialog: false,
              data: null,
              id: null,
            })
          }
        >
          <div className="portfolio-file">
            {users?.user?.portofolio[
              dialogPreview.id
            ]?.linkPortofolio?.includes(
              "https://firebasestorage.googleapis.com/v0/"
            ) ? (
              <>
                <Document
                  file={{
                    url: dialogPreview?.data?.linkPortofolio,
                  }}
                  onLoadSuccess={onDocumentLoadSuccess}
                  onLoadError={console.error}
                  crossorigin="anonymous"
                  className="pdf-file"
                >
                  <Page className="preview-page" pageNumber={pageNumber} />
                </Document>
                <div className="pagination-preview p-d-flex align-items-center">
                  <button
                    className="btn"
                    disabled={pageNumber <= 1}
                    onClick={previousPage}
                  >
                    <ChevronLeftIcon />
                  </button>
                  <p>
                    Page {pageNumber || (numPages ? 1 : "--")} of{" "}
                    {numPages || "--"}
                  </p>
                  <button
                    className="btn"
                    disabled={pageNumber >= numPages}
                    onClick={nextPage}
                  >
                    <ChevronRightIcon />
                  </button>
                </div>

                <Button
                  label="Download"
                  className="p-button-rounded p-btn-lg p-w-100 p-mt-4"
                  onClick={() => downloadFile(dialogPreview.id)}
                />
              </>
            ) : (
              <>
                <div className="p-d-flex p-jc-center">
                  <img
                    src={ImageLink}
                    alt="tautan"
                    className="image-dialog-portfolio"
                  />
                </div>
                <a
                  href={dialogPreview?.data?.linkPortofolio}
                  className="p-button p-button-primary p-component p-button-rounded p-btn-lg p-w-100 p-mt-4"
                  target="_blank"
                >
                  Kunjungi
                </a>
              </>
            )}

            <Link
              className="p-button p-button-rounded p-button-outlined p-btn-lg p-w-100 p-mt-4"
              to={`/profile/edit-portfolio/${dialogPreview.id}`}
            >
              Edit
            </Link>
          </div>
        </Dialog>
      </div>
    </>
  );
};

export default ProfileModule;
