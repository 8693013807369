import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { getPICDetailAction } from "../../../redux/action/clientPicAction";
import { getOrderAction } from "../../../redux/action/orderAction";
import { titlePage } from "../../helpers/titleHead";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import PICDetailModule from "../../modules/Client/PICManagement/PICDetailModule";

const PICDetailPage = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Tim",
    });
    dispatch(getPICDetailAction(QueryParams?.picId));
    dispatch(getOrderAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="myrelease-page">
      <DashboardClientLayout
        title="Manajemen Tim"
        mobileTitle="Detail Tim"
        menu="team"
      >
        <PICDetailModule />
      </DashboardClientLayout>
    </div>
  );
};

export default PICDetailPage;
