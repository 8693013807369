import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import md5 from "crypto-js/md5";

import { useLocation } from "react-router-dom";
import queryString from "query-string";

import ChatUserLayout from "../../layouts/chatLayout";
import ChatBoxWriter from "../../modules/Chat/ChatWriterModule";

import {
  getMyProjectAction,
  getDetailProjectAction,
  getLogArticleAction,
  getArticleAction,
} from "../../../redux/action/projectAction";

import { titlePage } from "../../helpers/titleHead";

const ChatPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const parsed = queryString.parse(location.search);
  const projectId = parsed.project;

  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMyProjectAction(users?.uid));

    titlePage({
      title: "IRIS - Message",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (projectId !== undefined) {
      const hashId = md5(projectId + users?.uid);
      dispatch(getDetailProjectAction(projectId));
      dispatch(getArticleAction(hashId.toString()));
      dispatch(getLogArticleAction(hashId.toString()));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  return (
    <ChatUserLayout>
      <ChatBoxWriter projectIdUrl={projectId} />
    </ChatUserLayout>
  );
};

export default ChatPage;
