export const isDefaultPhoto = (url) => {
  /*es-lin disable no-unused-expressions*/
  return url?.includes(process.env.REACT_APP_IMAGE_USER);
};

export const avatarLabel = (data) => {
  if (data?.user?.name) {
    return data?.user?.name.split("")[0];
  } else {
    return data?.user?.email.split("")[0];
  }
};
