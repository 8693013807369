import {
  GET_BANNER_REQUEST,
  GET_BENEFIT_REQUEST,
  GET_HOW_DOES_IT_WORK_REQUEST,
  GET_TESTIMONIAL_REQUEST,
  GET_SETTING_SITE_REQUEST,
  GET_TOP_WRITER_REQUEST,
  GET_ARTICLES_REQUEST,
  GET_DETAIL_ARTICLE_REQUEST,
  GET_FOOTER_REQUEST,
  GET_ABOUT_REQUEST,
  GET_TERM_REQUEST,
  GET_PRIVACY_REQUEST,
  GET_FAQ_REQUEST,
  TYPE_PRODUCT_REQUEST,
  GET_STATIC_PAGES_REQUEST,
} from "../constant/landingpageConstant";

export const getBannerAction = () => {
  return {
    type: GET_BANNER_REQUEST,
  };
};

export const getBenefitAction = () => {
  return {
    type: GET_BENEFIT_REQUEST,
  };
};

export const getHowDoesItWorkAction = () => {
  return {
    type: GET_HOW_DOES_IT_WORK_REQUEST,
  };
};

export const getFooterAction = () => {
  return {
    type: GET_FOOTER_REQUEST,
  };
};

export const getTestimonialAction = () => {
  return {
    type: GET_TESTIMONIAL_REQUEST,
  };
};

export const getSettingSiteAction = () => {
  return {
    type: GET_SETTING_SITE_REQUEST,
  };
};

export const getTopWriterAction = () => {
  return {
    type: GET_TOP_WRITER_REQUEST,
  };
};

export const getListArticleAction = () => {
  return {
    type: GET_ARTICLES_REQUEST,
  };
};

export const getDetailArticleAction = (id) => {
  return {
    type: GET_DETAIL_ARTICLE_REQUEST,
    id,
  };
};

export const getAboutAction = () => {
  return {
    type: GET_ABOUT_REQUEST,
  };
};

export const getTermAction = () => {
  return {
    type: GET_TERM_REQUEST,
  };
};

export const getPrivacyAction = () => {
  return {
    type: GET_PRIVACY_REQUEST,
  };
};

export const getFaqAction = (query) => {
  return {
    type: GET_FAQ_REQUEST,
    query,
  };
};

export const getTypeProductAction = () => {
  return {
    type: TYPE_PRODUCT_REQUEST,
  };
};

export const getStaticPageAction = (slug) => {
  return {
    type: GET_STATIC_PAGES_REQUEST,
    slug,
  };
};
