import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DetailProjectFinanceModule from "../../../modules/Admin/Finance/DetailProjectFinanceModule";
import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getDetailProjectAction,
  resetStateProjectAction,
} from "../../../../redux/action/projectAction";

const DetailProjectFinance = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();

  useEffect(() => {
    dispatch(getDetailProjectAction(QueryParams.projectId));
    dispatch(resetStateProjectAction);

    titlePage({
      title: "IRIS - Detail Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardFinanceLayout title="Manajemen Project" menu="project">
      <DetailProjectFinanceModule projectId={QueryParams.projectId} />
    </DashboardFinanceLayout>
  );
};

export default DetailProjectFinance;
