import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";

import { UploadThumbnail } from "../../../components/upload/uploadThumbnail";
import { updateOrderAction } from "../../../../redux/action/orderAction";
import { updateProjectFsAction } from "../../../../redux/action/projectAction";
import ButtonDefault from "../../../components/button/ButtonDefault";
import FullContent from "../../../components/loading/fullContentLoader";

function UploadPaymentModule(props) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [nameState, setNameState] = useState({ value: "", error: null });
  const [numberState, setNumberState] = useState({ value: "", error: null });
  const [bankState, setBankState] = useState({ value: "", error: null });
  const [nominalState, setNominalState] = useState({ value: "", error: null });
  const [imageState, setImageState] = useState({ value: null, error: null });

  const {
    dataDetailOrderClient,
    loadingUpdateOrder,
    loadingDetailOrderClient,
  } = useSelector((state) => state.order);
  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ value: dataUploadImage, error: null });
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (dataDetailOrderClient !== null) {
      setNominalState({
        value: dataDetailOrderClient?.totalPrice?.toString(),
        error: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailOrderClient?.totalPrice]);

  const submitData = () => {
    if (nameState?.value?.length < 1) {
      setNameState({ ...nameState, error: "Nama Rekening harus diisi" });
    }
    if (numberState?.value?.length < 1) {
      setNumberState({ ...numberState, error: "Nomor Rekening harus diisi" });
    }
    if (
      numberState?.value?.length > 0 &&
      !numberState?.value?.match(/^[0-9]+$/)
    ) {
      setNumberState({ ...numberState, error: "Nomor Rekening tidak valid" });
    }
    if (bankState?.value?.length < 1) {
      setBankState({ ...bankState, error: "Nama Bank harus diisi" });
    }
    if (nominalState?.value?.length < 1) {
      setNominalState({
        ...nominalState,
        error: "Jumlah Transfer harus diisi",
      });
    }
    if (imageState?.value === null) {
      setImageState({ ...imageState, error: "Silahkan upload bukti transfer" });
    }
    if (
      nameState?.value?.length > 0 &&
      numberState?.value?.length > 0 &&
      numberState?.value?.match(/^[0-9]+$/) &&
      bankState?.value?.length > 0 &&
      nominalState?.value?.length > 0 &&
      imageState?.value !== null
    ) {
      const data = {
        status: "confirmation_waiting",
        paymentConfirmation: {
          accountName: nameState.value,
          accountNumber: numberState.value,
          accountBank: bankState.value,
          nominal: parseInt(nominalState.value),
          paymentAttachment: imageState.value,
        },
      };
      dispatch(updateOrderAction(props.orderId, data));
      dispatch(updateProjectFsAction(props.orderId, { isPaid: true }));
    }
  };

  return (
    <div className="p-d-flex p-jc-center">
      <div className="col-lg-8 col-md-8 col-sm-12 upload-transfer">
        <div className="card card-custom mb-4 payment-detail">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <Link onClick={() => history.goBack()}>
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-7"></span>Upload Bukti Transfer
                </span>
              </Link>
            </h3>
          </div>
          <div className="card-body">
            {loadingDetailOrderClient ? (
              <>
                <FullContent />
                <FullContent />
              </>
            ) : (
              <>
                <div className="helper-blue p-d-flex p-jc-between">
                  <span className="icon-info"></span>
                  <p>
                    Silahkan upload bukti transfer anda, kami akan memproses
                    pesanan anda dengan waktu maksimal{" "}
                    <strong>2 hari kerja (jam 08.00 - 17.00 WIB)</strong>. Kami
                    akan mengirimkan email jika pesanan sudah dikonfirmasi.
                  </p>
                </div>
                <div className="upload-payment-form">
                  <div className="p-field">
                    <label className="p-d-block">Nama Rekening Pengirim</label>
                    <InputText
                      className={`p-d-block ${nameState.error && "p-invalid"}`}
                      type="text"
                      placeholder="Masukkan Nama Rekening"
                      value={nameState.value}
                      onChange={(e) =>
                        setNameState({ value: e.target.value, error: null })
                      }
                    />
                    {nameState.error && (
                      <small className="p-error p-d-block p-text-right">
                        {nameState.error}
                      </small>
                    )}
                  </div>
                  <div className="p-field">
                    <label className="p-d-block">Nomor Rekening Pengirim</label>
                    <InputText
                      className={`p-d-block ${numberState.error &&
                        "p-invalid"}`}
                      type="text"
                      placeholder="Masukkan Nomor Rekening"
                      onChange={(e) =>
                        setNumberState({ value: e.target.value, error: null })
                      }
                    />
                    {numberState.error && (
                      <small className="p-error p-d-block p-text-right">
                        {numberState.error}
                      </small>
                    )}
                  </div>
                  <div className="p-field">
                    <label className="p-d-block">Nama Bank Pengirim</label>
                    <InputText
                      className={`p-d-block ${bankState.error && "p-invalid"}`}
                      type="text"
                      placeholder="Masukkan Nama Bank"
                      onChange={(e) =>
                        setBankState({ value: e.target.value, error: null })
                      }
                    />
                    {bankState.error && (
                      <small className="p-error p-d-block p-text-right">
                        {bankState.error}
                      </small>
                    )}
                  </div>
                  <div className="p-field">
                    <label className="p-d-block">Jumlah Transfer (Rp)</label>
                    <InputText
                      className={`p-d-block ${nominalState.error &&
                        "p-invalid"}`}
                      type="text"
                      placeholder="Masukkan Jumlah Transfer"
                      value={nominalState.value}
                      onChange={(e) =>
                        setNominalState({ value: e.target.value, error: null })
                      }
                    />
                    {nominalState.error && (
                      <small className="p-error p-d-block p-text-right">
                        {nameState.error}
                      </small>
                    )}
                  </div>

                  <div className="upload-transfer">
                    <h3 className="text-center">Upload Bukti Transfer</h3>
                    <div className="p-d-flex p-jc-center">
                      <div className="upload">
                        <UploadThumbnail
                          attachment={imageState.value}
                          path="payment"
                          deleteImage={() =>
                            setImageState({ value: null, error: null })
                          }
                          errorText={imageState.error}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div className="card-footer p-d-flex p-jc-end">
            <ButtonDefault
              type="rounded"
              label="KIRIM"
              onClick={() => submitData()}
              loading={loadingUpdateOrder}
              disabled={loadingDetailOrderClient}
              className="p-button p-button-primary"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default UploadPaymentModule;
