import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

import ListReqPayoutModule from "../../../modules/Admin/Finance/ListReqPayoutModule";
import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { listPayoutFinanceAction } from "../../../../redux/action/transactionAction";

import { titlePage } from "../../../helpers/titleHead";

const ListReqPayout = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { users } = useSelector((state) => state.auth);
  const isFinance = users?.user?.type === "finance";
  const thisYear = dayjs().format("YYYY");
  const thisMonth = dayjs().format("M");

  useEffect(() => {
    history.push(
      `/admin/${
        isFinance ? "finance" : "pm"
      }/list-request-payout?month=${thisMonth}&year=${thisYear}`
    );
    dispatch(
      listPayoutFinanceAction({
        page: 1,
        perpage: 10,
        year: thisYear,
        month: thisMonth,
      })
    );
    titlePage({
      title: "IRIS - Manajemen Request Payout",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFinance ? (
    <DashboardFinanceLayout title="Manajemen Request Payout" menu="payout">
      <ListReqPayoutModule />
    </DashboardFinanceLayout>
  ) : (
    <DashboardLayout title="Manajemen Request Payout" menu="payout">
      <ListReqPayoutModule />
    </DashboardLayout>
  );
};

export default ListReqPayout;
