import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import StaticLayout from "../layouts/staticLayout";

import images from "../assets/img/not-found.svg";
import { titlePage } from "../helpers/titleHead";

const NotFound = () => {
  useEffect(() => {
    titlePage({
      title: "IRIS - Error Not Found",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticLayout>
      <div className="fullpage-content">
        <div className="not-found-card">
          <img src={images} alt="404" />
          <Link className="p-button p-button-outlined p-button-rounded" to="/">
            Halaman Utama
          </Link>
        </div>
      </div>
    </StaticLayout>
  );
};

export default NotFound;
