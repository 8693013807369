import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { usePageTracking } from "../helpers/pageTracking";
import { checkDevice } from "../helpers/deviceDetect";
import HeaderAuth from "../components/header/HeaderAuth";
import HeaderMobileLanding from "../components/header/HeaderMobileLanding";
import { getSettingSiteAction } from "../../redux/action/landingpageAction";

const AuthLayout = ({ children }) => {
  usePageTracking();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isMobile, setIsMobile] = useState(checkDevice());

  const { users, isLoggedIn } = useSelector((state) => state.auth);
  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    dispatch(getSettingSiteAction());
    setIsMobile(checkDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoggedIn && users?.user?.type === "writer") {
      history.push("/dashboard");
    }
    if (
      isLoggedIn &&
      (users?.user?.type === "pm" || users?.user?.type === "general_pm")
    ) {
      history.push("/admin/pm/list-project");
    }
    if (isLoggedIn && users?.user?.type === "client") {
      history.push("/client/dashboard");
    }
    if (isLoggedIn && users?.user?.type === "finance") {
      history.push("/admin/finance/list-invoice");
    }
  });
  return (
    <div className="auth-page">
      {isMobile ? (
        <HeaderMobileLanding
          dataSite={dataGetSettingSite}
          loading={loadingGetSettingSite}
        />
      ) : (
        <HeaderAuth />
      )}
      {children}
    </div>
  );
};

export default AuthLayout;
