import {
  GET_PROJECTS_PM_FAILURE,
  GET_PROJECTS_PM_REQUEST,
  GET_BRIEF_PROJECTS_PM_REQUEST,
  GET_PROJECTS_PM_SUCCESS,
  GET_PROJECTS_WRITER_FAILURE,
  GET_PROJECTS_WRITER_REQUEST,
  GET_PROJECTS_WRITER_SUCCESS,
  GET_MY_PROJECTS_FAILURE,
  GET_MY_PROJECTS_REQUEST,
  GET_MY_PROJECTS_SUCCESS,
  GET_DETAIL_PROJECT_FAILURE,
  GET_DETAIL_PROJECT_REQUEST,
  GET_DETAIL_PROJECT_SUCCESS,
  GET_ARTICLE_REQUEST,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_FAILURE,
  UPDATE_ARTICLE_REQUEST,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  GET_PROJECT_USER_FAILURE,
  GET_PROJECT_USER_REQUEST,
  GET_PROJECT_USER_SUCCESS,
  UPDATE_STATUS_PROJECT_FAILURE,
  UPDATE_STATUS_PROJECT_REQUEST,
  UPDATE_STATUS_PROJECT_SUCCESS,
  SUBMIT_ARTICLE_PROJECT_FAILURE,
  SUBMIT_ARTICLE_PROJECT_REQUEST,
  SUBMIT_ARTICLE_PROJECT_SUCCESS,
  GET_USER_INVITABLE_FAILURE,
  GET_USER_INVITABLE_REQUEST,
  GET_USER_INVITABLE_SUCCESS,
  ASSIGN_PROJECT_USER_FAILURE,
  ASSIGN_PROJECT_USER_REQUEST,
  ASSIGN_PROJECT_USER_SUCCESS,
  JOIN_PROJECT_WITH_TOKEN_FAILURE,
  JOIN_PROJECT_WITH_TOKEN_REQUEST,
  JOIN_PROJECT_WITH_TOKEN_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_REQUEST,
  CREATE_PROJECT_SUCCESS,
  JOIN_PROJECT_FAILURE,
  JOIN_PROJECT_REQUEST,
  JOIN_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  UPDATE_PROJECT_REQUEST,
  UPDATE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  DELETE_PROJECT_REQUEST,
  DELETE_PROJECT_SUCCESS,
  GET_LOG_ARTICLE_REQUEST,
  GET_LOG_ARTICLE_FAILURE,
  GET_LOG_ARTICLE_SUCCESS,
  RESET_STATE_PROJECT,
  UPDATE_USER_INVITABLE_PARAMS,
  RESET_PROJECT_DETAIL_DATA,
  COUNT_PROJECT_FAILURE,
  COUNT_PROJECT_REQUEST,
  COUNT_PROJECT_SUCCESS,
  UPDATE_COUNT_PROJECT_FAILURE,
  UPDATE_COUNT_PROJECT_REQUEST,
  UPDATE_COUNT_PROJECT_SUCCESS,
  GET_LIST_ARTICLES_REQUEST,
  GET_LIST_ARTICLES_SUCCESS,
  GET_LIST_ARTICLES_FAILURE,
  SAVE_DRAFT_FAILURE,
  SAVE_DRAFT_REQUEST,
  SAVE_DRAFT_SUCCESS,
  GET_DRAFT_REQUEST,
  GET_DRAFT_FAILURE,
  GET_DRAFT_SUCCESS,
  UPDATE_PROJECT_PM_STATUS_REQUEST,
  UPDATE_PROJECT_PM_STATUS_FAILURE,
  UPDATE_PROJECT_PM_STATUS_SUCCESS,
  UPDATE_ARTICLE_CLIENT_STATUS_REQUEST,
  UPDATE_ARTICLE_CLIENT_STATUS_FAILURE,
  UPDATE_ARTICLE_CLIENT_STATUS_SUCCESS,
  UPDATE_PROJECT_FS_REQUEST,
  UPDATE_PROJECT_FS_FAILURE,
  UPDATE_PROJECT_FS_SUCCESS,
  SUPERUSER_JOIN_PROJECT_FAILURE,
  SUPERUSER_JOIN_PROJECT_REQUEST,
  SUPERUSER_JOIN_PROJECT_SUCCESS,
  GET_PROJECT_ACTIVE_FAILURE,
  GET_PROJECT_ACTIVE_REQUEST,
  GET_PROJECT_ACTIVE_SUCCESS,
  CLIENT_PROJECT_OVERVIEW_FAILURE,
  CLIENT_PROJECT_OVERVIEW_REQUEST,
  CLIENT_PROJECT_OVERVIEW_SUCCESS,
  DELETE_ARTICLE_PROJECT_FAILURE,
  DELETE_ARTICLE_PROJECT_REQUEST,
  DELETE_ARTICLE_PROJECT_SUCCESS,
  GET_DETAIL_SAVE_PROJECT_REQUEST,
  GET_DETAIL_SAVE_PROJECT_SUCCESS,
  GET_DETAIL_SAVE_PROJECT_FAILURE,
  GET_BRIEF_PROJECTS_PM_SUCCESS,
  GET_BRIEF_PROJECTS_PM_FAILURE,
  GET_PROJECT_DETAIL_PM_REQUEST,
  GET_PROJECT_DETAIL_PM_SUCCESS,
  GET_PROJECT_DETAIL_PM_FAILURE,
  GET_PROJECT_DETAIL_CLIENT_REQUEST,
  GET_PROJECT_DETAIL_CLIENT_SUCCESS,
  GET_PROJECT_DETAIL_CLIENT_FAILURE,
} from "../constant/projectConstant";

const initialStateProject = {
  projectParams: {
    page: 1,
    perpage: 10,
  },
  projectWriterParams: {
    q: "",
    page: 1,
    perpage: 10,
    orderField: "",
    orderDir: "",
  },
  userInvitableParams: {
    q: "",
    page: 1,
    perpage: 10,
  },
  totalPage: "",

  loadingGetProjectPm: false,
  dataGetProjectPm: null,
  errGetProjectPm: false,

  loadingGetBriefProjectPm: false,
  dataGetBriefProjectPm: null,
  errGetBriefProjectPm: false,

  loadingGetProjectWriter: false,
  dataGetProjectWriter: null,
  errGetProjectWriter: false,

  loadingGetMyProject: false,
  dataGetMyProject: null,
  errGetMyProject: false,

  loadingGetDetailProject: false,
  dataGetDetailProject: null,
  errGetDetailProject: false,

  loadingGetSaveDetailProject: false,
  dataGetSaveDetailProject: null,
  errGetSaveDetailProject: false,

  loadingGetArticle: false,
  dataGetArticle: null,
  errGetArticle: false,

  loadingUpdateArticle: false,
  successUpdateArticle: false,
  errUpdateArticle: null,

  loadingGetProjectUser: false,
  dataGetProjectUser: null,
  errGetProjectUser: null,

  loadingUpdateStatusProject: false,
  successUpdateStatusProject: false,
  errUpdateStatusProject: null,

  loadingSubmitArticleProject: false,
  successSubmitArticleProject: false,
  errSubmitArticleProject: null,

  loadingGetUserInvitable: false,
  dataGetUserInvitable: null,
  errGetUserInvitable: null,

  loadingAssignProject: false,
  successAssignProject: false,
  errAssignProject: null,

  loadingJoinProjectWithToken: false,
  successJoinProjectWithToken: false,
  errJoinProjectWithToken: null,

  loadingCreateProject: false,
  successCreateProject: false,
  errCreateProject: null,

  loadingJoinProject: false,
  successJoinProject: false,
  errJoinProject: null,

  loadingUpdateProject: false,
  successUpdateProject: false,
  errUpdateProject: null,

  loadingDeleteProject: false,
  successDeleteProject: false,
  errDeleteProject: null,

  loadingLogArticle: false,
  dataLogArticle: null,
  errLogArticle: null,

  loadingCountProject: false,
  dataCountProject: null,
  errCountProject: null,
  successCountProject: false,

  loadingUpdateCountProject: false,
  successUpdateCountProject: false,
  errUpdateCountProject: null,

  loadingListArticles: false,
  dataListArticles: null,
  errListArticles: null,

  loadingGetDraft: false,
  dataGetDraft: null,
  errGetDraft: null,

  loadingSaveDraft: false,
  successSaveDraft: false,
  errSaveDraft: null,

  loadingUpdateProjectPmStatus: false,
  successUpdateProjectPmStatus: false,
  errUpdateProjectPmStatus: null,

  loadingUpdateArticleClientStatus: false,
  successUpdateArticleClientStatus: false,
  errUpdateArticleClientStatus: null,

  loadingUpdateProjectFs: false,
  successUpdateProjectFs: false,
  errUpdateProjectFs: null,

  loadingSuperuserJoinProject: false,
  successSuperuserJoinProject: false,
  errSuperuserJoinProject: null,
  idSuperuserJoinProject: null,

  loadingProjectActive: false,
  dataProjectActive: null,
  errProjectActive: null,

  loadingClientProjectOverview: false,
  dataClientProjectOverview: null,
  errClientProjectOverview: null,

  loadingDeleteArticleProject: false,
  successDeleteArticleProject: false,
  errDeleteArticleProject: null,
};

export const projectReducer = (state = initialStateProject, action) => {
  switch (action.type) {
    case RESET_STATE_PROJECT:
      return {
        ...state,
        loadingCreateProject: false,
        successCreateProject: false,
        errCreateProject: null,
        loadingUpdateProject: false,
        successUpdateProject: false,
        errUpdateProject: null,
        loadingJoinProject: false,
        successJoinProject: false,
        errJoinProject: null,
        loadingSubmitArticleProject: false,
        successSubmitArticleProject: false,
        errSubmitArticleProject: null,
        loadingAssignProject: false,
        successAssignProject: false,
        errAssignProject: null,
        loadingDeleteProject: false,
        successDeleteProject: false,
        errDeleteProject: null,
        loadingUpdateStatusProject: false,
        successUpdateStatusProject: false,
        errUpdateStatusProject: null,
        loadingCountProject: false,
        dataCountProject: null,
        errCountProject: null,
        successCountProject: false,
        loadingUpdateCountProject: false,
        successUpdateCountProject: false,
        errUpdateCountProject: null,
        loadingUpdateProjectPmStatus: false,
        successUpdateProjectPmStatus: false,
        errUpdateProjectPmStatus: null,
        loadingUpdateArticleClientStatus: false,
        successUpdateArticleClientStatus: false,
        errUpdateArticleClientStatus: null,
        loadingSuperuserJoinProject: false,
        successSuperuserJoinProject: false,
        errSuperuserJoinProject: null,
        idSuperuserJoinProject: null,
        loadingDeleteArticleProject: false,
        successDeleteArticleProject: false,
        errDeleteArticleProject: null,
        loadingGetProjectDetailPm: false,
        dataGetProjectDetailPm: null,
        errGetProjectDetailPm: null,
        loadingGetProjectDetailClient: false,
        dataGetProjectDetailClient: null,
        errGetProjectDetailClient: false,
      };

    case GET_PROJECTS_PM_REQUEST:
      return {
        ...state,
        loadingGetProjectPm: true,
        dataGetProjectPm: null,
        errGetProjectPm: false,
      };
    case GET_PROJECTS_PM_SUCCESS:
      return {
        ...state,
        loadingGetProjectPm: false,
        dataGetProjectPm: action.data.data,
        errGetProjectPm: false,
        totalPage: action.data.meta.total,
      };
    case GET_PROJECTS_PM_FAILURE:
      return {
        ...state,
        loadingGetProjectPm: false,
        dataGetProjectPm: null,
        errGetProjectPm: action.err,
      };
    case GET_PROJECT_DETAIL_PM_REQUEST:
      return {
        ...state,
        loadingGetProjectDetailPm: true,
        dataGetProjectDetailPm: null,
        errGetProjectDetailPm: false,
      };
    case GET_PROJECT_DETAIL_PM_SUCCESS:
      return {
        ...state,
        loadingGetProjectDetailPm: false,
        dataGetProjectDetailPm: action.data.data,
        errGetProjectDetailPm: false,
      };
    case GET_PROJECT_DETAIL_PM_FAILURE:
      return {
        ...state,
        loadingGetProjectDetailPm: false,
        dataGetProjectDetailPm: null,
        errGetProjectDetailPm: action.err,
      };
    case GET_PROJECT_DETAIL_CLIENT_REQUEST:
      return {
        ...state,
        loadingGetProjectDetailClient: true,
        dataGetProjectDetailClient: null,
        errGetProjectDetailClient: false,
      };
    case GET_PROJECT_DETAIL_CLIENT_SUCCESS:
      return {
        ...state,
        loadingGetProjectDetailClient: false,
        dataGetProjectDetailClient: action.data.data,
        errGetProjectDetailClient: false,
      };
    case GET_PROJECT_DETAIL_CLIENT_FAILURE:
      return {
        ...state,
        loadingGetProjectDetailClient: false,
        dataGetProjectDetailClient: null,
        errGetProjectDetailClient: action.err,
      };
      
    case GET_BRIEF_PROJECTS_PM_REQUEST:
      return {
        ...state,
        loadingGetBriefProjectPm: true,
        dataGetBriefProjectPm: null,
        errGetBriefProjectPm: false,
      };
    case GET_BRIEF_PROJECTS_PM_SUCCESS:
      return {
        ...state,
        loadingGetBriefProjectPm: false,
        dataGetBriefProjectPm: action.data.data,
        errGetBriefProjectPm: false,
        totalPage: action.data.meta.total,
      };
    case GET_BRIEF_PROJECTS_PM_FAILURE:
      return {
        ...state,
        loadingGetBriefProjectPm: false,
        dataGetBriefProjectPm: null,
        errGetBriefProjectPm: action.err,
      };

    case GET_PROJECTS_WRITER_REQUEST:
      return {
        ...state,
        loadingGetProjectWriter: true,
        errGetProjectWriter: false,
      };
    case GET_PROJECTS_WRITER_SUCCESS:
      return {
        ...state,
        loadingGetProjectWriter: false,
        dataGetProjectWriter: action.data.data,
        errGetProjectWriter: false,
        totalPage: action.data.meta.total,
      };
    case GET_PROJECTS_WRITER_FAILURE:
      return {
        ...state,
        loadingGetProjectWriter: false,
        dataGetProjectWriter: null,
        errGetProjectWriter: action.err,
      };

    case GET_MY_PROJECTS_REQUEST:
      return {
        ...state,
        loadingGetMyProject: true,
        errGetMyProject: false,
      };
    case GET_MY_PROJECTS_SUCCESS:
      return {
        ...state,
        loadingGetMyProject: false,
        dataGetMyProject: action.data.data,
        errGetMyProject: false,
        totalPage: action.data.totalpage,
      };
    case GET_MY_PROJECTS_FAILURE:
      return {
        ...state,
        loadingGetMyProject: false,
        dataGetMyProject: null,
        errGetMyProject: action.err,
      };

    case GET_DETAIL_PROJECT_REQUEST:
      return {
        ...state,
        loadingGetDetailProject: true,
        errGetDetailProject: false,
      };
    case GET_DETAIL_PROJECT_SUCCESS:
      return {
        ...state,
        loadingGetDetailProject: false,
        dataGetDetailProject: action.data,
        errGetDetailProject: false,
      };
    case GET_DETAIL_PROJECT_FAILURE:
      return {
        ...state,
        loadingGetDetailProject: false,
        dataGetDetailProject: null,
        errGetDetailProject: action.err,
      };

    case GET_DETAIL_SAVE_PROJECT_REQUEST:
      return {
        ...state,
        loadingGetSaveDetailProject: true,
        errGetSaveSaveDetailProject: false,
      };
    case GET_DETAIL_SAVE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingGetSaveDetailProject: false,
        dataGetSaveDetailProject: action.data,
        errGetSaveDetailProject: false,
      };
    case GET_DETAIL_SAVE_PROJECT_FAILURE:
      return {
        ...state,
        loadingGetSaveDetailProject: false,
        dataGetSaveDetailProject: null,
        errGetSaveDetailProject: action.err,
      };

    case GET_ARTICLE_REQUEST:
      return {
        ...state,
        loadingGetArticle: true,
        errGetArticle: false,
      };
    case GET_ARTICLE_SUCCESS:
      return {
        ...state,
        loadingGetArticle: false,
        dataGetArticle: action.data,
        errGetArticle: false,
      };
    case GET_ARTICLE_FAILURE:
      return {
        ...state,
        loadingGetArticle: false,
        dataGetArticle: null,
        errGetArticle: action.err,
      };

    case UPDATE_ARTICLE_REQUEST:
      return {
        ...state,
        loadingUpdateArticle: true,
        successUpdateArticle: false,
        errUpdateArticle: null,
      };
    case UPDATE_ARTICLE_SUCCESS:
      return {
        ...state,
        loadingUpdateArticle: false,
        successUpdateArticle: true,
        errUpdateArticle: null,
      };
    case UPDATE_ARTICLE_FAILURE:
      return {
        ...state,
        loadingUpdateArticle: false,
        successUpdateArticle: false,
        errUpdateArticle: action.err,
      };

    case UPDATE_STATUS_PROJECT_REQUEST:
      return {
        ...state,
        loadingUpdateStatusProject: true,
        successUpdateStatusProject: false,
        errUpdateStatusProject: false,
      };
    case UPDATE_STATUS_PROJECT_SUCCESS:
      return {
        ...state,
        loadingUpdateStatusProject: false,
        successUpdateStatusProject: true,
        errUpdateStatusProject: false,
      };
    case UPDATE_STATUS_PROJECT_FAILURE:
      return {
        ...state,
        loadingUpdateStatusProject: false,
        successUpdateStatusProject: false,
        errUpdateStatusProject: action.data,
      };

    case GET_PROJECT_USER_REQUEST:
      return {
        ...state,
        loadingGetProjectUser: true,
        errGetProjectUser: false,
      };
    case GET_PROJECT_USER_SUCCESS:
      return {
        ...state,
        loadingGetProjectUser: false,
        dataGetProjectUser: action.data.data,
        errGetProjectUser: false,
        totalPage: action.data.totalpage,
      };
    case GET_PROJECT_USER_FAILURE:
      return {
        ...state,
        loadingGetProjectUser: false,
        errGetProjectUser: action.data,
      };

    case SUBMIT_ARTICLE_PROJECT_REQUEST:
      return {
        ...state,
        loadingSubmitArticleProject: true,
        successSubmitArticleProject: false,
        errSubmitArticleProject: false,
      };
    case SUBMIT_ARTICLE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingSubmitArticleProject: false,
        successSubmitArticleProject: true,
        errSubmitArticleProject: false,
      };
    case SUBMIT_ARTICLE_PROJECT_FAILURE:
      return {
        ...state,
        loadingSubmitArticleProject: false,
        successSubmitArticleProject: false,
        errSubmitArticleProject: action.data,
      };

    case GET_USER_INVITABLE_REQUEST:
      return {
        ...state,
        loadingGetUserInvitable: true,
        errGetUserInvitable: null,
      };
    case GET_USER_INVITABLE_SUCCESS:
      return {
        ...state,
        loadingGetUserInvitable: false,
        dataGetUserInvitable: action.data.data,
        errGetUserInvitable: null,
        totalPage: action.data.meta.total,
      };
    case GET_USER_INVITABLE_FAILURE:
      return {
        ...state,
        loadingGetUserInvitable: false,
        errGetUserInvitable: action.data,
      };

    case UPDATE_USER_INVITABLE_PARAMS:
      return {
        ...state,
        userInvitableParams: action.data,
      };

    case ASSIGN_PROJECT_USER_REQUEST:
      return {
        ...state,
        loadingAssignProject: true,
        successAssignProject: false,
        errAssignProject: false,
      };
    case ASSIGN_PROJECT_USER_SUCCESS:
      return {
        ...state,
        loadingAssignProject: false,
        successAssignProject: true,
        errAssignProject: false,
      };
    case ASSIGN_PROJECT_USER_FAILURE:
      return {
        ...state,
        loadingAssignProject: false,
        successAssignProject: false,
        errAssignProject: true,
      };

    case JOIN_PROJECT_WITH_TOKEN_REQUEST:
      return {
        ...state,
        loadingJoinProjectWithToken: true,
        successJoinProjectWithToken: false,
        errJoinProjectWithToken: false,
      };
    case JOIN_PROJECT_WITH_TOKEN_SUCCESS:
      return {
        ...state,
        loadingJoinProjectWithToken: false,
        successJoinProjectWithToken: true,
        errJoinProjectWithToken: false,
      };
    case JOIN_PROJECT_WITH_TOKEN_FAILURE:
      return {
        ...state,
        loadingJoinProjectWithToken: false,
        successJoinProjectWithToken: false,
        errJoinProjectWithToken: true,
      };

    case CREATE_PROJECT_REQUEST:
      return {
        ...state,
        loadingCreateProject: true,
        successCreateProject: false,
        errCreateProject: null,
      };
    case CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingCreateProject: false,
        successCreateProject: true,
        errCreateProject: null,
      };
    case CREATE_PROJECT_FAILURE:
      return {
        ...state,
        loadingCreateProject: false,
        successCreateProject: false,
        errCreateProject: action.err,
      };

    case JOIN_PROJECT_REQUEST:
      return {
        ...state,
        loadingJoinProject: true,
        successJoinProject: false,
        errJoinProject: null,
      };
    case JOIN_PROJECT_SUCCESS:
      return {
        ...state,
        loadingJoinProject: false,
        successJoinProject: true,
        errJoinProject: null,
      };
    case JOIN_PROJECT_FAILURE:
      return {
        ...state,
        loadingJoinProject: false,
        successJoinProject: false,
        errJoinProject: action.data,
      };

    case UPDATE_PROJECT_REQUEST:
      return {
        ...state,
        loadingUpdateProject: true,
        successUpdateProject: false,
        errUpdateProject: null,
      };
    case UPDATE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingUpdateProject: false,
        successUpdateProject: true,
        errUpdateProject: null,
      };
    case UPDATE_PROJECT_FAILURE:
      return {
        ...state,
        loadingUpdateProject: false,
        successUpdateProject: false,
        errUpdateProject: action.data,
      };

    case DELETE_PROJECT_REQUEST:
      return {
        ...state,
        loadingDeleteProject: true,
        successDeleteProject: false,
        errDeleteProject: null,
      };
    case DELETE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingDeleteProject: false,
        successDeleteProject: true,
        errDeleteProject: null,
      };
    case DELETE_PROJECT_FAILURE:
      return {
        ...state,
        loadingDeleteProject: false,
        successDeleteProject: false,
        errDeleteProject: action.data,
      };

    case GET_LOG_ARTICLE_REQUEST:
      return {
        ...state,
        loadingLogArticle: true,
        dataLogArticle: null,
        errLogArticle: null,
      };
    case GET_LOG_ARTICLE_SUCCESS:
      return {
        ...state,
        loadingLogArticle: false,
        dataLogArticle: action.data,
        errLogArticle: null,
      };
    case GET_LOG_ARTICLE_FAILURE:
      return {
        ...state,
        loadingLogArticle: false,
        dataLogArticle: null,
        errLogArticle: action.err,
      };
    case RESET_PROJECT_DETAIL_DATA:
      return {
        ...state,
        dataGetDetailProject: null,
      };

    case COUNT_PROJECT_REQUEST:
      return {
        ...state,
        loadingCountProject: true,
        successCountProject: false,
        dataCountProject: null,
        errCountProject: null,
      };
    case COUNT_PROJECT_SUCCESS:
      return {
        ...state,
        loadingCountProject: false,
        dataCountProject: action.data,
        successCountProject: true,
        errCountProject: null,
      };
    case COUNT_PROJECT_FAILURE:
      return {
        ...state,
        loadingCountProject: false,
        dataCountProject: null,
        successCountProject: false,
        errCountProject: action.err,
      };

    case UPDATE_COUNT_PROJECT_REQUEST:
      return {
        ...state,
        loadingUpdateCountProject: true,
        successUpdateCountProject: false,
        errUpdateCountProject: null,
      };
    case UPDATE_COUNT_PROJECT_SUCCESS:
      return {
        ...state,
        loadingUpdateCountProject: false,
        successUpdateCountProject: true,
        errUpdateCountProject: null,
      };
    case UPDATE_COUNT_PROJECT_FAILURE:
      return {
        ...state,
        loadingUpdateCountProject: false,
        successUpdateCountProject: false,
        errUpdateCountProject: action.err,
      };

    case GET_LIST_ARTICLES_REQUEST:
      return {
        ...state,
        loadingListArticles: true,
        errListArticles: false,
      };
    case GET_LIST_ARTICLES_SUCCESS:
      return {
        ...state,
        loadingListArticles: false,
        dataListArticles: action.data.data,
        errListArticles: false,
        totalPage: action.data.totalpage,
      };
    case GET_LIST_ARTICLES_FAILURE:
      return {
        ...state,
        loadingListArticles: false,
        errListArticles: action.data,
      };

    case SAVE_DRAFT_REQUEST:
      return {
        ...state,
        loadingSaveDraft: true,
        successSaveDraft: false,
        errSaveDraft: null,
      };
    case SAVE_DRAFT_SUCCESS:
      return {
        ...state,
        loadingSaveDraft: false,
        successSaveDraft: true,
        errSaveDraft: false,
      };
    case SAVE_DRAFT_FAILURE:
      return {
        ...state,
        loadingSaveDraft: false,
        successSaveDraft: false,
        errSaveDraft: action.data,
      };

    case GET_DRAFT_REQUEST:
      return {
        ...state,
        loadingGetDraft: true,
        errGetDraft: null,
      };
    case GET_DRAFT_SUCCESS:
      return {
        ...state,
        loadingGetDraft: false,
        dataGetDraft: action.data,
        errGetDraft: false,
      };
    case GET_DRAFT_FAILURE:
      return {
        ...state,
        loadingGetDraft: false,
        errGetDraft: action.data,
      };

    case UPDATE_PROJECT_PM_STATUS_REQUEST:
      return {
        ...state,
        loadingUpdateProjectPmStatus: true,
        successUpdateProjectPmStatus: false,
        errUpdateProjectPmStatus: null,
      };
    case UPDATE_PROJECT_PM_STATUS_SUCCESS:
      return {
        ...state,
        loadingUpdateProjectPmStatus: false,
        successUpdateProjectPmStatus: true,
        errUpdateProjectPmStatus: null,
      };
    case UPDATE_PROJECT_PM_STATUS_FAILURE:
      return {
        ...state,
        loadingUpdateProjectPmStatus: false,
        successUpdateProjectPmStatus: false,
        errUpdateProjectPmStatus: action.data,
      };

    case UPDATE_ARTICLE_CLIENT_STATUS_REQUEST:
      return {
        ...state,
        loadingUpdateArticleClientStatus: true,
        successUpdateArticleClientStatus: false,
        errUpdateArticleClientStatus: null,
      };
    case UPDATE_ARTICLE_CLIENT_STATUS_SUCCESS:
      return {
        ...state,
        loadingUpdateArticleClientStatus: false,
        successUpdateArticleClientStatus: true,
        errUpdateArticleClientStatus: null,
      };
    case UPDATE_ARTICLE_CLIENT_STATUS_FAILURE:
      return {
        ...state,
        loadingUpdateArticleClientStatus: false,
        successUpdateArticleClientStatus: false,
        errUpdateArticleClientStatus: action.data,
      };

    case UPDATE_PROJECT_FS_REQUEST:
      return {
        ...state,
        loadingUpdateProjectFs: true,
        successUpdateProjectFs: false,
        errUpdateProjectFs: null,
      };
    case UPDATE_PROJECT_FS_SUCCESS:
      return {
        ...state,
        loadingUpdateProjectFs: false,
        successUpdateProjectFs: true,
        errUpdateProjectFs: null,
      };
    case UPDATE_PROJECT_FS_FAILURE:
      return {
        ...state,
        loadingUpdateProjectFs: false,
        successUpdateProjectFs: false,
        errUpdateProjectFs: action.data,
      };

    case SUPERUSER_JOIN_PROJECT_REQUEST:
      return {
        ...state,
        loadingSuperuserJoinProject: true,
        successSuperuserJoinProject: false,
        errSuperuserJoinProject: null,
      };
    case SUPERUSER_JOIN_PROJECT_SUCCESS:
      return {
        ...state,
        loadingSuperuserJoinProject: false,
        successSuperuserJoinProject: true,
        errSuperuserJoinProject: null,
        idSuperuserJoinProject: action.data.data.writerProjectId,
      };
    case SUPERUSER_JOIN_PROJECT_FAILURE:
      return {
        ...state,
        loadingSuperuserJoinProject: false,
        successSuperuserJoinProject: false,
        errSuperuserJoinProject: action.data.message,
      };

    case GET_PROJECT_ACTIVE_REQUEST:
      return {
        ...state,
        loadingProjectActive: true,
        errProjectActive: null,
      };
    case GET_PROJECT_ACTIVE_SUCCESS:
      return {
        ...state,
        loadingProjectActive: false,
        dataProjectActive: action.data.data,
        errProjectActive: false,
      };
    case GET_PROJECT_ACTIVE_FAILURE:
      return {
        ...state,
        loadingProjectActive: false,
        errProjectActive: action.data,
      };

    case CLIENT_PROJECT_OVERVIEW_REQUEST:
      return {
        ...state,
        loadingClientProjectOverview: true,
        errClientProjectOverview: null,
      };
    case CLIENT_PROJECT_OVERVIEW_SUCCESS:
      return {
        ...state,
        loadingClientProjectOverview: false,
        dataClientProjectOverview: action.data.data,
        errClientProjectOverview: false,
      };
    case CLIENT_PROJECT_OVERVIEW_FAILURE:
      return {
        ...state,
        loadingClientProjectOverview: false,
        errClientProjectOverview: action.data,
      };

    case DELETE_ARTICLE_PROJECT_REQUEST:
      return {
        ...state,
        loadingDeleteArticleProject: true,
        successDeleteArticleProject: false,
        errDeleteArticleProject: null,
      };
    case DELETE_ARTICLE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingDeleteArticleProject: false,
        successDeleteArticleProject: true,
        errDeleteArticleProject: null,
      };
    case DELETE_ARTICLE_PROJECT_FAILURE:
      return {
        ...state,
        loadingDeleteArticleProject: false,
        successDeleteArticleProject: false,
        errDeleteArticleProject: action.data,
      };

    default:
      return state;
  }
};
