import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeaderDashboardUser from "../components/header/HeaderDashboardUser";
import { getNotificationsAction } from "../../redux/action/utilsAction";

const ChatUserLayout = ({ children }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { users, isLoggined } = useSelector((state) => state.auth);
  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );
  const { successUpdateNotif } = useSelector((state) => state.utils);

  useEffect(() => {
    dispatch(getNotificationsAction(users?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successUpdateNotif) {
      dispatch(getNotificationsAction(users?.uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateNotif]);

  useEffect(() => {
    if (users === null && !isLoggined) {
      history.push("/");
    }
    if (
      users &&
      (users?.user?.type === "pm" || users?.user?.type === "general_pm")
    ) {
      history.push("/admin/pm/list-project");
    }
  });

  return (
    <div className="dashboard-user">
      <HeaderDashboardUser
        dataSite={dataGetSettingSite}
        loading={loadingGetSettingSite}
        users={users}
      />
      {children}
    </div>
  );
};

export default ChatUserLayout;
