import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useParams, useHistory } from "react-router-dom";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/id";

import { Button } from "primereact/button";
import TitleSection from "./Components/TitleSection";
import ContentSection from "./Components/ContentSection";
import ButtonLoader from "../../../components/button/ButtonLoader";
import ListLoader from "../../../components/loading/listLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";
import backIcon from '../../../assets/img/arrow-back.svg'

import {
  updateArticleAction,
  submitArticleAction,
  resetStateProjectAction,
} from "../../../../redux/action/projectAction";

const WriteArticleModule = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const history = useHistory();

  const [titleValue, setTitleValue] = useState("");
  const [imageValue, setImageValue] = useState([]);
  const [contentValue, setContentValue] = useState("");
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogErrorText, setDialogErrorText] = useState({
    title: "",
    text: "",
  });

  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );
  const {
    loadingUpdateArticle,
    successUpdateArticle,
    dataGetArticle,
    loadingGetArticle,
    loadingSubmitArticleProject,
    successSubmitArticleProject,
    errSubmitArticleProject,
  } = useSelector((state) => state.project);

  useEffect(() => {
    if (dataGetArticle !== null) {
      autoSaveRef.current(contentValue, titleValue, imageValue);
    }
  }, [contentValue, dataGetArticle, imageValue, titleValue]);

  useEffect(() => {
    if (!dataGetArticle?.articleTitle || dataGetArticle?.articleTitle === "") {
      setTitleValue("<p></p>");
    } else {
      setTitleValue(dataGetArticle?.articleTitle);
    }

    if (
      !dataGetArticle?.articleContent ||
      dataGetArticle?.articleContent === ""
    ) {
      setContentValue("<p></p>");
    } else {
      setContentValue(dataGetArticle?.articleContent);
    }

    setImageValue(dataGetArticle?.articleImage);
  }, [dataGetArticle]);

  useEffect(() => {
    if (successUploadImage) {
      setImageValue(dataUploadImage);
      const data = {
        articleTitle: titleValue,
        articleContent: contentValue,
        articleImage: dataUploadImage,
      };
      dispatch(updateArticleAction(QueryParams.projectId, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    if (successSubmitArticleProject) {
      setDialogWarning(false);
      setDialogSuccess(true);
    }
  }, [successSubmitArticleProject]);

  useEffect(() => {
    if (errSubmitArticleProject) {
      setDialogWarning(false);
      setDialogError(true);
      setDialogErrorText({
        title: "Gagal Mengirim Artikel",
        text: "Deadline project sudah terlewati",
      });
    }
  }, [errSubmitArticleProject]);

  const changeTitle = (value) => {
    setTitleValue(value);
  };

  const changeContent = (value) => {
    setContentValue(value);
  };

  const deleteImage = () => {
    setImageValue([]);
    const data = {
      articleTitle: titleValue,
      articleContent: contentValue,
      articleImage: null,
    };
    dispatch(updateArticleAction(QueryParams.projectId, data));
  };

  const autoSaveRef = useRef(
    _.debounce((contentValue, titleValue, imageValue) => {
      const data = {
        articleTitle: titleValue,
        articleContent: contentValue,
        articleImage: imageValue !== undefined ? imageValue : null,
      };

      setContentValue(contentValue);
      dispatch(updateArticleAction(QueryParams.projectId, data));
    }, 2000)
  );

  const closeSuccess = () => {
    setDialogSuccess(false);
    dispatch(resetStateProjectAction());
    history.push("/dashboard");
  };

  const closeDialogError = () => {
    setDialogError(false);
    dispatch(resetStateProjectAction());

  };

  const handlesubmit = (e) => {
    e.preventDefault();
    const data = {
      articleTitle: titleValue,
      articleContent: contentValue,
      articleImage: imageValue !== undefined ? imageValue : null,
    };
    dispatch(submitArticleAction(QueryParams.projectId, data));
  };

  const submitArticle = () => {
    const data = {
      articleTitle: titleValue,
      articleContent: contentValue,
      articleImage: imageValue !== undefined ? imageValue : null,
    };
    dispatch(updateArticleAction(QueryParams.projectId, data));
    if (
      titleValue === "" ||
      titleValue === "<p></p>" ||
      titleValue === "<p><br></p>" ||
      contentValue === "" ||
      contentValue === "<p></p>" ||
      contentValue === "<p><br></p>"
    ) {
      setDialogError(true);
      setDialogErrorText({
        title: "Artikel Masih Kosong",
        text: "Silakan membuat artikel atau konten terlebih dahulu",
      });
    } else {
      setDialogWarning(true);
    }
  };

  let autoSaveText;
  if (loadingUpdateArticle) {
    autoSaveText = "Saving...";
  }
  if (successUpdateArticle) {
    autoSaveText = "Saved";
  }

  const date = dataGetArticle?.deadline?.toDate();
  const deadline = dayjs(date).format("YYYY-MM-DD");
  dayjs.extend(relativeTime);
  const deadlineCount = dayjs(deadline)
    .locale("id")
    .fromNow();

  return (
    <>
      <div className="write-wrapper">
        {loadingGetArticle ? (
          <ListLoader />
        ) : (
          <>
            <div className="title-write">
              <h2>
                <button
                  onClick={() => history.goBack()}
                  className="button-back"
                >
                  <img src={backIcon} alt="back" />
                </button>{" "}
                {dataGetArticle?.title}
              </h2>
              <span className="deadline">
                <span className="icon-time p-mr-2"></span>
                {deadlineCount}
              </span>
            </div>
            <div className="attr-write">
              <ul>
                <li>
                  {dataGetArticle?.userData?.name || dataGetArticle?.writerId}
                </li>
                <li>
                  <span>{` | `}</span>Terakhir diupdate:{" "}
                  {dayjs(dataGetArticle?.updatedAt?.toDate())
                    .locale("id")
                    .format("DD MMMM YYYY HH:mm:ss")}
                </li>
                <li>{autoSaveText}</li>
              </ul>
            </div>
            <div className="write-body">
              <TitleSection
                titleValue={titleValue}
                imageValue={imageValue}
                changeTitle={changeTitle}
                deleteImage={() => deleteImage()}
              />
              <ContentSection
                contentValue={contentValue}
                changeContent={changeContent}
              />
            </div>
            <div className="write-footer">
              <div className="write-button">
                {loadingSubmitArticleProject ? (
                  <ButtonLoader />
                ) : (
                  <>
                    {dataGetArticle?.status !== "sent" && (
                      <Button
                        onClick={() => submitArticle()}
                        disabled={successSubmitArticleProject}
                        className="p-button-rounded p-button p-button-primary"
                      >
                        {dataGetArticle?.status !== "revision"
                          ? "KIRIM"
                          : "REVISI"}
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>

      <DialogAlert
        type={"warning"}
        actionDialog={handlesubmit}
        actionText={"KIRIM"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Kirim Artikel?"}
        text={"Pastikan artikel anda sudah sesuai"}
        loading={loadingSubmitArticleProject}
      />
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={() => closeDialogError()}
        title={dialogErrorText.title}
        text={dialogErrorText.text}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={`Berhasil ${dataGetArticle?.status !== "revision" ? `Mengirimkan` : `Merevisi`
          } Artikel`}
        text={"Artikel berhasil dikirim dan akan segera direview"}
      />
    </>
  );
};

export default WriteArticleModule;
