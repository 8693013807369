import React from "react";
import { useHistory } from "react-router-dom";

const ArticleTitleBody = (props) => {
  const { title, link } = props;
  const history = useHistory();
  const htmlRegex = /<[^>]*>/g;
  const articleTitle = title?.replace(htmlRegex, "");
  return (
    <div className="text-table" onClick={() => history.push(link)}>
      <p>{articleTitle}</p>
    </div>
  );
};

export default ArticleTitleBody;
