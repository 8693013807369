import firebase from "./firebase";

import fb from "firebase/app";
import { formattedPhoneNumber } from "../../../app/helpers/phoneNumber";

const auth = firebase.auth();
const firestore = firebase.firestore();

export const loginInvitation = async (email) =>
  await auth.signInWithEmailLink(email);

export const verifyPhoneNumber = async (phone, verify) =>
  await auth.signInWithPhoneNumber(formattedPhoneNumber(phone), verify);

export const checkCredentials = async (otpverificationId, code) =>
  await fb.auth.PhoneAuthProvider.credential(otpverificationId, code);

export const linkedUser = async (credential) =>
  await auth.currentUser.linkWithCredential(credential);

export const updatePhone = async (uid, phoneNumber) =>
  await await firestore
    .collection("users")
    .doc(uid)
    .update({
      isLinkLogin: true,
      phone: formattedPhoneNumber(phoneNumber),
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
    });

export const getUserByUid = async (uid) =>
  await firestore
    .collection("users")
    .doc(uid)
    .get();

export const logout = async () => await auth.signOut();

export const signInWithCredentials = async (credentials) =>
  await auth.signInWithCredential(credentials);

export const loginWithEmail = async (email, password) =>
  await auth.signInWithEmailAndPassword(email, password);

export const getToken = async () => {
  return new Promise(function(resolve, reject) {
    auth.onAuthStateChanged((users) =>
      users
        .getIdToken(false)
        .then((token) => {
          resolve(token);
        })
        .catch((error) => reject(error))
    );
  });
};

export const createUser = async (uid, data) =>
  await firestore
    .collection("users")
    .doc(uid)
    .set(data);

export const updateUser = async (uid, data) => {
  await firestore
    .collection("users")
    .doc(uid)
    .update({
      ...data,
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
    });}

export const checkRegistrationStatus = async (phone, onResult = () => {}) => {
  const userExist = await firestore
    .collection("users")
    .where("phone", "==", formattedPhoneNumber(phone))
    .get();

  if (userExist && userExist.docs && userExist.docs.length > 0) {
    onResult(false);
  } else {
    onResult(true);
  }
};

export const loginGoogle = async () =>
  await auth.signInWithPopup(new fb.auth.GoogleAuthProvider());

export const updatePassword = async (password) =>
  await auth.currentUser.updatePassword(password);

export const updateEmail = async (email) =>
  await auth.currentUser.verifyBeforeUpdateEmail(email);

export const verifyPhoneNumberActive = async (phone, verify) =>
  await new fb.auth.PhoneAuthProvider().verifyPhoneNumber(phone, verify);

export const updatePhoneCredential = async (credential) =>
  await auth.currentUser.updatePhoneNumber(credential);
