import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

import { updatePasswordAction } from "../../../../../redux/action/authAction";

const DialogPassword = (props) => {
  const dispatch = useDispatch();
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: null,
  });
  const [passwordConfirmState, setPasswordConfirmState] = useState({
    value: "",
    error: null,
  });
  const [passwordText, setPasswordText] = useState(false);

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Batal"
          onClick={props.closeDialog}
          className="p-button-secondary p-button-outlined p-button-rounded mr-3"
        />
        <Button
          label="Ubah"
          onClick={() => submitPassword()}
          className="p-button-primary p-button-rounded"
          autoFocus
        />
      </div>
    );
  };

  const submitPassword = () => {
    if (passwordState?.value?.length < 6) {
      setPasswordState({
        ...passwordState,
        error: "Kata sandi minimal 6 karakter",
      });
    }
    if (passwordConfirmState?.value?.length < 1) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi harus diisi",
      });
    }
    if (passwordConfirmState?.value !== passwordState.value) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi tidak sama",
      });
    }

    if (
      passwordState?.value?.length > 5 &&
      passwordConfirmState?.value === passwordState.value
    ) {
      dispatch(updatePasswordAction(passwordState.value));
    }
  };

  return (
    <div className="normal-dialog no-border-footer rounded">
      <Dialog
        header="Ubah Kata Sandi"
        visible={props.visible}
        style={{ width: "40vw" }}
        footer={renderFooter("displayBasic")}
        onHide={props.closeDialog}
      >
        <div className="form-setting p-px-4">
          <div className="p-field">
            <label className="p-d-block">Kata Sandi</label>
            <div className="show-password">
              <Checkbox
                onChange={() => setPasswordText(!passwordText)}
                checked={passwordText}
              />
              <label className="p-mb-0">Tampilkan Kata Sandi</label>
            </div>
            <InputText
              type={passwordText ? "text" : "password"}
              placeholder="Kata Sandi"
              className={`p-d-block ${passwordState?.error && "p-invalid"}`}
              value={passwordState.value}
              onChange={(e) =>
                setPasswordState({ value: e.target.value, error: null })
              }
            />
            {passwordState.error && (
              <small
                id="passwordConfirm"
                className="p-error p-d-block p-text-right"
              >
                {passwordState.error}
              </small>
            )}
          </div>
          <div className="p-field">
            <label className="p-d-block">Konfirmasi Kata Sandi</label>
            <InputText
              type={passwordText ? "text" : "password"}
              placeholder="Konfirmasi Kata Sandi"
              className={`p-d-block ${passwordConfirmState?.error &&
                "p-invalid"}`}
              value={passwordConfirmState.value}
              onChange={(e) =>
                setPasswordConfirmState({ value: e.target.value, error: null })
              }
            />
            {passwordConfirmState.error && (
              <small
                id="passwordConfirm"
                className="p-error p-d-block p-text-right"
              >
                {passwordConfirmState.error}
              </small>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogPassword;
