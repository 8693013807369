import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

import PreviewProjectModule from "../../../modules/Admin/PreviewProject/PreviewProjectModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getDetailProjectAction,
  userInvitableAction,
  resetStateProjectAction,
  getArticleAction,
} from "../../../../redux/action/projectAction";

const PreviewProjectPage = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  const writerId = parsed.writer;

  const {
    userInvitableParams,
    dataGetArticle,
    dataGetDetailProject,
  } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(getDetailProjectAction(QueryParams.projectId));
    dispatch(userInvitableAction(QueryParams.projectId, userInvitableParams));
    dispatch(getArticleAction(writerId));
    dispatch(resetStateProjectAction);

    titlePage({
      title: "IRIS - Preview Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      dataGetArticle?.projectId !== QueryParams.projectId ||
      dataGetArticle?.writerId !== writerId
    ) {
      dispatch(getDetailProjectAction(QueryParams.projectId));
      dispatch(userInvitableAction(QueryParams.projectId, userInvitableParams));
      dispatch(getArticleAction(writerId));
      dispatch(resetStateProjectAction);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QueryParams?.projectId, dataGetArticle?.projectId]);

  return (
    <DashboardLayout title="Manajemen Project" menu="article">
      <PreviewProjectModule
        articleData={dataGetArticle}
        chatId={writerId}
        projectData={dataGetDetailProject}
      />
    </DashboardLayout>
  );
};

export default PreviewProjectPage;
