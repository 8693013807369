import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Header } from "../../_metronic/layout/components/header/Header";
import { Aside } from "../../_metronic/layout/components/aside/Aside";
import { SubHeader } from "../../_metronic/layout/components/subheader/SubHeader";

import { getSettingSiteAction } from "../../redux/action/landingpageAction";

const DashboardLayout = ({ children, title, menu }) => {
  const { users, isLoggined } = useSelector((state) => state.auth);
  const { dataGetSettingSite } = useSelector((state) => state.landingpage);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (dataGetSettingSite === null) {
      dispatch(getSettingSiteAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users === null && !isLoggined) {
      history.push("/admin/login");
    }
    if (users && users?.user?.type === "writer") {
      history.push("/dashboard");
    }
    if (users && users?.user?.type === "client") {
      history.push("/client/dashboard");
    }
    if (users && users?.user?.type === "finance") {
      history.push("/admin/finance/list-invoice");
    }
  });

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row flex-column-fluid page">
        <Aside menu={menu} />
        <div className="d-flex flex-column flex-row-fluid wrapper">
          <Header />
          <div className="content d-flex flex-column flex-column-fluid">
            <SubHeader title={title} />
            <div className="d-flex flex-column-fluid">
              <div className="container-fluid">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardLayout;
