/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
// import React, { useEffect, useState, useRef } from "react";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import WriterListProject from "./Components/WriterListProject";
import WriterDetailProject from "./Components/WriterDetailProject";

import infoIcon from "../../assets/img/info-icon.svg";

import BoxLoader from "../../components/loading/boxLoader";
import ChatEmpty from "../../components/emptyState/chatEmpty";
import Discussion from "./Components/Discussion.js";

const ChatBoxWriter = (props) => {
  const location = useLocation();

  const history = useHistory();

  const parsed = queryString.parse(location.search);
  const writerId = parsed.writer;
  const { dataGetDetailProject, loadingGetDetailProject } = useSelector(
    (state) => state.project
  );

  const [isShowInfo, setIsShowInfo] = useState(false);

  return (
    <div className="chat-wrapper full-page p-mt-5">
      <div className="p-grid p-nogutter">
        <div
          className={`col-lg-3 col-md-3 col-sm-12 col-xs-12 list-project-chat ${
            props.projectIdUrl ? "hide" : ""
          }`}
        >
          <WriterListProject projectIdUrl={props.projectIdUrl} />
        </div>
        {props.projectIdUrl === undefined ? (
          <div className="p-col-9 empty-state-wrapper list-project-chat">
            <div className="box-info empty-state">
              <ChatEmpty />
            </div>
          </div>
        ) : (
          <>
            <div
              className={`col-lg-5 col-md-5 col-sm-12 col-xs-12 list-project-chat ${
                isShowInfo ? "hide" : ""
              }`}
            >
              <div className="box-info">
                <div className="header-box umkm-chat p-d-flex p-ai-center">
                  {loadingGetDetailProject ? (
                    <BoxLoader />
                  ) : (
                    <div className="p-d-flex p-jc-between p-ai-center w-100">
                      <div className="p-d-flex p-ai-center">
                        <button
                          onClick={() => history.goBack()}
                          className="button-back discussion"
                        >
                          <span className="card-label font-weight-bolder text-dark">
                            <span className="icon-back mr-2"></span>
                          </span>
                        </button>
                        <div className="pict">
                          <img
                            className="image-profile"
                            src={dataGetDetailProject?.thumbnail}
                          />
                        </div>
                        <h3 className="p-mb-0">
                          {dataGetDetailProject?.title}
                        </h3>
                      </div>
                      <button
                        className="btn-info-chat writer"
                        onClick={() => setIsShowInfo(true)}
                      >
                        <img src={infoIcon} alt="info" />
                      </button>
                    </div>
                  )}
                </div>
                <Discussion chatId={writerId} />
              </div>
            </div>
            <div
              className={`col-lg-4 col-md-4 col-sm-12 col-xs-12 list-project-chat ${
                isShowInfo ? "" : "hide"
              } `}
            >
              <WriterDetailProject goBack={() => setIsShowInfo(false)} />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatBoxWriter;
