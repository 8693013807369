/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { UserProfileDropdown } from "./dropdowns/UserProfileDropdown";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../_helpers";
import { logoutAction } from "../../../../redux/action/authAction";
import Dropdown from "react-bootstrap/Dropdown";
import dayjs from "dayjs";

import {
  updateNotificationAction,
  resetUtilsStateAction,
} from "../../../../redux/action/utilsAction";
import firebase from "../../../../redux/service/firebase/firebase";

import emptyNotif from "../../../../app/assets/img/empty-list-notif.png";

export function QuickUserToggler() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { users } = useSelector((state) => state.auth);
  const uiService = useHtmlClassService();
  const firestore = firebase.firestore();
  const layoutProps = useMemo(() => {
    return {
      offcanvas:
        objectPath.get(uiService.config, "extras.user.layout") === "offcanvas",
    };
  }, [uiService]);

  const [notifList, setNotifList] = useState([]);
  const [url, setUrl] = useState("");
  const { successUpdateNotif } = useSelector((state) => state.utils);
  const notifCount = notifList?.filter((item) => item.isRead === false);

  const getListNotifPm = async () => {
    return await firestore
      .collection("userNotifications")
      .where("target.value", "in", [users?.uid, "pm"])
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            id: doc.id,
          })
        );
        setNotifList(temp);
      });
  };

  const getListNotif = async () => {
    return await firestore
      .collection("userNotifications")
      .where("target.value", "in", [users?.uid])
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            id: doc.id,
          })
        );
        setNotifList(temp);
      });
  };

  useEffect(() => {
    if (users) {
      if (users?.user?.type === "pm") {
        getListNotifPm();
      } else {
        getListNotif();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (successUpdateNotif) {
      getListNotif();
      dispatch(resetUtilsStateAction());
      history.push(url.replace(process.env.REACT_APP_BASE_URL, ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateNotif]);

  const updateNotif = (data) => {
    dispatch(updateNotificationAction(data.id));

    setUrl(data.url);
  };

  const logoutHandler = (e) => {
    e.preventDefault();
    dispatch(logoutAction());
  };

  const goSetting = (e) => {
    e.preventDefault();
    history.push("/admin/pm/settings");
  };

  const goProfile = (e) => {
    e.preventDefault();
    history.push("/admin/pm/profile");
  };

  return (
    <>
      {layoutProps.offcanvas && (
        <div className="topbar-item">
          <Dropdown
            className={`notification ${
              notifCount?.length < 1 ? "not" : "has-noty"
            }`}
            alignRight={true}
          >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="notif">
                <span className="icon-notif"></span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-noty">
              <div className="title-menu">Notifikasi</div>
              {notifList?.length > 0 ? (
                notifList?.map((item) => (
                  <Dropdown.Item
                    className={item.isRead ? "nonactive" : "active"}
                    key={item.id}
                    onClick={() =>
                      updateNotif({ id: item.id, url: item?.url?.web || "/" })
                    }
                  >
                    <span className="notif-box">
                      <span className="date">
                        <span></span>{" "}
                        {dayjs(item.createdAt.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY HH:mm")}
                      </span>
                      <span className="title-box">{item.heading}</span>
                      <span className="desc">{item.content}</span>
                    </span>
                  </Dropdown.Item>
                ))
              ) : (
                <div className="empty-notification">
                  <img src={emptyNotif} alt="empty" />
                  <span>Notifikasi Belum tersedia</span>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <div className="topbar-hr" />
          <Dropdown className="user" alignRight={true}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <span className="font-size-base d-none d-md-inline mr-1">
                Hai,
              </span>
              <span className="font-size-base d-none d-md-inline">
                {users?.user?.name?.length > 15
                  ? `${users?.user?.name?.substring(0, 15)}...`
                  : users?.user?.name}
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={goProfile}>
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} />{" "}
                Profil
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={goSetting}>
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />{" "}
                Pengaturan
              </Dropdown.Item>
              <Dropdown.Item href="/#" onClick={logoutHandler}>
                <SVG src={toAbsoluteUrl("/media/svg/irisIcons/logout.svg")} />{" "}
                Keluar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}

      {!layoutProps.offcanvas && <UserProfileDropdown />}
    </>
  );
}
