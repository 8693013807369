import firebase from "./firebase";
import fb from "firebase/app";

const firestore = firebase.firestore();

export const updatePic = async (id, data) => {
  await firestore
    .collection("users")
    .doc(id)
    .update({
      ...data,
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
    });
};

export const getPicDetail = async (id) =>
  await firestore
    .collection("users")
    .doc(id)
    .get();
