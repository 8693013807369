import React from "react";
import { useParams } from "react-router-dom";
import UpdateEmail from "./Components/UpdateEmail";
import UpdatePassword from "./Components/UpdatePassword";
import UpdatePhone from "./Components/UpdatePhone";
import AccountNumber from "./Components/AccountNumber";

function UpdateSettingsModule() {
  const QueryParams = useParams();

  return (
    <div>
      {QueryParams.changedDetail === "email" && <UpdateEmail />}
      {QueryParams.changedDetail === "password" && <UpdatePassword />}
      {QueryParams.changedDetail === "phone" && <UpdatePhone />}
      {QueryParams.changedDetail === "account-number" && <AccountNumber />}
    </div>
  );
}

export default UpdateSettingsModule;
