/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";

import DialogPortfolio from "./Components/PopupPortfolio";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { UploadThumbnail } from "../../../components/upload/uploadThumbnail";
import ButtonAdminLoader from "../../../components/button/ButtonAdminLoader";
import ListLoader from "../../../components/loading/listLoader";
import { formUrl } from "../../../helpers/url";

import { getDistrictAction } from "../../../../redux/action/utilsAction";
import {
  updateUserAction,
  resetStateUserAction,
} from "../../../../redux/action/userAction";
import { getLastLocation } from "../../../../_metronic/_helpers";

const DEFAULT_IMAGE = process.env.REACT_APP_IMAGE_USER;

const EditUserModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogPortfolio, setDialogPortfolio] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const [nameState, setNameState] = useState({ value: "", error: null });
  const [addressState, setAddressState] = useState({ value: "", error: null });
  const [provinceState, setProvinceState] = useState({
    value: null,
    error: null,
  });
  const [districtState, setDistrictState] = useState({
    value: null,
    error: null,
  });
  const [aboutState, setAboutState] = useState({ value: "", error: null });
  const [imageState, setImageState] = useState({ value: null, error: null });
  const [linkedInState, setLinkedInState] = useState({
    value: "",
    error: null,
  });
  const [instagramState, setInstagramState] = useState({
    value: "",
    error: null,
  });
  const [provinceValue, setProvinceValue] = useState("");
  const [portfolioValue, setPortfolioValue] = useState([]);

  const {
    dataGetProvince,
    dataGetDistrict,
    dataUploadImage,
    successUploadImage,
  } = useSelector((state) => state.utils);

  const {
    loadingUpdateUser,
    successUpdateUser,
    dataDetailUser,
    loadingDetailUser,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (dataGetProvince !== null) {
      const dropdownItems = dataGetProvince?.map((item) => ({
        name: item.name,
        code: item.id,
      }));
      setProvinces(dropdownItems);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetProvince]);

  useEffect(() => {
    if (dataDetailUser !== null) {
      if (dataDetailUser?.photoURL || dataDetailUser?.photo) {
        setImageState({
          value: dataDetailUser?.photoURL || dataDetailUser?.photo,
          error: null,
        });
      } else {
        setImageState({
          value: DEFAULT_IMAGE,
          error: null,
        });
      }
      setNameState({ value: dataDetailUser?.name, error: null });
      setAddressState({
        value: dataDetailUser?.domicile?.address,
        error: null,
      });
      setAboutState({ value: dataDetailUser?.biography || "", error: null });
      setPortfolioValue(dataDetailUser?.portofolio);
      setProvinceValue(dataDetailUser?.domicile?.province);
      setProvinceState({
        value: { name: dataDetailUser?.domicile?.province, code: 0 },
        error: null,
      });
      setDistrictState({
        value: dataDetailUser?.domicile?.cities,
        error: null,
      });
      setProvinces(
        provinces?.concat({ name: dataDetailUser?.domicile?.province, code: 0 })
      );
      setDistricts([
        {
          name: dataDetailUser?.domicile?.cities,
          code: dataDetailUser?.domicile?.cities,
        },
      ]);
      if (dataDetailUser?.instagram !== null) {
        setInstagramState({ value: dataDetailUser?.instagram, error: null });
      }
      if (dataDetailUser?.linkedIn !== null) {
        setLinkedInState({ value: dataDetailUser?.linkedIn, error: null });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailUser]);

  useEffect(() => {
    if (districts?.length > 0) {
      const dropdownItems = dataGetDistrict?.map((item) => ({
        name: item.name,
        code: item.name,
      }));
      setDistricts(districts?.concat(dropdownItems));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetDistrict]);

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ value: dataUploadImage, error: null });
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (successUpdateUser) {
      setTimeout(() => {
        setLoadingState(false);
        setDialogSuccess(true);
      }, 750);
    }
  }, [successUpdateUser]);

  useEffect(() => {
    if (
      provinceState.value?.name &&
      dataGetDistrict === null &&
      provinces?.length > 2
    ) {
      const code = provinces.find(
        (province) =>
          province?.name === provinceState.value?.name && province?.code !== 0
      )?.code;
      dispatch(getDistrictAction(parseInt(code)));
    }
  }, [provinceState, dataGetDistrict, dispatch, provinces]);

  const closeSuccess = () => {
    setTimeout(() => {
      dispatch(resetStateUserAction());
      setDialogSuccess(false);
      history.push(getLastLocation());
    }, 750);
  };

  const handleProvince = (data) => {
    dispatch(getDistrictAction(parseInt(data.code)));
    setProvinceState({ value: data, error: null });
    setProvinceValue(data?.name);
  };

  const submitData = () => {
    let data = {
      name: nameState.value,
      domicile: {
        province: provinceValue,
        cities: districtState.value,
        address: addressState.value,
      },
      biography: aboutState.value,
      portofolio: portfolioValue,
    };

    const isInstagram =
      (instagramState?.value?.length > 0 && instagramState.error === null) ||
      instagramState?.value === "";
    const isLinkedIn =
      (linkedInState?.value?.length > 0 && linkedInState.error === null) ||
      linkedInState?.value === "";
    const isImages = imageState?.value !== null;

    if (linkedInState?.value?.length > 0 && !formUrl(linkedInState.value)) {
      setLinkedInState({ ...linkedInState, error: "Url tidak valid" });
    }
    if (instagramState?.value?.length > 0 && !formUrl(instagramState.value)) {
      setInstagramState({ ...instagramState, error: "Url tidak valid" });
    }

    if (isImages) {
      data = {
        ...data,
        photo: imageState.value,
      };
    }

    if (isInstagram) {
      data = {
        ...data,
        instagram: instagramState.value,
      };
    }
    if (isLinkedIn) {
      data = {
        ...data,
        linkedIn: linkedInState.value,
      };
    }
    if (isImages && isInstagram && isLinkedIn) {
      data = {
        ...data,
        photo: imageState.value,
        instagram: instagramState.value,
        linkedIn: linkedInState.value,
      };
    }
    if (provinceValue && !districtState.value) {
      setDistrictState({
        ...districtState,
        error: "Pilih kota/kabupaten Anda",
      });
    }
    setLoadingState(true);
    dispatch(updateUserAction(props.userId, data));
  };

  return (
    <>
      <div className="card card-custom mb-4">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <button onClick={() => history.goBack()} className="button-back">
              <span className="card-label font-weight-bolder text-dark">
                <span className="icon-back mr-2"></span>Perbarui Pengguna
              </span>
            </button>
          </h3>
        </div>
        {loadingDetailUser ? (
          <ListLoader />
        ) : (
          <div className="card-body pt-0 pb-4">
            <div className="form-add-project">
              <div className="p-field">
                <label className="p-d-block">Nama Lengkap <span className="text-danger" >*</span></label>
                <InputText
                  type="text"
                  className="p-d-block"
                  placeholder="Masukan Nama"
                  value={nameState.value}
                  onChange={(e) =>
                    setNameState({ value: e.target.value, error: null })
                  }
                />
              </div>
              <div className="p-field">
                <label className="p-d-block">Email</label>
                <InputText
                  className="p-d-block"
                  type="email"
                  placeholder="Masukan Email"
                  value={dataDetailUser?.email}
                  disabled
                />
              </div>
              <div className="p-field">
                <label className="p-d-block">Nomor Telepon</label>
                <InputText
                  type="text"
                  className="p-d-block"
                  placeholder="Masukkan Nomor Telepon"
                  value={dataDetailUser?.phone}
                  disabled
                />
              </div>
              <div className="p-field">
                <label>Foto Profil</label>
                <UploadThumbnail
                  attachment={imageState.value}
                  deleteImage={() =>
                    setImageState({ value: null, error: null })
                  }
                  path={"user-data"}
                  errorText={imageState.error}
                />
              </div>
              <div className="p-field">
                <label className="p-d-block">Alamat</label>
                <InputText
                  type="text"
                  className="p-d-block"
                  placeholder="Masukan Alamat"
                  value={addressState.value}
                  onChange={(e) =>
                    setAddressState({ value: e.target.value, error: null })
                  }
                />
              </div>
              <div className="p-field">
                <label className="p-d-block">Provinsi</label>
                <Dropdown
                  options={provinces}
                  value={provinceState.value}
                  optionLabel="name"
                  onChange={(e) => handleProvince(e.value)}
                  placeholder="Pilih Provinsi"
                />
              </div>

              <div className="p-field">
                <label className="p-d-block">Kota / Kabupaten</label>
                <Dropdown
                  options={districts}
                  value={districtState.value}
                  onChange={(e) =>
                    setDistrictState({ value: e.target.value, error: null })
                  }
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Pilih Kota / Kabupaten"
                  disabled={districts?.length < 1}
                />
              </div>

              <div className="p-field p-grid">
                <label className="p-d-block p-col-12">Link Sosial Media</label>
                <div className="p-col-6">
                  <InputText
                    type="url"
                    className={`${linkedInState.error !== null &&
                      "p-invalid"} p-d-block`}
                    placeholder="LinkedIn"
                    value={linkedInState.value}
                    onChange={(e) =>
                      setLinkedInState({ value: e.target.value, error: null })
                    }
                  />
                  {linkedInState.error !== null && (
                    <small className="p-error p-d-block">
                      {linkedInState.error}
                    </small>
                  )}
                </div>
                <div className="p-col-6">
                  <InputText
                    type="url"
                    className={`${instagramState.error !== null &&
                      "p-invalid"} p-d-block`}
                    placeholder="Instagram"
                    value={instagramState.value}
                    onChange={(e) =>
                      setInstagramState({ value: e.target.value, error: null })
                    }
                  />
                  {instagramState.error !== null && (
                    <small className="p-error p-d-block">
                      {instagramState.error}
                    </small>
                  )}
                </div>
                <span className="p-col-12 text-helper-italic">
                  *Silakan masukkan url, contoh:
                  https://media-sosialmu.domain/nickname
                </span>
              </div>

              <div className="p-field">
                <label className="p-d-block">Biografi</label>
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={aboutState.value}
                  onChange={(e) =>
                    setAboutState({ value: e.target.value, error: null })
                  }
                />
              </div>

              <div className="p-field p-mt-5 portfolio-list">
                <p className="count-folio">
                  Portofolio({portfolioValue?.length})
                  <span
                    className="ml-3"
                    onClick={() => setDialogPortfolio(true)}
                  >
                    <span className="icon-add-o"></span>
                  </span>
                </p>
                {portfolioValue?.map((item, key) => (
                  <div key={key} className="p-d-flex p-jc-between">
                    <div className="p-mb-3">
                      <label className="p-d-block folio-name">
                        {item.title}
                      </label>
                      <span className="folio-year">{item.year}</span>
                    </div>
                    <a
                      href={item.portofolioLink}
                      // eslint-disable-next-line react/jsx-no-target-blank
                      target="_blank"
                      className=" p-button btn-wide p-button-outlined p-button-rounded sm-rounded text-uppercase p-px-3"
                    >
                      Lihat Portofolio
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="footer-form">
              <div className="form-add-project p-d-flex p-jc-end p-ai-center">
                <a
                  onClick={() => history.goBack()}
                  className="p-button p-button-outlined btn-wide p-button-secondary mr-3"
                >
                  Batal
                </a>
                {loadingUpdateUser || loadingState ? (
                  <ButtonAdminLoader />
                ) : (
                  <Button
                    onClick={() => submitData()}
                    label="Update"
                    className="btn-wide p-button p-button-primary"
                    disabled={nameState.value.length < 1}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="normal-dialog no-close">
        <DialogPortfolio
          visible={dialogPortfolio}
          closeDialog={() => setDialogPortfolio(false)}
          setData={setPortfolioValue}
          data={portfolioValue}
        />
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Memperbarui Data"}
        text={"Data pengguna berhasil diperbarui"}
      />
    </>
  );
};

export default EditUserModule;
