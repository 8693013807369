import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RadioButton } from "primereact/radiobutton";

import { getSaveProjectClientAction } from "../../../../redux/action/orderAction";

function PopupFilterDraft(props) {
  const { show, parsed, closePopup } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [type, setType] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");

  const submitFilter = () => {
    const params = {
      q: parsed.search,
      category: category,
      type: type,
      status: status,
      orderField: parsed?.order || "",
      orderDir: parsed?.order === "title" ? "asc" : "desc",
      page: 1,
      perpage: 10,
    };
    dispatch(getSaveProjectClientAction(params));
    closePopup();
    history.push(
      `/client/my-order/draft?search=${parsed.search ||
        ""}&type=${type}&category=${category}&status=${status}&order=${parsed?.order ||
        ""}&page=1`
    );
  };

  const resetFilter = () => {
    const params = {
      q: parsed.search,
      category: "",
      type: "",
      status: "",
      orderField: parsed?.order || "",
      orderDir: parsed?.order === "createdAt" ? "desc" : "asc",
      page: 1,
      perpage: 10,
    };
    dispatch(getSaveProjectClientAction(params));
    setType("");
    setCategory("");
    setStatus("");
    closePopup();
  };

  return (
    <div className={`popup-myrelease-filter ${!show && "d-none"}`}>
      <div className="filter-body">
        <div className="sort-by">
          <h3 className="p-mb-3 p-mt-4">Jenis Project</h3>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="type1"
              name="type"
              value=""
              onChange={(e) => setType(e.value)}
              checked={type === ""}
            />
            <label htmlFor="type1" className="p-mb-0">
              Semua
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="type2"
              name="type"
              value="public"
              onChange={(e) => setType(e.value)}
              checked={type === "public"}
            />
            <label htmlFor="type2" className="p-mb-0">
              Public
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="type3"
              name="type"
              value="private"
              onChange={(e) => setType(e.value)}
              checked={type === "private"}
            />
            <label htmlFor="type3" className="p-mb-0">
              Private
            </label>
          </div>
          <h3 className="p-mb-3 p-mt-4">Kategori</h3>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category1"
              name="category"
              value=""
              onChange={(e) => setCategory(e.value)}
              checked={category === ""}
            />
            <label htmlFor="category1" className="p-mb-0">
              Semua
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category2"
              name="category"
              value="article"
              onChange={(e) => setCategory(e.value)}
              checked={category === "article"}
            />
            <label htmlFor="category2" className="p-mb-0">
              Artikel
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category3"
              name="category"
              value="pr"
              onChange={(e) => setCategory(e.value)}
              checked={category === "pr"}
            />
            <label htmlFor="category3" className="p-mb-0">
              Press Release
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category4"
              name="category"
              value="distribution_media"
              onChange={(e) => setCategory(e.value)}
              checked={category === "distribution_media"}
            />
            <label htmlFor="category4" className="p-mb-0">
              Media Distribusi
            </label>
          </div>
          <h3 className="p-mb-3 p-mt-4">Status</h3>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status1"
              name="status"
              value=""
              onChange={(e) => setStatus(e.value)}
              checked={status === ""}
            />
            <label htmlFor="status1" className="p-mb-0">
              Semua
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status2"
              name="status"
              value="new"
              onChange={(e) => setStatus(e.value)}
              checked={status === "new"}
            />
            <label htmlFor="status2" className="p-mb-0">
              Baru
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status3"
              name="status"
              value="draft"
              onChange={(e) => setStatus(e.value)}
              checked={status === "draft"}
            />
            <label htmlFor="status3" className="p-mb-0">
              Draft
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status4"
              name="status"
              value="ongoing"
              onChange={(e) => setStatus(e.value)}
              checked={status === "ongoing"}
            />
            <label htmlFor="status4" className="p-mb-0">
              Berjalan
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status5"
              name="status"
              value="done"
              onChange={(e) => setStatus(e.value)}
              checked={status === "done"}
            />
            <label htmlFor="status5" className="p-mb-0">
              Selesai
            </label>
          </div>
        </div>
      </div>
      <div className="filter-footer">
        <div className="button">
          <button className="p-btn reset" onClick={() => resetFilter()}>
            Reset
          </button>
          <button className="p-btn btn-primary" onClick={() => submitFilter()}>
            Terapkan
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupFilterDraft;
