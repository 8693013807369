import {
  RESET_RELEASE_STATE_REQUEST,
  GET_WINNER_RELEASE_REQUEST,
  GET_WINNER_RELEASE_FAILURE,
  GET_WINNER_RELEASE_SUCCESS,
  DETAIL_RELEASE_FAILURE,
  DETAIL_RELEASE_REQUEST,
  DETAIL_RELEASE_SUCCESS,
  FS_MEDIA_FAILURE,
  FS_MEDIA_REQUEST,
  FS_MEDIA_SUCCESS,
  ADD_MEDIA_RELEASE_FAILURE,
  ADD_MEDIA_RELEASE_REQUEST,
  ADD_MEDIA_RELEASE_SUCCESS,
  PUT_MEDIA_RELEASE_FAILURE,
  PUT_MEDIA_RELEASE_REQUEST,
  PUT_MEDIA_RELEASE_SUCCESS,
  DELETE_MEDIA_RELEASE_FAILURE,
  DELETE_MEDIA_RELEASE_REQUEST,
  DELETE_MEDIA_RELEASE_SUCCESS,
  FS_DETAIL_MEDIA_FAILURE,
  FS_DETAIL_MEDIA_REQUEST,
  FS_DETAIL_MEDIA_SUCCESS,
  STATS_RELEASE_FAILURE,
  STATS_RELEASE_REQUEST,
  STATS_RELEASE_SUCCESS,
  STATS_MEDIA_RELEASE_FAILURE,
  STATS_MEDIA_RELEASE_REQUEST,
  STATS_MEDIA_RELEASE_SUCCESS,
} from "../constant/releaseConstant";

const initialStateRelease = {
  loadingWinnerRelease: false,
  dataWinnerRelease: null,
  infoWinnerRelease: null,
  errWinnerRelease: null,

  loadingMediaRelease: false,
  dataMediaRelease: null,
  infoMediaRelease: null,
  errMediaRelease: null,

  loadingListMedia: false,
  dataListMedia: null,
  errListMedia: null,

  loadingAddRelease: false,
  successAddRelease: false,
  errAddRelease: null,

  loadingUpdateRelease: false,
  successUpdateRelease: false,
  errUpdateRelease: null,

  loadingDeleteRelease: false,
  successDeleteRelease: false,
  errDeleteRelease: null,

  loadingNews: false,
  dataNews: null,
  errNews: null,

  loadingStatsRelease: false,
  dataStatsRelease: null,
  errStatsRelease: null,

  loadingStatsMediaRelease: false,
  dataStatsMediaRelease: null,
  errStatsMediaRelease: null,
};

export const releaseReducer = (state = initialStateRelease, action) => {
  switch (action.type) {
    case RESET_RELEASE_STATE_REQUEST:
      return {
        ...state,
        loadingAddRelease: false,
        successAddRelease: false,
        errAddRelease: null,
        loadingUpdateRelease: false,
        successUpdateRelease: false,
        errUpdateRelease: null,
        loadingDeleteRelease: false,
        successDeleteRelease: false,
        errDeleteRelease: null,
        loadingNews: false,
        dataNews: null,
        errNews: null,
      };

    case GET_WINNER_RELEASE_REQUEST:
      return {
        ...state,
        loadingWinnerRelease: true,
        errWinnerRelease: null,
      };
    case GET_WINNER_RELEASE_SUCCESS:
      return {
        ...state,
        loadingWinnerRelease: false,
        dataWinnerRelease: action.data.data,
        infoWinnerRelease: action.data.meta,
        errWinnerRelease: null,
      };
    case GET_WINNER_RELEASE_FAILURE:
      return {
        ...state,
        loadingWinnerRelease: false,
        errWinnerRelease: action.data,
      };

    case DETAIL_RELEASE_REQUEST:
      return {
        ...state,
        loadingMediaRelease: true,
        errMediaRelease: null,
      };
    case DETAIL_RELEASE_SUCCESS:
      return {
        ...state,
        loadingMediaRelease: false,
        dataMediaRelease: action.data.data,
        infoMediaRelease: action.data.meta,
        errMediaRelease: null,
      };
    case DETAIL_RELEASE_FAILURE:
      return {
        ...state,
        loadingMediaRelease: false,
        errMediaRelease: action.data,
      };

    case FS_MEDIA_REQUEST:
      return {
        ...state,
        loadingListMedia: true,
        errListMedia: null,
      };
    case FS_MEDIA_SUCCESS:
      return {
        ...state,
        loadingListMedia: false,
        dataListMedia: action.data,
        errListMedia: null,
      };
    case FS_MEDIA_FAILURE:
      return {
        ...state,
        loadingListMedia: false,
        errListMedia: action.data,
      };

    case ADD_MEDIA_RELEASE_REQUEST:
      return {
        ...state,
        loadingAddRelease: true,
        successAddRelease: false,
        errAddRelease: null,
      };
    case ADD_MEDIA_RELEASE_SUCCESS:
      return {
        ...state,
        loadingAddRelease: false,
        successAddRelease: true,
        errAddRelease: null,
      };
    case ADD_MEDIA_RELEASE_FAILURE:
      return {
        ...state,
        loadingAddRelease: true,
        successAddRelease: false,
        errAddRelease: action.data,
      };

    case PUT_MEDIA_RELEASE_REQUEST:
      return {
        ...state,
        loadingUpdateRelease: true,
        successUpdateRelease: false,
        errUpdateRelease: null,
      };
    case PUT_MEDIA_RELEASE_SUCCESS:
      return {
        ...state,
        loadingUpdateRelease: false,
        successUpdateRelease: true,
        errUpdateRelease: null,
      };
    case PUT_MEDIA_RELEASE_FAILURE:
      return {
        ...state,
        loadingUpdateRelease: true,
        successUpdateRelease: false,
        errUpdateRelease: action.data,
      };

    case DELETE_MEDIA_RELEASE_REQUEST:
      return {
        ...state,
        loadingDeleteRelease: true,
        successDeleteRelease: false,
        errDeleteRelease: null,
      };
    case DELETE_MEDIA_RELEASE_SUCCESS:
      return {
        ...state,
        loadingDeleteRelease: false,
        successDeleteRelease: true,
        errDeleteRelease: null,
      };
    case DELETE_MEDIA_RELEASE_FAILURE:
      return {
        ...state,
        loadingDeleteRelease: false,
        successDeleteRelease: false,
        errDeleteRelease: action.data,
      };

    case FS_DETAIL_MEDIA_REQUEST:
      return {
        ...state,
        loadingNews: true,
        errNews: null,
      };
    case FS_DETAIL_MEDIA_SUCCESS:
      return {
        ...state,
        loadingNews: false,
        dataNews: action.data,
        errNews: null,
      };
    case FS_DETAIL_MEDIA_FAILURE:
      return {
        ...state,
        loadingNews: false,
        errNews: action.data,
      };

    case STATS_RELEASE_REQUEST:
      return {
        ...state,
        loadingStatsRelease: true,
        errStatsRelease: null,
      };
    case STATS_RELEASE_SUCCESS:
      return {
        ...state,
        loadingStatsRelease: false,
        dataStatsRelease: action.data.data,
        errStatsRelease: null,
      };
    case STATS_RELEASE_FAILURE:
      return {
        ...state,
        loadingStatsRelease: false,
        errStatsRelease: action.data.data,
      };

    case STATS_MEDIA_RELEASE_REQUEST:
      return {
        ...state,
        loadingStatsMediaRelease: true,
        errStatsMediaRelease: null,
      };
    case STATS_MEDIA_RELEASE_SUCCESS:
      return {
        ...state,
        loadingStatsMediaRelease: false,
        dataStatsMediaRelease: action.data.data,
        errStatsMediaRelease: null,
      };
    case STATS_MEDIA_RELEASE_FAILURE:
      return {
        ...state,
        loadingStatsMediaRelease: false,
        errStatsMediaRelease: action.data.data,
      };

    default:
      return state;
  }
};
