import React, { useEffect } from "react";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import PaymentDetailsModule from "../../modules/Client/Payment/PaymentDetailsModule";

function PaymentDetailsPage() {
  useEffect(() => {
    titlePage({
      title: "IRIS - Payment",
    });
  }, []);
  return (
    <DashboardClientLayout title="Konfirmasi Pembayaran" menu="invoice">
      <PaymentDetailsModule />
    </DashboardClientLayout>
  );
}

export default PaymentDetailsPage;
