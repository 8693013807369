/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import DialogPassword from "./Components/DialogPassword";
import DialogEmail from "./Components/DialogEmail";
import DialogPhone from "./Components/DialogPhone";
import DialogAlert from "../../../components/dialog/DialogAlert";
import LinkEdit from "../../../components/button/LinkEdit";

import {
  logoutAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";
import { getStorage } from "../../../../_metronic/_helpers/LocalStorageHelpers";

const SettingsModule = () => {
  const dispatch = useDispatch();
  const [openPassword, setOpenPassword] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({
    dialog: false,
    title: null,
    desc: null,
  });
  const [openError, setOpenError] = useState({
    dialog: false,
    title: null,
    desc: null,
  });

  const {
    successUpdatePassword,
    errUpdatePassword,
    successSendUpdateEmailWriter,
    errUpdateEmailWriter,
    successUpdatePhone,
    errUpdatePhone,
    errPhoneLogin,
    errCheckPhone,
    // users,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successUpdatePassword) {
      setOpenSuccess({
        dialog: true,
        title: "Berhasil Mengubah Kata Sandi",
        desc: "Silakan login ulang dengan kata sandi baru Anda",
      });
    }
  }, [successUpdatePassword]);

  useEffect(() => {
    if (errUpdatePassword) {
      setOpenError({
        dialog: true,
        title: "Gagal Mengubah Password",
        desc: "Password gagal diubah",
      });
    }
  }, [errUpdatePassword]);

  useEffect(() => {
    if (successSendUpdateEmailWriter) {
      setOpenSuccess({
        dialog: true,
        title: "Berhasil Mengubah Email",
        desc: "Silakan cek email anda untuk verifikasi",
      });
    }
  }, [successSendUpdateEmailWriter]);

  useEffect(() => {
    if (errUpdateEmailWriter) {
      setOpenError({
        dialog: true,
        title: "Gagal Mengubah Email",
        desc: "Email gagal diubah",
      });
    }
  }, [errUpdateEmailWriter]);

  useEffect(() => {
    if (successUpdatePhone) {
      setOpenSuccess({
        dialog: true,
        title: "Berhasil Mengubah Nomor telepon",
        desc: "Silakan login ulang dengan nomor telepon Baru",
      });
    }
  }, [successUpdatePhone]);

  useEffect(() => {
    if (errUpdatePhone) {
      setOpenError({
        dialog: true,
        title: "Gagal Mengubah Nomor telepon",
        desc: "Nomor telepon gagal diubah",
      });
    }
  }, [errUpdatePhone]);

  useEffect(() => {
    if (errPhoneLogin) {
      if (errPhoneLogin?.code === "auth/too-many-requests") {
        setOpenError({
          dialog: true,
          title: "Gagal mengirimkan OTP",
          desc:
            "Terlalu banyak percobaan pengiriman OTP. Coba lagi dalam nanti",
        });
      } else if (errPhoneLogin || errCheckPhone) {
        setOpenError({
          dialog: true,
          title: "Gagal mengirimkan OTP",
          desc: "Terjadi kesalahan dalam sistem. Coba lagi dalam nanti",
        });
      }
    }
  }, [errPhoneLogin, errCheckPhone]);

  const closeSuccess = () => {
    setOpenSuccess({
      dialog: false,
      title: null,
      desc: null,
    });
    dispatch(resetStateAuthAction());
    dispatch(logoutAction());
  };

  const closeError = () => {
    setOpenError({
      dialog: false,
      title: null,
      desc: null,
    });
    dispatch(resetStateAuthAction());
  };

  const closeDialog = (type) => {
    dispatch(resetStateAuthAction());
    switch (type) {
      case "email":
        setOpenEmail(false);
        break;
      case "phone":
        setOpenPhone(false);
        break;
      case "password":
        setOpenPassword(false);
        break;
      default:
        setOpenPassword(false);
    }
  };

  return (
    <>
      <div className="section bg-grey p-py-5 setting">
        <div className="p-d-flex p-justify-center">
          <div className="p-grid p-justify-center">
            <div className="col-lg-4 cl-md-4 col-sm-12">
              <div className="setting-side">
                <h2>Pengaturan</h2>
              </div>
            </div>
            <div className="col-lg-6 col-md-8 col-sm-12">
              <div className="card card-custom-detail setting w-full has-shadow mb-5">
                <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">
                        Email
                      </span>
                    </h3>
                  </div>
                  <div>
                    <LinkEdit link="/settings/email" label="Edit" />
                  </div>
                </div>
                <div className="card-body pt-0 pb-3">
                  <div className="notif-settings">
                    <div className="notif-icon">
                      <span className="material-icons">check_cirlce</span>
                    </div>
                    <label>Email anda telah diatur</label>
                    <p>
                      Anda mendaftar dengan menggunakan email. Anda bisa
                      mengubah email untuk login.
                    </p>
                  </div>
                </div>
              </div>
              {getStorage("login-mode") === "email" && (
                <div className="card card-custom-detail setting w-full has-shadow mb-5">
                  <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                          Kata Sandi
                        </span>
                      </h3>
                    </div>
                    <div>
                      <LinkEdit link="/settings/password" label="Edit" />
                    </div>
                  </div>
                  <div className="card-body pt-0 pb-3">
                    <div className="notif-settings">
                      <div className="notif-icon">
                        <span className="material-icons">check_cirlce</span>
                      </div>
                      <label>Kata sandi anda telah diatur</label>
                      <p>
                        Anda mendaftar dengan menggunakan kata sandi. Anda bisa
                        mengubah kata sandi untuk login.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {getStorage("login-mode") === "otp" && (
                <div className="card card-custom-detail setting w-full has-shadow">
                  <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                      <h3 className="card-title align-items-start flex-column">
                        <span className="card-label font-weight-bolder text-dark">
                          Nomor Telepon
                        </span>
                      </h3>
                    </div>
                    <div>
                      <LinkEdit link="/settings/phone" label="Edit" />
                    </div>
                  </div>
                  <div className="card-body pt-0 pb-3">
                    <div className="notif-settings">
                      <div className="notif-icon">
                        <span className="material-icons">check_cirlce</span>
                      </div>
                      <label>Nomor telepon anda telah diatur</label>
                      <p>
                        Anda mendaftar dengan menggunakan nomor telepon. Anda
                        bisa mengubah nomor telepon untuk login.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="card card-custom-detail setting w-full has-shadow">
                <div className="card-header no-border no-body py-5 d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">
                        Rekening Bank
                      </span>
                    </h3>
                  </div>
                  <div>
                    <Link
                      className="edit-button p-jc-end"
                      to="/settings/account-number"
                    >
                      <span className="icon-caret-right" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogPassword
        visible={openPassword}
        closeDialog={() => closeDialog("password")}
      />

      <DialogEmail
        visible={openEmail}
        closeDialog={() => closeDialog("email")}
      />

      <DialogPhone
        visible={openPhone}
        closeDialog={() => closeDialog("phone")}
      />

      <DialogAlert
        type={"success"}
        visible={openSuccess.dialog}
        closeDialog={() => closeSuccess()}
        title={openSuccess.title}
        text={openSuccess.desc}
      />

      <DialogAlert
        type={"error"}
        visible={openError.dialog}
        closeDialog={() => closeError()}
        title={openError.title}
        text={openError.desc}
      />
    </>
  );
};

export default SettingsModule;
