import React from "react";
import { useHistory } from "react-router-dom";

const TextBody = (props) => {
  const { text, link, isEmail } = props;
  const history = useHistory();
  return (
    <div
      className={`text-table ${isEmail && "email"} ${link && "link"}`}
      onClick={() => history.push(link)}
    >
      <p>{text}</p>
    </div>
  );
};

export default TextBody;
