/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { Link, useHistory } from "react-router-dom";
import * as dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/id";
import InfiniteScroll from "react-infinite-scroll-component";

import IsoBanner from "../../../assets/img/iso-banner.svg";

import TableLoader from "../../../components/loading/tableLoader";
import { isDefaultPhoto } from "../../../helpers/profileHelper";
import StatusProject from "../../../components/badge/StatusProject";

const DashboardModule = () => {
  const history = useHistory();
  const [projectItem, setProjectItem] = useState([]);
  const { users } = useSelector((state) => state.auth);
  const { dataGetMyProject, loadingGetMyProject } = useSelector(
    (state) => state.project
  );
  const { dataAnnouncements } = useSelector((state) => state.utils);

  useEffect(() => {
    if (dataGetMyProject !== null) {
      const project = dataGetMyProject?.splice(0, 10);
      setProjectItem(project);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetMyProject]);

  const fetchMoreData = () => {
    setTimeout(() => {
      const project = dataGetMyProject?.splice(0, 3);
      setProjectItem(projectItem?.concat(project));
    }, 1500);
  };

  const announcementsList = dataAnnouncements?.slice(0, 4);
  const deadlineRow = (dataRow) => {
    const date = dataRow?.deadline?.toDate();
    date.setDate(date.getDate() + 1);
    const deadline = dayjs(date).format("YYYY-MM-DD");
    dayjs.extend(relativeTime);
    const countdown = dayjs(deadline)
      .locale("id")
      .fromNow();
    return (
      <div>
        <span className="deadline">
          <span className="icon-time p-mr-2"></span>
          {countdown}
        </span>
      </div>
    );
  };
  const actionRow = (data) => {
    return (
      <div className="action-table">
        <Link
          to={`/my-project/${data?.projectId}`}
          className="btn btn-action text"
        >
          Lihat Project
          {data?.chat?.isRead && data?.chat?.userType === "pm" ? (
            <div className="dot" />
          ) : (
            ""
          )}
        </Link>
      </div>
    );
  };

  const avatarLabel = users?.user?.name
    ? users?.user?.name.split("")[0]
    : users?.user?.email.split("")[0];

  return (
    <>
      <div className="dashboard-content-wrapper">
        <div className="p-d-flex p-justify-center dashboard">
          <div className="p-grid mt-4">
            <div className="p-col-fixed name-box" style={{ width: "281px" }}>
              <div className="box-content p-px-4 p-py-3">
                <div className="writer-name">
                  <div className="pict">
                    {isDefaultPhoto(users?.user?.photoURL) ||
                    !users?.user?.photoURL ? (
                      <div className="letter-pics">{avatarLabel}</div>
                    ) : (
                      <img src={users?.user?.photoURL} alt="profile" />
                    )}
                  </div>
                  <div className="name">
                    {users?.user?.name || users?.user?.email}
                  </div>
                </div>
              </div>
            </div>
            <div className="p-col project-active-wrapper">
              <div className="box-content widget-dashboard">
                <div className="project-active">
                  <span className="p-mr-2">Project Aktif: </span>
                  <NumberFormat
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={users?.user?.nOngoingProject}
                    renderText={(value) => <p> {value} Project</p>}
                  />
                </div>
                <Link className="account-balance" to="/balance">
                  <span className="p-mr-2">Balance:</span>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={users?.user?.balance || 0}
                    renderText={(value) => (
                      <div className="nominal-balance"> {value}</div>
                    )}
                  />
                </Link>
              </div>
            </div>
          </div>
        </div>
        {projectItem?.length > 0 ? (
          <div className="p-d-flex p-justify-center">
            <div className="p-grid">
              <div className="p-col">
                {loadingGetMyProject ? (
                  <div className="box-content table-wrapper">
                    <TableLoader />
                  </div>
                ) : (
                  <div className="box-content table-wrapper">
                    <div className="table-box">
                      <div className="row-head  d-hide-mobile">
                        <div className="t-head">Nama Project</div>
                        <div className="t-head">Status</div>
                        <div className="t-head">Deadline</div>
                        <div className="t-head">Pembaruan Terakhir</div>
                        <div className="t-head"></div>
                      </div>
                      <InfiniteScroll
                        dataLength={projectItem?.length}
                        next={() => fetchMoreData()}
                        hasMore={dataGetMyProject?.length === 0 ? false : true}
                        loader={
                          <div className="row-body">
                            <div className="t-body p-mx-auto">
                              Memuat Data...
                            </div>
                          </div>
                        }
                        endMessage={
                          <div className="row-body">
                            <div className="t-body p-mx-auto end-data">
                              Semua Data Tertampil
                            </div>
                          </div>
                        }
                      >
                        {projectItem?.map((item) => (
                          <div className="row-body" key={item?.projectId}>
                            <div className="t-body">
                              <div className="m-label-name">Nama Project:</div>
                              <span
                                onClick={() =>
                                  history.push(`/my-project/${item?.projectId}`)
                                }
                              >
                                {item?.title}
                              </span>
                            </div>
                            <div className="t-body">
                              <div className="m-label-name">Status:</div>
                              <StatusProject
                                userType={users?.user?.type}
                                status={
                                  typeof item?.status === "string"
                                    ? item?.status
                                    : item?.status?.status
                                }
                              />
                            </div>
                            <div className="t-body">
                              <div className="m-label-name">Deadline:</div>
                              {deadlineRow(item)}
                            </div>
                            <div className="t-body">
                              <div className="m-label-name">
                                Pembaruan Terakhir:
                              </div>
                              {dayjs(item?.updatedAt.toDate())
                                .locale("id")
                                .format("DD MMM YYYY")}
                            </div>
                            <div className="t-body">{actionRow(item)}</div>
                          </div>
                        ))}
                      </InfiniteScroll>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="p-d-flex p-justify-center">
            <div className="p-grid">
              <div className="p-col">
                <div className="box-content action p-px-4 p-py-3">
                  <div className="call-action">
                    <div className="pict">
                      <img src={IsoBanner} alt="action" />
                    </div>
                    <div className="desc">
                      <h3>Saatnya Menjadi Penulis Profesional</h3>
                      <p>
                        Kamu punya hobi menulis dan ingin mendapatkan{" "}
                        <i>extra income</i> dari karya tulismu? Di IRIS kamu
                        bisa mendapatkan pekerjaan untuk membuat karya tulis
                        sesuai dengan bidang yang kamu inginkan secara gratis,
                        waktu yang fleksibel dan tanpa biaya. Mulai perjalananmu
                        menjadi penulis profesional bersama IRIS!
                      </p>
                      <div className="button-action">
                        <Link
                          to="/project"
                          className="p-button p-button-primary p-button-rounded p-mt-3"
                        >
                          Mulai Sekarang
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {dataAnnouncements?.length > 0 && (
        <div className="p-d-flex p-justify-center my-4">
          <div className="p-grid">
            <div className="p-col-12">
              <div className="title-section p-d-flex p-jc-between p-ai-center p-mb-1">
                <h2>Pengumuman</h2>
              </div>
            </div>
            {announcementsList?.map((item, key) => (
              <div key={key} className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
                {item?.link ? (
                  <a
                    className="announcement-box mb-3"
                    href={item?.link}
                    target="_blank"
                    style={{ backgroundImage: `url(${item?.image})` }}
                  >
                    <h3>{item?.title}</h3>
                    <p>{item?.description}</p>
                  </a>
                ) : (
                  <div
                    className="announcement-box mb-3"
                    style={{ backgroundImage: `url(${item?.image})` }}
                  >
                    <h3>{item?.title}</h3>
                    <p>{item?.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
};

export default DashboardModule;
