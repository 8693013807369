import {
  LOGIN_WITH_EMAIL_REQUEST,
  VERIFY_OTP_REQUEST,
  LOGIN_WITH_LINK_REQUEST,
  PHONE_REGISTER_REQUEST,
  LOGOUT_REQUEST,
  PHONE_LOGIN_REQUEST,
  LOGIN_VERIFY_OTP_REQUEST,
  CHECK_PHONE_REQUEST,
  CHECK_EMAIL_REQUEST,
  EMAIL_REGISTER_REQUEST,
  APPLICANT_REGISTER_REQUEST,
  GOOGLE_LOGIN_REQUEST,
  MY_PROFILE_REQUEST,
  EDIT_WRITER_PROFILE_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_EMAIL_REQUEST,
  CHECK_UPDATE_PHONE_REQUEST,
  UPDATE_PHONE_REQUEST,
  CLIENT_REGISTER_REQUEST,
  VERIFY_CLIENT_REQUEST,
  SEND_UPDATE_EMAIL_CLIENT_REQUEST,
  SEND_UPDATE_EMAIL_PM_REQUEST,
  SEND_UPDATE_EMAIL_WRITER_REQUEST,
  UPDATE_EMAIL_PM_REQUEST,
  UPDATE_EMAIL_WRITER_REQUEST,
  UPDATE_EMAIL_CLIENT_REQUEST,
  UPDATE_USER_FIREBASE_REQUEST,
  RESET_PASSWORD_REQUEST,
  RESET_STATE_AUTH,
  CREATE_NEW_PASSWORD_REQUEST,
} from "../constant/authConstant";

export const loginEmailAction = (email, password) => {
  return {
    type: LOGIN_WITH_EMAIL_REQUEST,
    email,
    password,
  };
};

export const verifyOtpAction = (verificationId, otp, data, email) => {
  return {
    type: VERIFY_OTP_REQUEST,
    verificationId,
    otp,
    data,
    email,
  };
};

export const loginWithLinkAction = (data) => {
  return {
    type: LOGIN_WITH_LINK_REQUEST,
    data,
  };
};

export const phoneRegisterAction = (recaptha, data) => {
  return {
    type: PHONE_REGISTER_REQUEST,
    recaptha,
    data,
  };
};

export const logoutAction = () => {
  return {
    type: LOGOUT_REQUEST,
  };
};

export const phoneLoginAction = (recaptha, data) => {
  return {
    type: PHONE_LOGIN_REQUEST,
    recaptha,
    data,
  };
};

export const loginVerifyOtpAction = (verificationId, otp, data) => {
  return {
    type: LOGIN_VERIFY_OTP_REQUEST,
    verificationId,
    otp,
    data,
  };
};

export const checkPhoneAction = (data) => {
  return {
    type: CHECK_PHONE_REQUEST,
    data,
  };
};

export const checkEmailAction = (data) => {
  return {
    type: CHECK_EMAIL_REQUEST,
    data,
  };
};

export const emailRegisterAction = (data) => {
  return {
    type: EMAIL_REGISTER_REQUEST,
    data,
  };
};

export const applicantRegisterAction = (data) => {
  return {
    type: APPLICANT_REGISTER_REQUEST,
    data,
  };
};

export const loginGoogleAction = () => {
  return {
    type: GOOGLE_LOGIN_REQUEST,
  };
};

export const myProfileAction = (uid) => {
  return {
    type: MY_PROFILE_REQUEST,
    uid,
  };
};

export const editWriterProfileAction = (data) => {
  return {
    type: EDIT_WRITER_PROFILE_REQUEST,
    data,
  };
};

export const resetStateAuthAction = () => {
  return {
    type: RESET_STATE_AUTH,
  };
};

export const updatePasswordAction = (data) => {
  return {
    type: UPDATE_PASSWORD_REQUEST,
    data,
  };
};

export const updateEmailAction = (data) => {
  return {
    type: UPDATE_EMAIL_REQUEST,
    data,
  };
};

export const checkUpdatePhoneAction = (recaptha, data) => {
  return {
    type: CHECK_UPDATE_PHONE_REQUEST,
    recaptha,
    data,
  };
};

export const updatePhoneAction = (verificationId, otp) => {
  return {
    type: UPDATE_PHONE_REQUEST,
    verificationId,
    otp,
  };
};

export const clientRegisterAction = (data) => {
  return {
    type: CLIENT_REGISTER_REQUEST,
    data,
  };
};

export const verifyClientAction = (data) => {
  return {
    type: VERIFY_CLIENT_REQUEST,
    data,
  };
};

export const sendUpdateEmailClientAction = (data) => {
  return {
    type: SEND_UPDATE_EMAIL_CLIENT_REQUEST,
    data,
  };
};

export const sendUpdateEmailPmAction = (data) => {
  return {
    type: SEND_UPDATE_EMAIL_PM_REQUEST,
    data,
  };
};

export const sendUpdateEmailWriterAction = (data) => {
  return {
    type: SEND_UPDATE_EMAIL_WRITER_REQUEST,
    data,
  };
};

export const updateEmailClientAction = (data) => {
  return {
    type: UPDATE_EMAIL_CLIENT_REQUEST,
    data,
  };
};


export const updateEmailPmAction = (data) => {
  return {
    type: UPDATE_EMAIL_PM_REQUEST,
    data,
  };
};

export const updateEmailWriterAction = (data) => {
  return {
    type: UPDATE_EMAIL_WRITER_REQUEST,
    data,
  };
};

export const updateUserFirebaseAction = (uid, data) => {
  return {
    type: UPDATE_USER_FIREBASE_REQUEST,
    uid,
    data,
  };
};

export const resetPasswordAction = (data) => {
  return {
    type: RESET_PASSWORD_REQUEST,
    data,
  };
};

export const createNewPasswordAction = (data) => {
  return {
    type: CREATE_NEW_PASSWORD_REQUEST,
    data,
  };
};
