import { call, put } from "redux-saga/effects";
import {
  GET_FINANCE_ORDER_FAILURE,
  GET_FINANCE_ORDER_SUCCESS,
  UPDATE_FINANCE_ORDER_FAILURE,
  UPDATE_FINANCE_ORDER_SUCCESS,
  GET_FINANCE_PROJECT_FAILURE,
  GET_FINANCE_PROJECT_SUCCESS,
  APPROVE_PAYOUT_FAILURE,
  APPROVE_PAYOUT_SUCCESS,
  APPROVE_ALL_PAYOUT_FAILURE,
  APPROVE_ALL_PAYOUT_SUCCESS,
} from "../constant/financeConstant";

import { getToken } from "../service/firebase/auth";
import { getFinanceOrders, putFinanceOrders } from "../service/api/orderApi";
import { getProjectFinance } from "../service/api/projectsApi";
import { approveAllPayout, approvePayout } from "../service/api/financeApi";

export function* getFinanceOrderSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getFinanceOrders, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_FINANCE_ORDER_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_FINANCE_ORDER_FAILURE, data });
  }
}

export function* updateFinanceOrderSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    putFinanceOrders,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: UPDATE_FINANCE_ORDER_SUCCESS });
  } else {
    yield put({ type: UPDATE_FINANCE_ORDER_FAILURE, data });
  }
}

export function* getFinanceProjectSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectFinance, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_FINANCE_PROJECT_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_FINANCE_PROJECT_FAILURE, data });
  }
}

export function* approvePayoutSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(approvePayout, token, action.id, action.data);
  if (ok) {
    yield put({ type: APPROVE_PAYOUT_SUCCESS });
  } else {
    yield put({ type: APPROVE_PAYOUT_FAILURE, data });
  }
}

export function* approveAllPayoutSaga() {
  const token = yield call(getToken);
  const { ok, data } = yield call(approveAllPayout, token);
  if (ok) {
    yield put({ type: APPROVE_ALL_PAYOUT_SUCCESS });
  } else {
    yield put({ type: APPROVE_ALL_PAYOUT_FAILURE, data });
  }
}
