import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";
import DashboardLayout from "../../../layouts/dashboardLayout";
import DetailPaymentModule from "../../../modules/Admin/Finance/PaymentDetailModule";
import { titlePage } from "../../../helpers/titleHead";

import { detailWriterPaymentAction } from "../../../../redux/action/transactionAction";

const FinancePaymentDetailPage = () => {
  const dispatch = useDispatch();
  const queryParams = useParams();

  const { users } = useSelector((state) => state.auth);
  const isFinance = users?.user?.type === "finance";

  useEffect(() => {
    dispatch(detailWriterPaymentAction(queryParams?.paymentId));
    titlePage({
      title: "IRIS - Detail Pembayaran",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFinance ? (
    <DashboardFinanceLayout title="Manajemen Pembayaran" menu="payment">
      <DetailPaymentModule isFinance={isFinance} />
    </DashboardFinanceLayout>
  ) : (
    <DashboardLayout title="Manajemen Pembayaran" menu="payment">
      <DetailPaymentModule isFinance={isFinance} />
    </DashboardLayout>
  );
};

export default FinancePaymentDetailPage;
