import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

import DialogAlert from "../../../../../components/dialog/DialogAlert";
import ButtonDefault from "../../../../../components/button/ButtonDefault";

import { UploadAndEditThumbnail } from "../../../../../components/upload/uploadAndEditThumbnail ";

import {
  updateProductAction,
  addProductAction,
  resetStateServiceAction,
} from "../../../../../../redux/action/serviceAction";

function ProductDetailForm(props) {
  const { dataProduct, type } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const statusDropdown = [
    { name: "Artikel", code: "article" },
    { name: "Press Release", code: "pr" },
  ];
  const isDetailPage = type === "detailpage";

  const [nameState, setNameState] = useState({ value: "", error: null });
  const [imageState, setImageState] = useState({ value: null, error: null });
  const [categoryState, setCategoryState] = useState({
    value: "",
    error: null,
  });
  const [noteState, setNoteState] = useState({ value: "", error: null });
  const [priceState, setPriceState] = useState({ value: "", error: null });

  const [loadingState, setLoadingState] = useState(false);
  const [dialogAlert, setDialogAlert] = useState({
    type: "",
    dialog: false,
    title: "",
    text: "",
  });

  const {
    successUpdateProduct,
    errUpdateProduct,
    successAddProduct,
    errAddProduct,
  } = useSelector((state) => state.service);
  const { successUploadImage, dataUploadImage } = useSelector(
    (state) => state.utils
  );

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ error: null, value: dataUploadImage });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    if (dataProduct !== null) {
      setNameState({ ...nameState, value: dataProduct?.name });
      setCategoryState({ ...categoryState, value: dataProduct?.category });
      setNoteState({ ...noteState, value: dataProduct?.description });
      setPriceState({ ...priceState, value: dataProduct?.price });
      if (dataProduct?.image) {
        setImageState({ ...imageState, value: dataProduct?.image });
      } else {
        setImageState({
          ...imageState,
          value: isDetailPage ? process.env.REACT_APP_IMAGE_ARTICLE : null,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProduct]);

  useEffect(() => {
    if (successAddProduct) {
      setTimeout(() => {
        setLoadingState(false);
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Menambahkan Data!",
          text: "Data produk berhasil disimpan",
        });
      }, 2000);
    }
    if (errAddProduct) {
      setLoadingState(false);
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "Gagal Menambahkan Data!",
        text: "Data produk gagal disimpan",
      });
    }
  }, [successAddProduct, errAddProduct]);

  useEffect(() => {
    if (successUpdateProduct) {
      setTimeout(() => {
        setLoadingState(false);
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Mengubah Data",
          text: "Data produk berhasil diubah",
        });
      }, 2000);
    }
    if (errUpdateProduct) {
      setLoadingState(false);
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "Gagal Mengubah Data",
        text: "Data produk gagal diubah",
      });
    }
  }, [successUpdateProduct, errUpdateProduct]);

  const submitData = () => {
    const data = {
      name: nameState?.value,
      price: priceState?.value,
      description: noteState?.value,
      category: categoryState.value,
      image: imageState.value,
    };

    if (nameState.value.length < 1) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    }
    if (categoryState.value.length < 1) {
      setCategoryState({ ...categoryState, error: "Kategori harus diisi" });
    }
    if (priceState.value.length < 1) {
      setPriceState({ ...priceState, error: "Harga harus diisi" });
    }
    if (!priceState.value.match(/^[0-9]+$/)) {
      setPriceState({ ...priceState, error: "Harga tidak valid" });
    }
    if (noteState.value.length < 1) {
      setNoteState({ ...noteState, error: "Deskripsi harus diisi" });
    }
    if (imageState.value === null) {
      setImageState({ ...imageState, error: "Gambar Thumbnail harus diisi" });
    }

    if (
      nameState.value.length > 0 &&
      categoryState.value.length > 0 &&
      priceState.value.length > 0 &&
      priceState.value.match(/^[0-9]+$/) &&
      noteState.value.length > 0 &&
      imageState.value !== null
    ) {
      setLoadingState(true);
      if (type === "createpage") {
        dispatch(addProductAction(data));
      } else {
        dispatch(updateProductAction(props.productId, data));
      }
    }
  };

  const closeDialog = () => {
    if (dialogAlert.type === "success") {
      setDialogAlert({ type: "", dialog: false, title: "", text: "" });
      dispatch(resetStateServiceAction());
      history.push("/admin/pm/list-product");
    } else {
      setDialogAlert({ type: "", dialog: false, title: "", text: "" });
      dispatch(resetStateServiceAction());
    }
  };

  let categoryValue = "Artikel";
  if (categoryState.value === "pr") {
    categoryValue = "Press Realese";
  } else if (categoryState.value === "distribution_media") {
    categoryValue = "Media Distribusi";
  } else {
    categoryValue = "Artikel";
  }

  return (
    <>
      <div className="card-body pt-0 pb-4 p-d-flex p-jc-center">
        <div className="form-detail-pm col-10">
          <div className="p-field">
            <label className="p-d-block">
              Foto Thumbnail Produk
              {!isDetailPage && <span className="text-danger">*</span>}
            </label>
            <UploadAndEditThumbnail
              attachment={imageState.value}
              displayOnly={isDetailPage}
              path={"products"}
              errorText={imageState.error}
              deleteImage={() => setImageState({ error: null, value: null })}
            />
          </div>
          <div className="p-field">
            <label className="p-d-block">
              Kategori Produk
              {!isDetailPage && <span className="text-danger">*</span>}
            </label>
            {isDetailPage ? (
              <p>{categoryValue}</p>
            ) : (
              <>
                <Dropdown
                  value={categoryState.value}
                  options={statusDropdown}
                  onChange={(e) =>
                    setCategoryState({
                      error: null,
                      value: e.target.value,
                    })
                  }
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Pilih Kategori Project"
                  className={`full-width ${categoryState.error && "p-invalid"}`}
                />
                {categoryState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {categoryState.error}
                  </small>
                )}
              </>
            )}
          </div>
          <div className="p-field">
            <label className="p-d-block">
              Nama Produk
              {!isDetailPage && <span className="text-danger">*</span>}
            </label>
            {isDetailPage ? (
              <p>{nameState.value}</p>
            ) : (
              <>
                <InputText
                  type="text"
                  className={`p-d-block ${nameState.error && "p-invalid"}`}
                  placeholder="Masukkan Nama Produk"
                  value={nameState.value}
                  onChange={(e) =>
                    setNameState({
                      error: null,
                      value: e.target.value,
                    })
                  }
                />
                {nameState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {nameState.error}
                  </small>
                )}
              </>
            )}
          </div>
          <div className="p-field">
            <label className="p-d-block">
              Keterangan Produk
              {!isDetailPage && <span className="text-danger">*</span>}
            </label>
            {isDetailPage ? (
              <p>{noteState.value}</p>
            ) : (
              <>
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={noteState.value}
                  onChange={(e) =>
                    setNoteState({
                      error: null,
                      value: e.target.value,
                    })
                  }
                  placeholder="Masukkan Keterangan Produk"
                  className={`p-d-block ${noteState.error && "p-invalid"}`}
                />
                {noteState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {noteState.error}
                  </small>
                )}
              </>
            )}
          </div>
          <div className="p-field">
            <label className="p-d-block">
              Harga{!isDetailPage && " (Rp)"}
              {!isDetailPage && <span className="text-danger">*</span>}
            </label>
            {isDetailPage ? (
              <NumberFormat
                prefix={"Rp "}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
                value={priceState.value}
                renderText={(value) => <p>{value}</p>}
              />
            ) : (
              <>
                <InputText
                  type="text"
                  className={`p-d-block ${priceState.error && "p-invalid"}`}
                  placeholder="Masukkan Harga"
                  value={priceState.value}
                  onChange={(e) =>
                    setPriceState({
                      error: null,
                      value: e.target.value,
                    })
                  }
                />
                {priceState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {priceState.error}
                  </small>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {!isDetailPage && (
        <div className="company footer-form">
          <div className="form-add-project col-10 p-d-flex p-jc-end p-ai-center">
            <ButtonDefault
              className="mr-2 p-button p-button-secondary"
              label="BATAL"
              onClick={() => history.goBack()}
              disabled={loadingState}
              type="bordered"
            />
            <ButtonDefault
              onClick={() => submitData()}
              label={type === "createpage" ? "SIMPAN" : "EDIT"}
              className="ml-2 p-button p-button-primary"
              loading={loadingState}
            />
          </div>
        </div>
      )}

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
}

export default ProductDetailForm;
