import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import LandingpageModule from "../../modules/User/Landingpage/LandingPageModule";
import { LandingPageLoad } from "../../components/loading/loadingContent";

import { titlePage } from "../../helpers/titleHead";

import {
  getBannerAction,
  getHowDoesItWorkAction,
  getBenefitAction,
  getTestimonialAction,
  getTopWriterAction,
  getListArticleAction,
} from "../../../redux/action/landingpageAction";

import LandingLayout from "../../layouts/landingLayout";

const LandingPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    titlePage({
      title: "Homepage",
    });

    dispatch(getBannerAction());
    dispatch(getHowDoesItWorkAction());
    dispatch(getBenefitAction());
    dispatch(getTestimonialAction());
    dispatch(getTopWriterAction());
    dispatch(getListArticleAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    loadingGetBanner,
    dataGetBanner,
    loadingGetHowDoesItWork,
    dataGetHowDoesItWork,
    loadingGetBenefit,
    dataGetBenefit,
    loadingGetTestimonial,
    dataGetTestimonial,
    loadingGetTopWriter,
    dataGetTopWriter,
    dataGetArticles,
  } = useSelector((state) => state.landingpage);

  const isLoading =
    loadingGetBanner &&
    loadingGetHowDoesItWork &&
    loadingGetBenefit &&
    loadingGetTopWriter;

  return (
    <LandingLayout title="Home">
      {isLoading ? (
        <div className="section loading-landing">
          <LandingPageLoad />
        </div>
      ) : (
        <LandingpageModule
          dataBanner={dataGetBanner}
          loadingBanner={loadingGetBanner}
          dataHowDoesItWork={dataGetHowDoesItWork}
          loadingHowDoesItWork={loadingGetHowDoesItWork}
          dataBenefit={dataGetBenefit}
          loadingBenefit={loadingGetBenefit}
          dataTestimonial={dataGetTestimonial}
          loadingTestimonial={loadingGetTestimonial}
          dataTopWriter={dataGetTopWriter}
          loadingTopWriter={loadingGetTopWriter}
          dataArticle={dataGetArticles}
        />
      )}
    </LandingLayout>
  );
};

export default LandingPage;
