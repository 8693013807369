import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import {
  LOGIN_WITH_EMAIL_FAILURE,
  LOGIN_WITH_EMAIL_REQUEST,
  LOGIN_WITH_EMAIL_SUCCESS,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  VERIFY_OTP_REQUEST,
  PHONE_REGISTER_FAILURE,
  PHONE_REGISTER_REQUEST,
  PHONE_REGISTER_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  LOGIN_WITH_LINK_FAILURE,
  LOGIN_WITH_LINK_REQUEST,
  LOGIN_WITH_LINK_SUCCESS,
  PHONE_LOGIN_FAILURE,
  PHONE_LOGIN_REQUEST,
  PHONE_LOGIN_SUCCESS,
  LOGIN_VERIFY_OTP_FAILURE,
  LOGIN_VERIFY_OTP_SUCCESS,
  LOGIN_VERIFY_OTP_REQUEST,
  CHECK_PHONE_FAILURE,
  CHECK_PHONE_SUCCESS,
  CHECK_PHONE_REQUEST,
  CHECK_EMAIL_FAILURE,
  CHECK_EMAIL_SUCCESS,
  CHECK_EMAIL_REQUEST,
  EMAIL_REGISTER_FAILURE,
  EMAIL_REGISTER_REQUEST,
  EMAIL_REGISTER_SUCCESS,
  APPLICANT_REGISTER_FAILURE,
  APPLICANT_REGISTER_REQUEST,
  APPLICANT_REGISTER_SUCCESS,
  GOOGLE_LOGIN_FAILURE,
  GOOGLE_LOGIN_REQUEST,
  GOOGLE_LOGIN_SUCCESS,
  MY_PROFILE_FAILURE,
  MY_PROFILE_SUCCESS,
  MY_PROFILE_REQUEST,
  EDIT_WRITER_PROFILE_FAILURE,
  EDIT_WRITER_PROFILE_SUCCESS,
  EDIT_WRITER_PROFILE_REQUEST,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_EMAIL_FAILURE,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_REQUEST,
  CHECK_UPDATE_PHONE_FAILURE,
  CHECK_UPDATE_PHONE_REQUEST,
  CHECK_UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE_FAILURE,
  UPDATE_PHONE_REQUEST,
  UPDATE_PHONE_SUCCESS,
  CLIENT_REGISTER_FAILURE,
  CLIENT_REGISTER_REQUEST,
  CLIENT_REGISTER_SUCCESS,
  VERIFY_CLIENT_FAILURE,
  VERIFY_CLIENT_REQUEST,
  VERIFY_CLIENT_SUCCESS,
  SEND_UPDATE_EMAIL_CLIENT_REQUEST,
  SEND_UPDATE_EMAIL_CLIENT_FAILURE,
  SEND_UPDATE_EMAIL_CLIENT_SUCCESS,
  SEND_UPDATE_EMAIL_PM_REQUEST,
  SEND_UPDATE_EMAIL_PM_FAILURE,
  SEND_UPDATE_EMAIL_PM_SUCCESS,
  SEND_UPDATE_EMAIL_WRITER_REQUEST,
  SEND_UPDATE_EMAIL_WRITER_FAILURE,
  SEND_UPDATE_EMAIL_WRITER_SUCCESS,
  UPDATE_EMAIL_CLIENT_REQUEST,
  UPDATE_EMAIL_CLIENT_FAILURE,
  UPDATE_EMAIL_CLIENT_SUCCESS,
  UPDATE_EMAIL_PM_REQUEST,
  UPDATE_EMAIL_PM_FAILURE,
  UPDATE_EMAIL_PM_SUCCESS,
  UPDATE_EMAIL_WRITER_REQUEST,
  UPDATE_EMAIL_WRITER_FAILURE,
  UPDATE_EMAIL_WRITER_SUCCESS,
  UPDATE_USER_FIREBASE_REQUEST,
  UPDATE_USER_FIREBASE_FAILURE,
  UPDATE_USER_FIREBASE_SUCCESS,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  CREATE_NEW_PASSWORD_REQUEST,
  CREATE_NEW_PASSWORD_FAILURE,
  CREATE_NEW_PASSWORD_SUCCESS,
  RESET_STATE_AUTH,
} from "../constant/authConstant";

export const initialAuthState = {
  loadingEmailLogin: false,
  successEmailLogin: false,
  errEmailLogin: null,

  users: null,
  isLoggedIn: false,
  verificationOtp: null,
  verificationPhoneNumber: null,
  dataInput: {
    phone_number: null,
  },
  userId: null,

  loadingLoginEmail: false,
  successLoginEmail: false,
  errLoginEmail: null,

  loadingPhoneRegister: false,
  successPhoneRegister: false,
  errPhoneRegister: null,

  loadingOtp: false,
  successOtp: false,
  errOtp: null,

  loadingLogout: false,
  successLogout: false,
  errLogout: null,

  loadingPhoneLogin: false,
  successPhoneLogin: false,
  errPhoneLogin: null,

  loadingOtpLogin: false,
  successOtpLogin: false,
  errOtpLogin: null,

  loadingCheckPhone: false,
  dataCheckPhone: null,
  errCheckPhone: null,

  loadingCheckEmail: false,
  dataCheckEmail: null,
  errCheckEmail: null,

  loadingEmailRegister: false,
  successEmailRegister: false,
  errEmailRegister: null,

  loadingApplicantRegister: false,
  successApplicantRegister: false,
  errApplicantRegister: null,

  loadingGoogleLogin: false,
  successGoogleLogin: false,
  errGoogleLogin: null,

  loadingMyProfile: false,
  successMyProfile: false,
  errMyProfile: null,

  loadingEditWriterProfile: false,
  successEditWriterProfile: false,
  errEditWriterProfile: null,

  loadingUpdatePassword: false,
  successUpdatePassword: false,
  errUpdatePassword: null,

  loadingUpdateEmail: false,
  successUpdateEmail: false,
  errUpdateEmail: null,

  loadingCheckUpdatePhone: false,
  successCheckUpdatePhone: false,
  errCheckUpdatePhone: null,

  loadingUpdatePhone: false,
  successUpdatePhone: false,
  errUpdatePhone: null,

  loadingRegisterClient: false,
  successRegisterClient: false,
  errRegisterClient: null,

  loadingVerifyClient: false,
  successVerifyClient: false,
  errVerifyClient: null,

  loadingSendUpdateEmailClient: false,
  successSendUpdateEmailClient: false,
  errSendUpdateEmailClient: null,

  loadingSendUpdateEmailPm: false,
  successSendUpdateEmailPm: false,
  errSendUpdateEmailPm: null,

  loadingSendUpdateEmailWriter: false,
  successSendUpdateEmailWriter: false,
  errSendUpdateEmailWriter: null,

  loadingUpdateEmailClient: false,
  successUpdateEmailClient: false,
  errUpdateEmailClient: null,

  loadingUpdateEmailPm: false,
  successUpdateEmailPm: false,
  errUpdateEmailPm: null,

  loadingUpdateEmailWriter: false,
  successUpdateEmailWriter: false,
  errUpdateEmailWriter: null,

  loadingUpdateUserFirebase: false,
  successUpdateUserFirebase: false,
  errUpdateUserFirebase: null,

  loadingResetPassword: false,
  successResetPassword: false,
  errResetPassword: null,

  loadingNewPassword: false,
  successNewPassword: false,
  errNewPassword: null,
};

export const authReducer = persistReducer(
  { storage, key: "v711-demo1-auth", whitelist: ["users", "isLoggedIn"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case LOGIN_WITH_EMAIL_REQUEST:
        return {
          ...state,
          loadingEmailLogin: true,
          successEmailLogin: false,
          errEmailLogin: null,
        };
      case LOGIN_WITH_EMAIL_SUCCESS:
        return {
          ...state,
          loadingEmailLogin: false,
          successEmailLogin: true,
          errEmailLogin: null,
          users: action.data,
          isLoggedIn: true,
        };
      case LOGIN_WITH_EMAIL_FAILURE:
        return {
          ...state,
          loadingEmailLogin: false,
          successEmailLogin: false,
          errEmailLogin: action.err,
        };

      case PHONE_REGISTER_REQUEST:
        return {
          ...state,
          verificationOtp: null,
          loadingPhoneRegister: true,
          successPhoneRegister: false,
          errPhoneRegister: null,
        };
      case PHONE_REGISTER_SUCCESS:
        return {
          ...state,
          verificationOtp: action.data,
          loadingPhoneRegister: false,
          successPhoneRegister: true,
          errPhoneRegister: null,
          dataInput: action.input,
        };
      case PHONE_REGISTER_FAILURE:
        return {
          ...state,
          verificationOtp: null,
          loadingPhoneRegister: false,
          successPhoneRegister: false,
          errPhoneRegister: action.err,
        };

      case VERIFY_OTP_REQUEST:
        return {
          ...state,
          loadingOtp: true,
          successOtp: false,
          errOtp: null,
        };
      case VERIFY_OTP_SUCCESS:
        return {
          ...state,
          loadingOtp: false,
          successOtp: true,
          users: action.data,
          isLoggedIn: true,
        };
      case VERIFY_OTP_FAILURE:
        return {
          ...state,
          loadingOtp: false,
          successOtp: false,
          errOtp: action.err,
        };

      case LOGIN_WITH_LINK_REQUEST:
        return {
          ...state,
          loadingLoginEmail: true,
          successLoginEmail: false,
          errLoginEmail: null,
        };
      case LOGIN_WITH_LINK_SUCCESS:
        return {
          ...state,
          loadingLoginEmail: false,
          successLoginEmail: true,
          errLoginEmail: null,
        };
      case LOGIN_WITH_LINK_FAILURE:
        return {
          ...state,
          loadingLoginEmail: false,
          successLoginEmail: false,
          errLoginEmail: action.err,
        };

      case LOGOUT_REQUEST:
        return {
          ...state,
          loadingLogout: true,
          successLogout: false,
          errLogout: null,
        };
      case LOGOUT_SUCCESS:
        return {
          ...state,
          loadingLogout: false,
          successLogout: true,
          errLogout: null,
          users: null,
          isLoggedIn: false,
        };
      case LOGOUT_FAILURE:
        return {
          ...state,
          loadingLogout: false,
          successLogout: false,
          errLogout: action.err,
        };

      case PHONE_LOGIN_REQUEST:
        return {
          ...state,
          verificationOtp: null,
          loadingPhoneLogin: true,
          successPhoneLogin: false,
          errPhoneLogin: null,
        };
      case PHONE_LOGIN_SUCCESS:
        return {
          ...state,
          verificationOtp: action.data,
          loadingPhoneLogin: false,
          successPhoneLogin: true,
          errPhoneLogin: null,
          dataInput: action.input,
        };
      case PHONE_LOGIN_FAILURE:
        return {
          ...state,
          verificationOtp: null,
          loadingPhoneLogin: false,
          successPhoneLogin: false,
          errPhoneLogin: action.err,
        };

      case LOGIN_VERIFY_OTP_REQUEST:
        return {
          ...state,
          loadingOtpLogin: true,
          successOtpLogin: false,
          errOtpLogin: null,
        };
      case LOGIN_VERIFY_OTP_SUCCESS:
        return {
          ...state,
          loadingOtpLogin: false,
          successOtpLogin: true,
          users: action.data,
          isLoggedIn: true,
        };
      case LOGIN_VERIFY_OTP_FAILURE:
        return {
          ...state,
          loadingOtpLogin: false,
          successOtpLogin: false,
          errOtpLogin: action.err,
        };

      case CHECK_PHONE_REQUEST:
        return {
          ...state,
          loadingCheckPhone: true,
          errCheckPhone: null,
        };
      case CHECK_PHONE_SUCCESS:
        return {
          ...state,
          loadingCheckPhone: false,
          dataCheckPhone: action.data.data,
        };
      case CHECK_PHONE_FAILURE:
        return {
          ...state,
          loadingCheckPhone: false,
          errCheckPhone: action.data?.message,
        };

      case CHECK_EMAIL_REQUEST:
        return {
          ...state,
          loadingCheckEmail: true,
          errCheckEmail: null,
        };
      case CHECK_EMAIL_SUCCESS:
        return {
          ...state,
          loadingCheckEmail: false,
          dataCheckEmail: action.data.data,
        };
      case CHECK_EMAIL_FAILURE:
        return {
          ...state,
          loadingCheckEmail: false,
          errCheckEmail: action.err,
        };

      case EMAIL_REGISTER_REQUEST:
        return {
          ...state,
          loadingEmailRegister: true,
          successEmailRegister: false,
          errEmailRegister: null,
        };
      case EMAIL_REGISTER_SUCCESS:
        return {
          ...state,
          loadingEmailRegister: false,
          successEmailRegister: true,
          users: action.data,
          isLoggedIn: true,
        };
      case EMAIL_REGISTER_FAILURE:
        return {
          ...state,
          loadingEmailRegister: false,
          successEmailRegister: false,
          errEmailRegister: action.err,
        };

      case APPLICANT_REGISTER_REQUEST:
        return {
          ...state,
          loadingApplicantRegister: true,
          successApplicantRegister: false,
          errApplicantRegister: null,
        };
      case APPLICANT_REGISTER_SUCCESS:
        return {
          ...state,
          loadingApplicantRegister: false,
          successApplicantRegister: true,
        };
      case APPLICANT_REGISTER_FAILURE:
        return {
          ...state,
          loadingApplicantRegister: false,
          successApplicantRegister: false,
          errApplicantRegister: action.data.message,
        };

      case GOOGLE_LOGIN_REQUEST:
        return {
          ...state,
          loadingGoogleLogin: true,
          successGoogleLogin: false,
          errGoogleLogin: null,
        };
      case GOOGLE_LOGIN_SUCCESS:
        return {
          ...state,
          loadingGoogleLogin: false,
          successGoogleLogin: true,
          users: action.data,
          isLoggedIn: true,
        };
      case GOOGLE_LOGIN_FAILURE:
        return {
          ...state,
          loadingGoogleLogin: false,
          successGoogleLogin: false,
          errGoogleLogin: action.err,
        };

      case MY_PROFILE_REQUEST:
        return {
          ...state,
          loadingMyProfile: true,
          successMyProfile: false,
          errMyProfile: null,
        };
      case MY_PROFILE_SUCCESS:
        return {
          ...state,
          loadingMyProfile: false,
          successMyProfile: true,
          users: action.data,
        };
      case MY_PROFILE_FAILURE:
        return {
          ...state,
          loadingMyProfile: false,
          successMyProfile: false,
          errMyProfile: action.err,
        };

      case EDIT_WRITER_PROFILE_REQUEST:
        return {
          ...state,
          loadingEditWriterProfile: true,
          successEditWriterProfile: false,
          errEditWriterProfile: null,
        };
      case EDIT_WRITER_PROFILE_SUCCESS:
        return {
          ...state,
          loadingEditWriterProfile: false,
          successEditWriterProfile: true,
          errEditWriterProfile: null,
        };
      case EDIT_WRITER_PROFILE_FAILURE:
        return {
          ...state,
          loadingEditWriterProfile: false,
          successEditWriterProfile: false,
          errEditWriterProfile: action.err,
        };

      case UPDATE_PASSWORD_REQUEST:
        return {
          ...state,
          loadingUpdatePassword: true,
          successUpdatePassword: false,
          errUpdatePassword: null,
        };
      case UPDATE_PASSWORD_SUCCESS:
        return {
          ...state,
          loadingUpdatePassword: false,
          successUpdatePassword: true,
          errUpdatePassword: null,
        };
      case UPDATE_PASSWORD_FAILURE:
        return {
          ...state,
          loadingUpdatePassword: false,
          successUpdatePassword: false,
          errUpdatePassword: action.err,
        };

      case UPDATE_EMAIL_REQUEST:
        return {
          ...state,
          loadingUpdateEmail: true,
          successUpdateEmail: false,
          errUpdateEmail: null,
        };
      case UPDATE_EMAIL_SUCCESS:
        return {
          ...state,
          loadingUpdateEmail: false,
          successUpdateEmail: true,
          errUpdateEmail: null,
        };
      case UPDATE_EMAIL_FAILURE:
        return {
          ...state,
          loadingUpdateEmail: false,
          successUpdateEmail: false,
          errUpdateEmail: action.err,
        };

      case CHECK_UPDATE_PHONE_REQUEST:
        return {
          ...state,
          loadingCheckUpdatePhone: true,
          successCheckUpdatePhone: false,
          errCheckUpdatePhone: null,
        };
      case CHECK_UPDATE_PHONE_SUCCESS:
        return {
          ...state,
          loadingCheckUpdatePhone: false,
          successCheckUpdatePhone: true,
          verificationPhoneNumber: action.data,
          errCheckUpdatePhone: null,
        };
      case CHECK_UPDATE_PHONE_FAILURE:
        return {
          ...state,
          loadingCheckUpdatePhone: false,
          successCheckUpdatePhone: false,
          errCheckUpdatePhone: action.err,
        };

      case UPDATE_PHONE_REQUEST:
        return {
          ...state,
          loadingUpdatePhone: true,
          successUpdatePhone: false,
          errUpdatePhone: null,
        };
      case UPDATE_PHONE_SUCCESS:
        return {
          ...state,
          loadingUpdatePhone: false,
          successUpdatePhone: true,
          errUpdatePhone: null,
        };
      case UPDATE_PHONE_FAILURE:
        return {
          ...state,
          loadingUpdatePhone: false,
          successUpdatePhone: false,
          errUpdatePhone: action.err,
        };

      case CLIENT_REGISTER_REQUEST:
        return {
          ...state,
          loadingRegisterClient: true,
          successRegisterClient: false,
          errRegisterClient: null,
        };
      case CLIENT_REGISTER_SUCCESS:
        return {
          ...state,
          loadingRegisterClient: false,
          successRegisterClient: true,
          errRegisterClient: null,
        };
      case CLIENT_REGISTER_FAILURE:
        return {
          ...state,
          loadingRegisterClient: false,
          successRegisterClient: false,
          errRegisterClient: action.data,
        };

      case VERIFY_CLIENT_REQUEST:
        return {
          ...state,
          loadingVerifyClient: true,
          successVerifyClient: false,
          errVerifyClient: null,
        };
      case VERIFY_CLIENT_SUCCESS:
        return {
          ...state,
          loadingVerifyClient: false,
          successVerifyClient: true,
          errVerifyClient: null,
        };
      case VERIFY_CLIENT_FAILURE:
        return {
          ...state,
          loadingVerifyClient: false,
          successVerifyClient: false,
          errVerifyClient: action.data,
        };

      case SEND_UPDATE_EMAIL_CLIENT_REQUEST:
        return {
          ...state,
          loadingSendUpdateEmailClient: true,
          successSendUpdateEmailClient: false,
          errSendUpdateEmailClient: null,
        };
      case SEND_UPDATE_EMAIL_CLIENT_SUCCESS:
        return {
          ...state,
          loadingSendUpdateEmailClient: false,
          successSendUpdateEmailClient: true,
          errSendUpdateEmailClient: null,
        };
      case SEND_UPDATE_EMAIL_CLIENT_FAILURE:
        return {
          ...state,
          loadingSendUpdateEmailClient: false,
          successSendUpdateEmailClient: false,
          errSendUpdateEmailClient: action.data,
        };

      case SEND_UPDATE_EMAIL_PM_REQUEST:
        return {
          ...state,
          loadingSendUpdateEmailPm: true,
          successSendUpdateEmailPm: false,
          errSendUpdateEmailPm: null,
        };
      case SEND_UPDATE_EMAIL_PM_SUCCESS:
        return {
          ...state,
          loadingSendUpdateEmailPm: false,
          successSendUpdateEmailPm: true,
          errSendUpdateEmailPm: null,
        };
      case SEND_UPDATE_EMAIL_PM_FAILURE:
        return {
          ...state,
          loadingSendUpdateEmailPm: false,
          successSendUpdateEmailPm: false,
          errSendUpdateEmailPm: action.data,
        };

      case SEND_UPDATE_EMAIL_WRITER_REQUEST:
        return {
          ...state,
          loadingSendUpdateEmailWriter: true,
          successSendUpdateEmailWriter: false,
          errSendUpdateEmailWriter: null,
        };
      case SEND_UPDATE_EMAIL_WRITER_SUCCESS:
        return {
          ...state,
          loadingSendUpdateEmailWriter: false,
          successSendUpdateEmailWriter: true,
          errSendUpdateEmailWriter: null,
        };
      case SEND_UPDATE_EMAIL_WRITER_FAILURE:
        return {
          ...state,
          loadingSendUpdateEmailWriter: false,
          successSendUpdateEmailWriter: false,
          errSendUpdateEmailWriter: action.data,
        };

      case UPDATE_EMAIL_CLIENT_REQUEST:
        return {
          ...state,
          loadingUpdateEmailClient: true,
          successUpdateEmailClient: false,
          errUpdateEmailClient: null,
        };
      case UPDATE_EMAIL_CLIENT_SUCCESS:
        return {
          ...state,
          loadingUpdateEmailClient: false,
          successUpdateEmailClient: true,
          errUpdateEmailClient: null,
        };
      case UPDATE_EMAIL_CLIENT_FAILURE:
        return {
          ...state,
          loadingUpdateEmailClient: false,
          successUpdateEmailClient: false,
          errUpdateEmailClient: action.data,
        };

      case UPDATE_EMAIL_PM_REQUEST:
        return {
          ...state,
          loadingUpdateEmailPm: true,
          successUpdateEmailPm: false,
          errUpdateEmailPm: null,
        };
      case UPDATE_EMAIL_PM_SUCCESS:
        return {
          ...state,
          loadingUpdateEmailPm: false,
          successUpdateEmailPm: true,
          errUpdateEmailPm: null,
        };
      case UPDATE_EMAIL_PM_FAILURE:
        return {
          ...state,
          loadingUpdateEmailPm: false,
          successUpdateEmailPm: false,
          errUpdateEmailPm: action.data,
        };

      case UPDATE_EMAIL_WRITER_REQUEST:
        return {
          ...state,
          loadingUpdateEmailWriter: true,
          successUpdateEmailWriter: false,
          errUpdateEmailWriter: null,
        };
      case UPDATE_EMAIL_WRITER_SUCCESS:
        return {
          ...state,
          loadingUpdateEmailWriter: false,
          successUpdateEmailWriter: true,
          errUpdateEmailWriter: null,
        };
      case UPDATE_EMAIL_WRITER_FAILURE:
        return {
          ...state,
          loadingUpdateEmailWriter: false,
          successUpdateEmailWriter: false,
          errUpdateEmailWriter: action.data,
        };

      case UPDATE_USER_FIREBASE_REQUEST:
        return {
          ...state,
          loadingUpdateUserFirebase: true,
          successUpdateUserFirebase: false,
          errUpdateUserFirebase: null,
        };
      case UPDATE_USER_FIREBASE_SUCCESS:
        return {
          ...state,
          loadingUpdateUserFirebase: false,
          successUpdateUserFirebase: true,
          errUpdateUserFirebase: null,
        };
      case UPDATE_USER_FIREBASE_FAILURE:
        return {
          ...state,
          loadingUpdateUserFirebase: false,
          successUpdateUserFirebase: false,
          errUpdateUserFirebase: action.data,
        };

      case RESET_PASSWORD_REQUEST:
        return {
          ...state,
          loadingResetPassword: true,
          successResetPassword: false,
          errResetPassword: null,
        };
      case RESET_PASSWORD_SUCCESS:
        return {
          ...state,
          loadingResetPassword: false,
          successResetPassword: true,
          errResetPassword: null,
        };
      case RESET_PASSWORD_FAILURE:
        return {
          ...state,
          loadingResetPassword: false,
          successResetPassword: false,
          errResetPassword: action.data,
        };

      case CREATE_NEW_PASSWORD_REQUEST:
        return {
          ...state,
          loadingNewPassword: true,
          successNewPassword: false,
          errNewPassword: null,
        };
      case CREATE_NEW_PASSWORD_SUCCESS:
        return {
          ...state,
          loadingNewPassword: false,
          successNewPassword: true,
          errNewPassword: null,
        };
      case CREATE_NEW_PASSWORD_FAILURE:
        return {
          ...state,
          loadingNewPassword: false,
          successNewPassword: false,
          errNewPassword: action.data,
        };

      case RESET_STATE_AUTH:
        return {
          ...state,
          loadingEditWriterProfile: false,
          successEditWriterProfile: false,
          errEditWriterProfile: null,
          loadingUpdatePassword: false,
          successUpdatePassword: false,
          errUpdatePassword: null,
          loadingUpdateEmail: false,
          successUpdateEmail: false,
          errUpdateEmail: null,
          loadingCheckUpdatePhone: false,
          successCheckUpdatePhone: false,
          errCheckUpdatePhone: null,
          loadingUpdatePhone: false,
          successUpdatePhone: false,
          errUpdatePhone: null,
          loadingEmailLogin: false,
          successEmailLogin: false,
          errEmailLogin: null,
          loadingSendUpdateEmailPm: false,
          successSendUpdateEmailPm: false,
          errSendUpdateEmailPm: null,
          loadingSendUpdateEmailWriter: false,
          successSendUpdateEmailWriter: false,
          errSendUpdateEmailWriter: null,
          loadingUpdateEmailClient: false,
          successUpdateEmailClient: false,
          errUpdateEmailClient: null,
          loadingUpdateEmailPm: false,
          successUpdateEmailPm: false,
          errUpdateEmailPm: null,
          loadingUpdateEmailWriter: false,
          successUpdateEmailWriter: false,
          errUpdateEmailWriter: null,
          loadingApplicantRegister: false,
          successApplicantRegister: false,
          errApplicantRegister: null,
          loadingOtpLogin: false,
          successOtpLogin: false,
          errOtpLogin: null,
          loadingUpdateUserFirebase: false,
          successUpdateUserFirebase: false,
          errUpdateUserFirebase: null,
          loadingCheckPhone: false,
          dataCheckPhone: null,
          errCheckPhone: null,
          loadingCheckEmail: false,
          dataCheckEmail: null,
          errCheckEmail: null,
          loadingPhoneLogin: false,
          successPhoneLogin: false,
          errPhoneLogin: null,
          loadingResetPassword: false,
          successResetPassword: false,
          errResetPassword: null,
          loadingNewPassword: false,
          successNewPassword: false,
          errNewPassword: null,
          loadingRegisterClient: false,
          successRegisterClient: false,
          errRegisterClient: null,
        };

      default:
        return state;
    }
  }
);
