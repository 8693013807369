import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import sortIcon from "../../../../assets/img/Filter.png";
import { InputText } from "primereact/inputtext";
import { RadioButton } from "primereact/radiobutton";
import { getFinanceOrderAction } from "../../../../../redux/action/financeAction";

const FilterListInvoice = (props) => {
  const { isFinance } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const [show, setShow] = useState(false);
  const [orderValue, setOrderValue] = useState({
    name: "newDate",
    orderDir: "desc",
    orderField: "createdAt",
  });
  const { parsed } = props;

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        orderField: orderValue?.orderField,
        orderDir: orderValue?.orderDir,
        page: 1,
        perpage: 10,
      };

      history.push(
        `/admin/${isFinance ? "finance" : "pm"}/list-invoice?search=${search ||
          ""}&orderField=${parsed?.orderField ||
          ""}&orderDir=${parsed?.orderDir || ""}&page=1`
      );
      dispatch(getFinanceOrderAction(params));
      setShow(false);
    }, 1000)
  ).current;

  const sortHandler = (data) => {
    setOrderValue(data);
    const params = {
      q: parsed?.search || "",
      orderField: orderValue?.orderField,
      orderDir: orderValue?.orderDir,
      page: 1,
      perpage: 10,
    };

    history.push(
      `/admin/${
        isFinance ? "finance" : "pm"
      }/list-invoice?search=${parsed?.search ||
        ""}&orderField=${orderValue?.orderField ||
        ""}&orderDir=${orderValue?.orderDir || ""}&page=1`
    );
    dispatch(getFinanceOrderAction(params));
    setShow(false);
  };

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter">
        <div className="filter-field">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block search-inputtext"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <p>
            Cari berdasarkan <span>Nomor Invoice/Order Id</span>
          </p>
        </div>
        <div className="filter-field">
          <button className="btn-sort" onClick={() => setShow(!show)}>
            <img src={sortIcon} alt="filter" />
          </button>
        </div>
      </div>
      <div className={`popup-project-sort invoice ${!show && "d-none"}`}>
        <div className="sort-body">
          <div className="sort-by">
            <h3 className="p-mb-4 p-mt-3">Urutkan</h3>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="sort1"
                name="sort"
                value="newDate"
                onChange={(e) =>
                  setOrderValue({
                    name: e.value,
                    orderDir: "desc",
                    orderField: "createdAt",
                  })
                }
                checked={orderValue?.name === "newDate"}
              />
              <label htmlFor="sort1" className="p-mb-0">
                Tanggal Invoice Terbaru
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="sort2"
                name="sort"
                value="oldDate"
                onChange={(e) =>
                  setOrderValue({
                    name: e.value,
                    orderDir: "asc",
                    orderField: "createdAt",
                  })
                }
                checked={orderValue?.name === "oldDate"}
              />
              <label htmlFor="sort2" className="p-mb-0">
                Tanggal Invoice Terlama
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="sort3"
                name="sort"
                value="newDeadline"
                onChange={(e) =>
                  setOrderValue({
                    name: e.value,
                    orderDir: "desc",
                    orderField: "paymentDeadline",
                  })
                }
                checked={orderValue?.name === "newDeadline"}
              />
              <label htmlFor="sort3" className="p-mb-0">
                Deadline Z-A
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="sort4"
                name="sort"
                value="oldDeadline"
                onChange={(e) =>
                  setOrderValue({
                    name: e.value,
                    orderDir: "asc",
                    orderField: "paymentDeadline",
                  })
                }
                checked={orderValue?.name === "oldDeadline"}
              />
              <label htmlFor="sort4" className="p-mb-0">
                Deadline A-Z
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="sort5"
                name="sort"
                value="azInvoice"
                onChange={(e) =>
                  setOrderValue({
                    name: e.value,
                    orderDir: "asc",
                    orderField: "invoiceNumber",
                  })
                }
                checked={orderValue?.name === "azInvoice"}
              />
              <label htmlFor="sort5" className="p-mb-0">
                Nomor Invoice A-Z
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="sort6"
                name="sort"
                value="zaInvoice"
                onChange={(e) =>
                  setOrderValue({
                    name: e.value,
                    orderDir: "desc",
                    orderField: "invoiceNumber",
                  })
                }
                checked={orderValue?.name === "zaInvoice"}
              />
              <label htmlFor="sort6" className="p-mb-0">
                Nomor Invoice Z-A
              </label>
            </div>
            <div className="button">
              <button
                className="p-btn reset mr-2"
                onClick={() => setShow(false)}
              >
                Batal
              </button>
              <button
                className="p-btn btn-primary ml-2"
                onClick={() => sortHandler()}
              >
                Terapkan
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterListInvoice;
