import {
  GET_FINANCE_ORDER_REQUEST,
  GET_FINANCE_ORDER_SUCCESS,
  GET_FINANCE_ORDER_FAILURE,
  UPDATE_FINANCE_ORDER_REQUEST,
  UPDATE_FINANCE_ORDER_SUCCESS,
  UPDATE_FINANCE_ORDER_FAILURE,
  GET_FINANCE_PROJECT_REQUEST,
  GET_FINANCE_PROJECT_SUCCESS,
  GET_FINANCE_PROJECT_FAILURE,
  APPROVE_PAYOUT_REQUEST,
  APPROVE_PAYOUT_SUCCESS,
  APPROVE_PAYOUT_FAILURE,
  APPROVE_ALL_PAYOUT_REQUEST,
  APPROVE_ALL_PAYOUT_SUCCESS,
  APPROVE_ALL_PAYOUT_FAILURE,
  RESET_FINANCE_STATE,
} from "../constant/financeConstant";

const initialStateFinance = {
  financeOrderParams: {
    perpage: 10,
    page: 1,
  },
  totalPage: null,

  loadingGetFinanceOrder: false,
  dataGetFinanceOrder: null,
  errGetFinanceOrder: null,

  loadingGetFinanceProject: false,
  dataGetFinanceProject: null,
  errGetFinanceProject: null,

  loadingUpdateFinanceOrder: false,
  successUpdateFinanceOrder: false,
  errUpdateFinanceOrder: null,

  loadingApprovePayout: false,
  successApprovePayout: false,
  errApprovePayout: null,

  loadingApproveAllPayout: false,
  successApproveAllPayout: false,
  errApproveAllPayout: null,
};

export const financeReducer = (state = initialStateFinance, action) => {
  switch (action.type) {
    case RESET_FINANCE_STATE:
      return {
        ...state,
        loadingUpdateFinanceOrder: false,
        successUpdateFinanceOrder: false,
        errUpdateFinanceOrder: null,

        loadingApprovePayout: false,
        successApprovePayout: false,
        errApprovePayout: null,

        loadingApproveAllPayout: false,
        successApproveAllPayout: false,
        errApproveAllPayout: null,
      };

    case GET_FINANCE_ORDER_REQUEST:
      return {
        ...state,
        loadingGetFinanceOrder: true,
        errGetFinanceOrder: null,
      };
    case GET_FINANCE_ORDER_SUCCESS:
      return {
        ...state,
        loadingGetFinanceOrder: false,
        dataGetFinanceOrder: action.data.data,
        errGetFinanceOrder: null,
        totalPage: action.data.totalpage,
      };
    case GET_FINANCE_ORDER_FAILURE:
      return {
        ...state,
        loadingGetFinanceOrder: false,
        errGetFinanceOrder: action.data,
      };

    case UPDATE_FINANCE_ORDER_REQUEST:
      return {
        ...state,
        loadingUpdateFinanceOrder: true,
        successUpdateFinanceOrder: false,
        errUpdateFinanceOrder: null,
      };
    case UPDATE_FINANCE_ORDER_SUCCESS:
      return {
        ...state,
        loadingUpdateFinanceOrder: false,
        successUpdateFinanceOrder: true,
        errUpdateFinanceOrder: null,
      };
    case UPDATE_FINANCE_ORDER_FAILURE:
      return {
        ...state,
        loadingUpdateFinanceOrder: false,
        successUpdateFinanceOrder: false,
        errUpdateFinanceOrder: action.data,
      };

    case GET_FINANCE_PROJECT_REQUEST:
      return {
        ...state,
        loadingGetFinanceProject: true,
        errGetFinanceProject: null,
      };
    case GET_FINANCE_PROJECT_SUCCESS:
      return {
        ...state,
        loadingGetFinanceProject: false,
        dataGetFinanceProject: action.data.data,
        errGetFinanceProject: null,
        totalPage: action.data.totalpage,
      };
    case GET_FINANCE_PROJECT_FAILURE:
      return {
        ...state,
        loadingGetFinanceProject: false,
        errGetFinanceProject: action.data,
      };

    case APPROVE_PAYOUT_REQUEST:
      return {
        ...state,
        loadingApprovePayout: true,
        successApprovePayout: false,
        errApprovePayout: null,
      };
    case APPROVE_PAYOUT_SUCCESS:
      return {
        ...state,
        loadingApprovePayout: false,
        successApprovePayout: true,
        errApprovePayout: null,
      };
    case APPROVE_PAYOUT_FAILURE:
      return {
        ...state,
        loadingApprovePayout: false,
        successApprovePayout: false,
        errApprovePayout: action.data,
      };

    case APPROVE_ALL_PAYOUT_REQUEST:
      return {
        ...state,
        loadingApproveAllPayout: true,
        successApproveAllPayout: false,
        errApproveAllPayout: null,
      };
    case APPROVE_ALL_PAYOUT_SUCCESS:
      return {
        ...state,
        loadingApproveAllPayout: false,
        successApproveAllPayout: true,
        errApproveAllPayout: null,
      };
    case APPROVE_ALL_PAYOUT_FAILURE:
      return {
        ...state,
        loadingApproveAllPayout: false,
        successApproveAllPayout: false,
        errApproveAllPayout: action.data,
      };

    default:
      return state;
  }
};
