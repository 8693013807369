import firebase from "./firebase";
const firestore = firebase.firestore();

export const sendChat = async (id, data) => {
  return await firestore
    .collection("writerProjects")
    .doc(id)
    .collection("chats")
    .add({ ...data });
};

export const sendClientChat = async (id, data) => {
  return await firestore
    .collection("writerProjects")
    .doc(id)
    .collection("clientChats")
    .add({ ...data });
};

export const unpinnedChat = async (projectId, chatId) => {
  return await firestore
    .collection("writerProjects")
    .doc(projectId)
    .collection("chats")
    .doc(chatId)
    .update({ isPinned: false });
};
