import React from "react";
import { Link } from "react-router-dom";
import clientImage from "../../../assets/img/register-client.svg";
import writerImage from "../../../assets/img/register-writer.svg";

function SelectRoleModule() {
  return (
    <div className="p-d-flex justify-content-sm-start justify-content-between align-items-center row">
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="role-card">
          <div className="role-card-image">
            <img src={clientImage} alt="client" />
          </div>
          <div>
            <h4>Daftar sebagai klien untuk memesan konten tulisan</h4>
            <Link className="p-button p-button-primary" to="/register-client">
              DAFTAR
            </Link>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-6 col-sm-12">
        <div className="role-card">
          <div className="role-card-image">
            <img src={writerImage} alt="writer" />
          </div>
          <div>
            <h4>Daftar sebagai penulis untuk membuat konten tulisan</h4>
            <Link className="p-button p-button-primary" to="/register-writer">
              DAFTAR
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectRoleModule;
