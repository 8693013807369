import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import ListInfoModule from "../../../modules/Admin/Information/ListInfoModule";

import { getListInfoAction } from "../../../../redux/action/adminAction";

const ListInfoPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Informasi Terbaru",
    });
    history.push("/admin/pm/list-information");
    const params = {
      page: 1,
      perpage: 10,
    };
    dispatch(getListInfoAction(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Informasi Terbaru" menu="info">
      <ListInfoModule />
    </DashboardLayout>
  );
};

export default ListInfoPage;
