import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import ServiceModule from "../../modules/Client/Service/ServiceModule";

import { titlePage } from "../../helpers/titleHead";
import { getProductAction } from "../../../redux/action/serviceAction";
import { clientDataProjectAction } from "../../../redux/action/utilsAction";

function ServicePage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const params = { page: 1, perpage: 10 };
    dispatch(getProductAction(params));
    dispatch(clientDataProjectAction(null))
    titlePage({
      title: "IRIS - Layanan & Produk",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="service-page">
      <DashboardClientLayout title="Layanan &amp; Produk">
        <ServiceModule />
      </DashboardClientLayout>
    </div>
  );
}

export default ServicePage;
