import React, { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { uploadImageAction } from "../../../../redux/action/utilsAction";
import { UploadProfileClient } from "../../../components/upload/uploadProfileClient";
import { useHistory } from "react-router-dom";
import { formPhoneNumber } from "../../../helpers/phoneNumber";
import { formEmail } from "../../../helpers/email";
import {
  resetStateAuthAction,
  updateUserFirebaseAction,
} from "../../../../redux/action/authAction";
import ButtonDefault from "../../../components/button/ButtonDefault";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { updateCompanyAction } from "../../../../redux/action/clientAction";
import UploadPdf from "../../../components/upload/uploadPdf";

function ProfileForm({ isEdit }) {
  const history = useHistory();
  const { dataDetailClient } = useSelector((state) => state.client);
  const {
    users,
    loadingUpdateUserFirebase,
    successUpdateUserFirebase,
    errUpdateUserFirebase,
  } = useSelector((state) => state.auth);
  const {
    loadingUploadImage,
    successUploadImage,
    dataUploadImage,
    dataBusiness,
  } = useSelector((state) => state.utils);

  const {
    successUpdateCompany,
    errUpdateCompany,
    loadingUpdateCompany,
  } = useSelector((state) => state.client);

  const {
    loadingUploadCompanyProfile,
    successUploadCompanyProfile,
    dataUploadCompanyProfile,
  } = useSelector((state) => state.user);

  const dispatch = useDispatch();

  const [business, setBusiness] = useState([]);
  const [errorImage, setErrorImage] = useState("");
  const [fileValue, setFileValue] = useState(null);
  const [fileName, setFileName] = useState("");
  const [nameState, setNameState] = useState({ value: "", error: null });
  const [positionState, setPositionState] = useState({
    value: "",
    error: null,
  });
  const [companyNameState, setCompanyNameState] = useState({
    value: "",
    error: null,
  });
  const [companyBackgroundState, setCompanyBackgroundState] = useState({
    value: "",
    error: null,
  });
  const [companyPhoneState, setCompanyPhoneState] = useState({
    value: "",
    error: null,
  });
  const [businessFieldState, setBusinessFieldState] = useState({
    value: "",
    error: null,
  });
  const [companyAddressState, setCompanyAddressState] = useState({
    value: "",
    error: null,
  });
  const [companyProfileState, setCompanyProfileState] = useState({
    value: "",
    error: null,
  });
  const [companyEmailState, setCompanyEmailState] = useState({
    value: "",
    error: null,
  });
  const [companyServiceState, setCompanyServiceState] = useState({
    value: "",
    error: null,
  });
  const [showDialogSuccess, setShowDialogSuccess] = useState(false);
  const [showDialogDanger, setShowDialogDanger] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [pdfValue, setPdfValue] = useState(null);

  useEffect(() => {
    if (successUploadImage) {
      setFileValue(dataUploadImage);
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (dataBusiness) {
      setBusiness(dataBusiness);
    }
  }, [dataBusiness]);

  useEffect(() => {
    if (successUploadCompanyProfile) {
      setCompanyProfileState({ value: dataUploadCompanyProfile, error: null });
    }
  }, [successUploadCompanyProfile, dataUploadCompanyProfile]);

  useEffect(() => {
    if (successUpdateUserFirebase && successUpdateCompany) {
      setShowDialogSuccess(true);
    }
  }, [successUpdateUserFirebase, successUpdateCompany]);

  useEffect(() => {
    if (successUpdateUserFirebase) {
      setShowDialogSuccess(true);
    }
  }, [successUpdateUserFirebase]);

  useEffect(() => {
    if (errUpdateUserFirebase) {
      setShowDialogDanger(true);
      setDialogText(errUpdateUserFirebase?.message);
    } else if (errUpdateCompany !== null) {
      setShowDialogDanger(true);
      setDialogText(errUpdateCompany?.message);
    }
  }, [errUpdateUserFirebase, errUpdateCompany]);

  useEffect(() => {
    if (dataDetailClient && users) {
      setNameState({ value: users?.user?.name, error: null });
      setPositionState({ value: users?.user?.position, error: null });
      setFileValue(users?.user?.photo);
      setCompanyEmailState({
        value: dataDetailClient?.companyEmail,
        error: null,
      });
      setCompanyNameState({
        value: dataDetailClient?.companyName,
        error: null,
      });
      setCompanyAddressState({
        value: dataDetailClient?.companyAddress,
        error: null,
      });
      setCompanyBackgroundState({
        value: dataDetailClient?.companyBackground,
        error: null,
      });
      setCompanyPhoneState({
        value: dataDetailClient?.companyPhone?.replace("+62", "0"),
        error: null,
      });
      setBusinessFieldState({
        value: dataDetailClient?.businessField,
        error: null,
      });
      setCompanyProfileState({
        value: dataDetailClient?.companyPortofolio,
        error: null,
      });
      setCompanyServiceState({
        value: dataDetailClient?.companyService,
        error: null,
      });
      setPdfValue(dataDetailClient?.companyPortofolio);
    }
  }, [users, dataDetailClient]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".jpg, .jpeg, .png",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        setErrorImage("Ukuran file lebih dari 5MB");
      } else if (acceptedFiles.length > 1) {
        setErrorImage("Silakan pilih satu file");
      } else {
        setErrorImage("");
        setFileName(acceptedFiles[0].name);

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/user-data/${times}/${users?.uid}/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
      }
    },
  });

  const submitProfile = () => {
    const data = {
      ...users?.user,
      name: nameState.value,
      position: positionState.value,
      photo: fileValue ? fileValue : process.env.REACT_APP_IMAGE_USER,
    };
    const companyData = {
      ...dataDetailClient,
      businessField: businessFieldState.value,
      companyAddress: companyAddressState.value,
      companyBackground: companyBackgroundState.value,
      companyName: companyNameState.value,
      companyPhone: companyPhoneState.value?.includes("+")
        ? companyPhoneState.value
        : `+62${companyPhoneState.value?.substring(1)}`,
      companyPortofolio: companyProfileState.value,
      companyEmail: companyEmailState.value,
      companyService: companyServiceState?.value,
    };
    if (!nameState.value) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    }
    if (!positionState.value) {
      setPositionState({ ...positionState, error: "Jabatan harus diis" });
    }
    if (!companyAddressState.value) {
      setCompanyAddressState({
        ...companyAddressState,
        error: "Nama perusahaan harus diisi",
      });
    }
    if (!companyBackgroundState.value) {
      setCompanyBackgroundState({
        ...companyBackgroundState,
        error: "Latar belakang perusahaan harus diisi",
      });
    }
    if (!companyServiceState.value) {
      setCompanyServiceState({
        ...companyServiceState,
        error: "Jenis layanan perusahaan harus diisi",
      });
    }
    if (!companyNameState.value) {
      setCompanyNameState({
        ...companyNameState,
        error: "Nama Perusahaan harus diisi",
      });
    }
    if (!formEmail(companyEmailState.value)) {
      setCompanyEmailState({
        ...companyEmailState,
        error: "Email Perusahaan tidak valid",
      });
    }
    if (!companyEmailState.value) {
      setCompanyEmailState({
        ...companyEmailState,
        error: "Email Perusahaan harus diisi",
      });
    }
    if (!formPhoneNumber(companyPhoneState.value)) {
      setCompanyPhoneState({
        ...companyPhoneState,
        error: "Nomor telepon tidak valid",
      });
    }
    if (!companyPhoneState.value) {
      setCompanyPhoneState({
        ...companyPhoneState,
        error: "Nomor telepon perusahaan harus diisi",
      });
    }
    if (!businessFieldState.value) {
      setBusinessFieldState({ ...businessFieldState, value: null });
    }

    if (
      formPhoneNumber(companyPhoneState.value) &&
      nameState.value &&
      positionState.value &&
      companyAddressState.value &&
      companyBackgroundState.value &&
      companyNameState.value &&
      businessFieldState.value &&
      companyServiceState.value
      // && companyProfileState.value
    ) {
      dispatch(updateUserFirebaseAction(users?.uid, data));
      dispatch(updateCompanyAction(users?.user?.companyId, companyData));
    }
  };

  const submitProfilePic = () => {
    const data = {
      ...users?.user,
      name: nameState.value,
      photo: fileValue ? fileValue : process.env.REACT_APP_IMAGE_USER,
    };

    if (!nameState.value) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    } else {
      dispatch(updateUserFirebaseAction(users?.uid, data));
    }
  };

  const handleInputPhone = (e) => {
    e.target.value.length < 13 &&
      setCompanyPhoneState({ value: e.target.value.toString(), error: null });
  };

  const handleKeyInputPhone = (e) => {
    const allowedKeys = "1234567890+".split("");
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  const closeDanger = () => {
    setShowDialogDanger(false);
  };

  const closeSuccess = () => {
    dispatch(resetStateAuthAction());
    setShowDialogSuccess(false);
    history.push("/client/profile");
  };

  return (
    <>
      <div className="profile-info d-block w-100">
        <div className="row">
          <div className="col-12">
            <div className="d-flex p-jc-between p-ai-center">
              <h3 className="mb-4">Profil Pengguna</h3>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="photo-profile-wrapper mb-5">
              <div className="photo-profile">
                <UploadProfileClient
                  isEdit={isEdit}
                  attachment={fileValue ? fileValue : users?.user?.photo}
                  path="user-data"
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="change-photo">
              <h4>Unggah Gambar</h4>
              <div className="p-field">
                <div {...getRootProps({ className: "file-upload" })}>
                  <input {...getInputProps()} />
                  <span className="file-name">
                    {fileValue !== null ? fileName : ""}
                  </span>
                  {loadingUploadImage ? (
                    <span className="file-button">Mengunggah...</span>
                  ) : (
                    <span className="file-button" onClick={open}>
                      {fileValue !== null ? "Ubah Gambar" : "Pilih Gambar"}
                    </span>
                  )}
                </div>
                {errorImage && (
                  <small id="error" className="p-error p-d-block p-text-right">
                    {errorImage}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label className="label-text" htmlFor="nama">
              Nama Lengkap
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputText
                disabled={!isEdit}
                placeholder=""
                value={nameState.value}
                onChange={(e) =>
                  setNameState({ value: e.target.value, error: null })
                }
                id="nama"
              />
              {nameState.error && (
                <small id="ses" className="p-error p-d-block">
                  {nameState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label className="label-text" htmlFor="jabatan">
              Jabatan
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputText
                disabled={!isEdit}
                placeholder=""
                value={positionState.value}
                onChange={(e) =>
                  setPositionState({ value: e.target.value, error: null })
                }
                id="jabatan"
              />
              {positionState.error && (
                <small id="jabatan" className="p-error p-d-block">
                  {positionState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label className="label-text">Nomor Telepon</label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputText
                disabled
                placeholder=""
                defaultValue={users?.user?.phone}
              />
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label className="label-text">Email</label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputText
                disabled
                placeholder=""
                defaultValue={users?.user?.email}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="info-group d-block w-100">
        <div className="row">
          <div className="col-12">
            <div className="p-d-flex p-jc-between p-ai-center">
              <h3>Profil Perusahaan</h3>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label className="label-text" htmlFor="namaPerusahaan">
              Nama Perusahaan
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputText
                disabled={!isEdit}
                placeholder=""
                value={companyNameState?.value}
                onChange={(e) =>
                  setCompanyNameState({ value: e.target.value, error: null })
                }
                id="namaPerusahaan"
              />
              {companyNameState.error && (
                <small id="namaPerusahaan" className="p-error p-d-block">
                  {companyNameState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label className="label-text" htmlFor="teleponPerusahaan">
              Nomor Telepon Perusahaan
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field short">
              <InputText
                disabled={!isEdit}
                placeholder=""
                type="number"
                value={companyPhoneState.value}
                onKeyPress={(e) => handleKeyInputPhone(e)}
                onChange={(e) => handleInputPhone(e)}
                id="teleponPerusahaan"
              />
              {companyPhoneState.error && (
                <small id="teleponPerusahaan" className="p-error p-d-block">
                  {companyPhoneState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label htmlFor="emailPerusahaan" className="label-text">
              Email Perusahaan
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field short">
              <InputText
                disabled={!isEdit}
                placeholder=""
                type="email"
                value={companyEmailState.value}
                onKeyPress={(e) =>
                  setCompanyEmailState({ value: e.target.value, error: null })
                }
                onChange={(e) =>
                  setCompanyEmailState({ value: e.target.value, error: null })
                }
                id="emailPerusahaan"
              />
              {companyPhoneState.error && (
                <small id="emailPerusahaan" className="p-error p-d-block">
                  {companyPhoneState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label htmlFor="background" className="label-text">
              Background Perusahaan
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputTextarea
                disabled={!isEdit}
                value={companyBackgroundState.value}
                onChange={(e) =>
                  setCompanyBackgroundState({
                    value: e.target.value,
                    error: null,
                  })
                }
                placeholder=""
                id="background"
              />
              {companyBackgroundState.error && (
                <small id="background" className="p-error p-d-block">
                  {companyBackgroundState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label htmlFor="bidangUsaha" className="label-text">
              Bidang Usaha
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field short ">
              <Dropdown
                value={businessFieldState.value}
                onChange={(e) =>
                  setBusinessFieldState({ value: e.target.value, error: null })
                }
                options={business}
                optionLabel="name"
                optionValue="code"
                disabled={!isEdit}
                id="bidangUsaha"
              />

              {businessFieldState.error && (
                <small id="bidangUsaha" className="p-error p-d-block">
                  {businessFieldState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label htmlFor="service" className="label-text">
              Jenis Layanan Perusahaan
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputTextarea
                disabled={!isEdit}
                value={companyServiceState.value}
                onChange={(e) =>
                  setCompanyServiceState({
                    value: e.target.value,
                    error: null,
                  })
                }
                placeholder=""
                id="service"
              />
              {companyServiceState.error && (
                <small id="service" className="p-error p-d-block">
                  {companyServiceState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <label htmlFor="alamat" className="label-text">
              Alamat Perusahaan
            </label>
          </div>
          <div className="col-12 col-sm-8">
            <div className="p-field">
              <InputTextarea
                disabled={!isEdit}
                placeholder=""
                value={companyAddressState.value}
                onChange={(e) =>
                  setCompanyAddressState({ value: e.target.value, error: null })
                }
                id="alamat"
              />
              {companyAddressState.error && (
                <small id="alamat" className="p-error p-d-block">
                  {companyAddressState.error}
                </small>
              )}
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Upload Dokumen</p>
          </div>
          <div className="col-12 col-sm-8">
            <UploadPdf
              value={pdfValue}
              filePath="user-data"
              loading={loadingUploadCompanyProfile}
            />
          </div>
        </div>
      </div>

      {isEdit && (
        <div className="footer-profile">
          <ButtonDefault
            className="p-button p-button-primary btn-wide"
            label="SIMPAN"
            loading={loadingUpdateUserFirebase || loadingUpdateCompany}
            onClick={
              users?.user?.type === "client"
                ? () => submitProfile()
                : () => submitProfilePic()
            }
          />
        </div>
      )}
      <DialogAlert
        type={"success"}
        title={"Berhasil Memperbarui Data"}
        text={"Data Profil Anda telah diperbarui"}
        closeDialog={closeSuccess}
        visible={showDialogSuccess}
      />
      <DialogAlert
        type={"error"}
        title={"Gagal Memperbarui Data"}
        text={dialogText}
        closeDialog={closeDanger}
        visible={showDialogDanger}
      />
    </>
  );
}

export default ProfileForm;
