/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import Questions from "./RichTextQuestion";

import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import ButtonDefault from "../../../../components/button/ButtonDefault";

import {
  dataAddReleaseAction,
  dataUpdateReleaseAction,
  uploadBriefAction,
} from "../../../../../redux/action/utilsAction";

import dummyQuestion from "./question.json";

const AddRelease = (props) => {
  const dispatch = useDispatch();
  const [keywordState, setKeywordState] = useState({ value: "", error: null });
  const [objectState, setObjectState] = useState({ value: "", error: null });
  const [attributeState, setAttributeState] = useState({
    value: "",
    error: null,
  });
  const [noteState, setNoteState] = useState({ value: "", error: null });
  const [categoryState, setCategoryState] = useState({
    value: null,
    error: null,
  });
  const [genderState, setGenderState] = useState({
    value: null,
    error: null,
  });
  const [ageState, setAgeState] = useState({
    value: null,
    error: null,
  });
  const [stakeholderState, setStakeholderState] = useState({
    value: [],
    error: null,
  });

  const [questionState, setQuestionState] = useState({
    value: "<p></p>",
    error: null,
  });

  const [isDisabled, setIsDisabled] = useState(true);
  const [fileName, setFileName] = useState("");
  const [errorImage, setErrorImage] = useState("");
  const [fileValue, setFileValue] = useState(null);

  const {
    dataAge,
    dataAddProject,
    dataGender,
    dataCategoryRelease,
    dataStakeholder,
    successUploadBrief,
    loadingUploadBrief,
    dataUploadBrief,
    dataUpdateProject,
  } = useSelector((state) => state.utils);

  const { users } = useSelector((state) => state.auth);
  const ageDropdown = dataAge?.map((age) => ({ name: age, code: age }));
  const genderDropdown = dataGender?.map((gender) => ({
    name: gender,
    code: gender,
  }));
  const categoryDropdown = dataCategoryRelease?.map((category) => ({
    name: category,
    code: category,
  }));
  const [showInfo, setShowInfo] = useState(false);
  const [showAdditionalQuestion, setShowAdditionalQuestion] = useState(false);
  const [hoveredQuestion, setHoveredQuestion] = useState("");
  const [hoveredPosition, setHoveredPosition] = useState("top");
  const [hoveredPositionAdditional, setHoveredPositionAdditional] = useState(
    "top"
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".pdf",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        setErrorImage("Ukuran file lebih dari 5MB");
      } else if (acceptedFiles.length > 1) {
        setErrorImage("Silakan pilih satu file");
      } else {
        setErrorImage("");
        setFileName(acceptedFiles[0].name);

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/project-asset/${times}/${users?.uid}/${fileName}`;

        dispatch(uploadBriefAction(acceptedFiles[0], path));
      }
    },
  });

  useEffect(() => {
    if (dataAddProject?.detail?.type === "pr") {
      if (
        keywordState?.value?.length > 0 &&
        objectState?.value?.length > 0 &&
        stakeholderState?.value?.length > 0 &&
        genderState?.value !== null &&
        ageState?.value !== null &&
        categoryState?.value !== null &&
        (questionState?.value !== "<p><br></p>" ||
          questionState?.value !== "<p></p>")
      ) {
        setIsDisabled(false);
      }
    } else {
      if (
        keywordState?.value?.length > 0 &&
        objectState?.value?.length > 0 &&
        stakeholderState?.value?.length > 0 &&
        genderState?.value !== null &&
        ageState?.value !== null
      ) {
        setIsDisabled(false);
      }
    }
  }, [
    ageState.value,
    categoryState.value,
    genderState.value,
    keywordState.value,
    objectState.value,
    stakeholderState.value,
    dataAddProject.detail.type,
    questionState.value,
  ]);

  const handleScroll = () => {
    const categoryReleaseElement = document
      .getElementById("release-category")
      ?.getBoundingClientRect()?.top;
    const deliveredAttributionEl = document
      .getElementById("delivered-attribution")
      ?.getBoundingClientRect()?.top;
    const limitTopCategoryRelease = 280;
    const limitBottomCategoryRelease = 295;
    const limitTopAttribution = 340;
    const limitBottomAttribution = 450;
    if (categoryReleaseElement < limitTopCategoryRelease) {
      setHoveredPosition("bottom");
    }
    if (categoryReleaseElement > limitBottomCategoryRelease) {
      setHoveredPosition("top");
    }
    if (deliveredAttributionEl < limitTopAttribution) {
      setHoveredPositionAdditional("bottom");
    }
    if (deliveredAttributionEl > limitBottomAttribution) {
      setHoveredPositionAdditional("top");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successUploadBrief) {
      setFileValue(dataUploadBrief);
    }
  }, [dataUploadBrief, successUploadBrief]);

  useEffect(() => {
    if (props.type === "add" && dataAddProject?.release !== null) {
      setKeywordState({
        value: dataAddProject?.release?.searchKeyword,
        error: null,
      });
      setCategoryState({
        value: dataAddProject?.release?.releaseCategory,
        error: null,
      });
      setGenderState({
        value: dataAddProject?.release?.target?.sex,
        error: null,
      });
      setAgeState({
        value: dataAddProject?.release?.target?.age,
        error: null,
      });
      setStakeholderState({
        value: dataAddProject?.release?.target?.stakeholders,
        error: null,
      });
      setObjectState({
        value: dataAddProject?.release?.objective,
        error: null,
      });
      setAttributeState({
        value: dataAddProject?.release?.attribution,
        error: null,
      });
      setNoteState({
        value: dataAddProject?.release?.releaseNote,
        error: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAddProject.release]);

  useEffect(() => {
    if (props.type === "edit" && dataUpdateProject?.release !== null) {
      setKeywordState({
        value: dataUpdateProject?.release?.searchKeyword,
        error: null,
      });
      setCategoryState({
        value: dataUpdateProject?.release?.releaseCategory,
        error: null,
      });
      setGenderState({
        value: dataUpdateProject?.release?.target?.sex,
        error: null,
      });
      setAgeState({
        value: dataUpdateProject?.release?.target?.age,
        error: null,
      });
      setStakeholderState({
        value: dataUpdateProject?.release?.target?.stakeholders,
        error: null,
      });
      setObjectState({
        value: dataUpdateProject?.release?.objective,
        error: null,
      });
      setAttributeState({
        value: dataUpdateProject?.release?.attribution,
        error: null,
      });
      setNoteState({
        value: dataUpdateProject?.release?.releaseNote,
        error: null,
      });
      setQuestionState({
        value: dataUpdateProject?.release?.releaseCategoryQuestion,
        error: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdateProject.release]);

  const onCheck = (e) => {
    let selectedData = [...stakeholderState.value];

    if (e.checked) selectedData.push(e.value);
    else selectedData.splice(selectedData.indexOf(e.value), 1);

    setStakeholderState({ value: selectedData, error: null });
  };

  const submitDetail = (e) => {
    e.preventDefault();
    const dataRelease = {
      searchKeyword: keywordState.value,
      releaseCategory: categoryState.value,
      releaseCategoryQuestion: questionState.value,
      objective: objectState.value,
      attribution: attributeState.value,
      releaseNote: noteState.value,
      target: {
        sex: genderState.value,
        age: ageState.value,
        stakeholders: stakeholderState.value,
      },
      briefUrl: fileValue,
    };
    if (keywordState?.value?.length < 1) {
      setKeywordState({ ...keywordState, error: "Keyword harus diisi" });
    }
    if (objectState?.value?.length < 1) {
      setObjectState({
        ...objectState,
        error: "Objektif Komunikasi harus diisi",
      });
    }
    if (stakeholderState?.value?.length < 1) {
      setStakeholderState({
        ...keywordState,
        error: "Stakeholder harus diisi",
      });
    }
    if (genderState?.value === null) {
      setGenderState({ ...genderState, error: "Gender harus diisi" });
    }
    if (ageState?.value == null) {
      setAgeState({ ...ageState, error: "Usia harus diisi" });
    }

    if (
      dataAddProject?.detail?.type === "pr" &&
      categoryState?.value === null
    ) {
      setCategoryState({ ...categoryState, error: "Kategori harus diisi" });
    }
    if (questionState?.value === null) {
      setQuestionState({
        ...questionState,
        error: "Informasi Tambahan harus diisi",
      });
    }

    if (dataAddProject?.detail?.type === "pr") {
      if (
        keywordState?.value?.length > 0 &&
        objectState?.value?.length > 0 &&
        stakeholderState?.value?.length > 0 &&
        genderState?.value !== null &&
        ageState?.value !== null &&
        categoryState?.value !== null &&
        questionState?.value !== null
      ) {
        if (props?.type === "add") {
          dispatch(dataAddReleaseAction(dataRelease));
        } else {
          dispatch(dataUpdateReleaseAction(dataRelease));
        }

        props.submitData();
      }
    } else if (dataAddProject?.detail?.type !== "pr") {
      if (
        keywordState?.value?.length > 0 &&
        objectState?.value?.length > 0 &&
        stakeholderState?.value?.length > 0 &&
        genderState?.value !== null &&
        ageState?.value !== null
      ) {
        if (props?.type === "add") {
          dispatch(dataAddReleaseAction(dataRelease));
        } else {
          dispatch(dataUpdateReleaseAction(dataRelease));
        }
        props.submitData();
      }
    }
  };

  const changeQuestion = (data) => {
    setQuestionState({ value: data, error: null });
  };

  const changeCategory = (data) => {
    setCategoryState({ value: data, error: null });
    const question = dummyQuestion?.filter((item) => item.name === data);
    setHoveredQuestion(question[0]?.question);
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-body pt-8 pb-4">
          <div className="form-add-project">
            <div className="p-field">
              <label className="p-d-block">
                Kata Kunci Pencarian <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                placeholder="Masukkan Kata Kunci Pencarian"
                onChange={(e) =>
                  setKeywordState({ value: e.target.value, error: null })
                }
                value={keywordState.value}
              />
              {keywordState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {keywordState.error}
                </small>
              )}
            </div>
            {dataAddProject?.detail?.type === "pr" && (
              <div className="p-field">
                <label className="p-d-block" id="release-category">
                  Kategori Rilis <span className="text-danger">*</span>
                </label>
                <Dropdown
                  value={categoryState.value}
                  options={categoryDropdown}
                  onChange={(e) => changeCategory(e.target.value)}
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Pilih Kategori Project"
                />
                {categoryState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {categoryState.error}
                  </small>
                )}
              </div>
            )}
            {dataAddProject?.detail?.type === "pr" && categoryState.value && (
              <div className="p-field">
                <label htmlFor="category" className="p-d-block">
                  Informasi Tambahan Kategori Rilis {categoryState.value}{" "}
                  <span className="text-danger">*</span>
                  <span
                    onMouseOver={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                    className="icon-info ml-2"
                    id="hovered-icon"
                  ></span>
                </label>
                <div className={`info-question-wrapper ${hoveredPosition}`}>
                  {showInfo && (
                    <div
                      className="info-question"
                      dangerouslySetInnerHTML={{ __html: hoveredQuestion }}
                    ></div>
                  )}
                </div>
                <Questions
                  contentValue={questionState.value}
                  changeContent={changeQuestion}
                />
                {questionState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {questionState.error}
                  </small>
                )}
              </div>
            )}
            <div className="p-field">
              <label className="p-d-block">
                Objektif Komunikasi Yang Ingin Dicapai{" "}
                <span className="text-danger">*</span>
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setObjectState({ value: e.target.value, error: null })
                }
                value={objectState.value}
              />
              {objectState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {objectState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label htmlFor="target" className="p-d-block">
                Target Pembaca
              </label>
              <div className="innef-field p-d-flex">
                <span>
                  Jenis Kelamin <span className="text-danger">*</span>
                </span>
                <div>
                  <Dropdown
                    value={genderState.value}
                    options={genderDropdown}
                    onChange={(e) =>
                      setGenderState({ value: e.target.value, error: null })
                    }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {genderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {genderState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field p-d-flex">
                <span>
                  Usia <span className="text-danger">*</span>
                </span>
                <div>
                  <Dropdown
                    value={ageState.value}
                    options={ageDropdown}
                    onChange={(e) =>
                      setAgeState({ value: e.target.value, error: null })
                    }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {ageState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {ageState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field p-d-flex">
                <span>
                  Stakeholder <span className="text-danger">*</span>
                </span>
                <div>
                  {dataStakeholder?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        inputId={item}
                        onChange={onCheck}
                        checked={stakeholderState.value.indexOf(item) !== -1}
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {stakeholderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {stakeholderState.error}
                    </small>
                  )}
                </div>
              </div>
            </div>

            <div className="p-field">
              <label className="p-d-block" id="delivered-attribution">
                Atribusi Quote Dan Pesan Yang Ingin Disampaikan
                {dataAddProject?.detail?.type === "pr" && (
                  <span
                    onMouseOver={() => setShowAdditionalQuestion(true)}
                    onMouseLeave={() => setShowAdditionalQuestion(false)}
                    className="icon-info ml-2"
                    id="hovered-icon"
                  />
                )}
              </label>
              <div
                className={`info-question-wrapper ${hoveredPositionAdditional}`}
              >
                {showAdditionalQuestion && (
                  <div
                    className="info-question"
                    dangerouslySetInnerHTML={{
                      __html: dummyQuestion[5].question,
                    }}
                  ></div>
                )}
              </div>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setAttributeState({ value: e.target.value, error: null })
                }
                value={attributeState.value}
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Poin penting lainnya yang ingin disampaikan
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setNoteState({ value: e.target.value, error: null })
                }
                value={noteState.value}
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">Unggah File Tambahan</label>
              <div {...getRootProps({ className: "file-upload" })}>
                <input {...getInputProps()} />
                <span className="file-name">
                  {fileValue !== null ? fileName : "Nama File"}
                </span>
                {loadingUploadBrief ? (
                  <span className="file-button">Mengupload...</span>
                ) : (
                  <span className="file-button" onClick={open}>
                    {fileValue !== null ? "Ubah File" : "Pilih File"}
                  </span>
                )}
              </div>
              {errorImage && (
                <small id="address" className="p-error p-d-block p-text-right">
                  {errorImage}
                </small>
              )}
            </div>
          </div>
          <div className="footer-form">
            <div className="form-add-project p-d-flex p-jc-between p-ai-center">
              <ButtonDefault
                label="Kembali"
                type="bordered"
                onClick={props.goBack}
                className="p-button p-button-secondary"
              />
              <ButtonDefault
                onClick={submitDetail}
                label="SELESAI"
                disabled={isDisabled}
                className="p-button p-button-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRelease;
