import React from "react";

const StatusProject = (props) => {
  const { status, userType, className } = props;

  let statusName;
  let statusClass;

  if (status === "winner") {
    statusName = "Diterima";
    statusClass = status;
  } else if (
    status === "published" &&
    (userType === "pm" || userType === "client")
  ) {
    statusName = "Diterbitkan";
    statusClass = status;
  } else if (status === "published" && userType === "writer") {
    statusName = "Diterima";
    statusClass = "winner";
  } else if (status === "ongoing") {
    statusName = "Berjalan";
    statusClass = "sent";
  } else if (status === "reject" || status === "rejected") {
    statusName = "Ditolak";
    statusClass = status;
  } else if (status === "approved") {
    statusName = "Diterima";
    statusClass = "Approved";
  } else if (status === "revision") {
    statusName = "Revisi";
    statusClass = status;
  } else if (status === "sent") {
    statusName = "Terkirim";
    statusClass = status;
  } else if (status === "removed") {
    statusName = "Nonaktif";
    statusClass = status;
  } else if (status === "public") {
    statusName = "Publik";
    statusClass = "sent";
  } else if (status === "private") {
    statusName = "Private";
    statusClass = "sent";
  } else if (status === "done") {
    statusName = "Selesai";
    statusClass = "winner";
  } else if (status === "payment_waiting") {
    statusName = "Belum Dibayar";
    statusClass = "waiting";
  } else if (status === "confirmation_waiting") {
    statusName = "Menunggu Konfirmasi";
    statusClass = "sent";
  } else if (status === "confirmed") {
    statusName = "Terkonfirmasi";
    statusClass = "winner";
  } else if (status === "new") {
    statusName = "Baru";
    statusClass = "ongoing";
  } else if (status === "pm_approved" && userType === "writer") {
    statusName = "Terkirim";
    statusClass = "sent";
  } else if (status === "pm_approved" && userType !== "writer") {
    statusName = "Review Klien";
    statusClass = "sent";
  } else if (status === "publish") {
    statusName = "Publish";
    statusClass = "winner";
  } else if (status === "draft") {
    statusName = "Draf";
    statusClass = "waiting";
  } else {
    statusName = status;
    statusClass = status;
  }

  return (
    <div className={`status ${statusClass} ${className}`}>{statusName}</div>
  );
};

export default StatusProject;
