/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";
import TextareaAutosize from "react-textarea-autosize";

import { uploadImageAction } from "../../../../../redux/action/utilsAction";
import imageLogo from "../../../../assets/img/Upload_Image.svg";

const TitleSection = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { users } = useSelector((state) => state.auth);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 1000000) {
        setError("Ukuran gambar lebih dari 1MB");
      } else if (acceptedFiles.length > 1) {
        setError("Silakan pilih satu gambar");
      } else {
        setError("");

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/project-asset/${times}/${users.uid}/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
      }
    },
  });

  return (
    <>
      <div className="form-title p-d-flex p-ai-center">
        <div className="upload-image-cover" {...getRootProps()}>
          <input {...getInputProps()} />
          <span>
            <img className="cover-icon" src={imageLogo} onClick={open} />
          </span>
        </div>

        <TextareaAutosize
          className="textarea-title"
          value={props.titleValue}
          onChange={props.changeTitle}
          placeholder="Judul Artikel"
        />
      </div>

      {props?.imageValue?.length > 0 && (
        <div className="textarea-image">
          <div className="image-box">
            <a
              href="javascript:void(0)"
              className="close-image"
              onClick={props.deleteImage}
            >
              <span className="pi pi-times" />
            </a>
            <img src={props?.imageValue} />
          </div>
        </div>
      )}
      {error?.length > 1 && (
        <div className="error-image-cover">
          {error}{" "}
          <span className="close-alert" onClick={() => setError("")}>
            x
          </span>
        </div>
      )}
    </>
  );
};
export default TitleSection;
