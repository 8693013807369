import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import _ from "lodash";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import TextBody from "../../../../components/datatableBody/TextBody";
import TableLoader from "../../../../components/loading/tableLoader";
import DataEmpty from "../../../../components/emptyState/dataEmpty";
import AdminPaginate from "../../../../components/paging/pagination";
import ButtonLoader from "../../../../components/button/ButtonLoader";

import { formEmail } from "../../../../helpers/email";

import {
  assignProjectAction,
  updateUserIvitableParams,
  userInvitableAction,
} from "../../../../../redux/action/projectAction";
import { inviteUserAction } from "../../../../../redux/action/userAction";
import { Checkbox } from "primereact/checkbox";

const DialogUsers = (props) => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const [selectedUser, setSelectedUser] = useState([]);
  const [emailState, setEmailState] = useState({ value: "", error: null });

  const {
    dataGetUserInvitable,
    loadingGetUserInvitable,
    loadingAssignProject,
    successAssignProject,
    userInvitableParams,
    totalPage,
  } = useSelector((state) => state.project);

  const { loadingInviteUser, successInviteUser, errInviteUser } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (successAssignProject) {
      setSelectedUser([]);
    }
  }, [successAssignProject]);

  useEffect(() => {
    if (successInviteUser) {
      setEmailState({ value: "", error: null });
    }
  }, [successInviteUser]);

  useEffect(() => {
    if (errInviteUser) {
      setEmailState({ value: "", error: null });
    }
  }, [errInviteUser]);

  const renderFooter = (name) => {
    return name === "invite" ? (
      <>
        <Button
          label="Batal"
          onClick={props.closeDialog}
          className="p-button-rounded p-button-outlined p-button-secondary"
        />
        {loadingInviteUser ? (
          <ButtonLoader />
        ) : (
          <Button
            label="Undang"
            onClick={submitInvite}
            autoFocus
            className="p-button-rounded "
          />
        )}
      </>
    ) : (
      <>
        <Button
          label="BATAL"
          onClick={props.closeDialog}
          className="p-button-rounded p-button-outlined p-button-secondary"
        />
        <>
          {loadingAssignProject ? (
            <ButtonLoader />
          ) : (
            <Button
              label="UNDANG"
              onClick={submitAssign}
              autoFocus
              className="p-button-rounded p-button-primary"
            />
          )}
        </>
      </>
    );
  };

  const textBodyTemplate = (item) => {
    return (
      <Link to={`/admin/pm/detail-user/${item?._id}`}>
        <TextBody text={item?.name || item?.email} />
      </Link>
    );
  };

  const actionBodyTemplate = (item) => {
    let changeSelect;
    const filter = selectedUser.find((user) => user === item._id);
    if (filter) {
      changeSelect = () => handleSelectedUser({ type: "batal", id: item._id });
    } else {
      changeSelect = () => handleSelectedUser({ type: "pilih", id: item._id });
    }

    return (
      <div className="checkbox-invite">
        <Checkbox onChange={changeSelect} checked={filter} />
      </div>
    );
  };

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = { ...userInvitableParams, q: search };
      dispatch(updateUserIvitableParams(params));
      dispatch(userInvitableAction(QueryParams.projectId, params));
    }, 1000)
  ).current;

  const paginationHandler = (page) => {
    const params = {
      ...userInvitableParams,
      page: page + 1,
      perpage: 10,
    };
    dispatch(userInvitableAction(QueryParams.projectId, params));
  };

  const handleSelectedUser = (data) => {
    if (data.type === "pilih") {
      const value = [...selectedUser, data?.id];
      setSelectedUser(value);
    } else {
      const value = selectedUser.filter((item) => item !== data?.id);
      setSelectedUser(value);
    }
  };

  const submitAssign = (e) => {
    e.preventDefault();
    const data = { uids: selectedUser };
    dispatch(assignProjectAction(QueryParams.projectId, data));
  };

  const submitInvite = (e) => {
    e.preventDefault();
    const data = {
      email: emailState.value,
      name: emailState?.value?.substring(
        0,
        emailState?.value?.lastIndexOf("@")
      ),
    };

    if (!formEmail(emailState.value)) {
      setEmailState({ ...emailState, error: "Email Tidak Valid" });
    } else {
      dispatch(inviteUserAction(data));
    }
  };

  return (
    <div className="dialog-invite">
      <div className="card">
        <Dialog
          header="Undang Penulis"
          visible={props.dialogUser !== null}
          style={{ width: "600px" }}
          footer={renderFooter(props.dialogUser)}
          onHide={props.closeDialog}
          className="list-user-dialog"
        >
          {props?.dialogUser === "invite" ? (
            <div className="p-text-center invite-wrapper">
              <p className="p-px-5">
                Masukkan email penulis yang ingin diundang untuk bergabung di
                dalam project
              </p>
              <div className="p-field p-text-left p-pt-5">
                <label htmlFor="email-invite" className="p-d-block">
                  Email
                </label>
                <InputText
                  id="email-invite"
                  value={emailState.value}
                  aria-describedby="email-invite-help"
                  className={`${emailState.error !== null &&
                    "p-invalid"} p-d-block`}
                  onChange={(e) =>
                    setEmailState({ value: e.target.value, error: null })
                  }
                />
              </div>
              {emailState.error !== null && (
                <small
                  id="phone-number"
                  className="p-error p-d-block p-text-right"
                >
                  {emailState.error}
                </small>
              )}
            </div>
          ) : (
            <div className="p-text-left invite-wrapper">
              <div className="p-d-flex p-jc-between p-pt-5">
                <div className="filter-wrapp">
                  <InputText
                    id="search"
                    aria-describedby="search-help"
                    className="p-d-block"
                    placeholder="Cari nama atau email"
                    onChange={(e) => searchingHandler(e.target.value)}
                  />
                </div>
                <Button
                  label="UNDANG PENULIS BARU"
                  onClick={props.openDialogInvite}
                  className="p-button-primary p-button-rounded"
                />
              </div>
              <div className="p-field p-text-left p-pt-5">
                {loadingGetUserInvitable ? (
                  <TableLoader />
                ) : (
                  <>
                    {dataGetUserInvitable === null ||
                    dataGetUserInvitable?.length < 1 ? (
                      <DataEmpty />
                    ) : (
                      <>
                        <DataTable
                          value={dataGetUserInvitable}
                          className="no-padding"
                        >
                          <Column
                            headerClassName="action"
                            body={actionBodyTemplate}
                            style={{ width: "47px" }}
                          ></Column>
                          <Column
                            header="Nama"
                            body={textBodyTemplate}
                          ></Column>
                          <Column header="Email" field="email"></Column>
                        </DataTable>
                      </>
                    )}
                  </>
                )}
                {totalPage > 1 && (
                  <AdminPaginate
                    active={userInvitableParams.page}
                    total={totalPage}
                    clicked={paginationHandler}
                    perpage={10}
                  />
                )}
              </div>
            </div>
          )}
        </Dialog>
      </div>
    </div>
  );
};

export default DialogUsers;
