import {
  GET_PIC_DETAIL_FAILURE,
  GET_PIC_DETAIL_SUCCESS,
  GET_PIC_DETAIL_REQUEST,
  GET_PIC_LIST_FAILURE,
  GET_PIC_LIST_SUCCESS,
  GET_PIC_LIST_REQUEST,
  UPDATE_PIC_FAILURE,
  UPDATE_PIC_SUCCESS,
  UPDATE_PIC_REQUEST,
  DELETE_PIC_FAILURE,
  DELETE_PIC_SUCCESS,
  DELETE_PIC_REQUEST,
  ADD_PIC_REQUEST,
  ADD_PIC_SUCCESS,
  ADD_PIC_FAILURE,
  ASSIGN_PIC_REQUEST,
  ASSIGN_PIC_SUCCESS,
  ASSIGN_PIC_FAILURE,
  RESET_PIC_STATE
} from "../constant/clientPicConstant";

const initialStatePIC = {
  picParams: {
    q: "",
    page: 1,
    perpage: 10,
  },
  totalPage: "",

  loadingGetPICList: false,
  dataGetPICList: null,
  errGetPICList: null,

  loadingGetDetailPIC: false,
  dataGetDetailPIC: null,
  errGetDetailPIC: null,

  loadingAddPIC: false,
  successAddPIC: false,
  errAddPIC: null,

  loadingUpdatePIC: false,
  successUpdatePIC: false,
  errUpdatePIC: null,

  loadingDeletePIC: false,
  successDeletePIC: false,
  errDeletePIC: null,

  loadingAssignPIC: false,
  successAssignPIC: false,
  errAssignPIC: null,
};

export const picReducer = (state = initialStatePIC, action) => {
  switch (action.type) {
    case GET_PIC_LIST_REQUEST:
      return {
        ...state,
        loadingGetPICList: true,
        errGetPICList: null,
      };
    case GET_PIC_LIST_SUCCESS:
      return {
        ...state,
        loadingGetPICList: false,
        dataGetPICList: action.data.data,
        errGetPICList: null,
        totalPage: action.data.totalpage,
      };
    case GET_PIC_LIST_FAILURE:
      return {
        ...state,
        loadingGetPICList: false,
        errGetPICList: action.data,
      };

    case ADD_PIC_REQUEST:
      return {
        ...state,
        loadingAddPIC: true,
        successAddPIC: false,
        errAddPIC: null,
      };
    case ADD_PIC_SUCCESS:
      return {
        ...state,
        loadingAddPIC: false,
        successAddPIC: true,
        errAddPIC: null,
      };
    case ADD_PIC_FAILURE:
      return {
        ...state,
        loadingAddPIC: false,
        successAddPIC: false,
        errAddPIC: action.data,
      };

    case GET_PIC_DETAIL_REQUEST:
      return {
        ...state,
        loadingGetDetailPIC: true,
        errGetDetailPIC: null,
      };
    case GET_PIC_DETAIL_SUCCESS:
      return {
        ...state,
        loadingGetDetailPIC: false,
        dataGetDetailPIC: action.data,
        errGetDetailPIC: null,
      };
    case GET_PIC_DETAIL_FAILURE:
      return {
        ...state,
        loadingGetDetailPIC: false,
        errGetDetailPIC: action.data,
      };

    case UPDATE_PIC_REQUEST:
      return {
        ...state,
        loadingUpdatePIC: true,
        successUpdatePIC: false,
        errUpdatePIC: null,
      };
    case UPDATE_PIC_SUCCESS:
      return {
        ...state,
        loadingUpdatePIC: false,
        successUpdatePIC: true,
        errUpdatePIC: null,
      };
    case UPDATE_PIC_FAILURE:
      return {
        ...state,
        loadingUpdatePIC: false,
        successUpdatePIC: false,
        errUpdatePIC: action.data,
      };

    case DELETE_PIC_REQUEST:
      return {
        ...state,
        loadingDeletePIC: true,
        successDeletePIC: false,
        errDeletePIC: null,
      };
    case DELETE_PIC_SUCCESS:
      return {
        ...state,
        loadingDeletePIC: false,
        successDeletePIC: true,
        errDeletePIC: null,
      };
    case DELETE_PIC_FAILURE:
      return {
        ...state,
        loadingDeletePIC: false,
        successDeletePIC: false,
        errDeletePIC: action.data,
      };

      case ASSIGN_PIC_REQUEST:
      return {
        ...state,
        loadingAssignPIC: true,
        successAssignPIC: false,
        errAssignPIC: null,
      };
    case ASSIGN_PIC_SUCCESS:
      return {
        ...state,
        loadingAssignPIC: false,
        successAssignPIC: true,
        errAssignPIC: null,
      };
    case ASSIGN_PIC_FAILURE:
      return {
        ...state,
        loadingAssignPIC: false,
        successAssignPIC: false,
        errAssignPIC: action.data,
      };

      case RESET_PIC_STATE: 
      return {
        ...state,
        successAddPIC: false,
        errAddPIC: null,
        successDeletePIC: false,
        errDeletePIC: null,
        successUpdatePIC: false,
        errUpdatePIC: null,
        successAssignPIC: false,
        errAssignPIC: null
      }

    default:
      return state;
  }
};
