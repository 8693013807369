/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPICAction } from "../../../../redux/action/clientPicAction";

import DialogAlert from "../../../components/dialog/DialogAlert";
import PICDetailForm from "./Components/PICDetailForm";

const PICAddModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { successAddPIC, errAddPIC } = useSelector((state) => state.clientPic);

  useEffect(() => {
    if (successAddPIC) {
      setTimeout(() => {
        setDialogSuccess(true);
      }, 1500);
    }
  }, [successAddPIC]);

  useEffect(() => {
    if (errAddPIC) {
      setDialogError(true);
      if (
        errAddPIC?.message ===
        "The email address is already in use by another account."
      ) {
        setErrorMessage("Email sudah digunakan akun lain");
      } else if (
        errAddPIC?.message ===
        "The user with the provided phone number already exists."
      ) {
        setErrorMessage("No. Handphone sudah digunakan akun lain");
      } else if (
        errAddPIC?.message === "Validation error" &&
        errAddPIC?.meta?.errors?.phone
      ) {
        setErrorMessage("No. Handphone tidak valid");
      } else {
        setErrorMessage(errAddPIC?.message);
      }
    }
  }, [errAddPIC]);

  const closeSuccess = () => {
    dispatch(resetPICAction());
    setDialogSuccess(false);
    history.push("/client/pic-list");
  };

  const closeError = () => {
    dispatch(resetPICAction());
    setDialogError(false);
  };

  return (
    <>
      <div className="p-d-flex p-jc-center detail-pm">
        <div className="card card-custom col-lg-6 col-md-6 col-sm-12">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Tambah Tim
                </span>
              </button>
            </h3>
          </div>
          <PICDetailForm type="add" />
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Menambah Tim"}
        text={""}
      />
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={() => closeError()}
        title={"Gagal Menambah Tim"}
        text={errorMessage}
      />
    </>
  );
};

export default PICAddModule;
