import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import SuccessPaymentModule from "../../modules/Client/Payment/SuccessPaymentModule";
import UploadPaymentModule from "../../modules/Client/Payment/UploadPaymentModule";
import { orderClientDetailAction } from "../../../redux/action/orderAction";
import { resetUtilsStateAction } from "../../../redux/action/utilsAction";

function PaymentUpload() {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const [successState, setSuccessState] = useState(false);

  const { dataDetailOrderClient, successUpdateOrder } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - Konfirmasi Pembayaran",
    });

    dispatch(orderClientDetailAction(queryParams.orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successUpdateOrder || dataDetailOrderClient?.paymentConfirmation) {
      setSuccessState(true);
      dispatch(resetUtilsStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateOrder, dataDetailOrderClient?.paymentConfirmation]);
  return (
    <DashboardClientLayout
      title="Konfirmasi Pembayaran"
      mobileTitle="Upload Bukti Pembayaran"
      menu="invoice"
    >
      {successState ? (
        <SuccessPaymentModule />
      ) : (
        <UploadPaymentModule orderId={queryParams.orderId} />
      )}
    </DashboardClientLayout>
  );
}

export default PaymentUpload;
