import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import {
  getClientAction,
  resetClientStateAction,
} from "../../../../redux/action/clientAction";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import AssignPMModule from "../../../modules/Admin/ProjectPM/AssignPMModule";

const AssignPMPage = () => {
  const dispatch = useDispatch();

  const { clientParams } = useSelector((state) => state.client);

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Project",
    });
    dispatch(getClientAction(clientParams));
    dispatch(resetUtilsStateAction());
    dispatch(resetClientStateAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Project" menu="project">
      <AssignPMModule />
    </DashboardLayout>
  );
};

export default AssignPMPage;
