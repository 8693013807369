import React from "react";
import Image from "../../assets/img/empty-message.svg";

const ChatEmpty = (props) => {
  return (
    <div className="data-empty-wrap">
      <img src={Image} alt="data-empty" />
      <p>Silahkan Pilih Chat</p>
    </div>
  );
};

export default ChatEmpty;
