import { call, put } from "redux-saga/effects";
import {
  LOGIN_WITH_EMAIL_FAILURE,
  LOGIN_WITH_EMAIL_SUCCESS,
  VERIFY_OTP_FAILURE,
  VERIFY_OTP_SUCCESS,
  PHONE_REGISTER_FAILURE,
  PHONE_REGISTER_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  LOGIN_WITH_LINK_FAILURE,
  LOGIN_WITH_LINK_SUCCESS,
  PHONE_LOGIN_FAILURE,
  PHONE_LOGIN_SUCCESS,
  LOGIN_VERIFY_OTP_FAILURE,
  LOGIN_VERIFY_OTP_SUCCESS,
  CHECK_PHONE_FAILURE,
  CHECK_PHONE_SUCCESS,
  CHECK_EMAIL_FAILURE,
  CHECK_EMAIL_SUCCESS,
  EMAIL_REGISTER_FAILURE,
  EMAIL_REGISTER_SUCCESS,
  APPLICANT_REGISTER_FAILURE,
  APPLICANT_REGISTER_SUCCESS,
  GOOGLE_LOGIN_FAILURE,
  GOOGLE_LOGIN_SUCCESS,
  MY_PROFILE_FAILURE,
  MY_PROFILE_SUCCESS,
  EDIT_WRITER_PROFILE_FAILURE,
  EDIT_WRITER_PROFILE_SUCCESS,
  UPDATE_PASSWORD_FAILURE,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_EMAIL_SUCCESS,
  UPDATE_EMAIL_FAILURE,
  CHECK_UPDATE_PHONE_FAILURE,
  CHECK_UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE_SUCCESS,
  UPDATE_PHONE_FAILURE,
  CLIENT_REGISTER_FAILURE,
  CLIENT_REGISTER_SUCCESS,
  VERIFY_CLIENT_SUCCESS,
  VERIFY_CLIENT_FAILURE,
  SEND_UPDATE_EMAIL_CLIENT_SUCCESS,
  SEND_UPDATE_EMAIL_CLIENT_FAILURE,
  SEND_UPDATE_EMAIL_WRITER_FAILURE,
  SEND_UPDATE_EMAIL_WRITER_SUCCESS,
  SEND_UPDATE_EMAIL_PM_FAILURE,
  SEND_UPDATE_EMAIL_PM_SUCCESS,
  UPDATE_EMAIL_CLIENT_SUCCESS,
  UPDATE_EMAIL_CLIENT_FAILURE,
  UPDATE_EMAIL_WRITER_FAILURE,
  UPDATE_EMAIL_WRITER_SUCCESS,
  UPDATE_EMAIL_PM_FAILURE,
  UPDATE_EMAIL_PM_SUCCESS,
  UPDATE_USER_FIREBASE_SUCCESS,
  UPDATE_USER_FIREBASE_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  CREATE_NEW_PASSWORD_SUCCESS,
  CREATE_NEW_PASSWORD_FAILURE,
} from "../constant/authConstant";

import {
  verifyPhoneNumber,
  checkCredentials,
  signInWithCredentials,
  loginWithEmail,
  getUserByUid,
  loginInvitation,
  updatePhone,
  logout,
  linkedUser,
  updateUser,
  loginGoogle,
  getToken,
  updatePassword,
  updateEmail,
  verifyPhoneNumberActive,
  updatePhoneCredential,
} from "../service/firebase/auth";

import {
  checkPhoneNumber,
  registerPassword,
  register,
  editWriterProfile,
  registerClient,
  verifyClient,
  requestUpdateEmailPm,
  updateEmailPm,
  requestUpdateEmailWriter,
  updateEmailWriter,
  checkEmail,
  resetPassword,
  requestUpdateEmailClient,
  updateEmailClient,
  createNewPassword,
} from "../service/api/userApi";
import { setStorage } from "../../_metronic/_helpers/LocalStorageHelpers";

export function* emailLoginSaga(action) {
  try {
    const result = yield call(loginWithEmail, action.email, action.password);
    const user = yield call(getUserByUid, result.user.uid);
    const data = { uid: result.user.uid, user: user.data() };
    yield put({
      type: LOGIN_WITH_EMAIL_SUCCESS,
      data,
    });
    setStorage("login-mode", "email");
  } catch (err) {
    yield put({ type: LOGIN_WITH_EMAIL_FAILURE, err });
  }
}

export function* verifyOtpSaga(action) {
  try {
    const credentials = yield call(
      checkCredentials,
      action.verificationId,
      action.otp
    );

    const result = yield call(linkedUser, credentials);
    if (result) {
      yield call(updatePhone, result.user.uid, action.data.phone_number);
      const user = yield call(getUserByUid, result.user.uid);
      const data = { uid: result.user.uid, user: user.data() };
      yield put({ type: VERIFY_OTP_SUCCESS, data: data });
    }
  } catch (err) {
    yield put({ type: VERIFY_OTP_FAILURE, err });
  }
}

export function* phoneRegisterSaga(action) {
  try {
    const result = yield call(
      verifyPhoneNumber,
      action.data.phone_number,
      action.recaptha
    );
    yield put({
      type: PHONE_REGISTER_SUCCESS,
      data: result.verificationId,
      input: action.data,
    });
  } catch (err) {
    yield put({ type: PHONE_REGISTER_FAILURE, err });
  }
}

export function* loginWithLinkSaga(action) {
  try {
    const result = yield call(loginInvitation, action.data);
    const user = yield call(getUserByUid, result.user.uid);
    const data = { uid: result.user.uid, user: user.data() };
    yield put({ type: LOGIN_WITH_LINK_SUCCESS, data });
  } catch (err) {
    yield put({ type: LOGIN_WITH_LINK_FAILURE, err });
  }
}

export function* logoutSaga() {
  try {
    yield call(logout);
    yield put({ type: LOGOUT_SUCCESS });
  } catch (err) {
    yield put({ type: LOGOUT_FAILURE, err });
  }
}

export function* phoneLoginSaga(action) {
  try {
    const result = yield call(
      verifyPhoneNumber,
      action.data.phone_number,
      action.recaptha
    );
    yield put({
      type: PHONE_LOGIN_SUCCESS,
      data: result.verificationId,
      input: action.data,
    });
  } catch (err) {
    yield put({ type: PHONE_LOGIN_FAILURE, err });
  }
}

export function* loginVerifyOtpSaga(action) {
  try {
    const credentials = yield call(
      checkCredentials,
      action.verificationId,
      action.otp
    );

    const result = yield call(signInWithCredentials, credentials);
    const user = yield call(getUserByUid, result.user.uid);
    if (result) {
      const data = { uid: result.user.uid, user: user.data() };
      yield put({ type: LOGIN_VERIFY_OTP_SUCCESS, data: data });
      setStorage("login-mode", "otp");
    }
  } catch (err) {
    yield put({ type: LOGIN_VERIFY_OTP_FAILURE, err });
  }
}

export function* checkPhoneSaga(action) {
  const { ok, data } = yield call(checkPhoneNumber, action.data);
  if (ok) {
    yield put({ type: CHECK_PHONE_SUCCESS, data });
  } else {
    yield put({ type: CHECK_PHONE_FAILURE, data });
  }
}

export function* checkEmailSaga(action) {
  const { ok, data } = yield call(checkEmail, action.data);
  if (ok) {
    yield put({ type: CHECK_EMAIL_SUCCESS, data });
  } else {
    yield put({ type: CHECK_EMAIL_FAILURE, data });
  }
}

export function* emailRegisterSaga(action) {
  const { ok, data } = yield call(registerPassword, action.data);
  const user = yield call(getUserByUid, data.data.uid);
  if (ok) {
    yield call(updateUser, data.data.uid, { isLinkLogin: true });
    const dataResult = { uid: data.data.uid, user: user.data() };
    yield put({ type: EMAIL_REGISTER_SUCCESS, data: dataResult });
  } else {
    yield put({ type: EMAIL_REGISTER_FAILURE, data });
  }
}

export function* applicantRegisterSaga(action) {
  const { ok, data } = yield call(register, action.data);
  if (ok) {
    yield put({ type: APPLICANT_REGISTER_SUCCESS, data });
  } else {
    yield put({ type: APPLICANT_REGISTER_FAILURE, data });
  }
}

export function* googleLoginSaga() {
  try {
    const result = yield call(loginGoogle);
    if (result) {
      const data = { uid: result.user.uid, user: result.user };
      yield put({ type: GOOGLE_LOGIN_SUCCESS, data });
    }
  } catch (err) {
    yield put({ type: GOOGLE_LOGIN_FAILURE, err });
  }
}

export function* myProfileSaga(action) {
  try {
    const user = yield call(getUserByUid, action.uid);
    if (user) {
      const data = { uid: action.uid, user: user.data() };
      yield put({ type: MY_PROFILE_SUCCESS, data: data });
    }
  } catch (err) {
    yield put({ type: MY_PROFILE_FAILURE, err });
  }
}

export function* editWriterProfileSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(editWriterProfile, token, action.data);
  if (ok) {
    yield put({ type: EDIT_WRITER_PROFILE_SUCCESS });
  } else {
    yield put({ type: EDIT_WRITER_PROFILE_FAILURE, data });
  }
}

export function* updatePasswordSaga(action) {
  try {
    yield call(updatePassword, action.data);
    yield put({ type: UPDATE_PASSWORD_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_PASSWORD_FAILURE, err });
  }
}

export function* updateEmailSaga(action) {
  try {
    yield call(updateEmail, action.data);
    yield put({ type: UPDATE_EMAIL_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_EMAIL_FAILURE, err });
  }
}

export function* chekPhoneNumberUpdateSaga(action) {
  try {
    const result = yield call(
      verifyPhoneNumberActive,
      action.data,
      action.recaptha
    );

    yield put({
      type: CHECK_UPDATE_PHONE_SUCCESS,
      data: result,
    });
  } catch (err) {
    yield put({ type: CHECK_UPDATE_PHONE_FAILURE, err });
  }
}

export function* updatePhoneNumberSaga(action) {
  try {
    const credentials = yield call(
      checkCredentials,
      action.verificationId,
      action.otp
    );

    yield call(updatePhoneCredential, credentials);
    yield put({ type: UPDATE_PHONE_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_PHONE_FAILURE, err });
  }
}

export function* clientRegisterSaga(action) {
  const { ok, data } = yield call(registerClient, action.data);
  if (ok) {
    yield put({ type: CLIENT_REGISTER_SUCCESS, data });
  } else {
    yield put({ type: CLIENT_REGISTER_FAILURE, data });
  }
}

export function* clientVerifySaga(action) {
  const { ok, data } = yield call(verifyClient, action.data);
  if (ok) {
    yield put({ type: VERIFY_CLIENT_SUCCESS, data });
  } else {
    yield put({ type: VERIFY_CLIENT_FAILURE, data });
  }
}

export function* sendUpdateEmailClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(requestUpdateEmailClient, token, action.data);
  if (ok) {
    yield put({ type: SEND_UPDATE_EMAIL_CLIENT_SUCCESS, data });
  } else {
    yield put({ type: SEND_UPDATE_EMAIL_CLIENT_FAILURE, data });
  }
}

export function* sendUpdateEmailPmSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(requestUpdateEmailPm, token, action.data);
  if (ok) {
    yield put({ type: SEND_UPDATE_EMAIL_PM_SUCCESS, data });
  } else {
    yield put({ type: SEND_UPDATE_EMAIL_PM_FAILURE, data });
  }
}

export function* sendUpdateEmailWriterSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(requestUpdateEmailWriter, token, action.data);
  if (ok) {
    yield put({ type: SEND_UPDATE_EMAIL_WRITER_SUCCESS, data });
  } else {
    yield put({ type: SEND_UPDATE_EMAIL_WRITER_FAILURE, data });
  }
}

export function* updateEmailClientSaga(action) {
  const { ok, data } = yield call(updateEmailClient, action.data);
  if (ok) {
    yield put({ type: UPDATE_EMAIL_CLIENT_SUCCESS, data });
  } else {
    yield put({ type: UPDATE_EMAIL_CLIENT_FAILURE, data });
  }
}

export function* updateEmailPmSaga(action) {
  const { ok, data } = yield call(updateEmailPm, action.data);
  if (ok) {
    yield put({ type: UPDATE_EMAIL_PM_SUCCESS, data });
  } else {
    yield put({ type: UPDATE_EMAIL_PM_FAILURE, data });
  }
}

export function* updateEmailWriterSaga(action) {
  const { ok, data } = yield call(updateEmailWriter, action.data);
  if (ok) {
    yield put({ type: UPDATE_EMAIL_WRITER_SUCCESS, data });
  } else {
    yield put({ type: UPDATE_EMAIL_WRITER_FAILURE, data });
  }
}

export function* updateUserFirebaseSaga(action) {
  try {
    yield call(updateUser, action.uid, action.data);
    yield put({ type: UPDATE_USER_FIREBASE_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_USER_FIREBASE_FAILURE, err });
  }
}

export function* resetPasswordSaga(action) {
  const { ok, data } = yield call(resetPassword, action.data);
  if (ok) {
    yield put({ type: RESET_PASSWORD_SUCCESS });
  } else {
    yield put({ type: RESET_PASSWORD_FAILURE, data });
  }
}

export function* createNewPasswordSaga(action) {
  const { ok, data } = yield call(createNewPassword, action.data);
  if (ok) {
    yield put({ type: CREATE_NEW_PASSWORD_SUCCESS });
  } else {
    yield put({ type: CREATE_NEW_PASSWORD_FAILURE, data });
  }
}
