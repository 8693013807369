import { call, put } from "redux-saga/effects";
import {
  AUTO_COMPLETE_COMPANY_FAILURE,
  AUTO_COMPLETE_COMPANY_SUCCESS,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_SUCCESS,
  GET_CLIENT_FAILURE,
  GET_CLIENT_SUCCESS,
  POST_CLIENT_FAILURE,
  POST_CLIENT_SUCCESS,
  GET_CLIENT_DETAIL_FAILURE,
  GET_CLIENT_DETAIL_SUCCESS,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_SUCCESS,
} from "../constant/clientConstant";
import {
  getCompanies,
  getClient,
  createClient,
  deleteClient,
  updateClient,
} from "../service/api/clientApi";
import { getDetailClient, updateCompany } from "../service/firebase/client";
import { getToken } from "../service/firebase/auth";

export function* getAutoCompleteCompanySaga(action) {
  const { ok, data } = yield call(getCompanies, action.params);
  if (ok) {
    yield put({ type: AUTO_COMPLETE_COMPANY_SUCCESS, data: data.data });
  } else {
    yield put({ type: AUTO_COMPLETE_COMPANY_FAILURE, data });
  }
}

export function* updateCompanySaga(action) {
  try {
    yield call(updateCompany, action.id, action.data);
    yield put({ type: UPDATE_COMPANY_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_COMPANY_FAILURE, err });
  }
}

export function* getClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getClient, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_CLIENT_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_CLIENT_FAILURE, data });
  }
}

export function* createClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(createClient, token, action.data);
  if (ok) {
    yield put({ type: POST_CLIENT_SUCCESS });
  } else {
    yield put({ type: POST_CLIENT_FAILURE, data });
  }
}

export function* updateClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(updateClient, token, action.id, action.data);
  if (ok) {
    yield put({ type: UPDATE_CLIENT_SUCCESS });
  } else {
    yield put({ type: UPDATE_CLIENT_FAILURE, data });
  }
}

export function* deleteClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(deleteClient, token, action.id);
  if (ok) {
    yield put({ type: DELETE_CLIENT_SUCCESS });
  } else {
    yield put({ type: DELETE_CLIENT_FAILURE, data });
  }
}

export function* getDetailClienSaga(action) {
  try {
    const snapshot = yield call(getDetailClient, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_CLIENT_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_CLIENT_DETAIL_FAILURE, err });
  }
}
