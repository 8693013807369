import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { useDispatch } from "react-redux";
import IconInfo from "../../../../assets/img/icon-info.svg";
import { Link } from "react-router-dom";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../../helpers/phoneNumber";
import { formEmail } from "../../../../helpers/email";
import { addDataAccountAction } from "../../../../../redux/action/utilsAction";

const AddAccount = (props) => {
  const initialFormData = {
    name: "",
    position: "",
    phone: "",
    email: "",
    password: "",
    confirmPassword: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formError, setFormError] = useState({});

  const validateForm = () => {
    const errors = {};

    if (!formData.name) errors.name = "Nama harus diisi";
    if (!formData.position) errors.position = "Jabatan harus diisi";
    if (!formData.phone) errors.phone = "Nomor telepon harus diisi";
    if (!formData.email) errors.email = "Email harus diisi";
    if (!formData.password) errors.password = "Kata Sandi harus diisi";
    if (!formData.confirmPassword)
      errors.confirmPassword = "Konfirmasi Kata Sandi harus diisi";
    if (formData.email && !formEmail(formData.email))
      errors.email = "Email tidak valid";
    if (formData.phone && !formPhoneNumber(formData.phone))
      errors.phone = "Nomor telepon tidak valid";
    if (formData.password && formData.password.length < 6)
      errors.password = "Kata Sandi minimal 6 karakter";
    if (formData.confirmPassword !== formData.password)
      errors.confirmPassword = "Konfirmasi Kata Sandi tidak sama";

    return errors;
  };

  const dispatch = useDispatch();

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setFormError({
      ...formError,
      [name]: null,
    });
  };

  const handleKeyPressPhone = (e) => {
    if (e.key === "e" || e.key === "-") {
      e.preventDefault();
    }
  };

  const submitData = (e) => {
    e.preventDefault();

    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      dispatch(
        addDataAccountAction({
          name: formData.name,
          email: formData.email,
          phone: formattedPhoneNumber(formData.phone),
          position: formData.position,
          password: formData.password,
        })
      );
      props.submitAccount();
    } else {
      setFormError(errors);
    }
  };

  const handleBack = () => {
    props.handleBack();
  };

  return (
    <div className="auth-wrapper">
      <div className="container">
        <div className="p-grid">
          {/* benefit */}
          <div className="p-col-6 p-px-0 benefit">
            <div className="auth-content left">
              <div className="box-content">
                <div className="info-brief">
                  <img src={IconInfo} alt="info icon" width={24} />
                  Brief Anda berhasil disimpan. Silahkan lakukan pendaftaran
                  untuk melanjutkan.
                </div>
                <h1>Keunggulan Layanan IRIS Membangun Citra Perusahaan</h1>
                <p>
                  Mulai langkah tepat membangun popularitas dan kredibilitas
                  perusahaan di media sekarang!
                </p>
                <ul>
                  <li>
                    <span className="icon-deal"></span>Layanan pembuatan press
                    release dan distribusi media dalam satu platform
                  </li>
                  <li>
                    <span className="icon-happy"></span>Fitur yang mudah untuk
                    pemesanan rilis, brief ke penulis, review tulisan, dan
                    approval
                  </li>
                  <li>
                    <span className="icon-connected"></span>IRIS bekerja sama
                    secara resmi dengan media nasional dan media lokal di 40
                    kota.
                  </li>
                  <li>
                    <span className="icon-pen"></span>IRIS memiliki penulis
                    profesional sesuai keahlian dalam berbagai bidang dan dapat
                    menyediakan semua jenis konten yang dibutuhkan klien.
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* account */}
          <div className="auth-register">
            <div onClick={handleBack} className="back p-d-flex p-ai-center">
              <span className="icon-back p-mr-3"></span>
            </div>
            <div className="info-brief-mobile">
              <img src={IconInfo} alt="info icon" width={24} />
              Brief Anda berhasil disimpan. Silahkan lakukan pendaftaran untuk
              melanjutkan.
            </div>
            <div className="p-field" id={formError.name ? "error" : ""}>
              <span className="p-float-label">
                <InputText
                  id="name"
                  name="name"
                  aria-describedby="name"
                  className={`p-d-block ${formError.name && "p-invalid"}`}
                  value={formData.name}
                  onChange={handleChange}
                  autoComplete="off"
                  autoFocus
                />
                <label>Nama Lengkap*</label>
              </span>
              {formError?.name && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {formError?.name}
                </small>
              )}
            </div>

            <div className="p-field" id={formError?.position ? "error" : ""}>
              <span className="p-float-label">
                <InputText
                  id="position"
                  name="position"
                  aria-describedby="position"
                  className={`p-d-block ${formError?.position && "p-invalid"}`}
                  value={formData?.position}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <label>Posisi / Jabatan*</label>
              </span>
              {formError?.position && (
                <small id="position" className="p-error p-d-block p-text-right">
                  {formError?.position}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="phone"
                  name="phone"
                  aria-describedby="phone"
                  className={`p-d-block ${formError?.phone && "p-invalid"}`}
                  value={formData?.phone}
                  onChange={handleChange}
                  type="number"
                  onKeyPress={(e) => handleKeyPressPhone(e)}
                  autoComplete="off"
                />
                <label>Nomor Telepon*</label>
              </span>
              {formError?.phone ? (
                <small id="phone" className="p-error p-d-block p-text-right">
                  {formError?.phone}
                </small>
              ) : (
                <small className="text-input-info p-d-block">
                  Contoh: 0812xxxxx
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="email"
                  name="email"
                  aria-describedby="email"
                  type="email"
                  className={`p-d-block ${formError?.email && "p-invalid"}`}
                  value={formData?.email}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <label>Email*</label>
              </span>
              {formError?.email && (
                <small id="email" className="p-error p-d-block p-text-right">
                  {formError?.email}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="password"
                  name="password"
                  aria-describedby="password"
                  type="password"
                  className={`p-d-block ${formError?.password && "p-invalid"}`}
                  value={formData?.password}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <label>Kata Sandi*</label>
              </span>

              {formError?.password && (
                <small id="password" className="p-error p-d-block p-text-right">
                  {formError?.password}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="confirmPassword"
                  name="confirmPassword"
                  aria-describedby="confirmPassword"
                  type="password"
                  className={`p-d-block ${formError?.confirmPassword &&
                    "p-invalid"}`}
                  value={formData?.confirmPassword}
                  onChange={handleChange}
                  autoComplete="off"
                />
                <label>Konfirmasi Kata Sandi*</label>
              </span>
              {formError?.confirmPassword && (
                <small
                  id="confirmPassword"
                  className="p-error p-d-block p-text-right"
                >
                  {formError?.confirmPassword}
                </small>
              )}
            </div>

            <Button
              label="Selanjutnya"
              className="p-button-rounded p-button-lg"
              onClick={submitData}
            />

            <div className="footer-register">
              <p>
                Sudah punya akun? <Link to="/login">Login disini</Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddAccount;
