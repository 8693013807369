import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  UPLOAD_SINGLE_IMAGE_FAILURE,
  UPLOAD_SINGLE_IMAGE_REQUEST,
  UPLOAD_SINGLE_IMAGE_SUCCESS,
  DELETE_SINGLE_IMAGE_FAILURE,
  DELETE_SINGLE_IMAGE_REQUEST,
  DELETE_SINGLE_IMAGE_SUCCESS,
  DATA_ADD_DETAIL,
  DATA_ADD_BRIEF,
  DATA_ADD_RELEASE,
  RESET_DATA_ADD,
  GET_AGE_FAILURE,
  GET_AGE_REQUEST,
  GET_AGE_SUCCESS,
  GET_GENDER_FAILURE,
  GET_GENDER_REQUEST,
  GET_GENDER_SUCCESS,
  GET_SES_FAILURE,
  GET_SES_REQUEST,
  GET_SES_SUCCESS,
  GET_STAKEHOLDER_FAILURE,
  GET_STAKEHOLDER_REQUEST,
  GET_STAKEHOLDER_SUCCESS,
  GET_CATEGORY_RELEASE_FAILURE,
  GET_CATEGORY_RELEASE_REQUEST,
  GET_CATEGORY_RELEASE_SUCCESS,
  GET_DATA_PROVINCES_FAILURE,
  GET_DATA_PROVINCES_REQUEST,
  GET_DATA_PROVINCES_SUCCESS,
  GET_DATA_DISTRICT_FAILURE,
  GET_DATA_DISTRICT_REQUEST,
  GET_DATA_DISTRICT_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  GET_NOTIFICATIONS_REQUEST,
  GET_NOTIFICATIONS_SUCCESS,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_REQUEST,
  UPDATE_NOTIFICATION_SUCCESS,
  DATA_UPDATE_DETAIL,
  DATA_UPDATE_BRIEF,
  DATA_UPDATE_RELEASE,
  RESET_DATA_UPDATE,
  RESET_UTILS_STATE,
  ANNOUNCEMENTS_FAILURE,
  ANNOUNCEMENTS_SUCCESS,
  ANNOUNCEMENTS_REQUEST,
  GET_HELP_CATEGORY_FAILURE,
  GET_HELP_CATEGORY_SUCCESS,
  GET_HELP_CATEGORY_REQUEST,
  UPLOAD_BRIEF_REQUEST,
  UPLOAD_BRIEF_FAILURE,
  UPLOAD_BRIEF_SUCCESS,
  GET_PAYMENT_ACCOUNT_REQUEST,
  GET_PAYMENT_ACCOUNT_FAILURE,
  GET_PAYMENT_ACCOUNT_SUCCESS,
  PAYMENT_ACCOUNT_DETAIL_REQUEST,
  PAYMENT_ACCOUNT_DETAIL_FAILURE,
  PAYMENT_ACCOUNT_DETAIL_SUCCESS,
  CLIENT_DATA_PROJECT,
  GET_BUSINESS_FAILURE,
  GET_BUSINESS_REQUEST,
  GET_BUSINESS_SUCCESS,
  ADD_DATA_ACCOUNT,
} from "../constant/utilsConstant";

const initialStateUtils = {
  loadingUploadImage: false,
  successUploadImage: false,
  errUploadImage: null,
  dataUploadImage: null,

  loadingDeleteImage: false,
  successDeleteImage: false,
  errDeleteImage: null,

  dataAddProject: {
    detail: null,
    brief: null,
    release: null,
  },

  loadingAge: false,
  dataAge: null,
  errAge: null,

  loadingGender: false,
  dataGender: null,
  errGender: null,

  loadingSes: false,
  dataSes: null,
  errSes: null,

  loadingStakeholder: false,
  dataStakeholder: null,
  errStakeholder: null,

  loadingCategoryRelease: false,
  dataCategoryRelease: null,
  errCategoryRelease: null,

  loadingGetProvince: false,
  dataGetProvince: null,
  errGetProvince: null,

  loadingGetDistrict: false,
  dataGetDistrict: null,
  errGetDistrict: null,

  loadingGetNotif: false,
  dataGetNotif: null,
  errGetNotif: null,

  loadingUpdateNotif: false,
  successUpdateNotif: null,
  errUpdateNotif: null,

  dataUpdateProject: {
    detail: null,
    brief: null,
    release: null,
  },

  loadingAnnouncements: false,
  dataAnnouncements: null,
  errAnnouncements: null,

  loadingUploadBrief: false,
  successUploadBrief: false,
  errUploadBrief: null,
  dataUploadBrief: null,

  loadingPaymentAccount: false,
  dataPaymentAccount: null,
  errPaymentAccount: null,

  loadingPaymentAccountDetail: false,
  dataPaymentAccountDetail: null,
  errPaymentAccountDetail: null,

  loadingHelpCategory: false,
  dataHelpCategory: null,
  errHelpCategory: null,

  dataAddProjectClient: null,

  loadingBusiness: false,
  dataBusiness: null,
  errBusiness: null,
};

export const utilsReducer = persistReducer(
  {
    storage,
    key: "v711-demo1-utils",
    whitelist: ["dataAddProject", "dataAddProjectClient"],
  },
  (state = initialStateUtils, action) => {
    switch (action.type) {
      case UPLOAD_SINGLE_IMAGE_REQUEST:
        return {
          ...state,
          loadingUploadImage: true,
          successUploadImage: false,
          errUploadImage: null,
        };
      case UPLOAD_SINGLE_IMAGE_SUCCESS:
        return {
          ...state,
          loadingUploadImage: false,
          successUploadImage: true,
          errUploadImage: null,
          dataUploadImage: action.data,
        };
      case UPLOAD_SINGLE_IMAGE_FAILURE:
        return {
          ...state,
          loadingUploadImage: false,
          successUploadImage: false,
          errUploadImage: action.err,
        };

      case DELETE_SINGLE_IMAGE_REQUEST:
        return {
          ...state,
          loadingDeleteImage: true,
          errDeleteImage: null,
        };
      case DELETE_SINGLE_IMAGE_SUCCESS:
        return {
          ...state,
          loadingDeleteImage: false,
          successDeleteImage: true,
          errDeleteImage: null,
        };
      case DELETE_SINGLE_IMAGE_FAILURE:
        return {
          ...state,
          loadingDeleteImage: false,
          successDeleteImage: false,
          errDeleteImage: action.err,
        };

      case RESET_DATA_ADD:
        return {
          ...state,
          dataAddProject: {
            detail: null,
            brief: null,
            release: null,
          },
        };
      case DATA_ADD_DETAIL:
        return {
          ...state,
          dataAddProject: {
            ...state.dataAddProject,
            detail: action.data,
          },
        };
      case DATA_ADD_BRIEF:
        return {
          ...state,
          dataAddProject: {
            ...state.dataAddProject,
            brief: action.data,
          },
        };
      case DATA_ADD_RELEASE:
        return {
          ...state,
          dataAddProject: {
            ...state.dataAddProject,
            release: action.data,
          },
        };

      case GET_AGE_REQUEST:
        return {
          ...state,
          loadingAge: true,
          errAge: null,
        };
      case GET_AGE_SUCCESS:
        return {
          ...state,
          loadingAge: false,
          dataAge: action.data,
          errAge: null,
        };
      case GET_AGE_FAILURE:
        return {
          ...state,
          loadingAge: false,
          errAge: action.err,
        };

      case GET_GENDER_REQUEST:
        return {
          ...state,
          loadingGender: true,
          errGender: null,
        };
      case GET_GENDER_SUCCESS:
        return {
          ...state,
          loadingGender: false,
          dataGender: action.data,
          errGender: null,
        };
      case GET_GENDER_FAILURE:
        return {
          ...state,
          loadingGender: false,
          errGender: action.err,
        };

      case GET_SES_REQUEST:
        return {
          ...state,
          loadingSes: true,
          errSes: null,
        };
      case GET_SES_SUCCESS:
        return {
          ...state,
          loadingSes: false,
          dataSes: action.data,
          errSes: null,
        };
      case GET_SES_FAILURE:
        return {
          ...state,
          loadingSes: false,
          errSes: action.err,
        };

      case GET_STAKEHOLDER_REQUEST:
        return {
          ...state,
          loadingStakeholder: true,
          errStakeholder: null,
        };
      case GET_STAKEHOLDER_SUCCESS:
        return {
          ...state,
          loadingStakeholder: false,
          dataStakeholder: action.data,
          errStakeholder: null,
        };
      case GET_STAKEHOLDER_FAILURE:
        return {
          ...state,
          loadingStakeholder: false,
          errStakeholder: action.err,
        };

      case GET_CATEGORY_RELEASE_REQUEST:
        return {
          ...state,
          loadingCategoryRelease: true,
          errCategoryRelease: null,
        };
      case GET_CATEGORY_RELEASE_SUCCESS:
        return {
          ...state,
          loadingCategoryRelease: false,
          dataCategoryRelease: action.data,
          errCategoryRelease: null,
        };
      case GET_CATEGORY_RELEASE_FAILURE:
        return {
          ...state,
          loadingCategoryRelease: false,
          errCategoryRelease: action.err,
        };

      case GET_DATA_PROVINCES_REQUEST:
        return {
          ...state,
          loadingGetProvince: true,
          errGetProvince: null,
        };
      case GET_DATA_PROVINCES_SUCCESS:
        return {
          ...state,
          loadingGetProvince: false,
          dataGetProvince: action.data,
          errGetProvince: null,
        };
      case GET_DATA_PROVINCES_FAILURE:
        return {
          ...state,
          loadingGetProvince: false,
          errGetProvince: action.err,
        };

      case GET_DATA_DISTRICT_REQUEST:
        return {
          ...state,
          loadingGetDistrict: true,
          errGetDistrict: null,
        };
      case GET_DATA_DISTRICT_SUCCESS:
        return {
          ...state,
          loadingGetDistrict: false,
          dataGetDistrict: action.data,
          errGetDistrict: null,
        };
      case GET_DATA_DISTRICT_FAILURE:
        return {
          ...state,
          loadingGetDistrict: false,
          errGetDistrict: action.err,
        };

      case GET_NOTIFICATIONS_REQUEST:
        return {
          ...state,
          loadingGetNotif: true,
          errGetNotif: null,
        };
      case GET_NOTIFICATIONS_SUCCESS:
        return {
          ...state,
          loadingGetNotif: false,
          dataGetNotif: action.data,
          errGetNotif: null,
        };
      case GET_NOTIFICATIONS_FAILURE:
        return {
          ...state,
          loadingGetNotif: false,
          errGetNotif: action.err,
        };

      case UPDATE_NOTIFICATION_REQUEST:
        return {
          ...state,
          loadingUpdateNotif: true,
          successUpdateNotif: false,
          errUpdateNotif: null,
        };
      case UPDATE_NOTIFICATION_SUCCESS:
        return {
          ...state,
          loadingUpdateNotif: false,
          successUpdateNotif: true,
          errUpdateNotif: null,
        };
      case UPDATE_NOTIFICATION_FAILURE:
        return {
          ...state,
          loadingUpdateNotif: false,
          successUpdateNotif: false,
          errUpdateNotif: action.err,
        };

      case RESET_UTILS_STATE:
        return {
          ...state,
          loadingUpdateNotif: false,
          successUpdateNotif: false,
          errUpdateNotif: null,
          loadingUploadImage: false,
          successUploadImage: false,
          errUploadImage: null,
          dataUploadImage: null,
          loadingUploadBrief: false,
          successUploadBrief: false,
          errUploadBrief: null,
          dataUploadBrief: null,
        };

      case RESET_DATA_UPDATE:
        return {
          ...state,
          dataUpdateProject: {
            detail: null,
            brief: null,
            release: null,
          },
        };
      case DATA_UPDATE_DETAIL:
        return {
          ...state,
          dataUpdateProject: {
            ...state.dataUpdateProject,
            detail: action.data,
          },
        };
      case DATA_UPDATE_BRIEF:
        return {
          ...state,
          dataUpdateProject: {
            ...state.dataUpdateProject,
            brief: action.data,
          },
        };
      case DATA_UPDATE_RELEASE:
        return {
          ...state,
          dataUpdateProject: {
            ...state.dataUpdateProject,
            release: action.data,
          },
        };

      case ANNOUNCEMENTS_REQUEST:
        return {
          ...state,
          loadingAnnouncements: true,
          errAnnouncements: null,
        };
      case ANNOUNCEMENTS_SUCCESS:
        return {
          ...state,
          loadingAnnouncements: false,
          dataAnnouncements: action?.result,
          errAnnouncements: null,
        };
      case ANNOUNCEMENTS_FAILURE:
        return {
          ...state,
          loadingAnnouncements: false,
          errAnnouncements: action.err,
        };

      case UPLOAD_BRIEF_REQUEST:
        return {
          ...state,
          loadingUploadBrief: true,
          successUploadBrief: false,
          errUploadBrief: null,
        };
      case UPLOAD_BRIEF_SUCCESS:
        return {
          ...state,
          loadingUploadBrief: false,
          successUploadBrief: true,
          errUploadBrief: null,
          dataUploadBrief: action.data,
        };
      case UPLOAD_BRIEF_FAILURE:
        return {
          ...state,
          loadingUploadBrief: false,
          successUploadBrief: false,
          errUploadBrief: action.err,
        };

      case GET_PAYMENT_ACCOUNT_REQUEST:
        return {
          ...state,
          loadingPaymentAccount: true,
          errPaymentAccount: null,
        };
      case GET_PAYMENT_ACCOUNT_SUCCESS:
        return {
          ...state,
          loadingPaymentAccount: false,
          dataPaymentAccount: action.result,
          errPaymentAccount: null,
        };
      case GET_PAYMENT_ACCOUNT_FAILURE:
        return {
          ...state,
          loadingPaymentAccount: false,
          errPaymentAccount: action.err,
        };

      case PAYMENT_ACCOUNT_DETAIL_REQUEST:
        return {
          ...state,
          loadingPaymentAccountDetail: true,
          errPaymentAccountDetail: null,
        };
      case PAYMENT_ACCOUNT_DETAIL_SUCCESS:
        return {
          ...state,
          loadingPaymentAccountDetail: false,
          dataPaymentAccountDetail: action.data,
          errPaymentAccountDetail: null,
        };
      case PAYMENT_ACCOUNT_DETAIL_FAILURE:
        return {
          ...state,
          loadingPaymentAccountDetail: false,
          errPaymentAccountDetail: action.err,
        };

      case GET_HELP_CATEGORY_REQUEST:
        return {
          ...state,
          loadingHelpCategory: true,
          errHelpCategory: null,
        };
      case GET_HELP_CATEGORY_SUCCESS:
        return {
          ...state,
          loadingHelpCategory: false,
          dataHelpCategory: action.data,
          errHelpCategory: null,
        };
      case GET_HELP_CATEGORY_FAILURE:
        return {
          ...state,
          loadingHelpCategory: false,
          errHelpCategory: action.err,
        };

      case CLIENT_DATA_PROJECT:
        return {
          ...state,
          dataAddProjectClient: action.data,
        };

      case GET_BUSINESS_REQUEST:
        return {
          ...state,
          loadingBusiness: true,
          errBusiness: null,
        };
      case GET_BUSINESS_SUCCESS:
        return {
          ...state,
          loadingBusiness: false,
          dataBusiness: action.data.map((item) => ({
            name: item.bussinesFieldName,
            code: item.bussinesFieldName,
          })),
          errBusiness: null,
        };
      case GET_BUSINESS_FAILURE:
        return {
          ...state,
          loadingBusiness: false,
          errBusiness: action.err,
        };
      case ADD_DATA_ACCOUNT:
        return {
          ...state,
          dataAccount: action.data,
        };
      default:
        return state;
    }
  }
);
