import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DetailProjectPmModule from "../../../modules/Admin/ProjectPM/DetailProjectModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getDetailProjectAction,
  userInvitableAction,
  resetStateProjectAction,
} from "../../../../redux/action/projectAction";

const DetailProjectPM = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();

  const { userInvitableParams } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(getDetailProjectAction(QueryParams.projectId));
    dispatch(userInvitableAction(QueryParams.projectId, userInvitableParams));
    dispatch(resetStateProjectAction);

    titlePage({
      title: "IRIS - Detail Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Project" menu="project">
      <DetailProjectPmModule projectId={QueryParams.projectId} />
    </DashboardLayout>
  );
};

export default DetailProjectPM;
