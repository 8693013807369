import firebase from "./firebase";
import firebaseSecond from "./firebaseStrapi";
import "firebase/remote-config";

const firestore = firebase.firestore();
const firestoreStrapi =
  process.env.REACT_APP_TYPE_ENV === "staging"
    ? firebaseSecond.firestore()
    : firebase.firestore();

const remoteConfig = firebase.remoteConfig();
remoteConfig.settings.minimumFetchIntervalMillis = 3600000;

export const getAge = async () =>
  await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue("articleTargetAge");
    })
    .catch((err) => {
      console.err(err, "err");
    });

export const getReleaseCategory = async () =>
  await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue("pressReleaseCategory");
    })
    .catch((err) => {
      console.err(err, "err");
    });

export const getGender = async () =>
  await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue("articleTargetSex");
    })
    .catch((err) => {
      console.err(err, "err");
    });

export const getSes = async () =>
  await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue("articleTargetSES");
    })
    .catch((err) => {
      console.err(err, "err");
    });

export const getStakeHolder = async () =>
  await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue("articleTargetStakeholders");
    })
    .catch((err) => {
      console.err(err, "err");
    });

export const getHelpCategory = async () =>
  await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue("helpCategory");
    })
    .catch((err) => {
      console.err(err, "err");
    });

export const getBusiness = async () =>
  await remoteConfig
    .fetchAndActivate()
    .then(() => {
      return remoteConfig.getValue("businessField");
    })
    .catch((err) => {
      console.err(err, "err");
    });

export const getProvinces = async () =>
  await firestore.collection("provinces").get();

export const getDistricts = async (id_provinces) =>
  await firestore
    .collection("districts")
    .where("province_id", "==", id_provinces)
    .get();

export const getNotifications = async (uid) =>
  await firestore
    .collection("userNotifications")
    .where("target.value", "==", uid)
    .orderBy("createdAt", "desc")
    .get();

export const getNotificationsPM = async (uid, userType) =>
  await firestore
    .collection("userNotifications")
    .where("target.value", "in", [uid, userType])
    .orderBy("createdAt", "desc")
    .get();

export const updateNotifications = async (id) =>
  await firestore
    .collection("userNotifications")
    .doc(id)
    .update({ isRead: true });

export const announcements = async () =>
  await firestoreStrapi.collection("announcements").get();

export const paymentAccount = async () =>
  await firestoreStrapi.collection("payment-accounts").get();

export const paymentAccountDetail = async (id) =>
  await firestoreStrapi
    .collection("payment-accounts")
    .doc(id)
    .get();

export const getMedia = async () =>
  await firestoreStrapi.collection("media").get();
