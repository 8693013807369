import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import AddProjectModule from "../../../modules/Admin/ProjectPM/AddProjectModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getAgeAction,
  getGenderAction,
  getCategoryReleaseAction,
  getSesAction,
  getStakeholderAction,
} from "../../../../redux/action/utilsAction";
import { getCompaniesRegisterAction } from "../../../../redux/action/clientAction";
import { getUsersAction } from "../../../../redux/action/userAction";

const AddProjectPM = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getAgeAction());
    dispatch(getGenderAction());
    dispatch(getCategoryReleaseAction());
    dispatch(getSesAction());
    dispatch(getStakeholderAction());
    dispatch(getCompaniesRegisterAction());

    titlePage({
      title: "IRIS - Tambah Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users && users?.user?.type === "pm") {
      const params = {
        page: 1,
        type: "general_pm",
        records: "all",
      };
      dispatch(getUsersAction(params));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  return (
    <DashboardLayout title="Manajemen Project" menu="project">
      <AddProjectModule />
    </DashboardLayout>
  );
};

export default AddProjectPM;
