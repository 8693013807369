/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import md5 from "crypto-js/md5";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import DetailMyProjectModule from "../../modules/User/Project/DetailMyProjectModule";
import { titlePage } from "../../helpers/titleHead";

import {
  getDetailProjectAction,
  getArticleAction,
  getLogArticleAction,
  getMyProjectAction,
} from "../../../redux/action/projectAction";
import { resetUtilsStateAction } from "../../../redux/action/utilsAction";

const DetailMyProjectPage = (props) => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const { users } = useSelector((state) => state.auth);
  const { dataGetArticle } = useSelector((state) => state.project);

  const hashId = md5(QueryParams.projectId + users?.uid);

  useEffect(() => {
    titlePage({
      title: "IRIS - Project Saya",
    });

    dispatch(getDetailProjectAction(QueryParams.projectId));
    dispatch(getArticleAction(hashId.toString()));
    dispatch(getLogArticleAction(hashId.toString()));
    dispatch(getMyProjectAction(users?.uid));
    dispatch(resetUtilsStateAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QueryParams.projectId]);

  useEffect(() => {
    if (dataGetArticle?.projectId !== QueryParams.projectId) {
      dispatch(getDetailProjectAction(QueryParams.projectId));
      dispatch(getArticleAction(hashId.toString()));
      dispatch(getLogArticleAction(hashId.toString()));
      dispatch(getMyProjectAction(users?.uid));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QueryParams?.projectId, dataGetArticle?.projectId]);

  return (
    <DashboardUserLayout>
      <DetailMyProjectModule hashId={hashId} />
    </DashboardUserLayout>
  );
};

export default DetailMyProjectPage;
