export const GET_HELP_LIST_REQUEST = "GET_HELP_LIST_REQUEST";
export const GET_HELP_LIST_SUCCESS = "GET_HELP_LIST_SUCCESS";
export const GET_HELP_LIST_FAILURE = "GET_HELP_LIST_FAILURE";

export const GET_HELP_DETAIL_REQUEST = "GET_HELP_DETAIL_REQUEST";
export const GET_HELP_DETAIL_SUCCESS = "GET_HELP_DETAIL_SUCCESS";
export const GET_HELP_DETAIL_FAILURE = "GET_HELP_DETAIL_FAILURE";

export const ADD_HELP_REQUEST = "ADD_HELP_REQUEST";
export const ADD_HELP_SUCCESS = "ADD_HELP_SUCCESS";
export const ADD_HELP_FAILURE = "ADD_HELP_FAILURE";

export const UPDATE_HELP_REQUEST = "UPDATE_HELP_REQUEST";
export const UPDATE_HELP_SUCCESS = "UPDATE_HELP_SUCCESS";
export const UPDATE_HELP_FAILURE = "UPDATE_HELP_FAILURE";

export const DELETE_HELP_REQUEST = "DELETE_HELP_REQUEST";
export const DELETE_HELP_SUCCESS = "DELETE_HELP_SUCCESS";
export const DELETE_HELP_FAILURE = "DELETE_HELP_FAILURE";

export const POST_HELP_CHAT_REQUEST = "POST_HELP_CHAT_REQUEST";
export const POST_HELP_CHAT_SUCCESS = "POST_HELP_CHAT_SUCCESS";
export const POST_HELP_CHAT_FAILURE = "POST_HELP_CHAT_FAILURE";

export const POST_HELP_NOTIF_REQUEST = "POST_HELP_NOTIF_REQUEST";
export const POST_HELP_NOTIF_SUCCESS = "POST_HELP_NOTIF_SUCCESS";
export const POST_HELP_NOTIF_FAILURE = "POST_HELP_NOTIF_FAILURE";

export const RESET_HELP_STATE = "RESET_HELP_STATE";
