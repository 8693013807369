import { all, debounce, takeLatest } from "redux-saga/effects";

import {
  LOGIN_WITH_EMAIL_REQUEST,
  VERIFY_OTP_REQUEST,
  PHONE_REGISTER_REQUEST,
  LOGOUT_REQUEST,
  LOGIN_WITH_LINK_REQUEST,
  PHONE_LOGIN_REQUEST,
  LOGIN_VERIFY_OTP_REQUEST,
  CHECK_PHONE_REQUEST,
  CHECK_EMAIL_REQUEST,
  EMAIL_REGISTER_REQUEST,
  APPLICANT_REGISTER_REQUEST,
  GOOGLE_LOGIN_REQUEST,
  MY_PROFILE_REQUEST,
  EDIT_WRITER_PROFILE_REQUEST,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_EMAIL_REQUEST,
  CHECK_UPDATE_PHONE_REQUEST,
  UPDATE_PHONE_REQUEST,
  CLIENT_REGISTER_REQUEST,
  VERIFY_CLIENT_REQUEST,
  SEND_UPDATE_EMAIL_PM_REQUEST,
  SEND_UPDATE_EMAIL_WRITER_REQUEST,
  UPDATE_EMAIL_PM_REQUEST,
  UPDATE_EMAIL_WRITER_REQUEST,
  UPDATE_USER_FIREBASE_REQUEST,
  RESET_PASSWORD_REQUEST,
  SEND_UPDATE_EMAIL_CLIENT_REQUEST,
  UPDATE_EMAIL_CLIENT_REQUEST,
  CREATE_NEW_PASSWORD_REQUEST,
} from "../constant/authConstant";
import {
  GET_BANNER_REQUEST,
  GET_BENEFIT_REQUEST,
  GET_HOW_DOES_IT_WORK_REQUEST,
  GET_SETTING_SITE_REQUEST,
  GET_TESTIMONIAL_REQUEST,
  GET_TOP_WRITER_REQUEST,
  GET_ARTICLES_REQUEST,
  GET_FOOTER_REQUEST,
  GET_DETAIL_ARTICLE_REQUEST,
  GET_ABOUT_REQUEST,
  GET_TERM_REQUEST,
  GET_PRIVACY_REQUEST,
  GET_FAQ_REQUEST,
  TYPE_PRODUCT_REQUEST,
  GET_STATIC_PAGES_REQUEST,
} from "../constant/landingpageConstant";
import {
  GET_PROJECTS_PM_REQUEST,
  GET_PROJECTS_WRITER_REQUEST,
  GET_MY_PROJECTS_REQUEST,
  GET_DETAIL_PROJECT_REQUEST,
  GET_ARTICLE_REQUEST,
  UPDATE_ARTICLE_REQUEST,
  GET_PROJECT_USER_REQUEST,
  UPDATE_STATUS_PROJECT_REQUEST,
  SUBMIT_ARTICLE_PROJECT_REQUEST,
  GET_USER_INVITABLE_REQUEST,
  ASSIGN_PROJECT_USER_REQUEST,
  JOIN_PROJECT_WITH_TOKEN_REQUEST,
  CREATE_PROJECT_REQUEST,
  JOIN_PROJECT_REQUEST,
  UPDATE_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
  GET_LOG_ARTICLE_REQUEST,
  COUNT_PROJECT_REQUEST,
  UPDATE_COUNT_PROJECT_REQUEST,
  GET_LIST_ARTICLES_REQUEST,
  GET_DRAFT_REQUEST,
  SAVE_DRAFT_REQUEST,
  UPDATE_PROJECT_PM_STATUS_REQUEST,
  UPDATE_ARTICLE_CLIENT_STATUS_REQUEST,
  UPDATE_PROJECT_FS_REQUEST,
  SUPERUSER_JOIN_PROJECT_REQUEST,
  GET_PROJECT_ACTIVE_REQUEST,
  CLIENT_PROJECT_OVERVIEW_REQUEST,
  DELETE_ARTICLE_PROJECT_REQUEST,
  GET_DETAIL_SAVE_PROJECT_REQUEST,
  GET_BRIEF_PROJECTS_PM_REQUEST,
  GET_PROJECT_DETAIL_PM_REQUEST,
  GET_PROJECT_DETAIL_CLIENT_REQUEST,
} from "../constant/projectConstant";
import {
  UPLOAD_SINGLE_IMAGE_REQUEST,
  DELETE_SINGLE_IMAGE_REQUEST,
  GET_AGE_REQUEST,
  GET_GENDER_REQUEST,
  GET_CATEGORY_RELEASE_REQUEST,
  GET_STAKEHOLDER_REQUEST,
  GET_SES_REQUEST,
  GET_DATA_DISTRICT_REQUEST,
  GET_DATA_PROVINCES_REQUEST,
  GET_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATION_REQUEST,
  ANNOUNCEMENTS_REQUEST,
  UPLOAD_BRIEF_REQUEST,
  GET_PAYMENT_ACCOUNT_REQUEST,
  GET_HELP_CATEGORY_REQUEST,
  PAYMENT_ACCOUNT_DETAIL_REQUEST,
  GET_BUSINESS_REQUEST,
} from "../constant/utilsConstant";
import {
  DELETE_USER_REQUEST,
  GET_DETAIL_USER_REQUEST,
  GET_PORTFOLIO_REQUEST,
  GET_USER_REQUEST,
  INVITE_USER_REQUEST,
  RE_INVITE_USER_REQUEST,
  UPDATE_USER_REQUEST,
  UPLOAD_PORTFOLIO_IMAGE_REQUEST,
  UPDATE_STATUS_APPLICANT_REQUEST,
  POST_PM_REQUEST,
  UPDATE_STATUS_USER_REQUEST,
  UPLOAD_COMPANY_PROFILE_REQUEST,
  POST_WRITER_BANK_REQUEST,
  GET_WRITER_BANK_REQUEST,
  UPDATE_WRITER_BANK_REQUEST,
} from "../constant/userConstant";
import {
  SEND_CHAT_CLIENT_FS_REQUEST,
  SEND_CHAT_CLIENT_REQUEST,
  SEND_CHAT_REQUEST,
  SEND_NOTIF_REQUEST,
  UNPIN_CHAT_REQUEST,
} from "../constant/chatConstant";
import {
  ADD_PRODUCT_REQUEST,
  DELETE_PRODUCT_REQUEST,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_REQUEST,
  GET_SERVICE_REQUEST,
  UPDATE_PRODUCT_REQUEST,
} from "../constant/serviceConstant";
import {
  AUTO_COMPLETE_COMPANY_REQUEST,
  DELETE_CLIENT_REQUEST,
  GET_CLIENT_DETAIL_REQUEST,
  GET_CLIENT_REQUEST,
  POST_CLIENT_REQUEST,
  UPDATE_CLIENT_REQUEST,
  UPDATE_COMPANY_REQUEST,
} from "../constant/clientConstant";
import {
  ADD_PIC_REQUEST,
  DELETE_PIC_REQUEST,
  GET_PIC_DETAIL_REQUEST,
  GET_PIC_LIST_REQUEST,
  UPDATE_PIC_REQUEST,
  ASSIGN_PIC_REQUEST,
} from "../constant/clientPicConstant";
import {
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_REQUEST,
  GET_ORDER_SAVE_DETAIL_REQUEST,
  GET_PROJECT_CLIENT_REQUEST,
  GET_SAVE_CLIENT_REQUEST,
  ORDER_CLIENT_DETAIL_REQUEST,
  ORDER_CLIENT_SAVE_DETAIL_REQUEST,
  POST_ORDER_BRIEF_REQUEST,
  POST_ORDER_CLIENT_REQUEST,
  POST_SAVE_CLIENT_REQUEST,
  UPDATE_ORDER_REQUEST,
} from "../constant/orderConstant";
import {
  GET_FINANCE_ORDER_REQUEST,
  GET_FINANCE_PROJECT_REQUEST,
  UPDATE_FINANCE_ORDER_REQUEST,
  APPROVE_ALL_PAYOUT_REQUEST,
  APPROVE_PAYOUT_REQUEST,
} from "../constant/financeConstant";
import {
  ADD_HELP_REQUEST,
  GET_HELP_LIST_REQUEST,
  GET_HELP_DETAIL_REQUEST,
  POST_HELP_NOTIF_REQUEST,
  POST_HELP_CHAT_REQUEST,
} from "../constant/helpConstant";

import {
  LIST_WRITER_PAYMENT_REQUEST,
  DETAIL_WRITER_PAYMENT_REQUEST,
  WRITER_TRANSACTION_REQUEST,
  WRITER_WITHDRAW_REQUEST,
  LIST_PAYOUT_FINANCE_REQUEST,
} from "../constant/transactionConstant";
import {
  DELETE_INFO_REQUEST,
  GET_DETAIL_INFO_REQUEST,
  GET_LIST_INFO_REQUEST,
  POST_INFO_REQUEST,
  UPDATE_INFO_REQUEST,
} from "../constant/adminConstant";
import {
  GET_WINNER_RELEASE_REQUEST,
  DETAIL_RELEASE_REQUEST,
  FS_MEDIA_REQUEST,
  ADD_MEDIA_RELEASE_REQUEST,
  PUT_MEDIA_RELEASE_REQUEST,
  DELETE_MEDIA_RELEASE_REQUEST,
  FS_DETAIL_MEDIA_REQUEST,
  STATS_MEDIA_RELEASE_REQUEST,
  STATS_RELEASE_REQUEST,
} from "../constant/releaseConstant";

import {
  emailLoginSaga,
  verifyOtpSaga,
  phoneRegisterSaga,
  logoutSaga,
  loginWithLinkSaga,
  phoneLoginSaga,
  loginVerifyOtpSaga,
  checkPhoneSaga,
  emailRegisterSaga,
  applicantRegisterSaga,
  googleLoginSaga,
  myProfileSaga,
  editWriterProfileSaga,
  updatePasswordSaga,
  updateEmailSaga,
  chekPhoneNumberUpdateSaga,
  updatePhoneNumberSaga,
  clientRegisterSaga,
  clientVerifySaga,
  sendUpdateEmailPmSaga,
  sendUpdateEmailWriterSaga,
  updateEmailPmSaga,
  updateEmailWriterSaga,
  updateUserFirebaseSaga,
  checkEmailSaga,
  resetPasswordSaga,
  sendUpdateEmailClientSaga,
  updateEmailClientSaga,
  createNewPasswordSaga,
} from "./authSaga";
import {
  getArticlesSaga,
  getBannerSaga,
  getBenefitSaga,
  getHowDoesItWorkSaga,
  getSettingSiteSaga,
  getTestimonialSaga,
  getTopWriterSaga,
  getDetailArticleSaga,
  getFooterSaga,
  getAboutSaga,
  getTermSaga,
  getPrivacySaga,
  getFaqSaga,
  typeProductSaga,
  staticPageSaga,
} from "./landingpageSaga";
import {
  getProjectsPMSaga,
  getMyProjectSaga,
  getDetailProjectSaga,
  getProjectsWriterSaga,
  updateArticleSaga,
  getArticleSaga,
  getProjectUserSaga,
  updateStatusProjectSaga,
  submitArticleProjectSaga,
  getUserInvitableSaga,
  assignProjectSaga,
  joinProjectWithTokenSaga,
  createProjectSaga,
  joinProjectSaga,
  updateProjectSaga,
  deleteProjectSaga,
  getLogArticleSaga,
  countProjectSaga,
  updateCountProjectSaga,
  getListArticlesSaga,
  getDraftSaga,
  saveDraftSaga,
  updateProjectPmStateSaga,
  updateArticleClientStatusSaga,
  updateProjectFsSaga,
  superuserJoinProjectSaga,
  getProjectActiveSaga,
  clientProjectOverviewSaga,
  deleteWriterArticleAction,
  getSaveDetailProjectSaga,
  getBriefProjectsPMSaga,
  getProjectDetailPMSaga,
  getProjectDetailClientSaga,
} from "./projectSaga";
import {
  uploadImageSaga,
  deleteImageSaga,
  getAgeSaga,
  getCategoryReleaseSaga,
  getGenderSaga,
  getSesSaga,
  getStakeholderSaga,
  getDistrictSaga,
  getProvincesSaga,
  getNotificationSaga,
  updateNotificationSaga,
  getAnnouncementsSaga,
  uploadBriefSaga,
  getPaymentAccountSaga,
  getPaymentAccountDetailSaga,
  getHelpCategorySaga,
  getBusinessSaga,
} from "./utilsSaga";
import {
  deleteUserSaga,
  getDetailUserSaga,
  getPortfolioFileSaga,
  getUsersSaga,
  inviteUserSaga,
  reInviteUserSaga,
  updateUserSaga,
  uploadPortfolioSaga,
  updateStatusApplicantSaga,
  postPmSaga,
  updateStatusUserSaga,
  uploadCompanyProfileSaga,
  getWriterBankSaga,
  postWriterBankSaga,
  updateWriterBankSaga,
} from "./userSaga";

import {
  sendChatClientFsSaga,
  sendChatClientSaga,
  sendChatSaga,
  sendNotifSaga,
  unpinSaga,
} from "./chatSaga";
import {
  createProductSaga,
  deleteProductSaga,
  getDetailProductSaga,
  getProductSaga,
  getServiceSaga,
  updateProductSaga,
} from "./serviceSaga";
import {
  createClientSaga,
  deleteClientSaga,
  getAutoCompleteCompanySaga,
  getClientSaga,
  getDetailClienSaga,
  updateClientSaga,
  updateCompanySaga,
} from "./clientSaga";
import {
  getOrderDetailClientSaga,
  getOrderDetailSaga,
  getOrderSaga,
  getOrderSaveDetailClientSaga,
  getOrderSaveDetailSaga,
  getProjectClientSaga,
  getSaveProjectClientSaga,
  postOrderBriefSaga,
  postOrderClientSaga,
  postProjectSaveClientSaga,
  updateOrderSaga,
} from "./orderSaga";
import {
  addPICSaga,
  assignPICSaga,
  deletePICSaga,
  getDetailPICSaga,
  getPICListSaga,
  updatePICSaga,
} from "./clientPicSaga";
import {
  addHelpSaga,
  getListHelpSaga,
  getHelpDetailSaga,
  postHelpChatSaga,
  postHelpNotifSaga,
} from "./helpSaga";
import {
  getFinanceOrderSaga,
  getFinanceProjectSaga,
  updateFinanceOrderSaga,
  approveAllPayoutSaga,
  approvePayoutSaga,
} from "./financeSaga";
import {
  listWriterPaymentSaga,
  detailWriterPaymentSaga,
  writerTransactionSaga,
  writerWithdrawSaga,
  listPayoutFinanceSaga,
} from "./transactionSaga";
import {
  deleteInfoSaga,
  getDetailInfoSaga,
  getListInfoSaga,
  postInfoSaga,
  updateInfoSaga,
} from "./adminSaga";
import {
  getWinnerReleaseSaga,
  getDetailReleaseSaga,
  getMediaSaga,
  postReleaseSaga,
  updateReleaseSaga,
  deleteReleaseSaga,
  getDetailMediaSaga,
  statsMediaReleaseSaga,
  statsReleaseSaga,
} from "./releaseSaga";

export function* rootSaga() {
  // Auth
  yield all([takeLatest(LOGIN_WITH_EMAIL_REQUEST, emailLoginSaga)]);
  yield all([takeLatest(VERIFY_OTP_REQUEST, verifyOtpSaga)]);
  yield all([takeLatest(PHONE_REGISTER_REQUEST, phoneRegisterSaga)]);
  yield all([takeLatest(PHONE_LOGIN_REQUEST, phoneLoginSaga)]);
  yield all([takeLatest(LOGOUT_REQUEST, logoutSaga)]);
  yield all([takeLatest(LOGIN_WITH_LINK_REQUEST, loginWithLinkSaga)]);
  yield all([takeLatest(LOGIN_VERIFY_OTP_REQUEST, loginVerifyOtpSaga)]);
  yield all([takeLatest(CHECK_PHONE_REQUEST, checkPhoneSaga)]);
  yield all([takeLatest(CHECK_EMAIL_REQUEST, checkEmailSaga)]);
  yield all([takeLatest(EMAIL_REGISTER_REQUEST, emailRegisterSaga)]);
  yield all([takeLatest(APPLICANT_REGISTER_REQUEST, applicantRegisterSaga)]);
  yield all([takeLatest(GOOGLE_LOGIN_REQUEST, googleLoginSaga)]);
  yield all([takeLatest(MY_PROFILE_REQUEST, myProfileSaga)]);
  yield all([takeLatest(EDIT_WRITER_PROFILE_REQUEST, editWriterProfileSaga)]);
  yield all([takeLatest(UPDATE_PASSWORD_REQUEST, updatePasswordSaga)]);
  yield all([takeLatest(UPDATE_EMAIL_REQUEST, updateEmailSaga)]);
  yield all([
    takeLatest(CHECK_UPDATE_PHONE_REQUEST, chekPhoneNumberUpdateSaga),
  ]);
  yield all([takeLatest(UPDATE_PHONE_REQUEST, updatePhoneNumberSaga)]);
  yield all([takeLatest(CLIENT_REGISTER_REQUEST, clientRegisterSaga)]);
  yield all([takeLatest(VERIFY_CLIENT_REQUEST, clientVerifySaga)]);
  yield all([
    takeLatest(SEND_UPDATE_EMAIL_CLIENT_REQUEST, sendUpdateEmailClientSaga),
  ]);
  yield all([takeLatest(SEND_UPDATE_EMAIL_PM_REQUEST, sendUpdateEmailPmSaga)]);
  yield all([
    takeLatest(SEND_UPDATE_EMAIL_WRITER_REQUEST, sendUpdateEmailWriterSaga),
  ]);

  yield all([takeLatest(UPDATE_EMAIL_CLIENT_REQUEST, updateEmailClientSaga)]);
  yield all([takeLatest(UPDATE_EMAIL_PM_REQUEST, updateEmailPmSaga)]);
  yield all([takeLatest(UPDATE_EMAIL_WRITER_REQUEST, updateEmailWriterSaga)]);
  yield all([takeLatest(UPDATE_USER_FIREBASE_REQUEST, updateUserFirebaseSaga)]);
  yield all([takeLatest(RESET_PASSWORD_REQUEST, resetPasswordSaga)]);
  yield all([takeLatest(CREATE_NEW_PASSWORD_REQUEST, createNewPasswordSaga)]);

  // LandingPage User
  yield all([takeLatest(GET_BANNER_REQUEST, getBannerSaga)]);
  yield all([takeLatest(GET_BENEFIT_REQUEST, getBenefitSaga)]);
  yield all([takeLatest(GET_TESTIMONIAL_REQUEST, getTestimonialSaga)]);
  yield all([takeLatest(GET_SETTING_SITE_REQUEST, getSettingSiteSaga)]);
  yield all([takeLatest(GET_HOW_DOES_IT_WORK_REQUEST, getHowDoesItWorkSaga)]);
  yield all([takeLatest(GET_TOP_WRITER_REQUEST, getTopWriterSaga)]);
  yield all([takeLatest(GET_ARTICLES_REQUEST, getArticlesSaga)]);
  yield all([takeLatest(GET_DETAIL_ARTICLE_REQUEST, getDetailArticleSaga)]);
  yield all([takeLatest(GET_PORTFOLIO_REQUEST, getPortfolioFileSaga)]);
  yield all([takeLatest(GET_FOOTER_REQUEST, getFooterSaga)]);
  yield all([takeLatest(GET_ABOUT_REQUEST, getAboutSaga)]);
  yield all([takeLatest(GET_TERM_REQUEST, getTermSaga)]);
  yield all([takeLatest(GET_PRIVACY_REQUEST, getPrivacySaga)]);
  yield all([takeLatest(GET_FAQ_REQUEST, getFaqSaga)]);
  yield all([takeLatest(TYPE_PRODUCT_REQUEST, typeProductSaga)]);
  yield all([takeLatest(GET_STATIC_PAGES_REQUEST, staticPageSaga)]);

  // Project
  yield all([takeLatest(GET_PROJECTS_PM_REQUEST, getProjectsPMSaga)]);
  yield all([takeLatest(GET_PROJECT_DETAIL_PM_REQUEST, getProjectDetailPMSaga)]);
  yield all([takeLatest(GET_PROJECT_DETAIL_CLIENT_REQUEST, getProjectDetailClientSaga)]);
  yield all([takeLatest(GET_BRIEF_PROJECTS_PM_REQUEST, getBriefProjectsPMSaga)]);
  yield all([takeLatest(GET_PROJECTS_WRITER_REQUEST, getProjectsWriterSaga)]);
  yield all([takeLatest(GET_MY_PROJECTS_REQUEST, getMyProjectSaga)]);
  yield all([takeLatest(GET_DETAIL_PROJECT_REQUEST, getDetailProjectSaga)]);
  yield all([takeLatest(GET_DETAIL_SAVE_PROJECT_REQUEST, getSaveDetailProjectSaga)]);
  yield all([takeLatest(GET_ARTICLE_REQUEST, getArticleSaga)]);
  yield all([takeLatest(UPDATE_ARTICLE_REQUEST, updateArticleSaga)]);
  yield all([
    takeLatest(UPDATE_STATUS_PROJECT_REQUEST, updateStatusProjectSaga),
  ]);
  yield all([takeLatest(GET_PROJECT_USER_REQUEST, getProjectUserSaga)]);
  yield all([
    takeLatest(SUBMIT_ARTICLE_PROJECT_REQUEST, submitArticleProjectSaga),
  ]);
  yield all([takeLatest(GET_USER_INVITABLE_REQUEST, getUserInvitableSaga)]);
  yield all([takeLatest(ASSIGN_PROJECT_USER_REQUEST, assignProjectSaga)]);
  yield all([
    takeLatest(JOIN_PROJECT_WITH_TOKEN_REQUEST, joinProjectWithTokenSaga),
  ]);
  yield all([takeLatest(CREATE_PROJECT_REQUEST, createProjectSaga)]);
  yield all([takeLatest(JOIN_PROJECT_REQUEST, joinProjectSaga)]);
  yield all([takeLatest(UPDATE_PROJECT_REQUEST, updateProjectSaga)]);
  yield all([takeLatest(DELETE_PROJECT_REQUEST, deleteProjectSaga)]);
  yield all([takeLatest(GET_LOG_ARTICLE_REQUEST, getLogArticleSaga)]);
  yield all([takeLatest(COUNT_PROJECT_REQUEST, countProjectSaga)]);
  yield all([takeLatest(UPDATE_COUNT_PROJECT_REQUEST, updateCountProjectSaga)]);
  yield all([takeLatest(GET_LIST_ARTICLES_REQUEST, getListArticlesSaga)]);
  yield all([takeLatest(GET_DRAFT_REQUEST, getDraftSaga)]);
  yield all([takeLatest(SAVE_DRAFT_REQUEST, saveDraftSaga)]);
  yield all([
    takeLatest(UPDATE_PROJECT_PM_STATUS_REQUEST, updateProjectPmStateSaga),
  ]);
  yield all([
    takeLatest(
      UPDATE_ARTICLE_CLIENT_STATUS_REQUEST,
      updateArticleClientStatusSaga
    ),
  ]);
  yield all([takeLatest(UPDATE_PROJECT_FS_REQUEST, updateProjectFsSaga)]);
  yield all([
    takeLatest(SUPERUSER_JOIN_PROJECT_REQUEST, superuserJoinProjectSaga),
  ]);
  yield all([takeLatest(GET_PROJECT_ACTIVE_REQUEST, getProjectActiveSaga)]);
  yield all([
    takeLatest(CLIENT_PROJECT_OVERVIEW_REQUEST, clientProjectOverviewSaga),
  ]);
  yield all([
    takeLatest(DELETE_ARTICLE_PROJECT_REQUEST, deleteWriterArticleAction),
  ]);

  // Utils
  yield all([takeLatest(UPLOAD_SINGLE_IMAGE_REQUEST, uploadImageSaga)]);
  yield all([takeLatest(DELETE_SINGLE_IMAGE_REQUEST, deleteImageSaga)]);
  yield all([takeLatest(GET_AGE_REQUEST, getAgeSaga)]);
  yield all([takeLatest(GET_GENDER_REQUEST, getGenderSaga)]);
  yield all([takeLatest(GET_SES_REQUEST, getSesSaga)]);
  yield all([takeLatest(GET_CATEGORY_RELEASE_REQUEST, getCategoryReleaseSaga)]);
  yield all([takeLatest(GET_STAKEHOLDER_REQUEST, getStakeholderSaga)]);
  yield all([takeLatest(GET_DATA_PROVINCES_REQUEST, getProvincesSaga)]);
  yield all([takeLatest(GET_DATA_DISTRICT_REQUEST, getDistrictSaga)]);
  yield all([takeLatest(GET_NOTIFICATIONS_REQUEST, getNotificationSaga)]);
  yield all([takeLatest(UPDATE_NOTIFICATION_REQUEST, updateNotificationSaga)]);
  yield all([takeLatest(ANNOUNCEMENTS_REQUEST, getAnnouncementsSaga)]);
  yield all([takeLatest(UPLOAD_BRIEF_REQUEST, uploadBriefSaga)]);
  yield all([takeLatest(GET_PAYMENT_ACCOUNT_REQUEST, getPaymentAccountSaga)]);
  yield all([takeLatest(GET_HELP_CATEGORY_REQUEST, getHelpCategorySaga)]);
  yield all([
    takeLatest(PAYMENT_ACCOUNT_DETAIL_REQUEST, getPaymentAccountDetailSaga),
  ]);
  yield all([takeLatest(GET_BUSINESS_REQUEST, getBusinessSaga)]);

  // User
  yield all([takeLatest(INVITE_USER_REQUEST, inviteUserSaga)]);
  yield all([takeLatest(RE_INVITE_USER_REQUEST, reInviteUserSaga)]);
  yield all([takeLatest(GET_USER_REQUEST, getUsersSaga)]);
  yield all([takeLatest(UPDATE_USER_REQUEST, updateUserSaga)]);
  yield all([takeLatest(DELETE_USER_REQUEST, deleteUserSaga)]);
  yield all([takeLatest(GET_DETAIL_USER_REQUEST, getDetailUserSaga)]);
  yield all([takeLatest(UPLOAD_PORTFOLIO_IMAGE_REQUEST, uploadPortfolioSaga)]);
  yield all([
    takeLatest(UPLOAD_COMPANY_PROFILE_REQUEST, uploadCompanyProfileSaga),
  ]);
  yield all([
    takeLatest(UPDATE_STATUS_APPLICANT_REQUEST, updateStatusApplicantSaga),
  ]);
  yield all([takeLatest(POST_PM_REQUEST, postPmSaga)]);
  yield all([takeLatest(UPDATE_STATUS_USER_REQUEST, updateStatusUserSaga)]);
  yield all([takeLatest(POST_WRITER_BANK_REQUEST, postWriterBankSaga)]);
  yield all([takeLatest(GET_WRITER_BANK_REQUEST, getWriterBankSaga)]);
  yield all([takeLatest(UPDATE_WRITER_BANK_REQUEST, updateWriterBankSaga)]);

  // Chat
  yield all([takeLatest(SEND_CHAT_REQUEST, sendChatSaga)]);
  yield all([takeLatest(SEND_NOTIF_REQUEST, sendNotifSaga)]);
  yield all([takeLatest(UNPIN_CHAT_REQUEST, unpinSaga)]);
  yield all([takeLatest(SEND_CHAT_CLIENT_REQUEST, sendChatClientSaga)]);
  yield all([takeLatest(SEND_CHAT_CLIENT_FS_REQUEST, sendChatClientFsSaga)]);

  // Service
  yield all([takeLatest(GET_SERVICE_REQUEST, getServiceSaga)]);
  yield all([takeLatest(GET_PRODUCT_REQUEST, getProductSaga)]);
  yield all([takeLatest(ADD_PRODUCT_REQUEST, createProductSaga)]);
  yield all([takeLatest(UPDATE_PRODUCT_REQUEST, updateProductSaga)]);
  yield all([takeLatest(DELETE_PRODUCT_REQUEST, deleteProductSaga)]);
  yield all([takeLatest(GET_PRODUCT_DETAIL_REQUEST, getDetailProductSaga)]);

  // Client
  yield all([
    debounce(1000, AUTO_COMPLETE_COMPANY_REQUEST, getAutoCompleteCompanySaga),
  ]);
  yield all([takeLatest(UPDATE_COMPANY_REQUEST, updateCompanySaga)]);
  yield all([takeLatest(POST_CLIENT_REQUEST, createClientSaga)]);
  yield all([takeLatest(GET_CLIENT_REQUEST, getClientSaga)]);
  yield all([takeLatest(GET_CLIENT_DETAIL_REQUEST, getDetailClienSaga)]);
  yield all([takeLatest(UPDATE_CLIENT_REQUEST, updateClientSaga)]);
  yield all([takeLatest(DELETE_CLIENT_REQUEST, deleteClientSaga)]);

  // Order
  yield all([takeLatest(GET_ORDER_REQUEST, getOrderSaga)]);
  yield all([takeLatest(GET_ORDER_DETAIL_REQUEST, getOrderDetailSaga)]);
  yield all([takeLatest(GET_ORDER_SAVE_DETAIL_REQUEST, getOrderSaveDetailSaga)]);
  yield all([takeLatest(POST_ORDER_CLIENT_REQUEST, postOrderClientSaga)]);
  yield all([takeLatest(POST_SAVE_CLIENT_REQUEST, postProjectSaveClientSaga)]);
  yield all([
    takeLatest(ORDER_CLIENT_DETAIL_REQUEST, getOrderDetailClientSaga),
  ]);
  yield all([
    takeLatest(ORDER_CLIENT_SAVE_DETAIL_REQUEST, getOrderSaveDetailClientSaga),
  ]);
  yield all([takeLatest(UPDATE_ORDER_REQUEST, updateOrderSaga)]);
  yield all([takeLatest(POST_ORDER_BRIEF_REQUEST, postOrderBriefSaga)]);
  yield all([takeLatest(GET_PROJECT_CLIENT_REQUEST, getProjectClientSaga)]);
  yield all([takeLatest(GET_SAVE_CLIENT_REQUEST, getSaveProjectClientSaga)]);

  // client - pic
  yield all([takeLatest(GET_PIC_LIST_REQUEST, getPICListSaga)]);
  yield all([takeLatest(GET_PIC_DETAIL_REQUEST, getDetailPICSaga)]);
  yield all([takeLatest(ADD_PIC_REQUEST, addPICSaga)]);
  yield all([takeLatest(UPDATE_PIC_REQUEST, updatePICSaga)]);
  yield all([takeLatest(DELETE_PIC_REQUEST, deletePICSaga)]);
  yield all([takeLatest(ASSIGN_PIC_REQUEST, assignPICSaga)]);

  // help
  yield all([takeLatest(ADD_HELP_REQUEST, addHelpSaga)]);
  yield all([takeLatest(GET_HELP_LIST_REQUEST, getListHelpSaga)]);
  yield all([takeLatest(GET_HELP_DETAIL_REQUEST, getHelpDetailSaga)]);
  yield all([takeLatest(POST_HELP_CHAT_REQUEST, postHelpChatSaga)]);
  yield all([takeLatest(POST_HELP_NOTIF_REQUEST, postHelpNotifSaga)]);

  // Finance
  yield all([takeLatest(GET_FINANCE_ORDER_REQUEST, getFinanceOrderSaga)]);
  yield all([takeLatest(UPDATE_FINANCE_ORDER_REQUEST, updateFinanceOrderSaga)]);
  yield all([takeLatest(GET_FINANCE_PROJECT_REQUEST, getFinanceProjectSaga)]);
  yield all([takeLatest(APPROVE_ALL_PAYOUT_REQUEST, approveAllPayoutSaga)]);
  yield all([takeLatest(APPROVE_PAYOUT_REQUEST, approvePayoutSaga)]);

  // Transaction
  yield all([takeLatest(WRITER_WITHDRAW_REQUEST, writerWithdrawSaga)]);
  yield all([takeLatest(WRITER_TRANSACTION_REQUEST, writerTransactionSaga)]);
  yield all([takeLatest(LIST_WRITER_PAYMENT_REQUEST, listWriterPaymentSaga)]);
  yield all([
    takeLatest(DETAIL_WRITER_PAYMENT_REQUEST, detailWriterPaymentSaga),
  ]);
  yield all([takeLatest(LIST_PAYOUT_FINANCE_REQUEST, listPayoutFinanceSaga)]);

  // Admin
  yield all([takeLatest(GET_LIST_INFO_REQUEST, getListInfoSaga)]);
  yield all([takeLatest(POST_INFO_REQUEST, postInfoSaga)]);
  yield all([takeLatest(GET_DETAIL_INFO_REQUEST, getDetailInfoSaga)]);
  yield all([takeLatest(UPDATE_INFO_REQUEST, updateInfoSaga)]);
  yield all([takeLatest(DELETE_INFO_REQUEST, deleteInfoSaga)]);

  // Release
  yield all([takeLatest(GET_WINNER_RELEASE_REQUEST, getWinnerReleaseSaga)]);
  yield all([takeLatest(DETAIL_RELEASE_REQUEST, getDetailReleaseSaga)]);
  yield all([takeLatest(FS_MEDIA_REQUEST, getMediaSaga)]);
  yield all([takeLatest(ADD_MEDIA_RELEASE_REQUEST, postReleaseSaga)]);
  yield all([takeLatest(PUT_MEDIA_RELEASE_REQUEST, updateReleaseSaga)]);
  yield all([takeLatest(DELETE_MEDIA_RELEASE_REQUEST, deleteReleaseSaga)]);
  yield all([takeLatest(FS_DETAIL_MEDIA_REQUEST, getDetailMediaSaga)]);
  yield all([takeLatest(STATS_MEDIA_RELEASE_REQUEST, statsMediaReleaseSaga)]);
  yield all([takeLatest(STATS_RELEASE_REQUEST, statsReleaseSaga)]);
}
