import React from "react";
import ContentLoader from "react-content-loader";

const ListLoader = (props) => (
  <ContentLoader
    width={"100%"}
    height={100}
    viewBox="0 0 100% 100"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
  >
    <rect x="37" y="34" rx="0" ry="0" width="0" height="0" />
    <rect x="28" y="29" rx="0" ry="0" width="749" height="32" />
    <rect x="28" y="71" rx="0" ry="0" width="749" height="32" />
    <rect x="434" y="94" rx="0" ry="0" width="0" height="0" />
  </ContentLoader>
);

export default ListLoader;
