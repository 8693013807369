import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import CompanyManagementModule from "../../../modules/Admin/Superadmin/CompanyManagement/CompanyManagementModule";
import {
  getClientAction,
  resetClientStateAction,
} from "../../../../redux/action/clientAction";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";

const CompanyManagementPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { clientParams } = useSelector((state) => state.client);

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Klien",
    });
    history.push("/admin/pm/company-list");
    dispatch(getClientAction(clientParams));
    dispatch(resetUtilsStateAction());
    dispatch(resetClientStateAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Klien">
      <CompanyManagementModule />
    </DashboardLayout>
  );
};

export default CompanyManagementPage;
