/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink, Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector } from "react-redux";

export function AsideMenuList({ layoutProps, menu }) {
  const location = useLocation();
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const { dataDetailUser, loadingDetailUser } = useSelector(
    (state) => state.user
  );
  const { users } = useSelector((state) => state.auth);

  const isSuperAdmin = users?.user?.type === "pm";

  const projectManagementPages = [
    "/admin/pm/detail-project",
    "/admin/pm/edit-project/",
    "admin/pm/add-project",
  ];
  const isProjectManagementPages = projectManagementPages
    .map((page) => location.pathname.includes(page))
    .find((item) => item);
  const userManagementPages = [
    "/admin/pm/list-user/applicants",
    "/admin/pm/list-user/verified",
    "/admin/pm/list-user/clients",
    "/admin/pm/detail-user/",
    "admin/pm/edit-user",
  ];

  let isVerifiedUserPage;
  let isApplicantPage;
  if (
    (location.pathname.includes("admin/pm/detail-user") ||
      location.pathname.includes("admin/pm/edit-user")) &&
    !loadingDetailUser
  ) {
    if (dataDetailUser?.status === "verified") {
      isVerifiedUserPage = true;
      isApplicantPage = false;
    } else if (dataDetailUser?.status === "waiting") {
      isVerifiedUserPage = false;
      isApplicantPage = true;
    } else {
      isVerifiedUserPage = false;
      isApplicantPage = false;
    }
  }

  useEffect(() => {
    if (
      userManagementPages
        .map((page) => location.pathname.includes(page))
        .find((item) => item)
    ) {
      setOpenSubmenu(true);
    }
  }, [location, userManagementPages]);

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const getSubmenuItemActive = (url) => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/pm/list-project",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className={`menu-link ${(isProjectManagementPages ||
              menu === "project") &&
              "active"}`}
            to="/admin/pm/list-project"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-invoice.svg")}
              />
            </span>
            <span className="menu-text">Manajemen Project</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive(
            "/admin/pm/list-articles",
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className={`menu-link ${menu === "article" && "active"}`}
            to="/admin/pm/list-articles"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-edit.svg")}
              />
            </span>
            <span className="menu-text">Manajemen Tulisan</span>
          </NavLink>
        </li>
        {isSuperAdmin && (
          <>
            <li
              className={`menu-item-submenu menu-item ${openSubmenu &&
                "menu-item-active menu-item-open"}`}
              aria-haspopup="true"
            >
              <a
                className={`menu-link menu-toggle ${(openSubmenu ||
                  menu === "penulis") &&
                  "active"} `}
                onClick={() => setOpenSubmenu(!openSubmenu)}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/ic-profile.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Manajemen Penulis</span>
              </a>
              <div
                className={`menu-submenu ${
                  openSubmenu ? "d-block" : "d-none"
                } `}
              >
                <span className="menu-arrow"></span>
                <ul className="menu-subnav">
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    className={`menu-item ${getSubmenuItemActive(
                      "/admin/pm/list-user/verified"
                    )} ${isVerifiedUserPage ? "menu-item-active" : ""} `}
                  >
                    <Link
                      to="/admin/pm/list-user/verified"
                      className="menu-link"
                    >
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Angle-right.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Penulis Terverifikasi</span>
                    </Link>
                  </li>
                  <li
                    aria-haspopup="true"
                    data-menu-toggle="hover"
                    className={`menu-item ${getSubmenuItemActive(
                      "/admin/pm/list-user/applicants"
                    )} ${isApplicantPage ? "menu-item-active" : ""}`}
                  >
                    <Link
                      to="/admin/pm/list-user/applicants"
                      className="menu-link"
                    >
                      <span className="svg-icon menu-icon">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Navigation/Angle-right.svg"
                          )}
                        />
                      </span>
                      <span className="menu-text">Pemohon</span>
                    </Link>
                  </li>
                </ul>
              </div>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/pm/company-list",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className={`menu-link ${menu === "company" && "active"}`}
                to="/admin/pm/company-list"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/ic-f-graph.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Manajemen Klien</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/pm/list-pm",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className={`menu-link ${menu === "pm" && "active"}`}
                to="/admin/pm/list-pm"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/ic-f-shield.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Manajemen PM</span>
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/pm/list-product",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className={`menu-link ${menu === "product" && "active"}`}
                to="/admin/pm/list-product"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/ic-f-paper.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Manajemen Produk</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/pm/list-information",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className={`menu-link ${menu === "information" && "active"}`}
                to="/admin/pm/list-information"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/icon_pencil.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Manajemen Informasi Terbaru</span>
              </NavLink>
            </li>

            {/* HIDE UNTIL NEXT SPRINT */}
            {/* <li
              className={`menu-item ${getMenuItemActive(
                "/admin/pm/list-articles",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className={`menu-link`} to="/admin/pm/list-articles">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/ic-f-bookmark.svg")}
                  />
                </span>
                <span className="menu-text">Manajemen Kupon</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/pm/list-articles",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className={`menu-link`} to="/admin/pm/list-articles">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/ic-f-swap.svg")}
                  />
                </span>
                <span className="menu-text">Manajemen Kategori Rilis</span>
              </NavLink>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                "/admin/pm/list-articles",
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink className={`menu-link`} to="/admin/pm/list-articles">
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/General/ic-f-ticket.svg")}
                  />
                </span>
                <span className="menu-text">Manajemen Bantuan</span>
              </NavLink>
            </li> */}
          </>
        )}
      </ul>
    </>
  );
}
