import { call, put } from "redux-saga/effects";
import {
  GET_PIC_LIST_FAILURE,
  GET_PIC_LIST_SUCCESS,
  ADD_PIC_FAILURE,
  ADD_PIC_SUCCESS,
  GET_PIC_DETAIL_FAILURE,
  GET_PIC_DETAIL_SUCCESS,
  UPDATE_PIC_FAILURE,
  UPDATE_PIC_SUCCESS,
  DELETE_PIC_FAILURE,
  DELETE_PIC_SUCCESS,
  ASSIGN_PIC_FAILURE,
  ASSIGN_PIC_SUCCESS,
} from "../constant/clientPicConstant";
import { getToken } from "../service/firebase/auth";
import { addPic, assignPic, deletePic, getPicList, updatePic } from "../service/api/clientPicApi";
import { getPicDetail } from "../service/firebase/clientPic";


export function* getPICListSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getPicList, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_PIC_LIST_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_PIC_LIST_FAILURE, data });
  }
}

export function* addPICSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(addPic, token, action.data);
  if (ok) {
    yield put({ type: ADD_PIC_SUCCESS });
  } else {
    yield put({ type: ADD_PIC_FAILURE, data });
  }
}

export function* updatePICSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(updatePic, token, action.id, action.data);
  if (ok) {
    yield put({ type: UPDATE_PIC_SUCCESS });
  } else {
    yield put({ type: UPDATE_PIC_FAILURE, data });
  }
}

export function* deletePICSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(deletePic, token, action.id);
  if (ok) {
    yield put({ type: DELETE_PIC_SUCCESS });
  } else {
    yield put({ type: DELETE_PIC_FAILURE, data });
  }
}

export function* assignPICSaga(action) {
  const token = yield call(getToken);
  const res = yield call(assignPic, token, action.id, action.data);
  if (res?.ok) {
    yield put({ type: ASSIGN_PIC_SUCCESS });
  } else {
    yield put({ type: ASSIGN_PIC_FAILURE, data: res?.data });
  }
}

export function* getDetailPICSaga(action) {
  try {
    const snapshot = yield call(getPicDetail, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_PIC_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_PIC_DETAIL_FAILURE, err });
  }
}
