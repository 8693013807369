import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { detailClientAction } from "../../../redux/action/clientAction";
import { getBusinessAction } from "../../../redux/action/utilsAction";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import ProfileEditModule from "../../modules/Client/Profile/ProfileEditModule";

function ClientEditProfilePage() {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);
  useEffect(() => {
    titlePage({
      title: "IRIS - Edit Profil",
    });
    dispatch(detailClientAction(users?.user?.companyId));
    dispatch(getBusinessAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Edit Profil">
      <ProfileEditModule />
    </DashboardClientLayout>
  );
}

export default ClientEditProfilePage;
