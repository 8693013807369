/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import ListLoader from "../../../../components/loading/listLoader";
import ProductDetailForm from "./Components/ProductDetailForm";

const ProductDetailModule = (props) => {
  const history = useHistory();

  const { dataDetailProduct, loadingDetailProduct } = useSelector(
    (state) => state.service
  );

  return (
    <>
      <div className="p-d-flex p-jc-center detail-pm">
        <div className="card card-custom col-8 mb-4">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Detail Produk
                </span>
              </button>
            </h3>
            <Link
              to={`/admin/pm/edit-product/${props.productId}`}
              className="btn px-0 py-o ml-3 d-flex align-items-center text-primary"
            >
              <strong>Edit</strong>
            </Link>
          </div>
          {loadingDetailProduct ? (
            <ListLoader />
          ) : (
            <ProductDetailForm
              type={"detailpage"}
              dataProduct={dataDetailProduct}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductDetailModule;
