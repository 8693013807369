import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import AddArticleModule from "../../../modules/Admin/PreviewProject/AddArticleModule";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import { getProjectActiveAction } from "../../../../redux/action/projectAction";

const AddArticlePage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Tulisan",
    });
    dispatch(resetUtilsStateAction());
    dispatch(getProjectActiveAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Tulisan" menu="article">
      <AddArticleModule />
    </DashboardLayout>
  );
};

export default AddArticlePage;
