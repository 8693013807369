import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import ProductDetailModule from "../../../modules/Admin/Superadmin/ProductManagement/ProductDetailModule";
import { getProductDetailAction } from "../../../../redux/action/serviceAction";

const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const queryParams = useParams();
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Produk",
    });
    dispatch(getProductDetailAction(queryParams.productId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Produk" menu="product">
      <ProductDetailModule productId={queryParams.productId} />
    </DashboardLayout>
  );
};

export default ProductDetailPage;
