/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import CloseIcon from "@material-ui/icons/Close";

import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import ButtonDefault from "../../../components/button/ButtonDefault";
import {
  addHelpAction,
  resetHelpAction,
} from "../../../../redux/action/helpAction";
import DialogAlert from "../../../components/dialog/DialogAlert";

const AddHelpModule = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { dataHelpCategory } = useSelector(
    (state) => state.utils
  );
  const { loadingAddHelp, successAddHelp, errAddHelp } = useSelector(
    (state) => state.help
  );
  const [isShowHint, setIsShowHint] = useState(true);
  const [categoryState, setCategoryState] = useState({
    value: null,
    error: null,
  });
  const [titleState, setTitleState] = useState({ value: "", error: null });
  const [descriptionState, setDescriptionState] = useState({
    value: "",
    error: null,
  });
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");

  useEffect(() => {
    if (successAddHelp) {
      setSuccessDialog(true);
    }
  }, [successAddHelp]);

  useEffect(() => {
    if (errAddHelp) {
      setErrorDialog(true);
      setDialogText(errAddHelp?.message);
    }
  }, [errAddHelp]);

  const closeSuccess = () => {
    setSuccessDialog(false);
    dispatch(resetHelpAction());
    history.push("/client/help");
  };

  const closeError = () => {
    setErrorDialog(false);
    dispatch(resetHelpAction());
  };

  const submitHelp = () => {
    const data = {
      title: titleState.value,
      category: categoryState.value,
      description: descriptionState.value,
      status: "open"
    };
    if (!titleState.value) {
      setTitleState({ ...titleState, error: "Judul tiket harus diisi" });
    }
    if (!descriptionState.value) {
      setDescriptionState({
        ...descriptionState,
        error: "Deskripsi harus diisi",
      });
    }
    if (!categoryState.value) {
      setCategoryState({
        ...categoryState,
        error: "Pilih salah satu kategori bantuan",
      });
    }
    if (titleState.value && descriptionState.value && categoryState.value) {
      dispatch(addHelpAction(data));
    }
  };

  return (
    <>
      <div className="card card-custom add-help">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              <span
                onClick={() => history.goBack()}
                className="icon-back mr-2"
              ></span>
              Tambah Pertanyaan
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="form-add-help">
            <div className="row">
              <div className="p-field col-6">
                <label className="p-d-block">Judul</label>
                <InputText
                  value={titleState.value}
                  onChange={(e) =>
                    setTitleState({ value: e.target.value, error: null })
                  }
                  placeholder="Judul Tiket"
                />
                {titleState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {titleState.error}
                  </small>
                )}
              </div>
              <div className="p-field col-6">
                <label className="p-d-block">Pilih Kategori Bantuan</label>
                <Dropdown
                  value={categoryState.value}
                  options={dataHelpCategory}
                  onChange={(e) =>
                    setCategoryState({ value: e.target.value, error: null })
                  }
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Pilih Kategori"
                />
                {categoryState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {categoryState.error}
                  </small>
                )}
              </div>
            </div>
            <div className="p-field">
              <label className="p-d-block">Deskripsi</label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setDescriptionState({ value: e.target.value, error: null })
                }
                value={descriptionState.value}
                placeholder="Deskripsi Tiket"
              />
              {descriptionState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {descriptionState.error}
                </small>
              )}
            </div>
            <div className="p-d-flex p-jc-end mb-5">
              <ButtonDefault
                className="p-button-primary p-button-rounded sm-rounded"
                label="KIRIM PERTANYAAN"
                onClick={submitHelp}
                loading={loadingAddHelp}
              />
            </div>
            <div className="p-d-flex p-jc-end">
              <div className={`hint add-help ${!isShowHint ? "d-none" : ""}`}>
                <div className="tail"></div>
                <div className="p-d-flex p-jc-center">
                  <p>
                    <strong>
                      Klik Button untuk mengirim pertanyaan ke PM.
                    </strong>{" "}
                    <br />
                    Anda tidak bisa mengubah pertanyaan yang telah anda buat.
                  </p>
                  <CloseIcon onClick={() => setIsShowHint(!isShowHint)} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type="success"
        title="Berhasil mengirimkan pertanyaan"
        text="Pertanyaan berhasil dikirim ke admin IRIS"
        closeDialog={closeSuccess}
        visible={successDialog}
      />
      <DialogAlert
        type="danger"
        title="Gagal mengirimkan pertanyaan"
        text={dialogText}
        closeDialog={closeError}
        visible={errorDialog}
      />
    </>
  );
};

export default AddHelpModule;
