import React, { useEffect } from "react";

import { titlePage } from "../../helpers/titleHead";
import UpdateSettingsModule from "../../modules/Client/Settings/UpdateSettingsModule";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import { useParams } from "react-router";

const ChangeDetailPage = () => {
  const QueryParams = useParams()
  useEffect(() => {
    titlePage({
      title: "IRIS - Ubah Informasi Akun",
    });
  }, []);

  return (
    <DashboardClientLayout title="Ubah Pengaturan" mobileTitle={QueryParams?.changedDetail === "password" ? "Ubah Kata Sandi" : "Ubah Email"}>
      <UpdateSettingsModule/>
    </DashboardClientLayout>
  );
};

export default ChangeDetailPage;
