import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TitleSection from "./Components/TitleSection";
import ContentSection from "./Components/ContentSection";
import PreviewContent from "./Components/PreviewSection";
import DialogAlert from "../../../components/dialog/DialogAlert";
import FullPageContent from "../../../components/loading/fullContentLoader";
import StatusProject from "../../../components/badge/StatusProject";
import ButtonDefault from "../../../components/button/ButtonDefault";

import {
  updateInfoAction,
  resetStateAdminAction,
} from "../../../../redux/action/adminAction";

const DetailInfoModule = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [titleValue, setTitleValue] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [contentValue, setContentValue] = useState("");
  const [contentCount, setContentCount] = useState("");

  const [isUpdate, setIsUpdate] = useState(false);
  const [openAlert, setOpenAlert] = useState({
    dialog: false,
    type: "",
    title: "",
    text: "",
  });

  const {
    loadingDetailInfo,
    dataDetailInfo,
    loadingUpdateInfo,
    successUpdateInfo,
    errorUpdateInfo,
  } = useSelector((state) => state.admin);

  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );

  useEffect(() => {
    if (dataDetailInfo?.status === "draft") {
      setIsUpdate(true);
    } else {
      setIsUpdate(false);
    }
    const counter = dataDetailInfo?.content
      ?.replace(/<[^>]*>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    setContentCount(counter);
    dispatch(resetStateAdminAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailInfo]);

  useEffect(() => {
    if (successUploadImage) {
      setImageValue(dataUploadImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    if (isUpdate) {
      setTitleValue(dataDetailInfo?.title);
      setContentValue(dataDetailInfo?.content);
      if (dataDetailInfo?.attachments) {
        setImageValue(dataDetailInfo?.attachments?.url);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdate]);

  useEffect(() => {
    if (successUpdateInfo) {
      setOpenAlert({
        type: "success",
        dialog: true,
        title: `Berhasil ${isUpdate ? "Publish" : "Unpublish"} Informasi`,
        text: `Informasi terbaru berhasil di ${
          isUpdate ? "Publish" : "Unpublish"
        }`,
      });

      setIsUpdate(!isUpdate);
    }
    if (errorUpdateInfo) {
      setOpenAlert({
        type: "error",
        dialog: true,
        title: `Gagal ${isUpdate ? "Publish" : "Unpublish"} Informasi`,
        text: `Informasi terbaru gagal di ${
          isUpdate ? "Publish" : "Unpublish"
        }`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateInfo, errorUpdateInfo]);

  // Function Write Article
  const changeTitle = (value) => {
    setTitleValue(value);
  };
  const changeContent = (value) => {
    setContentValue(value);
    const counter = value
      ?.replace(/<[^>]*>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    setContentCount(counter);
  };

  const deleteImage = () => {
    setImageValue(null);
  };

  const closeAlert = () => {
    setOpenAlert({
      dialog: false,
      type: "",
      title: "",
      text: "",
    });
    if (successUpdateInfo) {
      dispatch(resetStateAdminAction());
    } else if (errorUpdateInfo) {
      dispatch(resetStateAdminAction());
    }
  };

  const openDialogAlert = (state) => {
    if (state === "remove") {
      setOpenAlert({
        dialog: true,
        type: "warning",
        title: "UNPUBLISH INFORMASI?",
        text: "Informasi ini akan hilang dari landingpage.",
        actionText: "UNPUBLISH",
        actionDialog: () => updateInfo(state),
      });
    } else if (state === "save") {
      setOpenAlert({
        dialog: true,
        type: "warning",
        title: "PUBLISH INFORMASI?",
        text: "Pastikan Informasi yang anda tulis sudah sesuai",
        actionText: "PUBLISH",
        actionDialog: () => updateInfo(state),
      });
    }
  };

  const updateInfo = (state) => {
    if (state === "remove") {
      const data = {
        status: "draft",
      };
      dispatch(updateInfoAction(props?.articleId, data));
    } else if (state === "save") {
      if (titleValue === "") {
        setOpenAlert({
          dialog: true,
          type: "error",
          title: "Judul Informasi Kosong",
          text: "Silahkan tulis judul informasi terlebih dahulu",
        });
      } else if (contentCount?.split(" ").length < 1) {
        setOpenAlert({
          dialog: true,
          type: "error",
          title: "Informasi Kosong",
          text: "Silahkan tulis informasi terlebih dahulu",
        });
      } else {
        const image =
          imageValue !== undefined || imageValue === []
            ? { type: "image", url: imageValue }
            : null;
        const data = {
          title: titleValue,
          content: contentValue,
          attachments: image,
          status: "publish",
        };
        dispatch(updateInfoAction(props?.articleId, data));
      }
    }
  };

  return (
    <>
      <div className="card-header p-d-flex p-jc-between p-ai-center">
        <div className="p-d-flex p-jc-start p-ai-center">
          <button onClick={() => history.goBack()} className="button-back">
            <span className="icon-back"></span>
          </button>

          <h3 className="card-title align-items-start flex-column mb-0 ml-3">
            <span className="card-label font-weight-bolder text-dark">
              {dataDetailInfo?.title}
            </span>
          </h3>
        </div>
        <div className="p-d-flex p-jc-start p-ai-center">
          <StatusProject status={isUpdate ? "draft" : "publish"} />
        </div>
      </div>
      <div className="card-body p-0">
        <div className="article-preview">
          <div className={`section-article no-scroll w-100`}>
            {loadingDetailInfo ? (
              <div className="px-5">
                <FullPageContent />
                <FullPageContent />
              </div>
            ) : (
              <>
                {isUpdate ? (
                  <div className="section-article-box">
                    <div className="section-article-header">
                      <TitleSection
                        titleValue={titleValue}
                        imageValue={imageValue}
                        changeTitle={(e) => changeTitle(e.target.value)}
                        deleteImage={() => deleteImage()}
                      />
                    </div>
                    <div className="section-article-body">
                      <ContentSection
                        contentValue={contentValue}
                        changeContent={changeContent}
                      />
                    </div>
                  </div>
                ) : (
                  <PreviewContent articleData={dataDetailInfo} />
                )}

                <div className="section-article-footer">
                  <div className="write-note">
                    <div className="write-count">
                      {contentCount === ""
                        ? 0
                        : contentCount?.split(" ").length}{" "}
                      Kata
                    </div>
                  </div>
                  <div className="write-button">
                    {isUpdate ? (
                      <ButtonDefault
                        onClick={() => openDialogAlert("save")}
                        label="PUBLISH INFORMASI"
                        type="rounded"
                      />
                    ) : (
                      <ButtonDefault
                        onClick={() => openDialogAlert("remove")}
                        label="UNPUBLISH INFORMASI"
                        type="rounded"
                        color="danger"
                      />
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <DialogAlert
        type={openAlert?.type}
        actionDialog={openAlert.actionDialog}
        actionText={openAlert?.actionText}
        visible={openAlert?.dialog}
        closeDialog={() => closeAlert()}
        title={openAlert?.title}
        text={openAlert?.text}
        loading={loadingUpdateInfo}
      />
    </>
  );
};

export default DetailInfoModule;
