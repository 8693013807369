import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import TextBody from "../../../../../components/datatableBody/TextBody";
import DataEmpty from "../../../../../components/emptyState/dataEmpty";
import { getUsersAction } from "../../../../../../redux/action/userAction";

const TablePIC = (props) => {
  const dispatch = useDispatch();

  const { dataGetUsers } = useSelector((state) => state.user);

  useEffect(() => {
    if (props.companyId) {
      const params = {
        page: 1,
        perpage: 20,
        type: "pic",
        companyId: props.companyId,
      };
      dispatch(getUsersAction(params));
    }
  });

  const nameBodyTemplate = (item) => {
    return <TextBody text={item?.name} />;
  };

  const emailBodyTemplate = (item) => {
    return <TextBody text={item?.email} />;
  };
  const phoneBodyTemplate = (item) => {
    return <TextBody text={item?.phone?.replace("+62", "0")} />;
  };

  return (
    <>
      {dataGetUsers === null || dataGetUsers.length < 1 ? (
        <DataEmpty />
      ) : (
        <DataTable value={dataGetUsers} className="no-padding">
          <Column header="Nama Tim" body={nameBodyTemplate}></Column>
          <Column
            header="Email"
            body={emailBodyTemplate}
            field="commpanyEmail"
          ></Column>
          <Column
            header="No. Telepon"
            body={phoneBodyTemplate}
            field="nohp"
          ></Column>
        </DataTable>
      )}
    </>
  );
};

export default TablePIC;
