import { call, put } from "redux-saga/effects";
import {
  WRITER_WITHDRAW_FAILURE,
  WRITER_WITHDRAW_SUCCESS,
  WRITER_TRANSACTION_FAILURE,
  WRITER_TRANSACTION_SUCCESS,
  LIST_WRITER_PAYMENT_FAILURE,
  LIST_WRITER_PAYMENT_SUCCESS,
  DETAIL_WRITER_PAYMENT_FAILURE,
  DETAIL_WRITER_PAYMENT_SUCCESS,
  LIST_PAYOUT_FINANCE_FAILURE,
  LIST_PAYOUT_FINANCE_SUCCESS,
} from "../constant/transactionConstant";

import { getToken } from "../service/firebase/auth";
import {
  writerWithdraw,
  getWriterTransaction,
  getWriterPayment,
  detailWriterPayment,
  getFinancePayout,
} from "../service/api/financeApi";

export function* writerWithdrawSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(writerWithdraw, token, action.data);
  if (ok) {
    yield put({ type: WRITER_WITHDRAW_SUCCESS });
  } else {
    yield put({ type: WRITER_WITHDRAW_FAILURE, data });
  }
}

export function* writerTransactionSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getWriterTransaction, token, action.params);
  if (ok) {
    yield put({ type: WRITER_TRANSACTION_SUCCESS, data });
  } else {
    yield put({ type: WRITER_TRANSACTION_FAILURE, data });
  }
}

export function* listWriterPaymentSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getWriterPayment, token, action.params);
  if (ok) {
    yield put({ type: LIST_WRITER_PAYMENT_SUCCESS, data });
  } else {
    yield put({ type: LIST_WRITER_PAYMENT_FAILURE, data });
  }
}

export function* detailWriterPaymentSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(detailWriterPayment, token, action.id);
  if (ok) {
    yield put({ type: DETAIL_WRITER_PAYMENT_SUCCESS, data });
  } else {
    yield put({ type: DETAIL_WRITER_PAYMENT_FAILURE, data });
  }
}

export function* listPayoutFinanceSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getFinancePayout, token, action.params);
  if (ok) {
    yield put({ type: LIST_PAYOUT_FINANCE_SUCCESS, data });
  } else {
    yield put({ type: LIST_PAYOUT_FINANCE_FAILURE, data });
  }
}
