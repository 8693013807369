/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import PreviewProjectModule from "../../modules/Admin/PreviewProject/PreviewProjectModule";
import { titlePage } from "../../helpers/titleHead";

import { getArticleAction } from "../../../redux/action/projectAction";

const PreviewProjectWriter = (props) => {
  const dispatch = useDispatch();
  const QueryParams = useParams();

  const { dataGetArticle } = useSelector((state) => state.project);

  useEffect(() => {
    titlePage({
      title: "IRIS - Preview Artikel",
    });
  }, []);

  useEffect(() => {
    dispatch(getArticleAction(QueryParams.projectId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardUserLayout>
      <PreviewProjectModule
        articleData={dataGetArticle}
        chatId={QueryParams.projectId}
      />
    </DashboardUserLayout>
  );
};

export default PreviewProjectWriter;
