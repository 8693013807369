import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../helpers/phoneNumber";
import { formEmail } from "../../../helpers/email";

import ButtonLoader from "../../../components/button/ButtonLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";

import { applicantRegisterAction, checkPhoneAction, resetStateAuthAction } from "../../../../redux/action/authAction";
import {
  getDistrictAction,
  uploadImageAction,
  resetUtilsStateAction,
} from "../../../../redux/action/utilsAction";

const RegisterApplicantModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);

  const [emailState, setEmailState] = useState({ value: "", error: null });
  const [phoneState, setPhoneState] = useState({ value: "", error: null });
  const [nameState, setNameState] = useState({ value: "", error: null });
  const [addressState, setAddressState] = useState({ value: "", error: null });
  const [provinceState, setProvinceState] = useState({
    value: null,
    error: null,
  });
  const [districtState, setDistrictState] = useState({
    value: null,
    error: null,
  });
  const [noteState, setNoteState] = useState({ value: "", error: null });
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: null,
  });
  const [passwordConfirmState, setPasswordConfirmState] = useState({
    value: "",
    error: null,
  });
  const [provinceValue, setProvinceValue] = useState("");
  const [fileValue, setFileValue] = useState(null);
  const [fileName, setFileName] = useState("");
  const [errorImage, setErrorImage] = useState("");

  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogErrorText, setDialogErrorText] = useState('')
  const [errorCounter, setErrorCounter] = useState(0)

  const {
    loadingApplicantRegister,
    successApplicantRegister,
    errApplicantRegister,
    dataCheckPhone,
    loadingCheckPhone,
    errCheckPhone
  } = useSelector((state) => state.auth);
  const {
    dataGetProvince,
    dataGetDistrict,
    dataUploadImage,
    loadingUploadImage,
    successUploadImage,
  } = useSelector((state) => state.utils);

  useEffect(() => {
    if (successUploadImage) {
      setFileValue(dataUploadImage);
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (dataGetProvince !== null) {
      const dropdownItems = dataGetProvince?.map((item) => ({
        name: item.name,
        code: item.id,
      }));
      setProvinces(dropdownItems);
    }
  }, [dataGetProvince]);

  useEffect(() => {
    if (dataGetDistrict !== null) {
      const dropdownItems = dataGetDistrict?.map((item) => ({
        name: item.name,
        code: item.name,
      }));
      setDistricts(dropdownItems);
    }
  }, [dataGetDistrict]);

  useEffect(() => {
    if (successApplicantRegister) {
      setDialogSuccess(true);
    }
  }, [successApplicantRegister]);

  useEffect(() => {
    if (errApplicantRegister) {
      setDialogError(true);
      setDialogErrorText('Email sudah digunakan')
    }
  }, [errApplicantRegister]);

  useEffect(() => {
    if (errCheckPhone) {
      setDialogError(true)
      setDialogErrorText('Nomor telepon tidak valid. Periksa kembali nomor Anda')
    }
  }, [errCheckPhone])

  useEffect(() => {
    const elementAboveErrorHeight = 60
    const errorPosition = document.getElementById('error')?.offsetTop
    errorPosition && window.scrollTo(window.scrollX, errorPosition - elementAboveErrorHeight)

  }, [errorCounter])

  useEffect(() => {
    if (dataCheckPhone?.exists) {
      setDialogError(true)
      setDialogErrorText('Nomor telepon sudah digunakan')
    } else if (dataCheckPhone !== null && !dataCheckPhone?.exists && provinceState?.value) {
      const data = {
        email: emailState.value,
        phone: formattedPhoneNumber(phoneState.value),
        name: nameState.value,
        userMessage: noteState.value,
        domicile: {
          province: provinceValue,
          cities: districtState.value?.name,
          address: addressState.value,
        },
        portofolio: [
          {
            year: dayjs(new Date()).format("YYYY"),
            title: "Portofolio Saya",
            linkPortofolio: fileValue,
          },
        ],
        password: passwordState.value,
      }
      dispatch(applicantRegisterAction(data))
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dataCheckPhone])

  const handleProvince = (data) => {
    dispatch(getDistrictAction(parseInt(data.code)));
    setProvinceState({ value: data, error: null });
    setProvinceValue(data?.name);
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".pdf",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        setErrorImage("Ukuran file lebih dari 5MB");
      } else if (acceptedFiles.length > 1) {
        setErrorImage("Silakan pilih satu file");
      } else {
        setErrorImage("");
        setFileName(acceptedFiles[0].name);

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/user-data/${times}/applicants/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
      }
    },
  });

  const closeDialog = () => {
    setDialogSuccess(false);
    dispatch(resetUtilsStateAction());
    dispatch(resetStateAuthAction())
    history.push("/");
  };

  const closeDialogError = () => {
    setDialogError(false)
    dispatch(resetStateAuthAction())
  }

  const submitData = () => {

    if (!formEmail(emailState.value)) {
      setEmailState({ ...emailState, error: "Email tidak Valid" });
    }
    if (!formPhoneNumber(phoneState.value)) {
      setPhoneState({ ...phoneState, error: "Nomor telepon tidak Valid" });
    }
    if (nameState?.value?.length < 1) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    }
    if (addressState?.value?.length < 1) {
      setAddressState({ ...addressState, error: "Alamat harus diisi" });
    }
    if (provinceState?.value === null) {
      setProvinceState({ ...provinceState, error: "Provinsi harus diisi" });
    }
    if (districtState?.value === null) {
      setDistrictState({ ...districtState, error: "Kota harus diisi" });
    }
    if (passwordState?.value?.length < 6) {
      setPasswordState({
        ...passwordState,
        error: "Kata sandi minimal 6 karakter",
      });
    }
    if (passwordConfirmState?.value?.length < 1) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi harus diisi",
      });
    }
    if (passwordConfirmState?.value !== passwordState.value) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi tidak sama",
      });
    }
    if (fileValue === null) {
      setErrorImage("File harus diisi");
    }
    if (
      nameState?.value?.length > 0 &&
      emailState?.value?.length > 0 &&
      phoneState?.value?.length > 0 &&
      passwordState?.value?.length > 5 &&
      passwordConfirmState?.value === passwordState?.value &&
      provinceState?.value !== null &&
      districtState?.value !== null &&
      addressState?.value?.length > 0 &&
      fileValue !== null &&
      passwordConfirmState?.value === passwordState.value &&
      formEmail(emailState.value) &&
      formPhoneNumber(phoneState.value)
    ) {
      dispatch(checkPhoneAction({ phone: formattedPhoneNumber(phoneState.value) }))
    } else {
      setErrorCounter(errorCounter + 1)
    }
  };

  return (
    <>
      <div className="form-box p-py-4">
        <div className="p-field">
          <InputText
            id="name"
            aria-describedby="name"
            className={`p-d-block ${nameState.error && "p-invalid"}`}
            placeholder="Nama Lengkap*"
            value={nameState.value}
            onChange={(e) =>
              setNameState({ value: e.target.value, error: null })
            }
          />
          {nameState.error && (
            <small id="error" className="p-error p-d-block p-text-right">
              {nameState.error}
            </small>
          )}
        </div>

        <div className="p-field">
          <InputText
            id="email"
            aria-describedby="email"
            type="email"
            placeholder="Email*"
            className={`p-d-block ${emailState.error && "p-invalid"}`}
            value={emailState.value}
            onChange={(e) =>
              setEmailState({ value: e.target.value, error: null })
            }
          />
          {emailState.error && (
            <small id="error" className="p-error p-d-block p-text-right">
              {emailState.error}
            </small>
          )}
        </div>

        <div className="p-field">
          <InputText
            id="noHp"
            aria-describedby="noHp"
            placeholder="No.Telepon*"
            className={`p-d-block ${phoneState.error && "p-invalid"}`}
            value={phoneState.value}
            onChange={(e) =>
              setPhoneState({ value: e.target.value, error: null })
            }
          />
          {phoneState.error && (
            <small id="error" className="p-error p-d-block p-text-right">
              {phoneState.error}
            </small>
          )}
        </div>

        <div className="p-field">
          <InputText
            id="password"
            aria-describedby="password"
            placeholder="Kata Sandi*"
            type="password"
            className={`p-d-block ${passwordState.error && "p-invalid"}`}
            value={passwordState.value}
            onChange={(e) =>
              setPasswordState({ value: e.target.value, error: null })
            }
          />
          {passwordState.error && (
            <small id="error" className="p-error p-d-block p-text-right">
              {passwordState.error}
            </small>
          )}
        </div>

        <div className="p-field">
          <InputText
            id="passwordConfirm"
            aria-describedby="passwordConfirm"
            placeholder="Konfirmasi Kata Sandi*"
            type="password"
            className={`p-d-block ${passwordConfirmState.error && "p-invalid"}`}
            value={passwordConfirmState.value}
            onChange={(e) =>
              setPasswordConfirmState({ value: e.target.value, error: null })
            }
          />
          {passwordConfirmState.error && (
            <small
              id="error"
              className="p-error p-d-block p-text-right"
            >
              {passwordConfirmState.error}
            </small>
          )}
        </div>

        <div className="p-field">
          <Dropdown
            options={provinces}
            value={provinceState.value}
            className={`dropdown-register ${provinceState.error &&
              "p-invalid"}`}
            filter
            filterBy="name"
            optionLabel="name"
            onChange={(e) => handleProvince(e.value)}
            placeholder="Pilih Provinsi*"
            showOnFocus={true}
          />
          {provinceState.error && (
            <small
              id="error"
              className="p-error p-d-block p-text-right"
            >
              {provinceState.error}
            </small>
          )}
        </div>

        <div className="p-field">
          <Dropdown
            options={districts}
            className={`dropdown-register ${districtState.error &&
              "p-invalid"}`}
            filter
            filterBy="name"
            optionLabel="name"
            value={districtState.value}
            onChange={(e) =>
              setDistrictState({ value: e.target.value, error: null })
            }
            disabled={districts?.length < 1}
            placeholder="Pilih Kota*"
          />
          {districtState.error && (
            <small
              id="error"
              className="p-error p-d-block p-text-right"
            >
              {districtState.error}
            </small>
          )}
        </div>
        <div className="p-field">
          <InputTextarea
            placeholder="Alamat Lengkap*"
            rows={5}
            cols={30}
            value={addressState.value}
            onChange={(e) =>
              setAddressState({ value: e.target.value, error: null })
            }
          />
          {addressState.error && (
            <small id="error" className="p-error p-d-block p-text-right">
              {addressState.error}
            </small>
          )}
        </div>
        <div className="p-field">
          <div {...getRootProps({ className: "file-upload" })}>
            <input {...getInputProps()} />
            <span className="file-name">
              {fileValue !== null ? fileName : "Unggah PDF Portofoliomu"}
            </span>
            {loadingUploadImage ? (
              <span className="file-button">Mengunggah...</span>
            ) : (
              <span className="file-button" onClick={open}>
                {fileValue !== null ? "Ubah File" : "Pilih File"}
              </span>
            )}
          </div>
          {errorImage && (
            <small id="error" className="p-error p-d-block p-text-right">
              {errorImage}
            </small>
          )}
        </div>
        <div className="p-field">
          <InputTextarea
            placeholder="Tulis Pesan(Opsional)"
            rows={5}
            cols={30}
            value={noteState.value}
            onChange={(e) =>
              setNoteState({ value: e.target.value, error: null })
            }
            className="mt-3"
          />
          {noteState.error && (
            <small id="error" className="p-error p-d-block p-text-right">
              {noteState.error}
            </small>
          )}
        </div>
        {loadingApplicantRegister || loadingCheckPhone ? (
          <ButtonLoader />
        ) : (
          <Button
            label="Daftar"
            className="p-button-rounded p-button-lg"
            onClick={() => submitData()}
          />
        )}
      </div>

      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeDialog()}
        title={"Berhasil Mendaftarkan Akun"}
        text={
          "Selamat pendaftaran anda berhasil, silakan tunggu review dari Admin dalam waktu maksimal 7 x 24 jam melalui email "
        }
      />
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={closeDialogError}
        title={"Gagal Mendaftarkan Akun"}
        text={dialogErrorText}
      />
    </>
  );
};

export default RegisterApplicantModule;
