import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import DateBody from "../../../components/datatableBody/DateBody";
import NumberBody from "../../../components/datatableBody/NumberBody";

import StatusBadgeBody from "../../../components/datatableBody/StatusBadgeBody";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import FullContentLoader from "../../../components/loading/fullContentLoader";

const DetailPaymentModule = (props) => {
  const { isFinance } = props;
  const { loadingDetailWriterPayment, dataDetailWriterPayment } = useSelector(
    (state) => state.transaction
  );

  const pendapatanTemplate = (item) => {
    return <NumberBody number={item?.amount || 0} isPrice />;
  };

  const statusBodyTemplate = (item) => {
    return <StatusBadgeBody status={item.status} userType={"pm"} />;
  };

  const dateBodyTemplate = (item) => {
    return <DateBody date={item?.createdAt} />;
  };

  const datePayBodyTemplate = (item) => {
    return <DateBody date={item?.createdAt} />;
  };

  return (
    <div className="card card-custom">
      <div className="card-header border-0 py-5">
        <h5 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            <Link
              to={`/admin/${isFinance ? "finance" : "pm"}/list-payment`}
              className="btn-back dashboard-back"
            >
              <span className="icon-back"></span>
              Detail Pembayaran
            </Link>
          </span>
        </h5>
      </div>
      {loadingDetailWriterPayment ? (
        <div className="card-body pt-0 pb-3">
          <FullContentLoader />
          <FullContentLoader />
          <FullContentLoader />
          <FullContentLoader />
        </div>
      ) : (
        <>
          <div className="card-body py-2 mb-5 border-bottom">
            <div className="payment-info-list">
              <div className="payment-info-item">
                <h6>Nama Penulis</h6>
                <p>{dataDetailWriterPayment?.writerName}</p>
              </div>
              <div className="payment-info-item">
                <h6>Nomor Rekening</h6>
                <p>{dataDetailWriterPayment?.bankAccountNumber}</p>
                <p className="bank-text">
                  ({dataDetailWriterPayment?.bankName})
                </p>
              </div>
              <div className="payment-info-item">
                <h6>Total Pendapatan</h6>
                <NumberFormat
                  prefix="Rp"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={
                    dataDetailWriterPayment?.totalBalance === null
                      ? 0
                      : dataDetailWriterPayment?.totalBalance
                  }
                  renderText={(value) => <p>{`${value}`}</p>}
                />
              </div>
              <div className="payment-info-item">
                <h6>Saldo Akhir</h6>
                <NumberFormat
                  prefix="Rp"
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={
                    dataDetailWriterPayment?.balanceNow === null
                      ? 0
                      : dataDetailWriterPayment?.balanceNow
                  }
                  renderText={(value) => <p>{`${value}`}</p>}
                />
              </div>
            </div>
          </div>
          <div className="card-body pt-0 pb-3">
            {dataDetailWriterPayment?.listPayout?.length < 1 ? (
              <DataEmpty />
            ) : (
              <DataTable
                value={dataDetailWriterPayment?.listPayout}
                className="no-padding"
              >
                <Column
                  header="Tanggal Request"
                  body={dateBodyTemplate}
                ></Column>
                <Column
                  header="Jumlah Withdraw"
                  body={pendapatanTemplate}
                ></Column>
                <Column
                  header="Tanggal Pembayaran"
                  body={datePayBodyTemplate}
                ></Column>
                <Column header="Status" body={statusBodyTemplate}></Column>
              </DataTable>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default DetailPaymentModule;
