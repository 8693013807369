export const formattedPhoneNumber = (phone) => {
  const removingDash = phone.replace(/-/g, "");
  const removingUnderscore = removingDash.replace(/_/g, "");
  const finalReturn =
    "+62" + removingUnderscore.slice(1, removingUnderscore.length);
  return finalReturn;
};

export const formPhoneNumber = (input) => {
  const checkPhone = /^(^0)(\d{8,14})$/g;
  return checkPhone.test(input);
};

export const filterSearch = (data) => {
  return data.replace(/^0\d+?$/g, `+62${data?.substr(1)}`);
};

export const phoneNumberRepelace = (data) => {
  return data?.replace("+62", "0");
};
