import {
  AUTO_COMPLETE_COMPANY_FAILURE,
  AUTO_COMPLETE_COMPANY_SUCCESS,
  AUTO_COMPLETE_COMPANY_REQUEST,
  CLIENT_STATE_RESET,
  GET_CLIENT_FAILURE,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_REQUEST,
  GET_CLIENT_DETAIL_FAILURE,
  GET_CLIENT_DETAIL_SUCCESS,
  GET_CLIENT_DETAIL_REQUEST,
  POST_CLIENT_FAILURE,
  POST_CLIENT_SUCCESS,
  POST_CLIENT_REQUEST,
  UPDATE_CLIENT_FAILURE,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_REQUEST,
  UPDATE_COMPANY_FAILURE,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_REQUEST,
  DELETE_CLIENT_FAILURE,
  DELETE_CLIENT_SUCCESS,
  DELETE_CLIENT_REQUEST,
} from "../constant/clientConstant";

const initialStateClient = {
  clientParams: {
    q: "",
    page: 1,
    perpage: 10,
  },
  totalPage: "",
  loadingAutoCompleteCompanies: false,
  dataAutoCompleteCompanies: null,
  errAutoCompleteCompanies: null,

  loadingGetClient: false,
  dataGetClient: null,
  errGetClient: null,

  loadingPostClient: false,
  successPostClient: false,
  errPostClient: null,

  loadingDetailClient: false,
  dataDetailClient: null,
  errDetailClient: null,

  loadingUpdateClient: false,
  successUpdateClient: false,
  errUpdateClient: null,

  loadingDeleteClient: false,
  successDeleteClient: false,
  errDeleteClient: null,

  loadingUpdateCompany: false,
  successUpdateCompany: false,
  errUpdateCompany: null,

};

export const clientReducer = (state = initialStateClient, action) => {
  switch (action.type) {
    case CLIENT_STATE_RESET:
      return {
        ...state,
        loadingAutoCompleteCompanies: false,
        dataAutoCompleteCompanies: null,
        errAutoCompleteCompanies: null,
        loadingUpdateClient: false,
        successUpdateClient: false,
        errUpdateClient: null,
        loadingDeleteClient: false,
        successDeleteClient: false,
        errDeleteClient: null,
        loadingPostClient: false,
        successPostClient: false,
        errPostClient: null,
      };

    case GET_CLIENT_REQUEST:
      return {
        ...state,
        loadingGetClient: true,
        errGetClient: null,
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        loadingGetClient: false,
        dataGetClient: action.data.data,
        errGetClient: null,
        totalPage: action.data.totalpage,
      };
    case GET_CLIENT_FAILURE:
      return {
        ...state,
        loadingGetClient: false,
        errGetClient: action.data,
      };

    case AUTO_COMPLETE_COMPANY_REQUEST:
      return {
        ...state,
        loadingAutoCompleteCompanies: true,
        errAutoCompleteCompanies: null,
      };
    case AUTO_COMPLETE_COMPANY_SUCCESS:
      return {
        ...state,
        loadingAutoCompleteCompanies: false,
        dataAutoCompleteCompanies: action.data.data,
        errAutoCompleteCompanies: null,
      };
    case AUTO_COMPLETE_COMPANY_FAILURE:
      return {
        ...state,
        loadingAutoCompleteCompanies: false,
        errAutoCompleteCompanies: action.data,
      };

    case POST_CLIENT_REQUEST:
      return {
        ...state,
        loadingPostClient: true,
        successPostClient: false,
        errPostClient: null,
      };
    case POST_CLIENT_SUCCESS:
      return {
        ...state,
        loadingPostClient: false,
        successPostClient: true,
        errPostClient: null,
      };
    case POST_CLIENT_FAILURE:
      return {
        ...state,
        loadingPostClient: false,
        successPostClient: false,
        errPostClient: action.data,
      };

    case GET_CLIENT_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetailClient: true,
        errDetailClient: null,
      };
    case GET_CLIENT_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetailClient: false,
        dataDetailClient: action.data,
        errDetailClient: null,
      };
    case GET_CLIENT_DETAIL_FAILURE:
      return {
        ...state,
        loadingDetailClient: false,
        errDetailClient: action.data,
      };

    case UPDATE_CLIENT_REQUEST:
      return {
        ...state,
        loadingUpdateClient: true,
        successUpdateClient: false,
        errUpdateClient: null,
      };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        loadingUpdateClient: false,
        successUpdateClient: true,
        errUpdateClient: null,
      };
    case UPDATE_CLIENT_FAILURE:
      return {
        ...state,
        loadingUpdateClient: false,
        successUpdateClient: false,
        errUpdateClient: action.data,
      };

    case DELETE_CLIENT_REQUEST:
      return {
        ...state,
        loadingDeleteClient: true,
        successDeleteClient: false,
        errDeleteClient: null,
      };
    case DELETE_CLIENT_SUCCESS:
      return {
        ...state,
        loadingDeleteClient: false,
        successDeleteClient: true,
        errDeleteClient: null,
      };
    case DELETE_CLIENT_FAILURE:
      return {
        ...state,
        loadingDeleteClient: false,
        successDeleteClient: false,
        errDeleteClient: action.data,
      };

      case UPDATE_COMPANY_REQUEST:
      return {
        ...state,
        loadingUpdateCompany: true,
        successUpdateCompany: false,
        errUpdateCompany: null,
      };
    case UPDATE_COMPANY_SUCCESS:
      return {
        ...state,
        loadingUpdateCompany: false,
        successUpdateCompany: true,
        errUpdateCompany: null,
      };
    case UPDATE_COMPANY_FAILURE:
      return {
        ...state,
        loadingUpdateCompany: false,
        successUpdateCompany: false,
        errUpdateCompany: action.data,
      };

    default:
      return state;
  }
};
