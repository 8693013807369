import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const RichtextWithoutToolbar = (props) => {
  const modules = {
    toolbar: false,
  };

  return (
    <div className="form-write">
      <ReactQuill
        className="textarea-without-toolbar"
        value={props.contentValue}
        onChange={props.changeContent}
        modules={modules}
        {...props}
      />
    </div>
  );
};
export default RichtextWithoutToolbar;
