import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";
import ButtonDefault from "../../../../components/button/ButtonDefault";

import DialogPassword from "./PasswordConfirmation";
import OTPConfirmation from "./OTPConfirmation";
import DialogAlert from "../../../../components/dialog/DialogAlert";
import bankList from "./bankData.json";
import IconAtm from "../../../../assets/img/icon_atm_sign.svg";
import IconCC from "../../../../assets/img/icon_credit_card.svg";

import {
  getWriterBankAction,
  postWriterBankAction,
} from "../../../../../redux/action/userAction";

const AccountNumber = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [nameState, nameStateChange] = useState({
    value: "",
    error: null,
  });
  const [numberState, numberStateChange] = useState({
    value: "",
    error: null,
  });
  const [bankState, bankStateChange] = useState({
    value: "",
    error: null,
  });
  const [sectionType, setSectionType] = useState("empty");
  const [dialogPassword, setDialogPassword] = useState(false);
  const [dialogOtp, setDialogOtp] = useState(false);
  const [dialogAlert, setDialogAlert] = useState({
    type: null,
    text: null,
    title: null,
    dialog: false,
  });

  const {
    dataGetWriterBank,
    // loadingGetWriterBank,
    loadingPostWriterBank,
    successPostWriterBank,
    errPostWriterBank,
  } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getWriterBankAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataGetWriterBank !== null) {
      setSectionType("detail");
    } else {
      setSectionType("empty");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetWriterBank]);

  useEffect(() => {
    if (successPostWriterBank) {
      setDialogAlert({
        type: "success",
        text: "Berhasil!",
        title: "Berhasil Menambahkan Nomor Rekening",
        dialog: true,
      });
    }
    if (errPostWriterBank) {
      setDialogAlert({
        type: "error",
        text: "Gagal!",
        title: "Gagal Menambahkan Nomor Rekening",
        dialog: true,
      });
    }
  }, [successPostWriterBank, errPostWriterBank]);

  const submitData = () => {
    const data = {
      bankAccountNumber: numberState?.value,
      bankName: bankState?.value,
      holderName: nameState?.value,
    };

    if (nameState?.value?.length < 1) {
      nameStateChange({ ...nameState, error: "Nama lengkap harus diisi" });
    }
    if (numberState?.value?.length < 1) {
      numberStateChange({
        ...numberState,
        error: "Nomor Rekening harus diisi",
      });
    }
    if (
      numberState?.value?.length > 0 &&
      !numberState?.value?.match(/^[0-9]+$/)
    ) {
      numberStateChange({
        ...numberState,
        error: "Nomor Rekening tidak valid",
      });
    }
    if (bankState?.value?.length < 1) {
      bankStateChange({ ...bankState, error: "Nama bank harus diisi" });
    }

    if (
      nameState?.value?.length > 0 &&
      numberState?.value?.length > 0 &&
      numberState?.value?.match(/^[0-9]+$/) &&
      bankState?.value?.length > 0
    ) {
      dispatch(postWriterBankAction(data));
    }
  };

  const closeDialog = () => {
    if (dialogAlert.type === "success") {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
      setSectionType("detail");
    } else {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
    }
  };

  return (
    <>
      <div className="section bg-grey p-py-5 setting">
        <div className="p-d-flex p-jc-center">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-6">
            <div className="card card-custom-detail setting w-full has-shadow mb-5">
              <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                <div className="card-title">
                  <div
                    className="card-title-back"
                    onClick={() => history.goBack()}
                  >
                    <span className="icon-back"></span>
                  </div>
                  <h2 className="card-title-text">Rekening Bank</h2>
                </div>
              </div>
              {sectionType === "empty" ? (
                <div className="empty-bank-account">
                  <img src={IconAtm} alt="empty_bank_account" />
                  <p>Anda belum menambahkan rekening</p>
                  <ButtonDefault
                    label="TAMBAH REKENING"
                    type="rounded"
                    onClick={() => setSectionType("add")}
                  />
                </div>
              ) : (
                <>
                  <div className="form-update-setting">
                    <div className="p-d-flex p-jc-center">
                      {sectionType === "detail" && (
                        <img
                          className="image-card"
                          src={IconCC}
                          alt="card_bank_account"
                        />
                      )}
                    </div>
                    <div className="p-field pb-2">
                      <label className="p-d-block font-weight-bolder">
                        Nama Lengkap Pemilik Rekening
                      </label>

                      {sectionType === "add" ? (
                        <>
                          <InputText
                            type="text"
                            placeholder="Masukan Nama Lengkap"
                            className={`p-d-block ${nameState?.error &&
                              "p-invalid"}`}
                            value={nameState.value}
                            onChange={(e) =>
                              nameStateChange({
                                value: e.target.value,
                                error: null,
                              })
                            }
                          />
                          {nameState.error && (
                            <smal className="p-error p-d-block p-text-right">
                              {nameState.error}
                            </smal>
                          )}
                        </>
                      ) : (
                        <p className="text-card">
                          {dataGetWriterBank?.holderName}
                        </p>
                      )}
                    </div>
                    <div className="p-field pb-2">
                      <label className="p-d-block font-weight-bolder">
                        Nomor Rekening
                      </label>
                      {sectionType === "add" ? (
                        <>
                          <InputText
                            type="text"
                            placeholder="Masukan Nomor Rekening"
                            className={`p-d-block ${numberState?.error &&
                              "p-invalid"}`}
                            value={numberState.value}
                            onChange={(e) =>
                              numberStateChange({
                                value: e.target.value,
                                error: null,
                              })
                            }
                          />
                          {numberState.error && (
                            <smal className="p-error p-d-block p-text-right">
                              {numberState.error}
                            </smal>
                          )}
                        </>
                      ) : (
                        <p className="text-card">
                          {dataGetWriterBank?.bankAccountNumber}
                        </p>
                      )}
                    </div>
                    <div className="p-field pb-2">
                      <label className="p-d-block">Nama Bank</label>
                      {sectionType === "add" ? (
                        <>
                          <Dropdown
                            value={bankState.value}
                            options={bankList}
                            onChange={(e) =>
                              bankStateChange({
                                value: e.target.value,
                                error: null,
                              })
                            }
                            optionLabel="name"
                            optionValue="name"
                            placeholder="Pilih Jenis"
                            className="full-width"
                          />
                          {bankState.error && (
                            <small
                              id="name"
                              className="p-error p-d-block p-text-right"
                            >
                              {bankState.error}
                            </small>
                          )}
                        </>
                      ) : (
                        <p className="text-card">
                          {dataGetWriterBank?.bankName}
                        </p>
                      )}
                    </div>
                  </div>
                  {sectionType === "add" && (
                    <div className="udpate-setting-footer p-d-flex p-jc-end">
                      <ButtonDefault
                        label="batal"
                        onClick={() => history.goBack()}
                        type="rounded-bordered"
                        disabled={loadingPostWriterBank}
                      />
                      <ButtonDefault
                        label="tambah"
                        onClick={() => submitData()}
                        type="rounded"
                        disabled={loadingPostWriterBank}
                        loading={loadingPostWriterBank}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <DialogPassword
        visible={dialogPassword}
        closeDialog={() => setDialogPassword(false)}
      />

      <OTPConfirmation
        visible={dialogOtp}
        closeDialog={() => setDialogOtp(false)}
      />
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
};

export default AccountNumber;
