import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AuthLayout from "../../layouts/authLayout";
import RegisterBenefitModule from "../../modules/User/Register/RegisterBenefitModule";
import RegisterApplicantModule from "../../modules/User/Register/RegisterApplicantModule";

import { getProvincesAction } from "../../../redux/action/utilsAction";
import { titlePage } from "../../helpers/titleHead";
import LogoLoader from "../../components/loading/logoLoader";
import { getSettingSiteAction } from "../../../redux/action/landingpageAction";

const AccountRegisterPage = (props) => {
  const dispatch = useDispatch();

  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - Halaman Registrasi",
    });

    dispatch(getProvincesAction());

    !dataGetSettingSite && dispatch(getSettingSiteAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <div className="auth-wrapper">
        <div className="container">
          <div className="p-grid">
            <RegisterBenefitModule />
            <div className="col-lg-6 col-md-6 col-sm-0 col-xs-12 p-lg-12 p-md-0">
              <div className="auth-form auth-register p-d-flex p-jc-center">
                <div className="form-reg register">
                  <div className="p-text-center">
                    {loadingGetSettingSite ? (
                      <div className="loading">
                        <LogoLoader />
                      </div>
                    ) : (
                      <div className="logo">
                        <img src={dataGetSettingSite?.logo} alt="logo" />
                      </div>
                    )}
                    <h2>Registrasi Akun</h2>
                  </div>
                  <RegisterApplicantModule />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default AccountRegisterPage;
