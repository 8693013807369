import React from "react";
import { Accordion, AccordionTab } from "primereact/accordion";

const ProjectDetailAccordion = (props) => {
  const { activeIndex, onTabChange, dataProject } = props;
  const keywords = dataProject?.searchKeyword?.split(",");
  return (
    <Accordion
      className="accordion-project-detail"
      activeIndex={activeIndex}
      onTabChange={onTabChange}
    >
      <AccordionTab header="Brief Project">
        <div className="accordion-project-wrapper">
          <div className="row">
            <div className="col-12 col-sm-12">
              <h6>Nama Brand / Perusahaan</h6>
              <h2>{dataProject?.clientCompany?.name}</h2>
              <p>{dataProject?.clientCompany?.companyBackground}</p>
              <hr />
            </div>
            <div className="col-12 col-sm-6">
              <h6>Bidang Usaha</h6>
              <div className="box-text">
                {dataProject?.clientCompany?.businessField}
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <h6>Kata Kunci Pencarian</h6>
              <div className="d-flex flex-wrap">
                {keywords?.map((item) => (
                  <div className="box-text list" key={item}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-sm-12">
              <hr />
            </div>
            <div className="col-12 col-sm-12">
              <h3>Target Pembaca</h3>
            </div>
            <div className="col-12 col-sm-4">
              <h6>Usia</h6>
              <div className="box-text">{dataProject?.target?.age}</div>
            </div>
            <div className="col-12 col-sm-4">
              <h6>Jenis kelamin</h6>
              <div className="box-text">{dataProject?.target?.sex}</div>
            </div>
            <div className="col-12 col-sm-4">
              <h6>Stakeholder</h6>
              <div className="d-flex flex-wrap">
                {dataProject?.target?.stakeholders?.map((item) => (
                  <div className="box-text list" key={item}>
                    {item}
                  </div>
                ))}
              </div>
            </div>
            <div className="col-12 col-sm-12">
              <hr />
            </div>
            <div className="col-12 col-sm-12">
              <h3>Ruang Lingkup Rilis</h3>
            </div>
            {dataProject?.type === "pr" && (
              <>
                <div className="col-12 col-sm-4">
                  <h6>Kategori Rilis</h6>
                  <div className="box-text mb-5">
                    {dataProject?.releaseCategory}
                  </div>
                </div>
              </>
            )}

            <div className="col-12 col-sm-12">
              <h6>Objek Komunitas yang Ingin Dicapai</h6>
              <p>{dataProject?.objective || "Belum Tersedia"}</p>
              <h6>Atribut Quote dan Pesan yang Ingin Disampaikan</h6>
              <p>{dataProject?.attribution || "Belum Tersedia"}</p>
              <h6>Hal yang Diperhatikan dalam Penulisan Release</h6>
              <p>{dataProject?.releaseNote || "Belum Tersedia"}</p>
            </div>
          </div>
        </div>
      </AccordionTab>
    </Accordion>
  );
};
export default ProjectDetailAccordion;
