import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import IsoLogin from "../../assets/img/iso-login.svg";

import { titlePage } from "../../helpers/titleHead";
import { InputText } from "primereact/inputtext";
import queryString from "query-string";
import { useHistory, useLocation } from "react-router";
import {
  createNewPasswordAction,
  resetStateAuthAction,
} from "../../../redux/action/authAction";
import ButtonDefault from "../../components/button/ButtonDefault";
import DialogAlert from "../../components/dialog/DialogAlert";
import { getSettingSiteAction } from "../../../redux/action/landingpageAction";

const ResetPasswordPage = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search)?.token;

  const {
    loadingNewPassword,
    successNewPassword,
    errNewPassword,
    isLoggedIn,
    users,
  } = useSelector((state) => state.auth);
  const { dataGetSettingSite } = useSelector((state) => state.landingpage);
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: null,
  });
  const [passwordConfirmState, setPasswordConfirmState] = useState({
    value: "",
    error: null,
  });
  const [showDialogSuccess, setShowDialogSuccess] = useState(false);
  const [showDialogError, setShowDialogError] = useState(false);
  const [dialogText, setDialogText] = useState("");

  useEffect(() => {
    titlePage({
      title: "IRIS - Buat Password Baru",
    });
    dispatch(getSettingSiteAction());
    if (!parsed) {
      history.push("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsed, history]);

  useEffect(() => {
    if (successNewPassword) {
      setShowDialogSuccess(true);
    }
  }, [successNewPassword]);

  useEffect(() => {
    if (errNewPassword) {
      setShowDialogError(true);
      setDialogText(errNewPassword?.message);
    }
  }, [errNewPassword]);

  useEffect(() => {
    if (isLoggedIn && users?.user?.type === "writer") {
      history.push("/dashboard");
    }
    if (
      isLoggedIn &&
      (users?.user?.type === "pm" || users?.user?.type === "general_pm")
    ) {
      history.push("/admin/pm/list-project");
    }
    if (isLoggedIn && users?.user?.type === "client") {
      history.push("/client/dashboard");
    }
    if (isLoggedIn && users?.user?.type === "finance") {
      history.push("/admin/finance/list-invoice");
    }
  });

  useEffect(() => {
    if (!parsed) {
      history.push("/");
    }
  });

  const closeSuccess = () => {
    dispatch(resetStateAuthAction());
    setShowDialogSuccess(false);
    history.push("/login");
  };

  const closeDanger = () => {
    setShowDialogError(false);
  };

  const submitEmail = (e) => {
    e.preventDefault();
    const dataNewPassword = {
      token: parsed,
      password: passwordState.value,
    };
    if (passwordConfirmState.value.length < 6) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi minimal 6 karakter",
      });
    }
    if (!passwordConfirmState.value) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi harus diisi",
      });
    }
    if (passwordConfirmState.value !== passwordState.value) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi kata sandi tidak sama",
      });
    }
    if (passwordState.value.length < 6) {
      setPasswordState({
        ...passwordState,
        error: "Kata sandi minimal 6 karakter",
      });
    }
    if (!passwordState.value) {
      setPasswordState({
        ...passwordState,
        error: "Kata sandi tidak boleh kosong",
      });
    }
    if (
      passwordState.value === passwordConfirmState.value &&
      passwordState.value.length > 5 &&
      passwordConfirmState.value.length > 5 &&
      parsed
    ) {
      dispatch(createNewPasswordAction(dataNewPassword));
    }
  };

  return (
    <div className="auth-page">
      <div className="header-auth reset-password p-d-flex p-jc-center">
        <div className="p-grid p-d-flex p-jc-between p-ai-center"></div>
      </div>
      <div className="auth-wrapper login">
        <div className="container">
          <div className="p-grid">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lg-0 p-md-0">
              <div className="auth-form p-d-flex p-jc-center">
                <div className="form-reg">
                  <div className="p-text-center client-login-header reset-password">
                    <h2>Buat Password Baru</h2>
                    <div className="iris-logo">
                      <img src={dataGetSettingSite?.logo} alt="" />
                    </div>
                  </div>
                  <form onSubmit={(e) => submitEmail(e)}>
                    <div className="p-field mt-0">
                      <InputText
                        aria-describedby="phone-number"
                        className={`${passwordState.error !== null &&
                          "p-invalid"} p-d-block`}
                        placeholder="Masukkan Password Baru"
                        type="password"
                        value={passwordState.value}
                        onChange={(e) =>
                          setPasswordState({
                            value: e.target.value,
                            error: null,
                          })
                        }
                      />
                      {passwordState.error !== null && (
                        <small
                          id="phone-number"
                          className="p-error p-d-block p-text-right"
                        >
                          {passwordState.error}
                        </small>
                      )}
                    </div>

                    <div className="p-field my-2">
                      <InputText
                        aria-describedby="phone-number"
                        className={`${passwordConfirmState.error !== null &&
                          "p-invalid"} p-d-block`}
                        placeholder="Ulangi Password Baru "
                        type="password"
                        value={passwordConfirmState.value}
                        onChange={(e) =>
                          setPasswordConfirmState({
                            value: e.target.value,
                            error: null,
                          })
                        }
                      />
                      {passwordConfirmState.error !== null && (
                        <small
                          id="phone-number"
                          className="p-error p-d-block p-text-right"
                        >
                          {passwordConfirmState.error}
                        </small>
                      )}
                    </div>
                    <div className="p-d-flex">
                      <ButtonDefault
                        onClick={(e) => submitEmail(e)}
                        label="BUAT PASSWORD"
                        className="p-button rounded p-button-primary p-mt-3"
                        type="submit"
                        loading={loadingNewPassword}
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-0 col-xs-0 p-lg-0 p-md-0">
              <div className="iso-login">
                <img src={IsoLogin} alt="iso-login" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type="success"
        title="Berhasil mengubah kata sandi"
        text="Anda bisa menggunakan kata sandi baru Anda untuk login"
        closeDialog={closeSuccess}
        visible={showDialogSuccess}
      />
      <DialogAlert
        type="error"
        title="Gagal mengubah kata sandi"
        text={dialogText}
        closeDialog={closeDanger}
        visible={showDialogError}
      />
    </div>
  );
};

export default ResetPasswordPage;
