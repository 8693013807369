import React from "react";
import ContentLoader from "react-content-loader";

const FullContent = (props) => (
  <ContentLoader
    viewBox="0 0 100% 160"
    height={160}
    width={"100%"}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    backgroundOpacity={0.6}
    foregroundOpacity={0.8}
    {...props}
  >
    <rect x="0" y="13" rx="4" ry="4" width="100%" height="9" />
    <rect x="0" y="29" rx="4" ry="4" width="75%" height="8" />
    <rect x="0" y="50" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="65" rx="4" ry="4" width="100%" height="10" />
    <rect x="0" y="79" rx="4" ry="4" width="75%" height="10" />
  </ContentLoader>
);

export default FullContent;
