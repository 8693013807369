import React from "react";
import ListVerifiedModule from "../../../modules/Admin/UserVerified/ListVerifiedModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
const ListVerified = () => {
  return (
    <DashboardLayout title="Manajemen Penulis">
      <ListVerifiedModule />
    </DashboardLayout>
  );
};

export default ListVerified;
