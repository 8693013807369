import React, { useState } from "react";
import AuthLayout from "../../layouts/authLayout";
import { Button } from "primereact/button";
import Logo from "../../assets/img/logo.svg";
import Success from "../../assets/img/iso-success.svg";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import Select from "react-select";
import { Dialog } from "primereact/dialog";

const RegisterFormPage = (props) => {
  const options = [
    { value: "chocolate", label: "Chocolate" },
    { value: "strawberry", label: "Strawberry" },
    { value: "vanilla", label: "Vanilla" },
  ];
  const [displayBasic, setDisplayBasic] = useState(false);
  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  return (
    <AuthLayout>
      <div className="auth-wrapper">
        <div className="container">
          <div className="p-grid">
            <div className="p-col-6 p-px-0 p-py-0">
              <div className="auth-content left">
                <div className="box-content">
                  <h1>Manfaat bergabung bersama IRIS</h1>
                  <p>
                    Wujudkan impian menjadi penulis profesional dari sekarang!
                  </p>
                  <ul>
                    <li>
                      <span className="icon-deal"></span>Menerima penawaran
                      kerjaan secara rutin dari berbagai brand ternama
                    </li>
                    <li>
                      <span className="icon-happy"></span>Bekerja sesuai passion
                      dan bidang yang dikuasai
                    </li>
                    <li>
                      <span className="icon-connected"></span>Terhubung ke
                      banyak media lokal, nasional baik online maupun offline
                    </li>
                    <li>
                      <span className="icon-pen"></span>Mengasah kemampuan
                      menulis dan membangun portofolio secara profesional dengan
                      standar pembuatan rilis yang kredibel
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="p-col-6 p-px-0 p-py-0">
              <div className="auth-form p-d-flex p-jc-center p-pb-5">
                <div className="form-reg">
                  <div className="p-text-center">
                    <img src={Logo} alt="logo" />
                    <h2>Registrasi Akun</h2>
                  </div>
                  <div className="form-box">
                    <div className="p-field">
                      <InputText
                        id="name"
                        aria-describedby="name"
                        className="p-invalid p-d-block"
                        placeholder="Nama Lengkap"
                      />
                      <small
                        id="name"
                        className="p-error p-d-block p-text-right"
                      >
                        Nama tidak boleh kosong
                      </small>
                    </div>
                    <div className="p-field">
                      <InputText
                        id="email"
                        aria-describedby="email"
                        className="p-invalid p-d-block"
                        placeholder="Email"
                      />
                      <small
                        id="email"
                        className="p-error p-d-block p-text-right"
                      >
                        Email sudah terdaftar
                      </small>
                    </div>
                    <div className="p-field">
                      <InputText
                        id="noHp"
                        aria-describedby="noHp"
                        className="p-invalid p-d-block"
                        placeholder="No.Telepon"
                      />
                      <small
                        id="noHp"
                        className="p-error p-d-block p-text-right"
                      >
                        Nomor telepon tidak valid
                      </small>
                    </div>
                    <div className="p-field">
                      <InputText
                        id="password"
                        aria-describedby="password"
                        className="p-invalid p-d-block"
                        placeholder="Password"
                      />
                      <small
                        id="password"
                        className="p-error p-d-block p-text-right"
                      >
                        Password tidak boleh kosong
                      </small>
                    </div>
                    <div className="p-field">
                      <InputText
                        id="passwordConfirm"
                        aria-describedby="passwordConfirm"
                        className="p-invalid p-d-block"
                        placeholder="Konfirmasi Kata Sandi"
                      />
                      <small
                        id="passwordConfirm"
                        className="p-error p-d-block p-text-right"
                      >
                        Kata sandi tidak boleh kosong
                      </small>
                    </div>
                    <div className="p-field">
                      <Select options={options} placeholder="Provinsi" />
                      <small
                        id="passwordConfirm"
                        className="p-error p-d-block p-text-right"
                      >
                        Provinsi tidak boleh kosong
                      </small>
                    </div>
                    <div className="p-field">
                      <Select options={options} placeholder="Kota" />
                      <small
                        id="passwordConfirm"
                        className="p-error p-d-block p-text-right"
                      >
                        Kota tidak boleh kosong
                      </small>
                    </div>
                    <div className="p-field">
                      <InputTextarea
                        placeholder="Alamat Lengkap"
                        rows={5}
                        cols={30}
                      />
                      <small
                        id="address"
                        className="p-error p-d-block p-text-right"
                      >
                        Alamat lengkap tidak boleh kosong
                      </small>
                    </div>
                    <div className="p-field">
                      <small
                        id="address"
                        className="p-error p-d-block p-text-right"
                      >
                        Alamat lengkap tidak boleh kosong
                      </small>
                    </div>
                    <div className="p-field">
                      <InputTextarea
                        placeholder="Tulis Pesan"
                        rows={5}
                        cols={30}
                      />
                      <small
                        id="Message"
                        className="p-error p-d-block p-text-right"
                      >
                        Teks melebihi batas maksimal
                      </small>
                    </div>
                    <Button
                      label="Daftar"
                      className="p-button-rounded p-button-lg"
                      onClick={() => onClick("displayBasic")}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Dialog
          className="no-header"
          visible={displayBasic}
          style={{ width: "30vw" }}
          onHide={() => onHide("displayBasic")}
        >
          <div className="notif-alert">
            <img src={Success} alt="success" />
            <h3>Selamat, Pendaftaran Anda Berhasil</h3>
            <p>
              Silakan tunggu review dari Admin dalam waktu maksimal 7 x 24 jam
              melalui email
            </p>
            <Button
              label="Tutup"
              className="p-button-rounded p-px-6 mt-3"
              onClick={() => onHide("displayBasic")}
            />
          </div>
        </Dialog>
      </div>
    </AuthLayout>
  );
};

export default RegisterFormPage;
