/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";

import { TabView, TabPanel } from "primereact/tabview";

import UsersImage from "../../../assets/img/icon-users.svg";
import ClockImage from "../../../assets/img/icon-clock.svg";
import WinnerImage from "../../../assets/img/winner.svg";
import ImgInvite from "../../../assets/img/invite_writer.svg";

import ButtonIconLeft from "../../../components/button/ButtonIconLeft";
import DialogAlert from "../../../components/dialog/DialogAlert";
import StatusBadge from "../../../components/badge/StatusBadge";
import TabsUserProject from "../../../components/tabs/TabsUserProject";

import {
  TitleLoad,
  DetailPageLoad,
} from "../../../components/loading/loadingContent";

import { resetStateProjectAction } from "../../../../redux/action/projectAction";
import { resetStateUserAction } from "../../../../redux/action/userAction";
import firebase from "../../../../redux/service/firebase/firebase";

const DetailMyReleaseModule = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = firebase.firestore();
  const QueryParams = useParams();

  const [projectList, setProjectList] = useState([]);

  const [openAlert, setOpenAlert] = useState({
    dialog: false,
    type: null,
    title: null,
    desc: null,
  });

  const { successAssignProject } = useSelector((state) => state.project);
  const { users } = useSelector((state) => state.auth);

  const {
    loadingDetailOrder,
    dataDetailOrder,
    loadingDetailOrderClient,
    dataDetailOrderClient,
  } = useSelector((state) => state.order);

  // console.log(dataDetailOrder)

  const { successInviteUser, errInviteUser } = useSelector(
    (state) => state.user
  );
  const pdfRef = createRef();

  const statusOrder = (status) => {
    switch (status) {
      case "published":
        return "1";
      case "winner":
        return "2";
      case "approved":
        return "3";
      case "sent":
        return "4";
      case "revision":
        return "5";
      case "ongoing":
        return "6";
      case "reject":
        return "7";
      case "removed":
        return "8";
      default:
        return "9";
    }
  };

  let categoryName;
  if (dataDetailOrder?.type === "pr") {
    categoryName = "Press Release";
  } else if (dataDetailOrder?.type === "distribution_media") {
    categoryName = "Media Distribusi";
  } else {
    categoryName = "Artikel";
  }

  console.log(dataDetailOrder)

  const getListProject = async () => {
    return await firestore
      .collection("writerProjects")
      .where("projectId", "==", props?.projectId)
      .orderBy("updatedAt", "desc")
      .onSnapshot(
        (docs) => {
          const temp = [];
          docs.forEach((doc) =>
            temp.push({
              ...doc.data(),
              id: doc.id,
            })
          );
          // eslint-disable-next-line no-unused-expressions
          const sortedList = temp
            ?.map((users) => {
              return {
                ...users,
                statusOrder: statusOrder(users?.status),
              };
            })
            // .filter((items) => items.status !== "sent")
            .sort((a, b) => a?.statusOrder - b?.statusOrder);
          setProjectList(sortedList);
        },
        (error) => {
          console.error("error", error);
        }
      );
  };

  useEffect(() => {
    if (props.projectId) {
      getListProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId]);

  useEffect(() => {
    if (successAssignProject) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Assign Project",
        desc: "Sistem akan mengirimkan notifikasi ke email penulis",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successAssignProject]);

  useEffect(() => {
    if (successInviteUser) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Undang Penulis",
        desc: "Sistem akan mengirimkan notifikasi ke email penulis",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successInviteUser]);

  useEffect(() => {
    if (errInviteUser) {
      setOpenAlert({
        dialog: true,
        type: "error",
        title: "Gagal Undang Penulis",
        desc: "Penulis gagal diundang",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errInviteUser]);

  const closeAlert = () => {
    setOpenAlert({
      dialog: false,
      type: null,
      title: null,
      desc: null,
    });
    dispatch(resetStateProjectAction());
    dispatch(resetStateUserAction());
  };

  const keywords = dataDetailOrder?.searchKeyword?.split(",");

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-header">
          {loadingDetailOrder || loadingDetailOrderClient ? (
            <TitleLoad />
          ) : (
            <>
              <div className="card-title">
                <div
                  className="card-title-back"
                  onClick={() => history.goBack()}
                >
                  <span className="icon-back"></span>
                </div>
                <h2 className="card-title-text">Detail Project</h2>
              </div>
            </>
          )}
        </div>
        <div className="card-body px-0">
          {loadingDetailOrder || loadingDetailOrderClient ? (
            <div className="project-info-wrapp">
              <DetailPageLoad />
            </div>
          ) : (
            <>
              <div className="project-detail-top">
                <div className="project-info-wrapp">
                  <div className="project-image">
                    <img
                      src={
                        dataDetailOrder?.thumbnail
                          ? dataDetailOrder?.thumbnail
                          : process.env.REACT_APP_IMAGE_ARTICLE
                      }
                      alt="tumbnail"
                    />
                  </div>
                  <div className="project-info">
                    <div className="info-row-title">
                      <h3>{dataDetailOrder?.title}</h3>
                      <div className="d-flex align-items-center">
                        <h6>Nomor Pekerjaan: {dataDetailOrder?.jobNumber}</h6>
                        <StatusBadge
                          status={dataDetailOrder?.status}
                          className="ml-3"
                        />
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={UsersImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataDetailOrder?.nWriter || 0}
                          renderText={(value) => (
                            <p>
                              <strong> {value} </strong> Penulis Bergabung
                            </p>
                          )}
                        />
                      </div>
                      <div className="info">
                        <img src={WinnerImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={
                            dataDetailOrder?.totalWinner
                              ? dataDetailOrder?.totalWinner
                              : 0
                          }
                          renderText={(value) => <p>{value}/</p>}
                        />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataDetailOrder?.winnerQuota}
                          renderText={(value) => (
                            <p>
                              <strong>{value}</strong> Tulisan Terpilih
                            </p>
                          )}
                        />
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={ClockImage} alt="" />
                        <p>
                          {`Deadline ${
                            dataDetailOrder?.deadlineDate
                              ? dayjs(dataDetailOrder?.deadlineDate?.toDate())
                                  .locale("id")
                                  .format("DD MMMM YYYY")
                              : "belum diatur"
                          }`}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="project-description">
                    <p>
                      {dataDetailOrder?.description ||
                        "Deskripsi project belum diatur"}
                    </p>
                  </div>
                  <div className="project-action">
                    <div className="action-left" />
                    <div className="action-right">
                      {!dataDetailOrder?.picId &&
                        dataDetailOrder?.status !== "draft" && (
                          <ButtonIconLeft
                            className="ml-2"
                            label="TAMBAH TIM"
                            image={ImgInvite}
                            onClick={() =>
                              history.push(
                                `/client/my-release/assign-pic/${QueryParams.projectId}`
                              )
                            }
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {!dataDetailOrder?.deadlineDate ? (
                <div className="project-detail-bottom">
                  {dataDetailOrderClient?.status === "payment_waiting" ? (
                    <div className="project-detail-alert">
                      <h3>PEMBAYARAN BELUM TERKONFIRMASI</h3>
                      <p>
                        Silahkan periksa kembali tagihan anda untuk melanjutkan
                        project ini
                      </p>
                    </div>
                  ) : (
                    <div className="project-detail-alert">
                      <h3>BRIEF PROYEK BELUM LENGKAP</h3>
                      <p>
                        Silahkan lengkapi brief pesanan terlebih dahulu agar
                        pesanan bisa kami proses.
                      </p>
                      <Link
                        className="p-button p-button-rounded p-button-primary"
                        to={`/client/create-brief/${props.projectId}`}
                      >
                        {" "}
                        BUAT BRIEF
                      </Link>
                    </div>
                  )}
                </div>
              ) : (
                <div className="project-detail-bottom">
                  <div className="tab-project" ref={pdfRef}>
                    <TabView>
                      <TabPanel header="Ringkasan Project">
                        <div className="accordion-project-wrapper">
                          <div className="row">
                            <div className="col-12 col-sm-12">
                              <h3>Informasi Project</h3>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Jenis Project</h6>
                              <div className="box-text">{categoryName}</div>
                            </div>

                            <div className="col-12 col-sm-4">
                              <h6>PIC Project</h6>
                              <div className="box-text">
                                {dataDetailOrder?.picName || users?.user?.name}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Kata Kunci Pencarian</h6>
                              <div className="d-flex flex-wrap">
                                {keywords?.map((item) => (
                                  <div className="box-text list">{item}</div>
                                ))}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <hr />
                            </div>
                            <div className="col-12 col-sm-12">
                              <h3>Target Pembaca</h3>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Usia</h6>
                              <div className="box-text">
                                {dataDetailOrder?.target?.age}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Jenis kelamin</h6>
                              <div className="box-text">
                                {dataDetailOrder?.target?.sex}
                              </div>
                            </div>
                            <div className="col-12 col-sm-4">
                              <h6>Stakeholder</h6>
                              <div className="d-flex flex-wrap">
                                {dataDetailOrder?.target?.stakeholders?.map(
                                  (item) => (
                                    <div className="box-text list">{item}</div>
                                  )
                                )}
                              </div>
                            </div>
                            <div className="col-12 col-sm-12">
                              <hr />
                            </div>
                            <div className="col-12 col-sm-12">
                              <h3>Ruang Lingkup Rilis</h3>
                            </div>
                            {dataDetailOrder?.type === "pr" && (
                              <>
                                <div className="col-12 col-sm-4">
                                  <h6>Kategori Rilis</h6>
                                  <div className="box-text mb-5">
                                    {dataDetailOrder?.releaseCategory}
                                  </div>
                                </div>
                              </>
                            )}

                            <div className="col-12 col-sm-12">
                              <h6>Objek Komunitas yang Ingin Dicapai</h6>
                              <p>{dataDetailOrder?.objective}</p>
                              <h6>
                                Atribut Quote dan Pesan yang Ingin Disampaikan
                              </h6>
                              <p>{dataDetailOrder?.attribution}</p>
                              <h6>
                                Hal yang Diperhatikan dalam Penulisan Release
                              </h6>
                              <p>{dataDetailOrder?.releaseNote}</p>
                            </div>
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel header="Daftar Tulisan">
                        <TabsUserProject
                          userType={users?.user?.type}
                          writerList={
                              dataDetailOrder?.status !== "payment_waiting" &&
                              dataDetailOrder?.status !== "draft" &&
                              dataDetailOrder?.status !== "confirmation_waiting" ?
                              projectList : ""}
                          link={{
                            writer: `/client/article/`,
                            pic: `/client/article/`,
                          }}
                        />
                      </TabPanel>
                    </TabView>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <DialogAlert
        type={openAlert.type}
        visible={openAlert.dialog}
        closeDialog={() => closeAlert()}
        title={openAlert.title}
        text={openAlert.desc}
      />
    </>
  );
};

export default DetailMyReleaseModule;
