import api from "./api";

export const inviteUsers = async (token, data) => {
  return api.post("/pm/users", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const reInviteUsers = async (token, id) => {
  return api.put(
    `/pm/users/${id}/send-email`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getUsers = async (token, params) => {
  return api.get("/pm/users", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteUsers = async (token, id) => {
  return api.delete(
    `/pm/users/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const checkPhoneNumber = async (data) => {
  return api.put(`/check-phone-number`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const checkEmail = async (data) => {
  return api.put(`/check-email`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const registerPassword = async (data) => {
  return api.post(`/writer/users/add-password`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const register = async (data) => {
  return api.post(`/writer/users/register`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const editWriterProfile = async (token, data) => {
  return api.put(`/writer/users`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const registerClient = async (data) => {
  return api.post(`/client/users`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const verifyClient = async (data) => {
  return api.post(`/client/users/verify-users`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const getPortfolioFile = async (token, uid, portfolioId) => {
  return api.get(
    `/writer/users/${uid}/portofolio/${portfolioId}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const editStatusApplicant = async (token, uid, data) => {
  return api.put(`pm/users/applicants/${uid}/update-status`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const requestUpdateEmailWriter = async (token, data) => {
  return api.put("/user/send-verify-email", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const requestUpdateEmailPm = async (token, data) => {
  return api.put("/user/send-verify-email", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const requestUpdateEmailClient = async (token, data) => {
  return api.put("/user/send-verify-email", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateEmailWriter = async (data) => {
  return api.post("/user/update-email", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateEmailPm = async (data) => {
  return api.put("/user/update-email", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateEmailClient = async (data) => {
  return api.put("/user/update-email", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const resetPassword = async (data) => {
  return api.put("/user/send-reset-password-email", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const createNewPassword = async (data) => {
  return api.post("/user/reset-password", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const addPm = async (token, data) => {
  return api.post("/pm/users/general-pm", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const editStatusUser = async (token, id, data) => {
  return api.put(
    `/pm/users/${id}/update-status`,
    { status: data },
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const postWriterBank = async (token, data) => {
  return api.post("/writer/account-bank", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getWriterBank = async (token) => {
  return api.get(
    "/writer/account-bank",
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const updateWriterBank = async (token, data) => {
  return api.put("/writer/account-bank", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
