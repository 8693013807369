import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { InputText } from "primereact/inputtext";

import { getProjectPmAction } from "../../../../../redux/action/projectAction";
import filterIcon from "../../../../assets/img/Filter-2.png";
import PopupFilterListProject from "./PopupFilterListProject";

const FilterListProject = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [showPopupFilter, setShowPopupFilter] = useState(false);
  const { parsed } = props;

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        status: parsed?.status,
        type: parsed?.type || "",
        orderField: parsed?.order || "",
        orderDir: parsed?.order === "createdAt" ? "desc" : "asc",
        page: 1,
        perpage: 10,
      };

      history.push(
        `/admin/finance/list-project?search=${search}&status=${parsed?.status ||
          ""}&page=1`
      );
      dispatch(getProjectPmAction(params));
    }, 1000)
  ).current;

  const handleClickFilter = () => {
    setShowPopupFilter(!showPopupFilter);
  };

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="search-list-project">
        <div className="d-flex">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <button onClick={handleClickFilter}>
            <img src={filterIcon} alt="filter" />
          </button>
        </div>
        <p>
          Cari berdasarkan<span> Nama Project</span>
        </p>
      </div>
      <PopupFilterListProject
        show={showPopupFilter}
        onHide={() => setShowPopupFilter(false)}
        parsed={parsed}
      />
    </div>
  );
};

export default FilterListProject;
