import React, { useEffect } from "react";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import { titlePage } from "../../helpers/titleHead";
import UpdateSettingsModule from "../../modules/User/Settings/UpdateSettingsModule";

const ChangeDetailPage = () => {
  useEffect(() => {
    titlePage({
      title: "IRIS - Ubah Informasi Akun",
    });
  }, []);

  return (
    <DashboardUserLayout>
      <UpdateSettingsModule/>
    </DashboardUserLayout>
  );
};

export default ChangeDetailPage;
