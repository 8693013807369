import {
  AUTO_COMPLETE_COMPANY_REQUEST,
  UPDATE_COMPANY_REQUEST,
  POST_CLIENT_REQUEST,
  GET_CLIENT_REQUEST,
  GET_CLIENT_DETAIL_REQUEST,
  UPDATE_CLIENT_REQUEST,
  DELETE_CLIENT_REQUEST,
  CLIENT_STATE_RESET,
} from "../constant/clientConstant";

export const resetClientStateAction = () => {
  return {
    type: CLIENT_STATE_RESET,
  };
};

export const getCompaniesRegisterAction = () => {
  return {
    type: AUTO_COMPLETE_COMPANY_REQUEST,
  };
};

export const updateCompanyAction = (id, data) => {
  return {
    type: UPDATE_COMPANY_REQUEST,
    id,
    data,
  };
};

export const getClientAction = (params) => {
  return {
    type: GET_CLIENT_REQUEST,
    params,
  };
};

export const postClientAction = (data) => {
  return {
    type: POST_CLIENT_REQUEST,
    data,
  };
};

export const updateClientAction = (id, data) => {
  return {
    type: UPDATE_CLIENT_REQUEST,
    id,
    data,
  };
};

export const deleteClientAction = (id) => {
  return {
    type: DELETE_CLIENT_REQUEST,
    id,
  };
};

export const detailClientAction = (id) => {
  return {
    type: GET_CLIENT_DETAIL_REQUEST,
    id,
  };
};
