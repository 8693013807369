export const GET_PROJECTS_PM_REQUEST = "GET_PROJECTS_PM_REQUEST";
export const GET_PROJECTS_PM_SUCCESS = "GET_PROJECTS_PM_SUCCESS";
export const GET_PROJECTS_PM_FAILURE = "GET_PROJECTS_PM_FAILURE";

export const GET_PROJECT_DETAIL_PM_REQUEST = "GET_PROJECT_DETAIL_PM_REQUEST";
export const GET_PROJECT_DETAIL_PM_SUCCESS = "GET_PROJECT_DETAIL_PM_SUCCESS";
export const GET_PROJECT_DETAIL_PM_FAILURE = "GET_PROJECT_DETAIL_PM_FAILURE";

export const GET_PROJECT_DETAIL_CLIENT_REQUEST = "GET_PROJECT_DETAIL_CLIENT_REQUEST";
export const GET_PROJECT_DETAIL_CLIENT_FAILURE = "GET_PROJECT_DETAIL_CLIENT_FAILURE";
export const GET_PROJECT_DETAIL_CLIENT_SUCCESS = "GET_PROJECT_DETAIL_CLIENT_SUCCESS";

export const GET_BRIEF_PROJECTS_PM_REQUEST = "GET_BRIEF_PROJECTS_PM_REQUEST";
export const GET_BRIEF_PROJECTS_PM_SUCCESS = "GET_BRIEF_PROJECTS_PM_SUCCESS";
export const GET_BRIEF_PROJECTS_PM_FAILURE = "GET_BRIEF_PROJECTS_PM_FAILURE";

export const GET_PROJECTS_WRITER_REQUEST = "GET_PROJECTS_WRITER_REQUEST";
export const GET_PROJECTS_WRITER_SUCCESS = "GET_PROJECTS_WRITER_SUCCESS";
export const GET_PROJECTS_WRITER_FAILURE = "GET_PROJECTS_PM_FAILURE";

export const GET_DETAIL_PROJECT_REQUEST = "GET_DETAIL_PROJECT_REQUEST";
export const GET_DETAIL_PROJECT_SUCCESS = "GET_DETAIL_PROJECT_SUCCESS";
export const GET_DETAIL_PROJECT_FAILURE = "GET_DETAIL_PROJECT_FAILURE";

export const GET_DETAIL_SAVE_PROJECT_REQUEST = "GET_DETAIL_SAVE_PROJECT_REQUEST";
export const GET_DETAIL_SAVE_PROJECT_SUCCESS = "GET_DETAIL_SAVE_PROJECT_SUCCESS";
export const GET_DETAIL_SAVE_PROJECT_FAILURE = "GET_DETAIL_SAVE_PROJECT_FAILURE";

export const GET_MY_PROJECTS_REQUEST = "GET_MY_PROJECTS_REQUEST";
export const GET_MY_PROJECTS_SUCCESS = "GET_MY_PROJECTS_SUCCESS";
export const GET_MY_PROJECTS_FAILURE = "GET_MY_PROJECTS_FAILURE";

export const GET_ARTICLE_REQUEST = "GET_ARTICLE_REQUEST";
export const GET_ARTICLE_SUCCESS = "GET_ARTICLE_SUCCESS";
export const GET_ARTICLE_FAILURE = "GET_ARTICLE_FAILURE";

export const UPDATE_ARTICLE_REQUEST = "UPDATE_ARTICLE_REQUEST";
export const UPDATE_ARTICLE_SUCCESS = "UPDATE_ARTICLE_SUCCESS";
export const UPDATE_ARTICLE_FAILURE = "UPDATE_ARTICLE_FAILURE";

export const UPDATE_STATUS_PROJECT_REQUEST = "UPDATE_STATUS_PROJECT_REQUEST";
export const UPDATE_STATUS_PROJECT_SUCCESS = "UPDATE_STATUS_PROJECT_SUCCESS";
export const UPDATE_STATUS_PROJECT_FAILURE = "UPDATE_STATUS_PROJECT_FAILURE";

export const GET_PROJECT_USER_REQUEST = "GET_PROJECT_USER_REQUEST";
export const GET_PROJECT_USER_SUCCESS = "GET_PROJECT_USER_SUCCESS";
export const GET_PROJECT_USER_FAILURE = "GET_PROJECT_USER_FAILURE";

export const SUBMIT_ARTICLE_PROJECT_REQUEST = "SUBMIT_ARTICLE_PROJECT_REQUEST";
export const SUBMIT_ARTICLE_PROJECT_SUCCESS = "SUBMIT_ARTICLE_PROJECT_SUCCESS";
export const SUBMIT_ARTICLE_PROJECT_FAILURE = "SUBMIT_ARTICLE_PROJECT_FAILURE";

export const GET_USER_INVITABLE_REQUEST = "GET_USER_INVITABLE_REQUEST";
export const GET_USER_INVITABLE_SUCCESS = "GET_USER_INVITABLE_SUCCESS";
export const GET_USER_INVITABLE_FAILURE = "GET_USER_INVITABLE_FAILURE";

export const UPDATE_USER_INVITABLE_PARAMS = "UPDATE_USER_INVITABLE_PARAMS";

export const ASSIGN_PROJECT_USER_REQUEST = "ASSIGN_PROJECT_USER_REQUEST";
export const ASSIGN_PROJECT_USER_SUCCESS = "ASSIGN_PROJECT_USER_SUCCESS";
export const ASSIGN_PROJECT_USER_FAILURE = "ASSIGN_PROJECT_USER_FAILURE";

export const JOIN_PROJECT_WITH_TOKEN_REQUEST =
  "JOIN_PROJECT_WITH_TOKEN_REQUEST";
export const JOIN_PROJECT_WITH_TOKEN_SUCCESS =
  "JOIN_PROJECT_WITH_TOKEN_SUCCESS";
export const JOIN_PROJECT_WITH_TOKEN_FAILURE =
  "JOIN_PROJECT_WITH_TOKEN_FAILURE";

export const CREATE_PROJECT_REQUEST = "CREATE_PROJECTS_REQUEST";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECTS_SUCCESS";
export const CREATE_PROJECT_FAILURE = "CREATE_PROJECTS_FAILURE";

export const JOIN_PROJECT_REQUEST = "JOIN_PROJECT_REQUEST";
export const JOIN_PROJECT_SUCCESS = "JOIN_PROJECT_SUCCESS";
export const JOIN_PROJECT_FAILURE = "JOIN_PROJECT_FAILURE";

export const UPDATE_PROJECT_REQUEST = "UPDATE_PROJECTS_REQUEST";
export const UPDATE_PROJECT_SUCCESS = "UPDATE_PROJECTS_SUCCESS";
export const UPDATE_PROJECT_FAILURE = "UPDATE_PROJECTS_FAILURE";

export const DELETE_PROJECT_REQUEST = "DELETE_PROJECTS_REQUEST";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECTS_SUCCESS";
export const DELETE_PROJECT_FAILURE = "DELETE_PROJECTS_FAILURE";

export const GET_LOG_ARTICLE_REQUEST = "GET_LOG_ARTICLE_REQUEST";
export const GET_LOG_ARTICLE_SUCCESS = "GET_LOG_ARTICLE_SUCCESS";
export const GET_LOG_ARTICLE_FAILURE = "GET_LOG_ARTICLE_FAILURE";

export const COUNT_PROJECT_REQUEST = "COUNT_PROJECT_REQUEST";
export const COUNT_PROJECT_SUCCESS = "COUNT_PROJECT_SUCCESS";
export const COUNT_PROJECT_FAILURE = "COUNT_PROJECT_FAILURE";

export const UPDATE_COUNT_PROJECT_REQUEST = "UPDATE_COUNT_PROJECT_REQUEST";
export const UPDATE_COUNT_PROJECT_SUCCESS = "UPDATE_COUNT_PROJECT_SUCCESS";
export const UPDATE_COUNT_PROJECT_FAILURE = "UPDATE_COUNT_PROJECT_FAILURE";

export const GET_LIST_ARTICLES_REQUEST = "GET_LIST_ARTICLES_REQUEST";
export const GET_LIST_ARTICLES_SUCCESS = "GET_LIST_ARTICLES_SUCCESS";
export const GET_LIST_ARTICLES_FAILURE = "GET_LIST_ARTICLES_FAILURE";

export const RESET_STATE_PROJECT = "RESET_STATE_PROJECT";
export const RESET_PROJECT_DETAIL_DATA = "RESET_PROJECT_DETAIL_DATA";

export const SAVE_DRAFT_REQUEST = "SAVE_DRAFT_REQUEST";
export const SAVE_DRAFT_SUCCESS = "SAVE_DRAFT_SUCCESS";
export const SAVE_DRAFT_FAILURE = "SAVE_DRAFT_FAILURE";

export const GET_DRAFT_REQUEST = "GET_DRAFT_REQUEST";
export const GET_DRAFT_SUCCESS = "GET_DRAFT_SUCCESS";
export const GET_DRAFT_FAILURE = "GET_DRAFT_FAILURE";

export const UPDATE_PROJECT_PM_STATUS_REQUEST =
  "UPDATE_PROJECT_PM_STATUS_REQUEST";
export const UPDATE_PROJECT_PM_STATUS_SUCCESS =
  "UPDATE_PROJECT_PM_STATUS_SUCCESS";
export const UPDATE_PROJECT_PM_STATUS_FAILURE =
  "UPDATE_PROJECT_PM_STATUS_FAILURE";

export const UPDATE_ARTICLE_CLIENT_STATUS_REQUEST =
  "UPDATE_ARTICLE_CLIENT_STATUS_REQUEST";
export const UPDATE_ARTICLE_CLIENT_STATUS_SUCCESS =
  "UPDATE_ARTICLE_CLIENT_STATUS_SUCCESS";
export const UPDATE_ARTICLE_CLIENT_STATUS_FAILURE =
  "UPDATE_ARTICLE_CLIENT_STATUS_FAILURE";

export const UPDATE_PROJECT_FS_REQUEST = "UPDATE_PROJECT_FS_REQUEST";
export const UPDATE_PROJECT_FS_SUCCESS = "UPDATE_PROJECT_FS_SUCCESS";
export const UPDATE_PROJECT_FS_FAILURE = "UPDATE_PROJECT_FS_FAILURE";

export const SUPERUSER_JOIN_PROJECT_REQUEST = "SUPERUSER_JOIN_PROJECT_REQUEST";
export const SUPERUSER_JOIN_PROJECT_SUCCESS = "SUPERUSER_JOIN_PROJECT_SUCCESS";
export const SUPERUSER_JOIN_PROJECT_FAILURE = "SUPERUSER_JOIN_PROJECT_FAILURE";

export const GET_PROJECT_ACTIVE_REQUEST = "GET_PROJECT_ACTIVE_REQUEST";
export const GET_PROJECT_ACTIVE_SUCCESS = "GET_PROJECT_ACTIVE_SUCCESS";
export const GET_PROJECT_ACTIVE_FAILURE = "GET_PROJECT_ACTIVE_FAILURE";

export const CLIENT_PROJECT_OVERVIEW_REQUEST =
  "CLIENT_PROJECT_OVERVIEW_REQUEST";
export const CLIENT_PROJECT_OVERVIEW_SUCCESS =
  "CLIENT_PROJECT_OVERVIEW_SUCCESS";
export const CLIENT_PROJECT_OVERVIEW_FAILURE =
  "CLIENT_PROJECT_OVERVIEW_FAILURE";

export const DELETE_ARTICLE_PROJECT_REQUEST = "DELETE_ARTICLE_PROJECT_REQUEST";
export const DELETE_ARTICLE_PROJECT_SUCCESS = "DELETE_ARTICLE_PROJECT_SUCCESS";
export const DELETE_ARTICLE_PROJECT_FAILURE = "SUBMIT_ARTICLE_PROJECT_FAILURE";
