/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import { Checkbox } from "primereact/checkbox";
import ButtonDefault from "../../../components/button/ButtonDefault";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { getWriterBankAction } from "../../../../redux/action/userAction";
import { writerWithdrawAction } from "../../../../redux/action/transactionAction";

const AccountNumber = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [accept, acceptChange] = useState(false);

  const [dialogAlert, setDialogAlert] = useState({
    type: null,
    text: null,
    title: null,
    dialog: false,
  });

  const { users } = useSelector((state) => state.auth);
  const {
    loadingWriterWithdraw,
    successWriterWithdraw,
    errWriterWithdraw,
  } = useSelector((state) => state.transaction);

  const { dataGetWriterBank } = useSelector((state) => state.user);

  useEffect(() => {
    dispatch(getWriterBankAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successWriterWithdraw) {
      setDialogAlert({
        type: "success",
        title: "Berhasil!",
        text: "Permintaan penarikan dana berhasil",
        dialog: true,
      });
    }
    if (errWriterWithdraw) {
      setDialogAlert({
        type: "error",
        title: "Gagal!",
        text: "Permintaan penarikan dana gagal",
        dialog: true,
      });
    }
  }, [successWriterWithdraw, errWriterWithdraw]);

  const submitData = () => {
    const data = {
      amount: users?.user?.balance,
      type: "debit",
      status: "request",
      description: "penarikan dana",
    };
    if (!accept) {
      setDialogAlert({
        type: "warning",
        title: "Peringatan!",
        text: "Silahkan baca dan setujui Syarat dan Ketentuan terlebih dulu",
        dialog: true,
      });
    } else {
      dispatch(writerWithdrawAction(data));
    }
  };

  const closeDialog = () => {
    if (dialogAlert.type === "success") {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
    } else {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
    }
  };

  return (
    <>
      <div className="section bg-grey p-py-5">
        <div className="p-d-flex p-jc-center">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-6">
            <div className="card card-custom-detail setting w-full has-shadow mb-5">
              <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                <div className="card-title">
                  <div
                    className="card-title-back"
                    onClick={() => history.push("/balance")}
                  >
                    <span className="icon-back"></span>
                  </div>
                  <h2 className="card-title-text">Tarik Dana</h2>
                </div>
              </div>
              <div className="form-update-setting">
                <div className="info-withdraw">
                  <p>Nomor Rekening Tujuan</p>
                  {dataGetWriterBank ? (
                    <h3>{dataGetWriterBank?.bankAccountNumber}</h3>
                  ) : (
                    <Link to="/settings/account-number">
                      Tambahkan Nomor Rekening
                    </Link>
                  )}
                </div>
                <div className="info-withdraw border-0 mb-0">
                  <p>Jumlah Penarikan Dana</p>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={users?.user?.balance}
                    renderText={(value) => <h3>{value}</h3>}
                  />
                </div>
                <p className="text-note">
                  Catatan: jumlah dana yang di tarik adalah total saldo anda
                  saat ini.
                </p>
                <div className="p-field-checkbox">
                  <Checkbox
                    checked={accept}
                    onChange={() => acceptChange(!accept)}
                  />
                  <label className="p-mb-0">
                    Saya setuju dengan{" "}
                    <a href="/term" target="_blank">
                      Syarat dan Ketentuan
                    </a>{" "}
                    yang berlaku
                  </label>
                </div>
              </div>
              <div className="udpate-setting-footer p-d-flex p-jc-end">
                <ButtonDefault
                  label="batal"
                  onClick={() => history.goBack()}
                  type="rounded-bordered"
                  disabled={loadingWriterWithdraw}
                />
                <ButtonDefault
                  label="tarik dana"
                  onClick={() => submitData()}
                  type="rounded"
                  disabled={loadingWriterWithdraw}
                  loading={loadingWriterWithdraw}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
};

export default AccountNumber;
