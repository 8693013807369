/* eslint-disable jsx-a11y/anchor-has-content */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import { uploadImageAction } from "../../../../redux/action/utilsAction";

export const ButtonUpload = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);
  const { loadingUploadImage } = useSelector((state) => state.utils);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 1000000) {
        props.setImageError("Ukuran gambar lebih dari 1MB");
      } else if (acceptedFiles.length > 1) {
        props.setImageError("Silakan pilih satu gambar");
      } else {
        props.setImageError(null);

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/chat-data/${times}/${users.uid}/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
      }
    },
  });

  return (
    <div {...getRootProps({ className: "dropzone" })}>
      <input {...getInputProps()} />
      <button
        className="btn-img"
        onClick={open}
        disabled={loadingUploadImage || props?.isDisable}
      >
        <span className="icon-image"></span>
      </button>
    </div>
  );
};
