/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import "dayjs/locale/id";

import CarouselSlide from "../../../components/carousel/Carousel";
// Hide Until Next Sprint
// import CarouselAuthor from "../../../components/carousel/CarouselAuthor";
// import CarouselTestimony from "../../../components/carousel/CarouselTestimony";
import ContentsLoader from "../../../components/loading/contentLoader";
import CarouselAuthor from "../../../components/carousel/CarouselAuthor";
import CarouselTestimony from "../../../components/carousel/CarouselTestimony";
import { Button } from "primereact/button";
import bannerImage from "../../../assets/img/iso-login.svg";
import photo1 from "../../../assets/img/article-thumb.png";
import photo2 from "../../../assets/img/pict.png";

// import Article from "../../../assets/img/article-thumb.png";

const LandingpageClientModule = (props) => {
  const {
    // dataBanner,
    loadingBanner,
    loadingHowDoesItWork,
    loadingBenefit,
    loadingTestimonial,
    loadingTopWriter,
    // dataArticle,
    // dataTopWriter,
    // dataTestimonial,
  } = props;

  const dataBanner = [
    {
      title: "Tingkatkan Citra Perusahaan di Mata Pelanggan Secara Tepat",
      buttonLabel: "Daftar Gratis",
      url: "https://iris-staging-81cfa.web.app/register",
      image: bannerImage,
      id: "3cDYDLAQu9bLHJV3n7hQ",
      description:
        "Bersama jurnalis andalan dan berpengalaman, Anda dapat membuat press release lebih efektif dan kreatif.",
    },
    {
      title: "Tingkatkan Penjualan Produk dengan Copywriting yang Menarik",
      url: "https://iris-staging-81cfa.web.app/register",
      image: bannerImage,
      buttonLabel: "DAFTAR SEKARANG",
      description:
        "Bersama jurnalis andalan dan berpengalaman, Anda dapat membuat press release lebih efektif dan kreatif.",
      id: "VdpMUqXSTOXoyvvAG1bv",
    },
  ];

  const dataTopWriter = {
    title: "Penulis Terbaik",
    description:
      "Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum architecto velit est eos repellat dignissimos, ipsa veniam iusto quibusdam quod reprehenderit laborum placeat quasi, sapiente sit explicabo ipsum delectus fugiat nemo debitis, fuga praesentium! Rem nam libero delectus ab, ipsa fugit officia tenetur ratione ex, temporibus enim, qui dolorem consequatur neque totam. Esse perferendis ducimus facilis tenetur quas rerum beatae minima! Doloribus soluta quis nulla eum minus perspiciatis fugiat neque. Deserunt odit impedit repellat accusamus omnis quia assumenda sequi recusandae quo, dicta, ipsa corporis praesentium blanditiis nesciunt, aliquam rerum distinctio ipsam repellendus iusto quas ullam? Animi sunt amet vitae sit.",
    topWriterItemResult: [
      {
        imageUrl: photo1,
        name: "James Cordon",
        nArticle: 85,
        id: 1,
      },
      {
        imageUrl: photo2,
        name: "James Kokolomel",
        nArticle: 23,
        id: 2,
      },
      {
        imageUrl: photo1,
        name: "Putri Felicia",
        nArticle: 44,
        id: 3,
      },
      {
        imageUrl: photo2,
        name: "Grace Natalie",
        nArticle: 91,
        id: 4,
      },
      {
        imageUrl: photo1,
        name: "Kroenke Suwito",
        nArticle: 72,
        id: 6,
      },
    ],
  };

  const dataTestimonial = [
    {
      id: 1,
      image: photo1,
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio, minima. Harum expedita autem tempora nostrum!",
      name: "Dhika Ramadhan",
      occupation: "PT. Garudafood Lestari",
    },
    {
      id: 5,
      image: photo1,
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio, minima. Harum expedita autem tempora nostrum!",
      name: "Boston Master",
      occupation: "Indonesia Maju",
    },
    {
      id: 2,
      image: photo2,
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio, minima. Harum expedita autem tempora nostrum!",
      name: "Perluigi Imanuel",
      occupation: "CV Sejahtera",
    },
    {
      id: 3,
      image: photo1,
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio, minima. Harum expedita autem tempora nostrum!",
      name: "Alivia Askra",
      occupation: "Askara Intl",
    },
    {
      id: 4,
      image: photo2,
      content:
        "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Odio, minima. Harum expedita autem tempora nostrum!",
      name: "Wolisti Warhuben",
      occupation: "Grunzke Dracht",
    },
  ];

  return (
    <>
      <div className="landing-page client">
        <div className="section">
          <div>
            <CarouselSlide banners={dataBanner} loading={loadingBanner} />
          </div>
        </div>
        {loadingHowDoesItWork &&
        loadingBenefit &&
        loadingTestimonial &&
        loadingTopWriter ? (
          <div className="section about-section">
            <ContentsLoader />
          </div>
        ) : (
          <>
            <div className="section-benefit container">
              <h2>Lebih Mudah, Lebih Cepat, Lebih Akurat</h2>
              <div className="row">
                <div className="benefit-card col-lg-6 ol-md-6 col-sm-12">
                  <img src={photo1} alt="" />
                  <h4>Bikin Rilis</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Iusto error qui nam perferendis ipsam sint vel. Temporibus
                    eveniet vel possimus culpa ad. Veritatis corrupti incidunt
                    ea eum quo aperiam. Consectetur!
                  </p>
                </div>
                <div className="benefit-card col-lg-6 col-md-6 col-sm-12">
                  <img src={photo2} alt="" />
                  <h4>Bikin Rilis</h4>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad
                    autem, quae labore asperiores iusto exercitationem cumque?
                    Perferendis debitis impedit fugiat modi ullam porro!
                    Architecto consectetur voluptatem excepturi quae, pariatur
                    neque?
                  </p>
                </div>
              </div>
            </div>
            <div className="section author-section bg-grey">
              <div className="p-d-flex p-justify-center">
                <div className="p-grid p-justify-center">
                  <div className="p-lg-10 p-md-10 p-sm-12">
                    <div className="p-text-center">
                      <h2>{dataTopWriter?.title}</h2>
                      <p>{dataTopWriter?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="section carousel-section bg-grey">
              <div>
                <CarouselAuthor
                  topWriter={dataTopWriter?.topWriterItemResult}
                />
              </div>
            </div>
            <div className="section testimony-section">
              <h2>Happy Clients</h2>
            </div>
            <div className="section carousel-section">
              <div>
                <CarouselTestimony testimonial={dataTestimonial} />
              </div>
            </div>
            <div className="section-action p-d-flex p-jc-center flex-column">
              <div className="section-action-header">
                <h2>Siap Membangun Citra Perusahaanmu To The Next Level?</h2>
              </div>
              <div className="action-buttons">
                <Button
                  className="p-button p-button-primary mr-5"
                  label="HUBUNGI KAMI"
                />
                <Button
                  className="p-button p-button-primary ml-5"
                  label="PILIH PAKET"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default LandingpageClientModule;
