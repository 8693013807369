import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { formEmail } from "../../../helpers/email";
import {
  loginEmailAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";
import ButtonDefault from "../../../components/button/ButtonDefault";

const LoginEmail = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState({ value: "", error: null });
  const [password, setPassword] = useState({ value: "", error: null });
  const [openDialog, setOpendialog] = useState(false);
  const [textDialog, setTextdialog] = useState(false);

  const { loadingEmailLogin, errEmailLogin } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (
      errEmailLogin !== null &&
      errEmailLogin.code === "auth/user-not-found"
    ) {
      setOpendialog(true);
      setTextdialog("Email atau kata sandi anda salah");
    }

    if (errEmailLogin !== null && errEmailLogin.code === "auth/user-disabled") {
      setOpendialog(true);
      setTextdialog(
        "Akun anda belum terverifikasi. Silahkan tunggu email konfirmasi dari Tim Iris atau hub cs@irisnco.id untuk informasi lebih lanjut"
      );
    }

    if (
      errEmailLogin !== null &&
      errEmailLogin.code === "auth/wrong-password"
    ) {
      setOpendialog(true);
      setTextdialog("Email atau kata sandi anda salah");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errEmailLogin]);

  const submitEmail = (e) => {
    e.preventDefault();

    if (!formEmail(email.value)) {
      setEmail({ ...email, error: "Email tidak valid" });
    } else {
      dispatch(loginEmailAction(email.value, password.value));
    }
  };

  const handleCloseDialog = () => {
    setOpendialog(false);
    dispatch(resetStateAuthAction());
  };

  return (
    <>
      <form onSubmit={submitEmail}>
        <div className="p-field mt-0">
          <InputText
            aria-describedby="phone-number"
            className={`${email.error !== null && "p-invalid"} p-d-block`}
            placeholder="Masukkan Email"
            type="email"
            value={email.value}
            onChange={(e) => setEmail({ value: e.target.value, error: null })}
          />
          {email.error !== null && (
            <small id="phone-number" className="p-error p-d-block p-text-right">
              {email.error}
            </small>
          )}
        </div>
        <div className="p-field my-2">
          <InputText
            aria-describedby="phone-number"
            className={`${password.error !== null && "p-invalid"} p-d-block`}
            placeholder="Masukkan Kata Sandi"
            type="password"
            value={password.value}
            onChange={(e) =>
              setPassword({ value: e.target.value, error: null })
            }
          />
          {password.error !== null && (
            <small id="phone-number" className="p-error p-d-block p-text-right">
              {password.error}
            </small>
          )}
        </div>
        <div className="d-flex d-jc-end forgot-text">
          <Link to="/forgot-password">Lupa Kata Sandi?</Link>
        </div>
        <ButtonDefault
          onClick={submitEmail}
          label="Masuk"
          className="p-mt-3"
          typeButton="submit"
          size="login-user"
          type="rounded"
          loading={loadingEmailLogin}
        />
      </form>
      <div className="footer-form">
        <p>
          Belum memiliki akun? <Link to="/create-account">Daftar Sekarang</Link>
        </p>
      </div>

      <DialogAlert
        type={"error"}
        visible={openDialog}
        closeDialog={handleCloseDialog}
        title={"Gagal Login"}
        text={textDialog}
      />
    </>
  );
};

export default LoginEmail;
