/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory, Link } from "react-router-dom";

import CompanyDetailForm from "./Components/CompanyDetailForm";

const CompanyDetailModule = (props) => {
  const history = useHistory();

  return (
    <>
      <div className="p-d-flex p-jc-center">
        <div className="card card-custom col-8 mb-4">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Detail Perusahaan
                </span>
              </button>
            </h3>
            <Link
              to={`/admin/pm/edit-company/${props.companyId}`}
              className="btn px-0 py-o ml-3 d-flex align-items-center text-primary"
            >
              <strong>Edit</strong>
            </Link>
          </div>
          <CompanyDetailForm isDisabled={true} />
        </div>
      </div>
    </>
  );
};

export default CompanyDetailModule;
