/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-script-url */
import React, { useEffect, useState } from "react";

import { Button } from "primereact/button";
import { useHistory } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import CloseIcon from "@material-ui/icons/Close";

import RichtextWithoutToolbar from "../../../components/richtext/richtextWithoutToolbar";
import { UploadImageComment } from "../../../components/upload/uploadImageComment";
import FullPageContent from "../../../components/loading/fullContentLoader";
import thumbnailDokumen from "../../../assets/img/Document.svg";

import firebase from "../../../../redux/service/firebase/firebase";
import {
  postHelpChatsAction,
  postHelpNotifAction,
} from "../../../../redux/action/helpAction";

const HelpDetailModule = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = firebase.firestore();

  const [isShowHint, setIsShowHint] = useState(true);
  const [message, setMessage] = useState("");
  const [imageState, setImageState] = useState({
    value: null,
    error: null,
  });
  const [fileType, setFileType] = useState("");
  const [fileError, setFileError] = useState("");
  const [chats, setChats] = useState(null);
  const [chatCount, setChatCount] = useState(0);
  const [disableButton, setDisableButton] = useState(true);

  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );

  const { users } = useSelector((state) => state.auth);

  const { dataGetDetailHelp, loadingGetDetailHelp } = useSelector(
    (state) => state.help
  );

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ value: dataUploadImage, error: null });
      setDisableButton(false);
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (imageState?.value === null) {
      if (chatCount < 1) {
        setDisableButton(true);
      } else if (chatCount > 0 && chatCount < 2501) {
        setDisableButton(false);
      } else if (chatCount > 2500) {
        setDisableButton(true);
      }
    } else if (imageState?.value !== null) {
      if (chatCount < 1) {
        setDisableButton(false);
      } else if (chatCount > 0 && chatCount < 2501) {
        setDisableButton(false);
      } else if (chatCount > 2500) {
        setDisableButton(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatCount, imageState?.value]);

  useEffect(() => {
    if (props.ticketId) {
      getChatList(props.ticketId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.ticketId]);

  const getChatList = async (id) =>
    await firestore
      .collection("helpTickets")
      .doc(id)
      .collection("chats")
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            _id: doc.id,
          })
        );
        setChats(temp);
      });

  const sendingChat = () => {
    const dataMessage = {
      createdAt: new Date().getTime(),
      message: message,
      isRead: false,
      user: {
        id: users?.uid,
        name: users?.user?.name,
        type: users?.user?.type,
      },
      type: "message",
    };

    let data = dataMessage;
    if (imageState?.value === null) {
      data = dataMessage;
    } else {
      data = {
        ...data,
        attachments: [
          {
            url: imageState?.value,
            type: fileType,
          },
        ],
      };
    }

    dispatch(postHelpNotifAction(props.ticketId, data));
    dispatch(postHelpChatsAction(props.ticketId, data));
    setChatCount(0);
    setMessage("");
    setImageState({ value: null, error: null });
  };

  const changeMessage = (data) => {
    setMessage(data);
    let counter = "";
    const dataType = ["<p></p>", "<p><br></p>", ""];
    if (dataType.includes(data)) {
      counter = 0;
    } else {
      counter = data
        .replace(/<[^>]*>/g, " ")
        .replace(/\s+/g, " ")
        .trim()?.length;
    }
    setChatCount(counter);
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              <span
                onClick={() => history.goBack()}
                className="icon-back mr-2"
              ></span>
              Detail Bantuan
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          {loadingGetDetailHelp ? (
            <FullPageContent />
          ) : (
            <>
              <div className="help-detail-header">
                <div className="p-d-flex align-items-center">
                  <span className="detail-title">
                    Klik tombol Selesai jika pertanyaan anda sudah terjawab.
                  </span>
                  <Button
                    className="p-button-outlined p-button-rounded sm-rounded btn-wide"
                    label="SELESAI"
                  />
                  <div className={`hint ${!isShowHint ? "d-none" : ""}`}>
                    <div className="tail"></div>
                    <p>
                      Sesi bantuan ini akan berakhir ketika Anda klik tombol
                      selesai
                    </p>
                    <CloseIcon onClick={() => setIsShowHint(!isShowHint)} />
                  </div>
                </div>
              </div>
              <div className="help-detail-content">
                <div className="help-detail-question">
                  <div className={`ticket-item ${dataGetDetailHelp?.category}`}>
                    <div className="p-d-flex p-jc-between">
                      <h3>{dataGetDetailHelp?.title}</h3>
                      <span>{dataGetDetailHelp?.date}</span>
                    </div>
                    <div className="p-d-flex p-jc-between">
                      <span>{dataGetDetailHelp?.category}</span>
                      <span
                        className={`status ${
                          dataGetDetailHelp?.status === "closed"
                            ? "closed"
                            : "open"
                        }`}
                      >
                        {dataGetDetailHelp?.status}
                      </span>
                    </div>
                  </div>
                  <p className="question">{dataGetDetailHelp?.description}</p>
                </div>
                <div className="section-comment">
                  {chats?.map((item) => (
                    <div className="comment-list" key={item._id}>
                      <div className="comment-date">
                        {dayjs(item?.createdAt).format("DD/MM/YYYY HH:mm")}
                      </div>
                      <div className="comment-content">
                        <div className="comment-header">
                          <div className={`chat-avatar ${item?.user?.type}`}>
                            {item?.user?.type === "system"
                              ? "S"
                              : item?.user?.name?.substring(0, 1)}
                          </div>
                          <div className="comment-user">
                            {item?.user?.type === "system"
                              ? "Sistem"
                              : item?.user?.name}{" "}
                            {item?.user?.type !== "system" && (
                              <span>
                                (
                                {item?.user?.type === "pm" ||
                                item?.user?.type === "general_pm"
                                  ? "Manajer Project"
                                  : "Klien"}
                                )
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          className={`comment-text ${
                            item?.user?.type === "system" ? "blue" : ""
                          }`}
                        >
                          <div
                            className="message-inner"
                            dangerouslySetInnerHTML={{
                              __html: `${item?.message}`,
                            }}
                          />
                          {item?.attachments && (
                            <a
                              className="comment-image"
                              href={item?.attachments[0]?.url}
                              target="_blank"
                            >
                              <img
                                src={
                                  item?.attachments[0].type ===
                                  "application/pdf"
                                    ? thumbnailDokumen
                                    : item?.attachments[0]?.url
                                }
                                alt="img"
                              />
                            </a>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                  <div className="comment-box help">
                    <div className="p-d-flex p-jc-between p-ai-center">
                      <label>Balas</label>
                      <p className="text-grey">
                        Anda bisa melampirkan gambar atau dokumen pdf
                      </p>
                    </div>

                    <RichtextWithoutToolbar
                      contentValue={message}
                      changeContent={changeMessage}
                      placeholder="Tulis balasan anda"
                    />
                    {imageState.value !== null && (
                      <div className="image-comment-wrapper">
                        <div className="image-thumb-wrapp">
                          <a
                            href="javascript:void(0)"
                            className="close-image"
                            onClick={() =>
                              setImageState({ value: null, error: null })
                            }
                          >
                            <span className="pi pi-times" />
                          </a>
                          <div className="image-thumb">
                            <img
                              src={
                                fileType === "application/pdf"
                                  ? thumbnailDokumen
                                  : imageState.value
                              }
                              alt={imageState.value}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="comment-upload">
                      <div className="comment-upload-content p-d-flex p-jc-end">
                        <p className="text-danger">{fileError}</p>
                        <p>
                          {chatCount}/<span>2500</span>
                        </p>
                        <UploadImageComment
                          attachment={imageState.value}
                          setType={setFileType}
                          setError={setFileError}
                        />
                      </div>
                    </div>
                    <div className="p-d-flex p-jc-end w-100">
                      <Button
                        className="button-link btn-wide p-button-primary"
                        label="KIRIM"
                        onClick={() => sendingChat()}
                        disabled={disableButton}
                      ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default HelpDetailModule;
