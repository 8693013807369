import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { titlePage } from "../../helpers/titleHead";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import MyReleaseModule from "../../modules/Client/Release/MyReleaseModule";

import { getProjectClientAction } from "../../../redux/action/orderAction";

const MyReleasePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { orderParams } = useSelector((state) => state.order);
  useEffect(() => {
    titlePage({
      title: "IRIS - Project",
    });
    history.push("/client/my-order/confirm");
    dispatch(getProjectClientAction(orderParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="myrelease-page">
      <DashboardClientLayout activeMenu="my-release" title="Project">
        <MyReleaseModule />
      </DashboardClientLayout>
    </div>
  );
};

export default MyReleasePage;
