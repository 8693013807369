import api from "./api";

export const sendNotif = async (token, id, data) => {
  return api.post(`/chats/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendChatClient = async (token, id, data) => {
  return api.post(`/chats/${id}/client`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const sendNotifTicket = async (token, id, data) => {
  return api.post(`chats/${id}/help-ticket`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
