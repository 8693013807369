import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import ListPaymentModule from "../../../modules/Admin/Finance/PaymentModule";
import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";
import DashboardLayout from "../../../layouts/dashboardLayout";

import { listWriterPaymentAction } from "../../../../redux/action/transactionAction";

import { titlePage } from "../../../helpers/titleHead";

const PaymentPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { users } = useSelector((state) => state.auth);
  const isFinance = users?.user?.type === "finance";

  useEffect(() => {
    history.push(`/admin/${isFinance ? "finance" : "pm"}/list-payment`);
    dispatch(
      listWriterPaymentAction({
        page: 1,
        perpage: 10,
      })
    );
    titlePage({
      title: "IRIS - Pembayaran Penulis",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return isFinance ? (
    <DashboardFinanceLayout title="Manajemen Pembayaran" menu="payment">
      <ListPaymentModule isFinance={isFinance} />
    </DashboardFinanceLayout>
  ) : (
    <DashboardLayout title="Manajemen Pembayaran" menu="payment">
      <ListPaymentModule isFinance={isFinance} />
    </DashboardLayout>
  );
};

export default PaymentPage;
