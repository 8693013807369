import React from "react";
import ReactPaginate from "react-paginate";

const AdminPaginate = (props) => {
  return (
    <div className="text-left p-mt-5">
      <ReactPaginate
        pageCount={Math.ceil(props.total / props.perpage)}
        activeLinkClassName={"active"}
        containerClassName={"admin-pagination"}
        previousLabel={<i className="pi pi-angle-left" />}
        nextLabel={<i className="pi pi-angle-right" />}
        breakLabel={"..."}
        marginPagesDisplayed={2}
        pageRangeDisplayed={3}
        onPageChange={(e) => props.clicked(e.selected)}
        forcePage={props.active - 1}
      />
    </div>
  );
};

export default AdminPaginate;
