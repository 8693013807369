import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import PMListModule from "../../../modules/Admin/Superadmin/PMManagement/PMListModule";

import {
  getUsersAction,
  resetStateUserAction,
} from "../../../../redux/action/userAction";

const ListPMPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userParams } = useSelector((state) => state.user);

  useEffect(() => {
    history.push("/admin/pm/list-pm");
    const params = {
      ...userParams,
      type: "general_pm",
    };
    dispatch(getUsersAction(params));
    dispatch(resetStateUserAction());

    titlePage({
      title: "IRIS - Manajemen PM",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen PM" menu="pm">
      <PMListModule />
    </DashboardLayout>
  );
};

export default ListPMPage;
