import React from "react";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuFinanceList({ layoutProps, menu }) {
  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className="menu-item" aria-haspopup="true">
          <NavLink
            className={`menu-link ${menu === "invoice" ? "active" : ""}`}
            to="/admin/finance/list-invoice"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-invoice.svg")}
              />
            </span>
            <span className="menu-text">Manajemen Invoice</span>
          </NavLink>
        </li>
        <li className="menu-item" aria-haspopup="true">
          <NavLink
            className={`menu-link ${menu === "payment" ? "active" : ""}`}
            to="/admin/finance/list-payment"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-payment.svg")}
              />
            </span>
            <span className="menu-text">Manajemen Pembayaran</span>
          </NavLink>
        </li>
        <li className="menu-item" aria-haspopup="true">
          <NavLink
            className={`menu-link ${menu === "payout" ? "active" : ""}`}
            to="/admin/finance/list-request-payout"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-request.svg")}
              />
            </span>
            <span className="menu-text">Manajemen Request Payout</span>
          </NavLink>
        </li>
        <li className="menu-item" aria-haspopup="true">
          <NavLink
            className={`menu-link ${menu === "project" ? "active" : ""}`}
            to="/admin/finance/list-project"
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-invoice.svg")}
              />
            </span>
            <span className="menu-text">Manajemen Project</span>
          </NavLink>
        </li>
      </ul>
    </>
  );
}
