import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

import DialogAlert from "../../../components/dialog/DialogAlert";
import { UploadPhotoProfile } from "../../../components/upload/uploadPhotoProfile";

import ListLoader from "../../../components/loading/listLoader";
import { formUrl } from "../../../helpers/url";

import {
  getDistrictAction,
  resetUtilsStateAction,
} from "../../../../redux/action/utilsAction";

import {
  editWriterProfileAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";
import ButtonDefault from "../../../components/button/ButtonDefault";
import ButtonLink from "../../../components/button/ButtonLink";

export const IMAGE_DEFAULT = process.env.REACT_APP_IMAGE_USER;

const ProfileEditModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  const [nameState, setNameState] = useState({ value: "", error: null });
  const [addressState, setAddressState] = useState({ value: "", error: null });
  const [provinceState, setProvinceState] = useState({
    value: null,
    error: null,
  });
  const [districtState, setDistrictState] = useState({
    value: null,
    error: null,
  });
  const [aboutState, setAboutState] = useState({ value: "", error: null });
  const [imageState, setImageState] = useState({
    value: null,
    error: null,
  });
  const [linkedInState, setLinkedInState] = useState({
    value: "",
    error: null,
  });
  const [instagramState, setInstagramState] = useState({
    value: "",
    error: null,
  });
  const [provinceValue, setProvinceValue] = useState("");
  const [errUpdateDialog, setErrUpdateDialog] = useState(false);

  const {
    dataGetProvince,
    dataGetDistrict,
    dataUploadImage,
    successUploadImage,
  } = useSelector((state) => state.utils);

  const { loadingDetailUser } = useSelector((state) => state.user);

  const {
    users,
    loadingEditWriterProfile,
    successEditWriterProfile,
    errEditWriterProfile,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (users !== null) {
      if (users?.user?.photoURL || users?.user?.photo) {
        setImageState({
          value: users?.user?.photoURL || users?.user?.photo,
          error: null,
        });
      } else {
        setImageState({
          value: IMAGE_DEFAULT,
          error: null,
        });
      }
      setNameState({ value: users?.user?.name, error: null });
      setAddressState({
        value: users?.user?.domicile?.address,
        error: null,
      });
      setAboutState({ value: users?.user?.biography, error: null });
      setProvinceValue(users?.user?.domicile?.province);
      setProvinceState({
        value: { name: users?.user?.domicile?.province, code: 0 },
        error: null,
      });
      setDistrictState({
        value:
          users?.user?.domicile?.cities || users?.user?.domicile?.cities?.code,
        error: null,
      });
      setProvinces(
        provinces?.concat({ name: users?.user?.domicile?.province, code: 0 })
      );
      setDistricts([
        {
          name: users?.user?.domicile?.cities,
          code: users?.user?.domicile?.cities,
        },
      ]);
      if (users?.user?.instagram !== null) {
        setInstagramState({ value: users?.user?.instagram, error: null });
      }
      if (users?.user?.linkedIn !== null) {
        setLinkedInState({ value: users?.user?.linkedIn, error: null });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (dataGetProvince !== null) {
      const dropdownItems = dataGetProvince?.map((item) => ({
        name: item.name,
        code: item.id,
      }));
      setProvinces(provinces?.concat(dropdownItems));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetProvince]);

  useEffect(() => {
    if (
      provinceState.value?.name &&
      dataGetDistrict === null &&
      provinces?.length > 2
    ) {
      const code = provinces.find(
        (province) =>
          province?.name === provinceState.value?.name && province?.code !== 0
      )?.code;
      dispatch(getDistrictAction(parseInt(code)));
    }
  }, [provinceState, dataGetDistrict, dispatch, provinces]);

  useEffect(() => {
    if (districts?.length > 0) {
      const dropdownItems = dataGetDistrict?.map((item) => ({
        name: item.name,
        code: item.name,
      }));
      setDistricts(dropdownItems);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetDistrict]);

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ value: dataUploadImage, error: null });
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (errEditWriterProfile) {
      setErrUpdateDialog(true);
    }
  }, [errEditWriterProfile]);

  useEffect(() => {
    if (successEditWriterProfile) {
      setDialogSuccess(true);
      dispatch(resetUtilsStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successEditWriterProfile]);

  useEffect(() => {
    if (districtState.error || linkedInState.error || instagramState.error) {
      document.getElementById("error").scrollIntoView({ behavior: "smooth" });
    }
  }, [districtState.error, instagramState.error, linkedInState.error]);

  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(resetStateAuthAction());
    setDialogSuccess(false);
    history.push("/profile");
  };

  const handleProvince = (data) => {
    dispatch(getDistrictAction(parseInt(data.code)));
    setProvinceState({ value: data, error: null });
    setProvinceValue(data?.name);
  };

  const submitData = () => {
    let data = {
      name: nameState.value,
      photo: imageState.value,
      domicile: {
        province: provinceValue,
        cities: districtState.value,
        address: addressState.value,
      },
      biography: aboutState.value,
      linkedIn: linkedInState.value,
      instagram: instagramState.value,
    };

    if (linkedInState?.value?.length > 0 && !formUrl(linkedInState?.value)) {
      setLinkedInState({ ...linkedInState, error: "Url tidak valid" });
    }

    if (instagramState?.value?.length > 0 && !formUrl(instagramState?.value)) {
      setInstagramState({ ...instagramState, error: "Url tidak valid" });
    }

    if (provinceValue && !districtState.value) {
      setDistrictState({
        ...districtState,
        error: "Pilih kota/kabupaten Anda",
      });
    } else if (
      (linkedInState?.value?.length > 0 && !formUrl(linkedInState?.value)) ||
      (instagramState?.value?.length > 0 && !formUrl(instagramState?.value))
    ) {
      return null;
    } else {
      dispatch(editWriterProfileAction(data));
    }
  };

  return (
    <>
      <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="card card-custom card-edit-profile mb-4">
          <div className="card-header profile border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Edit Profil
                </span>
              </button>
            </h3>
          </div>
          {loadingDetailUser ? (
            <ListLoader />
          ) : (
            <div className="card-body pt-0 pb-4">
              <div className="form-edit-photo">
                <UploadPhotoProfile
                  attachment={imageState.value}
                  deleteImage={() =>
                    setImageState({
                      value: IMAGE_DEFAULT,
                      error: null,
                    })
                  }
                  path={"user-data"}
                />
              </div>
              <h3>Data Personal</h3>
              <div className="form-add-project">
                <div className="p-field">
                  <label className="p-d-block">
                    Nama Lengkap <span className="text-danger">*</span>
                  </label>
                  <InputText
                    className="p-d-block"
                    placeholder="Masukan Nama"
                    value={nameState.value}
                    onChange={(e) =>
                      setNameState({ value: e.target.value, error: null })
                    }
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block">Email</label>
                  <InputText
                    className="p-d-block"
                    type="email"
                    placeholder="Masukan Email"
                    value={users?.user?.email}
                    disabled
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block">Nomor Telepon</label>
                  <InputText
                    className="p-d-block"
                    placeholder="Masukkan Nomor Telepon"
                    value={users?.user?.phone}
                    disabled
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block">Alamat</label>
                  <InputText
                    className="p-d-block"
                    placeholder="Masukan Alamat"
                    value={addressState.value}
                    onChange={(e) =>
                      setAddressState({ value: e.target.value, error: null })
                    }
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block">Provinsi</label>
                  <Dropdown
                    options={provinces}
                    value={provinceState.value}
                    optionLabel="name"
                    onChange={(e) => handleProvince(e.value)}
                    placeholder="Pilih Provinsi"
                  />
                </div>

                <div
                  className="p-field"
                  id={districtState.error ? "district-error" : undefined}
                >
                  <label className="p-d-block">Kota / Kabupaten</label>
                  <Dropdown
                    options={districts}
                    value={districtState.value}
                    onChange={(e) =>
                      setDistrictState({ value: e.target.value, error: null })
                    }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kota / Kabupaten"
                    disabled={districts?.length < 1}
                  />
                  <small className="p-error p-d-block" id="error">
                    {districtState.error}
                  </small>
                </div>
                <div
                  className="p-field p-grid social-grid"
                  id={
                    instagramState.error || linkedInState.error
                      ? "error"
                      : undefined
                  }
                >
                  <label className="p-d-block p-col-12">
                    Link Sosial Media
                  </label>
                  <div className="p-col-6">
                    <InputText
                      className={`${linkedInState.error !== null &&
                        "p-invalid"} p-d-block`}
                      placeholder="LinkedIn"
                      value={linkedInState.value}
                      onChange={(e) =>
                        setLinkedInState({ value: e.target.value, error: null })
                      }
                    />
                    {linkedInState.error !== null && (
                      <small className="p-error p-d-block">
                        {linkedInState.error}
                      </small>
                    )}
                  </div>
                  <div className="p-col-6">
                    <InputText
                      className={`${instagramState.error !== null &&
                        "p-invalid"} p-d-block`}
                      placeholder="Instagram"
                      value={instagramState.value}
                      onChange={(e) =>
                        setInstagramState({
                          value: e.target.value,
                          error: null,
                        })
                      }
                    />
                    {instagramState.error !== null && (
                      <small className="p-error p-d-block">
                        {instagramState.error}
                      </small>
                    )}
                  </div>
                  <span className="p-col-12 text-helper-italic">
                    *Silahkan masukan url, contoh:
                    https://media-sosialmu.domain/nickname
                  </span>
                </div>

                <div className="p-field">
                  <label className="p-d-block">Biografi</label>
                  <InputTextarea
                    rows={5}
                    cols={30}
                    value={aboutState.value}
                    onChange={(e) =>
                      setAboutState({ value: e.target.value, error: null })
                    }
                  />
                </div>
              </div>
              <div className="footer-form edit-profile">
                <div className="form-add-project p-d-flex p-jc-end p-ai-center">
                  <ButtonLink
                    link="/profile"
                    type="bordered"
                    className="mr-2"
                    label="batal"
                  />

                  <ButtonDefault
                    label="Edit"
                    loading={loadingEditWriterProfile}
                    disabled={nameState?.value?.length < 1}
                    onClick={() => submitData()}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={closeSuccess}
        title={"Berhasil Memperbarui Data"}
        text={"Data user berhasil diperbarui"}
      />
      <DialogAlert
        type={"error"}
        visible={errUpdateDialog}
        closeDialog={() => setErrUpdateDialog(false)}
        title={"Gagal Perbarui Data"}
        text={"Terjadi masalah dengan sistem. Coba lagi nanti"}
      />
    </>
  );
};

export default ProfileEditModule;
