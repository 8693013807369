import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import queryString from "query-string";

import images from "../../assets/img/ellipsis-loading.gif";
import imagesSuccess from "../../assets/img/iso-success.svg";
import imageError from "../../assets/img/iso-error.svg"
import { titlePage } from "../../helpers/titleHead";

import { joinProjectTokenAction } from "../../../redux/action/projectAction";

const ProjectInvitationPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search);
  const token = parsed.token;
  const { successJoinProjectWithToken, errJoinProjectWithToken } = useSelector((state) => state.project);

  useEffect(() => {
    titlePage({
      title: "IRIS - Project Invitation",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (token) {
      const data = {
        token: token,
      };
      dispatch(joinProjectTokenAction(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="fullpage-content">
      <div className="not-found-card">
        {!successJoinProjectWithToken && !errJoinProjectWithToken ? (
          <>
            <img className="loading-image" src={images} alt="loading" />
            <h4>Silakan Tunggu..</h4>
          </>
        ) : (
          <>
            <img
              className="success-image"
              src={successJoinProjectWithToken ? imagesSuccess : imageError}
              alt="success"
            />
            {
              successJoinProjectWithToken ?
                <>
                  <h4>Berhasil Bergabung Dengan Project.</h4>
                  <h4>Silakan Login Untuk Melanjutkan</h4>
                </>
                :
                <>
                  <h4>Undangan telah kadaluarsa</h4>
                </>
            }
                  <Link
                    className="p-button p-button-outlined p-button-rounded p-mt-4"
                    to="/login"
                  >
                    Halaman Login
                  </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default ProjectInvitationPage;
