/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import Slider from "react-slick";
import BannerLoader from "../loading/bannerLoader";

const CarouselSlide = (props) => {
  const { banners, loading } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {loading ? (
        <BannerLoader />
      ) : (
        <Slider {...settings}>
          {banners?.map((banner) => (
            <div key={banner.id}>
              <div className="p-d-flex p-justify-center">
                <div className="p-grid">
                  <div className="p-sm-12 p-md-5 p-lg-5">
                    <div className="hero-banner image">
                      <img src={banner?.image} alt="banner" />
                    </div>
                  </div>
                  <div className="p-sm-12 p-md-7 p-lg-7">
                    <div className="hero-banner">
                      <h1>{banner?.title}</h1>
                      <p>{banner?.description}</p>
                      {banner?.url && (
                        <a
                          href={banner?.url}
                          className="p-button p-button-primary"
                          target="_blank"
                        >
                          {banner?.buttonLabel || "Selengkapnya"}
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      )}
    </>
  );
};

export default CarouselSlide;
