import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { titlePage } from "../../helpers/titleHead";
import FAQModule from "../../modules/User/FAQ/FAQModule";
import { getFaqAction } from "../../../redux/action/landingpageAction";
import StaticLayout from "../../layouts/staticLayout";

const FAQPage = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    titlePage({
      title: "IRIS - FAQ",
    });

    const query = {
      orderDir: "asc",
    };
    dispatch(getFaqAction(query));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticLayout>
      <FAQModule />
    </StaticLayout>
  );
};

export default FAQPage;
