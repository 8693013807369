import {
  SEND_CHAT_FAILURE,
  SEND_CHAT_REQUEST,
  SEND_CHAT_SUCCESS,
  SEND_NOTIF_FAILURE,
  SEND_NOTIF_REQUEST,
  SEND_NOTIF_SUCCESS,
  UNPIN_CHAT_FAILURE,
  UNPIN_CHAT_REQUEST,
  UNPIN_CHAT_SUCCESS,
  SEND_CHAT_CLIENT_FAILURE,
  SEND_CHAT_CLIENT_REQUEST,
  SEND_CHAT_CLIENT_SUCCESS,
  SEND_CHAT_CLIENT_FS_FAILURE,
  SEND_CHAT_CLIENT_FS_REQUEST,
  SEND_CHAT_CLIENT_FS_SUCCESS,
} from "../constant/chatConstant";

const initialStateChat = {
  loadingSendChat: false,
  successSendChat: false,
  errSendChat: null,

  loadingSendNotif: false,
  successSendNotif: false,
  errSendNotif: null,

  loadingSUnpinChat: false,
  successSUnpinChat: false,
  errSUnpinChat: null,

  loadingSendChatClient: false,
  successSendChatClient: false,
  errSendChatClient: null,

  loadingSendChatClientFs: false,
  successSendChatClientFs: false,
  errSendChatClientFs: null,
};

export const chatReducer = (state = initialStateChat, action) => {
  switch (action.type) {
    case SEND_CHAT_REQUEST:
      return {
        ...state,
        loadingSendChat: true,
        successSendChat: false,
        errSendChat: null,
      };
    case SEND_CHAT_SUCCESS:
      return {
        ...state,
        loadingSendChat: false,
        successSendChat: true,
        errSendChat: null,
      };
    case SEND_CHAT_FAILURE:
      return {
        ...state,
        loadingSendChat: false,
        successSendChat: false,
        errSendChat: action.data,
      };

    case SEND_NOTIF_REQUEST:
      return {
        ...state,
        loadingSendNotif: true,
        successSendNotif: false,
        errSendNotif: null,
      };
    case SEND_NOTIF_SUCCESS:
      return {
        ...state,
        loadingSendNotif: false,
        successSendNotif: true,
        errSendNotif: null,
      };
    case SEND_NOTIF_FAILURE:
      return {
        ...state,
        loadingSendNotif: false,
        successSendNotif: false,
        errSendNotif: action.data,
      };

    case UNPIN_CHAT_REQUEST:
      return {
        ...state,
        loadingUnpinChat: true,
        successUnpinChat: false,
        errUnpinChat: null,
      };
    case UNPIN_CHAT_SUCCESS:
      return {
        ...state,
        loadingUnpinChat: false,
        successUnpinChat: true,
        errUnpinChat: null,
      };
    case UNPIN_CHAT_FAILURE:
      return {
        ...state,
        loadingUnpinChat: false,
        successUnpinChat: false,
        errUnpinChat: action.data,
      };

    case SEND_CHAT_CLIENT_REQUEST:
      return {
        ...state,
        loadingSendChatClient: true,
        successSendChatClient: false,
        errSendChatClient: null,
      };
    case SEND_CHAT_CLIENT_SUCCESS:
      return {
        ...state,
        loadingSendChatClient: false,
        successSendChatClient: true,
        errSendChatClient: null,
      };
    case SEND_CHAT_CLIENT_FAILURE:
      return {
        ...state,
        loadingSendChatClient: false,
        successSendChatClient: false,
        errSendChatClient: action.data,
      };

    case SEND_CHAT_CLIENT_FS_REQUEST:
      return {
        ...state,
        loadingSendChatClientFs: true,
        successSendChatClientFs: false,
        errSendChatClientFs: null,
      };
    case SEND_CHAT_CLIENT_FS_SUCCESS:
      return {
        ...state,
        loadingSendChatClientFs: false,
        successSendChatClientFs: true,
        errSendChatClientFs: null,
      };
    case SEND_CHAT_CLIENT_FS_FAILURE:
      return {
        ...state,
        loadingSendChatClientFs: false,
        successSendChatClientFs: false,
        errSendChatClientFs: action.data,
      };

    default:
      return state;
  }
};
