import React from "react";
import SuccessImage from "../../assets/img/iso-success.svg";
import WarningImage from "../../assets/img/iso-warning.svg";
import ErrorImage from "../../assets/img/iso-error.svg";
import ButtonDefault from "../button/ButtonDefault";

import { Dialog } from "primereact/dialog";
const DialogAlert = (props) => {
  const {
    type,
    actionDialog,
    actionText,
    visible,
    closeDialog,
    title,
    text,
    loading,
    cancelText,
    cancelTextColor,
  } = props;

  let image;
  if (type === "warning") {
    image = WarningImage;
  } else if (type === "error") {
    image = ErrorImage;
  } else {
    image = SuccessImage;
  }
  return (
    <Dialog className="no-header" visible={visible} onHide={closeDialog}>
      <div className="notif-alert">
        <img src={image} alt={image} />
        <h3>{title}</h3>
        <p>{text}</p>
        <div className="p-d-flex p-jc-center dialog-footer">
          <ButtonDefault
            label={cancelText || "TUTUP"}
            onClick={closeDialog}
            className={`p-button mt-3 ${actionDialog && "mr-3"}`}
            type="rounded-bordered"
            color={!cancelTextColor && "danger"}
            disabled={loading}
          />
          {actionDialog && (
            <ButtonDefault
              onClick={actionDialog}
              label={actionText || "Submit"}
              className="p-button p-button-primary ml-3 mt-3"
              type="rounded"
              loading={loading}
            />
          )}
        </div>
      </div>
    </Dialog>
  );
};

export default DialogAlert;
