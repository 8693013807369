import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { AsideMenuList } from "./AsideMenuList";
import { AsideMenuSuperList } from "./AsideMenuSuperList";
import { useHtmlClassService } from "../../../_core/MetronicLayout";

export function AsideMenu({ disableScroll, menu }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  const { users } = useSelector((state) => state.auth);

  return (
    <>
      {/* begin::Menu Container */}
      <div
        id="kt_aside_menu"
        data-menu-vertical="1"
        className={`aside-menu my-4 ${layoutProps.asideClassesFromConfig}`}
        {...layoutProps.asideMenuAttr}
      >
        {users?.user?.type === "pm" ? (
          <AsideMenuSuperList layoutProps={layoutProps} menu={menu} />
        ) : (
          <AsideMenuList layoutProps={layoutProps} menu={menu} />
        )}
      </div>
      {/* end::Menu Container */}
    </>
  );
}
