import api from "./api";

export const getWinnerRelease = async (token, params) => {
  return api.get("/pm/press-release", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getDetailRelease = async (token, id, params) => {
  return api.get(`/pm/press-release/${id}/media`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postRelease = async (token, data) => {
  return api.post(`/pm/media`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateRelease = async (token, id, data) => {
  return api.put(`/pm/media/${id}/update`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteRelease = async (token, id) => {
  return api.delete(
    `/pm/media/${id}/delete`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const statsRelease = async (token) => {
  return api.get(
    `/pm/press-release/stats`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const statsMediaRelease = async (token, id) => {
  return api.get(
    `/pm/press-release/${id}/media/stats`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
