import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import BalanceModule from "../../modules/User/Balance/BalanceModule";

import { detailUserAction } from "../../../redux/action/userAction";
import { writerTransactionAction } from "../../../redux/action/transactionAction";
import { titlePage } from "../../helpers/titleHead";

const BalancePage = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);

  const params = {
    perPage: 10,
    page: 1,
  };

  useEffect(() => {
    dispatch(detailUserAction(users?.uid));
    dispatch(writerTransactionAction(params));

    titlePage({
      title: "IRIS - My Balance",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardUserLayout>
      <BalanceModule />
    </DashboardUserLayout>
  );
};

export default BalancePage;
