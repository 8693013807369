/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, useParams } from "react-router-dom";
import queryString from "query-string";

import { Button } from "primereact/button";

import TextBody from "../../../components/datatableBody/TextBody";
import NumberBody from "../../../components/datatableBody/NumberBody";
// import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import AdminPaginate from "../../../components/paging/pagination";
import DialogAlert from "../../../components/dialog/DialogAlert";

// import FilterListUser from "./Components/FilterListUser";
import {
  assignPICAction,
  getPICListAction,
  resetPICAction,
} from "../../../../redux/action/clientPicAction";
import ResponsiveTable from "../../../components/table/ResponsiveTable";
import ResponsiveColumn from "../../../components/table/ResponsiveColumn";

const AssignPICModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryParams = useParams();
  const parsed = queryString.parse(location.search);
  const [showSuccessDialog, setShowSuccessDialog] = useState(false);
  const [showWarning, setShowWarning] = useState(false);
  const [showDangerDialog, setShowDangerDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");
  const [warningTitle, setWarningTitle] = useState("");
  const [assignedPIC, setAssignedPIC] = useState();

  const {
    loadingGetPICList,
    dataGetPICList,
    loadingAssignPIC,
    successAssignPIC,
    totalPage,
    errAssignPIC,
  } = useSelector((state) => state.clientPic);

  useEffect(() => {
    if (successAssignPIC) {
      setShowSuccessDialog(true);
      setShowWarning(false);
    }
  }, [successAssignPIC]);

  useEffect(() => {
    if (errAssignPIC) {
      setShowDangerDialog(true);
      setShowWarning(false);
      setDialogText(errAssignPIC?.message);
    }
  }, [errAssignPIC]);

  const { dataDetailOrder } = useSelector((state) => state.order);

  const nameBodyTemplate = (item) => {
    return (
      <TextBody text={item.name} link={`/client/pic/detail/${item?._id}`} />
    );
  };

  const numberBodyTemplate = (item) => {
    return (
      <NumberBody
        link={`/client/pic/detail/${item?._id}`}
        number={item?.nProjectHandle ? item?.nProjectHandle : 0}
      />
    );
  };

  const actionBodyTemplate = (item) => {
    return (
      <div className="p-d-flex p-jc-end">
        <Button
          className="p-button-primary btn-wide"
          label="ASSIGN"
          onClick={() => handleClickAssign(item?.name, item?._id)}
        />
      </div>
    );
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search,
      page: page + 1,
      perpage: 10,
    };
    history.push(
      `/client/my-release/assign-pic/${queryParams.projectId}?search=${
        parsed.search
      }&page=${page + 1}`
    );
    dispatch(getPICListAction(params));
  };

  const handleClickAssign = (name, userId) => {
    setShowWarning(true);
    setWarningTitle(
      `Jadikan ${name} sebagai PIC di project ${dataDetailOrder?.title}?`
    );
    setDialogText(`Kami akan mengirimkan notifikasi ke akun ${name}`);
    setAssignedPIC({ name, userId });
  };

  const closeSuccessDialog = () => {
    setShowSuccessDialog(false);
    dispatch(resetPICAction());
    history.push(`/client/order-detail/confirm/${queryParams.projectId}`);
  };

  const closeDanger = () => {
    setShowDangerDialog(false);
    dispatch(resetPICAction());
  };

  const assignPic = (data) => {
    dispatch(assignPICAction(queryParams.projectId, data));
  };

  return (
    <>
      <div className="card card-custom assign-pic">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span
              className="card-label font-weight-bolder text-dark"
              onClick={() => history.goBack()}
            >
              <span className="icon-back mr-2"></span>List Tim
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          {loadingGetPICList ? (
            <TableLoader />
          ) : (
            <>
              <>
                <ResponsiveTable
                  value={dataGetPICList}
                  mobileTitle="Nama Tim"
                  className="no-padding admin"
                >
                  <ResponsiveColumn
                    header="Nama Tim"
                    body={nameBodyTemplate}
                  ></ResponsiveColumn>
                  <ResponsiveColumn
                    header="Jumlah Project Berjalan"
                    body={numberBodyTemplate}
                  ></ResponsiveColumn>
                  <ResponsiveColumn
                    headerClassName="action-button"
                    body={actionBodyTemplate}
                  ></ResponsiveColumn>
                </ResponsiveTable>
              </>
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={showSuccessDialog}
        closeDialog={closeSuccessDialog}
        title={"Berhasil Assign PIC"}
      />
      <DialogAlert
        type={"warning"}
        visible={showWarning}
        closeDialog={() => setShowWarning(false)}
        title={warningTitle}
        text={dialogText}
        actionText={"ASSIGN"}
        cancelText={"BATAL"}
        actionDialog={() => assignPic(assignedPIC)}
        loading={loadingAssignPIC}
      />
      <DialogAlert
        type={"danger"}
        visible={showDangerDialog}
        closeDialog={closeDanger}
        title={"Gagal Assign PIC"}
        text={dialogText}
      />
    </>
  );
};

export default AssignPICModule;
