import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";

import { clientDataProjectAction } from "../../../redux/action/utilsAction";
import DetailMyReleaseModuleDraft from "../../modules/Client/DraftRelease/DetailMyReleaseModule";
import { getProjectDetailClientAction, resetProjectDetailData, resetStateProjectAction } from "../../../redux/action/projectAction";

function DetailDraftMyReleasePage() {
  const dispatch = useDispatch();
  const queryParams = useParams();

  useEffect(() => {
    titlePage({
      title: "IRIS - Draft Project",
    });
    dispatch(getProjectDetailClientAction(queryParams.projectId));
    dispatch(clientDataProjectAction(null));
    dispatch(resetStateProjectAction());
    dispatch(resetProjectDetailData());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardClientLayout title="Detail Draft Release" menu="project">
      <DetailMyReleaseModuleDraft projectId={queryParams.projectId} />
    </DashboardClientLayout>
  );
}

export default DetailDraftMyReleasePage;
