import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import dayjs from "dayjs";
import "dayjs/locale/id";
import _ from "lodash";

import DeleteIcon from "../../../assets/img/trash.svg";
import { InputText } from "primereact/inputtext";

import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";
import StatusProject from "../../../components/badge/StatusProject";

import {
  getListInfoAction,
  deleteInfoAction,
  resetStateAdminAction,
} from "../../../../redux/action/adminAction";

const ListInfoModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogAlert, setDialogAlert] = useState({
    type: "",
    dialog: false,
    title: "",
    text: "",
  });
  const [dataTable, setDataTable] = useState(null);
  const [deletedId, setDeletedId] = useState("");

  const {
    loadingListInfo,
    dataListInfo,
    totalPage,
    loadingDeleteInfo,
    successDeleteInfo,
  } = useSelector((state) => state.admin);

  useEffect(() => {
    if (dataListInfo) {
      setDataTable(dataListInfo);
    }
  }, [dataListInfo]);

  useEffect(() => {
    if (successDeleteInfo) {
      setDialogAlert({
        dialog: true,
        type: "success",
        title: `Berhasil Hapus Data`,
        text: "Data Berhasil Dihapus",
      });
    }
  }, [successDeleteInfo]);

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
      };

      history.push(`/admin/pm/list-information?search=${search}&page=1`);
      dispatch(getListInfoAction(params));
    }, 1000)
  ).current;

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getListInfoAction(params));

    history.push(
      `/admin/pm/list-information?search=${parsed?.search || ""}&page=${page +
        1}`
    );
  };

  const deleteArticle = (data) => {
    const deleteItem = () => {
      dispatch(deleteInfoAction(data?._id));
      setDeletedId(data?._id);
    };
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: `Hapus ${data?.title}?`,
      text: "Informasi ini akan dihapus dan hilang dari publikasi",
      actionText: "Hapus",
      actionDialog: () => deleteItem(),
    });
  };

  const closeAlert = () => {
    setDialogAlert({ type: "", dialog: false, title: "", text: "" });
    if (successDeleteInfo) {
      const dataAfterRemoved = dataTable?.filter(
        (item) => item?._id !== deletedId
      );
      setDataTable(dataAfterRemoved);
      dispatch(resetStateAdminAction());
    }
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Informasi
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="p-d-flex p-jc-between p-mb-3">
            <div className="search-list-project">
              <div className="d-flex">
                <InputText
                  id="search"
                  aria-describedby="search-help"
                  className="p-d-block"
                  placeholder="Cari"
                  onChange={(e) => searchingHandler(e.target.value)}
                />
              </div>
              <p>
                Cari berdasarkan<span> Nama Informasi</span>
              </p>
            </div>
            <Link
              to="/admin/pm/create-information"
              className="p-button p-button-primary button-link"
            >
              Tambah Informasi
            </Link>
          </div>
          {loadingListInfo ? (
            <TableLoader />
          ) : (
            <div className="card-info-bottom">
              {dataTable?.length > 0 ? (
                <>
                  {dataTable?.map((item) => (
                    <div key={item?._id} className="article-box">
                      <Link
                        to={`/admin/pm/information/${item?._id}`}
                        className="article-left"
                      >
                        <h3>{item?.title}</h3>
                        <StatusProject status={item?.status} />
                      </Link>
                      <div className="article-right">
                        <p>
                          {dayjs(item?.createdAt)
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </p>
                        <button
                          onClick={() => deleteArticle(item)}
                          className="btn btn-action"
                        >
                          <img src={DeleteIcon} alt="" />
                        </button>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <DataEmpty />
              )}

              {totalPage > 10 && (
                <AdminPaginate
                  active={parsed?.page || 1}
                  total={totalPage}
                  clicked={paginationHandler}
                  perpage={10}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeAlert()}
        title={dialogAlert.title}
        text={dialogAlert.text}
        loading={loadingDeleteInfo}
        actionDialog={dialogAlert.actionDialog}
        actionText={dialogAlert.actionText}
      />
    </>
  );
};

export default ListInfoModule;
