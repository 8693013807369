import React, { useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

import TextBody from "../../../../components/datatableBody/TextBody";
import NumberBody from "../../../../components/datatableBody/NumberBody";
import TableLoader from "../../../../components/loading/tableLoader";
import DataEmpty from "../../../../components/emptyState/dataEmpty";
import ButtonDefault from "../../../../components/button/ButtonDefault";

import { getUsersAction } from "../../../../../redux/action/userAction";
import { updateProjectAction } from "../../../../../redux/action/projectAction";

const DialogPm = (props) => {
  const dispatch = useDispatch();

  const { dataGetUsers, loadingGetUsers } = useSelector((state) => state.user);

  useEffect(() => {
    const params = {
      page: 1,
      perPage: 50,
      type: "general_pm",
    };
    dispatch(getUsersAction(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderFooter = () => {
    return (
      <>
        <ButtonDefault
          label="Batal"
          onClick={props.closeDialog}
          type="borderer"
          color="grey"
        />
      </>
    );
  };

  const selectPm = (data) => {
    dispatch(updateProjectAction(props.projectId, data));
  };

  const textBodyTemplate = (item) => {
    return <TextBody text={item?.name} />;
  };
  const projectBodyTemplate = (item) => {
    return <NumberBody number={item?.nProjectHandle || 0} text="Project" />;
  };

  const actionBodyTemplate = (item) => {
    return (
      <div className="w-100 p-d-flex p-jc-end">
        <ButtonDefault
          label="Pilih"
          onClick={() => selectPm({ pmName: item?.name, pmId: item._id })}
          size="fit"
        />
      </div>
    );
  };

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = { page: 1, perPage: 50, type: "general_pm", q: search };
      dispatch(getUsersAction(params));
    }, 1000)
  ).current;

  return (
    <div className="dialog-invite">
      <div className="card">
        <Dialog
          header="Assign Manajer Project"
          visible={props.dialogPm}
          style={{ width: "650px" }}
          footer={renderFooter(props.dialogUser)}
          onHide={props.closeDialog}
          className="list-user-dialog"
        >
          <div className="p-text-left invite-wrapper">
            <div className="p-field p-text-left p-pt-5">
              <div className="filter-wrapp">
                <InputText
                  id="search"
                  aria-describedby="search-help"
                  className="p-d-block search-inputtext"
                  placeholder="Cari "
                  onChange={(e) => searchingHandler(e.target.value)}
                />
                <p>
                  Cari berdasarkan <span>Nama</span>
                </p>
              </div>
            </div>
            <div className="p-field p-text-left p-pt-5">
              {loadingGetUsers ? (
                <TableLoader />
              ) : (
                <>
                  {dataGetUsers === null || dataGetUsers?.length < 1 ? (
                    <DataEmpty />
                  ) : (
                    <>
                      <DataTable value={dataGetUsers} className="no-padding">
                        <Column
                          header="Nama Manager"
                          body={textBodyTemplate}
                        ></Column>
                        <Column
                          header="Jumlah Project"
                          body={projectBodyTemplate}
                        ></Column>
                        <Column body={actionBodyTemplate}></Column>
                      </DataTable>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DialogPm;
