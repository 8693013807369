import React from "react";
import Image from "../../assets/img/data-empty.svg";

const DataEmpty = (props) => {
  const { text } = props;
  return (
    <div className="data-empty-wrap">
      <img src={Image} alt="data-empty" />
      <p>{text || "Data tidak tersedia"}</p>
    </div>
  );
};

export default DataEmpty;
