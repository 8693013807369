/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import dayjs from "dayjs";
import "dayjs/locale/id";

import {
  updateStatusProjectAction,
  getArticleAction,
  getLogArticleAction,
} from "../../../../../redux/action/projectAction";

const DialogReview = (props) => {
  const dispatch = useDispatch();
  const { dataGetArticle, loadingGetArticle, dataLogArticle } = useSelector(
    (state) => state.project
  );
  useEffect(() => {
    if (props?.projectId !== null) {
      dispatch(getArticleAction(props?.projectId));
      dispatch(getLogArticleAction(props?.projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Revisi"
          onClick={submitRevision}
          disabled={
            dataGetArticle?.status === "revision" ||
            dataGetArticle?.status === "winner"
          }
          className="p-button-rounded"
        />
      </div>
    );
  };

  const submitRevision = (e) => {
    e.preventDefault();
    const data = { status: "revision" };
    const query = "";
    dispatch(updateStatusProjectAction(props?.projectId, data, query));
  };

  return (
    <div className="dialog-invite">
      <div className="card">
        <Dialog
          header="Review Artikel"
          visible={props.dialogReview}
          style={{ width: "800px" }}
          footer={renderFooter()}
          onHide={props.closeReview}
        >
          <div className="p-text-left review-wrapper">
            <div className="p-text-left review-wrapper">
              <h2 className="title-wrapp">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${dataGetArticle?.articleTitle}`,
                  }}
                />
              </h2>

              {dataGetArticle?.articleImage && (
                <div className="image-wrapp">
                  <img src={dataGetArticle?.articleImage} />
                </div>
              )}
              <div className="content-wrapp">
                <div
                  dangerouslySetInnerHTML={{
                    __html: `${dataGetArticle?.articleContent}`,
                  }}
                />
              </div>
              <div className="history-wrapp">
                <h6>Riwayat Aktivitas</h6>
                {dataLogArticle?.map((item) => (
                  <div className="history-list" key={item?.id}>
                    <strong>
                      {dayjs(item?.date?.toDate())
                        .locale("id")
                        .format("DD MMMM YYYY HH:mm")}
                    </strong>{" "}
                    - {item?.message}
                  </div>
                ))}
                {dataLogArticle?.length < 1 && (
                  <div className="history-list">Belum ada aktivitas</div>
                )}
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DialogReview;
