import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../components/header/Header";
import HeaderMobileLanding from "../components/header/HeaderMobileLanding";
import Footer from "../components/footer/Footer";
import { usePageTracking } from "../helpers/pageTracking";
import { checkDevice } from "../helpers/deviceDetect";
import { getSettingSiteAction } from "../../redux/action/landingpageAction";

const LandingLayout = ({ children, title }) => {
  usePageTracking();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMobile, setIsMobile] = useState(checkDevice());

  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getSettingSiteAction());
    setIsMobile(checkDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users && users?.user?.type === "writer") {
      history.push("/dashboard");
    }
    if (
      users &&
      (users?.user?.type === "pm" || users?.user?.type === "general_pm")
    ) {
      history.push("/admin/pm/list-project");
    }
    if (users && users?.user?.type === "client") {
      history.push("/client/dashboard");
    }
  });

  return (
    <div className="landing-page">
      {isMobile ? (
        <HeaderMobileLanding
          dataSite={dataGetSettingSite}
          loading={loadingGetSettingSite}
        />
      ) : (
        <Header
          title={title}
          dataSite={dataGetSettingSite}
          loading={loadingGetSettingSite}
        />
      )}
      {children}
      <Footer dataSite={dataGetSettingSite} loading={loadingGetSettingSite} />
    </div>
  );
};

export default LandingLayout;
