import React from "react";
import { Link } from "react-router-dom";

const StatusBadge = (props) => {
  const { user } = props;
  let name;
  let style = props?.status;

  const isClient = user === "client";
  const isWriter = user === "writer";

  switch (props?.status) {
    case "published":
      if (isClient || isWriter) {
        name = "Tulisan Terpilih";
        style = "winner";
      } else {
        name = "Publish";
      }
      break;
    case "winner":
      name = "Tulisan Terpilih";
      break;
    case "pm_approved":
      if (isClient) {
        name = "Review";
      } else {
        name = "Review Klien";
      }
      break;
    case "sent":
      if (isClient) {
        name = "Berlangsung";
        style = "ongoing";
      } else if (isWriter) {
        name = "Terkirim";
      } else {
        name = "Dikirim Penulis";
      }
      break;
    case "revision":
      name = "revisi";
      break;
    case "ongoing":
      name = "Berlangsung";
      break;
    case "removed":
      name = "Nonaktif";
      break;
    case "unpublished":
      if (isClient || isWriter) {
        name = "Tulisan Terpilih";
        style = "winner";
      } else {
        name = "Unpublish";
      }
      break;
    case "only_view":
      name = "Hanya Bisa Melihat";
      break;
    case "new":
      name = "Baru";
      break;
    case "draft":
      name = "Draft";
      break;
    case "payment_waiting":
      name = "Belum Dibayar";
      style = "ongoing";
      break;
    case "confirmation_waiting":
      name = "Belum Dikonfirmasi";
      style = "draft";
      break;
    case "confirmed":
      name = "Tekonfirmasi";
      style = "winner";
      break;
    case "rejected":
      name = "Ditolak";
      style = "only_view";
      break;
    case "done":
      name = "Selesai";
      style = "winner";
      break;
    case "join":
      name = "Bergabung";
      style = "draft";
      break;
    case "success":
      name = "Berhasil";
      style = "winner";
      break;
    case "request":
      name = "Menunggu";
      style = "draft";
      break;
    case "process":
      name = "Menunggu";
      style = "draft";
      break;
    case "public":
      name = "Publik";
      style = "new";
      break;
    case "private":
      name = "Privat";
      style = "unpublished";
      break;

    default:
      name = props?.status;
      break;
  }

  return props?.link ? (
    <Link
      className={`badge-status ${style} ${props?.className}`}
      to={props?.link}
    >
      {name}
    </Link>
  ) : (
    <div className={`badge-status ${style} ${props?.className}`}>{name}</div>
  );
};

export default StatusBadge;
