import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getPICListAction } from "../../../redux/action/clientPicAction";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import AssignPICModule from "../../modules/Client/Release/AssignPICModule";
import { getOrderDetailAction } from "../../../redux/action/orderAction";

function AssignPICPage() {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const { dataDetailOrder } = useSelector((state) => state.order);
  useEffect(() => {
    titlePage({
      title: "IRIS - Pesanan",
    });
    dispatch(getPICListAction());
    if (!dataDetailOrder) {
      dispatch(getOrderDetailAction(queryParams.projectId));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout
      title="My Release"
      mobileTitle="List Tim"
      menu="project"
    >
      <AssignPICModule />
    </DashboardClientLayout>
  );
}

export default AssignPICPage;
