/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useHistory } from "react-router-dom";

import ProductDetailForm from "./Components/ProductDetailForm";

const AddProductModule = () => {
  const history = useHistory();
  return (
    <>
      <div className="p-d-flex p-jc-center detail-pm">
        <div className="card card-custom col-8 mb-4">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Tambah Produk
                </span>
              </button>
            </h3>
          </div>
          <ProductDetailForm dataProduct={null} type={"createpage"} />
        </div>
      </div>
    </>
  );
};

export default AddProductModule;
