import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";

import ButtonDefault from "../../../components/button/ButtonDefault";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { FormLoad } from "../../../components/loading/loadingContent";
import { formUrl } from "../../../helpers/url";

import {
  postMediaReleaseAction,
  updateMediaReleaseAction,
  resetStateReleaseAction,
} from "../../../../redux/action/releaseAction";

const MediaShareFormModule = (props) => {
  const { releaseId, typeForm, mediaId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const isEdit = typeForm === "edit";

  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);
  const [dataMedia, setDataMedia] = useState([]);
  const [mediaState, mediaStateChange] = useState({ value: null, error: null });
  const [mediaDetail, mediaDetailChange] = useState({
    name: "",
    tier: "",
    prValue: "",
  });
  const [titleState, titleStateChange] = useState({ value: "", error: null });
  const [linkState, linkStateChange] = useState({ value: "", error: null });
  const [dialogAlert, setDialogAlert] = useState({
    dialog: false,
    type: "",
    title: "",
    text: "",
  });

  const {
    dataListMedia,
    successAddRelease,
    errAddRelease,
    successUpdateRelease,
    errUpdateRelease,
    dataNews,
    loadingNews,
  } = useSelector((state) => state.release);

  useEffect(() => {
    if (isEdit && dataNews) {
      titleStateChange({ value: dataNews?.title, error: null });
      linkStateChange({ value: dataNews?.mediaLink, error: null });
      mediaStateChange({ value: dataNews?.mediaTypeId, error: null });
      mediaDetailChange({
        name: dataNews?.mediaName,
        tier: dataNews?.mediaTier,
        prValue: dataNews?.prValue,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, dataNews]);

  useEffect(() => {
    if (dataListMedia) {
      const list = dataListMedia?.map((item) => ({
        name: item.mediaName,
        code: item.id,
      }));
      setDataMedia(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListMedia]);

  useEffect(() => {
    if (mediaState.value?.length > 0) {
      const filterMedia = dataListMedia
        ?.filter((item) => item.id === mediaState.value)
        .reduce((item) => item);
      mediaDetailChange({
        name: filterMedia?.mediaName,
        tier: filterMedia?.mediaTier,
        prValue: filterMedia?.ratecard * 3,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mediaState.value]);

  useEffect(() => {
    if (successAddRelease) {
      setTimeout(() => {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          text: "Data berita berhasil ditambahkan",
        });
        setLoadingAdd(false);
      }, 2500);
    }
    if (errAddRelease) {
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "GAGAL!",
        text: "Data berita gagal ditambahkan",
      });
      setLoadingAdd(false);
    }
  }, [successAddRelease, errAddRelease]);

  useEffect(() => {
    if (successUpdateRelease) {
      setTimeout(() => {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          text: "Data berita berhasil diupdate",
        });
        setLoadingUpdate(false);
      }, 2500);
    }
    if (errUpdateRelease) {
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "GAGAL!",
        text: "Data berita gagal diupdate",
      });
      setLoadingUpdate(false);
    }
  }, [successUpdateRelease, errUpdateRelease]);

  const closeDialog = () => {
    setDialogAlert({ dialog: false, type: "", title: "", text: "" });
    if (successAddRelease || successUpdateRelease) {
      history.push(`/admin/pm/detail-release/${releaseId}`);
      dispatch(resetStateReleaseAction());
    } else if (errAddRelease || errUpdateRelease) {
      dispatch(resetStateReleaseAction());
    }
  };

  const submitData = () => {
    const data = {
      mediaTypeId: mediaState?.value,
      mediaName: mediaDetail.name,
      mediaTier: mediaDetail.tier,
      prValue: mediaDetail.prValue,
      title: titleState.value,
      mediaLink: linkState.value,
      pressReleaseId: releaseId,
    };

    if (titleState.value?.length < 1) {
      titleStateChange({
        ...titleState,
        error: "Judul berita tidak boleh kosong",
      });
    }

    if (mediaState.value === null) {
      mediaStateChange({
        ...mediaState,
        error: "Nama media tidak boleh kosong",
      });
    }

    if (linkState.value?.length < 1) {
      linkStateChange({
        ...linkState,
        error: "Tautan berita tidak boleh kosong",
      });
    } else if (!formUrl(linkState.value)) {
      linkStateChange({
        ...linkState,
        error: "Tautan berita tidak valid",
      });
    }

    if (
      titleState.value?.length > 0 &&
      mediaState.value !== null &&
      linkState.value?.length > 0 &&
      formUrl(linkState.value)
    ) {
      if (typeForm === "add") {
        dispatch(postMediaReleaseAction(data));
        setLoadingAdd(true);
      } else if (typeForm === "edit") {
        dispatch(updateMediaReleaseAction(mediaId, data));
        setLoadingUpdate(true);
      }
    }
  };

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-header">
          <div className="card-title">
            <div className="card-title-back" onClick={() => history.goBack()}>
              <span className="icon-back"></span>
            </div>
            <h2 className="card-title-text">
              {isEdit ? "Edit Berita" : "Tambah Output Berita"}
            </h2>
          </div>
        </div>
        <div className="card-body">
          {loadingNews ? (
            <div className="dashboard-half-form">
              <FormLoad />
            </div>
          ) : (
            <div className="dashboard-half-form">
              <div className="p-field">
                <label className="p-d-block">Judul Berita</label>
                <InputText
                  value={titleState.value}
                  onChange={(e) =>
                    titleStateChange({ value: e.target.value, error: null })
                  }
                  className="p-d-block"
                  placeholder="Masukan Judul Berita"
                  type="text"
                />
                {titleState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {titleState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block" id="release-category">
                  Nama Media
                </label>
                <Dropdown
                  value={mediaState.value}
                  options={dataMedia}
                  onChange={(e) =>
                    mediaStateChange({ value: e.target.value, error: null })
                  }
                  optionLabel="name"
                  optionValue="code"
                  placeholder="Pilih Media"
                  filter
                  filterBy="name"
                />
                {mediaState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {mediaState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">PR Value(Rp)</label>
                <InputText
                  value={mediaDetail.prValue}
                  className="p-d-block"
                  placeholder="PR Value"
                  type="text"
                  readOnly
                />
              </div>
              <div className="p-field">
                <label className="p-d-block">Tier</label>
                <InputText
                  value={mediaDetail.tier}
                  className="p-d-block"
                  placeholder="Tier"
                  type="text"
                  readOnly
                />
              </div>
              <div className="p-field">
                <label className="p-d-block">Tautan Berita</label>
                <InputText
                  className="p-d-block"
                  placeholder="Masukan Tautan Berita"
                  type="link"
                  value={linkState.value}
                  onChange={(e) =>
                    linkStateChange({ value: e.target.value, error: null })
                  }
                />
                {linkState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {linkState.error}
                  </small>
                )}
              </div>
              <div className="button-action-wrapp">
                <ButtonDefault
                  label="Batal"
                  type="bordered"
                  onClick={() => history.goBack()}
                  disabled={isEdit ? loadingUpdate : loadingAdd}
                />
                <ButtonDefault
                  label="Simpan Data"
                  className="ml-3"
                  onClick={() => submitData()}
                  loading={isEdit ? loadingUpdate : loadingAdd}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
        loading={typeForm === "add" ? loadingAdd : loadingUpdate}
      />
    </>
  );
};

export default MediaShareFormModule;
