import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import ListProjectModule from "../../../modules/Admin/Finance/ListProjectFinanceModule";
import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";

import { getFinanceProjectAction } from "../../../../redux/action/financeAction";
import { titlePage } from "../../../helpers/titleHead";

const ListProjectFinance = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  useEffect(() => {
    dispatch(
      getFinanceProjectAction({
        page: parsed?.page ? parsed?.page : 1,
        perpage: 10,
        orderDir: "desc",
        orderField: "createdAt",
      })
    );
    titlePage({
      title: "IRIS - Daftar Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardFinanceLayout title="Manajemen Project" menu="project">
      <ListProjectModule />
    </DashboardFinanceLayout>
  );
};

export default ListProjectFinance;
