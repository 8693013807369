import React from "react";
import DashboardFinanceLayout from "../../../layouts/dashboardFinanceLayout";
import SettingModule from "../../../modules/Admin/Finance/SettingModule";
const FinanceSettingPage = () => {
  return (
    <DashboardFinanceLayout title="Manajemen Invoice">
      <SettingModule />
    </DashboardFinanceLayout>
  );
};

export default FinanceSettingPage;
