import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import ButtonLoader from "../../../components/button/ButtonLoader";

import { emailRegisterAction } from "../../../../redux/action/authAction";

const RegisterEmailModule = (props) => {
  const dispatch = useDispatch();
  const [password, setPassword] = useState({ value: "", error: null });

  const { loadingEmailRegister } = useSelector((state) => state.auth);

  const submitEmail = (e) => {
    e.preventDefault();
    if (password?.value?.length < 6) {
      setPassword({
        ...password,
        error: "Password minimal 6 karakter",
      });
    } else {
      const data = { token: props.tokenUrl, password: password.value };
      dispatch(emailRegisterAction(data));
    }
  };

  return (
    <>
      <div className="p-field p-mt-0">
        <InputText
          id="phone"
          aria-describedby="phone-number"
          className="p-d-block"
          placeholder="Masukkan Email"
          value={props.emailUrl}
          type="email"
          disabled
        />
      </div>

      <div className="p-field my-2">
        <InputText
          className={`${password.error !== null && "p-invalid"} p-d-block`}
          placeholder="Masukkan Password"
          type="password"
          value={password.value}
          onChange={(e) => setPassword({ value: e.target.value, error: null })}
        />
        {password.error !== null && (
          <small className="p-error p-d-block p-text-right">
            {password.error}
          </small>
        )}
      </div>
      {loadingEmailRegister ? (
        <ButtonLoader />
      ) : (
        <Button
          label="Daftar"
          className="p-button-rounded p-button-lg"
          onClick={submitEmail}
        />
      )}
    </>
  );
};

export default RegisterEmailModule;
