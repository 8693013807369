import firebase from "./firebase";
import "firebase/storage";

export const singleUpload = async (photo, path) =>
  new Promise(async (resolve, reject) => {
    const imageRef = await firebase.storage().ref(path);
    await imageRef.put(photo).then(async (snapshot) => {
      if (snapshot.state === "success") {
        const downloadUrl = await imageRef.getDownloadURL();
        resolve(downloadUrl);
      } else {
        reject(new Error("upload failed"));
      }
    });
  });

export const singleDelete = async (url) => {
  const imageRef = await firebase.storage().refFromURL(url);
  const deletedImage = await imageRef.delete();
  return deletedImage;
};
