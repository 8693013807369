import React from "react";
import { useParams } from "react-router-dom";
import UpdateEmail from "./Components/UpdateEmail";
import UpdatePassword from "./Components/UpdatePassword";

function UpdateSettingsModule() {
  const QueryParams = useParams();
  return (
    <div>
      {QueryParams.changedDetail === "email" && <UpdateEmail />}
      {QueryParams.changedDetail === "password" && <UpdatePassword />}
    </div>
  );
}

export default UpdateSettingsModule;
