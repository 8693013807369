/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import AddDetail from "./Components/FormAddDetail";
import AddRelease from "./Components/FormAddRelease";
import DialogAlert from "../../../components/dialog/DialogAlert";
import dayjs from "dayjs";

import {
  createProjectAction,
  resetStateProjectAction,
  countProjectAction,
  updateCountProjectAction,
} from "../../../../redux/action/projectAction";
import {
  resetDataAddProjectAction,
  resetUtilsStateAction,
} from "../../../../redux/action/utilsAction";

const AddProjectModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [layout, setLayout] = useState("detail");
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);

  const { dataAddProject } = useSelector((state) => state.utils);
  const { users } = useSelector((state) => state.auth);
  const {
    loadingCreateProject,
    successCreateProject,
    dataCountProject,
    successCountProject,
  } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(resetUtilsStateAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(resetDataAddProjectAction());
    dispatch(resetStateProjectAction());
    setDialogSuccess(false);
    history.push("/admin/pm/list-project");
  };

  useEffect(() => {
    if (successCountProject) {
      const numberPO = dataCountProject?.projectCount + 1;
      const data = {
        title: dataAddProject?.detail?.title,
        category: dataAddProject?.detail?.category,
        type: dataAddProject?.detail?.type,
        thumbnail: dataAddProject?.detail?.thumbnail,
        description: dataAddProject?.detail?.description,
        price: parseInt(dataAddProject?.detail?.price),
        deadlineDate: dayjs(dataAddProject?.detail?.deadlineDate).toDate(),
        clientName: dataAddProject?.detail?.company?.clientName,
        jobNumber: `PO${numberPO.toString().padStart(5, "0")}`,
        clientEmail: dataAddProject?.detail?.company?.clientEmail,
        clientCompany: {
          name: dataAddProject?.detail?.company?.name,
          businessField: dataAddProject?.detail?.company?.business,
          companyBackground: dataAddProject?.detail?.company?.background,
          companyId: dataAddProject?.detail?.company?.companyId,
        },
        pmName: dataAddProject?.detail?.pm?.pmName,
        pmId: dataAddProject?.detail?.pm?.pmId,
        createdBy: {
          name: users?.user?.name,
          userId: users?.uid,
          type: users?.user?.type,
        },
        status: "new",
        searchKeyword: dataAddProject?.release?.searchKeyword,
        releaseCategory: dataAddProject?.release?.releaseCategory,
        releaseCategoryQuestion:
          dataAddProject?.release?.releaseCategoryQuestion,
        objective: dataAddProject?.release?.objective,
        target: {
          sex: dataAddProject?.release?.target?.sex,
          age: dataAddProject?.release?.target?.age,
          stakeholders: dataAddProject?.release?.target?.stakeholders,
        },
        attribution: dataAddProject?.release?.attribution,
        releaseNote: dataAddProject?.release?.releaseNote,
        nWriter: 0,
        writerJoinedIds: [],
        briefUrl: dataAddProject?.release?.briefUrl,
        winnerQuota: parseInt(dataAddProject?.detail?.quota),
      };
      dispatch(createProjectAction(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCountProject]);

  useEffect(() => {
    if (successCreateProject) {
      setTimeout(() => {
        setDialogWarning(false);
        setDialogSuccess(true);
      }, 1500);
      dispatch(updateCountProjectAction(dataCountProject?.projectCount + 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successCreateProject]);

  const handleSubmit = () => {
    dispatch(countProjectAction());
  };

  return (
    <>
      <div className="card card-custom mb-4">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <a onClick={() => history.goBack()}>
              <span className="card-label font-weight-bolder text-dark">
                <span className="icon-back mr-2"></span>Tambah Project
              </span>
            </a>
          </h3>
        </div>
        <div className="card-body pt-0 pb-4">
          <div className="step-add">
            <ul>
              <li className="active">
                <div className="step-number">
                  <span>1</span>
                </div>
                Detail Project
              </li>
              <li className={layout === "release" ? "active" : "none"}>
                <div className="step-number">
                  <span>2</span>
                </div>
                Press Release
              </li>
            </ul>
          </div>
        </div>
      </div>
      {layout === "detail" && (
        <AddDetail setBrief={() => setLayout("release")} type="add" />
      )}
      {layout === "release" && (
        <AddRelease
          submitData={() => setDialogWarning(true)}
          goBack={() => setLayout("detail")}
          type="add"
        />
      )}
      <DialogAlert
        type={"warning"}
        actionDialog={() => handleSubmit()}
        actionText={"SIMPAN"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Simpan Data?"}
        text={"Pastikan data yang anda masukan benar"}
        loading={loadingCreateProject || successCreateProject}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={closeSuccess}
        title={"Berhasil Menyimpan"}
        text={"Data project berhasil tersimpan"}
      />
    </>
  );
};

export default AddProjectModule;
