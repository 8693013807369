import firebase from "./firebase";
import fb from "firebase/app";

const firestore = firebase.firestore();

export const getOrderDetail = async (id) =>
  await firestore
    .collection("orders")
    .doc(id)
    .get();

export const getOrderSaveDetail = async (id) =>
  await firestore
    .collection("orders-inquiry")
    .doc(id)
    .get();

export const updateOrder = async (id, data) =>
  await firestore
    .collection("orders")
    .doc(id)
    .update({
      ...data,
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
    });
