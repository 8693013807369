export const INVITE_USER_REQUEST = "INVITE_USER_REQUEST";
export const INVITE_USER_SUCCESS = "INVITE_USER_SUCCESS";
export const INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

export const RE_INVITE_USER_REQUEST = "RE_INVITE_USER_REQUEST";
export const RE_INVITE_USER_SUCCESS = "RE_INVITE_USER_SUCCESS";
export const RE_INVITE_USER_FAILURE = "INVITE_USER_FAILURE";

export const GET_USER_REQUEST = "GET_USER_REQUEST";
export const GET_USER_SUCCESS = "GET_USER_SUCCESS";
export const GET_USER_FAILURE = "GET_USER_FAILURE";

export const GET_DETAIL_USER_REQUEST = "GET_DETAIL_USER_REQUEST";
export const GET_DETAIL_USER_SUCCESS = "GET_DETAIL_USER_SUCCESS";
export const GET_DETAIL_USER_FAILURE = "GET_DETAIL_USER_FAILURE";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_FAILURE = "UPDATE_USER_FAILURE";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";

export const UPLOAD_PORTFOLIO_IMAGE_REQUEST = "UPLOAD_PORTFOLIO_IMAGE_REQUEST";
export const UPLOAD_PORTFOLIO_IMAGE_SUCCESS = "UPLOAD_PORTFOLIO_IMAGE_SUCCESS";
export const UPLOAD_PORTFOLIO_IMAGE_FAILURE = "UPLOAD_PORTFOLIO_IMAGE_FAILURE";
export const UPLOAD_PORTFOLIO_IMAGE_RESET = "UPLOAD_PORTFOLIO_IMAGE_RESET";

export const UPLOAD_COMPANY_PROFILE_REQUEST = "UPLOAD_COMPANY_PROFILE_REQUEST";
export const UPLOAD_COMPANY_PROFILE_SUCCESS = "UPLOAD_COMPANY_PROFILE_SUCCESS";
export const UPLOAD_COMPANY_PROFILE_FAILURE = "UPLOAD_COMPANY_PROFILE_FAILURE";
export const UPLOAD_COMPANY_PROFILE_RESET = "UPLOAD_COMPANY_PROFILE_RESET";

export const GET_PORTFOLIO_REQUEST = "GET_PORTFOLIO_REQUEST";
export const GET_PORTFOLIO_SUCCESS = "GET_PORTFOLIO_SUCCESS";
export const GET_PORTFOLIO_FAILURE = "GET_PORTFOLIO_FAILURE";

export const RESET_STATE_USER = "RESET_STATE_USER";

export const UPDATE_STATUS_APPLICANT_REQUEST =
  "UPDATE_STATUS_APPLICANT_REQUEST";
export const UPDATE_STATUS_APPLICANT_SUCCESS =
  "UPDATE_STATUS_APPLICANT_SUCCESS";
export const UPDATE_STATUS_APPLICANT_FAILURE =
  "UPDATE_STATUS_APPLICANT_FAILURE";

export const POST_PM_REQUEST = "POST_PM_REQUEST";
export const POST_PM_SUCCESS = "POST_PM_SUCCESS";
export const POST_PM_FAILURE = "POST_PM_FAILURE";

export const UPDATE_STATUS_USER_REQUEST = "UPDATE_STATUS_USER_REQUEST";
export const UPDATE_STATUS_USER_SUCCESS = "UPDATE_STATUS_USER_SUCCESS";
export const UPDATE_STATUS_USER_FAILURE = "UPDATE_STATUS_USER_FAILURE";

export const POST_WRITER_BANK_REQUEST = "POST_WRITER_BANK_REQUEST";
export const POST_WRITER_BANK_SUCCESS = "POST_WRITER_BANK_SUCCESS";
export const POST_WRITER_BANK_FAILURE = "POST_WRITER_BANK_FAILURE";

export const GET_WRITER_BANK_REQUEST = "GET_WRITER_BANK_REQUEST";
export const GET_WRITER_BANK_SUCCESS = "GET_WRITER_BANK_SUCCESS";
export const GET_WRITER_BANK_FAILURE = "GET_WRITER_BANK_FAILURE";

export const UPDATE_WRITER_BANK_REQUEST = "UPDATE_WRITER_BANK_REQUEST";
export const UPDATE_WRITER_BANK_SUCCESS = "UPDATE_WRITER_BANK_SUCCESS";
export const UPDATE_WRITER_BANK_FAILURE = "UPDATE_WRITER_BANK_FAILURE";
