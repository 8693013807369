import React from "react";
import { useHistory } from "react-router-dom";

const ImageProjectBody = (props) => {
  const { image, link } = props;
  const history = useHistory();
  return (
    <div className="image-table" onClick={() => history.push(link)}>
      <div className="img-wrap">
        <img src={image} alt="thumbnail" />
      </div>
    </div>
  );
};

export default ImageProjectBody;
