import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import CreateProjectModule from "../../modules/Client/CreateProject/CreateProjectModule";
import { titlePage } from "../../helpers/titleHead";
import { orderClientDetailAction } from "../../../redux/action/orderAction";
import {
  clientDataProjectAction,
  getAgeAction,
  getGenderAction,
  getCategoryReleaseAction,
  getSesAction,
  getStakeholderAction,
} from "../../../redux/action/utilsAction";
import { detailClientAction } from "../../../redux/action/clientAction";
import { getProductDetailAction } from "../../../redux/action/serviceAction";
import { resetProjectDetailData, resetStateProjectAction } from "../../../redux/action/projectAction";

function ClientCreateProject() {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const { dataDetailOrderClient } = useSelector((state) => state.order);
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    titlePage({
      title: "IRIS - Project",
    });
    dispatch(getProductDetailAction(queryParams.productId))
    dispatch(orderClientDetailAction(queryParams.projectId));
    dispatch(resetStateProjectAction());
    dispatch(resetProjectDetailData());
    dispatch(getAgeAction());
    dispatch(getGenderAction());
    dispatch(getCategoryReleaseAction());
    dispatch(getSesAction());
    dispatch(getStakeholderAction());
    if (users) {
      dispatch(detailClientAction(users?.user?.companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    const data = {
      title: "",
      category: "",
      type: "",
      storyAngles: "",
      ideationFeature: "",
      desc: "",
      deadline: "",
      releaseCategory: "",
      objective: "",
      attribution: "" ? "" : "",
      releaseNote: "" ? "" : "",
      target: {
        sex: "",
        age: "",
        ses: "",
        stakeholders: "",
      },
      keyword: "",
    };
    dispatch(clientDataProjectAction(data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailOrderClient]);

  return (
    <DashboardClientLayout title="Buat Brief" menu="product">
      <CreateProjectModule projectId={queryParams.productId} />
    </DashboardClientLayout>
  );
}

export default ClientCreateProject;
