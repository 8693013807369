import {
  GET_SERVICE_FAILURE,
  GET_SERVICE_REQUEST,
  GET_SERVICE_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_REQUEST,
  GET_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_REQUEST,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_REQUEST,
  ADD_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_REQUEST,
  GET_PRODUCT_DETAIL_SUCCESS,
  SERVICE_STATE_RESET,
} from "../constant/serviceConstant";

const initialStateService = {
  totalPage: null,
  loadingGetService: false,
  dataGetService: null,
  errGetService: null,

  loadingGetProduct: false,
  dataGetProduct: null,
  errGetProduct: null,

  loadingDetailProduct: false,
  dataDetailProduct: null,
  errDetailProduct: null,

  loadingUpdateProduct: false,
  successUpdateProduct: false,
  errUpdateProduct: null,

  loadingAddProduct: false,
  successAddProduct: false,
  errAddProduct: null,

  loadingDeleteProduct: false,
  successDeleteProduct: false,
  errDeleteProduct: null,
};

export const serviceReducer = (state = initialStateService, action) => {
  switch (action.type) {
    case SERVICE_STATE_RESET:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: false,
        errUpdateProduct: null,
        loadingAddProduct: false,
        successAddProduct: false,
        errAddProduct: null,
        loadingDeleteProduct: false,
        successDeleteProduct: false,
        errDeleteProduct: null,
      };

    case GET_SERVICE_REQUEST:
      return {
        ...state,
        loadingGetService: true,
        errGetService: null,
      };
    case GET_SERVICE_SUCCESS:
      return {
        ...state,
        loadingGetService: false,
        dataGetService: action.data,
        errGetService: null,
      };
    case GET_SERVICE_FAILURE:
      return {
        ...state,
        loadingGetService: false,
        dataGetService: null,
        errGetService: action.data,
      };

    case GET_PRODUCT_REQUEST:
      return {
        ...state,
        loadingGetProduct: true,
        errGetProduct: null,
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingGetProduct: false,
        dataGetProduct: action.data.data,
        totalPage: action.data.totalpage,
        errGetProduct: null,
      };
    case GET_PRODUCT_FAILURE:
      return {
        ...state,
        loadingGetProduct: false,
        dataGetProduct: null,
        errGetProduct: action.data,
      };

    case GET_PRODUCT_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetailProduct: true,
        errDetailProduct: null,
      };
    case GET_PRODUCT_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetailProduct: false,
        dataDetailProduct: action.data,
        errDetailProduct: null,
      };
    case GET_PRODUCT_DETAIL_FAILURE:
      return {
        ...state,
        loadingDetailProduct: false,
        dataDetailProduct: null,
        errDetailProduct: action.data,
      };

    case ADD_PRODUCT_REQUEST:
      return {
        ...state,
        loadingAddProduct: true,
        successAddProduct: false,
        errAddProduct: null,
      };
    case ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingAddProduct: false,
        successAddProduct: true,
        errAddProduct: null,
      };
    case ADD_PRODUCT_FAILURE:
      return {
        ...state,
        loadingAddProduct: false,
        successAddProduct: false,
        errAddProduct: action.err,
      };

    case UPDATE_PRODUCT_REQUEST:
      return {
        ...state,
        loadingUpdateProduct: true,
        successUpdateProduct: false,
        errUpdateProduct: null,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: true,
        errUpdateProduct: null,
      };
    case UPDATE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingUpdateProduct: false,
        successUpdateProduct: false,
        errUpdateProduct: action.err,
      };

    case DELETE_PRODUCT_REQUEST:
      return {
        ...state,
        loadingDeleteProduct: true,
        successDeleteProduct: false,
        errDeleteProduct: null,
      };
    case DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        loadingDeleteProduct: false,
        successDeleteProduct: true,
        errDeleteProduct: null,
      };
    case DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        loadingDeleteProduct: false,
        successDeleteProduct: false,
        errDeleteProduct: action.err,
      };

    default:
      return state;
  }
};
