import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import MediaShareDetailModule from "../../../modules/Admin/MediaShare/MediaShareDetailModule";
import {
  getDetailReleaseAction,
  statsMediaReleaseAction,
} from "../../../../redux/action/releaseAction";

const MediaShareDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useParams();

  useEffect(() => {
    history.push(`/admin/pm/detail-release/${queryParams?.releaseId}`);
    titlePage({
      title: "IRIS - Manajemen Media Distribusi",
    });
    const params = {
      page: 1,
      perpage: 10,
    };
    dispatch(getDetailReleaseAction(queryParams?.releaseId, params));
    dispatch(statsMediaReleaseAction(queryParams?.releaseId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Media Distribusi" menu="distribution">
      <MediaShareDetailModule releaseId={queryParams?.releaseId} />
    </DashboardLayout>
  );
};

export default MediaShareDetailPage;
