import {
  INVITE_USER_FAILURE,
  INVITE_USER_REQUEST,
  INVITE_USER_SUCCESS,
  RE_INVITE_USER_FAILURE,
  RE_INVITE_USER_REQUEST,
  RE_INVITE_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  RESET_STATE_USER,
  GET_DETAIL_USER_FAILURE,
  GET_DETAIL_USER_REQUEST,
  GET_DETAIL_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  UPLOAD_PORTFOLIO_IMAGE_REQUEST,
  UPLOAD_PORTFOLIO_IMAGE_FAILURE,
  UPLOAD_PORTFOLIO_IMAGE_SUCCESS,
  UPLOAD_PORTFOLIO_IMAGE_RESET,
  GET_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_REQUEST,
  GET_PORTFOLIO_SUCCESS,
  UPDATE_STATUS_APPLICANT_FAILURE,
  UPDATE_STATUS_APPLICANT_REQUEST,
  UPDATE_STATUS_APPLICANT_SUCCESS,
  POST_PM_FAILURE,
  POST_PM_REQUEST,
  POST_PM_SUCCESS,
  UPDATE_STATUS_USER_FAILURE,
  UPDATE_STATUS_USER_REQUEST,
  UPDATE_STATUS_USER_SUCCESS,
  UPLOAD_COMPANY_PROFILE_SUCCESS,
  UPLOAD_COMPANY_PROFILE_REQUEST,
  UPLOAD_COMPANY_PROFILE_FAILURE,
  UPLOAD_COMPANY_PROFILE_RESET,
  POST_WRITER_BANK_FAILURE,
  POST_WRITER_BANK_REQUEST,
  POST_WRITER_BANK_SUCCESS,
  GET_WRITER_BANK_FAILURE,
  GET_WRITER_BANK_REQUEST,
  GET_WRITER_BANK_SUCCESS,
  UPDATE_WRITER_BANK_FAILURE,
  UPDATE_WRITER_BANK_REQUEST,
  UPDATE_WRITER_BANK_SUCCESS,
} from "../constant/userConstant";

const initialStateUsers = {
  userParams: {
    q: "",
    page: 1,
    perpage: 10,
    orderField: "updatedAt",
    orderDir: "desc",
  },
  totalPage: null,

  loadingInviteUser: false,
  successInviteUser: false,
  errInviteUser: null,

  loadingReInviteUser: false,
  successReInviteUser: false,
  errReInviteUser: null,

  loadingGetUsers: false,
  dataGetUsers: null,
  errGetUsers: null,

  loadingDetailUser: false,
  dataDetailUser: null,
  errDetailUser: null,

  loadingUpdateUser: false,
  successUpdateUser: false,
  errUpdateUser: null,

  loadingDeleteUser: false,
  successDeleteUser: false,
  errDeleteUser: null,

  loadingUploadPortfolio: false,
  successUploadPortfolio: false,
  errUploadPortfolio: null,
  dataUploadPortfolio: null,

  loadingUploadCompanyProfile: false,
  successUploadCompanyProfile: false,
  errUploadCompanyProfile: null,
  dataUploadCompanyProfile: null,

  loadingGetFilePortfolio: false,
  successGetFilePortfolio: false,
  errGetFilePortfolio: null,
  dataGetFilePortfolio: null,

  loadingUpdateStatusApplicant: false,
  successUpdateStatusApplicant: false,
  errUpdateStatusApplicant: null,

  loadingPostPm: false,
  successPostPm: false,
  errPostPm: null,

  loadingUpdateStatusUser: false,
  successUpdateStatusUser: false,
  errUpdateStatusUser: null,

  loadingPostWriterBank: false,
  successPostWriterBank: false,
  errPostWriterBank: null,

  loadingGetWriterBank: false,
  dataGetWriterBank: null,
  errGetWriterBank: null,

  loadingUpdateWriterBank: false,
  successUpdateWriterBank: false,
  errUpdateWriterBank: null,
};

export const usersReducer = (state = initialStateUsers, action) => {
  switch (action.type) {
    case INVITE_USER_REQUEST:
      return {
        ...state,
        loadingInviteUser: true,
        errInviteUser: null,
      };
    case INVITE_USER_SUCCESS:
      return {
        ...state,
        loadingInviteUser: false,
        successInviteUser: true,
        errInviteUser: null,
      };
    case INVITE_USER_FAILURE:
      return {
        ...state,
        loadingInviteUser: false,
        successInviteUser: false,
        errInviteUser: action.data,
      };

    case RE_INVITE_USER_REQUEST:
      return {
        ...state,
        loadingReInviteUser: true,
        errReInviteUser: null,
      };
    case RE_INVITE_USER_SUCCESS:
      return {
        ...state,
        loadingReInviteUser: false,
        successReInviteUser: true,
        errReInviteUser: null,
      };
    case RE_INVITE_USER_FAILURE:
      return {
        ...state,
        loadingReInviteUser: false,
        successReInviteUser: false,
        errReInviteUser: action.data,
      };

    case GET_USER_REQUEST:
      return {
        ...state,
        loadingGetUsers: true,
        errGetUsers: null,
      };
    case GET_USER_SUCCESS:
      return {
        ...state,
        loadingGetUsers: false,
        dataGetUsers: action.data.data,
        errGetUsers: null,
        totalPage: action.data.meta.total,
      };
    case GET_USER_FAILURE:
      return {
        ...state,
        loadingGetUsers: false,
        dataGetUsers: false,
        errGetUsers: action.data,
      };

    case RESET_STATE_USER:
      return {
        ...state,
        loadingReInviteUser: false,
        successReInviteUser: false,
        errReInviteUser: null,
        loadingUpdateUser: false,
        successUpdateUser: false,
        errUpdateUser: null,
        loadingDeleteUser: false,
        successDeleteUser: false,
        errDeleteUser: null,
        loadingInviteUser: false,
        successInviteUser: false,
        errInviteUser: null,
        loadingUpdateStatusApplicant: false,
        successUpdateStatusApplicant: false,
        errUpdateStatusApplicant: null,
        loadingPostPm: false,
        successPostPm: false,
        errPostPm: null,
        loadingUpdateStatusUser: false,
        successUpdateStatusUser: false,
        errUpdateStatusUser: null,
      };

    case GET_DETAIL_USER_REQUEST:
      return {
        ...state,
        loadingDetailUser: true,
        errDetailUser: null,
      };
    case GET_DETAIL_USER_SUCCESS:
      return {
        ...state,
        loadingDetailUser: false,
        dataDetailUser: action.data,
        errDetailUser: null,
      };
    case GET_DETAIL_USER_FAILURE:
      return {
        ...state,
        loadingDetailUser: false,
        errDetailUser: action.data,
      };

    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loadingUpdateUser: true,
        successUpdateUser: false,
        errUpdateUser: null,
      };
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loadingUpdateUser: false,
        successUpdateUser: true,
        errUpdateUser: null,
      };
    case UPDATE_USER_FAILURE:
      return {
        ...state,
        loadingUpdateUser: false,
        successUpdateUser: false,
        errUpdateUser: action.data,
      };

    case DELETE_USER_REQUEST:
      return {
        ...state,
        loadingDeleteUser: true,
        successDeleteUser: false,
        errDeleteUser: null,
      };
    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loadingDeleteUser: false,
        successDeleteUser: true,
        errDeleteUser: null,
      };
    case DELETE_USER_FAILURE:
      return {
        ...state,
        loadingDeleteUser: false,
        successDeleteUser: false,
        errDeleteUser: action.data,
      };

    case UPLOAD_PORTFOLIO_IMAGE_REQUEST:
      return {
        ...state,
        loadingUploadPortfolio: true,
        successUploadPortfolio: false,
        errUploadPortfolio: null,
      };
    case UPLOAD_PORTFOLIO_IMAGE_SUCCESS:
      return {
        ...state,
        loadingUploadPortfolio: false,
        successUploadPortfolio: true,
        dataUploadPortfolio: action.data,
      };
    case UPLOAD_PORTFOLIO_IMAGE_FAILURE:
      return {
        ...state,
        loadingUploadPortfolio: false,
        successUploadPortfolio: false,
        errUploadPortfolio: action.err,
      };
    case UPLOAD_PORTFOLIO_IMAGE_RESET:
      return {
        ...state,
        loadingUploadPortfolio: false,
        successUploadPortfolio: false,
        errUploadPortfolio: null,
        dataUploadPortfolio: null,
      };

    case UPLOAD_COMPANY_PROFILE_REQUEST:
      return {
        ...state,
        loadingUploadCompanyProfile: true,
        successUploadCompanyProfile: false,
        errUploadCompanyProfile: null,
      };
    case UPLOAD_COMPANY_PROFILE_SUCCESS:
      return {
        ...state,
        loadingUploadCompanyProfile: false,
        successUploadCompanyProfile: true,
        dataUploadCompanyProfile: action.data,
      };
    case UPLOAD_COMPANY_PROFILE_FAILURE:
      return {
        ...state,
        loadingUploadCompanyProfile: false,
        successUploadCompanyProfile: false,
        errUploadCompanyProfile: action.err,
      };
    case UPLOAD_COMPANY_PROFILE_RESET:
      return {
        ...state,
        loadingUploadCompanyProfile: false,
        successUploadCompanyProfile: false,
        errUploadCompanyProfile: null,
        dataUploadCompanyProfile: null,
      };

    case GET_PORTFOLIO_REQUEST:
      return {
        ...state,
        loadingGetFilePortfolio: true,
        successGetFilePortfolio: false,
        errGetFilePortfolio: null,
      };
    case GET_PORTFOLIO_SUCCESS:
      return {
        ...state,
        loadingGetFilePortfolio: false,
        successGetFilePortfolio: true,
        dataGetFilePortfolio: action.data,
      };
    case GET_PORTFOLIO_FAILURE:
      return {
        ...state,
        loadingGetFilePortfolio: false,
        successGetFilePortfolio: false,
        errGetFilePortfolio: action.err,
      };

    case UPDATE_STATUS_APPLICANT_REQUEST:
      return {
        ...state,
        loadingUpdateStatusApplicant: true,
        successUpdateStatusApplicant: false,
        errUpdateStatusApplicant: null,
      };
    case UPDATE_STATUS_APPLICANT_SUCCESS:
      return {
        ...state,
        loadingUpdateStatusApplicant: false,
        successUpdateStatusApplicant: true,
        errUpdateStatusApplicant: null,
      };
    case UPDATE_STATUS_APPLICANT_FAILURE:
      return {
        ...state,
        loadingUpdateStatusApplicant: false,
        successUpdateStatusApplicant: false,
        errUpdateStatusApplicant: action.data,
      };

    case POST_PM_REQUEST:
      return {
        ...state,
        loadingPostPm: true,
        successPostPm: false,
        errPostPm: null,
      };
    case POST_PM_SUCCESS:
      return {
        ...state,
        loadingPostPm: false,
        successPostPm: true,
        errPostPm: null,
      };
    case POST_PM_FAILURE:
      return {
        ...state,
        loadingPostPm: false,
        successPostPm: false,
        errPostPm: action.data,
      };

    case UPDATE_STATUS_USER_REQUEST:
      return {
        ...state,
        loadingUpdateStatusUser: true,
        successUpdateStatusUser: false,
        errUpdateStatusUser: null,
      };
    case UPDATE_STATUS_USER_SUCCESS:
      return {
        ...state,
        loadingUpdateStatusUser: false,
        successUpdateStatusUser: true,
        errUpdateStatusUser: null,
      };
    case UPDATE_STATUS_USER_FAILURE:
      return {
        ...state,
        loadingUpdateStatusUser: false,
        successUpdateStatusUser: false,
        errUpdateStatusUser: action.data,
      };

    case GET_WRITER_BANK_REQUEST:
      return {
        ...state,
        loadingGetWriterBank: true,
        errGetWriterBank: null,
      };
    case GET_WRITER_BANK_SUCCESS:
      return {
        ...state,
        loadingGetWriterBank: false,
        dataGetWriterBank:
          action.data.data.length > 0 ? action.data.data[0] : null,
        errGetWriterBank: null,
      };
    case GET_WRITER_BANK_FAILURE:
      return {
        ...state,
        loadingGetWriterBank: false,
        dataGetWriterBank: null,
        errGetWriterBank: action.data,
      };

    case POST_WRITER_BANK_REQUEST:
      return {
        ...state,
        loadingPostWriterBank: true,
        successPostWriterBank: false,
        errPostWriterBank: null,
      };
    case POST_WRITER_BANK_SUCCESS:
      return {
        ...state,
        loadingPostWriterBank: false,
        successPostWriterBank: true,
        errPostWriterBank: null,
      };
    case POST_WRITER_BANK_FAILURE:
      return {
        ...state,
        loadingPostWriterBank: false,
        successPostWriterBank: false,
        errPostWriterBank: action.data,
      };

    case UPDATE_WRITER_BANK_REQUEST:
      return {
        ...state,
        loadingUpdateWriterBank: true,
        successUpdateWriterBank: false,
        errUpdateWriterBank: null,
      };
    case UPDATE_WRITER_BANK_SUCCESS:
      return {
        ...state,
        loadingUpdateWriterBank: false,
        successUpdateWriterBank: true,
        errUpdateWriterBank: null,
      };
    case UPDATE_WRITER_BANK_FAILURE:
      return {
        ...state,
        loadingUpdateWriterBank: false,
        successUpdateWriterBank: false,
        errUpdateWriterBank: action.data,
      };

    default:
      return state;
  }
};
