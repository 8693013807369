import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../helpers/phoneNumber";
import { formEmail } from "../../../helpers/email";

import ButtonLoader from "../../../components/button/ButtonLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";

import {
  clientRegisterAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";

const RegisterClientModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const [business, setBusiness] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesFiltered, setCompaniesFiltered] = useState([]);
  const [errorCounter, setErrorCounter] = useState(0);
  const [step, setStep] = useState("personal");

  const [emailState, setEmailState] = useState({ value: "", error: null });
  const [phoneState, setPhoneState] = useState({ value: "", error: null });
  const [nameState, setNameState] = useState({ value: "", error: null });
  const [positionState, setPositionState] = useState({
    value: "",
    error: null,
  });

  const [companyState, setCompanyState] = useState({ value: "", error: null });
  const [companyPhoneState, setCompanyPhoneState] = useState({
    value: "",
    error: null,
  });
  const [companyTypeState, setCompanyTypeState] = useState({
    value: "",
    error: null,
  });
  const [companyAddressState, setCompanyAddressState] = useState({
    value: "",
    error: null,
  });
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: null,
  });
  const [companyDetailState, setCompanyDetailState] = useState({
    value: "",
    error: null,
  });
  const [passwordConfirmState, setPasswordConfirmState] = useState({
    value: "",
    error: null,
  });

  const [dialogAlert, setDialogAlert] = useState({
    type: null,
    dialog: false,
    title: "",
    text: "",
  });

  const {
    loadingRegisterClient,
    successRegisterClient,
    errRegisterClient,
  } = useSelector((state) => state.auth);

  const {
    dataAutoCompleteCompanies,
    loadingAutoCompleteCompanies,
  } = useSelector((state) => state.client);

  const { dataBusiness } = useSelector((state) => state.utils);

  useEffect(() => {
    if (companyState?.value?.length > 0) {
      const company = dataAutoCompleteCompanies?.filter(
        (item) => item.companyName === companyState.value
      );
      if (company?.length > 0) {
        const data = company.reduce((item) => item);
        setCompanyAddressState({
          ...companyAddressState,
          value: data?.companyAddress,
        });
        setCompanyTypeState({
          ...companyTypeState,
          value: data?.businessField,
        });
        setCompanyDetailState({
          ...companyDetailState,
          value: data?.companyBackground,
        });
        setCompanyPhoneState({
          ...companyPhoneState,
          value: data?.companyPhone.replace("+62", "0"),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companiesData, companyState.value, dataAutoCompleteCompanies]);

  useEffect(() => {
    if (dataBusiness) {
      setBusiness(dataBusiness);
    }
  }, [dataBusiness]);

  useEffect(() => {
    if (!loadingAutoCompleteCompanies && dataAutoCompleteCompanies !== null) {
      const companiesFilter = dataAutoCompleteCompanies?.map(
        (item) => item.companyName
      );
      setCompanies(companiesFilter);
      setCompaniesData(dataAutoCompleteCompanies);
    }
  }, [loadingAutoCompleteCompanies, dataAutoCompleteCompanies]);

  useEffect(() => {
    if (successRegisterClient) {
      setDialogAlert({
        type: "success",
        dialog: true,
        title: "Berhasil Mendaftarkan Akun",
        text: "Tautan verifikasi akun akan dikirim ke email anda",
      });
    }
  }, [successRegisterClient]);

  useEffect(() => {
    if (errRegisterClient) {
      setDialogAlert({
        type: "error",
        dialog: true,
        title: "Gagal Mendaftarkan Akun",
        text: "Nomor telepon atau Email sudah dipakai",
      });
    }
  }, [errRegisterClient]);

  useEffect(() => {
    if (errorCounter) {
      document.getElementById("error") &&
        document.getElementById("error").scrollIntoView();
    }
  }, [errorCounter]);

  const completeMethodHandler = (event) => {
    const data = event.query.trim();
    if (data.length) {
      let filter = companies?.filter(
        (item) => item?.toLowerCase().indexOf(data.toLowerCase()) > -1
      );
      setCompaniesFiltered(filter);
    }
  };

  const closeDialog = () => {
    setDialogAlert({ type: null, dialog: false, title: "", text: "" });
    dispatch(resetStateAuthAction());
    if (successRegisterClient) {
      history.push("/client");
    }
  };

  const handleKeyPressPhone = (e) => {
    if (e.key === "e" || e.key === "-") {
      e.preventDefault();
    }
  };

  const submitData = () => {
    const data = {
      name: nameState.value,
      email: emailState.value,
      phone: formattedPhoneNumber(phoneState.value),
      companyAddress: companyAddressState.value,
      companyPhone: formattedPhoneNumber(companyPhoneState.value),
      businessField: companyTypeState.value,
      companyName: companyState.value,
      companyBackground: companyDetailState.value,
      password: passwordState.value,
      position: positionState.value,
      companyPortofolio: null,
    };

    if (!formEmail(emailState.value)) {
      setEmailState({ ...emailState, error: "Email tidak Valid" });
      setStep("personal");
    }
    if (!formPhoneNumber(phoneState.value)) {
      setPhoneState({ ...phoneState, error: "Nomor telepon tidak Valid" });
      setStep("personal");
    }
    if (emailState?.value?.length < 1) {
      setEmailState({ ...emailState, error: "Email harus diisi" });
      setStep("personal");
    }
    if (phoneState?.value?.length < 1) {
      setPhoneState({ ...phoneState, error: "Nomor telepon harus diisi" });
      setStep("personal");
    }
    if (nameState?.value?.length < 1) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
      setStep("personal");
    }
    if (positionState?.value?.length < 1) {
      setPositionState({ ...positionState, error: "Jabatan harus diisi" });
      setStep("personal");
    }
    if (passwordState?.value?.length < 6) {
      setPasswordState({
        ...passwordState,
        error: "Kata Sandi minimal 6 karakter",
      });
      setStep("personal");
    }
    if (passwordConfirmState?.value?.length < 1) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi Kata Sandi harus diisi",
      });
      setStep("personal");
    }
    if (passwordConfirmState?.value !== passwordState.value) {
      setPasswordConfirmState({
        ...passwordConfirmState,
        error: "Konfirmasi Kata Sandi tidak sama",
      });
      setStep("personal");
    }

    if (companyState?.value?.length < 1) {
      setCompanyState({
        ...companyState,
        error: "Nama perusahaan / brand harus diisi",
      });
      setStep("company");
    }
    if (companyAddressState?.value?.length < 1) {
      setCompanyAddressState({
        ...companyAddressState,
        error: "Alamat perusahaan / brand harus diisi",
      });
      setStep("company");
    }
    if (companyTypeState?.value?.length < 1) {
      setCompanyTypeState({
        ...companyTypeState,
        error: "Bidang perusahaan / brand harus diisi",
      });
      setStep("company");
    }
    if (companyDetailState?.value?.length < 1) {
      setCompanyDetailState({
        ...companyDetailState,
        error: "Deskripsi perusahaan / brand harus diisi",
      });
      setStep("company");
    }
    if (companyPhoneState?.value?.length < 1) {
      setCompanyPhoneState({
        ...companyPhoneState,
        error: "Nomor Telepon perusahaan / brand harus diisi",
      });
      setStep("company");
    }
    if (!formPhoneNumber(companyPhoneState.value)) {
      setCompanyPhoneState({
        ...companyPhoneState,
        error: "Nomor telepon perusahaan / brand tidak Valid",
      });
      setStep("company");
    }

    if (
      nameState?.value?.length > 0 &&
      emailState?.value?.length > 0 &&
      formEmail(emailState?.value) &&
      phoneState?.value?.length > 0 &&
      formPhoneNumber(phoneState?.value) &&
      positionState?.value?.length > 0 &&
      passwordState?.value?.length > 5 &&
      passwordConfirmState?.value === passwordState?.value &&
      passwordConfirmState?.value === passwordState.value &&
      companyState?.value?.length > 0 &&
      companyAddressState?.value?.length > 0 &&
      companyTypeState?.value?.length > 0 &&
      companyDetailState?.value?.length > 0 &&
      companyPhoneState?.value?.length > 0 &&
      formPhoneNumber(companyPhoneState?.value)
    ) {
      dispatch(clientRegisterAction(data));
    } else {
      setErrorCounter(errorCounter + 1);
    }
  };

  const itemTemplate = (item) => {
    return <p>{item}</p>;
  };

  return (
    <>
      <div className="register-step p-d-flex">
        <div
          onClick={() => setStep("personal")}
          className="step p-d-flex active"
        >
          <div className="number">
            <span>1</span>
          </div>
          <span className="text">Data Akun</span>
        </div>
        <div
          onClick={() => setStep("company")}
          className={`step p-d-flex ${step === "company" ? "active" : ""}`}
        >
          <div className="number">
            <span>2</span>
          </div>
          <span className="text">Data Perusahaan</span>
        </div>
      </div>
      <div className="form-box p-py-4">
        {step === "personal" ? (
          <>
            <div className="p-field" id={nameState?.error ? "error" : ""}>
              <span className="p-float-label">
                <InputText
                  id="name"
                  aria-describedby="name"
                  className={`p-d-block ${nameState.error && "p-invalid"}`}
                  value={nameState.value}
                  onChange={(e) =>
                    setNameState({ value: e.target.value, error: null })
                  }
                  autoComplete="off"
                />
                <label>Nama Lengkap*</label>
              </span>
              {nameState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {nameState.error}
                </small>
              )}
            </div>
            <div className="p-field" id={positionState?.error ? "error" : ""}>
              <span className="p-float-label">
                <InputText
                  id="position"
                  aria-describedby="position"
                  className={`p-d-block ${positionState.error && "p-invalid"}`}
                  value={positionState.value}
                  onChange={(e) =>
                    setPositionState({ value: e.target.value, error: null })
                  }
                  autoComplete="off"
                />
                <label>Posisi / Jabatan*</label>
              </span>
              {positionState.error && (
                <small id="position" className="p-error p-d-block p-text-right">
                  {positionState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="noHp"
                  aria-describedby="noHp"
                  className={`p-d-block ${phoneState.error && "p-invalid"}`}
                  value={phoneState.value}
                  onChange={(e) =>
                    setPhoneState({
                      value: e.target.value.toString(),
                      error: null,
                    })
                  }
                  type="number"
                  onKeyPress={(e) => handleKeyPressPhone(e)}
                  autoComplete="off"
                />
                <label>Nomor Telepon*</label>
              </span>
              {phoneState.error ? (
                <small id="noHp" className="p-error p-d-block p-text-right">
                  {phoneState.error}
                </small>
              ) : (
                <small className="text-input-info p-d-block">
                  Contoh: 0812xxxxx
                </small>
              )}
            </div>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="email"
                  aria-describedby="email"
                  type="email"
                  className={`p-d-block ${emailState.error && "p-invalid"}`}
                  value={emailState.value}
                  onChange={(e) =>
                    setEmailState({ value: e.target.value, error: null })
                  }
                  autoComplete="off"
                />
                <label>Email*</label>
              </span>
              {emailState.error && (
                <small id="email" className="p-error p-d-block p-text-right">
                  {emailState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="password"
                  aria-describedby="password"
                  type="password"
                  className={`p-d-block ${passwordState.error && "p-invalid"}`}
                  value={passwordState.value}
                  onChange={(e) =>
                    setPasswordState({ value: e.target.value, error: null })
                  }
                  autoComplete="off"
                />
                <label>Kata Sandi*</label>
              </span>

              {passwordState.error && (
                <small id="password" className="p-error p-d-block p-text-right">
                  {passwordState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="passwordConfirm"
                  aria-describedby="passwordConfirm"
                  type="password"
                  className={`p-d-block ${passwordConfirmState.error &&
                    "p-invalid"}`}
                  value={passwordConfirmState.value}
                  onChange={(e) =>
                    setPasswordConfirmState({
                      value: e.target.value,
                      error: null,
                    })
                  }
                  autoComplete="off"
                />
                <label>Konfirmasi Kata Sandi*</label>
              </span>
              {passwordConfirmState.error && (
                <small
                  id="passwordConfirm"
                  className="p-error p-d-block p-text-right"
                >
                  {passwordConfirmState.error}
                </small>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="p-field">
              <span className="p-float-label company-name">
                <AutoComplete
                  value={companyState.value}
                  className={`p-d-block ${companyState.error && "p-invalid"}`}
                  suggestions={companiesFiltered}
                  completeMethod={completeMethodHandler}
                  field="name"
                  onChange={(e) =>
                    setCompanyState({ value: e.target.value, error: null })
                  }
                  itemTemplate={itemTemplate}
                />
                <label>Nama Perusahaan / Brand*</label>
              </span>
              {companyState.error && (
                <small id="company" className="p-error p-d-block p-text-right">
                  {companyState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <span className="p-float-label">
                <InputText
                  id="noHp"
                  aria-describedby="noHp"
                  className={`p-d-block ${companyPhoneState.error &&
                    "p-invalid"}`}
                  value={companyPhoneState.value}
                  onChange={(e) =>
                    setCompanyPhoneState({
                      value: e.target.value.toString(),
                      error: null,
                    })
                  }
                  onKeyPress={(e) => handleKeyPressPhone(e)}
                  autoComplete="off"
                />
                <label>Nomor Telepon Perusahaan / Brand*</label>
              </span>
              {companyPhoneState.error ? (
                <small id="noHp" className="p-error p-d-block p-text-right">
                  {companyPhoneState.error}
                </small>
              ) : (
                <small className="text-input-info p-d-block">
                  Contoh: 0812xxxxx atau 0213xxxxx
                </small>
              )}
            </div>
            <div className="p-field business-dropdown">
              <span className="p-float-label">
                <Dropdown
                  value={companyTypeState.value}
                  onChange={(e) =>
                    setCompanyTypeState({ value: e.target.value, error: null })
                  }
                  className={`${companyState.error && "p-invalid"}`}
                  options={business}
                  optionLabel="name"
                  optionValue="code"
                />
                <label>Bidang Perusahaan / Brand*</label>
              </span>
              {companyTypeState.error && (
                <small id="company" className="p-error p-d-block p-text-right">
                  {companyTypeState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={companyDetailState.value}
                  className={`p-d-block ${companyDetailState.error &&
                    "p-invalid"}`}
                  onChange={(e) =>
                    setCompanyDetailState({
                      value: e.target.value,
                      error: null,
                    })
                  }
                />
                <label>Deskripsi Perusahaan / Brand*</label>
              </span>
              {companyDetailState.error && (
                <small id="address" className="p-error p-d-block p-text-right">
                  {companyDetailState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <span className="p-float-label">
                <InputTextarea
                  rows={5}
                  cols={30}
                  value={companyAddressState.value}
                  className={`p-d-block ${companyAddressState.error &&
                    "p-invalid"}`}
                  onChange={(e) =>
                    setCompanyAddressState({
                      value: e.target.value,
                      error: null,
                    })
                  }
                />
                <label>Alamat Perusahaan / Brand*</label>
              </span>
              {companyAddressState.error && (
                <small id="address" className="p-error p-d-block p-text-right">
                  {companyAddressState.error}
                </small>
              )}
            </div>
          </>
        )}

        {loadingRegisterClient ? (
          <ButtonLoader />
        ) : (
          <Button
            label={step === "personal" ? "Selanjutnya" : "Daftar"}
            className="p-button-rounded p-button-lg"
            onClick={
              step === "personal"
                ? () => setStep("company")
                : () => submitData()
            }
          />
        )}
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
};

export default RegisterClientModule;
