import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";

import images from "../assets/img/not-found.svg";
import StaticLayout from "../layouts/staticLayout";

import { titlePage } from "../helpers/titleHead";

import { getStaticPageAction } from "../../redux/action/landingpageAction";
import { TextLoad } from "../components/loading/loadingContent";

const StaticPage = () => {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const { dataStaticPage, loadingStaticPage } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    titlePage({
      title: "Static Page",
    });
    dispatch(getStaticPageAction(queryParams.slug));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticLayout>
      {loadingStaticPage ? (
        <div className="loading-text">
          <TextLoad />
        </div>
      ) : (
        <>
          {dataStaticPage === null ? (
            <div className="fullpage-content">
              <div className="not-found-card">
                <img src={images} alt="404" />
                <Link
                  className="p-button p-button-outlined p-button-rounded"
                  to="/"
                >
                  Halaman Utama
                </Link>
              </div>
            </div>
          ) : (
            <div className="static-page">
              <div className="static-content-wrapper">
                <div className="title-static">
                  <h1>{dataStaticPage?.pageName}</h1>
                </div>
                <div className="body">
                  <div className="w-100 p-d-flex p-jc-center">
                    <img
                      className="image-banner"
                      src={dataStaticPage?.image}
                      alt="image_static"
                    />
                  </div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${dataStaticPage?.contentText}`,
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </StaticLayout>
  );
};

export default StaticPage;
