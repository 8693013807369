import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
import ButtonDefault from "../../../../components/button/ButtonDefault";
import dummyQuestion from "./question.json";
// import Questions from "./RichTextQuestion";
import { clientDataProjectAction, getAgeAction, getCategoryReleaseAction, getGenderAction, getSesAction, getStakeholderAction } from "../../../../../redux/action/utilsAction";

const AddHeadline = (props) => {
    const dispatch = useDispatch();
    const { users } = useSelector((state) => state.auth);
    useEffect(() => {
      dispatch(getAgeAction());
      dispatch(getGenderAction());
      dispatch(getCategoryReleaseAction());
      dispatch(getSesAction());
      dispatch(getStakeholderAction());
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [users]);
    
    const {
      dataAge,
      dataGender,
      dataStakeholder,
      dataCategoryRelease,
      dataAddProjectClient,
    } = useSelector((state) => state.utils);

    // const { dataDetailProduct } = useSelector(
    //   (state) => state.service
    // );
  
    const [keyword, setKeyword] = useState({ value: "", error: null });
    const [objectState, setObjectState] = useState({ value: "", error: null });
    const [what, setWhat] = useState({
        value: null,
        error: null,
    });
    const [when, setWhen] = useState({
        value: null,
        error: null,
    });
    const [where, setWhere] = useState({
        value: null,
        error: null,
    });
    const [who, setWho] = useState({
        value: null,
        error: null,
    });
    const [why, setWhy] = useState({
        value: null,
        error: null,
    });
    const [how, setHow] = useState({
        value: null,
        error: null,
    });
    const [categoryState, setCategoryState] = useState({
        value: "Fitur/layanan dan Produk Baru",
        error: null,
    });
    const [attributeState, setAttributeState] = useState({
        value: "",
        error: null,
    });
    const [genderState, setGenderState] = useState({
        value: null,
        error: null,
    });
    const [ageState, setAgeState] = useState({
        value: null,
        error: null,
    });
    const [stakeholderState, setStakeholderState] = useState({
        value: [],
        error: null,
    });
    const [sesState, setSesState] = useState({
        value: [],
        error: null,
    });

    const [questionState] = useState({
        value: [
          {
            what: "Apa nama layanan/fitur baru yang akan diluncurkan?",
            when: "Kapan layanan baru tersebut tersedia secara luas bisa diakses?",
            where: "Dimanakah masyarakat/user dapat mengakses layanan/produk baru tersebut?",
            why: "",
            who: "Siapa yang menjadi pengguna dari layanan/produk baru tersebut?",
            how: "Bagaimana perusahaan melihat potensi ke depannya dari produk/layanan baru tersebut?",
          },
          {
            what: "Apa bentuk kerjasama baru yang sedang dilakukan?",
            when: "Kapan kerjasama tersebut akan dijalankan?",
            where: "Dimana kerjasama tersebut disetujui/disahkan?",
            why: "Kenapa kerjasama tersebut dapat terbentuk?",
            who: "Siapa yang menjadi inisiator terjalinnya kerjasama tersebut?",
            how: "Bagaimana perusahaan melihat atau mengambil potensi dari kerjasama tersebut ke depannya?",
          },
          {
            what: "Apa isu yang akan diulas?",
            when: "Kapan isu tersebut bisa diselesaikan?",
            where: "Dimana letak pokok permasalahan dari isu tersebut?",
            why: "Kenapa isu tersebut bisa terjadi?",
            who: "Siapa yang bertanggungjawab atas isu tersebut?",
            how: "Bagaimana sebenarnya kronologi isu tersebut?",
          },
          {
            what: "Apa bentuk program CSR yang ingin diliput?",
            when: "Kapan program tersebut akan dilaksanakan atau diluncurkan?",
            where: "Dimana program tersebut akan berjalan dan terlaksana?",
            why: "Kenapa program tersebut penting dilakukan?",
            who: "Siapa saja yang menjadi target utama dari prohram tersebut?",
            how: "Bagaimana dampak program tersebut menunjang perusahaan dan masyarakat?",
          },
          {
            what: "Apa bentuk pencapaian perusahaan yang ingin dipublikasikan?",
            when: "Kapan kinerja tersebut dapat dicapai perusahaani?",
            where: "Di sektor/unit bisnis apa pencapaian tersebut terjadi?",
            why: "Kenapa kinerja tersebut perlu disampaikan ke publik?",
            who: "Siapa saja pihak yang terlibat dan berkontribusi dalam pencapaian tersebut?",
            how: "",
          }
        ],
        error: null,
    });

    useEffect(() => {
      if(categoryState.value === "Fitur/layanan dan Produk Baru"){
        setSelected(0)
      }
      else if(categoryState.value === "Kerjasama Baru Perusahaan"){
        setSelected(1)
      }
      else if(categoryState.value === "Klarifikasi Isu atau Kasus"){
        setSelected(2)
      }
      else if(categoryState.value === "Update CSR program"){
        setSelected(3)
      }
      else if(categoryState.value === "Kinerja Perusahaan"){
        setSelected(4)
      }
    }, [categoryState])

    const [selectedQuestion, setSelected] = useState(0)

    const [noteState, setNoteState] = useState({ value: "", error: null });
    const [isDisabled, setIsDisabled] = useState(true);
    // const [showInfo, setShowInfo] = useState(false);
    const [showAdditionalQuestion, setShowAdditionalQuestion] = useState(false);
    // const [hoveredQuestion, setHoveredQuestion] = useState("");
    // const [hoveredPosition, setHoveredPosition] = useState("top");
    const [hoveredPositionAdditional, setHoveredPositionAdditional] = useState(
      "top"
    );


    const handleScroll = () => {
        // const categoryReleaseElement = document
          // .getElementById("release-category")
          // ?.getBoundingClientRect()?.top;
        const deliveredAttributionEl = document
          .getElementById("delivered-attribution")
          ?.getBoundingClientRect()?.top;
        // const limitTopCategoryRelease = 280;
        // const limitBottomCategoryRelease = 295;
        const limitTopAttribution = 340;
        const limitBottomAttribution = 450;
        // if (categoryReleaseElement < limitTopCategoryRelease) {
        //   setHoveredPosition("bottom");
        // }
        // if (categoryReleaseElement > limitBottomCategoryRelease) {
        //   setHoveredPosition("top");
        // }
        if (deliveredAttributionEl < limitTopAttribution) {
          setHoveredPositionAdditional("bottom");
        }
        if (deliveredAttributionEl > limitBottomAttribution) {
          setHoveredPositionAdditional("top");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    useEffect(() => {
        if (
            keyword?.value?.length > 0 &&
            what?.value?.length > 0 &&
            objectState?.value?.length > 0 &&
            stakeholderState?.value?.length > 0 &&
            sesState?.value?.length !== null &&
            ageState?.value !== null &&
            categoryState?.value !== null
        ) {
          setIsDisabled(false);
        }
      else (
        setIsDisabled(true)
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        keyword.value,
        what.value,
        ageState.value,
        categoryState.value,
        objectState.value,
        stakeholderState.value,
        sesState.value,
        // eslint-disable-next-line no-use-before-define
        dataAddProjectClient,
    ]);
    const onCheck = (e) => {
        let selectedData = [...stakeholderState.value];
    
        if (e.checked) selectedData.push(e.value);
        else selectedData.splice(selectedData.indexOf(e.value), 1);
    
        setStakeholderState({ value: selectedData, error: null });
    };

    const onCheckSES = (e) => {
        let selectedData = [...sesState.value];
    
        if (e.checked) selectedData.push(e.value);
        else selectedData.splice(selectedData.indexOf(e.value), 1);
    
        setSesState({ value: selectedData, error: null });
    };

    useEffect(() => {
        if (dataAddProjectClient?.release !== null) {
          setKeyword({
            value: dataAddProjectClient?.keyword,
            error: null,
          });
          setWhat({
            value: dataAddProjectClient?.ideationFeature.what,
            error: null,
          });
          setWhen({
            value: dataAddProjectClient?.ideationFeature.when,
            error: null,
          });
          setWhere({
            value: dataAddProjectClient?.ideationFeature.where,
            error: null,
          });
          setWhy({
            value: dataAddProjectClient?.ideationFeature.why,
            error: null,
          });
          setWho({
            value: dataAddProjectClient?.ideationFeature.who,
            error: null,
          });
          setHow({
            value: dataAddProjectClient?.ideationFeature.how,
            error: null,
          });
          setObjectState({
            value: dataAddProjectClient?.objective,
            error: null,
          });
          setAttributeState({
            value: dataAddProjectClient?.attribution,
            error: null,
          });
          setCategoryState({
            value: dataAddProjectClient?.releaseCategory,
            error: null,
          });
          setGenderState({
            value: dataAddProjectClient?.target?.sex,
            error: null,
          });
          setAgeState({
            value: dataAddProjectClient?.target?.age,
            error: null,
          });
          setNoteState({
            value: dataAddProjectClient?.releaseNote,
            error: null,
          });
        }
    }, [dataAddProjectClient]);
      
    // const changeQuestion = (data) => {
    //     setQuestionState({ value: data, error: null });
    // };

    const changeCategory = (data) => {
        setCategoryState({ value: data, error: null });
        // const question = dummyQuestion?.filter((item) => item.name === data);
        // setHoveredQuestion(question[0]?.question);
    };
    const dataSesStake = [
        "SES A",
        "SES B",
        "SES C",
        "SES D"
      ]
    
    const ageDropdown = dataAge?.map((age) => ({ name: age, code: age }));
    const genderDropdown = dataGender?.map((gender) => ({
        name: gender,
        code: gender,
    }));
    const categoryDropdown = dataCategoryRelease?.map((category) => ({
        name: category,
        code: category,
    }));

    const handleNext = (e) => {
      e.preventDefault();
      const dataRelease = {
        keyword: keyword.value,
        ideationFeature: {
          what: what.value,
          when: when.value,
          where: where.value,
          why: why.value,
          who: who.value,
          how: how.value,
        },
        objective: objectState.value,
        releaseCategory: categoryState.value,
        releaseNote: noteState.value,
        // releaseCategoryQuestion: questionState.value,
        attribution: attributeState.value,
        target: {
          sex: genderState.value,
          age: ageState.value,
          stakeholders: stakeholderState.value,
          ses: sesState.value,
        },
      };
      dispatch(clientDataProjectAction(dataRelease));
      props.submitDetail()
    }

    return (
      <div className="d-flex">
        <div className="w-50">
            <div className="text-dark px-8 mb-8">
                <div className="h2 mb-2">Headline Generator</div>
                <div className="">Masukan kata kunci untuk membuat headline</div>
            </div>
            <div className="form-add-ideation brief px-8">
            <div className="p-field">
              <label className="p-d-block">
                Kata Kunci Pencarian <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                placeholder="Masukkan Kata Kunci Pencarian"
                onChange={(e) =>
                  setKeyword({ value: e.target.value, error: null })
                }
                value={keyword.value}
              />
              {keyword.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {keyword.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block" id="release-category">
                Kategori Rilis <span className="text-danger">*</span>
              </label>
              <Dropdown
                value={categoryState.value}
                options={categoryDropdown}
                onChange={(e) => changeCategory(e.target.value)}
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Kategori Rilis"
              />
              {categoryState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {categoryState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block">
                {questionState.value?.[selectedQuestion].what} <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                placeholder="Text here"
                onChange={(e) =>
                  setWhat({ value: e.target.value, error: null })
                }
                value={what.value}
              />
              {what.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {what.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block">
                {questionState.value?.[selectedQuestion].who}
              </label>
              <InputText
                className="p-d-block"
                placeholder="Text here"
                onChange={(e) =>
                  setWho({ value: e.target.value, error: null })
                }
                value={who.value}
              />
              {who.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {who.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block">
                {questionState.value?.[selectedQuestion].where}
              </label>
              <InputText
                className="p-d-block"
                placeholder="Text here"
                onChange={(e) =>
                  setWhere({ value: e.target.value, error: null })
                }
                value={where.value}
              />
              {where.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {where.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block">
                {questionState.value?.[selectedQuestion].when}
              </label>
              <InputText
                className="p-d-block"
                placeholder="Text here"
                onChange={(e) =>
                  setWhen({ value: e.target.value, error: null })
                }
                value={when.value}
              />
              {when.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {when.error}
                </small>
              )}
            </div>

            {questionState.value?.[selectedQuestion].why !== "" ? (
              <div className="p-field">
                <label className="p-d-block">
                  {questionState.value?.[selectedQuestion].why}
                </label>
                <InputText
                  className="p-d-block"
                  placeholder="Text here"
                  onChange={(e) =>
                    setWhy({ value: e.target.value, error: null })
                  }
                  value={why.value}
                />
                {why.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {why.error}
                  </small>
                )}
              </div>
            ) : null}

            {questionState.value?.[selectedQuestion].how !== "" ? (
              <div className="p-field">
                <label className="p-d-block">
                  {questionState.value?.[selectedQuestion].how}
                </label>
                <InputText
                  className="p-d-block"
                  placeholder="Text here"
                  onChange={(e) =>
                    setHow({ value: e.target.value, error: null })
                  }
                  value={how.value}
                />
                {how.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {how.error}
                  </small>
                )}
              </div>
            ) : null}

            {/* {dataDetailProduct?.category === "pr" && categoryState.value && (
              <div className="p-field">
                <label htmlFor="category" className="p-d-block">
                  Informasi Tambahan Kategori Rilis {categoryState.value}{" "}
                  <span className="text-danger">*</span>
                  <span
                    onMouseOver={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                    className="icon-info ml-2"
                    id="hovered-icon"
                  ></span>
                </label>
                <div className={`info-question-wrapper ${hoveredPosition}`}>
                  {showInfo && (
                    <div
                      className="info-question"
                      dangerouslySetInnerHTML={{ __html: hoveredQuestion }}
                    ></div>
                  )}
                </div>
                <Questions
                  contentValue={questionState.value}
                  changeContent={changeQuestion}
                />
                {questionState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {questionState.error}
                  </small>
                )}
              </div>
            )} */}

            
            <div className="p-field">
              <label htmlFor="target" className="p-d-block">
                Target Pembaca
              </label>
              <div className="innef-field p-d-flex">
                <span>Jenis Kelamin <span className="text-danger">*</span></span>
                <div>
                  <Dropdown
                    value={genderState.value}
                    options={genderDropdown}
                    onChange={(e) =>
                      setGenderState({ value: e.target.value, error: null })
                    }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {genderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {genderState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field p-d-flex">
                <span>
                  Usia <span className="text-danger">*</span>
                </span>
                <div>
                  <Dropdown
                    value={ageState.value}
                    options={ageDropdown}
                    onChange={(e) =>
                      setAgeState({ value: e.target.value, error: null })
                    }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {ageState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {ageState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field">
                <span>
                  Stakeholder <span className="text-danger">*</span>
                </span>
                <div>
                  {dataStakeholder?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        inputId={item}
                        onChange={onCheck}
                        checked={stakeholderState.value.indexOf(item) !== -1}
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {stakeholderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {stakeholderState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field">
                <span>
                  SES <span className="text-danger">*</span>
                </span>
                <div>
                  {dataSesStake?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        inputId={item}
                        onChange={onCheckSES}
                        checked={sesState.value.indexOf(item) !== -1}
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {stakeholderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {stakeholderState.error}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Objektif komunikasi {" "}
                <span className="text-danger">*</span>
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setObjectState({ value: e.target.value, error: null })
                }
                value={objectState.value}
              />
              {objectState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {objectState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block" id="delivered-attribution">
                Atribusi Quote Dan Pesan Yang Ingin Disampaikan
                  <span
                    onMouseOver={() => setShowAdditionalQuestion(true)}
                    onMouseLeave={() => setShowAdditionalQuestion(false)}
                    className="icon-info ml-2"
                    id="hovered-icon"
                  />
              </label>
              <div
                className={`info-question-wrapper ${hoveredPositionAdditional}`}
              >
                {showAdditionalQuestion && (
                  <div
                    className="info-question"
                    dangerouslySetInnerHTML={{
                      __html: dummyQuestion[5].question,
                    }}
                  ></div>
                )}
              </div>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setAttributeState({ value: e.target.value, error: null })
                }
                value={attributeState.value}
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Poin penting lainnya yang ingin disampaikan
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setNoteState({ value: e.target.value, error: null })
                }
                value={noteState.value}
              />
            </div>
            <div className="footer-form">
                <div className="form-add-ideation brief ">
                    <ButtonDefault
                        onClick={handleNext}
                        disabled={isDisabled}
                        label="GENERATE"
                        type="rounded"
                        className="p-button p-button-primary w-100"
                    />
                </div>
            </div>
          </div>
        </div>
        <div className="w-50 d-flex">
          <div className="w-50"/>
          <div className="w-70 mr-20">
            <div className="px-4 py-6 text-dark border rounded border-5 border-right-grey border-bottom-grey border-left-grey border-top-info">
              <div className="h3 font-weight-bold mb-5">Tata cara pengisian informasi {categoryState.value}:</div>
              <div className="mb-3">Deskripsikan dengan lengkap dan terperinci informasi lebih lanjut terkait {categoryState.value}, seperti:</div>
              <ul className="mb-3">
                <li>{questionState.value?.[selectedQuestion].what}</li>
                <li>{questionState.value?.[selectedQuestion].when}</li>
                <li>{questionState.value?.[selectedQuestion].where}</li>
                {questionState.value?.[selectedQuestion].why !== "" ? (<li>{questionState.value?.[selectedQuestion].why}</li>) : null}
                <li>{questionState.value?.[selectedQuestion].who}</li>
                {questionState.value?.[selectedQuestion].how !== "" ? (<li>{questionState.value?.[selectedQuestion].how}</li>) : null}
              </ul>
              <div>Tambahkan spesifikasi dan poin penting lainnya yang ingin disampaikan, agar dapat membantu penulis bekerja dengan baik.</div>
            </div>
          </div>
        </div>
      </div>
      
  )
}

export default AddHeadline