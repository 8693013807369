import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getProjectDetailPmAction,
  resetStateProjectAction,
  userInvitableAction,
} from "../../../../redux/action/projectAction";
import DetailBriefProjectModule from "../../../modules/Admin/Superadmin/BriefRelease/DetailBriefProjectModule";

const DetailBriefReleasePM = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  // const history = useHistory();

  const { userInvitableParams } = useSelector((state) => state.project);

  useEffect(() => {
    dispatch(getProjectDetailPmAction(QueryParams.projectId));
    dispatch(userInvitableAction(QueryParams.projectId, userInvitableParams));
    dispatch(resetStateProjectAction);

    titlePage({
      title: "IRIS - Detail Brief Project",
    });
    // history.push(`/admin/pm/detail-brief-release-client/${QueryParams.projectId}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Brief Rilis Klien" menu="release">
      <DetailBriefProjectModule projectId={QueryParams.projectId} />
    </DashboardLayout>
  );
};

export default DetailBriefReleasePM;
