import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import PaymentConfirmationModule from "../../modules/Client/Payment/PaymentConfirmationModule";
import { getProductDetailAction } from "../../../redux/action/serviceAction";
import { detailClientAction } from "../../../redux/action/clientAction";

function PaymentConfirmationPage() {
  const dispatch = useDispatch();
  const queryParams = useParams();

  const { users } = useSelector((state) => state.auth);
  useEffect(() => {
    titlePage({
      title: "IRIS - Konfirmasi Pesanan",
    });
    dispatch(getProductDetailAction(queryParams.productId));
    if (users) {
      dispatch(detailClientAction(users?.user?.companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);
  return (
    <DashboardClientLayout
      title="Pesanan Baru"
      mobileTitle="Detail Pesanan"
      menu="product"
    >
      <PaymentConfirmationModule productId={queryParams.productId} />
    </DashboardClientLayout>
  );
}

export default PaymentConfirmationPage;
