import React from "react";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import dayjs from "dayjs";
import "dayjs/locale/id";

const DialogReview = (props) => {
  const { dataGetArticle, dataLogArticle } = useSelector(
    (state) => state.project
  );

  return (
    <div className="dialog-invite">
      <div className="card">
        <Dialog
          header="Preview"
          visible={props.dialogReview}
          style={{ width: "800px" }}
          onHide={props.closeReview}
        >
          <div className="p-text-left review-wrapper">
            <h2 className="title-wrapp">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${dataGetArticle?.articleTitle}`,
                }}
              />
            </h2>
            {dataGetArticle?.articleImage && (
              <div className="image-wrapp">
                <img src={dataGetArticle?.articleImage} alt="images" />
              </div>
            )}
            <div className="content-wrapp">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${dataGetArticle?.articleContent}`,
                }}
              />
            </div>
            <div className="history-wrapp">
              <h6>Riwayat Aktivitas</h6>
              {dataLogArticle?.map((item) => (
                <div className="history-list" key={item?.id}>
                  <strong>
                    {dayjs(item?.date?.toDate())
                      .locale("id")
                      .format("DD MMMM YYYY HH:mm")}
                  </strong>{" "}
                  - {item?.message}
                </div>
              ))}
              {dataLogArticle?.length < 1 && (
                <div className="history-list">Belum ada aktivitas</div>
              )}
            </div>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DialogReview;
