/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";

import DialogReview from "./Components/PopupReview";
import { DetailPageLoad } from "../../../components/loading/loadingContent";
import DialogAlert from "../../../components/dialog/DialogAlert";
import ProjectDetailAccordion from "../../../components/accordion/ProjectDetailAccordion";

import CoinImage from "../../../assets/img/icon-coin.svg";
import ClockImage from "../../../assets/img/icon-clock.svg";
import DocImage from "../../../assets/img/document_icon.svg";

import {
  submitArticleAction,
  resetStateProjectAction,
} from "../../../../redux/action/projectAction";
import StatusBadge from "../../../components/badge/StatusBadge";

const DetailMyProjectModule = (props) => {
  const dispatch = useDispatch();
  const {
    dataGetDetailProject,
    dataGetArticle,
    loadingGetDetailProject,
    loadingGetArticle,
    loadingSubmitArticleProject,
    successSubmitArticleProject,
    errSubmitArticleProject,
    dataLogArticle,
  } = useSelector((state) => state.project);

  const [openReview, setOpenReview] = useState(false);
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogErrorText, setDialogErrorText] = useState({
    title: "",
    text: "",
  });

  const history = useHistory();

  useEffect(() => {
    if (successSubmitArticleProject) {
      setDialogWarning(false);
      setDialogSuccess(true);
    }
  }, [successSubmitArticleProject]);

  useEffect(() => {
    if (errSubmitArticleProject) {
      setDialogWarning(false);
      setDialogError(true);
      setDialogErrorText({
        title: "Gagal Mengirimkan Artikel",
        text: "Deadline project sudah terlewati",
      });
    }
  }, [errSubmitArticleProject]);

  const handlesubmit = (e) => {
    e.preventDefault();
    const data = {
      articleTitle: dataGetArticle.articleTitle,
      articleContent: dataGetArticle.articleContent,
      articleImage: dataGetArticle.articleImage,
    };
    dispatch(submitArticleAction(props?.hashId, data));
  };

  const closeSuccess = () => {
    setDialogSuccess(false);
    dispatch(resetStateProjectAction());
  };

  const latestActivity =
    dataLogArticle && dataLogArticle[dataLogArticle?.length - 1];

  const [activeIndex, setActiveIndex] = useState(0);

  const isEmptyArticle =
    (!dataGetArticle?.articleTitle && !dataGetArticle?.articleContent) ||
    (dataGetArticle?.articleTitle === "<p></p>" &&
      dataGetArticle?.articleContent === "<p></p>") ||
    (dataGetArticle?.articleTitle === "<p></p>" &&
      dataGetArticle?.articleContent === "<p><br></p>");

  return (
    <>
      <div className="p-d-flex p-justify-center project-detail">
        <div className="p-grid">
          <div className="p-col-12">
            <div className="box-content box-project box-write p-mb-4 p-mt-4">
              {loadingGetDetailProject && loadingGetArticle ? (
                <div className="write-detail">
                  <DetailPageLoad />
                </div>
              ) : (
                <div className="write-wrapper">
                  <div className="attr-write">
                    <div className="card-title">
                      <div
                        className="card-title-back"
                        onClick={() => history.push("/dashboard")}
                      >
                        <span className="icon-back"></span>
                      </div>
                      <h2 className="card-title-text large">
                        Detail Project Saya
                      </h2>
                    </div>
                    <div>
                      <div className="p-d-flex p-jc-end">
                        <StatusBadge status="join" user="writer" />
                      </div>
                      {latestActivity && (
                        <div className="history-list">
                          <strong>
                            {dayjs(latestActivity?.date?.toDate())
                              .locale("id")
                              .format("DD MMMM YYYY HH:mm")}
                          </strong>{" "}
                          - {latestActivity?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="write-detail">
                    <div className="summary">
                      <div className="d-flex flex-row bd-highlight mb-3">
                        <div className="mr-auto p-2 bd-highlight author-wrapp">
                          <div className="pict detail">
                            <img
                              src={dataGetDetailProject?.thumbnail}
                              alt="photo"
                            />
                          </div>
                          <div className="desc mb-6">
                            <h3>{dataGetDetailProject?.title}</h3>
                            <h6>
                              Nomor Pekerjaan: {dataGetDetailProject?.jobNumber}
                            </h6>
                            <div className="project-info-wrapp">
                              <div className="info-row">
                                <div className="info">
                                  <img src={CoinImage} alt="" />
                                  <NumberFormat
                                    prefix={"Rp "}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    displayType="text"
                                    value={dataGetDetailProject?.price}
                                    renderText={(value) => <p>{value}</p>}
                                  />
                                </div>
                                <div className="info">
                                  <img src={ClockImage} alt="" />
                                  <p>
                                    {`Deadline ${dayjs(
                                      dataGetDetailProject?.deadlineDate?.toDate()
                                    )
                                      .locale("id")
                                      .format("DD MMMM YYYY")}`}
                                  </p>
                                </div>
                                <div className="info">
                                  <img src={DocImage} alt="" />
                                  <p>
                                    {dataGetDetailProject?.type === "pr"
                                      ? "Press Release"
                                      : "Artikel"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <p>{dataGetDetailProject?.description}</p>
                      <div className="p-d-flex p-jc-end p-ai-center footer-attr">
                        <span>
                          Diposting{" "}
                          {dayjs(dataGetDetailProject?.createdAt.toDate())
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </span>
                      </div>
                      <div className="action-wrapper">
                        <div className="action-row">
                          <div className="article-status">
                            <p>Status Tulisan:</p>
                            <StatusBadge
                              status={dataGetArticle?.status}
                              user="writer"
                            />
                          </div>
                        </div>
                        <div className="action-row right">
                          <Link
                            to={`/my-project/${dataGetArticle?.projectId}/article`}
                            className="btn btn-preview"
                          >
                            <span
                              className={
                                isEmptyArticle
                                  ? "icon-edit-pencil-blue"
                                  : "icon-show-blue"
                              }
                            />
                            {isEmptyArticle ? "Tulis Artikel" : "Lihat Artikel"}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProjectDetailAccordion
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                    dataProject={dataGetDetailProject}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <DialogReview
        dialogReview={openReview}
        closeReview={() => setOpenReview(false)}
      />
      <DialogAlert
        type={"warning"}
        actionDialog={handlesubmit}
        actionText={"KIRIM"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Kirim Artikel?"}
        text={"Pastikan artikel anda sudah sesuai"}
        loading={loadingSubmitArticleProject}
      />
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={() => setDialogError(false)}
        title={"Preview Belum Tersedia"}
        text={"Silakan membuat artikel atau konten terlebih dahulu"}
      />
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={() => setDialogError(false)}
        title={dialogErrorText.title}
        text={dialogErrorText.text}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Mengirimkan Artikel"}
        text={"Artikel berhasil dikirimkan dan akan segera direview"}
      />
    </>
  );
};

export default DetailMyProjectModule;
