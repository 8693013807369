import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import IsoLogin from "../../assets/img/iso-login.svg";
import AuthLayout from "../../layouts/authLayout";
import ButtonLoader from "../../components/button/ButtonLoader";
import DialogAlert from "../../components/dialog/DialogAlert";
import { formEmail } from "../../helpers/email";

import {
  checkEmailAction,
  resetPasswordAction,
  resetStateAuthAction,
} from "../../../redux/action/authAction";

const ForgotWriterPage = (props) => {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState({ value: "", error: null });
  const [dialogAlert, setDialogAlert] = useState({
    dailog: false,
    type: null,
    title: null,
    text: null,
  });

  const {
    loadingCheckEmail,
    dataCheckEmail,
    loadingResetPassword,
    successResetPassword,
    errResetPassword,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (dataCheckEmail !== null && dataCheckEmail?.exists) {
      dispatch(resetPasswordAction({ email: email.value }));
    } else if (dataCheckEmail !== null && !dataCheckEmail?.exists) {
      setEmail({ ...email, error: "Email belum terdaftar" });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dataCheckEmail]);

  useEffect(() => {
    if (successResetPassword) {
      setDialogAlert({
        dialog: true,
        type: "success",
        title: "Berhasil Reset Password",
        text: "Tautan untuk mengganti password telah dikirimkan ke email anda",
      });
    }
  }, [successResetPassword]);

  useEffect(() => {
    if (errResetPassword?.message === "User have been disabled by admin") {
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "Gagal Reset Password",
        text: "Akun anda belum terverifikasi atau telah dinonaktifkan",
      });
    }
  }, [errResetPassword]);

  const sendEmail = () => {
    if (!formEmail(email?.value)) {
      setEmail({
        ...email,
        error: "Email tidak valid",
      });
    } else if (email?.value?.length < 1) {
      setEmail({
        ...email,
        error: "Email harus diisi",
      });
    } else {
      dispatch(checkEmailAction({ email: email.value }));
    }
  };

  const closeDialog = () => {
    setDialogAlert({
      dailog: false,
      type: null,
      title: null,
      text: null,
    });
    dispatch(resetStateAuthAction());
    history.push(
      `${
        location?.pathname === "/client" || location?.pathname === "/client/"
          ? "/client/login"
          : "/login"
      }`
    );
  };

  return (
    <AuthLayout>
      <div className="auth-wrapper login">
        <div className="container">
          <div className="p-grid">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lg-0 p-md-0">
              <div className="auth-form p-d-flex p-jc-center">
                <div className="form-reg">
                  <div className="p-text-center">
                    <h2>Lupa Password</h2>
                    <p className="note-auth">
                      Silahkan masukan email untuk merubah password anda
                    </p>
                  </div>
                  <div className="p-field">
                    <div className="p-field mt-0">
                      <InputText
                        aria-describedby="phone-number"
                        className={`${email.error !== null &&
                          "p-invalid"} p-d-block`}
                        placeholder="Masukkan Email Anda"
                        type="email"
                        value={email.value}
                        onChange={(e) =>
                          setEmail({ value: e.target.value, error: null })
                        }
                      />
                      {email.error !== null && (
                        <small
                          id="phone-number"
                          className="p-error p-d-block p-text-right"
                        >
                          {email.error}
                        </small>
                      )}
                    </div>
                  </div>
                  {loadingCheckEmail || loadingResetPassword ? (
                    <ButtonLoader />
                  ) : (
                    <Button
                      label="KIRIM"
                      className="p-button-rounded p-button-lg p-mt-3"
                      onClick={() => sendEmail()}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-0 col-xs-0 p-lg-0 p-md-0">
              <div className="iso-login">
                <img src={IsoLogin} alt="iso-login" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </AuthLayout>
  );
};

export default ForgotWriterPage;
