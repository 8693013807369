import React from "react";
import { Dialog } from "primereact/dialog";

const DialogPreview = (props) => {
  const { visible, closeDialog, images } = props;

  return (
    <Dialog
      className="dialog-preview"
      header="Preview Gambar"
      visible={visible}
      onHide={closeDialog}
    >
      <div className="image-wraper">
        <img src={images} alt={images} />
      </div>
    </Dialog>
  );
};

export default DialogPreview;
