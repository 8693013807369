import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import CompanyDetailModule from "../../../modules/Admin/Superadmin/CompanyManagement/CompanyDetailModule";

import { detailClientAction } from "../../../../redux/action/clientAction";
import { detailUserAction } from "../../../../redux/action/userAction";
import { getBusinessAction } from "../../../../redux/action/utilsAction";

const CompanyDetailPage = () => {
  const dispatch = useDispatch();
  const QueryParam = useParams();

  const { dataDetailClient } = useSelector((state) => state.client);

  useEffect(() => {
    dispatch(detailClientAction(QueryParam.companyId));
    dispatch(getBusinessAction());
    titlePage({
      title: "IRIS - Detail Perusahaan",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataDetailClient) {
      dispatch(detailUserAction(dataDetailClient?.client?.clientId));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailClient]);

  return (
    <DashboardLayout title="Manajemen Klien" menu="company">
      <CompanyDetailModule companyId={QueryParam.companyId} />
    </DashboardLayout>
  );
};

export default CompanyDetailPage;
