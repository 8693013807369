import React from "react";
import { Button } from "primereact/button";
import Image from "../../assets/img/ellipsis.gif";

const ButtonLoader = () => {
  return (
    <Button className="p-button-rounded p-button-lg p-button-disabled" disabled>
      <img src={Image} alt="loading..." />
    </Button>
  );
};

export default ButtonLoader;
