import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { RadioButton } from "primereact/radiobutton";
import { getProjectPmAction } from "../../../../../redux/action/projectAction";

function PopupSort(props) {
  const { show, parsed, onHide } = props;
  const [sort, setSort] = useState("updatedAt");
  const dispatch = useDispatch();
  const history = useHistory();

  const submitFilter = () => {
    const params = {
      q: parsed?.search || "",
      category: parsed.category || "",
      type: parsed.type || "",
      orderField: sort || "",
      orderDir: sort === "createdAt" ? "desc" : "asc",
      status: parsed.status,
      page: 1,
      perpage: 10,
    };
    history.push(
      `/admin/pm/list-project??search=${parsed.search || ""}&type=${
        parsed.type
      }&category=${parsed.category}&status=${parsed.status}&order=${sort ||
        ""}&page=1`
    );
    dispatch(getProjectPmAction(params));
    onHide();
  };

  return (
    <div className={`popup-project-sort ${!show && "d-none"}`}>
      <div className="sort-body">
        <div className="sort-by">
          <h3 className="p-mb-4 p-mt-3">Urutkan</h3>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="sort1"
              name="sort"
              value="updatedAt"
              onChange={(e) => setSort(e.value)}
              checked={sort === "updatedAt"}
            />
            <label htmlFor="sort1" className="p-mb-0">
              Update Terbaru
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="sort2"
              name="sort"
              value="title"
              onChange={(e) => setSort(e.value)}
              checked={sort === "title"}
            />
            <label htmlFor="sort2" className="p-mb-0">
              Judul Project
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="sort3"
              name="sort"
              value="deadlineDate"
              onChange={(e) => setSort(e.value)}
              checked={sort === "deadlineDate"}
            />
            <label htmlFor="sort3" className="p-mb-0">
              Deadline Terdekat
            </label>
          </div>
          <div className="button">
            <button className="p-btn reset mr-2" onClick={() => onHide()}>
              Batal
            </button>
            <button
              className="p-btn btn-primary ml-2"
              onClick={() => submitFilter()}
            >
              Terapkan
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PopupSort;
