import {
  GET_HELP_LIST_REQUEST,
  GET_HELP_DETAIL_REQUEST,
  ADD_HELP_REQUEST,
  UPDATE_HELP_REQUEST,
  DELETE_HELP_REQUEST,
  POST_HELP_CHAT_REQUEST,
  POST_HELP_NOTIF_REQUEST,
  RESET_HELP_STATE,
} from "../constant/helpConstant";

export const getHelpListAction = (params) => {
  return {
    type: GET_HELP_LIST_REQUEST,
    params,
  };
};

export const getHelpDetailAction = (id) => {
  return {
    type: GET_HELP_DETAIL_REQUEST,
    id,
  };
};

export const addHelpAction = (data) => {
  return {
    type: ADD_HELP_REQUEST,
    data,
  };
};

export const updateHelpAction = (id, data) => {
  return {
    type: UPDATE_HELP_REQUEST,
    id,
    data,
  };
};

export const deleteHelpAction = (id) => {
  return {
    type: DELETE_HELP_REQUEST,
    id,
  };
};

export const resetHelpAction = () => {
  return {
    type: RESET_HELP_STATE,
  };
};

export const postHelpChatsAction = (id, data) => {
  return {
    type: POST_HELP_CHAT_REQUEST,
    id,
    data,
  };
};

export const postHelpNotifAction = (id, data) => {
  return {
    type: POST_HELP_NOTIF_REQUEST,
    id,
    data,
  };
};
