import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getPrivacyAction } from "../../../redux/action/landingpageAction";
import { TextLoad } from "../../components/loading/loadingContent";
import { titlePage } from "../../helpers/titleHead";
import StaticLayout from "../../layouts/staticLayout";

const AboutUsPage = () => {
  const dispatch = useDispatch();
  const { dataGetPrivacy, loadingGetPrivacy } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - Privacy Policy",
    });
    dispatch(getPrivacyAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <StaticLayout>
      <div className="static-content-wrapper">
        {loadingGetPrivacy ? (
          <div className="loading-text">
            <TextLoad />
          </div>
        ) : (
          <>
            <div className="title-static">
              <h1>Privacy Policy</h1>
            </div>
            <div className="body">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${dataGetPrivacy?.privacyPolicy}`,
                }}
              />
            </div>
          </>
        )}
      </div>
    </StaticLayout>
  );
};

export default AboutUsPage;
