import React, { useState } from "react";
import AddHeadline from "./Components/AddHeadline";
import AddAccount from "./Components/AddAccount";
import AddCompany from "./Components/AddCompany";
import AddStory from "./Components/AddStory";

function CreateCampaignModule() {
  const [layout, setLayout] = useState("headline");

  return (
    <>
      <div className="step">
        <ul>
          {layout === "story" ? (
            <div
              className="link-headline"
              onClick={() => setLayout("headline")}
            >
              <li className="p-d-flex active">
                <div className="step-number">
                  <span>1</span>
                </div>
                <div className="text">Headline</div>
              </li>
            </div>
          ) : (
            <li
              className={`p-d-flex active ${
                layout === "headline" ? "show" : "hide"
              }`}
            >
              <div className="step-number">
                <span>1</span>
              </div>
              <div className="text">Headline</div>
            </li>
          )}
          <li
            className={`${
              layout === "headline" || layout === "story" ? "show" : "hide"
            } ${
              layout === "story" || layout === "account" || layout === "company"
                ? "active"
                : ""
            }`}
          >
            <div className="step-number">
              <span>2</span>
            </div>
            <div className="text">Story Angle</div>
          </li>
          <li
            className={`${
              layout === "account" || layout === "company"
                ? "show active"
                : "hide"
            }`}
          >
            <div className="step-number">
              <span>3</span>
            </div>
            <div className="text">Data Akun</div>
          </li>
          <li
            className={`${
              layout === "account" || layout === "company" ? "show" : "hide"
            } ${layout === "company" ? "active" : ""}`}
          >
            <div className="step-number">
              <span>4</span>
            </div>
            <div className="text">Data Perusahaan</div>
          </li>
        </ul>
      </div>
      {layout === "headline" && (
        <AddHeadline setHeadline={() => setLayout("story")} />
      )}
      {layout === "story" && (
        <AddStory
          handleBack={() => setLayout("headline")}
          submitAngle={() => setLayout("account")}
        />
      )}
      {layout === "account" && (
        <AddAccount
          handleBack={() => setLayout("story")}
          submitAccount={() => setLayout("company")}
        />
      )}
      {layout === "company" && (
        <AddCompany
          handleBack={() => setLayout("account")}
          registerFailed={() => setLayout("account")}
        />
      )}
    </>
  );
}

export default CreateCampaignModule;
