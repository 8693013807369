import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import HeaderMobileLanding from "../components/header/HeaderMobileLanding";
import HeaderDashboardUser from "../components/header/HeaderDashboardUser";
import Footer from "../components/footer/Footer";
import { usePageTracking } from "../helpers/pageTracking";
import { checkDevice } from "../helpers/deviceDetect";
import { getNotificationsAction } from "../../redux/action/utilsAction";
import { getSettingSiteAction } from "../../redux/action/landingpageAction";

const DashboardUserLayout = ({ children }) => {
  usePageTracking();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMobile, setIsMobile] = useState(checkDevice());

  const { users, isLoggined } = useSelector((state) => state.auth);
  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );
  const { successUpdateNotif } = useSelector((state) => state.utils);

  useEffect(() => {
    dispatch(getNotificationsAction(users?.uid, users?.user?.type));
    setIsMobile(checkDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataGetSettingSite === null) {
      dispatch(getSettingSiteAction());
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successUpdateNotif) {
      dispatch(getNotificationsAction(users?.uid, users?.user?.type));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateNotif]);

  useEffect(() => {
    if (users === null && !isLoggined) {
      history.push("/login");
    }
    if (
      users &&
      (users?.user?.type === "pm" || users?.user?.type === "general_pm")
    ) {
      history.push("/admin/pm/list-project");
    }
    if (users && users?.user?.type === "client") {
      history.push("/client/dashboard");
    }
    if (users && users?.user?.type === "finance") {
      history.push("/admin/finance/list-invoice");
    }
  });

  return (
    <div className="dashboard-user">
      {isMobile ? (
        <HeaderMobileLanding
          dataSite={dataGetSettingSite}
          loading={loadingGetSettingSite}
        />
      ) : (
        <HeaderDashboardUser
          dataSite={dataGetSettingSite}
          loading={loadingGetSettingSite}
          users={users}
        />
      )}
      {children}
      <Footer dataSite={dataGetSettingSite} loading={loadingGetSettingSite} />
    </div>
  );
};

export default DashboardUserLayout;
