import {
  GET_WINNER_RELEASE_REQUEST,
  RESET_RELEASE_STATE_REQUEST,
  ADD_MEDIA_RELEASE_REQUEST,
  DETAIL_RELEASE_REQUEST,
  PUT_MEDIA_RELEASE_REQUEST,
  DELETE_MEDIA_RELEASE_REQUEST,
  FS_MEDIA_REQUEST,
  FS_DETAIL_MEDIA_REQUEST,
  STATS_RELEASE_REQUEST,
  STATS_MEDIA_RELEASE_REQUEST,
} from "../constant/releaseConstant";

export const getWinnerReleaseAction = (params) => {
  return {
    type: GET_WINNER_RELEASE_REQUEST,
    params,
  };
};

export const resetStateReleaseAction = () => {
  return {
    type: RESET_RELEASE_STATE_REQUEST,
  };
};

export const getDetailReleaseAction = (id, params) => {
  return {
    type: DETAIL_RELEASE_REQUEST,
    id,
    params,
  };
};

export const postMediaReleaseAction = (data) => {
  return {
    type: ADD_MEDIA_RELEASE_REQUEST,
    data,
  };
};

export const updateMediaReleaseAction = (id, data) => {
  return {
    type: PUT_MEDIA_RELEASE_REQUEST,
    id,
    data,
  };
};

export const deleteMediaReleaseAction = (id) => {
  return {
    type: DELETE_MEDIA_RELEASE_REQUEST,
    id,
  };
};

export const getMediaAction = () => {
  return {
    type: FS_MEDIA_REQUEST,
  };
};

export const getNewsAction = (id) => {
  return {
    type: FS_DETAIL_MEDIA_REQUEST,
    id,
  };
};

export const statsReleaseAction = () => {
  return {
    type: STATS_RELEASE_REQUEST,
  };
};

export const statsMediaReleaseAction = (id) => {
  return {
    type: STATS_MEDIA_RELEASE_REQUEST,
    id,
  };
};
