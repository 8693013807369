import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import _ from "lodash";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { getProjectPmAction } from "../../../../../redux/action/projectAction";

const FilterListProject = (props) => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState({
    category: "",
    type: "",
    order: "",
  });

  const { projectParam } = useSelector((state) => state.project);
  const categoryItems = [
    { name: "Tampilkan Semua", code: "" },
    { name: "Private", code: "private" },
    { name: "Public", code: "public" },
  ];
  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = { ...projectParam, q: search };
      dispatch(getProjectPmAction(params));
    }, 1000)
  ).current;

  const filterHandler = (data) => {
    setFilterValue(data);
    const params = {
      ...projectParam,
      type: data.type.code,
      category: data.category.code,
      orderField: data.order.code,
    };
    dispatch(getProjectPmAction(params));
  };

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter">
        <div className="filter-wrapp">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block"
            placeholder="Masukan nama project"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <small id="search-help" className="p-d-block">
            Cari di <strong>List User</strong>
          </small>
        </div>
        <div className="filter-wrapp">
          <div className="sort-filter">
            <Dropdown
              value={filterValue.category}
              options={categoryItems}
              onChange={(e) =>
                filterHandler({
                  ...filterValue,
                  category: e.target.value,
                })
              }
              optionLabel="name"
              placeholder="Tampilkan semua"
            />
            <small className="p-d-block">Urutkan Berdasarkan</small>
          </div>
        </div>
      </div>
      <Link
        to="/admin/pm/add-project"
        className="p-button p-button-primary button-link"
      >
        Tambah Pengguna
      </Link>
    </div>
  );
};

export default FilterListProject;
