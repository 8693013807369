/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import DialogPassword from "./Components/DialogPassword";
import DialogEmail from "./Components/DialogEmail";
import DialogPhone from "./Components/DialogPhone";
import DialogAlert from "../../../components/dialog/DialogAlert";

import {
  logoutAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";
import { useHistory } from "react-router";

const SettingsClientModule = () => {
  const history = useHistory()
  const dispatch = useDispatch();
  const [openPassword, setOpenPassword] = useState(false);
  const [openEmail, setOpenEmail] = useState(false);
  const [openPhone, setOpenPhone] = useState(false);
  const [openSuccess, setOpenSuccess] = useState({
    dialog: false,
    title: null,
    desc: null,
  });
  const [openError, setOpenError] = useState({
    dialog: false,
    title: null,
    desc: null,
  });

  const {
    successUpdatePassword,
    errUpdatePassword,
    successUpdateEmail,
    errUpdateEmail,
    successUpdatePhone,
    errUpdatePhone,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successUpdatePassword) {
      setOpenSuccess({
        dialog: true,
        title: "Berhasil Mengubah Kata Sandi",
        desc: "Silakan masuk ulang dengan kata sandi baru",
      });
    }
  }, [successUpdatePassword]);

  useEffect(() => {
    if (errUpdatePassword) {
      setOpenError({
        dialog: true,
        title: "Gagal Mengubah Kata Sandi",
        desc: "Kata sandi gagal diubah",
      });
    }
  }, [errUpdatePassword]);

  useEffect(() => {
    if (successUpdateEmail) {
      setOpenSuccess({
        dialog: true,
        title: "Berhasil Mengubah Email",
        desc: "Silakan cek email anda untuk verifikasi",
      });
    }
  }, [successUpdateEmail]);

  useEffect(() => {
    if (errUpdateEmail) {
      setOpenError({
        dialog: true,
        title: "Gagal Mengubah Email",
        desc: "Email gagal diubah",
      });
    }
  }, [errUpdateEmail]);

  useEffect(() => {
    if (successUpdatePhone) {
      setOpenSuccess({
        dialog: true,
        title: "Berhasil Mengubah Nomor Telepon",
        desc: "Silakan login ulang dengan nomor telepon Baru",
      });
    }
  }, [successUpdatePhone]);

  useEffect(() => {
    if (errUpdatePhone) {
      setOpenError({
        dialog: true,
        title: "Gagal Mengubah Nomor telepon",
        desc: "Nomor telepon gagal diubah",
      });
    }
  }, [errUpdatePhone]);

  const closeSuccess = () => {
    setOpenSuccess({
      dialog: false,
      title: null,
      desc: null,
    });
    dispatch(resetStateAuthAction());
    dispatch(logoutAction());
  };

  const closeError = () => {
    setOpenError({
      dialog: false,
      title: null,
      desc: null,
    });
    dispatch(resetStateAuthAction());
  };

  return (
    <>
      <div className="section bg-grey">
        <div className="p-d-flex p-justify-center">
          <div className="p-grid p-justify-center">
            <div className="p-col-12">
              <div className="card card-custom-detail w-full has-shadow mb-5 client-setting">
                <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">
                        Ubah Password
                      </span>
                    </h3>
                  </div>
                  <div>
                    <a
                      className="btn px-0 py-o ml-3"
                      onClick={() => history.push("/client/settings/password")}
                    >
                      <span className="icon-edit-o" />
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0 pb-3">
                  <div className="notif-settings">
                    <div className="notif-icon">
                      <span className="material-icons">check_cirlce</span>
                    </div>
                    <label>Kata sandi anda telah diatur</label>
                    <p>
                      Anda mendaftar dengan mengunakan kata sandi. Anda bisa
                      mengubah kata sandi untuk login.
                    </p>
                  </div>
                </div>
              </div>
              <div className="card card-custom-detail w-full has-shadow mb-5 client-setting">
                <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                  <div className="d-flex">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label font-weight-bolder text-dark">
                        Ubah Email
                      </span>
                    </h3>
                  </div>
                  <div>
                    <a
                      className="btn px-0 py-o ml-3"
                      onClick={() => history.push("/client/settings/email")}
                    >
                      <span className="icon-edit-o" />
                    </a>
                  </div>
                </div>
                <div className="card-body pt-0 pb-3">
                  <div className="notif-settings">
                    <div className="notif-icon">
                      <span className="material-icons">check_cirlce</span>
                    </div>
                    <label>Email anda telah diatur</label>
                    <p>
                      Anda mendaftar dengan mengunakan email. Anda bisa mengubah
                      email untuk login.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogPassword
        visible={openPassword}
        closeDialog={() => setOpenPassword(false)}
      />

      <DialogEmail
        visible={openEmail}
        closeDialog={() => setOpenEmail(false)}
      />

      <DialogPhone
        visible={openPhone}
        closeDialog={() => setOpenPhone(false)}
      />

      <DialogAlert
        type={"success"}
        visible={openSuccess.dialog}
        closeDialog={() => closeSuccess()}
        title={openSuccess.title}
        text={openSuccess.desc}
      />

      <DialogAlert
        type={"error"}
        visible={openError.dialog}
        closeDialog={() => closeError()}
        title={openError.title}
        text={openError.desc}
      />
    </>
  );
};

//   return (
//     <div className='setting-module' >
//             <div className="title">Password</div>

//             <div className="table-list">
//                 TEST
//             </div>

//         </div>
//   );
// };

export default SettingsClientModule;
