import { call, put } from "redux-saga/effects";
import {
  GET_LIST_INFO_FAILURE,
  GET_LIST_INFO_SUCCESS,
  POST_INFO_FAILURE,
  POST_INFO_SUCCESS,
  GET_DETAIL_INFO_FAILURE,
  GET_DETAIL_INFO_SUCCESS,
  UPDATE_INFO_FAILURE,
  UPDATE_INFO_SUCCESS,
  DELETE_INFO_FAILURE,
  DELETE_INFO_SUCCESS,
} from "../constant/adminConstant";

import { getToken } from "../service/firebase/auth";
import {
  getInfo,
  postInfo,
  updateInfo,
  deleteInfo,
} from "../service/api/adminApi";
import { getDetailInfo } from "../service/firebase/admin";

export function* getListInfoSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getInfo, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_LIST_INFO_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_LIST_INFO_FAILURE, data });
  }
}

export function* postInfoSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(postInfo, token, action.data);
  if (ok) {
    yield put({ type: POST_INFO_SUCCESS });
  } else {
    yield put({ type: POST_INFO_FAILURE, data });
  }
}

export function* getDetailInfoSaga(action) {
  try {
    const snapshot = yield call(getDetailInfo, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_DETAIL_INFO_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_DETAIL_INFO_FAILURE, err });
  }
}

export function* updateInfoSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(updateInfo, token, action.id, action.data);
  if (ok) {
    yield put({ type: UPDATE_INFO_SUCCESS });
  } else {
    yield put({ type: UPDATE_INFO_FAILURE, data });
  }
}

export function* deleteInfoSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(deleteInfo, token, action.id);
  if (ok) {
    yield put({ type: DELETE_INFO_SUCCESS });
  } else {
    yield put({ type: DELETE_INFO_FAILURE, data });
  }
}
