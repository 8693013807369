import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import AddUserModule from "../../../modules/Admin/UserPM/AddUserModule";
import DashboardLayout from "../../../layouts/dashboardLayout";

import { getProvincesAction } from "../../../../redux/action/utilsAction";
import { titlePage } from "../../../helpers/titleHead";

const AddUser = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProvincesAction());

    titlePage({
      title: "IRIS - Tambah Pengguna",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout title="Manajemen Pengguna">
      <AddUserModule />
    </DashboardLayout>
  );
};

export default AddUser;
