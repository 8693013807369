import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import Logo from "../../assets/img/logo.svg";
import AuthLayout from "../../layouts/authLayout";
import RegisterBenefitModule from "../../modules/User/Register/RegisterBenefitModule";
import RegisterPhoneModule from "../../modules/User/Register/RegisterPhoneModule";
import RegisterOtpModule from "../../modules/User/Register/RegisterOtpModule";
import RegisterEmailModule from "../../modules/User/Register/RegisterEmailModule";

import { loginWithLinkAction } from "../../../redux/action/authAction";
import { titlePage } from "../../helpers/titleHead";

const RegisterInvitationPage = (props) => {
  const location = useLocation();
  const history = useHistory();

  const dispatch = useDispatch();

  const [otpLayout, setOtpLayout] = useState(false);
  const [tabs, setTabs] = useState("email");

  const parsed = queryString.parse(location.search);

  const emailUrl = parsed.email;
  const tokenUrl = parsed.token;

  const {
    verificationOtp,
    successPhoneRegister,
    successOtp,
    isLoggedIn,
    errLoginEmail,
    successEmailRegister,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successPhoneRegister && verificationOtp !== null) {
      setOtpLayout(true);
      setTabs("otp");
    }
  }, [successPhoneRegister, verificationOtp]);

  useEffect(() => {
    titlePage({
      title: "IRIS - Registrasi Undangan",
    });

    dispatch(loginWithLinkAction(emailUrl));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((successOtp || successEmailRegister) && isLoggedIn) {
      history.push("/dashboard");
    }
  });

  useEffect(() => {
    if (errLoginEmail) {
      history.push("/not-found");
    }
  });

  return (
    <AuthLayout>
      <div className="auth-wrapper">
        <div className="container">
          <div className="p-grid">
            <RegisterBenefitModule />
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lg-0 p-md-0">
              <div className="auth-form p-d-flex p-jc-center">
                <div className="form-reg">
                  <div className="p-text-center">
                    <img src={Logo} alt="logo" />
                    <h2>Registrasi Akun</h2>
                    <h3>Invitation Only</h3>
                    {!otpLayout && (
                      <div className="button-tab-wrapper">
                        <button
                          className={`tab-auth ${
                            tabs === "email" ? "active" : ""
                          }`}
                          onClick={() => setTabs("email")}
                        >
                          Email
                        </button>
                        <button
                          className={`tab-auth ${
                            tabs === "phone" ? "active" : ""
                          }`}
                          onClick={() => setTabs("phone")}
                        >
                          Nomor Telepon
                        </button>
                      </div>
                    )}
                    {tabs === "phone" && <RegisterPhoneModule />}
                    {tabs === "email" && (
                      <RegisterEmailModule
                        emailUrl={emailUrl}
                        tokenUrl={tokenUrl}
                      />
                    )}
                    {otpLayout && <RegisterOtpModule />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default RegisterInvitationPage;
