import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getPICListAction } from "../../../redux/action/clientPicAction";
import { titlePage } from "../../helpers/titleHead";
import queryString from "query-string";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import PICListModule from "../../modules/Client/PICManagement/PICListModule";

const PICListPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const parsed = queryString.parse(location.search);
  const paramsFromUrl = {
      page: parsed?.page,
      q: parsed?.search
  }
  const { picParams } = useSelector((state) => state.clientPic);
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Tim",
    });
    dispatch(getPICListAction(parsed?.page ? paramsFromUrl : picParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="myrelease-page">
      <DashboardClientLayout title="Manajemen Tim">
        <PICListModule />
      </DashboardClientLayout>
    </div>
  );
};

export default PICListPage;
