import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";
import emptyNotif from "../../assets/img/empty-list-notif.png";
import {
  updateNotificationAction,
  resetUtilsStateAction,
} from "../../../redux/action/utilsAction";

const NotificationMobile = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [url, setUrl] = useState("");

  const { successUpdateNotif } = useSelector((state) => state.utils);
  useEffect(() => {
    if (successUpdateNotif) {
      dispatch(resetUtilsStateAction());
      history.push(url.replace(process.env.REACT_APP_BASE_URL, ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateNotif]);

  const updateNotif = (data) => {
    dispatch(updateNotificationAction(data.id));
    setUrl(data.url);
  };
  return (
    <div className="notification-mobile">
      <h4>Notifikasi</h4>
      {props?.notifList?.length > 0 ? (
        <div className="notification-content">
          {props?.notifList?.map((item) => (
            <div
              className={`notification-box ${item.isRead ? "" : "active"} `}
              onClick={() =>
                updateNotif({ id: item.id, url: item?.url?.web || "/" })
              }
            >
              <p className="date">
                {dayjs(item.createdAt.toDate())
                  .locale("id")
                  .format("DD MMMM YYYY, HH:mm")}
              </p>
              <p className="notif-title">{item.heading}</p>
              <p className="notif-text">{item.content}</p>
            </div>
          ))}
        </div>
      ) : (
        <div className="empty-notification">
          <img src={emptyNotif} alt="empty" />
          <span>Notifikasi Belum Tersedia </span>
        </div>
      )}
    </div>
  );
};

export default NotificationMobile;
