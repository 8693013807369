/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { formUrl } from "../../../helpers/url";
import DialogAlert from "../../../components/dialog/DialogAlert";
import ButtonLoader from "../../../components/button/ButtonLoader";

import {
  uploadPortfolioAction,
  resetPortfolioAction,
} from "../../../../redux/action/userAction";

import {
  editWriterProfileAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";

const ProfileEditPortfolioModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useParams();

  const [yearValue, setYearValue] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [linkError, setLinkError] = useState("");
  const [titleError, setTitleError] = useState("");
  const [yearError, setYearError] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [fileName, setFileName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [errorImage, setErrorImage] = useState("");
  const [dialogSuccess, setDialogSuccess] = useState({
    dialog: false,
    title: "",
    text: "",
  });
  const [dialogAlert, setDialogAlert] = useState(false);
  const [portfolios, setPortfolios] = useState([]);
  const [portfolioError, setPortfolioError] = useState("");

  const {
    users,
    loadingEditWriterProfile,
    successEditWriterProfile,
  } = useSelector((state) => state.auth);

  const {
    loadingUploadPortfolio,
    successUploadPortfolio,
    dataUploadPortfolio,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (query.portfolioId) {
      setPortfolios(
        users?.user?.portofolio?.filter(
          (item, i) => query?.portfolioId.indexOf(i) === -1
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.portfolioId]);

  useEffect(() => {
    if (query.portfolioId) {
      setYearValue(users?.user?.portofolio[query?.portfolioId]?.year);
      setTitleValue(users?.user?.portofolio[query?.portfolioId]?.title);
      if (
        users?.user?.portofolio[query.portfolioId]?.linkPortofolio?.includes(
          "https://firebasestorage.googleapis.com/v0/"
        )
      ) {
        setImageValue(
          users?.user?.portofolio[query.portfolioId]?.linkPortofolio
        );
        setFileName(
          `${users?.user?.portofolio[query.portfolioId]?.title?.replaceAll(
            " ",
            "-"
          )}.pdf`
        );
        setErrorImage("");
      } else {
        setLinkValue(
          users?.user?.portofolio[query.portfolioId]?.linkPortofolio
        );
        setLinkError("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.portfolioId]);

  useEffect(() => {
    if (
      yearValue?.length > 0 &&
      titleValue?.length > 0 &&
      (linkValue?.length > 0 || imageValue !== null)
    ) {
      setIsDisabled(false);
    }
  }, [imageValue, linkValue.length, titleValue.length, yearValue.length]);

  useEffect(() => {
    if (successUploadPortfolio) {
      setImageValue(dataUploadPortfolio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadPortfolio]);

  useEffect(() => {
    if (successEditWriterProfile) {
      setDialogSuccess({ ...dialogSuccess, dialog: true });
    }
    if (dialogAlert.dialog && successEditWriterProfile) {
      setDialogAlert({ ...dialogAlert, dialog: false });
      dispatch(resetStateAuthAction());
      dispatch(resetPortfolioAction());
      history.push("/profile");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successEditWriterProfile]);

  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(resetStateAuthAction());
    dispatch(resetPortfolioAction());
    setDialogSuccess({ dialog: false, title: "", text: "" });
    history.push("/profile");
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".pdf",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        setErrorImage("Ukuran file lebih dari 5MB");
      } else if (acceptedFiles.length > 1) {
        setErrorImage("Silakan pilih satu file");
      } else if (acceptedFiles[0]?.type !== "application/pdf") {
        setErrorImage("File portofolio harus dalam bentuk PDF");
      } else {
        setErrorImage("");
        setFileName(acceptedFiles[0].name);

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/user-data/${times}/${users.uid}/${fileName}`;

        dispatch(uploadPortfolioAction(acceptedFiles[0], path));
      }
    },
  });

  const changeUrl = (value) => {
    setLinkError("");
    setLinkValue(value);
  };

  const deleteData = () => {
    dispatch(editWriterProfileAction({ portofolio: portfolios }));
    setDialogSuccess({
      ...dialogSuccess,
      text: "Portofolio berhasil dihapus",
      title: "Berhasil Hapus Portofolio",
    });
  };

  const submitData = () => {
    setTitleError("");
    setYearError("");
    setLinkError("");
    titleValue.length < 1 && setTitleError("Judul portofolio harus diisi");
    if (yearValue.length !== 4 || !yearValue.match(/^[0-9]+$/)) {
      setYearError("Tahun tidak valid");
    }
    if (linkValue?.length < 1 && imageValue === null) {
      setPortfolioError("Masukkan tautan atau unggah file portofolio");
    }
    if (linkValue?.length > 0 && !formUrl(linkValue)) {
      setLinkError("Url tidak valid");
    }
    if (linkValue?.length > 0 && imageValue !== null) {
      setPortfolioError("Pilih salah satu, gunakan tautan atau unggah file");
    }
    if (
      titleValue.length > 1 &&
      yearValue.length === 4 &&
      yearValue.match(/^[0-9]+$/) &&
      ((linkValue?.length > 0 && formUrl(linkValue)) || imageValue !== null) &&
      ((linkValue?.length > 0 && imageValue === null) ||
        (linkValue?.length === 0 && imageValue !== null))
    ) {
      const newData = {
        year: yearValue,
        title: titleValue,
        linkPortofolio: linkValue || imageValue,
      };
      portfolios.splice(query?.portfolioId, 0, newData);
      dispatch(editWriterProfileAction({ portofolio: portfolios }));
      setDialogSuccess({
        ...dialogSuccess,
        text: "Portofolio berhasil diupdate",
        title: "Berhasil Update Portofolio",
      });
    }
  };

  const handleInputYear = (e) => {
    e.target.value.length < 5
      ? setYearValue(e.target.value)
      : setYearValue(yearValue);
  };

  const handleKeyInputYear = (e) => {
    const allowedKeys = "1234567890".split("");
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="card card-custom-detail w-full mb-5 border-0 has-padding-x edit-portfolio">
          <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center p-px-0">
            <div className="d-flex">
              <h3 className="card-title align-items-start flex-column">
                <span className="card-label font-weight-bolder text-dark">
                  Edit Portofolio
                </span>
              </h3>
            </div>
            <div>
              <a
                href="#"
                className="btn px-0 py-o ml-3"
                onClick={() => setDialogAlert({ ...dialogAlert, dialog: true })}
              >
                <span className="icon-delete-line" />
              </a>
            </div>
          </div>
          <div className="card-body pt-0 pb-3 p-px-0">
            <div className="p-field">
              <label className="p-d-block dark">Tahun Portofolio*</label>
              <InputText
                className="p-d-block half"
                placeholder="Contoh: 2020"
                value={yearValue}
                onChange={(e) => handleInputYear(e)}
                onKeyPress={(e) => handleKeyInputYear(e)}
                type="text"
              />
              <span className="p-col-12 text-helper text-danger">
                {yearError}
              </span>
            </div>
            <div className="p-field">
              <label className="p-d-block dark">Judul Portofolio*</label>
              <InputText
                className="p-d-block half"
                placeholder="Judul Portfolio"
                value={titleValue}
                onChange={(e) => setTitleValue(e.target.value)}
              />
              <span className="p-col-12 text-helper text-danger">
                {titleError}
              </span>
            </div>
            <div className="p-field p-grid">
              <label className="p-d-block dark p-col-12">Portofolio* </label>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <InputText
                  className="p-d-block"
                  placeholder="Masukan Link Portfolio"
                  value={linkValue}
                  type="link"
                  onChange={(e) => changeUrl(e.target.value)}
                />
                {linkError?.length > 0 && (
                  <span className="p-col-12 text-helper text-danger">
                    {linkError}
                  </span>
                )}
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12 full">
                <div {...getRootProps({ className: "file-upload" })}>
                  <input {...getInputProps()} />
                  <span className="file-name">
                    {imageValue !== null ? fileName : "Masukkan PDF Portofolio"}
                  </span>
                  {loadingUploadPortfolio ? (
                    <span className="file-button">Mengupload...</span>
                  ) : (
                    <span className="file-button" onClick={open}>
                      {imageValue !== null ? "Ubah File" : "Pilih File"}
                    </span>
                  )}
                </div>
                {errorImage?.length > 0 && (
                  <span className="p-col-12 text-helper text-danger">
                    {errorImage}
                  </span>
                )}
              </div>
              <span
                className={`p-col-12 ${
                  portfolioError
                    ? "text-helper text-danger"
                    : "text-helper-italic"
                }`}
              >
                *Pilih salah satu, gunakan link atau upload file
              </span>
            </div>
            <div className="footer-form">
              <div className=" p-d-flex p-jc-end p-ai-center">
                <Button
                  className="p-button p-button-outlined p-button-secondary p-button-rounded mr-3"
                  onClick={() => history.goBack()}
                  label="Batal"
                />
                {loadingEditWriterProfile ? (
                  <ButtonLoader />
                ) : (
                  <Button
                    label="Edit"
                    onClick={() => submitData()}
                    className="p-button-rounded"
                    disabled={isDisabled}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogAlert
        type={"success"}
        visible={dialogSuccess.dialog}
        closeDialog={closeSuccess}
        title={dialogSuccess.title}
        text={dialogSuccess.text}
      />
      <DialogAlert
        type={"warning"}
        visible={dialogAlert.dialog}
        closeDialog={() => setDialogAlert({ ...dialogAlert, dialog: false })}
        title={"Anda yakin ingin menghapus portofolio ini?"}
        text={""}
        actionText={"HAPUS"}
        actionDialog={() => deleteData()}
        loading={loadingEditWriterProfile}
        cancelText={"BATAL"}
      />
    </>
  );
};

export default ProfileEditPortfolioModule;
