import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getHelpListAction } from "../../../redux/action/helpAction";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import HelpListModule from "../../modules/Client/Help/HelpListModule";

function HelpPage() {
  const dispatch = useDispatch()
  useEffect(() => {
    titlePage({
      title: "IRIS - Bantuan",
    });
    dispatch(getHelpListAction())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Tiket Bantuan">
      <HelpListModule/>
    </DashboardClientLayout>
  );
}

export default HelpPage;
