/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import callIcon from "../../../assets/img/Call.png";
import mailIcon from "../../../assets/img/Message.png";
import iconDownload from "../../../assets/img/download-blue.svg";
import { avatarLabel } from "../../../helpers/profileHelper";
import { phoneNumberRepelace } from "../../../helpers/phoneNumber";

function ProfileModule() {
  const { users } = useSelector((state) => state.auth);
  const { dataDetailClient } = useSelector((state) => state.client);

  return (
    <div className="client-profile-module">
      <div className="profile-title d-flex">
        <span>Profil</span>
        <Link
          to={`/client/profile/edit`}
          className="px-0 py-o ml-3 d-flex align-items-center text-primary"
        >
          <strong>Edit</strong>
        </Link>
      </div>
      <div className="profile-info d-block w-100">
        <div className="row">
          <div className="col-12">
            <div className="header-account p-jc-between p-ai-center">
              <h3>Profil Pengguna</h3>
              <Link
                to={`/client/profile/edit`}
                className="px-0 py-o ml-3 d-flex align-items-center text-primary"
              >
                <strong>Edit</strong>
              </Link>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <div className="photo-profile-wrapper">
              <div className="photo-profile">
                {!users?.user?.photo ? (
                  <div className="photo-profile-warp">
                    <div className="image-thumb client-thumb">
                      <div className="letter-pics client-upload">
                        {avatarLabel(users)}
                      </div>
                    </div>
                  </div>
                ) : (
                  <img src={users?.user?.photo} alt="profile" />
                )}
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-8">
            <div className="info-client w-100">
              <div className="name">{users?.user?.name}</div>
              <div className="position">{users?.user?.position}</div>
              <div className="info-item">
                <img src={mailIcon} alt="mail" />
                <span>{users?.user?.email}</span>
              </div>
              <div className="info-item">
                <img src={callIcon} alt="icon" />
                <span>{phoneNumberRepelace(users?.user?.phone)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="info-group d-block w-100">
        <div className="row">
          <div className="col-12">
            <div className="p-d-flex p-jc-between p-ai-center">
              <h3>Profil Perusahaan</h3>
              <a
                className="btn-download"
                href={dataDetailClient?.companyPortofolio}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iconDownload} alt="ic-btn" />
                Portfolio Perusahaan
              </a>
            </div>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Nama Perusahaan</p>
          </div>
          <div className="col-12 col-sm-8">
            <p className="detail-text">{dataDetailClient?.companyName}</p>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Nomor Telepon Perusahaan</p>
          </div>
          <div className="col-12 col-sm-8">
            <p className="detail-text">{dataDetailClient?.companyPhone}</p>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Email Perusahaan</p>
          </div>
          <div className="col-12 col-sm-8">
            <p className="detail-text">{dataDetailClient?.companyEmail}</p>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Background Perusahaan</p>
          </div>
          <div className="col-12 col-sm-8">
            <p className="detail-text">{dataDetailClient?.companyBackground}</p>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Bidang Usaha</p>
          </div>
          <div className="col-12 col-sm-8">
            <p className="detail-text">{dataDetailClient?.businessField}</p>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Jenis Layanan Perusahaan</p>
          </div>
          <div className="col-12 col-sm-8">
            <p className="detail-text">{dataDetailClient?.companyService}</p>
          </div>
          <div className="col-12 col-sm-4">
            <p className="label-text">Alamat Perusahaan</p>
          </div>
          <div className="col-12 col-sm-8">
            <p className="detail-text">{dataDetailClient?.companyAddress}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileModule;
