/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ActionBody from "../../../../components/datatableBody/ActionBody";
import TextBody from "../../../../components/datatableBody/TextBody";
import DataEmpty from "../../../../components/emptyState/dataEmpty";
import TableLoader from "../../../../components/loading/tableLoader";
import AdminPaginate from "../../../../components/paging/pagination";
import DialogAlert from "../../../../components/dialog/DialogAlert";
import {
  getUsersAction,
  resetStateUserAction,
  deleteUserAction,
} from "../../../../../redux/action/userAction";
import DateBody from "../../../../components/datatableBody/DateBody";
import FilterPM from "./Components/FilterPM";

const PMListModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const {
    dataGetUsers,
    loadingGetUsers,
    totalPage,
    successDeleteUser,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (successDeleteUser) {
      setTimeout(() => {
        setLoadingState(false);
        const params = {
          q: "",
          page: 1,
          perpage: 10,
          type: "general_pm",
        };
        dispatch(getUsersAction(params));
        dispatch(resetStateUserAction());
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDeleteUser]);

  const nameBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.name || item?.email}
        link={`/admin/pm/pm-detail/${item?._id}`}
      />
    );
  };

  const dateBodyTemplate = (item) => {
    return <DateBody date={item?.createdAt} />;
  };

  const phoneBodyTemplate = (item) => {
    return <TextBody text={item?.phone?.replace("+62", "0") || "-"} />;
  };

  const statusBodyTemplate = (item) => {
    return (
      <span
        className={`badge wide ${
          item.status === "active" ? "verified" : "reject"
        }`}
      >
        {item.status === "active" ? "Aktif" : "Nonaktif"}
      </span>
    );
  };

  const actionBodyTemplate = (item) => {
    return (
      <div className="p-d-flex p-jc-end">
        <ActionBody
          deleteOnly
          id={item?._id}
          name={item?.name}
          onDelete={() => deleteUser(item?._id)}
          loading={loadingState}
          isSuccess={successDeleteUser}
          disableButton={item?.nProjectHandle > 0}
        />
      </div>
    );
  };

  const deleteUser = (id) => {
    dispatch(deleteUserAction(id));
    setLoadingState(true);
  };

  const closeSuccess = () => {
    dispatch(resetStateUserAction());
    setDialogSuccess(false);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search || "",
      page: page + 1,
      perpage: 10,
      type: "general_pm",
    };
    history.push(
      `/admin/pm/list-pm?search=${parsed.search || ""}&page=${page + 1}`
    );
    dispatch(getUsersAction(params));
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Project Manager
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterPM />
          {loadingGetUsers ? (
            <TableLoader />
          ) : (
            <>
              {dataGetUsers?.length < 1 || dataGetUsers === null ? (
                <DataEmpty />
              ) : (
                <>
                  <DataTable value={dataGetUsers} className="no-padding">
                    <Column
                      header="Tanggal"
                      headerClassName="pm-date"
                      body={dateBodyTemplate}
                    ></Column>
                    <Column
                      header="Nama"
                      headerClassName="pm-name"
                      body={nameBodyTemplate}
                    ></Column>
                    <Column
                      header="Email"
                      headerClassName="pm-email"
                      field="email"
                    ></Column>
                    <Column
                      header="No. Handphone"
                      body={phoneBodyTemplate}
                      field="nohp"
                    ></Column>
                    <Column header="Status" body={statusBodyTemplate}></Column>
                    <Column
                      headerClassName="action delete-only"
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Kirim Ulang"}
        text={"Tautan berhasil dikirim ke email user"}
      />
    </>
  );
};

export default PMListModule;
