import React from "react";
import { useHistory } from "react-router";

const TitleProjectBody = (props) => {
  const { name, owner, link } = props;
  const history = useHistory();
  return (
    <div className="name-title-table" onClick={() => history.push(link)}>
      <h6>{name}</h6>
      <p>{owner}</p>
    </div>
  );
};

export default TitleProjectBody;
