import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import ListUserApplicantsModule from "../../../modules/Admin/UserPM/ListUserApplicantsModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getUsersAction,
  resetStateUserAction,
} from "../../../../redux/action/userAction";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";

const ListUserPM = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { userParams } = useSelector((state) => state.user);

  useEffect(() => {
    history.push("/admin/pm/list-user/applicants");
    const params = {
      ...userParams,
      type: "applicant",
    };
    dispatch(getUsersAction(params));
    dispatch(resetUtilsStateAction());
    dispatch(resetStateUserAction());

    titlePage({
      title: "IRIS - Daftar Penulis",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Penulis">
      <ListUserApplicantsModule />
    </DashboardLayout>
  );
};

export default ListUserPM;
