import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { formEmail } from "../../../../helpers/email";
import {
  updateEmailAction,
  editWriterProfileAction,
} from "../../../../../redux/action/authAction";

const DialogEmail = (props) => {
  const dispatch = useDispatch();
  const [emailState, setEmailState] = useState({
    value: "",
    error: null,
  });

  const { users, loadingUpdateEmail, successUpdateEmail } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (successUpdateEmail) {
      dispatch(
        editWriterProfileAction({
          email: emailState.value,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateEmail]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Batal"
          onClick={props.closeDialog}
          className="p-button-secondary p-button-outlined p-button-rounded mr-3"
          disabled={loadingUpdateEmail}
        />
        <Button
          label="Ubah"
          onClick={() => submitEmail()}
          className="p-button-primary p-button-rounded"
          autoFocus
          disabled={loadingUpdateEmail}
        />
      </div>
    );
  };

  const submitEmail = () => {
    if (!formEmail(emailState?.value)) {
      setEmailState({
        ...emailState,
        error: "Email tidak valid",
      });
    } else if (emailState?.value?.length < 1) {
      setEmailState({
        ...emailState,
        error: "Email harus diisi",
      });
    } else {
      dispatch(updateEmailAction(emailState.value));
    }
  };

  return (
    <div className="normal-dialog no-border-footer rounded">
      <Dialog
        header="Ubah Email"
        visible={props.visible}
        style={{ width: "40vw" }}
        footer={renderFooter()}
        onHide={props.closeDialog}
      >
        <div className="form-setting p-px-4">
          <div className="p-field">
            <label className="p-d-block">Email Sekarang</label>
            <InputText
              type="email"
              placeholder="Email Lama"
              className="p-d-block"
              value={users?.user?.email}
              disabled
            />
          </div>
          <div className="p-field">
            <label className="p-d-block">Email Baru</label>
            <InputText
              type="email"
              placeholder="Email Baru"
              className={`p-d-block ${emailState?.error && "p-invalid"}`}
              value={emailState.value}
              onChange={(e) =>
                setEmailState({ value: e.target.value, error: null })
              }
            />
            {emailState.error && (
              <smal className="p-error p-d-block p-text-right">
                {emailState.error}
              </smal>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DialogEmail;
