import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import TextBody from "../../../components/datatableBody/TextBody";
import DateBody from "../../../components/datatableBody/DateBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";

import FilterListProject from "./Components/FilterListInvoice";
import { getFinanceOrderAction } from "../../../../redux/action/financeAction";
import StatusBadge from "../../../components/badge/StatusBadge";

const ListInvoiceModule = (props) => {
  const { isFinance } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const {
    loadingGetFinanceOrder,
    dataGetFinanceOrder,
    totalPage,
  } = useSelector((state) => state.finance);
  const { users } = useSelector((state) => state.auth);

  const invoiceBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.invoiceNumber || "Belum Tersedia"}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
      />
    );
  };
  const orderBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.jobNumber || "Belum Tersedia"}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
      />
    );
  };

  const clientBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.clientName || "Belum Tersedia"}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
      />
    );
  };

  const dateBodyTemplate = (item) => {
    return (
      <DateBody
        date={item?.createdAt}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
        isHour
      />
    );
  };
  const deadlineBodyTemplate = (item) => {
    return item?.paymentDeadline ? (
      <DateBody
        date={item?.createdAt}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
        isHour
      />
    ) : (
      <TextBody
        text={"Belum Tersedia"}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
      />
    );
  };

  const statusBodyTemplate = (item) => {
    return (
      <StatusBadge
        status={item.status}
        userType={users?.user?.type}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
      />
    );
  };

  const noteBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.note || "-"}
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-invoice/${
          item?._id
        }`}
      />
    );
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      orderField: parsed?.orderField || "",
      orderDir: parsed?.orderDir || "",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getFinanceOrderAction(params));

    history.push(
      `/admin/${
        isFinance ? "finance" : "pm"
      }/list-invoice?search=${parsed?.search ||
        ""}&orderField=${parsed?.orderField ||
        ""}&orderDir=${parsed?.orderDir || ""}&page=${page + 1}`
    );
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Invoice
            </span>
          </h3>
        </div>
        <div className="card-body pt-0">
          <FilterListProject parsed={parsed} isFinance />
          {loadingGetFinanceOrder ? (
            <TableLoader />
          ) : (
            <>
              {dataGetFinanceOrder?.length < 1 ||
              dataGetFinanceOrder === null ? (
                <DataEmpty />
              ) : (
                <DataTable value={dataGetFinanceOrder} className="no-padding">
                  <Column
                    header="Request Time"
                    body={dateBodyTemplate}
                    bodyClassName="text-grey"
                  />
                  <Column
                    header="Order ID"
                    bodyClassName="order-body"
                    headerClassName="order-body"
                    body={orderBodyTemplate}
                  />
                  <Column
                    header="Nomor Invoice"
                    bodyClassName="invoice-body"
                    headerClassName="invoice-body"
                    body={invoiceBodyTemplate}
                  />
                  <Column header="Nama Klien" body={clientBodyTemplate} />
                  <Column
                    header="Deadline Pembayaran"
                    bodyClassName="text-grey"
                    body={deadlineBodyTemplate}
                  />
                  <Column header="Status" body={statusBodyTemplate} />
                  <Column
                    header="Catatan"
                    bodyClassName="text-grey text-italic"
                    body={noteBodyTemplate}
                  />
                </DataTable>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListInvoiceModule;
