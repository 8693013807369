/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import SVG from "react-inlinesvg";
import MinusIcon from "../../../../../app/assets/img/minus-icon.svg";
import PlusIcon from "../../../../../app/assets/img/plus-icon.svg";
import { toAbsoluteUrl } from "../../../../_helpers";

export function AsideMenuSuperList({ layoutProps, menu }) {
  const [groupActive, groupActiveSet] = useState({ name: "", isOpen: false });

  const menuProject = ["project", "article", "distribution", "release"];
  const menuUser = ["verified", "request", "manager", "company"];
  const menuAdmin = ["product", "info", "coupon", "ticket", "release"];
  const menuFinance = ["invoice", "payment", "payout"];

  useEffect(() => {
    if (menuProject.includes(menu)) {
      groupActiveSet({ name: "projects", isOpen: true });
    } else if (menuUser.includes(menu)) {
      groupActiveSet({ name: "users", isOpen: true });
    } else if (menuAdmin.includes(menu)) {
      groupActiveSet({ name: "admins", isOpen: true });
    } else if (menuFinance.includes(menu)) {
      groupActiveSet({ name: "finances", isOpen: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toogleMenu = (data) => {
    if (groupActive.name === data && groupActive.isOpen) {
      groupActiveSet({ ...groupActive, isOpen: false });
    } else if (groupActive.name === data && !groupActive.isOpen) {
      groupActiveSet({ ...groupActive, isOpen: true });
    } else if (groupActive.name !== data && groupActive.isOpen) {
      groupActiveSet({ name: data, isOpen: true });
    } else if (groupActive.name !== data && !groupActive.isOpen) {
      groupActiveSet({ name: data, isOpen: true });
    }
  };

  return (
    <>
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li
          className={`menu-item-submenu menu-item ${groupActive ===
            "projects" && "menu-item-active menu-item-open"}`}
          aria-haspopup="true"
        >
          <a
            className={`menu-link menu-toggle ${groupActive?.isOpen &&
              groupActive?.name === "projects" &&
              "active"} `}
            onClick={() => toogleMenu("projects")}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-f-invoice.svg")}
              />
            </span>
            <span className="menu-text">Menu Project & Artikel</span>
            <span
              className={`menu-${
                groupActive?.isOpen && groupActive?.name === "projects"
                  ? "hide"
                  : "show"
              }`}
            >
              <SVG
                src={
                  groupActive?.isOpen && groupActive?.name === "projects"
                    ? MinusIcon
                    : PlusIcon
                }
              />
            </span>
          </a>
          <div
            className={`menu-submenu ${
              groupActive?.isOpen && groupActive?.name === "projects"
                ? "d-block"
                : "d-none"
            } `}
          >
            <span className="menu-arrow"></span>
            <ul className="menu-subnav">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "project" ? "menu-item-active" : ""
                } `}
              >
                <Link to="/admin/pm/list-project" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-invoice.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Project</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "article" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-articles" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-edit.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Tulisan</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "distribution" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-release" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-distribution.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Media Distribusi</span>
                </Link>
              </li>
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "release" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/brief-release-client" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/icon_pencil.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Brief Rilis Klien</span>
                </Link>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item-submenu menu-item ${groupActive === "users" &&
            "menu-item-active menu-item-open"}`}
          aria-haspopup="true"
        >
          <a
            className={`menu-link menu-toggle ${groupActive?.isOpen &&
              groupActive?.name === "users" &&
              "active"} `}
            onClick={() => toogleMenu("users")}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/General/ic-profile.svg")}
              />
            </span>
            <span className="menu-text">Menu User</span>
            <span
              className={`menu-${
                groupActive?.isOpen && groupActive?.name === "users"
                  ? "hide"
                  : "show"
              }`}
            >
              <SVG
                src={
                  groupActive?.isOpen && groupActive?.name === "users"
                    ? MinusIcon
                    : PlusIcon
                }
              />
            </span>
          </a>
          <div
            className={`menu-submenu ${
              groupActive?.isOpen && groupActive?.name === "users"
                ? "d-block"
                : "d-none"
            } `}
          >
            <span className="menu-arrow"></span>
            <ul className="menu-subnav">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "verified" ? "menu-item-active" : ""
                } `}
              >
                <Link to="/admin/pm/list-user/verified" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-profile.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Penulis Terverifikasi</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "request" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-user/applicants" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-profile.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Penulis Pemohon</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "manager" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-pm" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-shield.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Project Manajer</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "company" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/company-list" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-graph.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Klien</span>
                </Link>
              </li>
            </ul>
          </div>
        </li>

        <li
          className={`menu-item-submenu menu-item ${groupActive === "admins" &&
            "menu-item-active menu-item-open"}`}
          aria-haspopup="true"
        >
          <a
            className={`menu-link menu-toggle ${groupActive?.isOpen &&
              groupActive?.name === "admins" &&
              "active"} `}
            onClick={() => toogleMenu("admins")}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Files/folder_icon.svg")}
              />
            </span>
            <span className="menu-text">Menu Admin</span>
            <span
              className={`menu-${
                groupActive?.isOpen && groupActive?.name === "admins"
                  ? "hide"
                  : "show"
              }`}
            >
              <SVG
                src={
                  groupActive?.isOpen && groupActive?.name === "admins"
                    ? MinusIcon
                    : PlusIcon
                }
              />
            </span>
          </a>
          <div
            className={`menu-submenu ${
              groupActive?.isOpen && groupActive?.name === "admins"
                ? "d-block"
                : "d-none"
            } `}
          >
            <span className="menu-arrow"></span>
            <ul className="menu-subnav">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "product" ? "menu-item-active" : ""
                } `}
              >
                <Link to="/admin/pm/list-product" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-paper.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Produk</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "info" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-information" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/icon_pencil.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Informasi</span>
                </Link>
              </li>
              {/* Hidden until next confirmation */}
              {/* <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "ticket" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-ticket" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ticket_icon.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Bantuan</span>
                </Link>
              </li>
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "article" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-articles" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/bookmark_icon.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Kupon</span>
                </Link>
              </li>
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "article" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-articles" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/swap_icon.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Rilis</span>
                </Link>
              </li> */}
            </ul>
          </div>
        </li>

        <li
          className={`menu-item-submenu menu-item ${groupActive ===
            "finances" && "menu-item-active menu-item-open"}`}
          aria-haspopup="true"
        >
          <a
            className={`menu-link menu-toggle ${groupActive?.isOpen &&
              groupActive?.name === "finances" &&
              "active"} `}
            onClick={() => toogleMenu("finances")}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl("/media/svg/icons/Files/finance_icon.svg")}
              />
            </span>
            <span className="menu-text">Menu Finance</span>
            <span
              className={`menu-${
                groupActive?.isOpen && groupActive?.name === "finances"
                  ? "hide"
                  : "show"
              }`}
            >
              <SVG
                src={
                  groupActive?.isOpen && groupActive?.name === "finances"
                    ? MinusIcon
                    : PlusIcon
                }
              />
            </span>
          </a>
          <div
            className={`menu-submenu ${
              groupActive?.isOpen && groupActive?.name === "finances"
                ? "d-block"
                : "d-none"
            } `}
          >
            <span className="menu-arrow"></span>
            <ul className="menu-subnav">
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "invoice" ? "menu-item-active" : ""
                } `}
              >
                <Link to="/admin/pm/list-invoice" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-invoice.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Invoice</span>
                </Link>
              </li>

              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "payment" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-payment" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-payment.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Manajemen Pembayaran</span>
                </Link>
              </li>
              <li
                aria-haspopup="true"
                data-menu-toggle="hover"
                className={`menu-item ${
                  menu === "payout" ? "menu-item-active" : ""
                }`}
              >
                <Link to="/admin/pm/list-request-payout" className="menu-link">
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/ic-f-request.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Request Payout</span>
                </Link>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </>
  );
}
