/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import TextBody from "../../../components/datatableBody/TextBody";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import AdminPaginate from "../../../components/paging/pagination";
import DialogAlert from "../../../components/dialog/DialogAlert";

import {
  getUsersAction,
  resetStateUserAction,
} from "../../../../redux/action/userAction";

const AssignPMModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogSuccess, setDialogSuccess] = useState(false);

  const {
    userParams,
    successReInviteUser,

    successDeleteUser,
  } = useSelector((state) => state.user);

  const { dataGetClient, loadingGetClient, totalPage } = useSelector(
    (state) => state.client
  );

  useEffect(() => {
    if (successReInviteUser) {
      setDialogSuccess(true);
    }
  }, [successReInviteUser]);

  useEffect(() => {
    if (successDeleteUser) {
      dispatch(getUsersAction(userParams));
      dispatch(resetStateUserAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDeleteUser]);

  const nameBodyTemplate = (item) => {
    return (
      <div onClick={() => history.push("/admin/pm/company-detail")}>
        <TextBody text={item.name} />
      </div>
    );
  };

  const amountBodyTemplate = (item) => {
    return <TextBody text={item?.amount} />;
  };

  const actionBodyTemplate = (item) => {
    return (
      <div className="p-d-flex p-jc-end">
        <Button className="p-button-primary btn-wide" label="ASSIGN" />
      </div>
    );
  };

  const closeSuccess = () => {
    dispatch(resetStateUserAction());
    setDialogSuccess(false);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search,
      page: page + 1,
      perpage: 10,
    };
    history.push(
      `/admin/pm/company-list?search=${parsed.search}&page=${page + 1}`
    );
    dispatch(getUsersAction(params));
  };

  const dataPM = [
    { name: "Retno Marsudi", amount: 2 },
    { name: "Sudi Silalahi", amount: 3 },
    { name: "Herjunot Ali", amount: 1 },
  ];

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              <span className="icon-back mr-2"></span>List PM
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          {loadingGetClient ? (
            <TableLoader />
          ) : (
            <>
              {dataGetClient?.length < 1 || dataGetClient === null ? (
                <DataEmpty />
              ) : (
                <>
                  <DataTable value={dataPM} className="no-padding admin">
                    <Column header="Nama PM" body={nameBodyTemplate}></Column>
                    <Column
                      header="Jumlah Tanggungan Project"
                      body={amountBodyTemplate}
                    ></Column>
                    <Column
                      headerClassName="action-button"
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Kirim Ulang"}
        text={"Tautan berhasil dikirim ke email user"}
      />
    </>
  );
};

export default AssignPMModule;
