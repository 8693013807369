import api from "./api";

export const getCompanies = async (params) => {
  return api.get("companies", params, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const updateCompany = async (token, id, data) => {
  return api.put(`/companies/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createClient = async (token, data) => {
  return api.post("/pm/clients", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateClient = async (token, id, data) => {
  return api.put(`/pm/clients/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getClient = async (token, params) => {
  return api.get("/pm/clients", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteClient = async (token, id) => {
  return api.delete(
    `/pm/clients/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
