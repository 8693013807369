/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";

import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../../helpers/phoneNumber";
import { formEmail } from "../../../../helpers/email";

import DialogAlert from "../../../../components/dialog/DialogAlert";

import { uploadBriefAction } from "../../../../../redux/action/utilsAction";
import {
  postClientAction,
  resetClientStateAction,
} from "../../../../../redux/action/clientAction";
import ButtonDefault from "../../../../components/button/ButtonDefault";

const AddCompanyModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [business, setBusiness] = useState([]);
  const [dialogAlert, setDialogAlert] = useState({
    type: "",
    dialog: false,
    title: "",
    text: "",
  });
  const [emailState, setEmailState] = useState({ value: "", error: null });
  const [phoneState, setPhoneState] = useState({ value: "", error: null });
  const [nameState, setNameState] = useState({ value: "", error: null });
  const [positionState, setPositionState] = useState({
    value: "",
    error: null,
  });
  const [companyState, setCompanyState] = useState({ value: "", error: null });
  const [companyPhoneState, setCompanyPhoneState] = useState({
    value: "",
    error: null,
  });
  const [companyTypeState, setCompanyTypeState] = useState({
    value: "",
    error: null,
  });
  const [companyAddressState, setCompanyAddressState] = useState({
    value: "",
    error: null,
  });
  const [companyDetailState, setCompanyDetailState] = useState({
    value: "",
    error: null,
  });
  const [companyEmailState, setCompanyEmailState] = useState({
    value: "",
    error: null,
  });
  const [companyFileState, setCompanyFileState] = useState({
    value: null,
    error: null,
  });
  const [fileName, setFileName] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const { users } = useSelector((state) => state.auth);
  const { loadingPostClient, successPostClient, errPostClient } = useSelector(
    (state) => state.client
  );
  const {
    successUploadBrief,
    loadingUploadBrief,
    dataUploadBrief,
    dataBusiness,
  } = useSelector((state) => state.utils);

  useEffect(() => {
    if (dataBusiness) {
      setBusiness(dataBusiness);
    }
  }, [dataBusiness]);

  useEffect(() => {
    if (successPostClient) {
      setTimeout(() => {
        setIsLoading(false);
        setDialogAlert({
          type: "success",
          dialog: true,
          title: "Berhasil Tambah Data",
          text: "Data perusahaan berhasil ditambahkan",
        });
      }, 750);
    }
  });

  useEffect(() => {
    if (errPostClient) {
      setTimeout(() => {
        setIsLoading(false);
        setDialogAlert({
          type: "error",
          dialog: true,
          title: "Gagal Tambah Data",
          text: "Email atau Nomor akun sudah didaftarkan",
        });
      }, 750);
    }
  });

  useEffect(() => {
    if (successUploadBrief) {
      setCompanyFileState({
        value: dataUploadBrief,
        error: null,
      });
    }
  }, [dataUploadBrief, successUploadBrief]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".pdf",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        setCompanyFileState({
          ...companyFileState,
          error: "Ukuran file lebih dari 5MB",
        });
      } else if (acceptedFiles.length > 1) {
        setCompanyFileState({
          ...companyFileState,
          error: "Silakan pilih satu file",
        });
      } else {
        setCompanyFileState({ ...companyFileState, error: null });
        setFileName(acceptedFiles[0].name);

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/user-data/${times}/${users?.uid}/${fileName}`;

        dispatch(uploadBriefAction(acceptedFiles[0], path));
      }
    },
  });

  const submitData = () => {
    const data = {
      name: nameState.value,
      email: emailState.value,
      phone: formattedPhoneNumber(phoneState.value),
      companyAddress: companyAddressState.value,
      companyPhone: formattedPhoneNumber(companyPhoneState.value),
      businessField: companyTypeState.value,
      companyName: companyState.value,
      companyBackground: companyDetailState.value,
      position: positionState.value,
      companyPortofolio: companyFileState.value,
      companyEmail: companyEmailState.value,
    };

    if (!formEmail(emailState.value)) {
      setEmailState({ ...emailState, error: "Email tidak Valid" });
    }
    if (!formPhoneNumber(phoneState.value)) {
      setPhoneState({ ...phoneState, error: "Nomor telepon tidak Valid" });
    }
    if (emailState?.value?.length < 1) {
      setEmailState({ ...emailState, error: "Email harus diisi" });
    }
    if (phoneState?.value?.length < 1) {
      setPhoneState({ ...phoneState, error: "Nomor telepon harus diisi" });
    }
    if (nameState?.value?.length < 1) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    }
    if (positionState?.value?.length < 1) {
      setPositionState({ ...positionState, error: "Jabatan harus diisi" });
    }
    if (companyState?.value?.length < 1) {
      setCompanyState({
        ...companyState,
        error: "Nama perusahaan / brand harus diisi",
      });
    }
    if (companyAddressState?.value?.length < 1) {
      setCompanyAddressState({
        ...companyAddressState,
        error: "Alamat perusahaan / brand harus diisi",
      });
    }
    if (companyTypeState?.value?.length < 1) {
      setCompanyTypeState({
        ...companyTypeState,
        error: "Bidang perusahaan / brand harus diisi",
      });
    }
    if (companyDetailState?.value?.length < 1) {
      setCompanyDetailState({
        ...companyDetailState,
        error: "Deskripsi perusahaan / brand harus diisi",
      });
    }
    if (companyPhoneState?.value?.length < 1) {
      setCompanyPhoneState({
        ...companyPhoneState,
        error: "Nomor Telepon perusahaan / brand harus diisi",
      });
    }
    if (!formPhoneNumber(companyPhoneState.value)) {
      setCompanyPhoneState({
        ...companyPhoneState,
        error: "Nomor telepon perusahaan / brand tidak Valid",
      });
    }
    if (companyEmailState?.value?.length < 1) {
      setCompanyEmailState({
        ...companyEmailState,
        error: "Email perusahaan / brand harus diisi",
      });
    }
    if (!formEmail(companyEmailState.value)) {
      setCompanyEmailState({
        ...companyEmailState,
        error: "Email perusahaan / brand tidak Valid",
      });
    }

    if (
      nameState?.value?.length > 0 &&
      emailState?.value?.length > 0 &&
      formEmail(emailState?.value) &&
      phoneState?.value?.length > 0 &&
      formPhoneNumber(phoneState?.value) &&
      positionState?.value?.length > 0 &&
      companyState?.value?.length > 0 &&
      companyAddressState?.value?.length > 0 &&
      companyTypeState?.value?.length > 0 &&
      companyDetailState?.value?.length > 0 &&
      companyPhoneState?.value?.length > 0 &&
      formPhoneNumber(companyPhoneState?.value) &&
      companyEmailState?.value?.length > 0 &&
      formEmail(companyEmailState?.value)
    ) {
      dispatch(postClientAction(data));
      setIsLoading(true);
    }
  };

  const closeSuccess = () => {
    setTimeout(() => {
      dispatch(resetClientStateAction());
      setDialogAlert({
        type: "",
        dialog: false,
        title: "",
        text: "",
      });
      history.push("/admin/pm/company-list");
    }, 750);
  };

  return (
    <>
      <div className="p-d-flex p-jc-center">
        <div className="card card-custom mb-4 col-8">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Tambah Perusahaan
                </span>
              </button>
            </h3>
          </div>
          <div className="card-body pt-0 pb-4 p-d-flex p-jc-center">
            <div className="form-detail-company col-10">
              <div className="form-group-header">
                <h4>DATA AKUN</h4>
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  Nama Lengkap <span className="text-danger">*</span>
                </label>
                <InputText
                  type="text"
                  className={`p-d-block ${nameState.error && "p-invalid"}`}
                  placeholder="Masukkan Nama Lengkap"
                  value={nameState.value}
                  onChange={(e) =>
                    setNameState({ value: e.target.value, error: null })
                  }
                />
                {nameState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {nameState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  Jabatan / Posisi <span className="text-danger">*</span>
                </label>
                <InputText
                  type="text"
                  className={`p-d-block ${positionState.error && "p-invalid"}`}
                  placeholder="Masukkan Jabatan / Posisi"
                  value={positionState.value}
                  onChange={(e) =>
                    setPositionState({ value: e.target.value, error: null })
                  }
                />
                {positionState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {positionState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  Email <span className="text-danger">*</span>
                </label>
                <InputText
                  type="email"
                  className={`p-d-block ${emailState.error && "p-invalid"}`}
                  placeholder="Masukkan Email"
                  value={emailState.value}
                  onChange={(e) =>
                    setEmailState({ value: e.target.value, error: null })
                  }
                />
                {emailState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {emailState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  No. Telepon <span className="text-danger">*</span>
                </label>
                <InputText
                  type="text"
                  className={`p-d-block ${phoneState.error && "p-invalid"}`}
                  placeholder="Masukkan No. Telepon"
                  value={phoneState.value}
                  onChange={(e) =>
                    setPhoneState({ value: e.target.value, error: null })
                  }
                />
                {phoneState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {phoneState.error}
                  </small>
                )}
              </div>
              <div className="form-group-header m-t-50">
                <h4>DATA PERUSAHAAN / BRAND</h4>
              </div>

              <div className="p-field">
                <label className="p-d-block">
                  Nama Perusahaan / Brand <span className="text-danger">*</span>
                </label>
                <InputText
                  type="text"
                  className={`p-d-block ${companyState.error && "p-invalid"}`}
                  placeholder="Masukkan Nama Perusahaan / Brand"
                  value={companyState.value}
                  onChange={(e) =>
                    setCompanyState({ value: e.target.value, error: null })
                  }
                />
                {companyState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {companyState.error}
                  </small>
                )}
              </div>

              <div className="p-field">
                <label className="p-d-block">
                  Email Perusahaan / Brand{" "}
                  <span className="text-danger">*</span>
                </label>
                <InputText
                  type="email"
                  className={`p-d-block ${companyEmailState.error &&
                    "p-invalid"}`}
                  placeholder="Masukkan Email Perusahaan / Brand"
                  value={companyEmailState.value}
                  onChange={(e) =>
                    setCompanyEmailState({ value: e.target.value, error: null })
                  }
                />
                {companyEmailState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {companyEmailState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  No. Telepon Perusahaan / Brand{" "}
                  <span className="text-danger">*</span>
                </label>
                <InputText
                  type="text"
                  className={`p-d-block ${companyPhoneState.error &&
                    "p-invalid"}`}
                  placeholder="Masukkan No. Telepon Perusahaan / Brand"
                  value={companyPhoneState.value}
                  onChange={(e) =>
                    setCompanyPhoneState({ value: e.target.value, error: null })
                  }
                />
                {companyPhoneState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {companyPhoneState.error}
                  </small>
                )}
              </div>
              <div className="p-field dropdown-full">
                <label className="p-d-block">
                  {" "}
                  Bidang Perusahaan / Brand{" "}
                  <span className="text-danger">*</span>
                </label>
                <Dropdown
                  value={companyTypeState.value}
                  onChange={(e) =>
                    setCompanyTypeState({ value: e.target.value, error: null })
                  }
                  options={business}
                  optionLabel="name"
                  optionValue="code"
                />
                {companyTypeState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {companyTypeState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  Detail Perusahaan / Brand{" "}
                  <span className="text-danger">*</span>
                </label>
                <InputTextarea
                  rows={5}
                  cols={30}
                  className={`p-d-block ${companyDetailState.error &&
                    "p-invalid"}`}
                  placeholder="Masukkan Detail Perusahaan / Brand"
                  value={companyDetailState.value}
                  onChange={(e) =>
                    setCompanyDetailState({
                      value: e.target.value,
                      error: null,
                    })
                  }
                />
                {companyDetailState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {companyDetailState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  Alamat Perusahaan / Brand{" "}
                  <span className="text-danger">*</span>
                </label>
                <InputTextarea
                  rows={5}
                  cols={30}
                  className={`p-d-block ${companyAddressState.error &&
                    "p-invalid"}`}
                  placeholder="Masukkan Alamat Perusahaan / Brand"
                  value={companyAddressState.value}
                  onChange={(e) =>
                    setCompanyAddressState({
                      value: e.target.value,
                      error: null,
                    })
                  }
                />
                {companyAddressState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {companyAddressState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  Unggah Profil Perusahaan (Opsional)
                </label>
                <div {...getRootProps({ className: "file-upload" })}>
                  <input {...getInputProps()} />
                  <span className="file-name">
                    {companyFileState.value !== null ? fileName : "Unggah File"}
                  </span>
                  {loadingUploadBrief ? (
                    <span className="file-button">Mengupload...</span>
                  ) : (
                    <span className="file-button" onClick={open}>
                      {companyFileState.value !== null
                        ? "Ubah File"
                        : "Pilih File"}
                    </span>
                  )}
                </div>
                {companyFileState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {companyFileState.error}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="company footer-form">
            <div className="form-add-project col-10 p-d-flex p-jc-end p-ai-center">
              <ButtonDefault
                label="BATAL"
                onClick={() => history.goBack()}
                disabled={isLoading || loadingPostClient}
                type="bordered"
                className="p-button p-button-secondary"
              />
              <ButtonDefault
                onClick={() => submitData()}
                label="SIMPAN"
                className="ml-4 p-button p-button-primary"
                loading={isLoading || loadingPostClient}
              />
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type={dialogAlert?.type}
        visible={dialogAlert?.dialog}
        closeDialog={() => closeSuccess()}
        title={dialogAlert?.title}
        text={dialogAlert?.text}
      />
    </>
  );
};

export default AddCompanyModule;
