/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import ProfileEditPortfolioModule from "../../modules/User/Profile/ProfileEditPortfolio";

const ProfileEditPortfolio = (props) => {
  return (
    <DashboardUserLayout>
      <div className="p-d-flex p-justify-center p-mt-5 profile-module">
        <div className="p-grid p-justify-center">
          <ProfileEditPortfolioModule />
        </div>
      </div>
    </DashboardUserLayout>
  );
};

export default ProfileEditPortfolio;
