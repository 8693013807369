import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import EditUserModule from "../../../modules/Admin/UserPM/EditUserModule";
import DashboardLayout from "../../../layouts/dashboardLayout";

import { getProvincesAction } from "../../../../redux/action/utilsAction";
import { detailUserAction } from "../../../../redux/action/userAction";
import { titlePage } from "../../../helpers/titleHead";

const EditUser = () => {
  const dispatch = useDispatch();
  const QueryParam = useParams();
  useEffect(() => {
    dispatch(getProvincesAction());
    dispatch(detailUserAction(QueryParam.userId));

    titlePage({
      title: "IRIS - Edit Penulis",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardLayout title="Manajemen Penulis">
      <EditUserModule userId={QueryParam.userId} />
    </DashboardLayout>
  );
};

export default EditUser;
