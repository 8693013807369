import React from "react";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import SettingModule from "../../modules/Client/Settings/SettingsModule";

const SettingPage=()=>{
    return(
        <div className="setting-page">
            <DashboardClientLayout title="Pengaturan">
                <SettingModule />
            </DashboardClientLayout>
        </div>
  
    )
}

export default SettingPage;