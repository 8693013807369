import React from "react";
import { useSelector } from "react-redux";
import NumberFormat from "react-number-format";
import { Link } from "react-router-dom";

import IsoRocket from "../../../assets/img/iso-rocket.svg";
import { isDefaultPhoto } from "../../../helpers/profileHelper";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { TableLoad } from "../../../components/loading/loadingContent";
import NumberBody from "../../../components/datatableBody/NumberBody";
import TextBody from "../../../components/datatableBody/TextBody";
import KpiBody from "../../../components/datatableBody/KpiBody";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import StatusBadge from "../../../components/badge/StatusBadge";
import DateBody from "../../../components/datatableBody/DateBody";

const DasboardClientModule = () => {
  const { users } = useSelector((state) => state.auth);
  const { dataClientProjectOverview } = useSelector((state) => state.project);
  const { dataGetProjectClient, loadingGetProjectClient } = useSelector(
    (state) => state.order
  );
  const { dataWinnerRelease, loadingWinnerRelease } = useSelector(
    (state) => state.release
  );
  const isClient = users?.user?.type === "client";

  const avatarLabel = users?.user?.name
    ? users?.user?.name.split("")[0]
    : users?.user?.email.split("")[0];

  // Table Project
  const projectTitleBody = (item) => {
    return <TextBody text={`${item?.title}`} />;
  };
  const statusBody = (item) => {
    return <StatusBadge status={item?.status} />;
  };
  const deadlineBody = (item) => {
    return <DateBody text={`${item?.deadlineDate}`} />;
  };
  const actionRow = (data) => {
    return (
      <div className="action-table">
        <div className="action-table">
          <Link
            to={`/client/order-detail/confirm/${data._id}`}
            className="link"
          >
            Lihat Project
          </Link>
        </div>
      </div>
    );
  };

  // Table Release
  const kpiBody = (item) => {
    return <KpiBody number={item?.output || 0} />;
  };
  const prValueBody = (item) => {
    return <NumberBody number={item?.prValue || 0} isPrice />;
  };
  const titleBody = (item) => {
    return <TextBody text={`${item?.title}`} />;
  };
  const actionBody = (item) => {
    return (
      <div className="action-table">
        <Link to={`/client/detail-release/${item._id}`} className="link">
          Lihat Release
        </Link>
      </div>
    );
  };

  return (
    <>
      <div className="p-grid">
        <div className="p-col-12 p-md-4">
          <div className="box-content client-dashboard">
            <div className="writer-name">
              <div className="pict">
                {isDefaultPhoto(users?.user?.photoURL) ||
                !users?.user?.photoURL ? (
                  <div className="letter-pics">{avatarLabel}</div>
                ) : (
                  <img src={users?.user?.photoURL} alt="profile" />
                )}
              </div>
              <div className="writer-text-content">
                <div className="name">{users?.user?.name}</div>
                <p>{users?.user?.position}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="p-col-6 p-md-3">
          <div className="box-content client-dashboard">
            <div className="project-active column">
              <p className="dashboard-label">Total Project</p>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
                value={dataClientProjectOverview?.totalProject || 0}
                renderText={(value) => <h1> {value} </h1>}
              />
            </div>
          </div>
        </div>
        <div className="p-col-6 p-md-3">
          <div className="box-content client-dashboard">
            <div className="project-active column">
              <p className="dashboard-label">Project Aktif</p>
              <NumberFormat
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
                value={dataClientProjectOverview?.ongoingProject || 0}
                renderText={(value) => <h1> {value} </h1>}
              />
            </div>
          </div>
        </div>
      </div>

      {!loadingGetProjectClient && dataGetProjectClient?.length < 1 ? (
        <div className="p-grid">
          <div className="p-col-12">
            <div className="box-content action p-px-4 p-py-3">
              <div className="call-action">
                <div className="pict">
                  <img src={IsoRocket} alt="action" />
                </div>
                <div className="desc">
                  <h3>Anda Belum Memiliki Project</h3>
                  <p>
                    Pilih paket layanan produk untuk scope tulisan dengan harga
                    terbaik. Anda dapat memilih layanan Press Release yang Anda
                    butuhkan. Tim IRIS siap memonitor dan mengevaluasi konten
                    sesuai industri bisnis Anda.
                  </p>
                  {isClient && (
                    <div className="d-flex">
                      <div className="button-action">
                        <Link
                          to="/client/order"
                          className="p-button p-button-rounded p-mt-4 p-button-primary"
                        >
                          PESAN SEKARANG
                        </Link>
                      </div>
                      <div className="button-action ml-5">
                        <Link
                          to="/client/order"
                          className="p-button p-button-rounded p-mt-4 p-button-outlined"
                        >
                          KIRIM BRIEF
                        </Link>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="p-grid">
            <div className="p-col-12">
              <div className="card card-dashboard mt-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2 className="card-title-text">Project Terbaru</h2>
                  </div>
                </div>
                <div className="card-body">
                  {loadingGetProjectClient ? (
                    <TableLoad />
                  ) : (
                    <div className="table-content-wrapp">
                      {dataGetProjectClient?.length < 1 ||
                      dataGetProjectClient === null ? (
                        <DataEmpty />
                      ) : (
                        <DataTable
                          value={dataGetProjectClient}
                          className="table-release"
                        >
                          <Column
                            headerClassName="release-title"
                            header="Judul Project"
                            body={projectTitleBody}
                          />
                          <Column header="Status" body={statusBody} />
                          <Column header="Deadline" body={deadlineBody} />
                          <Column body={actionRow} />
                        </DataTable>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="p-grid full-width">
            <div className="p-col-12">
              <div className="card card-dashboard mt-4">
                <div className="card-header">
                  <div className="card-title">
                    <h2 className="card-title-text">News Coverage Terbaru</h2>
                  </div>
                </div>
                <div className="card-body">
                  {loadingWinnerRelease ? (
                    <TableLoad />
                  ) : (
                    <div className="table-content-wrapp">
                      {dataWinnerRelease?.length < 1 ||
                      dataWinnerRelease === null ? (
                        <DataEmpty />
                      ) : (
                        <DataTable
                          value={dataWinnerRelease}
                          className="table-release"
                        >
                          <Column
                            headerClassName="release-title"
                            header="Judul Release"
                            body={titleBody}
                          />
                          <Column header="PR Value" body={prValueBody} />
                          <Column header="Output Media" body={kpiBody} />
                          <Column body={actionBody} />
                        </DataTable>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DasboardClientModule;
