export const GET_BANNER_REQUEST = "GET_BANNER_REQUEST";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAILURE = "GET_BANNER_FAILURE";

export const GET_HOW_DOES_IT_WORK_REQUEST = "GET_HOW_DOES_IT_WORK_REQUEST";
export const GET_HOW_DOES_IT_WORK_SUCCESS = "GET_HOW_DOES_IT_WORK_SUCCESS";
export const GET_HOW_DOES_IT_WORK_FAILURE = "GET_HOW_DOES_IT_WORK_FAILURE";

export const GET_BENEFIT_REQUEST = "GET_BENEFIT_REQUEST";
export const GET_BENEFIT_SUCCESS = "GET_BENEFIT_SUCCESS";
export const GET_BENEFIT_FAILURE = "GET_BENEFIT_FAILURE";

export const GET_TESTIMONIAL_REQUEST = "GET_TESTIMONIAL_REQUEST";
export const GET_TESTIMONIAL_SUCCESS = "GET_TESTIMONIAL_SUCCESS";
export const GET_TESTIMONIAL_FAILURE = "GET_TESTIMONIAL_FAILURE";

export const GET_SETTING_SITE_REQUEST = "GET_SETTING_SITE_REQUEST";
export const GET_SETTING_SITE_SUCCESS = "GET_SETTING_SITE_SUCCESS";
export const GET_SETTING_SITE_FAILURE = "GET_SETTING_SITE_FAILURE";

export const GET_TOP_WRITER_REQUEST = "GET_TOP_WRITER_REQUEST";
export const GET_TOP_WRITER_SUCCESS = "GET_TOP_WRITER_SUCCESS";
export const GET_TOP_WRITER_FAILURE = "GET_TOP_WRITER_FAILURE";

export const GET_ARTICLES_REQUEST = "GET_ARTICLES_REQUEST";
export const GET_ARTICLES_SUCCESS = "GET_ARTICLES_SUCCESS";
export const GET_ARTICLES_FAILURE = "GET_ARTICLES_FAILURE";

export const GET_DETAIL_ARTICLE_REQUEST = "GET_DETAIL_ARTICLE_REQUEST";
export const GET_DETAIL_ARTICLE_SUCCESS = "GET_DETAIL_ARTICLE_SUCCESS";
export const GET_DETAIL_ARTICLE_FAILURE = "GET_DETAIL_ARTICLE_FAILURE";

export const GET_FOOTER_REQUEST = "GET_FOOTER_REQUEST";
export const GET_FOOTER_SUCCESS = "GET_FOOTER_SUCCESS";
export const GET_FOOTER_FAILURE = "GET_FOOTER_FAILURE";

export const GET_ABOUT_REQUEST = "GET_ABOUT_REQUEST";
export const GET_ABOUT_SUCCESS = "GET_ABOUT_SUCCESS";
export const GET_ABOUT_FAILURE = "GET_ABOUT_FAILURE";

export const GET_TERM_REQUEST = "GET_TERM_REQUEST";
export const GET_TERM_SUCCESS = "GET_TERM_SUCCESS";
export const GET_TERM_FAILURE = "GET_TERM_FAILURE";

export const GET_PRIVACY_REQUEST = "GET_PRIVACY_REQUEST";
export const GET_PRIVACY_SUCCESS = "GET_PRIVACY_SUCCESS";
export const GET_PRIVACY_FAILURE = "GET_PRIVACY_FAILURE";

export const GET_FAQ_REQUEST = "GET_FAQ_REQUEST";
export const GET_FAQ_SUCCESS = "GET_FAQ_SUCCESS";
export const GET_FAQ_FAILURE = "GET_FAQ_FAILURE";

export const TYPE_PRODUCT_REQUEST = "TYPE_PRODUCT_REQUEST";
export const TYPE_PRODUCT_SUCCESS = "TYPE_PRODUCT_SUCCESS";
export const TYPE_PRODUCT_FAILURE = "TYPE_PRODUCT_FAILURE";

export const GET_STATIC_PAGES_REQUEST = "GET_STATIC_PAGES_REQUEST";
export const GET_STATIC_PAGES_SUCCESS = "GET_STATIC_PAGES_SUCCESS";
export const GET_STATIC_PAGES_FAILURE = "GET_STATIC_PAGES_FAILURE";
