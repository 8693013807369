import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import ListUserInvitedModule from "../../../modules/Admin/ProjectPM/ListUserInvitedModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import {
  getDetailProjectAction,
  getProjectUserAction,
  userInvitableAction,
} from "../../../../redux/action/projectAction";

const ListUserInvited = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();

  const { userInvitableParams, successUpdateStatusProject } = useSelector(
    (state) => state.project
  );

  useEffect(() => {
    dispatch(getDetailProjectAction(QueryParams.projectId));
    dispatch(getProjectUserAction(QueryParams.projectId));
    dispatch(userInvitableAction(QueryParams.projectId, userInvitableParams));

    titlePage({
      title: "IRIS - Daftar Penulis",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (successUpdateStatusProject) {
      dispatch(getProjectUserAction(QueryParams.projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateStatusProject]);

  return (
    <DashboardLayout title="Manajemen Project">
      <ListUserInvitedModule projectId={QueryParams.projectId} />
    </DashboardLayout>
  );
};

export default ListUserInvited;
