/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";

import { TabView, TabPanel } from "primereact/tabview";

import iconDownload from "../../../../assets/img/download-blue.svg";
import CoinImage from "../../../../assets/img/icon-coin.svg";
// import UsersImage from "../../../../assets/img/icon-users.svg";
import ClockImage from "../../../../assets/img/icon-clock.svg";
import WinnerImage from "../../../../assets/img/winner.svg";

import DialogAlert from "../../../../components/dialog/DialogAlert";

import {
  TitleLoad,
  DetailPageLoad,
} from "../../../../components/loading/loadingContent";
import StatusBadge from "../../../../components/badge/StatusBadge";

const DetailBriefProjectModule = (props) => {
  const QueryParams = useParams();
  const history = useHistory();

  const [openNotice, setOpenNotice] = useState(false);


  const {
    loadingGetProjectDetailPm,
    dataGetProjectDetailPm
  } = useSelector((state) => state.project);

  const { users } = useSelector((state) => state.auth);
  const isAssigned =
    users?.user?.type === "pm" || dataGetProjectDetailPm?.pmId === users?.uid;
  const pdfRef = createRef();

  console.log(dataGetProjectDetailPm)

  useEffect(() => {
    if (dataGetProjectDetailPm && !dataGetProjectDetailPm?.price && isAssigned) {
      setOpenNotice(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetProjectDetailPm, dataGetProjectDetailPm?.price]);


  const openEditPage = () => {
    setOpenNotice(false);
    history.push(`/admin/pm/edit-project/${QueryParams?.projectId}`);
  };

  const closeDialogBrief = () => {
    setOpenNotice(false);
    history.goBack();
  };

  let categoryName;
  if (dataGetProjectDetailPm?.type === "pr") {
    categoryName = "Press Release";
  } else if (dataGetProjectDetailPm?.type === "distribution_media") {
    categoryName = "Media Distribusi";
  } else {
    categoryName = "Artikel";
  }

  const keywords = dataGetProjectDetailPm?.searchKeyword?.split(",");

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-header">
          {loadingGetProjectDetailPm ? (
            <TitleLoad />
          ) : (
            <>
              <div className="card-title">
                <div
                  className="card-title-back"
                  onClick={() => history.goBack()}
                >
                  <span className="icon-back"></span>
                </div>
                <h2 className="card-title-text">Detail Brief</h2>
              </div>
              {/* <div className="card-header-right">
                {!isAssigned && <StatusBadge status="only_view" />}

                {dataGetProjectDetailPm?.status !== "done" && isAssigned && (
                  <LinkEdit
                    label="edit"
                    link={`/admin/pm/edit-project/${QueryParams?.projectId}`}
                    className="ml-2"
                  />
                )}
              </div> */}
            </>
          )}
        </div>
        <div className="card-body px-0">
          {loadingGetProjectDetailPm ? (
            <div className="project-info-wrapp">
              <DetailPageLoad />
            </div>
          ) : (
            <>
              <div className="project-detail-top">
                <div className="project-info-wrapp">
                  <div className="project-image">
                    <img src={dataGetProjectDetailPm?.thumbnail} alt="tumbnail" />
                  </div>
                  <div className="project-info">
                    <div className="info-row-title">
                      <h3>{dataGetProjectDetailPm?.title}</h3>
                      <div className="d-flex align-items-center">
                        <h6>
                          Nomor Pekerjaan: {dataGetProjectDetailPm?.jobNumber}
                        </h6>
                        <StatusBadge
                          status={dataGetProjectDetailPm?.status}
                          className="ml-3"
                        />
                      </div>
                    </div>
                    <div className="info-row">
                      <div className="info">
                        <img src={CoinImage} alt="" />
                        <NumberFormat
                          prefix={"Rp "}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetProjectDetailPm?.price}
                          renderText={(value) => <p>{value}</p>}
                        />
                      </div>
                      <div className="info">
                        <img src={ClockImage} alt="" />
                        <p>
                          Deadline{" "}
                          {dayjs(dataGetProjectDetailPm?.deadlineDate)
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </p>
                      </div>
                    </div>
                    <div className="info-row">
                      {/* <div className="info">
                        <img src={UsersImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetProjectDetailPm?.nWriter}
                          renderText={(value) => (
                            <p>
                              <strong> {value} </strong> Penulis Bergabung
                            </p>
                          )}
                        />
                      </div> */}
                      <div className="info">
                        <img src={WinnerImage} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={
                            dataGetProjectDetailPm?.totalWinner
                              ? dataGetProjectDetailPm?.totalWinner
                              : 0
                          }
                          renderText={(value) => <p>{value}/</p>}
                        />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataGetProjectDetailPm?.winnerQuota}
                          renderText={(value) => (
                            <p>
                              <strong>{value}</strong> Tulisan Terpilih
                            </p>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="project-description">
                    <p>{dataGetProjectDetailPm?.description}</p>
                  </div>
                  <div className="project-action">
                    <div className="action-left">
                      {dataGetProjectDetailPm?.briefUrl && (
                        <a
                          className="btn-download brief"
                          href={dataGetProjectDetailPm?.briefUrl}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img src={iconDownload} />
                          Unduh Ringkasan
                        </a>
                      )}
                    </div>
                    {/* <div className="action-right">
                      {isAssigned && (
                        <>
                          {((dataGetProjectDetailPm?.status === "published" &&
                            (!dataGetProjectDetailPm?.totalWinner ||
                              dataGetProjectDetailPm?.totalWinner <
                                dataGetProjectDetailPm?.winnerQuota)) ||
                            (dataGetProjectDetailPm?.status === "ongoing" &&
                              (!dataGetProjectDetailPm?.totalWinner ||
                                dataGetProjectDetailPm?.totalWinner <
                                  dataGetProjectDetailPm?.winnerQuota))) && (
                            <>
                              {isAssigned && (
                                <ButtonIconLeft
                                  className="ml-2"
                                  label="UNDANG PENULIS"
                                  image={ImgInvite}
                                  onClick={() => setOpenDialog("users")}
                                />
                              )}
                            </>
                          )}
                          {dataGetProjectDetailPm?.status === "new" && (
                            <>
                              {(!dataGetProjectDetailPm?.pmName ||
                                !dataGetProjectDetailPm?.pmId) && (
                                <ButtonIconLeft
                                  className="ml-2"
                                  label="ASSIGN PM"
                                  image={ImgInvite}
                                  onClick={() => openPm()}
                                />
                              )}
                              <ButtonIconLeft
                                className="ml-2"
                                label="TERBITKAN"
                                image={ImgPublish}
                                onClick={() => handlePublish()}
                                loading={loadingUpdateProject}
                              />
                            </>
                          )}
                          {(dataGetProjectDetailPm?.status === "published" ||
                            dataGetProjectDetailPm?.status === "ongoing") && (
                            <ButtonIconLeft
                              className="ml-2"
                              label="TUTUP PROJECT"
                              color="danger"
                              image={ImgClose}
                              onClick={() => handleCloseProject()}
                            />
                          )}
                        </>
                      )}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="project-detail-bottom">
                <div className="tab-project" ref={pdfRef}>
                  <TabView>
                    <TabPanel header="Ringkasan Project">
                      <div className="accordion-project-wrapper">
                        <div className="row">
                          <div className="col-12 col-sm-12">
                            <h3>Informasi Project</h3>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Jenis Project</h6>
                            <div className="box-text">{categoryName}</div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Kategori Project</h6>
                            <div className="box-text">
                              {dataGetProjectDetailPm?.category}
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Manajer Project</h6>
                            <div className="box-text">
                              {dataGetProjectDetailPm?.pmName ||
                                users?.user?.name}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12">
                            <hr />
                          </div>
                          <div className="col-12 col-sm-12">
                            <h3>Informasi Klien</h3>
                          </div>
                          <div className="col-12 col-sm-12">
                            <h2>{dataGetProjectDetailPm?.clientCompany?.name}</h2>
                          </div>
                          <div className="col-12 col-sm-12">
                            <p>
                              {
                                dataGetProjectDetailPm?.clientCompany
                                  ?.companyBackground
                              }
                            </p>
                          </div>
                          <div className="col-12 col-sm-6">
                            <h6>Bidang Usaha</h6>
                            <div className="box-text">
                              {
                                dataGetProjectDetailPm?.clientCompany
                                  ?.businessField
                              }
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <h6>Kata Kunci Pencarian</h6>
                            <div className="d-flex flex-wrap">
                              {keywords?.map((item) => (
                                <div className="box-text list">{item}</div>
                              ))}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12">
                            <hr />
                          </div>
                          <div className="col-12 col-sm-12">
                            <h3>Target Pembaca</h3>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Usia</h6>
                            <div className="box-text">
                              {dataGetProjectDetailPm?.target?.age}
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Jenis kelamin</h6>
                            <div className="box-text">
                              {dataGetProjectDetailPm?.target?.sex}
                            </div>
                          </div>
                          <div className="col-12 col-sm-4">
                            <h6>Stakeholder</h6>
                            <div className="d-flex flex-wrap">
                              {dataGetProjectDetailPm?.target?.stakeholders?.map(
                                (item) => (
                                  <div className="box-text list">{item}</div>
                                )
                              )}
                            </div>
                          </div>
                          <div className="col-12 col-sm-12">
                            <hr />
                          </div>
                          <div className="col-12 col-sm-12">
                            <h3>Ruang Lingkup Rilis</h3>
                          </div>
                          {dataGetProjectDetailPm?.type === "pr" && (
                            <>
                              <div className="col-12 col-sm-4">
                                <h6>Kategori Rilis</h6>
                                <div className="box-text mb-5">
                                  {dataGetProjectDetailPm?.releaseCategory}
                                </div>
                              </div>
                            </>
                          )}

                          <div className="col-12 col-sm-12">
                            <h6>Objek Komunitas yang Ingin Dicapai</h6>
                            <p>{dataGetProjectDetailPm?.objective}</p>
                            <h6>
                              Atribut Quote dan Pesan yang Ingin Disampaikan
                            </h6>
                            <p>{dataGetProjectDetailPm?.attribution}</p>
                            <h6>
                              Hal yang Diperhatikan dalam Penulisan Release
                            </h6>
                            <p>{dataGetProjectDetailPm?.releaseNote}</p>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel header="Story Angle">
                      <div className='d-flex justify-content-between flex-wrap mx-10 mt-20'>
                        {dataGetProjectDetailPm?.storyAngles.map((item, i) => (
                          <div key={i} className='px-5 py-8 mb-5 border border-2 rounded w-350px'>
                            <div className="mb-3 d-flex justify-content-between">
                              <div className='h4 pr-3'>{item?.headline}</div>
                              { item.isSelected === true ?
                                (
                                  <div>
                                    <img src="/media/Lunas.svg" alt="" />
                                  </div>
                                ) : null
                              }
                            </div>
                            <div className='mb-10'>
                              {item?.talkingPoints.map((point, index) => (
                                <li key={index} className='mb-1'>{point}</li>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                  </TabView>
                </div>
              </div>
            </>
          )}
        </div>
      </div>

      <DialogAlert
        type="warning"
        visible={openNotice}
        actionDialog={() => openEditPage()}
        actionText="LENGKAPI DATA"
        closeDialog={() => closeDialogBrief()}
        title="Data Project Belum Lengkap"
        text="Silahkan lengkapi data terlebih dahulu"
        cancelText="TUTUP"
      />
    </>
  );
};

export default DetailBriefProjectModule;
