import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import md5 from "crypto-js/md5";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import CreateArticleModule from "../../modules/User/CreateArticle/CreateArticleModule";

import {
  getArticleAction,
  getDetailProjectAction,
} from "../../../redux/action/projectAction";

import { titlePage } from "../../helpers/titleHead";

const CreateArticlePage = () => {
  const dispatch = useDispatch();
  const queryParam = useParams();
  const { users } = useSelector((state) => state.auth);
  const { dataGetArticle } = useSelector((state) => state.project);
  const hashId = md5(queryParam?.projectId + users?.uid);

  useEffect(() => {
    titlePage({
      title: "IRIS - Tulis Artikel",
    });
    dispatch(getDetailProjectAction(queryParam?.projectId));
    dispatch(getArticleAction(hashId.toString()));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataGetArticle?.projectId !== queryParam?.projectId) {
      dispatch(getDetailProjectAction(queryParam?.projectId));
      dispatch(getArticleAction(hashId.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryParam?.projectId, dataGetArticle?.projectId]);

  return (
    <DashboardUserLayout>
      <div className="p-d-flex p-justify-center">
        <div className="p-grid">
          <div className="col-12 create-article-wrapper p-justify-center">
            <div className="box-content box-create p-mt-4 p-mb-4">
              <CreateArticleModule
                projectId={queryParam?.projectId}
                hashId={hashId?.toString()}
              />
            </div>
          </div>
        </div>
      </div>
    </DashboardUserLayout>
  );
};

export default CreateArticlePage;
