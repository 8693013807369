import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import ButtonLoader from "../../../../components/button/ButtonLoader";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import RegisterBenefitModule from "../../Register/RegisterBenefitModule";
import { AutoComplete } from "primereact/autocomplete";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/inputtextarea";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../../helpers/phoneNumber";
import {
  clientRegisterAction,
  resetStateAuthAction,
} from "../../../../../redux/action/authAction";
import DialogAlert from "../../../../components/dialog/DialogAlert";

const AddCompany = (props) => {
  const initialFormData = {
    company: "",
    phone: "",
    type: "",
    detail: "",
    address: "",
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formError, setFormError] = useState({});

  const [companies, setCompanies] = useState([]);
  const [business, setBusiness] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [companiesFiltered, setCompaniesFiltered] = useState([]);
  const [dialogAlert, setDialogAlert] = useState({
    type: null,
    dialog: false,
    title: "",
    text: "",
  });

  const validateForm = () => {
    const errors = {};

    if (!formData.company)
      errors.company = "Nama Perusahaan / Brand harus diisi";
    if (!formData.phone)
      errors.phone = "Nomor Telepon perusahaan / brand harus diisi";
    if (!formData.type) errors.type = "Bidang Perusahaan / Brand harus diisi";
    if (!formData.detail)
      errors.detail = "Deskripsi perusahaan / brand harus diisi";
    if (!formData.address)
      errors.address = "Alamat Perusahaan / Brand harus diisi";
    if (formData.phone && !formPhoneNumber(formData.phone)) {
      errors.phone = "Nomor telepon perusahaan / brand tidak Valid";
    }

    return errors;
  };

  const history = useHistory();
  const dispatch = useDispatch();

  const {
    loadingRegisterClient,
    successRegisterClient,
    errRegisterClient,
  } = useSelector((state) => state.auth);

  const {
    dataAutoCompleteCompanies,
    loadingAutoCompleteCompanies,
  } = useSelector((state) => state.client);

  const { dataBusiness, dataAccount } = useSelector((state) => state.utils);

  useEffect(() => {
    if (formData?.company?.length > 0) {
      const companies = dataAutoCompleteCompanies?.filter(
        (item) => item.companyName === formData.company
      );

      if (companies?.length > 0) {
        const data = companies.reduce((item) => item);

        setFormData((prevFormData) => ({
          ...prevFormData,
          address: data?.companyAddress,
          type: data?.businessField,
          detail: data?.companyBackground,
          phone: data?.companyPhone.replace("+62", "0"),
        }));
      }
    }
  }, [formData.company, dataAutoCompleteCompanies, companiesData]);

  useEffect(() => {
    if (dataBusiness) {
      setBusiness(dataBusiness);
    }
  }, [dataBusiness]);

  useEffect(() => {
    if (!loadingAutoCompleteCompanies && dataAutoCompleteCompanies !== null) {
      const companiesFilter = dataAutoCompleteCompanies?.map(
        (item) => item.companyName
      );
      setCompanies(companiesFilter);
      setCompaniesData(dataAutoCompleteCompanies);
    }
  }, [loadingAutoCompleteCompanies, dataAutoCompleteCompanies]);

  useEffect(() => {
    if (successRegisterClient) {
      setDialogAlert({
        type: "success",
        dialog: true,
        title: "Berhasil Mendaftarkan Akun",
        text: "Tautan verifikasi akun akan dikirim ke email anda",
      });
    }
  }, [successRegisterClient]);

  useEffect(() => {
    if (errRegisterClient) {
      setDialogAlert({
        type: "error",
        dialog: true,
        title: "Gagal Mendaftarkan Akun",
        text: "Nomor telepon atau Email sudah dipakai",
      });
    }
  }, [errRegisterClient]);

  const completeMethodHandler = (e) => {
    const data = e.query.trim();
    if (data.length) {
      let filter = companies?.filter(
        (item) => item?.toLowerCase().indexOf(data.toLowerCase()) > -1
      );
      setCompaniesFiltered(filter);
    }
  };

  const itemTemplate = (item) => {
    return <p>{item}</p>;
  };

  const handleKeyPressPhone = (e) => {
    if (e.key === "e" || e.key === "-") {
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });

    setFormError({
      ...formError,
      [name]: null,
    });
  };

  const submitData = (e) => {
    e.preventDefault();

    const data = {
      ...dataAccount,
      companyAddress: formData.address,
      companyPhone: formattedPhoneNumber(formData.phone),
      businessField: formData.type,
      companyName: formData.company,
      companyBackground: formData.detail,
      companyPortofolio: null,
    };

    const errors = validateForm();

    if (Object.keys(errors).length === 0) {
      dispatch(clientRegisterAction(data));
    } else {
      setFormError(errors);
    }
  };

  const closeDialog = () => {
    setDialogAlert({ type: null, dialog: false, title: "", text: "" });
    dispatch(resetStateAuthAction());

    if (successRegisterClient) {
      history.push("/login");
    } else {
      props.registerFailed();
    }
  };

  const handleBack = () => {
    props.handleBack();
  };

  return (
    <>
      <div className="auth-wrapper">
        <div className="container">
          <div className="p-grid">
            {/* benefit */}
            <RegisterBenefitModule />
            {/* account */}
            <div className="auth-register">
              <div onClick={handleBack} className="back p-d-flex p-ai-center">
                <span className="icon-back p-mr-3"></span>
              </div>
              <>
                <div className="p-field">
                  <span className="p-float-label company-name">
                    <AutoComplete
                      name="company"
                      value={formData.company}
                      className={`p-d-block ${formError.company &&
                        "p-invalid"}`}
                      suggestions={companiesFiltered}
                      completeMethod={completeMethodHandler}
                      field="name"
                      onChange={handleChange}
                      itemTemplate={itemTemplate}
                      autoFocus
                    />
                    <label>Nama Perusahaan / Brand*</label>
                  </span>
                  {formError.company && (
                    <small
                      id="company"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.company}
                    </small>
                  )}
                </div>
                <div className="p-field">
                  <span className="p-float-label">
                    <InputText
                      id="phone"
                      name="phone"
                      aria-describedby="phone"
                      className={`p-d-block ${formError.phone && "p-invalid"}`}
                      value={formData.phone}
                      onChange={handleChange}
                      onKeyPress={(e) => handleKeyPressPhone(e)}
                      autoComplete="off"
                    />
                    <label>Nomor Telepon Perusahaan / Brand*</label>
                  </span>
                  {formError.phone ? (
                    <small
                      id="phone"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.phone}
                    </small>
                  ) : (
                    <small className="text-input-info p-d-block">
                      Contoh: 0812xxxxx atau 0213xxxxx
                    </small>
                  )}
                </div>
                <div className="p-field business-dropdown">
                  <span className="p-float-label">
                    <Dropdown
                      name="type"
                      value={formData.type}
                      onChange={handleChange}
                      className={`${formError.type && "p-invalid"}`}
                      options={business}
                      optionLabel="name"
                      optionValue="code"
                    />
                    <label>Bidang Perusahaan / Brand*</label>
                  </span>
                  {formError.type && (
                    <small
                      id="company"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.type}
                    </small>
                  )}
                </div>
                <div className="p-field">
                  <span className="p-float-label">
                    <InputTextarea
                      name="detail"
                      rows={5}
                      cols={30}
                      value={formData.detail}
                      className={`p-d-block ${formError.detail && "p-invalid"}`}
                      onChange={handleChange}
                    />
                    <label>Deskripsi Perusahaan / Brand*</label>
                  </span>
                  {formError.detail && (
                    <small
                      id="address"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.detail}
                    </small>
                  )}
                </div>
                <div className="p-field">
                  <span className="p-float-label">
                    <InputTextarea
                      name="address"
                      rows={5}
                      cols={30}
                      value={formData.address}
                      className={`p-d-block ${formError.address &&
                        "p-invalid"}`}
                      onChange={handleChange}
                    />
                    <label>Alamat Perusahaan / Brand*</label>
                  </span>
                  {formError.address && (
                    <small
                      id="address"
                      className="p-error p-d-block p-text-right"
                    >
                      {formError.address}
                    </small>
                  )}
                </div>
              </>
              {loadingRegisterClient ? (
                <ButtonLoader />
              ) : (
                <Button
                  label="Selanjutnya"
                  className="p-button-rounded p-button-lg"
                  onClick={submitData}
                />
              )}
              <div className="footer-register">
                <p>
                  Sudah punya akun? <Link to="/login">Login disini</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
};

export default AddCompany;
