import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import iconDownload from "../../../assets/img/download-blue.svg";

import DateBody from "../../../components/datatableBody/DateBody";
import NumberBody from "../../../components/datatableBody/NumberBody";
import StatusBadge from "../../../components/badge/StatusBadge";
import TextBody from "../../../components/datatableBody/TextBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";

import FilterReqPayout from "./Components/FilterReqPayout";
import EditPayoutPopup from "./Components/EditPayoutPopup";

import ButtonDefault from "../../../components/button/ButtonDefault";
import { listPayoutFinanceAction } from "../../../../redux/action/transactionAction";
import { getToken } from "../../../../redux/service/firebase/auth";
import {
  approveAllPayoutAction,
  resetStateFinanceAction,
} from "../../../../redux/action/financeAction";

const ListReqPayoutModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogAlert, setDialogAlert] = useState({
    type: "",
    dialog: false,
    title: "",
    text: "",
  });

  const { loadingPayoutFinance, dataPayoutFinance, totalPage } = useSelector(
    (state) => state.transaction
  );
  const { loadingApproveAllPayout, successApproveAllPayout } = useSelector(
    (state) => state.finance
  );
  const { users } = useSelector((state) => state.auth);
  const isFinance = users?.user?.type === "finance";

  useEffect(() => {
    if (successApproveAllPayout) {
      setDialogAlert({
        type: "success",
        dialog: true,
        title: "Berhasil!",
        text: "Semua payout berhasil di setujui",
      });
    }
  }, [successApproveAllPayout]);

  const rekeningBody = (item) => {
    return <TextBody text={item?.writerBankAccount} />;
  };

  const namaBody = (item) => {
    return <TextBody text={item?.writerName} />;
  };

  const amountTemplate = (item) => {
    return <NumberBody number={item?.amount || 0} isPrice />;
  };

  const statusBodyTemplate = (item) => {
    return <StatusBadge status={item.status} userType={"finance"} />;
  };

  const dateBodyTemplate = (item) => {
    return <DateBody date={item?.createdAt} />;
  };

  const actionBodyTemplate = (item) => {
    return item.status === "process" || item.status === "waiting" ? (
      <div className="action-table">
        <EditPayoutPopup data={item} />
      </div>
    ) : (
      <TextBody text="-" />
    );
  };

  const confirmApprove = () => {
    setDialogAlert({
      type: "warning",
      dialog: true,
      title: "Approve Semua Payout?",
      actionDialog: () => approveAllPayout(),
    });
  };

  const approveAllPayout = () => {
    dispatch(approveAllPayoutAction());
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      page: page + 1,
      perpage: 10,
    };
    dispatch(listPayoutFinanceAction(params));

    history.push(
      `/admin/${
        isFinance ? "finance" : "pm"
      }/list-request-payout?search=${parsed?.search || ""}&page=${page + 1}`
    );
  };

  const closeDialog = () => {
    if (dialogAlert === "success") {
      dispatch(resetStateFinanceAction());
    }
    setDialogAlert({ type: "", dialog: false, title: "", text: "" });
  };

  const downloadFile = async () => {
    const token = await getToken();
    const url = parsed?.search
      ? `&search=${parsed?.search}&page=1&year=${parsed?.year}&month=${parsed?.month}`
      : `&page=1&year=${parsed?.year}&month=${parsed?.month}`;
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1/finance/payout/export?token=${token}${url}`,
      "_blank"
    );
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5 p-d-flex p-jc-between p-ai-center">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              List Request Payout
            </span>
          </h3>
          <div className="p-d-flex p-jc-end p-ai-center">
            <button
              className="btn-download mr-3"
              onClick={() => downloadFile()}
            >
              <img src={iconDownload} alt="icon" />
              Unduh Payout
            </button>
            <ButtonDefault
              label="Approve All"
              onClick={() => confirmApprove()}
              disabled={
                dataPayoutFinance?.length < 1 || dataPayoutFinance === null
              }
            />
          </div>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterReqPayout parsed={parsed} />
          {loadingPayoutFinance ? (
            <TableLoader />
          ) : (
            <>
              {dataPayoutFinance?.length < 1 || dataPayoutFinance === null ? (
                <DataEmpty />
              ) : (
                <DataTable value={dataPayoutFinance} className="no-padding">
                  <Column
                    header="Tanggal Request"
                    body={dateBodyTemplate}
                  ></Column>
                  <Column header="Nomor Rekening" body={rekeningBody}></Column>
                  <Column header="Nama" body={namaBody}></Column>
                  <Column header="Jumlah Payout" body={amountTemplate}></Column>
                  <Column header="Status" body={statusBodyTemplate}></Column>
                  <Column
                    bodyClassName="d-flex justify-content-end"
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
        loading={loadingApproveAllPayout}
        cancelText={dialogAlert.cancelText}
        actionDialog={dialogAlert.actionDialog}
        actionText={dialogAlert.actionText}
      />
    </>
  );
};

export default ListReqPayoutModule;
