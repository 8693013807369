/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import NumberFormat from "react-number-format";
import queryString from "query-string";
import _ from "lodash";
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import { InputText } from "primereact/inputtext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import iconDownload from "../../../assets/img/download-blue.svg";
import {
  HeaderContentLoad,
  TableLoad,
} from "../../../components/loading/loadingContent";
import NumberBody from "../../../components/datatableBody/NumberBody";
import TextBody from "../../../components/datatableBody/TextBody";
import DateBody from "../../../components/datatableBody/DateBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import ButtonLink from "../../../components/button/ButtonLink";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { getToken } from "../../../../redux/service/firebase/auth";
import {
  getDetailReleaseAction,
  deleteMediaReleaseAction,
  resetStateReleaseAction,
  statsMediaReleaseAction,
} from "../../../../redux/action/releaseAction";

const MediaShareDetailModule = (props) => {
  const { releaseId } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [loadingDelete, setLoadingDelete] = useState(false);
  const [chart, setChart] = useState(null);
  const [dialogAlert, dialogAlertSet] = useState({
    type: "warning",
    dialog: false,
    title: "",
    text: "",
  });
  const {
    dataMediaRelease,
    loadingMediaRelease,
    infoMediaRelease,
    successDeleteRelease,
    errDeleteRelease,
    dataStatsMediaRelease,
    loadingStatsMediaRelease,
  } = useSelector((state) => state.release);

  useEffect(() => {
    if (!loadingStatsMediaRelease && dataStatsMediaRelease !== null) {
      const { tier } = dataStatsMediaRelease;
      const info = [
        { name: "Tier 1", value: tier?.tier1 || 0, color: "#0099FF" },
        { name: "Tier 2", value: tier?.tier2 || 0, color: "#006BB3" },
        { name: "Tier 3", value: tier?.tier3 || 0, color: "#0CCAE8" },
      ];

      setChart(info);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataStatsMediaRelease]);

  useEffect(() => {
    if (successDeleteRelease) {
      setTimeout(() => {
        setLoadingDelete(false);
        dialogAlertSet({
          type: "success",
          dialog: true,
          title: "Berhasil Terhapus!",
          text: "Berita Berhasil dihapus",
        });
      }, 2500);
    } else if (errDeleteRelease) {
      dialogAlertSet({
        type: "error",
        dialog: true,
        title: "Gagal Terhapus!",
        text: "Berita Gagal dihapus",
      });
      setLoadingDelete(false);
    }
  }, [successDeleteRelease, errDeleteRelease]);

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
      };

      history.push(
        `/admin/pm/detail-release/${releaseId}?search=${search || ""}&page=1`
      );
      dispatch(getDetailReleaseAction(releaseId, params));
    }, 1000)
  ).current;

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getDetailReleaseAction(releaseId, params));

    history.push(
      `/admin/pm/detail-release/${releaseId}?search=${parsed?.search ||
        ""}&page=${page + 1}`
    );
  };

  const openDelete = (data) => {
    const deleteAction = (id) => {
      dispatch(deleteMediaReleaseAction(id));
      setLoadingDelete(true);
    };

    dialogAlertSet({
      type: "warning",
      dialog: true,
      title: `Hapus ${data?.title}`,
      text: "Anda yakin menghapus berita ini?",
      actionDialog: () => deleteAction(data._id),
      actionText: "Hapus",
    });
  };

  const closeAlert = () => {
    dialogAlertSet({
      type: "warning",
      dialog: false,
      title: "",
      text: "",
    });
    if (successDeleteRelease) {
      dispatch(resetStateReleaseAction());
      dispatch(getDetailReleaseAction(releaseId, {}));
      dispatch(statsMediaReleaseAction(releaseId));
    } else if (errDeleteRelease) {
      dispatch(resetStateReleaseAction());
    }
  };

  const downloadFile = async (type) => {
    const token = await getToken();
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1/pm/press-release/${releaseId}/media/export?token=${token}`,
      "_blank"
    );
  };

  const dateBody = (item) => {
    return <DateBody date={item?.createdAt} />;
  };
  const titleBody = (item) => {
    return <TextBody text={`${item?.title}`} />;
  };
  const mediaBody = (item) => {
    return <TextBody text={`${item?.mediaName}`} />;
  };
  const tierBody = (item) => {
    return <NumberBody number={item?.mediaTier} />;
  };
  const prValueBody = (item) => {
    return <NumberBody number={item?.prValue || 0} isPrice />;
  };
  const actionBody = (item) => {
    return (
      <div className="action-table">
        <a href={item?.mediaLink} target="_blank" className="btn btn-action">
          <span className="icon-ac-link" />
        </a>
        <Link
          to={`/admin/pm/detail-release/${releaseId}/update/${item?._id}`}
          className="btn btn-action"
        >
          <span className="icon-edit-pencil-blue" />
        </Link>
        <button className="btn btn-action" onClick={() => openDelete(item)}>
          <span className="icon-ac-delete" />
        </button>
      </div>
    );
  };

  const labelBody = ({ active, payload }) => {
    if (active && payload && payload?.length) {
      return (
        <div className="label-box">
          <div
            className="label-color"
            style={{ backgroundColor: `${payload[0]?.payload?.color}` }}
          />
          <div className="label-text">
            <div className="label-name">{payload[0]?.name}</div>
            <div className="label-value">{`${payload[0]?.value?.toFixed(
              2
            )}%`}</div>
          </div>
        </div>
      );
    } else {
      return <div />;
    }
  };

  return (
    <>
      <div className="card card-dashboard">
        <div className="card-body">
          {loadingStatsMediaRelease && !dataStatsMediaRelease ? (
            <HeaderContentLoad />
          ) : (
            <div className="media-share-upper">
              <div className="media-share-text">
                <div className="media-share-box">
                  <p>Total News</p>
                  <h3>{dataStatsMediaRelease?.totalNews}</h3>
                </div>
                <div className="media-share-box">
                  <p>Total PR Value</p>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={dataStatsMediaRelease?.totalPRValue}
                    renderText={(value) => <h3>{value}</h3>}
                  />
                </div>
              </div>
              <div className="media-share-chart">
                <PieChart width={200} height={200}>
                  <Pie
                    data={chart}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    fill="#0099FF"
                  >
                    {chart?.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip content={labelBody} />
                </PieChart>
              </div>
              <div className="media-share-legend">
                <p>Akumulatif Media Tier</p>
                {chart?.map((item) => (
                  <div className="legend-box" key={item.name}>
                    <div
                      className="legend-color"
                      style={{ backgroundColor: `${item.color}` }}
                    />
                    <div className="legend-value">{`${item.value.toFixed(
                      2
                    )}%`}</div>
                    <div className="legend-name">{item.name}</div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="card card-dashboard mt-4">
        <div className="card-header">
          <div className="card-title">
            <h2 className="card-title-text">List Media Distribusi</h2>
          </div>
        </div>
        <div className="card-body">
          <div className="table-attribute-wrapp between">
            <div className="filter">
              <div className="filter-field">
                <InputText
                  id="search"
                  aria-describedby="search-help"
                  className="p-d-block search-inputtext"
                  placeholder="Cari"
                  onChange={(e) => searchingHandler(e.target.value)}
                />
                <p>
                  Cari berdasarkan <span>Judul</span>
                </p>
              </div>
            </div>
            <div className="attribute-right">
              <button
                className="btn-download mr-5"
                onClick={() => downloadFile()}
              >
                <img src={iconDownload} alt="download" />
                Export
              </button>
              <ButtonLink
                label="Tambah Media Distribusi"
                link={`/admin/pm/add-release/${releaseId}`}
                className="ml-5"
              />
            </div>
          </div>
          {loadingMediaRelease ? (
            <TableLoad />
          ) : (
            <div className="table-content-wrapp">
              {dataMediaRelease?.length < 1 || dataMediaRelease === null ? (
                <DataEmpty />
              ) : (
                <DataTable value={dataMediaRelease} className="table-release">
                  <Column
                    bodyClassName="text-grey"
                    header="Tanggal"
                    body={dateBody}
                  />
                  <Column
                    headerClassName="release-title"
                    header="Judul Berita"
                    body={titleBody}
                  />
                  <Column header="Nama Media" body={mediaBody} />
                  <Column header="PR Value" body={prValueBody} />
                  <Column header="Media Tier" body={tierBody} />
                  <Column body={actionBody} />
                </DataTable>
              )}
            </div>
          )}
          {infoMediaRelease?.total > 10 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={infoMediaRelease?.total}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeAlert()}
        title={dialogAlert.title}
        text={dialogAlert.text}
        actionDialog={dialogAlert.actionDialog}
        actionText={dialogAlert.actionText}
        loading={loadingDelete}
      />
    </>
  );
};

export default MediaShareDetailModule;
