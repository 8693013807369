import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useHistory } from "react-router-dom";
import ButtonLoader from "../../../../components/button/ButtonLoader";
import { getStorage } from "../../../../../_metronic/_helpers/LocalStorageHelpers";

import DialogPassword from "./PasswordConfirmation";
import OTPConfirmation from "./OTPConfirmation";
import DialogAlert from "../../../../components/dialog/DialogAlert";

import { formEmail } from "../../../../helpers/email";
import {
  checkEmailAction,
  resetStateAuthAction,
  logoutAction,
  sendUpdateEmailClientAction,
} from "../../../../../redux/action/authAction";

const UpdateEmail = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [emailState, setEmailState] = useState({
    value: "",
    error: null,
  });
  const [dialogPassword, setDialogPassword] = useState(false);
  const [dialogOtp, setDialogOtp] = useState(false);
  const [dialogAlert, setDialogAlert] = useState({
    type: null,
    text: null,
    title: null,
    dialog: false,
  });

  const {
    users,
    dataCheckEmail,
    loadingCheckEmail,
    loadingEmailLogin,
    successEmailLogin,
    errEmailLogin,
    successSendUpdateEmailClient,
    errSendUpdateEmailClient,
    successOtpLogin,
    errOtpLogin,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successEmailLogin) {
      dispatch(sendUpdateEmailClientAction({ newEmail: emailState.value }));
    }
    if (errEmailLogin) {
      setDialogAlert({
        type: "error",
        text: "Kata sandi yang anda masukkan salah",
        title: "Gagal Konfirmasi Kata Sandi",
        dialog: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successEmailLogin, errEmailLogin]);

  useEffect(() => {
    if (successOtpLogin) {
      dispatch(sendUpdateEmailClientAction({ newEmail: emailState.value }));
    }
    if (errOtpLogin) {
      setDialogAlert({
        type: "error",
        text: "Otp yang anda masukan salah",
        title: "Gagal Konfirmasi No. Handphone",
        dialog: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successOtpLogin, errOtpLogin]);

  useEffect(() => {
    if (successSendUpdateEmailClient) {
      setDialogAlert({
        type: "success",
        text: "Berhasil Mengubah Email",
        title: "Silakan cek email anda untuk verifikasi",
        dialog: true,
      });
    }
    if (errSendUpdateEmailClient) {
      setDialogAlert({
        type: "error",
        text: "Gagal Mengubah Email",
        title: "Email gagal diubah",
        dialog: true,
      });
    }
  }, [successSendUpdateEmailClient, errSendUpdateEmailClient]);

  useEffect(() => {
    if (dataCheckEmail !== null && !dataCheckEmail?.exists) {
      if (getStorage("login-mode") === "email") {
        setDialogPassword(true);
      } else {
        setDialogOtp(true);
      }
    } else if (dataCheckEmail?.exists) {
      setDialogAlert({
        type: "error",
        text: "Email telah terdaftar dengan akun lainnya",
        title: "Gagal Update Email",
        dialog: true,
      });
    }
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [dataCheckEmail]);

  const submitEmail = () => {
    if (!formEmail(emailState?.value)) {
      setEmailState({
        ...emailState,
        error: "Email tidak valid",
      });
    } else if (emailState?.value?.length < 1) {
      setEmailState({
        ...emailState,
        error: "Email harus diisi",
      });
    } else if (emailState?.value === users?.user?.email) {
      setEmailState({
        ...emailState,
        error: "Email baru harus berbeda dengan email lama",
      });
    } else {
      dispatch(checkEmailAction({ email: emailState.value }));
    }
  };

  const closeDialog = () => {
    if (dialogAlert.type === "success") {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
      dispatch(resetStateAuthAction());
      dispatch(logoutAction());
    } else {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
    }
  };

  return (
    <>
      <div className="section bg-grey">
        <div className="p-d-flex p-jc-center">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-6 update-setting">
            <div className="card card-custom-detail setting  client-update-setting w-full has-shadow mb-5">
              <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <h3 className="card-title flex-column update-setting">
                    <div
                      className="icon-back mr-2"
                      onClick={() => history.goBack()}
                    />
                    <span className="card-label font-weight-bolder text-dark">
                      Ubah Email
                    </span>
                  </h3>
                </div>
              </div>
              <div className="form-update-setting">
                <div className="p-field">
                  <label className="p-d-block font-weight-bolder">
                    Email Saat Ini
                  </label>
                  <InputText
                    type="email"
                    placeholder="Email Lama"
                    className="p-d-block"
                    value={users?.user?.email}
                    disabled
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block font-weight-bolder">
                    Email Baru
                  </label>
                  <InputText
                    type="email"
                    placeholder="Masukan email baru"
                    className={`p-d-block ${emailState?.error && "p-invalid"}`}
                    value={emailState.value}
                    onChange={(e) =>
                      setEmailState({ value: e.target.value, error: null })
                    }
                  />
                  {emailState.error && (
                    <smal className="p-error p-d-block p-text-right">
                      {emailState.error}
                    </smal>
                  )}
                </div>
              </div>
              <div className="udpate-setting-footer client p-d-flex p-jc-end">
                <Button
                  className="p-button p-button-outlined"
                  onClick={() => history.goBack()}
                >
                  BATAL
                </Button>
                {loadingCheckEmail || loadingEmailLogin ? (
                  <ButtonLoader />
                ) : (
                  <Button
                    className="p-button p-button-primary"
                    onClick={() => submitEmail()}
                    label="SIMPAN"
                  >
                  </Button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogPassword
        visible={dialogPassword}
        closeDialog={() => setDialogPassword(false)}
      />

      <OTPConfirmation
        visible={dialogOtp}
        closeDialog={() => setDialogOtp(false)}
      />
      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
};

export default UpdateEmail;
