/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";

import { InputText } from "primereact/inputtext";

import { Button } from "primereact/button";

import DialogAlert from "../../../components/dialog/DialogAlert";
import { UploadPhotoProfile } from "../../../components/upload/uploadPhotoProfile";
import ButtonLoader from "../../../components/button/ButtonLoader";
import ListLoader from "../../../components/loading/listLoader";
import { formPhoneNumber } from "../../../helpers/phoneNumber";

import {
  updateUserFirebaseAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";

const IMAGE_DEFAULT =
  "https://firebasestorage.googleapis.com/v0/b/iris-staging-81cfa.appspot.com/o/assets%2FDefault_Profile%2FProfile.png?alt=media&token=401b15ab-4eab-4b39-b5e9-1d5d3a164ba7";

const ProfileEditPmModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [dialogSuccess, setDialogSuccess] = useState(false);

  const [nameState, setNameState] = useState({ value: "", error: null });
  const [phoneState, setPhoneState] = useState({ value: "", error: null });
  const [imageState, setImageState] = useState({
    value: null,
    error: null,
  });

  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );

  const { loadingDetailUser } = useSelector((state) => state.user);

  const {
    users,
    loadingUpdateUserFirebase,
    successUpdateUserFirebase,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (users !== null) {
      if (users?.user?.photo) {
        setImageState({
          value: users?.user?.photo,
          error: null,
        });
      } else {
        setImageState({
          value: IMAGE_DEFAULT,
          error: null,
        });
      }
      setNameState({ value: users?.user?.name, error: null });
      setNameState({ value: users?.user?.name, error: null });
      setPhoneState({ value: users?.user?.phone, error: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ value: dataUploadImage, error: null });
    }
  }, [dataUploadImage, successUploadImage]);

  useEffect(() => {
    if (successUpdateUserFirebase) {
      setDialogSuccess(true);
    }
  }, [successUpdateUserFirebase]);

  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(resetStateAuthAction());
    setDialogSuccess(false);
    history.push("/admin/pm/profile");
  };

  const submitData = () => {
    let data = {
      name: nameState.value,
      photo: imageState.value,
      phone: phoneState.value,
    };

    if (nameState?.value?.length < 1) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    }
    if (!formPhoneNumber(phoneState.value)) {
      setPhoneState({ ...phoneState, error: "Format nomor telepon salah" });
    }
    if (nameState?.value?.length > 0) {
      dispatch(updateUserFirebaseAction(users?.uid, data));
    }
  };

  const handleInputPhone = (e) => {
    e.target.value.length < 13 &&
      setPhoneState({ value: e.target.value.toString(), error: null });
  };

  const handleKeyInputPhone = (e) => {
    const allowedKeys = "1234567890+".split("");
    if (!allowedKeys.includes(e.key)) {
      e.preventDefault();
    }
  };
  return (
    <>
      <div className="col-lg-8 col-md-12 col-sm-12 mx-auto">
        <div className="card card-custom card-edit-profile mb-4">
          <div className="card-header profile border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>EDIT PROFILE
                </span>
              </button>
            </h3>
          </div>
          {loadingDetailUser ? (
            <ListLoader />
          ) : (
            <div className="card-body pt-0 pb-4">
              <div className="form-edit-photo pm">
                <UploadPhotoProfile
                  attachment={imageState.value}
                  deleteImage={() =>
                    setImageState({
                      value: IMAGE_DEFAULT,
                      error: null,
                    })
                  }
                  path={"user-data"}
                />
              </div>
              <h3>Data Personal</h3>
              <div className="form-add-project pm">
                <div className="p-field">
                  <label className="p-d-block">Nama Lengkap*</label>
                  <InputText
                    className="p-d-block"
                    placeholder="Masukan Nama"
                    value={nameState.value}
                    onChange={(e) =>
                      setNameState({ value: e.target.value, error: null })
                    }
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block">Email</label>
                  <InputText
                    className="p-d-block"
                    type="email"
                    placeholder="Masukan Email"
                    value={users?.user?.email}
                    disabled
                  />
                </div>
                <div className="p-field">
                  <label className="p-d-block">Nomor Telepon</label>
                  <InputText
                    className="p-d-block"
                    placeholder="Masukkan Nomor Telepon"
                    value={phoneState?.value}
                    onChange={(e) => handleInputPhone(e)}
                    onKeyPress={(e) => handleKeyInputPhone(e)}
                    type="text"
                  />
                </div>
              </div>
              <div className="footer-form edit-profile">
                <div className="form-add-project p-d-flex p-jc-end p-ai-center">
                  <Link
                    to="/admin/pm/profile"
                    className="p-button p-button-outlined p-button-secondary p-button-rounded mr-3"
                  >
                    Batal
                  </Link>
                  {loadingUpdateUserFirebase ? (
                    <ButtonLoader />
                  ) : (
                    <Button
                      onClick={() => submitData()}
                      label="Simpan"
                      className="p-button-rounded p-button p-button-primary"
                      disabled={nameState?.value?.length < 1}
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={closeSuccess}
        title={"Berhasil Memperbarui Data"}
        text={"Data pengguna berhasil diperbarui"}
      />
    </>
  );
};

export default ProfileEditPmModule;
