import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { RadioButton } from "primereact/radiobutton";
import ButtonDefault from "../../../components/button/ButtonDefault";
import ListLoader from "../../../components/loading/listLoader";
import { updateOrderAction } from "../../../../redux/action/orderAction";

function PaymentMethodModule(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [method, setMethod] = useState("transfer");
  const [bank, setBank] = useState({ name: "", id: "" });

  const { dataPaymentAccount, loadingPaymentAccount } = useSelector(
    (state) => state.utils
  );
  const { loadingUpdateOrder } = useSelector((state) => state.order);

  const updateData = () => {
    const data = {
      paymentMethod: {
        bankName: bank.name,
        bankId: bank.id,
      },
    };
    dispatch(updateOrderAction(props.orderId, data));
  };

  return (
    <div className="p-d-flex p-jc-center">
      <div className="col-lg-8 col-md-8 col-sm-12 method">
        <div className="card card-custom mb-4 payment-method">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <Link onClick={() => history.goBack()}>
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-7"></span>Pilih Metode
                  Pembayaran
                </span>
              </Link>
            </h3>
          </div>
          <div className="card-body">
            {loadingPaymentAccount ? (
              <ListLoader />
            ) : (
              <>
                <div className="payment-option">
                  <RadioButton
                    name="method"
                    inputId="method1"
                    checked={method === "transfer"}
                    onChange={() => setMethod("transfer")}
                  />
                  <label htmlFor="method1">
                    Transfer Bank (Konfirmasi Manual)
                  </label>
                </div>
                <div className="bank-options">
                  {dataPaymentAccount?.map((account) => (
                    <label
                      className="bank"
                      key={account?.id}
                      htmlFor={`bank${account?.id}`}
                    >
                      <div className="p-d-flex">
                        <RadioButton
                          name={account?.paymentName}
                          inputId={`bank${account?.id}`}
                          checked={bank.id === `${account?.id}`}
                          onChange={() =>
                            setBank({
                              name: account?.paymentName,
                              id: account?.id,
                            })
                          }
                        />
                        <label htmlFor={`bank${account?.id}`}>
                          {account?.paymentName}
                        </label>
                      </div>
                      <img src={account?.image} alt="bank_image" />
                    </label>
                  ))}
                </div>
              </>
            )}
          </div>
          <div className="card-footer p-d-flex p-jc-end">
            <ButtonDefault
              type="rounded"
              label="KONFIRMASI"
              className="p-button p-button-primary"
              loading={loadingUpdateOrder}
              disabled={loadingPaymentAccount}
              onClick={() => updateData()}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodModule;
