import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import { titlePage } from "../../helpers/titleHead";
import MediaShareListModule from "../../modules/Client/MediaShare/MediaShareListModule";
import {
  getWinnerReleaseAction,
  statsReleaseAction,
} from "../../../redux/action/releaseAction";

const ClientMediaSharePage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Media Distribusi",
    });
    history.push("/client/list-release");
    const params = {
      page: 1,
      perpage: 10,
    };
    dispatch(getWinnerReleaseAction(params));
    dispatch(statsReleaseAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardClientLayout
      title="Manajemen Media Distribusi"
      menu="distribution"
    >
      <MediaShareListModule />
    </DashboardClientLayout>
  );
};

export default ClientMediaSharePage;
