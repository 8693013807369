/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/id";

import { Button } from "primereact/button";
import DialogReview from "./PopupReview";
import DialogAlert from "../../../components/dialog/DialogAlert";
import ButtonLoader from "../../../components/button/ButtonAdminLoader";
import FullContentLoader from "../../../components/loading/fullContentLoader";

import {
  updateStatusProjectAction,
  resetStateProjectAction,
  getDetailProjectAction,
  getArticleAction,
} from "../../../../redux/action/projectAction";

const PMDetailProject = (props) => {
  const dispatch = useDispatch();

  const [openReview, setOpenReview] = useState({ dialog: false, id: null });
  const [openAlert, setOpenAlert] = useState({
    dialog: false,
    type: null,
    title: null,
    desc: null,
  });
  const [typeStatus, setTypeStatus] = useState(null);

  const {
    dataGetDetailProject,
    dataGetArticle,
    loadingGetDetailProject,
    loadingGetArticle,
    loadingUpdateStatusProject,
    successUpdateStatusProject,
    errUpdateStatusProject,
  } = useSelector((state) => state.project);

  useEffect(() => {
    if (successUpdateStatusProject) {
      if (typeStatus === "published") {
        setOpenAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Publish Artikel",
          desc: "Artikel akan ditampilkan pada landingpage",
        });
      } else if (typeStatus === "winner") {
        setOpenAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Pilih Tulisan",
          desc: "Sistem akan mengirimkan notifikasi ke email penulis",
        });
      } else {
        setOpenAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Tolak Tulisan",
          desc: "Sistem akan mengirimkan notifikasi ke email penulis",
        });
      }
      dispatch(getDetailProjectAction(dataGetArticle?.projectId));
      dispatch(getArticleAction(props.writerId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateStatusProject]);

  useEffect(() => {
    if (errUpdateStatusProject) {
      if (typeStatus === "published") {
        setOpenAlert({
          dialog: true,
          type: "error",
          title: "Gagal Publish Artikel",
          desc: "Artikel gagal dipublikasikan",
        });
      } else if (typeStatus === "winner") {
        setOpenAlert({
          dialog: true,
          type: "error",
          title: "Gagal Pilih Tulisan",
          desc: "Project sudah melewati deadline",
        });
      } else {
        setOpenAlert({
          dialog: true,
          type: "error",
          title: "Gagal Tolak Tulisan",
          desc: "Project tidak bisa ditolak",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errUpdateStatusProject]);

  const updateStatus = (dataProject) => {
    const data = { status: dataProject.status };
    const query = "";
    dispatch(updateStatusProjectAction(dataProject.id, data, query));
    setTypeStatus(dataProject.status);
  };

  const closeAlert = () => {
    setOpenAlert({ dialog: false, type: null, title: null, desc: null });
    setTypeStatus(null);
    dispatch(resetStateProjectAction());
  };

  let labelButton = "PILIH TULISAN";

  if (dataGetArticle?.status === "revision") {
    labelButton = "MENUNGGU REVISI";
  } else if (
    dataGetDetailProject?.totalWinner === dataGetDetailProject?.winnerQuota
  ) {
    labelButton = "PEMENANG SUDAH PENUH";
  }

  return (
    <>
      <div className="box-info">
        {loadingGetArticle || loadingGetDetailProject ? (
          <div className="box-inner-right chat-pm">
            <FullContentLoader />
            <FullContentLoader />
            <FullContentLoader />
          </div>
        ) : (
          <>
            <div className="header-box client-chat bordered p-d-flex p-jc-between p-ai-center">
              <h3 className="p-mb-0">
                <span>{dataGetArticle?.title}</span>
              </h3>
              {typeof dataGetArticle?.status !== "object" ? (
                <span
                  className={`status ${dataGetArticle?.status} weight-semibold`}
                >
                  {dataGetArticle?.status === "winner"
                    ? "Approved"
                    : dataGetArticle?.status}
                </span>
              ) : (
                <span
                  className={`status ${dataGetArticle?.status?.status} weight-semibold`}
                >
                  {dataGetArticle?.status?.status === "winner"
                    ? "Approved"
                    : dataGetArticle?.status?.status}
                </span>
              )}
            </div>
            <div className="box-inner-right chat-pm">
              <div className="detail-project">
                <p>
                  {dataGetDetailProject?.description?.length > 100
                    ? `${dataGetDetailProject?.description?.slice(0, 100)}...`
                    : dataGetDetailProject?.description}{" "}
                </p>
                <p>
                  <a
                    href={`/admin/pm/detail-project/${dataGetArticle?.projectId}`}
                    target="_blank"
                  >
                    Lihat Selengkapnya
                  </a>
                </p>

                <div className="publish-date">
                  Dipublish{" "}
                  {dayjs(dataGetDetailProject?.createdAt?.toDate())
                    .locale("id")
                    .format("DD MMMM YYYY")}
                </div>

                <div className="publish-date">
                  Deadline{" "}
                  {dayjs(dataGetDetailProject?.deadlineDate?.toDate())
                    .locale("id")
                    .format("DD MMMM YYYY")}
                </div>

                {dataGetArticle?.status !== "ongoing" && (
                  <div className="preview-article">
                    <a
                      href="#"
                      onClick={() =>
                        setOpenReview({ dialog: true, id: props.writerId })
                      }
                    >
                      <span className="icon-page" /> Lihat Tulisan
                    </a>
                  </div>
                )}

                <div className="user-progress">
                  <ul>
                    <li className="active">
                      <p>Bergabung Dalam Project</p>
                      <span>
                        {dayjs(dataGetArticle?.createdAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")}
                      </span>
                    </li>
                    {dataGetArticle?.submitAt && (
                      <li className={dataGetArticle?.submitAt ? "active" : ""}>
                        <p>On Progress</p>
                        <span>
                          {dataGetArticle?.submitAt
                            ? dayjs(dataGetArticle?.submitAt?.toDate())
                                .locale("id")
                                .format("DD MMMM YYYY")
                            : "Belum tersedia"}
                        </span>
                      </li>
                    )}
                    {dataGetArticle?.lastRevisionAt && (
                      <li
                        className={
                          dataGetArticle?.lastRevisionAt ? "active" : ""
                        }
                      >
                        <p>Revisi</p>
                        <span>
                          {dataGetArticle?.lastRevisionAt
                            ? dayjs(dataGetArticle?.lastRevisionAt?.toDate())
                                .locale("id")
                                .format("DD MMMM YYYY")
                            : "Belum tersedia"}
                        </span>
                      </li>
                    )}
                    {dataGetArticle?.doneAt && (
                      <li
                        className={
                          dataGetArticle?.doneAt ||
                          (dataGetArticle?.status !== "object"
                            ? dataGetArticle?.status === "reject"
                            : dataGetArticle?.status?.status === "reject")
                            ? "active"
                            : ""
                        }
                      >
                        <p>Selesai</p>
                        <span>
                          {dataGetArticle?.doneAt
                            ? dayjs(dataGetArticle?.doneAt?.toDate())
                                .locale("id")
                                .format("DD MMMM YYYY")
                            : "Belum tersedia"}
                        </span>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className="button-project">
                {((dataGetArticle?.status === "sent" ||
                  dataGetArticle?.status === "revision") &&
                  loadingUpdateStatusProject) ||
                  ((dataGetArticle?.status === "winner" ||
                    dataGetArticle?.status === "published") &&
                    loadingUpdateStatusProject && <ButtonLoader />)}

                {(dataGetArticle?.status === "sent" ||
                  dataGetArticle?.status === "revision") &&
                  !loadingUpdateStatusProject && (
                    <>
                      <Button
                        className="p-button p-button-primary"
                        disabled={
                          dataGetArticle?.status === "revision" ||
                          dataGetDetailProject?.totalWinner ===
                            dataGetDetailProject?.winnerQuota
                        }
                        onClick={() =>
                          updateStatus({ status: "winner", id: props.writerId })
                        }
                      >
                        {labelButton}
                      </Button>

                      <Button
                        className="p-button p-button-grey"
                        disabled={dataGetArticle?.status === "revision"}
                        onClick={() =>
                          updateStatus({ status: "reject", id: props.writerId })
                        }
                      >
                        TOLAK
                      </Button>
                    </>
                  )}

                {(dataGetArticle?.status === "winner" ||
                  dataGetArticle?.status === "published") &&
                  !loadingUpdateStatusProject && (
                    <Button
                      className="p-button p-button-primary"
                      disabled={dataGetArticle?.status === "published"}
                      onClick={() =>
                        updateStatus({
                          status: "published",
                          id: props.writerId,
                        })
                      }
                    >
                      {dataGetArticle?.status === "published"
                        ? "TERPUBLISH"
                        : "PUBLISH"}
                    </Button>
                  )}
              </div>
            </div>
          </>
        )}
      </div>

      <DialogReview
        dialogReview={openReview.dialog}
        projectId={openReview.id}
        closeReview={() => setOpenReview({ dialog: false, id: null })}
      />

      <DialogAlert
        type={openAlert.type}
        visible={openAlert.dialog}
        closeDialog={() => closeAlert()}
        title={openAlert.title}
        text={openAlert.desc}
      />
    </>
  );
};

export default PMDetailProject;
