import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams, useLocation } from "react-router-dom";
import queryString from "query-string";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import WriterProjectPmModule from "../../../modules/Admin/WriterProjectPm/WriterProjectPmModule";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import { getArticleAction } from "../../../../redux/action/projectAction";

const WriterProjectPmPage = () => {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const location = useLocation();
  const parsed = queryString.parse(location.search);
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Informasi Terbaru",
    });
    dispatch(resetUtilsStateAction());
    dispatch(getArticleAction(parsed.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Project" menu="project">
      <WriterProjectPmModule
        projectId={queryParams.projectId}
        hashId={parsed.id}
      />
    </DashboardLayout>
  );
};

export default WriterProjectPmPage;
