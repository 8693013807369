import firebase from "./firebase";
import fb from "firebase/app";

const firestore = firebase.firestore();

export const createHelp = async (data) =>
  await firestore
    .collection("helpTickets")
    .doc()
    .set({ ...data, createdAt: fb.firestore.FieldValue.serverTimestamp() });

export const getHelpList = async () =>
  await firestore.collection("helpTickets").get();

export const getHelpDetail = async (id) =>
  await firestore
    .collection("helpTickets")
    .doc(id)
    .get();

export const getHelpChats = async (id) =>
  await firestore
    .collection("helpTickets")
    .doc(id)
    .collection("chats")
    .get();

export const postHelpChats = async (id, data) =>
  await firestore
    .collection("helpTickets")
    .doc(id)
    .collection("chats")
    .add(data);
