import React from "react";
import { Link } from "react-router-dom";

const LinkEdit = (props) => {
  const { link, label, className } = props;
  return (
    <Link className={`edit-button ${className ? className : ""}`} to={link}>
      <span className="icon-edit-pencil-blue" />
      {label}
    </Link>
  );
};

export default LinkEdit;
