import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import HelpDetailModule from "../../modules/Client/Help/HelpDetailModule";

import { getHelpDetailAction } from "../../../redux/action/helpAction";

function HelpDetailPage() {
  const dispatch = useDispatch();
  const queryParams = useParams();

  useEffect(() => {
    titlePage({
      title: "IRIS - Bantuan",
    });
    dispatch(getHelpDetailAction(queryParams.ticketId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Tiket Bantuan" menu="ticket">
      <HelpDetailModule ticketId={queryParams.ticketId} />
    </DashboardClientLayout>
  );
}

export default HelpDetailPage;
