export const AUTO_COMPLETE_COMPANY_REQUEST = "AUTO_COMPLETE_COMPANY_REQUEST";
export const AUTO_COMPLETE_COMPANY_SUCCESS = "AUTO_COMPLETE_COMPANY_SUCCESS";
export const AUTO_COMPLETE_COMPANY_FAILURE = "AUTO_COMPLETE_COMPANY_FAILURE";

export const UPDATE_COMPANY_REQUEST = "UPDATE_COMPANY_REQUEST";
export const UPDATE_COMPANY_SUCCESS = "UPDATE_COMPANY_SUCCESS";
export const UPDATE_COMPANY_FAILURE = "UPDATE_COMPANY_FAILURE";

export const GET_CLIENT_REQUEST = "GET_CLIENT_REQUEST";
export const GET_CLIENT_SUCCESS = "GET_CLIENT_SUCCESS";
export const GET_CLIENT_FAILURE = "GET_CLIENT_FAILURE";

export const POST_CLIENT_REQUEST = "POST_CLIENT_REQUEST";
export const POST_CLIENT_SUCCESS = "POST_CLIENT_SUCCESS";
export const POST_CLIENT_FAILURE = "POST_CLIENT_FAILURE";

export const UPDATE_CLIENT_REQUEST = "UPDATE_CLIENT_REQUEST";
export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILURE = "UPDATE_CLIENT_FAILURE";

export const DELETE_CLIENT_REQUEST = "DELETE_CLIENT_REQUEST";
export const DELETE_CLIENT_SUCCESS = "DELETE_CLIENT_SUCCESS";
export const DELETE_CLIENT_FAILURE = "DELETE_CLIENT_FAILURE";

export const GET_CLIENT_DETAIL_REQUEST = "GET_CLIENT_DETAIL_REQUEST";
export const GET_CLIENT_DETAIL_SUCCESS = "GET_CLIENT_DETAIL_SUCCESS";
export const GET_CLIENT_DETAIL_FAILURE = "GET_CLIENT_DETAIL_FAILURE";

export const CLIENT_STATE_RESET = "CLIENT_STATE_RESET";
