import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import DashboardClientModule from "../../modules/Client/Dashboard/DashboardModule";
import { clientProjectOverviewAction } from "../../../redux/action/projectAction";
import { getProjectClientAction } from "../../../redux/action/orderAction";
import { getWinnerReleaseAction } from "../../../redux/action/releaseAction";

const DashboardClientPage = () => {
  const dispatch = useDispatch();
  const orderParams = {
    perpage: 5,
    page: 1,
    orderDir: "desc",
  };
  useEffect(() => {
    titlePage({
      title: "IRIS - Dashboard Client",
    });
    const params = {
      page: 1,
      perpage: 5,
    };
    dispatch(getWinnerReleaseAction(params));
    dispatch(clientProjectOverviewAction());
    dispatch(getProjectClientAction(orderParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="service-page">
      <DashboardClientLayout title="Dashboard" activeMenu="dashboard">
        <DashboardClientModule />
      </DashboardClientLayout>
    </div>
  );
};

export default DashboardClientPage;
