/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import {
  getBriefProjectPmAction,
} from "../../../../../redux/action/projectAction";
import { TableLoad } from "../../../../components/loading/loadingContent";
import TextBody from "../../../../components/datatableBody/TextBody";
import DateBody from "../../../../components/datatableBody/DateBody";
import DataEmpty from "../../../../components/emptyState/dataEmpty";
import AdminPaginate from "../../../../components/paging/pagination";
// import NumberBody from "../../../../components/datatableBody/NumberBody";
import FilterListProjectBrief from "./Components/FilterListProjectBrief";

const ListBriefRelease = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dataTable, setDataTable] = useState([]);

  const {
    dataGetBriefProjectPm,
    totalPage,
    loadingGetBriefProjectPm,
  } = useSelector((state) => state.project);

  // const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    if (dataGetBriefProjectPm) {
      setDataTable(dataGetBriefProjectPm);
    }
  }, [dataGetBriefProjectPm]);


  const companyBodyTemplate = (item) => {
    return (
      <Link to={`/admin/pm/detail-brief-release-client/${item?._id}`}>
        <TextBody
          text={item?.clientCompany.name}
          link={`/admin/pm/detail-brief-release-client/${item?._id}`}
        />
      </Link>
    );
  };
  const createdBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.createdBy?.name}
        link={`/admin/pm/detail-brief-release-client/${item?._id}`}
      />
    );
  };

  const categoryBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.releaseCategory}
        link={`/admin/pm/detail-brief-release-client/${item?._id}`}
      />
    );
  };

  // const phoneBodyTemplate = (item) => {
  //   return (
  //     <NumberBody
  //       number={item?.phoneNumber || 0}
  //       link={`/admin/pm/detail-brief-release-client/${item?._id}`}
  //     />
  //   );
  // };

  const dateBodyTemplate = (item) => {
    return (
      <DateBody
        date={item?.createdAt}
        link={`/admin/pm/detail-brief-release-client/${item?._id}`}
      />
    );
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      category: parsed?.category || "",
      type: parsed?.type || "",
      orderField: parsed?.order || "",
      orderDir:
        parsed?.order === "createdAt" || !parsed?.order ? "desc" : "asc",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getBriefProjectPmAction(params));

    history.push(
      `/admin/pm/brief-release-client?search=${parsed?.search ||
        ""}&type=${parsed?.type || ""}&category=${parsed.category ||
        ""}&order=${parsed?.order || ""}&page=${page + 1}`
    );
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Brief Rilis Klien
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterListProjectBrief parsed={parsed} />
          {loadingGetBriefProjectPm ? (
            <TableLoad />
          ) : (
            <>
              {dataTable?.length < 1 || dataTable === null ? (
                <DataEmpty />
              ) : (
                <DataTable
                  value={dataTable}
                  className="no-padding"
                >
                  <Column
                    header="Nama Perusahaan"
                    body={companyBodyTemplate}
                    headerClassName="project-name"
                  ></Column>
                  <Column
                    header="Dibuat Oleh"
                    headerClassName="project-date"
                    body={createdBodyTemplate}
                  ></Column>
                  <Column
                    header="Kategori Rilis"
                    headerClassName="project-writer"
                    body={categoryBodyTemplate}
                  ></Column>
                  {/* <Column
                    header="No. Telepon"
                    headerClassName="project-creator"
                    body={phoneBodyTemplate}
                  ></Column> */}
                  <Column
                    header="Tanggal Dibuat"
                    headerClassName="project-category"
                    body={dateBodyTemplate}
                  ></Column>
                </DataTable>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListBriefRelease;
