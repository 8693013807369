/* eslint-disable jsx-a11y/alt-text */
import React, { createRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import dayjs from "dayjs";
import "dayjs/locale/id";

import iconDownload from "../../../assets/img/download-blue.svg";

import ReactToPdf from "react-to-pdf";
// import { Document, Packer } from "docx";
// import { saveAs } from "file-saver";

import FullContentLoader from "../../../components/loading/fullContentLoader";

import {
  updateStatusProjectAction,
  getArticleAction,
  getLogArticleAction,
} from "../../../../redux/action/projectAction";

const DialogReview = (props) => {
  const dispatch = useDispatch();
  const { dataGetArticle, loadingGetArticle, dataLogArticle } = useSelector(
    (state) => state.project
  );
  useEffect(() => {
    if (props?.projectId !== null) {
      dispatch(getArticleAction(props?.projectId));
      dispatch(getLogArticleAction(props?.projectId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectId]);

  const renderFooter = () => {
    return (
      <div className="d-flex p-jc-end">
        <ReactToPdf
          scale="0.75"
          targetRef={pdfRef}
          filename="press-release.pdf"
          x={10.5}
          y={10.5}
        >
          {({ toPdf }) => (
            <button className="btn-download" onClick={toPdf}>
              <img src={iconDownload} />
              Download Tulisan
            </button>
          )}
        </ReactToPdf>
        <Button
          label="Request Revisi"
          onClick={submitRevision}
          disabled={dataGetArticle?.status !== "sent"}
          className="p-button-rounded"
        />
      </div>
    );
  };

  const pdfRef = createRef();
  //   const saveDocumentToFile = (doc, fileName) => {
  //     const packer = new Packer();
  //     const mimeType =
  //       "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
  //     packer.toBlob(doc).then(blob => {
  //       const docblob = blob.slice(0, blob.size, mimeType);
  //       saveAs(docblob, fileName);
  //     });
  //   }

  //  const generateWordDocument = (event)=> {
  //     event.preventDefault();
  //     let doc = new Document();
  //     saveDocumentToFile(doc, "New Document.docx");
  //   }

  const submitRevision = (e) => {
    e.preventDefault();
    const data = { status: "revision" };
    const query = "";
    dispatch(updateStatusProjectAction(props?.projectId, data, query));
  };

  return (
    <div className="dialog-invite">
      <div className="card">
        <Dialog
          header="PREVIEW TULISAN"
          visible={props.dialogReview}
          style={{ width: "800px" }}
          footer={renderFooter()}
          onHide={props.closeReview}
        >
          <div className="p-text-left review-wrapper" ref={pdfRef}>
            {loadingGetArticle ? (
              <>
                <FullContentLoader />
                <FullContentLoader />
                <FullContentLoader />
              </>
            ) : (
              <>
                <h2 className="title-wrapp">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${dataGetArticle?.articleTitle}`,
                    }}
                  />
                </h2>

                {dataGetArticle?.articleImage && (
                  <div className="image-wrapp">
                    <img src={dataGetArticle?.articleImage} />
                  </div>
                )}
                <div className="content-wrapp">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${dataGetArticle?.articleContent}`,
                    }}
                  />
                </div>
                <div className="history-wrapp">
                  <h6>Riwayat Aktivitas</h6>
                  {dataLogArticle?.map((item) => (
                    <div className="history-list" key={item?.id}>
                      <strong>
                        {dayjs(item?.date?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY HH:mm")}
                      </strong>{" "}
                      - {item?.message}
                    </div>
                  ))}
                  {dataLogArticle?.length < 1 && (
                    <div className="history-list">Belum ada aktivitas</div>
                  )}
                </div>
              </>
            )}
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default DialogReview;
