import React from "react";
import { useHistory } from "react-router-dom";
import successImage from "../../../assets/img/iso-success.svg";
import ButtonDefault from "../../../components/button/ButtonDefault";

function SuccessPaymentModule() {
  const history = useHistory();
  return (
    <div className="p-d-flex p-jc-center">
      <div className="col-lg-8 col-md-8 col-sm-12">
        <div className="card card-custom mb-4 success-payment">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label font-weight-bolder text-dark">
                Konfirmasi Pembayaran Berhasil
              </span>
            </h3>
          </div>
          <div className="card-body">
            <div className="p-d-flex p-jc-center">
              <img src={successImage} alt="success" />
            </div>
            <h2>Bukti Pembayaran Anda Berhasil Dikirim</h2>
            <p>
              Terima kasih, bukti pembayaran sudah terkirim. Pesanan anda akan
              segera kami proses maksimal 3 hari kerja. Kami akan mengirimkan
              pesan ke email anda setelah pesanan sudah terkonfirmasi.
            </p>
            <div className="p-d-flex p-jc-center">
              <ButtonDefault
                type="rounded"
                label="HALAMAN PROJECT"
                onClick={() => history.push("/client/my-order/confirm")}
                className="p-button p-button-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessPaymentModule;
