/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import DataUser from "./Components/ListUser.json";
import ActionBody from "../../../components/datatableBody/ActionBody";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import FilterListProject from "./Components/FilterListProject";

const ListVerifiedModule = () => {
  const statusBodyTemplate = (item) => {
    return (
      <span className={`badge wide ${item.statusclass}`}>{item.status}</span>
    );
  };

  const actionBodyTemplate = (item) => {
    return (
      <ActionBody
        linkDetail={`/admin/pm/detail-project/${item?._id}`}
        linkEdit={`/admin/pm/edit-project/${item?._id}`}
        id={item?._id}
        name={item?.title}
      />
    );
  };

  return (
    <div className="card card-custom">
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            List User
          </span>
        </h3>
      </div>
      <div className="card-body pt-0 pb-3">
        <FilterListProject />
        <DataTable value={DataUser.data} className="no-padding">
          <Column
            header="Tanggal"
            field="date"
            headerClassName="project-name"
            bodyClassName="text-grey"
          ></Column>
          <Column header="Nama" field="name"></Column>
          <Column header="Email" field="email"></Column>
          <Column header="No.Telepon" field="nohp"></Column>
          <Column header="Status" body={statusBodyTemplate}></Column>
          <Column headerClassName="action" body={actionBodyTemplate}></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default ListVerifiedModule;
