import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RadioButton } from "primereact/radiobutton";

import { getProjectPmAction } from "../../../../../redux/action/projectAction";

function PopupFilter(props) {
  const { show, parsed, onHide } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [status, setStatus] = useState("");

  const submitFilter = () => {
    const params = {
      q: parsed?.search || "",
      status: status,
      page: 1,
      perpage: 10,
    };
    history.push(
      `/admin/finance/list-project?search=${parsed.search ||
        ""}&status=${status}&page=1`
    );
    dispatch(getProjectPmAction(params));
    onHide();
  };

  const resetFilter = () => {
    const params = {
      q: parsed.search,
      status: "",
      page: 1,
      perpage: 10,
    };
    dispatch(getProjectPmAction(params));
    setStatus("");
    onHide();
  };

  return (
    <div className={`popup-project-filter height-fit ${!show && "d-none"}`}>
      <div className="filter-body height-fit">
        <div className="sort-by">
          <h3 className="p-mb-3 p-mt-4">Status Project</h3>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status1"
              name="status"
              value=""
              onChange={(e) => setStatus(e.value)}
              checked={status === ""}
            />
            <label htmlFor="status1" className="p-mb-0">
              Semua
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status2"
              name="status"
              value="new"
              onChange={(e) => setStatus(e.value)}
              checked={status === "new"}
            />
            <label htmlFor="status2" className="p-mb-0">
              Baru
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status3"
              name="status"
              value="published"
              onChange={(e) => setStatus(e.value)}
              checked={status === "published"}
            />
            <label htmlFor="status3" className="p-mb-0">
              Terbit
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status4"
              name="status"
              value="ongoing"
              onChange={(e) => setStatus(e.value)}
              checked={status === "ongoing"}
            />
            <label htmlFor="status4" className="p-mb-0">
              Berjalan
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status5"
              name="status"
              value="done"
              onChange={(e) => setStatus(e.value)}
              checked={status === "done"}
            />
            <label htmlFor="status5" className="p-mb-0">
              Selesai
            </label>
          </div>
        </div>
      </div>
      <div className="filter-footer">
        <div className="button">
          <button className="p-btn reset" onClick={() => resetFilter()}>
            Reset
          </button>
          <button className="p-btn btn-primary" onClick={() => submitFilter()}>
            Terapkan
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupFilter;
