/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { 
  useDispatch, 
  useSelector 
} from "react-redux";
import { useHistory } from "react-router-dom";
import AddDetail from "./Components/ServiceAddDetail";
import AddBrief from "./Components/ServiceAddBrief";
import ProjectConfirmation from "./Components/ProjectConfirmation";
import DialogAlert from "../../../components/dialog/DialogAlert";
import dayjs from "dayjs";
// import { useParams } from "react-router";
import {
  postOrderBriefAction,
  postSaveBriefAction,
  resetOrderStateAction,
  resetSaveStateAction,
} from "../../../../redux/action/orderAction";

import { clientDataProjectAction } from "../../../../redux/action/utilsAction";
import AddStory from "./Components/AddStory";
import { getToken } from "../../../../redux/service/firebase/auth";
import firebase from "../../../../redux/service/firebase/firebase";

const CreateOrderModule = (props) => {
  const firestore = firebase.firestore();
  const dispatch = useDispatch();
  const history = useHistory();
  const [layout, setLayout] = useState("brief");
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [isSave, setDialogSave] = useState(false);
  const [dialogSuccessSave, setDialogSuccessSave] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const [qty, setQty] = useState({ value: 1, error: null });

  const { dataAddProjectClient } = useSelector((state) => state.utils);
  const { successPostOrderBrief } = useSelector((state) => state.order);
  const { successSaveOrderBrief } = useSelector((state) => state.save);
  const { dataDetailClient } = useSelector((state) => state.client);
  const { dataDetailProduct } = useSelector(
    (state) => state.service
  );

  const {
    dataGetProjectDetailClient
  } = useSelector((state) => state.project);

  useEffect(() => {
    if (!dataGetProjectDetailClient) {
      setLayout("brief")
    } else {
      setLayout("detail")
    }
  }, [dataGetProjectDetailClient])

  const { users } = useSelector((state) => state.auth);
  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(clientDataProjectAction(null));
    dispatch(resetOrderStateAction());
    dispatch(resetSaveStateAction());
    setDialogSuccess(false);
    if(dataGetProjectDetailClient) {
      history.push(`/client/invoice/${dataGetProjectDetailClient?.id}`);
    } else {
      history.push(`/client/invoice/${successPostOrderBrief?.data?.id}`);
    }
  };

  const handlePayment = async () => {
    // setLoading(true)
    try {
      const token = await getToken();
      const response = await fetch(`${process.env.REACT_APP_STORAGE_URL}/v1/client/orders/process-inquiry`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          orderId: successPostOrderBrief?.data?.id || dataGetProjectDetailClient?.id,
        })
      })
      const data = await response.json()
      console.log(data)
      setLoadingState(false);
      setDialogWarning(false);
      setDialogSuccess(true);
    } catch(error) {
      console.log(error)
      // setDialogWarning(false)
      // setDialogSuccessSave(true)
    }
  }
  
  const closeSuccessSave = (e) => {
    e.preventDefault();
    dispatch(clientDataProjectAction(null));
    dispatch(resetOrderStateAction());
    dispatch(resetSaveStateAction());
    setDialogSuccessSave(false);
    history.push("/client/order");
  };

  useEffect(() => {
    if (dataAddProjectClient !== null) {
      setQty({
        value: dataAddProjectClient.quantity,
        error: null,
      })
    }
  },[dataAddProjectClient])
  
  useEffect(() => {
    if (successPostOrderBrief) {
      setTimeout(() => {
        setLoadingState(false);
        setDialogWarning(false);
        setDialogSuccess(true);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successPostOrderBrief]);

  useEffect(() => {
    if (successSaveOrderBrief) {
      setTimeout(() => {
        setLoadingState(false);
        setDialogSave(false);
        setDialogSuccessSave(true);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSaveOrderBrief]);

  const handleSubmit = () => {
    setLoadingState(true);
    const paymentDeadline = new Date();
    paymentDeadline.setDate(paymentDeadline.getDate() + 1);
    const data = {
      searchKeyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: dataAddProjectClient.storyAngles,
      title: dataAddProjectClient?.title,
      category: dataAddProjectClient?.category,
      type: dataAddProjectClient?.type,
      thumbnail: process.env.REACT_APP_IMAGE_ARTICLE,
      description: dataAddProjectClient?.desc,
      deadlineDate: dayjs(dataAddProjectClient?.deadline).toDate(),
      releaseCategory: dataAddProjectClient?.releaseCategory,
      objective: dataAddProjectClient?.objective,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient?.releaseNote,
      winnerQuota: parseInt(dataAddProjectClient?.quota),
      clientName: dataDetailClient?.client?.name,
      clientEmail: users?.user?.email,
      clientId : dataDetailClient?.client?.clientId,
      companyAddress : dataDetailClient?.companyAddress,
      companyBusinessField : dataDetailClient?.businessField,
      companyId : users?.user?.companyId,
      companyName : users?.user?.companyName,
      paymentDeadline: paymentDeadline,
      phone : users?.user?.phone,
      ppn: Math.ceil(parseInt(dataDetailProduct?.price) * qty.value * 0.11),
      productCategory: dataDetailProduct?.category,
      productDetail: dataDetailProduct?.description,
      productName : dataDetailProduct.name,
      productPrice : parseInt(dataDetailProduct?.price),
      quantity : qty.value,
      totalPrice: Math.ceil(
        parseInt(dataDetailProduct?.price) * qty.value +
        parseInt(dataDetailProduct?.price) * qty.value * 0.11
      ),
    };
    dispatch(postOrderBriefAction(props.projectId, data));
  };
  
  const handleSave = async () => {
    setLoadingState(true);
    const paymentDeadline = new Date();
    paymentDeadline.setDate(paymentDeadline.getDate() + 1);
    const data = {
      searchKeyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: dataAddProjectClient.storyAngles,
      title: dataAddProjectClient?.title,
      category: dataAddProjectClient?.category,
      type: dataAddProjectClient?.type,
      thumbnail: process.env.REACT_APP_IMAGE_ARTICLE,
      description: dataAddProjectClient?.desc,
      deadlineDate: dayjs(dataAddProjectClient?.deadline).toDate(),
      releaseCategory: dataAddProjectClient?.releaseCategory,
      objective: dataAddProjectClient?.objective,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient?.releaseNote,
      winnerQuota: parseInt(dataAddProjectClient?.quota),
      clientName: dataDetailClient?.client?.name,
      clientEmail: users?.user?.email,
      clientId : dataDetailClient?.client?.clientId,
      companyAddress : dataDetailClient?.companyAddress,
      companyBusinessField : dataDetailClient?.businessField,
      companyId : users?.user?.companyId,
      companyName : users?.user?.companyName,
      paymentDeadline: paymentDeadline,
      phone : users?.user?.phone,
      ppn: Math.ceil(parseInt(dataDetailProduct?.price || dataGetProjectDetailClient?.price) * qty.value * 0.11),
      productCategory: dataDetailProduct?.category || dataGetProjectDetailClient?.productCategory,
      productDetail: dataDetailProduct?.description || dataGetProjectDetailClient?.productDetail,
      productName : dataDetailProduct?.name || dataGetProjectDetailClient?.productName,
      productPrice : parseInt(dataDetailProduct?.price|| dataGetProjectDetailClient?.price),
      quantity : qty.value,
      totalPrice: Math.ceil(
        parseInt(dataDetailProduct?.price || dataGetProjectDetailClient?.price) * qty.value +
        parseInt(dataDetailProduct?.price || dataGetProjectDetailClient?.price) * qty.value * 0.11
      ),
    };
    if (dataGetProjectDetailClient) {
      try {
        await firestore
          .collection("projects-inquiry")
          .doc(dataGetProjectDetailClient?.id)
          .update( data );
        await firestore
          .collection("orders-inquiry")
          .doc(dataGetProjectDetailClient?.id)
          .update( data );
        handlePayment()
      } catch (error) {
        console.error('Error updating document: ', error);
      }
    } else {
      dispatch(postSaveBriefAction(props.projectId, data));
    }
  };

  return (
    <>
      <div className="helper-warning d-flex">
        <div className="icon-info-yellow mr-3"></div>
        <p>
          Detail project anda belum lengkap. Silakan lengkapi detail project
          yang anda pesan untuk memulai pengerjaan
        </p>
      </div>
      <div className="card card-custom mb-4 brief-step">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <a href={ dataGetProjectDetailClient ? `/client/order-detail/draft/${dataGetProjectDetailClient?.id}` : "/#"}>
              <span className="card-label font-weight-bolder text-dark">
                <span className="icon-back mr-7"></span>{dataGetProjectDetailClient ? "Edit" : "Buat"} Brief Project
              </span>
            </a>
          </h3>
        </div>
        <div className="card-body pb-4">
          <div className="step-add">
            <ul>
              {!dataGetProjectDetailClient ? (
                <>
                  <li className="active">
                    <div className="step-number">
                      <span>1</span>
                    </div>
                    {dataDetailProduct?.category === "pr" ? "Press Release" : "Brief Project"}
                  </li>
                  {dataDetailProduct?.category === "pr" ? (
                    <li
                      className={
                        layout === "story"|| layout === "detail" || layout === "checkout"
                          ? "active"
                          : "none"
                      }
                    >
                      <div className="step-number">
                        <span>2</span>
                      </div>
                      Story Angle
                    </li> 
                  ) : null}
                </>
              ) : null}
              <li
                className={
                  layout === "detail" || layout === "checkout"
                    ? "active"
                    : "none"
                }
              >
                <div className="step-number">
                  <span>{dataGetProjectDetailClient ? "1" : dataDetailProduct?.category === "article" ? "2" : "3"}</span>
                </div>
                Detail Project
              </li>
              <li
                className={
                  layout === "checkout"
                    ? "active"
                    : "none"
                }
              >
                <div className="step-number">
                  <span>{dataGetProjectDetailClient ? "2" : dataDetailProduct?.category === "article" ? "3" : "4"}</span>
                </div>
                CheckOut
              </li>
            </ul>
          </div>
        </div>
      </div>
      {layout === "brief" && <AddBrief setBrief={() => setLayout(dataDetailProduct?.category === "pr" ? "story" : "detail")} />}
      {layout === "story" && <AddStory submitAngle={() => setLayout("detail")} />}
      {layout === "detail" && (
        <AddDetail
          submitData={() => setLayout("checkout")}
          goBack={() => setLayout("story")}
          setBrief={() => setLayout("checkout")}
        />
      )}
      {layout === "checkout" && (
        <ProjectConfirmation 
          goBack={() => setLayout("detail")} 
          onSubmit={() => setDialogWarning(true)}
          onSave={() => setDialogSave(true)}
        />
      )}
      <DialogAlert
        type={"warning"}
        actionDialog={dataGetProjectDetailClient ? handleSave : handleSubmit}
        actionText={"KONFIMASI"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Konfirmasi Pemesanan Layanan"}
        text={loadingState ? "Draft Sedang Dibuat. Mohon Tunggu.." : "Anda yakin ingin melakukan konfirmasi pemesanan layanan?"}
        loading={loadingState && successPostOrderBrief}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={closeSuccess}
        // title={"Berhasil Menyimpan"}
        actionText={"LIHAT INVOICE"}
        text={"Pesanan Layanan Berhasil Dibuat"}
      />
      <DialogAlert
        type={"warning"}
        actionDialog={handleSave}
        actionText={"KONFIMASI"}
        visible={isSave}
        closeDialog={() => setDialogSave(false)}
        title={"Simpan brief?"}
        text={"Anda yakin ingin menyimpan brief terlebih dahulu?"}
        loading={loadingState || successSaveOrderBrief}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccessSave}
        closeDialog={closeSuccessSave}
        title={"Berhasil Menyimpan"}
        // actionText={"LIHAT INVOICE"}
        text={"Pesanan Layanan Berhasil Disimpan"}
      />
    </>
  );
};

export default CreateOrderModule;
