/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

import { LandingHeaderLoad } from "../loading/loadingContent";
import Dropdown from "react-bootstrap/Dropdown";
import { Navbar } from "react-bootstrap";
import { Nav } from "react-bootstrap";

import {
  logoutAction,
  myProfileAction,
  resetStateAuthAction,
} from "../../../redux/action/authAction";

import {
  updateNotificationAction,
  resetUtilsStateAction,
} from "../../../redux/action/utilsAction";
import firebase from "../../../redux/service/firebase/firebase";

import emptyNotif from "../../assets/img/empty-list-notif.png";
import { isDefaultPhoto } from "../../helpers/profileHelper";

const HeaderDashboardUser = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const firestore = firebase.firestore();

  const [url, setUrl] = useState("");
  const [mobileMenu, setMobileMenu] = useState(false);
  const [notifList, setNotifList] = useState([]);
  const { loading, dataSite, users } = props;

  const { successUpdateNotif } = useSelector((state) => state.utils);

  const notifCount = notifList?.filter((item) => item.isRead === false);

  const getListNotif = async () => {
    return await firestore
      .collection("userNotifications")
      .where("target.value", "in", [users?.uid])
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            id: doc.id,
          })
        );
        setNotifList(temp);
      });
  };

  useEffect(() => {
    if (users) {
      getListNotif();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (successUpdateNotif) {
      dispatch(resetUtilsStateAction());
      history.push(url.replace(process.env.REACT_APP_BASE_URL, ""));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateNotif]);

  useEffect(() => {
    dispatch(myProfileAction(users?.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const avatarLabel = users?.user?.name
    ? users?.user?.name.split("")[0]
    : users?.user?.email.split("")[0];

  const location = history.location.pathname?.split("/")[1];

  const logout = (e) => {
    e.preventDefault();
    dispatch(logoutAction());
  };

  const updateNotif = (data) => {
    dispatch(updateNotificationAction(data.id));
    setUrl(data.url);
  };

  const goSetting = (e) => {
    e.preventDefault();
    history.push("/settings");
    dispatch(resetStateAuthAction());
  };

  const goProfile = (e) => {
    e.preventDefault();
    history.push("/profile");
  };

  const goBalance = (e) => {
    e.preventDefault();
    history.push("/balance");
  };

  return (
    <div className="header-landing p-d-flex p-jc-center">
      <div className="p-grid p-d-flex p-jc-between p-ai-center items header-user">
        <div className="menu-mobile">
          <button
            className="btn-icon-outline"
            onClick={() => setMobileMenu(!mobileMenu)}
          >
            <span className="icon-menu" />
          </button>
          <div className={`menu-list-mobile ${mobileMenu ? "show" : "hide"}`}>
            <Link to="/dashboard">
              <span className={location === "dashboard" ? "active" : ""}>
                Dashboard
              </span>
            </Link>
            <Link to="/project">
              <span className={location === "project" ? "active" : ""}>
                Project
              </span>
            </Link>
          </div>
        </div>
        <div className="p-d-flex">
          {loading ? (
            <LandingHeaderLoad />
          ) : (
            <>
              <Navbar expand="lg">
                <Navbar.Brand>
                  <Link className="header-logo" to="/dashboard">
                    <img src={dataSite?.logo} alt="logo" />
                  </Link>
                </Navbar.Brand>

                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="menu">
                    {users?.user?.type === "writer" ? (
                      <Link to="/dashboard">
                        <span
                          className={location === "dashboard" ? "active" : ""}
                        >
                          Dashboard
                        </span>
                      </Link>
                    ) : (
                      <Link to="/client/dashboard">
                        <span
                          className={location === "dashboard" ? "active" : ""}
                        >
                          Dashboard
                        </span>
                      </Link>
                    )}
                    {users?.user?.type === "writer" && (
                      <Link to="/project">
                        <span
                          className={location === "project" ? "active" : ""}
                        >
                          Project
                        </span>
                      </Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </>
          )}
        </div>
        <div className="header-user-right">
          <Dropdown
            className={`notification ${
              notifCount?.length < 1 ? "not" : "has-noty"
            }`}
            alignRight={true}
          >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="notif">
                <span className="icon-notif"></span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-noty">
              <div className="title-menu">Notifikasi</div>
              {notifList?.length > 0 ? (
                notifList.map((item) => (
                  <Dropdown.Item
                    className={item.isRead ? "nonactive" : "active"}
                    key={item.id}
                    onClick={() =>
                      updateNotif({ id: item.id, url: item?.url?.web || "/" })
                    }
                  >
                    <span className="notif-box">
                      <span className="date">
                        <span></span>{" "}
                        {dayjs(item.createdAt.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY HH:mm")}
                      </span>
                      <span className="title-box">{item.heading}</span>
                      <span className="desc">{item.content}</span>
                    </span>
                  </Dropdown.Item>
                ))
              ) : (
                <div className="empty-notification">
                  <img src={emptyNotif} alt="empty" />
                  <span>Notifikasi Belum Tersedia </span>
                </div>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <div className="topbar-hr" />
          <Dropdown className="user-writer" alignRight={true}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="user-pict">
                {isDefaultPhoto(users?.user?.photoURL) ||
                !users?.user?.photoURL ? (
                  <div className="letter-pics">{avatarLabel}</div>
                ) : (
                  <img src={users?.user?.photoURL} alt="profile" />
                )}
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-user">
              <Dropdown.Item href="#" onClick={goProfile}>
                <div className="user-box">
                  <div className="user-pict">
                    {isDefaultPhoto(users?.user?.photoURL) ||
                    !users?.user?.photoURL ? (
                      <div className="letter-pics">{avatarLabel}</div>
                    ) : (
                      <img src={users?.user?.photoURL} alt="profile" />
                    )}
                  </div>
                  <div className="summary">
                    <div className="user-name">
                      {users?.user?.name || users?.user?.email}
                    </div>
                    <span className="user-role">{users?.user?.type}</span>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item href="#" onClick={goBalance}>
                <span className="icon-swap "></span> Balance
              </Dropdown.Item>
              <Dropdown.Item href="/#" onClick={goSetting}>
                <span className="icon-setting "></span> Pengaturan
              </Dropdown.Item>
              <Dropdown.Item href="/#" onClick={logout}>
                <span className="icon-logout "></span> Keluar
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
};

export default HeaderDashboardUser;
