import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import ListBriefRelease from "../../../modules/Admin/Superadmin/BriefRelease/ListBriefRelease";
import { getBriefProjectPmAction } from "../../../../redux/action/projectAction";
// import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";

const BriefReleasePM = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const { projectParams } = useSelector((state) => state.project);
  const { page, perpage } = projectParams;
  
  useEffect(() => {
    dispatch(
      getBriefProjectPmAction({
        page: parsed?.page ? parsed?.page : page,
        perpage,
        orderDir: "desc",
        orderField: "createdAt",
      })
    );
    titlePage({
      title: "IRIS - Brief Rilis Klien",
    });
    history.push("/admin/pm/brief-release-client");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Brief Rilis Klien" menu="release">
      <ListBriefRelease />
    </DashboardLayout>
  );
};

export default BriefReleasePM;
