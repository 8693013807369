/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import DialogAlert from "../../../components/dialog/DialogAlert";
import ListLoader from "../../../components/loading/listLoader";

import {
  updateProjectAction,
  resetStateProjectAction,
  resetProjectDetailData,
} from "../../../../redux/action/projectAction";
import {
  dataUpdateDetailAction,
  dataUpdateReleaseAction,
  resetDataUpdateProjectAction,
  dataAddDetailAction,
} from "../../../../redux/action/utilsAction";
import AddDetail from "./Components/FormAddDetail";
import AddRelease from "./Components/FormAddRelease";

const EditProjectModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [layout, setLayout] = useState("detail");
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogError, setDialogError] = useState(false);

  const { dataUpdateProject } = useSelector((state) => state.utils);
  const {
    loadingUpdateProject,
    dataGetDetailProject,
    successUpdateProject,
    loadingGetDetailProject,
  } = useSelector((state) => state.project);

  useEffect(() => {
    if (dataGetDetailProject !== null) {
      const dataDetail = {
        title: dataGetDetailProject?.title,
        category: dataGetDetailProject?.category,
        description: dataGetDetailProject?.description,
        price: dataGetDetailProject?.price,
        deadlineDate: dataGetDetailProject?.deadlineDate.toDate(),
        thumbnail: dataGetDetailProject?.thumbnail,
        type: dataGetDetailProject?.type,
        quota: dataGetDetailProject?.winnerQuota,
        company: {
          name: dataGetDetailProject?.clientCompany?.name,
          business: dataGetDetailProject?.clientCompany?.businessField,
          companyId: dataGetDetailProject?.clientCompany?.companyId,
          clientName: dataGetDetailProject?.clientName,
          clientEmail: dataGetDetailProject?.clientEmail,
          background: dataGetDetailProject?.clientCompany?.companyBackground,
        },
        pm: {
          pmName: dataGetDetailProject?.pmName,
          pmId: dataGetDetailProject?.pmId,
        },
      };

      const dataRelease = {
        searchKeyword: dataGetDetailProject?.searchKeyword,
        releaseCategory: dataGetDetailProject?.releaseCategory,
        releaseCategoryQuestion: dataGetDetailProject?.releaseCategoryQuestion,
        objective: dataGetDetailProject?.objective,
        attribution: dataGetDetailProject?.attribution,
        releaseNote: dataGetDetailProject?.releaseNote,
        target: {
          sex: dataGetDetailProject?.target?.sex,
          age: dataGetDetailProject?.target?.age,
          ses: dataGetDetailProject?.target?.ses,
          stakeholders: dataGetDetailProject?.target?.stakeholders,
        },
        briefUrl: dataGetDetailProject?.briefUrl,
      };

      dispatch(dataUpdateDetailAction(dataDetail));
      dispatch(dataUpdateReleaseAction(dataRelease));
      dispatch(
        dataAddDetailAction({
          type: dataGetDetailProject?.type,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetDetailProject]);

  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(resetDataUpdateProjectAction());
    dispatch(resetStateProjectAction());
    setDialogSuccess(false);
    history.push("/admin/pm/list-project");
  };

  const closeError = (e) => {
    e.preventDefault();
    dispatch(resetDataUpdateProjectAction());
    dispatch(resetStateProjectAction());
    dispatch(resetProjectDetailData());
    setDialogError(false);
    history.push("/admin/pm/list-project");
  };

  useEffect(() => {
    if (successUpdateProject) {
      setTimeout(() => {
        setDialogWarning(false);
        setDialogSuccess(true);
      }, 1500);
    }
  }, [successUpdateProject]);

  const handleSubmit = () => {
    const data = {
      title: dataUpdateProject?.detail?.title,
      category: dataUpdateProject?.detail?.category,
      type: dataUpdateProject?.detail?.type,
      thumbnail: dataUpdateProject?.detail?.thumbnail,
      description: dataUpdateProject?.detail?.description,
      price: parseInt(dataUpdateProject?.detail?.price),
      deadlineDate: dayjs(dataUpdateProject?.detail?.deadlineDate).toDate(),
      clientName: dataUpdateProject?.detail?.company?.clientName,
      jobNumber: dataUpdateProject?.brief?.jobNumber,
      clientEmail: dataUpdateProject?.detail?.company?.clientEmail,
      clientCompany: {
        name: dataUpdateProject?.detail?.company?.name,
        businessField: dataUpdateProject?.detail?.company?.business,
        companyBackground: dataUpdateProject?.detail?.company?.background,
        companyId: dataUpdateProject?.detail?.company?.companyId,
      },
      pmName: dataUpdateProject?.detail?.pm?.pmName,
      pmId: dataUpdateProject?.detail?.pm?.pmId,
      searchKeyword: dataUpdateProject?.release?.searchKeyword,
      releaseCategory: dataUpdateProject?.release?.releaseCategory,
      releaseCategoryQuestion:
        dataUpdateProject?.release?.releaseCategoryQuestion,
      objective: dataUpdateProject?.release?.objective,
      target: {
        sex: dataUpdateProject?.release?.target?.sex,
        age: dataUpdateProject?.release?.target?.age,
        ses: dataUpdateProject?.release?.target?.ses,
        stakeholders: dataUpdateProject?.release?.target?.stakeholders,
      },
      attribution: dataUpdateProject?.release?.attribution,
      releaseNote: dataUpdateProject?.release?.releaseNote,
      briefUrl: dataUpdateProject?.release?.briefUrl,
      winnerQuota: parseInt(dataUpdateProject?.detail?.quota),
    };

    dispatch(updateProjectAction(props.projectId, data));
  };
  return (
    <>
      <div className="card card-custom mb-4">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span
              className="card-label font-weight-bolder text-dark"
              onClick={() => history.goBack()}
            >
              <span className="icon-back mr-2"></span>Edit Project
            </span>
          </h3>
        </div>

        <div className="card-body pt-0 pb-4">
          <div className="step-add">
            <ul>
              <li className="active">
                <div className="step-number">
                  <span>1</span>
                </div>
                Detail Project
              </li>
              <li className={layout === "release" ? "active" : "none"}>
                <div className="step-number">
                  <span>2</span>
                </div>
                Press Release
              </li>
            </ul>
          </div>
        </div>
      </div>
      {loadingGetDetailProject ? (
        <ListLoader />
      ) : (
        <>
          {layout === "detail" && (
            <AddDetail
              setBrief={() => setLayout("release")}
              type="edit"
              isClient={dataGetDetailProject?.createdBy?.type === "client"}
            />
          )}
          {layout === "release" && (
            <AddRelease
              submitData={() => setDialogWarning(true)}
              type="edit"
              goBack={() => setLayout("detail")}
            />
          )}
        </>
      )}

      <DialogAlert
        type={"warning"}
        actionDialog={() => handleSubmit()}
        actionText={"PERBARUI"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Perbarui Data"}
        text={"Pastikan data yang anda masukkan benar"}
        loading={loadingUpdateProject}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={closeSuccess}
        title={"Berhasil Memperbarui"}
        text={"Data project berhasil diperbarui"}
      />
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={closeError}
        title={"Tidak Bisa Memperbarui"}
        text={"Project yang sedang berjalan tidak bisa diperbarui"}
      />
    </>
  );
};

export default EditProjectModule;
