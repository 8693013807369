import {
  GET_HELP_DETAIL_FAILURE,
  GET_HELP_DETAIL_SUCCESS,
  GET_HELP_DETAIL_REQUEST,
  GET_HELP_LIST_FAILURE,
  GET_HELP_LIST_SUCCESS,
  GET_HELP_LIST_REQUEST,
  UPDATE_HELP_FAILURE,
  UPDATE_HELP_SUCCESS,
  UPDATE_HELP_REQUEST,
  DELETE_HELP_FAILURE,
  DELETE_HELP_SUCCESS,
  DELETE_HELP_REQUEST,
  ADD_HELP_REQUEST,
  ADD_HELP_SUCCESS,
  ADD_HELP_FAILURE,
  POST_HELP_CHAT_REQUEST,
  POST_HELP_CHAT_SUCCESS,
  POST_HELP_CHAT_FAILURE,
  POST_HELP_NOTIF_REQUEST,
  POST_HELP_NOTIF_SUCCESS,
  POST_HELP_NOTIF_FAILURE,
  RESET_HELP_STATE,
} from "../constant/helpConstant";

const initialStateHelp = {
  helpParams: {
    q: "",
    page: 1,
    perpage: 10,
  },
  totalPage: "",

  loadingGetHelpList: false,
  dataGetHelpList: null,
  errGetHelpList: null,

  loadingGetDetailHelp: false,
  dataGetDetailHelp: null,
  errGetDetailHelp: null,

  loadingAddHelp: false,
  successAddHelp: false,
  errAddHelp: null,

  loadingUpdateHelp: false,
  successUpdateHelp: false,
  errUpdateHelp: null,

  loadingDeleteHelp: false,
  successDeleteHelp: false,
  errDeleteHelp: null,

  loadingAssignHelp: false,
  successAssignHelp: false,
  errAssignHelp: null,

  loadingPostHelpChat: false,
  successPostHelpChat: false,
  errPostHelpChat: null,

  loadingPostHelpNotif: false,
  successPostHelpNotif: false,
  errPostHelpNotif: null,
};

export const helpReducer = (state = initialStateHelp, action) => {
  switch (action.type) {
    case GET_HELP_LIST_REQUEST:
      return {
        ...state,
        loadingGetHelpList: true,
        errGetHelpList: null,
      };
    case GET_HELP_LIST_SUCCESS:
      return {
        ...state,
        loadingGetHelpList: false,
        dataGetHelpList: action.data,
        errGetHelpList: null,
        totalPage: action.data.totalpage,
      };
    case GET_HELP_LIST_FAILURE:
      return {
        ...state,
        loadingGetHelpList: false,
        errGetHelpList: action.data,
      };

    case ADD_HELP_REQUEST:
      return {
        ...state,
        loadingAddHelp: true,
        successAddHelp: false,
        errAddHelp: null,
      };
    case ADD_HELP_SUCCESS:
      return {
        ...state,
        loadingAddHelp: false,
        successAddHelp: true,
        errAddHelp: null,
      };
    case ADD_HELP_FAILURE:
      return {
        ...state,
        loadingAddHelp: false,
        successAddHelp: false,
        errAddHelp: action.data,
      };

    case GET_HELP_DETAIL_REQUEST:
      return {
        ...state,
        loadingGetDetailHelp: true,
        errGetDetailHelp: null,
      };
    case GET_HELP_DETAIL_SUCCESS:
      return {
        ...state,
        loadingGetDetailHelp: false,
        dataGetDetailHelp: action.data,
        errGetDetailHelp: null,
      };
    case GET_HELP_DETAIL_FAILURE:
      return {
        ...state,
        loadingGetDetailHelp: false,
        errGetDetailHelp: action.data,
      };

    case UPDATE_HELP_REQUEST:
      return {
        ...state,
        loadingUpdateHelp: true,
        successUpdateHelp: false,
        errUpdateHelp: null,
      };
    case UPDATE_HELP_SUCCESS:
      return {
        ...state,
        loadingUpdateHelp: false,
        successUpdateHelp: true,
        errUpdateHelp: null,
      };
    case UPDATE_HELP_FAILURE:
      return {
        ...state,
        loadingUpdateHelp: false,
        successUpdateHelp: false,
        errUpdateHelp: action.data,
      };

    case DELETE_HELP_REQUEST:
      return {
        ...state,
        loadingDeleteHelp: true,
        successDeleteHelp: false,
        errDeleteHelp: null,
      };
    case DELETE_HELP_SUCCESS:
      return {
        ...state,
        loadingDeleteHelp: false,
        successDeleteHelp: true,
        errDeleteHelp: null,
      };
    case DELETE_HELP_FAILURE:
      return {
        ...state,
        loadingDeleteHelp: false,
        successDeleteHelp: false,
        errDeleteHelp: action.data,
      };

    case POST_HELP_CHAT_REQUEST:
      return {
        ...state,
        loadingPostHelpChat: true,
        successPostHelpChat: false,
        errPostHelpChat: null,
      };
    case POST_HELP_CHAT_SUCCESS:
      return {
        ...state,
        loadingPostHelpChat: false,
        successPostHelpChat: true,
        errPostHelpChat: null,
      };
    case POST_HELP_CHAT_FAILURE:
      return {
        ...state,
        loadingPostHelpChat: false,
        successPostHelpChat: false,
        errPostHelpChat: action.data,
      };

    case POST_HELP_NOTIF_REQUEST:
      return {
        ...state,
        loadingPostHelpNotif: true,
        successPostHelpNotif: false,
        errPostHelpNotif: null,
      };
    case POST_HELP_NOTIF_SUCCESS:
      return {
        ...state,
        loadingPostHelpNotif: false,
        successPostHelpNotif: true,
        errPostHelpNotif: null,
      };
    case POST_HELP_NOTIF_FAILURE:
      return {
        ...state,
        loadingPostHelpNotif: false,
        successPostHelpNotif: false,
        errPostHelpNotif: action.data,
      };

    case RESET_HELP_STATE:
      return {
        ...state,
        successAddHelp: false,
        errAddHelp: null,
        successDeleteHelp: false,
        errDeleteHelp: null,
        successUpdateHelp: false,
        errUpdateHelp: null,
        successAssignHelp: false,
        errAssignHelp: null,
        loadingPostHelpNotif: false,
        successPostHelpNotif: false,
        errPostHelpNotif: null,
        loadingPostHelpChat: false,
        successPostHelpChat: false,
        errPostHelpChat: null,
      };

    default:
      return state;
  }
};
