/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import dayjs from "dayjs";

import DataEmpty from "../../../assets/img/data-empty.svg";
import BoxLoader from "../../../components/loading/boxLoader";
import firebase from "../../../../redux/service/firebase/firebase";

const WriterListProject = (props) => {
  const history = useHistory();
  const firestore = firebase.firestore();

  const [projectList, setProjectList] = useState([]);

  const getListProject = async () => {
    return await firestore
      .collection("writerProjects")
      .where("projectId", "==", props.projectIdUrl)
      .orderBy("updatedAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            id: doc.id,
          })
        );
        setProjectList(temp);
      });
  };

  useEffect(() => {
    if (props.projectIdUrl) {
      getListProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.projectIdUrl]);

  const { loadingGetMyProject } = useSelector((state) => state.project);

  const goToChat = (id) => {
    history.push(
      `/admin/pm/detail-project/${props.projectIdUrl}/chat?writer=${id}`
    );
  };

  return (
    <div className="box-info">
      <div className="header-box umkm-chat px-5 p-d-flex p-ai-center">
        <Link
          to={`/admin/pm/detail-project/${props?.projectIdUrl}`}
          className="btn-back"
        >
          <span className="icon-back"></span>
        </Link>
        <h3 className="p-mb-0 p-ml-2">Inbox</h3>
      </div>
      <div className="box-inner-left">
        <div className="inboxlist chat-pm">
          {loadingGetMyProject ? (
            <>
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
            </>
          ) : (
            <div className="card">
              {projectList?.length > 0 ? (
                projectList?.map((item) => (
                  <div
                    className={`inbox-item ${
                      props.writerId === item?.id ? "active" : ""
                    }`}
                    key={item?.id}
                    onClick={() => goToChat(item?.id)}
                  >
                    <div className="pict-container">
                      <div className="chat-avatar">
                        {item?.userData?.name?.substring(0, 1)}
                      </div>
                    </div>
                    <div className="inbox-item-detail">
                      <h5 className="p-mb-2">
                        {item?.userData?.name || "Belum Tersedia"}
                        {item?.chat?.isRead &&
                        item?.chat?.userType === "writer" ? (
                          <span className="dot-inbox" />
                        ) : (
                          <div />
                        )}
                      </h5>
                      <p className="p-mb-0">
                        {dayjs(item?.updatedAt.toDate()).format("DD/MM/YYYY")}
                      </p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state">
                  <div>
                    <img src={DataEmpty} alt="empty" />
                    <p>Data Tidak Ditemukan</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WriterListProject;
