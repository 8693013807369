/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPICAction } from "../../../../redux/action/clientPicAction";

// import ListLoader from "../../../../components/loading/listLoader";

import DialogAlert from "../../../components/dialog/DialogAlert";
import PICDetailForm from "./Components/PICDetailForm";

const PICEditModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dataGetDetailPIC, successUpdatePIC, errUpdatePIC } = useSelector(
    (state) => state.clientPic
  );
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogDanger, setDialogDanger] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  useEffect(() => {
    if (successUpdatePIC) {
      setDialogSuccess(true);
    }
  }, [successUpdatePIC]);

  useEffect(() => {
    if (errUpdatePIC) {
      if (
        errUpdatePIC?.message ===
        "The email address is already in use by another account."
      ) {
        setDialogMessage("Email telah digunakan oleh akun lain.");
      } else if (
        errUpdatePIC?.message ===
        "The user with the provided phone number already exists."
      ) {
        setDialogMessage("No. Handphone telah digunakan akun lain");
      } else {
        setDialogMessage(errUpdatePIC?.message);
      }
      setDialogDanger(true);
    }
  }, [errUpdatePIC]);

  const closeSuccess = () => {
    dispatch(resetPICAction());
    setDialogSuccess(false);
    history.goBack();
  };

  const closeDanger = () => {
    setDialogDanger(false);
    dispatch(resetPICAction());
  };

  return (
    <>
      <div className="p-d-flex p-jc-center detail-pm">
        <div className="card card-custom col-lg-6 col-md-6 col-sm-12">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Edit Tim
                </span>
              </button>
            </h3>
          </div>
          <PICDetailForm data={dataGetDetailPIC} type="edit" />
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Memperbarui Data Tim"}
        text={"Data Tim berhasil diperbarui"}
      />
      <DialogAlert
        type={"error"}
        visible={dialogDanger}
        closeDialog={() => closeDanger()}
        title={"Gagal Memperbarui Data Tim"}
        text={dialogMessage}
      />
    </>
  );
};

export default PICEditModule;
