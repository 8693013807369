import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";
import dayjs from "dayjs";
import "dayjs/locale/id";
import _ from "lodash";

import { Dropdown } from "primereact/dropdown";

import ButtonDefault from "../../../components/button/ButtonDefault";
import { writerTransactionAction } from "../../../../redux/action/transactionAction";
import StatusBadge from "../../../components/badge/StatusBadge";

const BalanceModule = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const typeOption = [
    { name: "Semua Transaksi", code: "" },
    { name: "Debit", code: "debit" },
    { name: "Kredit", code: "kredit" },
  ];
  const filterMonths = [
    { name: "Semua Bulan", code: 0 },
    { name: "Januari", code: 1 },
    { name: "Februari", code: 2 },
    { name: "Maret", code: 3 },
    { name: "April", code: 4 },
    { name: "Mei", code: 5 },
    { name: "Juni", code: 6 },
    { name: "Juli", code: 7 },
    { name: "Agustus", code: 8 },
    { name: "September", code: 9 },
    { name: "Oktober", code: 10 },
    { name: "November", code: 11 },
    { name: "Desember", code: 12 },
  ];
  const [yearList, yearListChange] = useState([]);
  const [yearState, yearStateChange] = useState(0);
  const [monthState, monthStateChange] = useState("");
  const [typeState, typeStateChange] = useState("");
  const [isShowHint, setIsShowHint] = useState(false);

  const { users } = useSelector((state) => state.auth);
  // eslint-disable-next-line no-unused-vars
  const { dataWriterTransaction, totalWriterTransaction } = useSelector(
    (state) => state.transaction
  );
  const { dataDetailUser } = useSelector((state) => state.user);

  useEffect(() => {
    if (users && users?.user?.balance < 500000) {
      setIsShowHint(true);
    } else if (users && !users?.user?.payoutStatus) {
      setIsShowHint(true);
    }
  }, [users]);

  useEffect(() => {
    if (dataDetailUser) {
      const userYear = dayjs(dataDetailUser?.createdAt?.toDate()).format(
        "YYYY"
      );
      generateYear(userYear);
    }
  }, [dataDetailUser]);

  const generateYear = (yearCreate) => {
    const now = dayjs().format("YYYY");
    const maxYear = parseInt(now);
    const minYear = yearCreate;
    let years = [];
    for (let i = maxYear; i >= minYear; i--) {
      years.push({ code: i, name: i });
    }
    yearStateChange(maxYear);
    yearListChange(years);
  };

  const filterType = useRef(
    _.debounce((type) => {
      const params = {
        page: 1,
        perPage: 10,
        type,
        year: yearState === 0 ? "" : yearState,
        month: monthState === 0 ? "" : monthState,
      };
      typeStateChange(type);
      dispatch(writerTransactionAction(params));
    }, 1000)
  ).current;

  const filterMonth = useRef(
    _.debounce((value) => {
      const params = {
        page: 1,
        perPage: 10,
        type: typeState,
        year: yearState === 0 ? "" : yearState,
        month: value === 0 ? "" : value,
      };
      monthStateChange(value);
      dispatch(writerTransactionAction(params));
    }, 1000)
  ).current;

  const filterYear = useRef(
    _.debounce((value) => {
      const params = {
        page: 1,
        perPage: 10,
        type: typeState,
        year: value === 0 ? "" : value,
        month: monthState === 0 ? "" : monthState,
      };
      yearStateChange(value);
      dispatch(writerTransactionAction(params));
    }, 1000)
  ).current;

  const loadMore = () => {
    const params = {
      page: 1,
      perPage: dataWriterTransaction?.length + 10,
      type: typeState,
      year: yearState === 0 ? "" : yearState,
      month: monthState === 0 ? "" : monthState,
    };
    dispatch(writerTransactionAction(params));
  };

  return (
    <div className="dashboard-content-wrapper bg-white">
      <div className="container">
        <div className="balance-upper">
          <div className="balance-grey-box">
            <div className="balance-text">
              <p>Balance Saya:</p>
              <NumberFormat
                prefix={"Rp "}
                thousandSeparator={"."}
                decimalSeparator={","}
                displayType="text"
                value={users?.user?.balance}
                renderText={(value) => <h4>{value}</h4>}
              />
            </div>
            <div className="button-balance-wrapper">
              <ButtonDefault
                label="TARIK DANA"
                type="rounded-bordered"
                onClick={() => history.push("/withdraw")}
                disabled={isShowHint}
              />
              <div className={`hint large ${!isShowHint ? "d-none" : ""} mt-4`}>
                <div className="tail top"></div>
                <p>{`Proses penarikan dana hanya bisa dilakukan satu bulan sekali dengan jumlah penarikan minimal Rp 500.000`}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="balance-bottom">
          <div className="balance-filter">
            <Dropdown
              value={typeState}
              options={typeOption}
              onChange={(e) => filterType(e.target.value)}
              optionLabel="name"
              optionValue="code"
              placeholder="Semua Transaksi"
            />
            <Dropdown
              value={monthState}
              options={filterMonths}
              onChange={(e) => filterMonth(e.target.value)}
              optionLabel="name"
              optionValue="code"
              placeholder="Semua Bulan"
            />
            <Dropdown
              value={yearState}
              options={yearList}
              onChange={(e) => filterYear(e.target.value)}
              optionLabel="name"
              optionValue="code"
              placeholder="2022"
            />
          </div>
          <div className="box-content table-wrapper">
            <div className="table-box balance-table">
              <div className="row-head d-hide-mobile">
                <div className="t-head">Tanggal</div>
                <div className="t-head">Jenis Transaksi</div>
                <div className="t-head">Nama Project</div>
                <div className="t-head">Nominal</div>
                <div className="t-head">Status</div>
              </div>
              {dataWriterTransaction?.length > 0 ? (
                <>
                  {dataWriterTransaction?.map((item) => (
                    <div className="row-body" key={item._id}>
                      <div className="t-body">
                      <div className="m-label-name">Tanggal:</div>
                        {dayjs(item?.createdAt)
                          .locale("id")
                          .format("DD MMMM YYYY")}
                      </div>
                      <div className="t-body">
                      <div className="m-label-name">Jenis Transaksi:</div>
                        {item?.type === "debit"
                          ? "Tarik Dana"
                          : "Insentive Project"}
                      </div>
                      <div className="t-body">
                      <div className="m-label-name">Nama Project:</div>
                        {item?.type === "debit" ? "-" : item?.projectName}
                      </div>
                      <div className="t-body">
                      <div className="m-label-name">Nominal:</div>
                        <NumberFormat
                          prefix={"Rp "}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={item.amount}
                          renderText={(value) => (
                            <p
                              className={
                                item?.type === "debit" ? "danger" : "success"
                              }
                            >
                              {item?.type === "debit"
                                ? `-${value} (D)`
                                : `+${value} (K)`}
                            </p>
                          )}
                        />
                      </div>
                      <div className="t-body">
                      <div className="m-label-name">Status:</div>
                        <StatusBadge user="writer" status={item?.status} />
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="row-body">
                  <div className="t-body p-mx-auto end-data">
                    <p className="text-grey mb-0">Data tidak ditemukan</p>
                  </div>
                </div>
              )}
              {dataWriterTransaction?.length > 10 && (
                <div className="row-body">
                  <div className="t-body p-mx-auto end-data">
                    <button className="button-more" onClick={() => loadMore()}>
                      Lihat Lainnya
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BalanceModule;
