import React from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";

const ActionBody = (props) => {
  const { number, isPrice, link, text } = props;
  const history = useHistory();
  return (
    <div
      className={`number-table ${link ? "link" : "no-link"}`}
      onClick={() => history.push(link)}
    >
      {isPrice ? (
        <NumberFormat
          prefix={"Rp "}
          thousandSeparator={"."}
          decimalSeparator={","}
          displayType="text"
          value={number}
        />
      ) : (
        <NumberFormat
          thousandSeparator={"."}
          decimalSeparator={","}
          displayType="text"
          value={number}
          renderText={(value) => (
            <p className="mb-0">{`${value} ${text ? text : ""}`}</p>
          )}
        />
      )}
    </div>
  );
};

export default ActionBody;
