import React, { useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { filterSearch } from "../../../../helpers/phoneNumber";

import { getUsersAction } from "../../../../../redux/action/userAction";

const FilterListUser = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { parsed } = props;
  const [filterValue, setFilterValue] = useState({
    order: "",
  });

  const orderItems = [
    { name: "Pembaruan Terkini", code: "updatedAt" },
    { name: "Nama Pengguna", code: "name" },
    { name: "Email", code: "email" },
    { name: "Telepon", code: "phone" },
  ];

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: filterSearch(search),
        page: 1,
        perpage: 10,
        orderField: parsed.sort,
        orderDir: "desc",
      };

      history.push(
        `/admin/pm/list-user/verified?search=${search ||
          ""}&sort=${parsed.sort || ""}&order=desc&page=1`
      );
      dispatch(getUsersAction(params));
    }, 1000)
  ).current;

  const filterHandler = (data) => {
    setFilterValue(data);
    const params = {
      q: parsed.search,
      page: 1,
      perpage: 10,
      orderField: data.order,
      orderDir: data.order === "updatedAt" ? "desc" : "asc",
    };

    history.push(
      `/admin/pm/list-user/verified?search=${parsed.search ||
        ""}&sort=${data.order || ""}&order=${
        data.order === "updatedAt" ? "desc" : "asc"
      }&page=1`
    );
    dispatch(getUsersAction(params));
  };

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter">
        <div className="filter-field">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block search-inputtext"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <p>
            Cari berdasarkan <span>Nama atau Email</span>
          </p>
        </div>
        <div className="filter-wrapp">
          <div className="sort-filter">
            <Dropdown
              value={filterValue.order}
              options={orderItems}
              onChange={(e) =>
                filterHandler({
                  ...filterValue,
                  order: e.target.value,
                })
              }
              optionLabel="name"
              optionValue="code"
              placeholder="Tampilkan semua"
            />

            <small className="p-d-block">Urutkan Berdasarkan</small>
          </div>
        </div>
      </div>
      <Link
        to="/admin/pm/add-user"
        className="p-button p-button-primary button-link"
      >
        Tambah Penulis
      </Link>
    </div>
  );
};

export default FilterListUser;
