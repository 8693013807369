import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getTermAction } from "../../../redux/action/landingpageAction";
import { titlePage } from "../../helpers/titleHead";
import StaticLayout from "../../layouts/staticLayout";
import { TextLoad } from "../../components/loading/loadingContent";

const TermsPage = () => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const { dataGetTerm, loadingGetTerm } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - Terms & Conditions",
    });
    dispatch(getTermAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticLayout>
      {loadingGetTerm ? (
        <div className="loading-text">
          <TextLoad />
        </div>
      ) : (
        <div className="static-content-wrapper">
          <div className="title-static">
            <h1>Terms and Conditions</h1>
          </div>
          <div className="body">
            <div
              dangerouslySetInnerHTML={{
                __html: `${dataGetTerm?.termAndCondition}`,
              }}
            />
          </div>
        </div>
      )}
    </StaticLayout>
  );
};

export default TermsPage;
