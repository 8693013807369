import React from "react";

const RegisterBenefitModule = () => {
  return (
    <div className="p-col-6 p-px-0 p-py-0 benefit">
      <div className="auth-content left">
        <div className="box-content">
          <h1>Keunggulan Layanan IRIS Membangun Citra Perusahaan</h1>
          <p>
            Mulai langkah tepat membangun popularitas dan kredibilitas
            perusahaan di media sekarang!
          </p>
          <ul>
            <li>
              <span className="icon-deal"></span>Layanan pembuatan press release
              dan distribusi media dalam satu platform
            </li>
            <li>
              <span className="icon-happy"></span>Fitur yang mudah untuk
              pemesanan rilis, brief ke penulis, review tulisan, dan approval
            </li>
            <li>
              <span className="icon-connected"></span>IRIS bekerja sama secara
              resmi dengan media nasional dan media lokal di 40 kota.
            </li>
            <li>
              <span className="icon-pen"></span>IRIS memiliki penulis
              profesional sesuai keahlian dalam berbagai bidang dan dapat
              menyediakan semua jenis konten yang dibutuhkan klien.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RegisterBenefitModule;
