import firebase from "./firebase";
import fb from "firebase/app";

const firestore = firebase.firestore();

export const getMyProject = async (id) =>
  await firestore
    .collection("writerProjects")
    .where("writerId", "==", id)
    .orderBy("updatedAt", "desc")
    .get();

export const getDetailProject = async (id) =>
  await firestore
    .collection("projects")
    .doc(id)
    .get();

export const getArticle = async (id) =>
  await firestore
    .collection("writerProjects")
    .doc(id)
    .get();

export const updateArticle = async (id, data) => {
  return await firestore
    .collection("writerProjects")
    .doc(id)
    .update({
      ...data,
      articleUpdatedAt: fb.firestore.FieldValue.serverTimestamp(),
    });
};

export const getProjectWriters = async (id) =>
  await firestore
    .collection("writerProjects")
    .where("projectId", "==", id)
    .get();

export const createProject = async (data) =>
  await firestore
    .collection("projects")
    .doc()
    .set({ ...data, createdAt: fb.firestore.FieldValue.serverTimestamp() });

export const deleteProject = async (id) =>
  await firestore
    .collection("projects")
    .doc(id)
    .delete();

export const getLogArticle = async (id) =>
  await firestore
    .collection("writerProjects")
    .doc(id)
    .collection("statusLogs")
    .orderBy("date", "desc")
    .get();

export const countProject = async () =>
  await firestore
    .collection("projects")
    .doc("--stats--")
    .get();

export const updateCountProject = async (data) =>
  await firestore
    .collection("projects")
    .doc("--stats--")
    .update({ projectCount: data });

export const saveAsDraft = async (id, data) => {
  return await firestore
    .collection("writerProjects")
    .doc(id)
    .collection("draft")
    .doc("default")
    .set(data);
};
export const getDraft = async (id) => {
  return await firestore
    .collection("writerProjects")
    .doc(id)
    .collection("draft")
    .doc("default")
    .get();
};

export const updateProjectFs = async (id, data) =>
  await firestore
    .collection("projects")
    .doc(id)
    .update({
      ...data,
      updatedAt: fb.firestore.FieldValue.serverTimestamp(),
    });

export const getDetailMedia = async (id) =>
  await firestore
    .collection("medias")
    .doc(id)
    .get();

export const getDraftProject = async (id) =>
  await firestore
    .collection("projects-inquiry")
    .doc(id)
    .get();
