import React from "react";
import ContentLoader from "react-content-loader";

const ColumnLoader = (props) => (
  <ContentLoader
    width={"100%"}
    height={310}
    viewBox="0 0 100% 310"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    {...props}
  >
    <rect x={"0%"} y="9" rx="2" ry="2" width={"31.33%"} height="300" />
    <rect x={"33%"} y="9" rx="2" ry="2" width={"31.33%"} height="300" />
    <rect x={"66%"} y="9" rx="2" ry="2" width={"31.33%"} height="300" />
  </ContentLoader>
);

export default ColumnLoader;
