/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import HeaderLoginAdmin from "../../components/header/HeaderLoginAdmin";
import LoginAdminModule from "../../modules/Admin/AuthAdmin/LoginAdminModule";

import { titlePage } from "../../helpers/titleHead";

const LoginAdmin = (props) => {
  const { successEmailLogin, isLoggedIn, users } = useSelector(
    (state) => state.auth
  );
  const history = useHistory();

  useEffect(() => {
    titlePage({
      title: "IRIS - Masuk sebagai Admin",
    });
  }, []);

  useEffect(() => {
    if (successEmailLogin && isLoggedIn) {
      history.push("/admin/pm/list-project");
    }
  }, [history, isLoggedIn, successEmailLogin]);

  useEffect(() => {
    if (isLoggedIn && users?.user?.type === "writer") {
      history.push("/dashboard");
    }
    if (
      isLoggedIn &&
      (users?.user?.type === "pm" || users?.user?.type === "general_pm")
    ) {
      history.push("/admin/pm/list-project");
    }
    if (isLoggedIn && users?.user?.type === "client") {
      history.push("/client/dashboard");
    }
  });

  return (
    <div className="auth-admin">
      <HeaderLoginAdmin />
      <div className="auth-admin-content justify-content-center">
        <div className="content-wrapp">
          <LoginAdminModule />
        </div>
      </div>
    </div>
  );
};

export default LoginAdmin;
