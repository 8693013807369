import React, { useEffect } from "react";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import AddProductModule from "../../../modules/Admin/Superadmin/ProductManagement/AddProductModule";

const AddProductPage = () => {
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Produk",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Produk" menu="product">
      <AddProductModule />
    </DashboardLayout>
  );
};

export default AddProductPage;
