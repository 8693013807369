import { call, put } from "redux-saga/effects";
import {
  GET_PROJECTS_PM_FAILURE,
  GET_PROJECTS_PM_SUCCESS,
  GET_PROJECTS_WRITER_FAILURE,
  GET_PROJECTS_WRITER_SUCCESS,
  GET_MY_PROJECTS_FAILURE,
  GET_MY_PROJECTS_SUCCESS,
  GET_DETAIL_PROJECT_FAILURE,
  GET_DETAIL_PROJECT_SUCCESS,
  GET_ARTICLE_SUCCESS,
  GET_ARTICLE_FAILURE,
  UPDATE_ARTICLE_SUCCESS,
  UPDATE_ARTICLE_FAILURE,
  GET_PROJECT_USER_FAILURE,
  GET_PROJECT_USER_SUCCESS,
  UPDATE_STATUS_PROJECT_FAILURE,
  UPDATE_STATUS_PROJECT_SUCCESS,
  SUBMIT_ARTICLE_PROJECT_FAILURE,
  SUBMIT_ARTICLE_PROJECT_SUCCESS,
  GET_USER_INVITABLE_FAILURE,
  GET_USER_INVITABLE_SUCCESS,
  ASSIGN_PROJECT_USER_FAILURE,
  ASSIGN_PROJECT_USER_SUCCESS,
  JOIN_PROJECT_WITH_TOKEN_FAILURE,
  JOIN_PROJECT_WITH_TOKEN_SUCCESS,
  CREATE_PROJECT_FAILURE,
  CREATE_PROJECT_SUCCESS,
  JOIN_PROJECT_FAILURE,
  JOIN_PROJECT_SUCCESS,
  UPDATE_PROJECT_SUCCESS,
  UPDATE_PROJECT_FAILURE,
  DELETE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILURE,
  GET_LOG_ARTICLE_SUCCESS,
  GET_LOG_ARTICLE_FAILURE,
  COUNT_PROJECT_FAILURE,
  COUNT_PROJECT_SUCCESS,
  UPDATE_COUNT_PROJECT_FAILURE,
  UPDATE_COUNT_PROJECT_SUCCESS,
  GET_LIST_ARTICLES_SUCCESS,
  GET_LIST_ARTICLES_FAILURE,
  GET_DRAFT_SUCCESS,
  GET_DRAFT_FAILURE,
  SAVE_DRAFT_SUCCESS,
  SAVE_DRAFT_FAILURE,
  UPDATE_PROJECT_PM_STATUS_SUCCESS,
  UPDATE_PROJECT_PM_STATUS_FAILURE,
  UPDATE_ARTICLE_CLIENT_STATUS_SUCCESS,
  UPDATE_ARTICLE_CLIENT_STATUS_FAILURE,
  UPDATE_PROJECT_FS_SUCCESS,
  UPDATE_PROJECT_FS_FAILURE,
  SUPERUSER_JOIN_PROJECT_FAILURE,
  SUPERUSER_JOIN_PROJECT_SUCCESS,
  GET_PROJECT_ACTIVE_FAILURE,
  GET_PROJECT_ACTIVE_SUCCESS,
  CLIENT_PROJECT_OVERVIEW_FAILURE,
  CLIENT_PROJECT_OVERVIEW_SUCCESS,
  DELETE_ARTICLE_PROJECT_FAILURE,
  DELETE_ARTICLE_PROJECT_SUCCESS,
  GET_DETAIL_SAVE_PROJECT_SUCCESS,
  GET_DETAIL_SAVE_PROJECT_FAILURE,
  GET_BRIEF_PROJECTS_PM_SUCCESS,
  GET_BRIEF_PROJECTS_PM_FAILURE,
  GET_PROJECT_DETAIL_PM_SUCCESS,
  GET_PROJECT_DETAIL_PM_FAILURE,
  GET_PROJECT_DETAIL_CLIENT_SUCCESS,
  GET_PROJECT_DETAIL_CLIENT_FAILURE,
} from "../constant/projectConstant";
import {
  getProjectsPM,
  getProjectsWriter,
  updateStatusProject,
  submitArticleProject,
  getUserInvitable,
  joinProjectWithToken,
  inviteProjectUser,
  joinProject,
  updateProjectsPM,
  getListArticles,
  updateProjectPmStatus,
  updateArticleClientStatus,
  pmJoinProject,
  getProjectActive,
  clientProjectOverview,
  deleteWriterArticle,
  getBriefProjectsPM,
  getProjectDetailPM,
  getProjectDetailClient,
} from "../service/api/projectsApi";
import { getToken } from "../service/firebase/auth";
import {
  getMyProject,
  getDetailProject,
  getArticle,
  updateArticle,
  getProjectWriters,
  createProject,
  deleteProject,
  getLogArticle,
  countProject,
  updateCountProject,
  getDraft,
  saveAsDraft,
  updateProjectFs,
  getDraftProject,
} from "../service/firebase/project";

export function* getProjectsPMSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectsPM, token, action.params);
  if (ok) {
    yield put({ type: GET_PROJECTS_PM_SUCCESS, data, params: action.params });
  } else {
    yield put({ type: GET_PROJECTS_PM_FAILURE, data });
  }
}

export function* getProjectDetailPMSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectDetailPM, token, action.id);
  if (ok) {
    yield put({ type: GET_PROJECT_DETAIL_PM_SUCCESS, data });
  } else {
    yield put({ type: GET_PROJECT_DETAIL_PM_FAILURE, data });
  }
}

export function* getProjectDetailClientSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectDetailClient, token, action.id);
  if (ok) {
    yield put({ type: GET_PROJECT_DETAIL_CLIENT_SUCCESS, data });
  } else {
    yield put({ type: GET_PROJECT_DETAIL_CLIENT_FAILURE, data });
  }
}

export function* getBriefProjectsPMSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getBriefProjectsPM, token, action.params);
  if (ok) {
    yield put({ type: GET_BRIEF_PROJECTS_PM_SUCCESS, data, params: action.params });
  } else {
    yield put({ type: GET_BRIEF_PROJECTS_PM_FAILURE, data });
  }
}

export function* getProjectsWriterSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectsWriter, token, action.params);
  if (ok) {
    yield put({ type: GET_PROJECTS_WRITER_SUCCESS, data });
  } else {
    yield put({ type: GET_PROJECTS_WRITER_FAILURE, data });
  }
}

export function* getMyProjectSaga(action) {
  try {
    const res = yield call(getMyProject, action.uid);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    const resultData = { data: data, totalpage: Math.ceil(data.length / 10) };
    yield put({ type: GET_MY_PROJECTS_SUCCESS, data: resultData });
  } catch (err) {
    yield put({ type: GET_MY_PROJECTS_FAILURE, err });
  }
}

export function* getDetailProjectSaga(action) {
  try {
    const snapshot = yield call(getDetailProject, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_DETAIL_PROJECT_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_DETAIL_PROJECT_FAILURE, err });
  }
}

export function* getSaveDetailProjectSaga(action) {
  try {
    const snapshot = yield call(getDraftProject, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_DETAIL_SAVE_PROJECT_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_DETAIL_SAVE_PROJECT_FAILURE, err });
  }
}

export function* getArticleSaga(action) {
  try {
    const result = yield call(getArticle, action.id);
    const articleData = result.data();
    const data = { ...articleData, id: action.id };
    yield put({
      type: GET_ARTICLE_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_ARTICLE_FAILURE, err });
  }
}

export function* updateArticleSaga(action) {
  try {
    yield call(updateArticle, action.id, action.data);
    yield put({ type: UPDATE_ARTICLE_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_ARTICLE_FAILURE, err });
  }
}

export function* getProjectUserSaga(action) {
  try {
    const res = yield call(getProjectWriters, action.id);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    const resultData = { data: data, totalpage: Math.ceil(data.length / 10) };
    yield put({ type: GET_PROJECT_USER_SUCCESS, data: resultData });
  } catch (err) {
    yield put({ type: GET_PROJECT_USER_FAILURE, err });
  }
}

export function* updateStatusProjectSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    updateStatusProject,
    token,
    action.id,
    action.data,
    action.query
  );
  if (ok) {
    yield put({ type: UPDATE_STATUS_PROJECT_SUCCESS });
  } else {
    yield put({ type: UPDATE_STATUS_PROJECT_FAILURE, data });
  }
}

export function* submitArticleProjectSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    submitArticleProject,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: SUBMIT_ARTICLE_PROJECT_SUCCESS });
  } else {
    yield put({ type: SUBMIT_ARTICLE_PROJECT_FAILURE, data });
  }
}

export function* getUserInvitableSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    getUserInvitable,
    token,
    action.id,
    action.params
  );
  if (ok) {
    yield put({ type: GET_USER_INVITABLE_SUCCESS, data });
  } else {
    yield put({ type: GET_USER_INVITABLE_FAILURE, data });
  }
}

export function* assignProjectSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    inviteProjectUser,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: ASSIGN_PROJECT_USER_SUCCESS });
  } else {
    yield put({ type: ASSIGN_PROJECT_USER_FAILURE, data });
  }
}

export function* joinProjectWithTokenSaga(action) {
  const { ok, data } = yield call(joinProjectWithToken, action.data);
  if (ok) {
    yield put({ type: JOIN_PROJECT_WITH_TOKEN_SUCCESS });
  } else {
    yield put({ type: JOIN_PROJECT_WITH_TOKEN_FAILURE, data });
  }
}

export function* createProjectSaga(action) {
  try {
    yield call(createProject, action.data);
    yield put({ type: CREATE_PROJECT_SUCCESS });
  } catch (err) {
    yield put({ type: CREATE_PROJECT_FAILURE, err });
  }
}

export function* joinProjectSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(joinProject, token, action.id);
  if (ok) {
    yield put({ type: JOIN_PROJECT_SUCCESS });
  } else {
    yield put({ type: JOIN_PROJECT_FAILURE, data });
  }
}

export function* updateProjectSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    updateProjectsPM,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: UPDATE_PROJECT_SUCCESS });
  } else {
    yield put({ type: UPDATE_PROJECT_FAILURE, data });
  }
}

export function* deleteProjectSaga(action) {
  try {
    yield call(deleteProject, action.id);
    yield put({ type: DELETE_PROJECT_SUCCESS });
  } catch (err) {
    yield put({ type: DELETE_PROJECT_FAILURE, err });
  }
}

export function* getLogArticleSaga(action) {
  try {
    const res = yield call(getLogArticle, action.id);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({
      type: GET_LOG_ARTICLE_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_LOG_ARTICLE_FAILURE, err });
  }
}

export function* countProjectSaga() {
  try {
    const result = yield call(countProject);
    const data = result.data();
    yield put({ type: COUNT_PROJECT_SUCCESS, data });
  } catch (err) {
    yield put({ type: COUNT_PROJECT_FAILURE, err });
  }
}

export function* updateCountProjectSaga(action) {
  try {
    yield call(updateCountProject, action.data);
    yield put({ type: UPDATE_COUNT_PROJECT_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_COUNT_PROJECT_FAILURE, err });
  }
}

export function* getListArticlesSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getListArticles, token, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_LIST_ARTICLES_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_LIST_ARTICLES_FAILURE, data });
  }
}

export function* getDraftSaga(action) {
  try {
    const doc = yield call(getDraft, action.id);
    const draft = doc.data();
    const data = { ...draft, id: action.id };
    yield put({
      type: GET_DRAFT_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_DRAFT_FAILURE, err });
  }
}

export function* saveDraftSaga(action) {
  try {
    yield call(saveAsDraft, action.id, action.data);
    yield put({ type: SAVE_DRAFT_SUCCESS });
  } catch (err) {
    yield put({ type: SAVE_DRAFT_FAILURE, err });
  }
}

export function* updateProjectPmStateSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    updateProjectPmStatus,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: UPDATE_PROJECT_PM_STATUS_SUCCESS });
  } else {
    yield put({ type: UPDATE_PROJECT_PM_STATUS_FAILURE, data });
  }
}

export function* updateArticleClientStatusSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    updateArticleClientStatus,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: UPDATE_ARTICLE_CLIENT_STATUS_SUCCESS });
  } else {
    yield put({ type: UPDATE_ARTICLE_CLIENT_STATUS_FAILURE, data });
  }
}

export function* updateProjectFsSaga(action) {
  try {
    yield call(updateProjectFs, action.id, action.data);
    yield put({ type: UPDATE_PROJECT_FS_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_PROJECT_FS_FAILURE, err });
  }
}

export function* superuserJoinProjectSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(pmJoinProject, token, action.id, action.data);
  if (ok) {
    yield put({ type: SUPERUSER_JOIN_PROJECT_SUCCESS, data });
  } else {
    yield put({ type: SUPERUSER_JOIN_PROJECT_FAILURE, data });
  }
}

export function* getProjectActiveSaga() {
  const token = yield call(getToken);
  const { ok, data } = yield call(getProjectActive, token);
  if (ok) {
    yield put({ type: GET_PROJECT_ACTIVE_SUCCESS, data });
  } else {
    yield put({ type: GET_PROJECT_ACTIVE_FAILURE, data });
  }
}

export function* clientProjectOverviewSaga() {
  const token = yield call(getToken);
  const { ok, data } = yield call(clientProjectOverview, token);
  if (ok) {
    yield put({ type: CLIENT_PROJECT_OVERVIEW_SUCCESS, data });
  } else {
    yield put({ type: CLIENT_PROJECT_OVERVIEW_FAILURE, data });
  }
}

export function* deleteWriterArticleAction(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(deleteWriterArticle, token, action.id);
  if (ok) {
    yield put({ type: DELETE_ARTICLE_PROJECT_SUCCESS });
  } else {
    yield put({ type: DELETE_ARTICLE_PROJECT_FAILURE, data });
  }
}
