import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { titlePage } from "../../helpers/titleHead";
import LandingLayout from "../../layouts/landingLayout";
import ProductModule from "../../modules/Client/Product/ProductModule";

import { getTypeProductAction } from "../../../redux/action/landingpageAction";

const ProductPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    titlePage({
      title: "Homepage Services",
    });
    dispatch(getTypeProductAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <LandingLayout title="Service">
      <ProductModule />
    </LandingLayout>
  );
};

export default ProductPage;
