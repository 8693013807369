import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import CreateInfoModule from "../../../modules/Admin/Information/CreateInfoModule";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import { resetStateAdminAction } from "../../../../redux/action/adminAction";

const CreateInfoPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Informasi Terbaru",
    });
    dispatch(resetUtilsStateAction());
    dispatch(resetStateAdminAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Informasi Terbaru" menu="info">
      <CreateInfoModule />
    </DashboardLayout>
  );
};

export default CreateInfoPage;
