import api from "./api";

export const getProjectsPM = async (token, params) => {
  return api.get("/pm/projects", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectDetailPM = async (token, id) => {
  return api.get(`/pm/projects/detail/inquiry/${id}`, {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectDetailClient = async (token, id) => {
  return api.get(`/client/projects/detail/inquiry/${id}`, {}, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBriefProjectsPM = async (token, params) => {
  return api.get("/pm/projects/inquiry", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectsWriter = async (token, params) => {
  return api.get("/writer/projects", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserInvitable = async (token, id, params) => {
  return api.get(`/pm/projects/${id}/invitable-users`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateStatusProject = async (token, id, data, query) => {
  return api.post(`/pm/writer-project/${id}/update-status${query}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const submitArticleProject = async (token, id, data) => {
  return api.post(`/writer/writer-project/${id}/submit`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const inviteProjectUser = async (token, id, data) => {
  return api.post(`/pm/projects/${id}/invite-users`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const joinProjectWithToken = async (data) => {
  return api.post(`/writer/projects/join-with-token`, data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const joinProject = async (token, id) => {
  return api.post(
    `/writer/projects/${id}/join`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const pmJoinProject = async (token, id, data) => {
  return api.post(`/pm/projects/${id}/join`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateProjectsPM = async (token, id, data) => {
  return api.put(`/pm/projects/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getListArticles = async (token, params) => {
  return api.get(`/pm/writer-project/`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectClient = async (token, params) => {
  return api.get(`/client/projects`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectSaveClient = async (token, params) => {
  return api.get(`/client/projects/inquiry`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateProjectPmStatus = async (token, id, data) => {
  return api.put(`/pm/projects/${id}/update-status`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateArticleClientStatus = async (token, id, data) => {
  return api.put(`/client/writer-projects/${id}/update-status`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectFinance = async (token, params) => {
  return api.get(`/finance/projects`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getProjectActive = async (token) => {
  return api.get(
    `/pm/projects/active`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const clientProjectOverview = async (token) => {
  return api.get(
    `/client/projects/overview`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const deleteWriterArticle = async (token, id) => {
  return api.delete(
    `/pm/writer-project/${id}/delete`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const postProjectSave = async (token, data, id) => {
  return api.post(`/client/projects/${id}/brief/inquiry`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
