import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import firebase from "firebase/app";

import { Button } from "primereact/button";
import ButtonLoader from "../../../components/button/ButtonLoader";

import {
  phoneRegisterAction,
  verifyOtpAction,
} from "../../../../redux/action/authAction";

const RegisterOtpModule = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [otpValue, setOtpValue] = useState("");
  const [timeLeft, setTimeLeft] = useState(61);
  const [resendOtpView, setResendOtpView] = useState(false);
  const [otpError, setOtpError] = useState(null);

  const parsed = queryString.parse(location.search);
  const emailUrl = encodeURI(parsed.email);

  const { verificationOtp, loadingOtp, errOtp, dataInput } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    if (!timeLeft) {
      setResendOtpView(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "grecaptcha-button",
      {
        size: "invisible",
      }
    );
  }, []);

  useEffect(() => {
    if (errOtp) {
      setOtpError("Kode OTP tidak valid atau kadaluarsa");
    }
    if (errOtp && errOtp?.code === "auth/provider-already-linked") {
      setOtpError("Akun Sudah Terdaftar");
    }
  }, [errOtp]);

  const resendOtpHandler = (e) => {
    e.preventDefault();
    setTimeLeft(61);
    setResendOtpView(false);
    dispatch(phoneRegisterAction(window.recaptchaVerifier, dataInput));
  };

  const submitOtp = (e) => {
    e.preventDefault();
    dispatch(verifyOtpAction(verificationOtp, otpValue, dataInput, emailUrl));
  };

  return (
    <>
      <p>
        Silakan masukan kode OTP yang kami kirimkan ke nomor{" "}
        <strong>
          {dataInput?.phone_number?.length > 0 &&
            "*".repeat(dataInput?.phone_number?.length - 4) +
            dataInput?.phone_number
              .replace(/-/g, "")
              .replace(/_/g, "")
              .slice(-3)}
        </strong>
      </p>

      <div id="grecaptcha-button" />
      <form onSubmit={submitOtp}>
        <div className="p-field otp-code">
          <OtpInput
            value={otpValue}
            onChange={(value) => setOtpValue(value)}
            numInputs={6}
            containerStyle="otp-wrapp"
            inputStyle="p-inputtext"
            isInputNum
          />
        </div>
        {otpError !== null && (
          <small id="otp-number" className="p-error p-d-block p-text-right">
            {otpError}
          </small>
        )}
        {loadingOtp ? (
          <ButtonLoader />
        ) : (
          <>
            <Button
              onClick={submitOtp}
              label="Konfirmasi"
              className="p-button-rounded p-button-lg"
              type="submit"
            />
            <div className="py-5 p-text-center">
              {!resendOtpView ? (
                <span className="time-otp">
                  Tersisa <strong>{timeLeft} detik</strong> lagi
                </span>
              ) : (
                <p>
                  Tidak menerima kode OTP?{" "}
                  <a href="/#" onClick={resendOtpHandler}>
                    Kirim Ulang
                  </a>
                </p>
              )}
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default RegisterOtpModule;
