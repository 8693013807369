import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IsoLogin from "../../assets/img/iso-login.svg";
import AuthLayout from "../../layouts/authLayout";
import LoginEmail from "../../modules/User/Login/LoginEmailModule";

import { titlePage } from "../../helpers/titleHead";

const LoginClientPage = (props) => {
  const history = useHistory();

  const { isLoggedIn, successEmailLogin, users } = useSelector(
    (state) => state.auth
  );
  const { dataGetSettingSite } = useSelector((state) => state.landingpage);

  useEffect(() => {
    titlePage({
      title: "IRIS - Masuk",
    });
  }, []);

  useEffect(() => {
    if (
      successEmailLogin &&
      isLoggedIn &&
      (users?.user?.type === "client" || users?.user?.type === "pic")
    ) {
      history.push("/client/dashboard");
    }
  });

  return (
    <AuthLayout>
      <div className="auth-wrapper login">
        <div className="container">
          <div className="p-grid">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lg-0 p-md-0">
              <div className="auth-form p-d-flex p-jc-center">
                <div className="form-reg">
                  <div className="p-text-center client-login-header">
                    <h2>Selamat Datang di IRIS</h2>
                    <div className="iris-logo">
                      <img src={dataGetSettingSite?.logo} alt="" />
                    </div>
                  </div>
                  <div className="button-tab-wrapper" />
                  <LoginEmail />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-0 p-lg-0 p-md-0">
              <div className="iso-login">
                <img src={IsoLogin} alt="iso-login" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginClientPage;
