import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import InvoiceModule from "../../modules/Client/Payment/InvoiceModule";

import { orderClientDetailAction } from "../../../redux/action/orderAction";

function InvoicePage() {
  const dispatch = useDispatch();
  const queryParams = useParams();

  useEffect(() => {
    titlePage({
      title: "IRIS - Tagihan",
    });
    dispatch(orderClientDetailAction(queryParams.orderId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Tagihan" menu="invoice">
      <InvoiceModule orderId={queryParams.orderId} />
    </DashboardClientLayout>
  );
}

export default InvoicePage;
