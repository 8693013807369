import {
  GET_PROJECTS_PM_REQUEST,
  GET_BRIEF_PROJECTS_PM_REQUEST,
  GET_PROJECTS_WRITER_REQUEST,
  GET_MY_PROJECTS_REQUEST,
  GET_DETAIL_PROJECT_REQUEST,
  GET_ARTICLE_REQUEST,
  UPDATE_ARTICLE_REQUEST,
  GET_PROJECT_USER_REQUEST,
  UPDATE_STATUS_PROJECT_REQUEST,
  SUBMIT_ARTICLE_PROJECT_REQUEST,
  GET_USER_INVITABLE_REQUEST,
  ASSIGN_PROJECT_USER_REQUEST,
  JOIN_PROJECT_WITH_TOKEN_REQUEST,
  CREATE_PROJECT_REQUEST,
  JOIN_PROJECT_REQUEST,
  RESET_STATE_PROJECT,
  UPDATE_PROJECT_REQUEST,
  DELETE_PROJECT_REQUEST,
  GET_LOG_ARTICLE_REQUEST,
  UPDATE_USER_INVITABLE_PARAMS,
  RESET_PROJECT_DETAIL_DATA,
  COUNT_PROJECT_REQUEST,
  UPDATE_COUNT_PROJECT_REQUEST,
  GET_LIST_ARTICLES_REQUEST,
  SAVE_DRAFT_REQUEST,
  GET_DRAFT_REQUEST,
  UPDATE_PROJECT_PM_STATUS_REQUEST,
  UPDATE_ARTICLE_CLIENT_STATUS_REQUEST,
  UPDATE_PROJECT_FS_REQUEST,
  SUPERUSER_JOIN_PROJECT_REQUEST,
  GET_PROJECT_ACTIVE_REQUEST,
  CLIENT_PROJECT_OVERVIEW_REQUEST,
  DELETE_ARTICLE_PROJECT_REQUEST,
  GET_DETAIL_SAVE_PROJECT_REQUEST,
  GET_PROJECT_DETAIL_PM_REQUEST,
  GET_PROJECT_DETAIL_CLIENT_REQUEST,
} from "../constant/projectConstant";

export const getProjectPmAction = (params) => {
  return {
    type: GET_PROJECTS_PM_REQUEST,
    params,
  };
};

export const getProjectDetailPmAction = (id) => {
  return {
    type: GET_PROJECT_DETAIL_PM_REQUEST,
    id,
  };
};

export const getProjectDetailClientAction = (id) => {
  return {
    type: GET_PROJECT_DETAIL_CLIENT_REQUEST,
    id,
  };
};

export const getBriefProjectPmAction = (params) => {
  return {
    type: GET_BRIEF_PROJECTS_PM_REQUEST,
    params,
  };
};

export const getProjectWriterAction = (params) => {
  return {
    type: GET_PROJECTS_WRITER_REQUEST,
    params,
  };
};

export const getMyProjectAction = (uid) => {
  return {
    type: GET_MY_PROJECTS_REQUEST,
    uid,
  };
};

export const getDetailProjectAction = (id) => {
  return {
    type: GET_DETAIL_PROJECT_REQUEST,
    id,
  };
};

export const getSaveDetailProjectAction = (id) => {
  return {
    type: GET_DETAIL_SAVE_PROJECT_REQUEST,
    id,
  };
};

export const getArticleAction = (id) => {
  return {
    type: GET_ARTICLE_REQUEST,
    id,
  };
};

export const updateArticleAction = (id, data) => {
  return {
    type: UPDATE_ARTICLE_REQUEST,
    id,
    data,
  };
};

export const getProjectUserAction = (id) => {
  return {
    type: GET_PROJECT_USER_REQUEST,
    id,
  };
};

export const updateStatusProjectAction = (id, data, query) => {
  return {
    type: UPDATE_STATUS_PROJECT_REQUEST,
    id,
    data,
    query,
  };
};

export const submitArticleAction = (id, data) => {
  return {
    type: SUBMIT_ARTICLE_PROJECT_REQUEST,
    id,
    data,
  };
};

export const userInvitableAction = (id, params) => {
  return {
    type: GET_USER_INVITABLE_REQUEST,
    id,
    params,
  };
};

export const updateUserIvitableParams = (data) => {
  return {
    type: UPDATE_USER_INVITABLE_PARAMS,
    data,
  };
};

export const assignProjectAction = (id, data) => {
  return {
    type: ASSIGN_PROJECT_USER_REQUEST,
    id,
    data,
  };
};

export const joinProjectTokenAction = (data) => {
  return {
    type: JOIN_PROJECT_WITH_TOKEN_REQUEST,
    data,
  };
};

export const createProjectAction = (data) => {
  return {
    type: CREATE_PROJECT_REQUEST,
    data,
  };
};

export const resetStateProjectAction = () => {
  return {
    type: RESET_STATE_PROJECT,
  };
};

export const joinProjectAction = (id) => {
  return {
    type: JOIN_PROJECT_REQUEST,
    id,
  };
};

export const updateProjectAction = (id, data) => {
  return {
    type: UPDATE_PROJECT_REQUEST,
    id,
    data,
  };
};

export const deleteProjectAction = (id) => {
  return {
    type: DELETE_PROJECT_REQUEST,
    id,
  };
};

export const getLogArticleAction = (id) => {
  return {
    type: GET_LOG_ARTICLE_REQUEST,
    id,
  };
};

export const resetProjectDetailData = () => {
  return {
    type: RESET_PROJECT_DETAIL_DATA,
  };
};

export const countProjectAction = () => {
  return {
    type: COUNT_PROJECT_REQUEST,
  };
};

export const updateCountProjectAction = (data) => {
  return {
    type: UPDATE_COUNT_PROJECT_REQUEST,
    data,
  };
};

export const getListArticlesPmAction = (params) => {
  return {
    type: GET_LIST_ARTICLES_REQUEST,
    params,
  };
};

export const saveAsDraftAction = (id, data) => {
  return {
    type: SAVE_DRAFT_REQUEST,
    id,
    data,
  };
};

export const getDraftAction = (id) => {
  return {
    type: GET_DRAFT_REQUEST,
    id,
  };
};

export const updateProjectPmStatusAction = (id, data) => {
  return {
    type: UPDATE_PROJECT_PM_STATUS_REQUEST,
    id,
    data,
  };
};

export const updateArticleClientStatusAction = (id, data) => {
  return {
    type: UPDATE_ARTICLE_CLIENT_STATUS_REQUEST,
    id,
    data,
  };
};

export const updateProjectFsAction = (id, data) => {
  return {
    type: UPDATE_PROJECT_FS_REQUEST,
    id,
    data,
  };
};

export const superuserJoinProjectAction = (id, data) => {
  return {
    type: SUPERUSER_JOIN_PROJECT_REQUEST,
    id,
    data,
  };
};

export const getProjectActiveAction = () => {
  return {
    type: GET_PROJECT_ACTIVE_REQUEST,
  };
};

export const clientProjectOverviewAction = () => {
  return {
    type: CLIENT_PROJECT_OVERVIEW_REQUEST,
  };
};

export const deleteArticleProjectAction = (id) => {
  return {
    type: DELETE_ARTICLE_PROJECT_REQUEST,
    id,
  };
};
