import dayjs from "dayjs";
import { random } from "lodash-es";
import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { uploadCompanyProfileAction } from "../../../redux/action/userAction";

function UploadPdf(props) {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);
  const [errorFile, setErrorFile] = useState(null);
  const [fileNameState, setFileNameState] = useState("");

  useEffect(() => {
    if (props.value) {
      setFileNameState("Company Profile.pdf");
    }
  }, [props.value]);
  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".pdf",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        setErrorFile("Ukuran file lebih dari 5MB");
      } else if (acceptedFiles.length > 1) {
        setErrorFile("Silakan pilih satu file");
      } else {
        setErrorFile("");
        setFileNameState(acceptedFiles[0].name);

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/${props.filePath}/${times}/${users?.uid}/${fileName}`;

        dispatch(uploadCompanyProfileAction(acceptedFiles[0], path));
      }
    },
  });

  return (
    <>
      <div {...getRootProps({ className: "file-upload" })}>
        <input {...getInputProps()} />
        <span className="file-name">{fileNameState ? fileNameState : ""}</span>
        {props.loading ? (
          <span className="file-button">Mengunggah...</span>
        ) : (
          <span className="file-button" onClick={open}>
            {fileNameState ? "Ubah File" : "Pilih File"}
          </span>
        )}
      </div>
      {errorFile ? (
        <small id="error" className="p-error p-d-block">
          {errorFile}
        </small>
      ) : (
        <p className="text-note">
          Catatan: Format dokumen harus PDF dan ukuran maksimal 5MB
        </p>
      )}
    </>
  );
}

export default UploadPdf;
