/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Checkbox } from "primereact/checkbox";
// import Questions from "./RichTextQuestion";

import { clientDataProjectAction } from "../../../../../redux/action/utilsAction";

import dummyQuestion from "./question.json";
import ButtonDefault from "../../../../components/button/ButtonDefault";
// import dayjs from "dayjs";

const AddRelease = (props) => {
  const dispatch = useDispatch();
  const [keyword, setKeyword] = useState({ value: "", error: null });
  const [objectState, setObjectState] = useState({ value: "", error: null });
  const [what, setWhat] = useState({
    value: null,
    error: null,
  });
  const [when, setWhen] = useState({
      value: null,
      error: null,
  });
  const [where, setWhere] = useState({
      value: null,
      error: null,
  });
  const [who, setWho] = useState({
      value: null,
      error: null,
  });
  const [why, setWhy] = useState({
      value: null,
      error: null,
  });
  const [how, setHow] = useState({
      value: null,
      error: null,
  });
  const [categoryState, setCategoryState] = useState({
    value: null,
    error: null,
  });
  const [attributeState, setAttributeState] = useState({
    value: "",
    error: null,
  });
  const [genderState, setGenderState] = useState({
    value: null,
    error: null,
  });
  const [ageState, setAgeState] = useState({
    value: null,
    error: null,
  });
  const [stakeholderState, setStakeholderState] = useState({
    value: [],
    error: null,
  });
  const [sesState, setSesState] = useState({
    value: [],
    error: null,
  });
  
  const [questionState] = useState({
    value: [
      {
        what: "Apa nama layanan/fitur baru yang akan diluncurkan?",
        when: "Kapan layanan baru tersebut tersedia secara luas bisa diakses?",
        where: "Dimanakah masyarakat/user dapat mengakses layanan/produk baru tersebut?",
        why: "",
        who: "Siapa yang menjadi pengguna dari layanan/produk baru tersebut?",
        how: "Bagaimana perusahaan melihat potensi ke depannya dari produk/layanan baru tersebut?",
      },
      {
        what: "Apa bentuk kerjasama baru yang sedang dilakukan?",
        when: "Kapan kerjasama tersebut akan dijalankan?",
        where: "Dimana kerjasama tersebut disetujui/disahkan?",
        why: "Kenapa kerjasama tersebut dapat terbentuk?",
        who: "Siapa yang menjadi inisiator terjalinnya kerjasama tersebut?",
        how: "Bagaimana perusahaan melihat atau mengambil potensi dari kerjasama tersebut ke depannya?",
      },
      {
        what: "Apa isu yang akan diulas?",
        when: "Kapan isu tersebut bisa diselesaikan?",
        where: "Dimana letak pokok permasalahan dari isu tersebut?",
        why: "Kenapa isu tersebut bisa terjadi?",
        who: "Siapa yang bertanggungjawab atas isu tersebut?",
        how: "Bagaimana sebenarnya kronologi isu tersebut?",
      },
      {
        what: "Apa bentuk program CSR yang ingin diliput?",
        when: "Kapan program tersebut akan dilaksanakan atau diluncurkan?",
        where: "Dimana program tersebut akan berjalan dan terlaksana?",
        why: "Kenapa program tersebut penting dilakukan?",
        who: "Siapa saja yang menjadi target utama dari prohram tersebut?",
        how: "Bagaimana dampak program tersebut menunjang perusahaan dan masyarakat?",
      },
      {
        what: "Apa bentuk pencapaian perusahaan yang ingin dipublikasikan?",
        when: "Kapan kinerja tersebut dapat dicapai perusahaani?",
        where: "Di sektor/unit bisnis apa pencapaian tersebut terjadi?",
        why: "Kenapa kinerja tersebut perlu disampaikan ke publik?",
        who: "Siapa saja pihak yang terlibat dan berkontribusi dalam pencapaian tersebut?",
        how: "",
      }
    ],
    error: null,
  });

  useEffect(() => {
    if(categoryState.value === "Fitur/layanan dan Produk Baru"){
      setSelected(0)
    }
    else if(categoryState.value === "Kerjasama Baru Perusahaan"){
      setSelected(1)
    }
    else if(categoryState.value === "Klarifikasi Isu atau Kasus"){
      setSelected(2)
    }
    else if(categoryState.value === "Update CSR program"){
      setSelected(3)
    }
    else if(categoryState.value === "Kinerja Perusahaan"){
      setSelected(4)
    }
  }, [categoryState])

  const [selectedQuestion, setSelected] = useState(0)

  const [noteState, setNoteState] = useState({ value: "", error: null });

  const {
    dataAge,
    dataGender,
    dataStakeholder,
    dataCategoryRelease,
    dataAddProjectClient,
  } = useSelector((state) => state.utils);

  // console.log(dataAddProjectClient)

  const { dataDetailProduct } = useSelector(
    (state) => state.service
  );

  const dataSesStake = [
    "SES A",
    "SES B",
    "SES C",
    "SES D"
  ]

  const ageDropdown = dataAge?.map((age) => ({ name: age, code: age }));
  const genderDropdown = dataGender?.map((gender) => ({
    name: gender,
    code: gender,
  }));
  const categoryDropdown = dataCategoryRelease?.map((category) => ({
    name: category,
    code: category,
  }));
  const [isDisabled, setIsDisabled] = useState(true);
  // const [showInfo, setShowInfo] = useState(false);
  const [showAdditionalQuestion, setShowAdditionalQuestion] = useState(false);
  // const [hoveredQuestion, setHoveredQuestion] = useState("");
  // const [hoveredPosition, setHoveredPosition] = useState("top");
  const [hoveredPositionAdditional, setHoveredPositionAdditional] = useState(
    "top"
  );

  useEffect(() => {
    if (dataDetailProduct?.category === "article") {
      if (
        keyword?.value?.length > 0 &&
        objectState?.value?.length > 0 &&
        stakeholderState?.value?.length > 0 &&
        sesState?.value?.length > 0 &&
        ageState?.value !== null
      ) {
        setIsDisabled(false);
      }
    } else if (
      keyword?.value?.length > 0 &&
      what?.value?.length > 0 &&
      objectState?.value?.length > 0 &&
      stakeholderState?.value?.length > 0 &&
      sesState?.value?.length > 0 &&
      ageState?.value !== null &&
      categoryState?.value !== null
    ) {
      setIsDisabled(false);
    }
    else (
      setIsDisabled(true)
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    keyword.value,
    what.value,
    ageState.value,
    categoryState.value,
    objectState.value,
    stakeholderState.value,
    sesState.value,
    dataAddProjectClient,
  ]);

  const handleScroll = () => {
    // const categoryReleaseElement = document
      // .getElementById("release-category")
      // ?.getBoundingClientRect()?.top;
    const deliveredAttributionEl = document
      .getElementById("delivered-attribution")
      ?.getBoundingClientRect()?.top;
    // const limitTopCategoryRelease = 280;
    // const limitBottomCategoryRelease = 295;
    const limitTopAttribution = 340;
    const limitBottomAttribution = 450;
    // if (categoryReleaseElement < limitTopCategoryRelease) {
    //   setHoveredPosition("bottom");
    // }
    // if (categoryReleaseElement > limitBottomCategoryRelease) {
    //   setHoveredPosition("top");
    // }
    if (deliveredAttributionEl < limitTopAttribution) {
      setHoveredPositionAdditional("bottom");
    }
    if (deliveredAttributionEl > limitBottomAttribution) {
      setHoveredPositionAdditional("top");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataAddProjectClient?.release !== null) {
      if (dataDetailProduct?.category === "pr") {
        setKeyword({
          value: dataAddProjectClient?.keyword,
          error: null,
        });
        setWhat({
          value: dataAddProjectClient?.ideationFeature.what,
          error: null,
        });
        setWhen({
          value: dataAddProjectClient?.ideationFeature.when,
          error: null,
        });
        setWhere({
          value: dataAddProjectClient?.ideationFeature.where,
          error: null,
        });
        setWhy({
          value: dataAddProjectClient?.ideationFeature.why,
          error: null,
        });
        setWho({
          value: dataAddProjectClient?.ideationFeature.who,
          error: null,
        });
        setHow({
          value: dataAddProjectClient?.ideationFeature.how,
          error: null,
        });
        setObjectState({
          value: dataAddProjectClient?.objective,
          error: null,
        });
        setAttributeState({
          value: dataAddProjectClient?.attribution,
          error: null,
        });
        setCategoryState({
          value: dataAddProjectClient?.releaseCategory,
          error: null,
        });
        setGenderState({
          value: dataAddProjectClient?.target?.sex,
          error: null,
        });
        setAgeState({
          value: dataAddProjectClient?.target?.age,
          error: null,
        });
        setNoteState({
          value: dataAddProjectClient?.releaseNote,
          error: null,
        });
      } else if (dataDetailProduct?.category === "article") {
        setKeyword({
          value: dataAddProjectClient?.keyword,
          error: null,
        });
        setObjectState({
          value: dataAddProjectClient?.objective,
          error: null,
        });
        setAttributeState({
          value: dataAddProjectClient?.attribution,
          error: null,
        });
        setGenderState({
          value: dataAddProjectClient?.target?.sex,
          error: null,
        });
        setAgeState({
          value: dataAddProjectClient?.target?.age,
          error: null,
        });
        setNoteState({
          value: dataAddProjectClient?.releaseNote,
          error: null,
        });
      }
    }
  }, [dataAddProjectClient, dataDetailProduct]);

  const onCheck = (e) => {
    let selectedData = [...stakeholderState.value];

    if (e.checked) selectedData.push(e.value);
    else selectedData.splice(selectedData.indexOf(e.value), 1);

    setStakeholderState({ value: selectedData, error: null });
  };

  const onCheckSES = (e) => {
    let selectedData = [...sesState.value];

    if (e.checked) selectedData.push(e.value);
    else selectedData.splice(selectedData.indexOf(e.value), 1);

    setSesState({ value: selectedData, error: null });
  };

  const submitDetail = (e) => {
    e.preventDefault();
    const dataRelease = {
      keyword: keyword.value,
      ideationFeature: {
        what: what.value,
        when: when.value,
        where: where.value,
        why: why.value,
        who: who.value,
        how: how.value,
      },
      objective: objectState.value,
      releaseCategory: categoryState.value,
      releaseNote: noteState.value,
      attribution: attributeState.value,
      target: {
        sex: genderState.value,
        age: ageState.value,
        stakeholders: stakeholderState.value,
        ses: sesState.value,
      },
      title: dataAddProjectClient.title ? dataAddProjectClient.title : null,
      category: dataAddProjectClient.category ? dataAddProjectClient.category : null,
      type: dataAddProjectClient.type ? dataAddProjectClient.type : null,
      quota: dataAddProjectClient.quota ? dataAddProjectClient.quota : null,
      desc: dataAddProjectClient.desc ? dataAddProjectClient.desc : null,
      deadline: dataAddProjectClient?.deadline ? dataAddProjectClient?.deadline : null,
    };

    const dataArticle = {
      keyword: keyword.value,
      objective: objectState.value,
      releaseNote: noteState.value,
      attribution: attributeState.value,
      target: {
        sex: genderState.value,
        age: ageState.value,
        stakeholders: stakeholderState.value,
        ses: sesState.value,
      },
      title: dataAddProjectClient.title ? dataAddProjectClient.title : null,
      category: dataAddProjectClient.category ? dataAddProjectClient.category : null,
      type: dataAddProjectClient.type ? dataAddProjectClient.type : null,
      quota: dataAddProjectClient.quota ? dataAddProjectClient.quota : null,
      desc: dataAddProjectClient.desc ? dataAddProjectClient.desc : null,
      deadline: dataAddProjectClient?.deadline ? dataAddProjectClient?.deadline : null,
    };

    if (objectState?.value?.length < 1) {
      setObjectState({
        ...objectState,
        error: "Objektif Komunikasi harus diisi",
      });
    }
    if (stakeholderState?.value?.length < 1) {
      setStakeholderState({
        ...stakeholderState,
        error: "Stakeholder harus diisi",
      });
    }
    if (sesState?.value?.length < 1) {
      setSesState({
        ...sesState,
        error: "SES harus diisi",
      });
    }
    if (ageState?.value == null) {
      setAgeState({ ...ageState, error: "Usia harus diisi" });
    }
    if (dataDetailProduct?.category === "pr" && categoryState?.value === null) {
      setCategoryState({ ...categoryState, error: "Kategori harus diisi" });
    }
    if (dataDetailProduct?.category === "pr") {
      if (
        objectState?.value?.length > 0 &&
        stakeholderState?.value?.length > 0 &&
        sesState?.value?.length !== null &&
        ageState?.value !== null &&
        categoryState?.value !== null
        // questionState?.value !== null
      ) {
        dispatch(clientDataProjectAction(dataRelease));
        props.setBrief();
      }
    } else if (dataDetailProduct?.category === "article") {
      if (
        objectState?.value?.length > 0 &&
        stakeholderState?.value?.length > 0 &&
        sesState?.value?.length !== null &&
        ageState?.value !== null
      ) {
        dispatch(clientDataProjectAction(dataArticle));
        props.setBrief();
      }
    }

  };

  // const changeQuestion = (data) => {
  //   setQuestionState({ value: data, error: null });
  // };

  const changeCategory = (data) => {
    setCategoryState({ value: data, error: null });
    // const question = dummyQuestion?.filter((item) => item.name === data);
    // setHoveredQuestion(question[0]?.question);
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-body pt-8 pb-4 p-d-flex">
          <div className="form-add-project brief w-60 pr-20">
            <div className="p-field">
              <label className="p-d-block">
                Kata Kunci Pencarian <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                placeholder="Masukkan Kata Kunci Pencarian"
                onChange={(e) =>
                  setKeyword({ value: e.target.value, error: null })
                }
                value={keyword.value}
              />
              {keyword.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {keyword.error}
                </small>
              )}
            </div>

            {dataDetailProduct?.category === "pr" && (
              <>
                <div className="p-field">
                  <label className="p-d-block" id="release-category">
                    Kategori Rilis <span className="text-danger">*</span>
                  </label>
                  <Dropdown
                    value={categoryState.value}
                    options={categoryDropdown}
                    onChange={(e) => changeCategory(e.target.value)}
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Rilis"
                  />
                  {categoryState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {categoryState.error}
                    </small>
                  )}
                </div>
              

                <div className="p-field">
                  <label className="p-d-block">
                    {questionState.value?.[selectedQuestion].what} <span className="text-danger">*</span>
                  </label>
                  <InputText
                    className="p-d-block"
                    placeholder="Text here"
                    onChange={(e) =>
                      setWhat({ value: e.target.value, error: null })
                    }
                    value={what.value}
                  />
                  {what.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {what.error}
                    </small>
                  )}
                </div>

                <div className="p-field">
                  <label className="p-d-block">
                    {questionState.value?.[selectedQuestion].who}
                  </label>
                  <InputText
                    className="p-d-block"
                    placeholder="Text here"
                    onChange={(e) =>
                      setWho({ value: e.target.value, error: null })
                    }
                    value={who.value}
                  />
                  {who.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {who.error}
                    </small>
                  )}
                </div>

                <div className="p-field">
                  <label className="p-d-block">
                    {questionState.value?.[selectedQuestion].where}
                  </label>
                  <InputText
                    className="p-d-block"
                    placeholder="Text here"
                    onChange={(e) =>
                      setWhere({ value: e.target.value, error: null })
                    }
                    value={where.value}
                  />
                  {where.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {where.error}
                    </small>
                  )}
                </div>

                <div className="p-field">
                  <label className="p-d-block">
                    {questionState.value?.[selectedQuestion].when}
                  </label>
                  <InputText
                    className="p-d-block"
                    placeholder="Text here"
                    onChange={(e) =>
                      setWhen({ value: e.target.value, error: null })
                    }
                    value={when.value}
                  />
                  {when.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {when.error}
                    </small>
                  )}
                </div>

                {questionState.value?.[selectedQuestion].why !== "" ? (
                  <div className="p-field">
                    <label className="p-d-block">
                      {questionState.value?.[selectedQuestion].why}
                    </label>
                    <InputText
                      className="p-d-block"
                      placeholder="Text here"
                      onChange={(e) =>
                        setWhy({ value: e.target.value, error: null })
                      }
                      value={why.value}
                    />
                    {why.error && (
                      <small id="name" className="p-error p-d-block p-text-right">
                        {why.error}
                      </small>
                    )}
                  </div>
                ) : null}

                {questionState.value?.[selectedQuestion].how !== "" ? (
                  <div className="p-field">
                    <label className="p-d-block">
                    {questionState.value?.[selectedQuestion].how}
                    </label>
                    <InputText
                      className="p-d-block"
                      placeholder="Text here"
                      onChange={(e) =>
                        setHow({ value: e.target.value, error: null })
                      }
                      value={how.value}
                    />
                    {how.error && (
                      <small id="name" className="p-error p-d-block p-text-right">
                        {how.error}
                      </small>
                    )}
                  </div>
                ) : null}
              </>
            )}

            {/* {dataDetailProduct?.category === "pr" && categoryState.value && (
              <div className="p-field">
                <label htmlFor="category" className="p-d-block">
                  Informasi Tambahan Kategori Rilis {categoryState.value}{" "}
                  <span className="text-danger">*</span>
                  <span
                    onMouseOver={() => setShowInfo(true)}
                    onMouseLeave={() => setShowInfo(false)}
                    className="icon-info ml-2"
                    id="hovered-icon"
                  ></span>
                </label>
                <div className={`info-question-wrapper ${hoveredPosition}`}>
                  {showInfo && (
                    <div
                      className="info-question"
                      dangerouslySetInnerHTML={{ __html: hoveredQuestion }}
                    ></div>
                  )}
                </div>
                <Questions
                  contentValue={questionState.value}
                  changeContent={changeQuestion}
                />
                {questionState.error && (
                  <small id="name" className="p-error p-d-block p-text-right">
                    {questionState.error}
                  </small>
                )}
              </div>
            )} */}

            
            <div className="p-field">
              <label htmlFor="target" className="p-d-block">
                Target Pembaca
              </label>
              <div className="innef-field p-d-flex">
                <span>Jenis Kelamin <span className="text-danger">*</span></span>
                <div>
                  <Dropdown
                    value={genderState.value}
                    options={genderDropdown}
                    onChange={(e) =>
                      setGenderState({ value: e.target.value, error: null })
                    }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {genderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {genderState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field p-d-flex">
                <span>
                  Usia <span className="text-danger">*</span>
                </span>
                <div>
                  <Dropdown
                    value={ageState.value}
                    options={ageDropdown}
                    onChange={(e) =>
                      setAgeState({ value: e.target.value, error: null })
                    }
                    optionLabel="name"
                    optionValue="code"
                    placeholder="Pilih Kategori Project"
                  />
                  {ageState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {ageState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field">
                <span>
                  Stakeholder <span className="text-danger">*</span>
                </span>
                <div>
                  {dataStakeholder?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        inputId={item}
                        onChange={onCheck}
                        checked={stakeholderState.value.indexOf(item) !== -1}
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {stakeholderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {stakeholderState.error}
                    </small>
                  )}
                </div>
              </div>
              <div className="innef-field">
                <span>
                  SES <span className="text-danger">*</span>
                </span>
                <div>
                  {dataSesStake?.map((item, i) => (
                    <div className="p-field-checkbox p-mt-2" key={i}>
                      <Checkbox
                        inputId={item}
                        onChange={onCheckSES}
                        checked={sesState.value.indexOf(item) !== -1}
                        value={item}
                      />
                      <label htmlFor={item} className="p-mb-0">
                        {item}
                      </label>
                    </div>
                  ))}
                  {stakeholderState.error && (
                    <small id="name" className="p-error p-d-block p-text-right">
                      {stakeholderState.error}
                    </small>
                  )}
                </div>
              </div>
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Objektif komunikasi {" "}
                <span className="text-danger">*</span>
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setObjectState({ value: e.target.value, error: null })
                }
                value={objectState.value}
              />
              {objectState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {objectState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block" id="delivered-attribution">
                Atribusi Quote Dan Pesan Yang Ingin Disampaikan
                  <span
                    onMouseOver={() => setShowAdditionalQuestion(true)}
                    onMouseLeave={() => setShowAdditionalQuestion(false)}
                    className="icon-info ml-2"
                    id="hovered-icon"
                  />
              </label>
              <div
                className={`info-question-wrapper ${hoveredPositionAdditional}`}
              >
                {showAdditionalQuestion && (
                  <div
                    className="info-question"
                    dangerouslySetInnerHTML={{
                      __html: dummyQuestion[5].question,
                    }}
                  ></div>
                )}
              </div>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setAttributeState({ value: e.target.value, error: null })
                }
                value={attributeState.value}
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Poin penting lainnya yang ingin disampaikan
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setNoteState({ value: e.target.value, error: null })
                }
                value={noteState.value}
              />
            </div>
          </div>
          {dataDetailProduct?.category === "pr" && (
            <div className="w-50 mr-10 px-4 py-6 text-dark border rounded border-3 border-top-info h-100">
              <div className="h3 font-weight-bold mb-5">Tata cara pengisian informasi {categoryState.value}:</div>
              <div className="mb-3">Deskripsikan dengan lengkap dan terperinci informasi lebih lanjut terkait {categoryState.value}, seperti:</div>
              <ul className="mb-3">
                <li>{questionState.value?.[selectedQuestion].what}</li>
                <li>{questionState.value?.[selectedQuestion].when}</li>
                <li>{questionState.value?.[selectedQuestion].where}</li>
                {questionState.value?.[selectedQuestion].why !== "" ? (<li>{questionState.value?.[selectedQuestion].why}</li>) : null}
                <li>{questionState.value?.[selectedQuestion].who}</li>
                {questionState.value?.[selectedQuestion].how !== "" ? (<li>{questionState.value?.[selectedQuestion].how}</li>) : null}
              </ul>
              <div>Tambahkan spesifikasi dan poin penting lainnya yang ingin disampaikan, agar dapat membantu penulis bekerja dengan baik.</div>
            </div>
          )}

        </div>
        <div className="footer-form">
          <div className="form-add-project brief p-d-flex p-jc-end p-ai-center">
            <ButtonDefault
              onClick={submitDetail}
              disabled={isDisabled}
              label="Selanjutnya"
              type="rounded"
              className="p-button p-button-primary"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default AddRelease;
