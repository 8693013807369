import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import firebase from "firebase/app";

import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../helpers/phoneNumber";
import ButtonLoader from "../../../components/button/ButtonLoader";

import {
  phoneLoginAction,
  checkPhoneAction,
  resetStateAuthAction,
} from "../../../../redux/action/authAction";
import DialogAlert from "../../../components/dialog/DialogAlert";

const LoginPhone = () => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState({ value: "", error: null });
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogText, setDialogText] = useState("");

  const {
    loadingPhoneLogin,
    dataCheckPhone,
    loadingCheckPhone,
    errPhoneLogin,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "grecaptcha-button",
      {
        size: "invisible",
      }
    );
  }, []);

  useEffect(() => {
    if (dataCheckPhone !== null && !dataCheckPhone?.exists) {
      setDialogText("Nomor telepon belum terdaftar");
      setOpenDialog(true);
    }
    if (dataCheckPhone !== null && dataCheckPhone?.disabled) {
      setOpenDialog(true);
      setDialogText(
        "Email atau nomor telepon Anda sudah pernah daftar, silakan  hub cs@iris.id untuk informasi lebih lanjut"
      );
    }
    if (
      dataCheckPhone !== null &&
      dataCheckPhone?.exists &&
      !dataCheckPhone?.disabled
    ) {
      const data = { phone_number: phone.value };
      dispatch(phoneLoginAction(window.recaptchaVerifier, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCheckPhone]);

  useEffect(() => {
    if (errPhoneLogin?.code === "auth/too-many-requests") {
      setOpenDialog(true);
      setDialogText("Terlalu banyak percobaan pengiriman OTP. Coba lagi nanti");
    }
  }, [errPhoneLogin]);

  const submitPhone = (e) => {
    e.preventDefault();

    if (!formPhoneNumber(phone.value)) {
      setPhone({ ...phone, error: "Nomor telepon tidak valid" });
    } else {
      const data = { phone: formattedPhoneNumber(phone.value) };
      dispatch(checkPhoneAction(data));
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    dispatch(resetStateAuthAction());
  };

  return (
    <>
      <form onSubmit={submitPhone}>
        <div className="p-field mt-0">
          <InputText
            id="phone"
            aria-describedby="phone-number"
            className={`${phone.error !== null && "p-invalid"} p-d-block`}
            placeholder="Masukkan Nomor Telepon"
            value={phone.value}
            onChange={(e) =>
              setPhone({ ...phone, value: e.target.value, error: null })
            }
          />
          {phone.error !== null && (
            <small id="phone-number" className="p-error p-d-block p-text-right">
              {phone.error}
            </small>
          )}
        </div>

        {loadingPhoneLogin || loadingCheckPhone ? (
          <ButtonLoader />
        ) : (
          <Button
            onClick={submitPhone}
            label="Masuk"
            className="p-button-rounded p-button-lg p-mt-3"
            type="submit"
          />
        )}
      </form>

      <div id="grecaptcha-button" />

      <div className="footer-form">
        <p>
          Belum memiliki akun? <Link to="/create-account">Daftar Sekarang</Link>
        </p>
      </div>
      <DialogAlert
        type={"error"}
        visible={openDialog}
        closeDialog={handleCloseDialog}
        title={"Gagal Login"}
        text={dialogText}
      />
    </>
  );
};

export default LoginPhone;
