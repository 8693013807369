/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

import Dropdown from "react-bootstrap/Dropdown";

import DialogAlert from "../../../components/dialog/DialogAlert";
import FullPageContent from "../../../components/loading/fullContentLoader";
import RichtextWithoutToolbar from "../../../components/richtext/richtextWithoutToolbar";
import emptyImage from "../../../assets/img/empty-list.png";
import ButtonDefault from "../../../components/button/ButtonDefault";
import iconDownload from "../../../assets/img/download-blue.svg";

import firebase from "../../../../redux/service/firebase/firebase";
import {
  sendChatClientAction,
  sendChatClientFsAction,
} from "../../../../redux/action/chatAction";
import {
  updateArticleAction,
  resetStateProjectAction,
  getDraftAction,
  getArticleAction,
  updateArticleClientStatusAction,
} from "../../../../redux/action/projectAction";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import { getToken } from "../../../../redux/service/firebase/auth";

const CreateArticleModule = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { articleId, articleData } = props;

  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = firebase.firestore();
  const [openAlert, setOpenAlert] = useState({
    dialog: false,
    type: "",
    title: "",
    desc: "",
  });
  const [typeStatus, setTypeStatus] = useState("");
  const [message, setMessage] = useState("");
  const [renderChats, setRenderChats] = useState(null);

  const {
    dataGetArticle,
    loadingGetArticle,
    loadingUpdateArticleClientStatus,
    successUpdateArticleClientStatus,
    errUpdateArticleClientStatus,
  } = useSelector((state) => state.project);
  const { users } = useSelector((state) => state.auth);

  console.log(dataGetArticle)

  const isAssigned =
    users?.user?.type === "client" || articleData?.picId === users?.uid;

  useEffect(() => {
    if (successUpdateArticleClientStatus) {
      setOpenAlert({
        dialog: true,
        type: "success",
        title:
          typeStatus === "select"
            ? "Artikel Telah Dipilih"
            : "Berhasil Ajukan Revisi",
        desc:
          typeStatus === "select"
            ? "Artikel berhasil dipilih"
            : "Kami akan kirim notifikasi ke Manajer Project",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateArticleClientStatus]);

  useEffect(() => {
    if (errUpdateArticleClientStatus) {
      setOpenAlert({
        dialog: true,
        type: "error",
        title:
          typeStatus === "select"
            ? "Artikel Gagal Dipilih"
            : "Gagal Ajukan Revisi",
        desc:
          typeStatus === "select"
            ? "Artikel gagal dipilih"
            : "Permintaan Revisi gagal dikirim",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errUpdateArticleClientStatus]);

  useEffect(() => {
    if (articleId !== null) {
      getChatList(articleId);
      dispatch(getDraftAction(articleId));

      if (dataGetArticle?.chat?.userType === "pm") {
        const notif = {
          isRead: false,
          userType: users?.user?.type,
        };
        dispatch(updateArticleAction(articleId, { chatClient: notif }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleId, dataGetArticle?.chat?.userType]);

  const handleSelect = () => {
    const publishAction = () => {
      const data = {
        status: "client_approved",
      };
      dispatch(updateArticleClientStatusAction(props.articleId, data));
    };
    setTypeStatus("select");
    setOpenAlert({
      dialog: true,
      type: "warning",
      title: "Pilih Artikel Ini?",
      desc: "Harap pastikan artikel yang dipilih sudah sesuai",
      actionDialog: () => publishAction(),
      actionText: "PILIH",
      cancelText: "BATAL",
      loading: loadingUpdateArticleClientStatus,
    });
  };

  const handleRevision = () => {
    const publishAction = () => {
      const data = {
        status: "revision",
      };
      dispatch(updateArticleClientStatusAction(props.articleId, data));
    };
    setTypeStatus("revision");
    setOpenAlert({
      dialog: true,
      type: "warning",
      title: "Ajukan Revisi Artikel Ini?",
      desc: "Kami akan mengirim notifikasi ke Manajer Project",
      actionDialog: () => publishAction(),
      actionText: "AJUKAN",
      cancelText: "BATAL",
      loading: loadingUpdateArticleClientStatus,
    });
  };

  const closeAlert = () => {
    setOpenAlert({
      dialog: false,
      type: "",
      title: "",
      desc: "",
    });
    dispatch(resetStateProjectAction());
    if (openAlert?.type === "success" && typeStatus?.length > 0) {
      setTypeStatus("");
      dispatch(getArticleAction(articleId));
    } else {
      setTypeStatus("");
    }
  };

  // Function Comment
  const getChatList = async (id) =>
    await firestore
      .collection("writerProjects")
      .doc(id)
      .collection("clientChats")
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            _id: doc.id,
          })
        );
        setRenderChats(temp);
      });

  const sendingChat = () => {
    if (message.trim() !== "" && message !== "<p><br></p>") {
      const data = {
        createdAt: new Date().getTime(),
        message: message,
        isRead: false,
        user: {
          id: users?.uid,
          name: users?.user?.name,
          type: users?.user?.type,
        },
        type: "message",
      };
      const notif = {
        isRead: true,
        userType: users?.user?.type,
      };

      dispatch(sendChatClientAction(articleId, data));
      dispatch(sendChatClientFsAction(articleId, data));
      dispatch(updateArticleAction(articleId, { chatClient: notif }));
      setMessage("");
      dispatch(resetUtilsStateAction());
    }
  };

  const downloadFile = async (type) => {
    const token = await getToken();
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1/chats/users/article/${props.articleId}?token=${token}&type=${type}`,
      "_blank"
    );
  };

  const totalWords = articleData?.articleContent
    ?.replace(/<[^>]*>/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    ?.split(" ")?.length;

  const changeMessage = (data) => {
    setMessage(data);
  };

  // Status Project Writer
  let statusPm;
  let statusClient;
  switch (articleData?.status) {
    case "removed":
      statusPm = 1;
      break;
    case "sent":
      statusPm = 2;
      break;
    case "revision":
      statusPm = 3;
      break;
    case "pm_approved":
      statusPm = 4;
      break;
    case "reject":
      statusPm = 5;
      break;
    case "winner":
      statusPm = 6;
      break;
    case "published":
      statusPm = 7;
      break;
    case "draft":
      statusPm = 8;
      break;
    default:
      statusPm = 0;
  }

  switch (articleData?.clientStatus) {
    case "revision":
      statusClient = 1;
      break;
    case "client_approved":
      statusClient = 2;
      break;
    case "pm_approved":
      statusClient = 3;
      break;
    case "send_client":
      statusClient = 4;
      break;
    case "draft":
      statusClient = 5;
      break;
    default:
      statusClient = 0;
  }

  const isEmptyArticle = statusClient === 0 && statusPm <= 1

  const renderButtonHeader = () => {
    let statusText;
    let statusClass;
    if (isAssigned && (statusClient === 2 || statusPm >= 6)) {
      statusText = "Artikel telah terpilih";
      statusClass = "success";
    } else if (isAssigned && (statusPm === 3 || statusClient === 1)) {
      statusText = "Sedang direvisi Penulis";
      statusClass = "danger";
    } else if (!isAssigned) {
      statusText = "Anda hanya bisa melihat";
      statusClass = "warning";
    } else {
      statusText = "";
      statusClass = "danger";
    }

    return (
      <div className="p-d-flex p-jc-start p-ai-center pb-0">
        <h5 className={`text-${statusClass} mb-0 mr-4`}>{statusText}</h5>
        {isAssigned && statusPm >= 6 && (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="btn-download">
              <img src={iconDownload} alt="icon" />
              Unduh Artikel
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => downloadFile("doc")}>
                Format Doc
              </Dropdown.Item>
              <Dropdown.Item onClick={() => downloadFile("pdf")}>
                Format Pdf
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {isAssigned && statusPm > 2 && (
          <Dropdown>
            <Dropdown.Toggle
              className="button-style-dropdown"
              id="dropdown-basic"
            >
              Menu Aksi
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                as="button"
                onClick={() => handleSelect()}
                disabled={statusClient > 3 || statusClient === 1}
              >
                Pilih Tulisan
              </Dropdown.Item>

              <Dropdown.Item
                as="button"
                onClick={() => handleRevision()}
                disabled={statusClient > 3 || statusClient === 1}
              >
                Ajukan Revisi
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </div>
    );
  };

  return (
    <>
      <div className="card-header approval-release p-d-flex p-jc-between p-ai-center">
        <div className="p-d-flex p-jc-start p-ai-center project-title">
          <button
            href="#"
            onClick={() => history.goBack()}
            className="button-back"
          >
            <span className="icon-back"></span>
          </button>

          <h3 className="card-title align-items-start flex-column mb-0 ml-3">
            <span className="card-label font-weight-bolder text-dark">
              {dataGetArticle?.title?.length >= 50
                ? `${dataGetArticle?.title?.substring(0, 50)}...`
                : dataGetArticle?.title}
            </span>
          </h3>
        </div>
        {renderButtonHeader()}
      </div>
      <div className="mobile-preview-header">
        <h3 className="project-title">{dataGetArticle?.title}</h3>
        <div className="header-subtitle p-d-flex">
          <span className="mr-3">{dataGetArticle?.userData?.name}</span> {" | "}
          <span className="date ml-3">
            {dataGetArticle?.doneAt
              ? dayjs(dataGetArticle?.doneAt?.toDate())
                  .locale("id")
                  .format("DD MMMM YYYY")
              : "Belum tersedia"}
          </span>
        </div>
      </div>
      <div className="card-header p-jc-center preview-article">
        {loadingGetArticle ? (
          <>
            <FullPageContent />
          </>
        ) : (
          <div className="section-progress writer">
            <div className="step-add preview">
              <ul>
                <li className="active">
                  <div className="step-number">
                    <span className="done"></span>
                  </div>
                  <div className="step-text">Bergabung</div>
                  <div className="step-date">
                    {dataGetArticle?.createdAt
                      ? dayjs(dataGetArticle?.createdAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </div>
                </li>
                <li className={dataGetArticle?.submitAt || dataGetArticle?.status === "sent" ? "active" : ""}>
                  <div className="step-number">
                    <span className="done"></span>
                  </div>
                  <div className="step-text">On Progress</div>

                  <div className="step-date">
                    {dataGetArticle?.submitAt || dataGetArticle?.createdAt
                      ? dayjs(dataGetArticle?.submitAt?.toDate() ? dataGetArticle?.submitAt?.toDate() : dataGetArticle?.createdAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </div>
                </li>
                {dataGetArticle?.lastRevisionAt && (
                  <li className="active">
                    <div className="step-number">
                      <span className="done"></span>
                    </div>
                    <div className="step-text">Revisi</div>

                    <div className="step-date">
                      {dayjs(dataGetArticle?.lastRevisionAt?.toDate())
                        .locale("id")
                        .format("DD MMMM YYYY")}
                    </div>
                  </li>
                )}
                <li
                  className={
                    dataGetArticle?.doneAt || dataGetArticle?.status === "draft" ||
                    (dataGetArticle?.status !== "object"
                      ? dataGetArticle?.status === "reject"
                      : dataGetArticle?.status?.status === "reject")
                      ? "active"
                      : ""
                  }
                >
                  <div className="step-number">
                    <span className="done"></span>
                  </div>
                  <div className="step-text">Selesai</div>

                  <div className="step-date">
                    {dataGetArticle?.doneAt || dataGetArticle?.createdAt
                      ? dayjs(dataGetArticle?.doneAt?.toDate() ? dataGetArticle?.doneAt?.toDate() : dataGetArticle?.createdAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="card-body p-0">
        {loadingGetArticle ? (
          <div className="p-5">
            <FullPageContent />
            <FullPageContent />
          </div>
        ) : (
          <div className="article-preview">
            <div className={`section-article no-scroll`}>
              <div className="section-article-box preview">
                {isEmptyArticle ? (
                  <div className="empty-article">
                    <img src={emptyImage} alt="kosong" />
                    <h1>Tulisan Masih Kosong</h1>
                  </div>
                ) : (
                  <>
                    <div className="article-box">
                      <div
                        className="article-view-title"
                        dangerouslySetInnerHTML={{
                          __html: `${articleData?.articleTitle}`,
                        }}
                      />
                    </div>
                    <div className="article-view-body">
                      {articleData?.articleImage && (
                        <div className="article-view-cover">
                          <img
                            src={articleData?.articleImage}
                            alt="photo-article"
                          />
                        </div>
                      )}
                      <div
                        className="article-view-content"
                        dangerouslySetInnerHTML={{
                          __html: `${articleData?.articleContent}`,
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="section-article-footer">
                <div className="write-note">
                  <div className="write-count">
                    <p>
                      Ditulis oleh{" "}
                      <strong>{articleData?.userData?.name}</strong>, dengan
                      jumlah <strong>{totalWords || "0"}</strong> Kata
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className={`section-comment-writer`}>
              {!isEmptyArticle && (
                <div className="comment-writer-box">
                  <label>Komentar</label>
                  <RichtextWithoutToolbar
                    contentValue={message}
                    changeContent={changeMessage}
                    placeholder="Tulis komentar anda"
                  />
                  <div className="p-d-flex p-jc-end w-100">
                    <ButtonDefault
                      label="Kirim"
                      type="rounded"
                      onClick={() => sendingChat()}
                      disabled={isEmptyArticle}
                    />
                  </div>
                </div>
              )}

              <div className="comment-writer-head">Riwayat Aktivitas</div>
              <div
                className={`comment-writer-body ${
                  (dataGetArticle?.status === "revision" ||
                    dataGetArticle?.status === "ongoing") &&
                  dataGetArticle.articleImage
                    ? "fit-content"
                    : "comment-wrap"
                }`}
              >
                <div className="comment-list-wraper">
                  {renderChats?.map((item) => (
                    <div className="comment-list" key={item._id}>
                      <div className="comment-image">
                        <div className={`chat-avatar ${item?.user?.type}`}>
                          {item?.user?.type === "system"
                            ? "S"
                            : item?.user?.name?.substring(0, 1)}
                        </div>
                      </div>
                      <div className="comment-content">
                        <div className="comment-user">
                          {item?.user?.type === "system"
                            ? "Sistem"
                            : item?.user?.name}{" "}
                          {item?.user?.type !== "system" && (
                            <span>
                              (
                              {item?.user?.type === "pm" ||
                              item?.user?.type === "general_pm"
                                ? "Manajer Project"
                                : "Klien"}
                              )
                            </span>
                          )}
                        </div>
                        <div
                          className={`comment-text ${
                            item?.user?.type === "system" ? "blue" : ""
                          }`}
                        >
                          <div
                            className="message-inner"
                            dangerouslySetInnerHTML={{
                              __html: `${item?.message}`,
                            }}
                          />
                        </div>
                        <div className="comment-date">
                          {dayjs(item?.createdAt)
                            .locale("id")
                            .format("DD MMMM YYYY HH:mm")}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <DialogAlert
        type={openAlert.type}
        visible={openAlert.dialog}
        closeDialog={() => closeAlert()}
        title={openAlert.title}
        text={openAlert.desc}
        actionDialog={openAlert?.actionDialog}
        actionText={openAlert?.actionText}
        cancelText={openAlert?.cancelText}
        loading={openAlert?.loading}
      />
    </>
  );
};

export default CreateArticleModule;
