/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfilePMModule from "../../../modules/Admin/Profile/ProfilePmModule";
import DashboardLayout from "../../../layouts/dashboardLayout";

import { titlePage } from "../../../helpers/titleHead";

import { myProfileAction } from "../../../../redux/action/authAction";

const ProfilePmPage = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);
  useEffect(() => {
    titlePage({
      title: "IRIS - Profile",
    });

    dispatch(myProfileAction(users.uid));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Profile">
      <ProfilePMModule />
    </DashboardLayout>
  );
};

export default ProfilePmPage;
