/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetPICAction } from "../../../../redux/action/clientPicAction";

// import ListLoader from "../../../../components/loading/listLoader";

import { getLastLocation } from "../../../../_metronic/_helpers";
import DialogAlert from "../../../components/dialog/DialogAlert";
import PICDetailForm from "./Components/PICDetailForm";

const PICDetailModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { dataGetDetailPIC, errDeletePIC, successDeletePIC } = useSelector(
    (state) => state.clientPic
  );

  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogDanger, setDialogDanger] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const closeSuccess = () => {
    setTimeout(() => {
      dispatch(resetPICAction());
      setDialogSuccess(false);
      history.push(getLastLocation());
    }, 750);
  };

  const closeDanger = () => {
    setDialogDanger(false);
    dispatch(resetPICAction());
  };

  useEffect(() => {
    if (errDeletePIC) {
      setDialogDanger(true);
      if (errDeletePIC?.message === "PIC have a project") {
        setDialogMessage("Tim Mempunyai Project");
      } else {
        setDialogMessage(errDeletePIC?.message);
      }
    }
  }, [errDeletePIC]);

  useEffect(() => {
    if (successDeletePIC) {
      setDialogSuccess(true);
    }
  }, [successDeletePIC]);

  return (
    <>
      <div className='p-d-flex p-jc-center detail-pm'>
        <div className='card card-custom col-lg-6 col-md-6 col-sm-12 mb-4'>
          <div className='card-header border-0 py-5'>
            <h3 className='card-title align-items-start flex-column'>
              <button onClick={() => history.goBack()} className='button-back'>
                <span className='card-label font-weight-bolder text-dark'>
                  <span className='icon-back mr-2'></span>Detail Tim
                </span>
              </button>
            </h3>
          </div>
          <PICDetailForm isDisabled={true} data={dataGetDetailPIC} />
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Memperbarui Data"}
        text={"Data pengguna berhasil diperbarui"}
      />
      <DialogAlert
        type={"danger"}
        visible={dialogDanger}
        closeDialog={() => closeDanger()}
        title={"Gagal Menghapus Tim"}
        text={dialogMessage}
      />
    </>
  );
};

export default PICDetailModule;
