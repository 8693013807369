import {
  SEND_CHAT_REQUEST,
  SEND_NOTIF_REQUEST,
  UNPIN_CHAT_REQUEST,
  SEND_CHAT_CLIENT_REQUEST,
  SEND_CHAT_CLIENT_FS_REQUEST,
} from "../constant/chatConstant";

export const sendChatAction = (id, data) => {
  return {
    type: SEND_CHAT_REQUEST,
    id,
    data,
  };
};

export const sendNotifAction = (id, data) => {
  return {
    type: SEND_NOTIF_REQUEST,
    id,
    data,
  };
};

export const unpinChatAction = (projectId, chatId) => {
  return {
    type: UNPIN_CHAT_REQUEST,
    projectId,
    chatId,
  };
};

export const sendChatClientAction = (id, data) => {
  return {
    type: SEND_CHAT_CLIENT_REQUEST,
    id,
    data,
  };
};

export const sendChatClientFsAction = (id, data) => {
  return {
    type: SEND_CHAT_CLIENT_FS_REQUEST,
    id,
    data,
  };
};
