import {
  WRITER_WITHDRAW_FAILURE,
  WRITER_WITHDRAW_REQUEST,
  WRITER_WITHDRAW_SUCCESS,
  WRITER_TRANSACTION_FAILURE,
  WRITER_TRANSACTION_REQUEST,
  WRITER_TRANSACTION_SUCCESS,
  LIST_WRITER_PAYMENT_FAILURE,
  LIST_WRITER_PAYMENT_REQUEST,
  LIST_WRITER_PAYMENT_SUCCESS,
  DETAIL_WRITER_PAYMENT_FAILURE,
  DETAIL_WRITER_PAYMENT_REQUEST,
  DETAIL_WRITER_PAYMENT_SUCCESS,
  LIST_PAYOUT_FINANCE_FAILURE,
  LIST_PAYOUT_FINANCE_REQUEST,
  LIST_PAYOUT_FINANCE_SUCCESS,
  RESET_STATE_TRANSACTION,
} from "../constant/transactionConstant";

const initialStateTransaction = {
  transactionParams: {
    perpage: 10,
    page: 1,
  },
  totalPage: null,

  loadingWriterWithdraw: false,
  successWriterWithdraw: false,
  errWriterWithdraw: null,

  loadingWriterTransaction: false,
  dataWriterTransaction: null,
  errWriterTransaction: null,
  totalWriterTransaction: 0,

  loadingListWriterPayment: false,
  dataListWriterPayment: null,
  errListWriterPayment: null,

  loadingDetailWriterPayment: false,
  dataDetailWriterPayment: null,
  errDetailWriterPayment: null,

  loadingPayoutFinance: false,
  dataPayoutFinance: null,
  errPayoutFinance: null,
};

export const transactionReducer = (state = initialStateTransaction, action) => {
  switch (action.type) {
    case RESET_STATE_TRANSACTION:
      return {
        ...state,
        loadingWriterWithdraw: false,
        successWriterWithdraw: false,
        errWriterWithdraw: null,
        loadingWriterTransaction: false,
        dataWriterTransaction: null,
        errWriterTransaction: null,
      };
    case WRITER_WITHDRAW_REQUEST:
      return {
        ...state,
        loadingWriterWithdraw: true,
        errWriterWithdraw: null,
      };
    case WRITER_WITHDRAW_SUCCESS:
      return {
        ...state,
        loadingWriterWithdraw: false,
        successWriterWithdraw: true,
        errWriterWithdraw: null,
      };
    case WRITER_WITHDRAW_FAILURE:
      return {
        ...state,
        loadingWriterWithdraw: false,
        errWriterWithdraw: action.data,
      };

    case WRITER_TRANSACTION_REQUEST:
      return {
        ...state,
        loadingWriterTransaction: true,
        errWriterTransaction: null,
      };
    case WRITER_TRANSACTION_SUCCESS:
      return {
        ...state,
        loadingWriterTransaction: false,
        dataWriterTransaction: action.data.data,
        totalWriterTransaction: action.data.meta.total,
        errWriterTransaction: null,
      };
    case WRITER_TRANSACTION_FAILURE:
      return {
        ...state,
        loadingWriterTransaction: false,
        errWriterTransaction: action.data,
      };

    case LIST_WRITER_PAYMENT_REQUEST:
      return {
        ...state,
        loadingListWriterPayment: true,
        errListWriterPayment: null,
      };
    case LIST_WRITER_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingListWriterPayment: false,
        dataListWriterPayment: action.data.data,
        totalPage: action.data.meta.total,
        errListWriterPayment: null,
      };
    case LIST_WRITER_PAYMENT_FAILURE:
      return {
        ...state,
        loadingListWriterPayment: false,
        errListWriterPayment: action.data,
      };

    case DETAIL_WRITER_PAYMENT_REQUEST:
      return {
        ...state,
        loadingDetailWriterPayment: true,
        errDetailWriterPayment: null,
      };
    case DETAIL_WRITER_PAYMENT_SUCCESS:
      return {
        ...state,
        loadingDetailWriterPayment: false,
        dataDetailWriterPayment: action.data.data,
        errDetailWriterPayment: null,
      };
    case DETAIL_WRITER_PAYMENT_FAILURE:
      return {
        ...state,
        loadingDetailWriterPayment: false,
        errDetailWriterPayment: action.data,
      };

    case LIST_PAYOUT_FINANCE_REQUEST:
      return {
        ...state,
        loadingPayoutFinance: true,
        errPayoutFinance: null,
      };
    case LIST_PAYOUT_FINANCE_SUCCESS:
      return {
        ...state,
        loadingPayoutFinance: false,
        dataPayoutFinance: action.data.data,
        totalPage: action.data.meta.total,
        errPayoutFinance: null,
      };
    case LIST_PAYOUT_FINANCE_FAILURE:
      return {
        ...state,
        loadingPayoutFinance: false,
        errPayoutFinance: action.data,
      };

    default:
      return state;
  }
};
