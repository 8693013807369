import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import MediaShareFormModule from "../../../modules/Admin/MediaShare/MediaShareFormModule";
import {
  getMediaAction,
  getNewsAction,
} from "../../../../redux/action/releaseAction";

const MediaShareEditPage = () => {
  const dispatch = useDispatch();
  const queryParams = useParams();
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Media Distribusi",
    });
    dispatch(getMediaAction());
    dispatch(getNewsAction(queryParams.mediaId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Media Distribusi" menu="distribution">
      <MediaShareFormModule
        typeForm="edit"
        releaseId={queryParams.releaseId}
        mediaId={queryParams.mediaId}
      />
    </DashboardLayout>
  );
};

export default MediaShareEditPage;
