export const UPLOAD_SINGLE_IMAGE_REQUEST = "UPLOAD_SINGLE_IMAGE_REQUEST";
export const UPLOAD_SINGLE_IMAGE_SUCCESS = "UPLOAD_SINGLE_IMAGE_SUCCESS";
export const UPLOAD_SINGLE_IMAGE_FAILURE = "UPLOAD_SINGLE_IMAGE_FAILURE";

export const DELETE_SINGLE_IMAGE_REQUEST = "DELETE_SINGLE_IMAGE_REQUEST";
export const DELETE_SINGLE_IMAGE_SUCCESS = "DELETE_SINGLE_IMAGE_SUCCESS";
export const DELETE_SINGLE_IMAGE_FAILURE = "DELETE_SINGLE_IMAGE_FAILURE";

export const DATA_ADD_DETAIL = "DATA_ADD_DETAIL";
export const DATA_ADD_BRIEF = "DATA_ADD_BRIEF";
export const DATA_ADD_RELEASE = "DATA_ADD_RELEASE";
export const RESET_DATA_ADD = "RESET_DATA_ADD";

export const GET_AGE_REQUEST = "GET_AGE_REQUEST";
export const GET_AGE_SUCCESS = "GET_AGE_SUCCESS";
export const GET_AGE_FAILURE = "GET_AGE_FAILURE";

export const GET_GENDER_REQUEST = "GET_GENDER_REQUEST";
export const GET_GENDER_SUCCESS = "GET_GENDER_SUCCESS";
export const GET_GENDER_FAILURE = "GET_GENDER_FAILURE";

export const GET_SES_REQUEST = "GET_SES_REQUEST";
export const GET_SES_SUCCESS = "GET_SES_SUCCESS";
export const GET_SES_FAILURE = "GET_SES_FAILURE";

export const GET_STAKEHOLDER_REQUEST = "GET_STAKEHOLDER_REQUEST";
export const GET_STAKEHOLDER_SUCCESS = "GET_STAKEHOLDER_SUCCESS";
export const GET_STAKEHOLDER_FAILURE = "GET_STAKEHOLDER_FAILURE";

export const GET_CATEGORY_RELEASE_REQUEST = "GET_CATEGORY_RELEASE_REQUEST";
export const GET_CATEGORY_RELEASE_SUCCESS = "GET_CATEGORY_RELEASE_SUCCESS";
export const GET_CATEGORY_RELEASE_FAILURE = "GET_CATEGORY_RELEASE_FAILURE";

export const GET_DATA_PROVINCES_REQUEST = "GET_DATA_PROVINCES_REQUEST";
export const GET_DATA_PROVINCES_SUCCESS = "GET_DATA_PROVINCES_SUCCESS";
export const GET_DATA_PROVINCES_FAILURE = "GET_DATA_PROVINCES_FAILURE";

export const GET_DATA_DISTRICT_REQUEST = "GET_DATA_DISTRICT_REQUEST";
export const GET_DATA_DISTRICT_SUCCESS = "GET_DATA_DISTRICT_SUCCESS";
export const GET_DATA_DISTRICT_FAILURE = "GET_DATA_DISTRICT_FAILURE";

export const GET_NOTIFICATIONS_REQUEST = "GET_NOTIFICATIONS_REQUEST";
export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_FAILURE = "GET_NOTIFICATIONS_FAILURE";

export const UPDATE_NOTIFICATION_REQUEST = "UPDATE_NOTIFICATION_REQUEST";
export const UPDATE_NOTIFICATION_SUCCESS = "UPDATE_NOTIFICATION_SUCCESS";
export const UPDATE_NOTIFICATION_FAILURE = "UPDATE_NOTIFICATION_FAILURE";

export const RESET_UTILS_STATE = "RESET_UTILS_STATE";

export const DATA_UPDATE_DETAIL = "DATA_UPDATE_DETAIL";
export const DATA_UPDATE_BRIEF = "DATA_UPDATE_BRIEF";
export const DATA_UPDATE_RELEASE = "DATA_UPDATE_RELEASE";
export const RESET_DATA_UPDATE = "RESET_DATA_UPDATE";

export const ANNOUNCEMENTS_REQUEST = "ANNOUNCEMENTS_REQUEST";
export const ANNOUNCEMENTS_SUCCESS = "ANNOUNCEMENTS_SUCCESS";
export const ANNOUNCEMENTS_FAILURE = "ANNOUNCEMENTS_FAILURE";

export const UPLOAD_BRIEF_REQUEST = "UPLOAD_BRIEF_REQUEST";
export const UPLOAD_BRIEF_SUCCESS = "UPLOAD_BRIEF_SUCCESS";
export const UPLOAD_BRIEF_FAILURE = "UPLOAD_BRIEF_FAILURE";

export const GET_PAYMENT_ACCOUNT_REQUEST = "GET_PAYMENT_ACCOUNT_REQUEST";
export const GET_PAYMENT_ACCOUNT_SUCCESS = "GET_PAYMENT_ACCOUNT_SUCCESS";
export const GET_PAYMENT_ACCOUNT_FAILURE = "GET_PAYMENT_ACCOUNT_FAILURE";

export const PAYMENT_ACCOUNT_DETAIL_REQUEST = "PAYMENT_ACCOUNT_DETAIL_REQUEST";
export const PAYMENT_ACCOUNT_DETAIL_SUCCESS = "PAYMENT_ACCOUNT_DETAIL_SUCCESS";
export const PAYMENT_ACCOUNT_DETAIL_FAILURE = "PAYMENT_ACCOUNT_DETAIL_FAILURE";

export const GET_HELP_CATEGORY_REQUEST = "GET_HELP_CATEGORY_REQUEST";
export const GET_HELP_CATEGORY_SUCCESS = "GET_HELP_CATEGORY_SUCCESS";
export const GET_HELP_CATEGORY_FAILURE = "GET_HELP_CATEGORY_FAILURE";

export const CLIENT_DATA_PROJECT = "CLIENT_DATA_PROJECT";

export const GET_BUSINESS_REQUEST = "GET_BUSINESS_REQUEST";
export const GET_BUSINESS_SUCCESS = "GET_BUSINESS_SUCCESS";
export const GET_BUSINESS_FAILURE = "GET_BUSINESS_FAILURE";

export const ADD_DATA_ACCOUNT = "ADD_DATA_ACCOUNT";