import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { InputText } from "primereact/inputtext";

import { getUsersAction } from "../../../../../redux/action/userAction";

const FilterListUser = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { parsed } = props;

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
        orderField: parsed.sort,
        orderDir: "desc",
        type: props.type,
      };

      history.push(
        `/admin/pm/list-user/${props.type}s?search=${search || ""}&page=1`
      );
      dispatch(getUsersAction(params));
    }, 1000)
  ).current;

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter">
        <div className="filter-field">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block search-inputtext"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <p>
            Cari berdasarkan<span> Nama Project</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FilterListUser;
