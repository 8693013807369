import {
  UPLOAD_SINGLE_IMAGE_REQUEST,
  GET_HELP_CATEGORY_REQUEST,
  DELETE_SINGLE_IMAGE_REQUEST,
  DATA_ADD_BRIEF,
  DATA_ADD_DETAIL,
  DATA_ADD_RELEASE,
  RESET_DATA_ADD,
  GET_AGE_REQUEST,
  GET_GENDER_REQUEST,
  GET_CATEGORY_RELEASE_REQUEST,
  GET_SES_REQUEST,
  GET_STAKEHOLDER_REQUEST,
  GET_DATA_PROVINCES_REQUEST,
  GET_DATA_DISTRICT_REQUEST,
  GET_NOTIFICATIONS_REQUEST,
  UPDATE_NOTIFICATION_REQUEST,
  RESET_UTILS_STATE,
  DATA_UPDATE_BRIEF,
  DATA_UPDATE_DETAIL,
  DATA_UPDATE_RELEASE,
  RESET_DATA_UPDATE,
  ANNOUNCEMENTS_REQUEST,
  UPLOAD_BRIEF_REQUEST,
  GET_PAYMENT_ACCOUNT_REQUEST,
  PAYMENT_ACCOUNT_DETAIL_REQUEST,
  CLIENT_DATA_PROJECT,
  GET_BUSINESS_REQUEST,
  ADD_DATA_ACCOUNT,
} from "../constant/utilsConstant";

export const deleteImageAction = (url) => {
  return {
    type: DELETE_SINGLE_IMAGE_REQUEST,
    url,
  };
};

export const uploadImageAction = (file, path) => {
  return {
    type: UPLOAD_SINGLE_IMAGE_REQUEST,
    file,
    path,
  };
};

export const dataAddDetailAction = (data) => {
  return {
    type: DATA_ADD_DETAIL,
    data,
  };
};

export const dataAddBriefAction = (data) => {
  return {
    type: DATA_ADD_BRIEF,
    data,
  };
};

export const dataAddReleaseAction = (data) => {
  return {
    type: DATA_ADD_RELEASE,
    data,
  };
};

export const resetDataAddProjectAction = () => {
  return {
    type: RESET_DATA_ADD,
  };
};

export const getAgeAction = () => {
  return {
    type: GET_AGE_REQUEST,
  };
};

export const getGenderAction = () => {
  return {
    type: GET_GENDER_REQUEST,
  };
};

export const getSesAction = () => {
  return {
    type: GET_SES_REQUEST,
  };
};

export const getStakeholderAction = () => {
  return {
    type: GET_STAKEHOLDER_REQUEST,
  };
};

export const getCategoryReleaseAction = () => {
  return {
    type: GET_CATEGORY_RELEASE_REQUEST,
  };
};

export const getProvincesAction = () => {
  return {
    type: GET_DATA_PROVINCES_REQUEST,
  };
};

export const getDistrictAction = (id) => {
  return {
    type: GET_DATA_DISTRICT_REQUEST,
    id,
  };
};

export const getNotificationsAction = (uid, userType) => {
  return {
    type: GET_NOTIFICATIONS_REQUEST,
    uid,
    userType,
  };
};

export const updateNotificationAction = (id) => {
  return {
    type: UPDATE_NOTIFICATION_REQUEST,
    id,
  };
};

export const resetUtilsStateAction = () => {
  return {
    type: RESET_UTILS_STATE,
  };
};

export const dataUpdateDetailAction = (data) => {
  return {
    type: DATA_UPDATE_DETAIL,
    data,
  };
};

export const dataUpdateBriefAction = (data) => {
  return {
    type: DATA_UPDATE_BRIEF,
    data,
  };
};

export const dataUpdateReleaseAction = (data) => {
  return {
    type: DATA_UPDATE_RELEASE,
    data,
  };
};

export const resetDataUpdateProjectAction = () => {
  return {
    type: RESET_DATA_UPDATE,
  };
};

export const announcementsAction = () => {
  return {
    type: ANNOUNCEMENTS_REQUEST,
  };
};

export const uploadBriefAction = (file, path) => {
  return {
    type: UPLOAD_BRIEF_REQUEST,
    file,
    path,
  };
};

export const paymentAccountAction = () => {
  return {
    type: GET_PAYMENT_ACCOUNT_REQUEST,
  };
};

export const paymentAccountDetailAction = (id) => {
  return {
    type: PAYMENT_ACCOUNT_DETAIL_REQUEST,
    id,
  };
};

export const clientDataProjectAction = (data) => {
  return {
    type: CLIENT_DATA_PROJECT,
    data,
  };
};

export const helpCategoryAction = () => {
  return {
    type: GET_HELP_CATEGORY_REQUEST,
  };
};

export const getBusinessAction = () => {
  return {
    type: GET_BUSINESS_REQUEST,
  };
};

export const addDataAccountAction = (data) => {
  return {
    type: ADD_DATA_ACCOUNT,
    data,
  };
};
