import React from "react";

const ButtonDefault = (props) => {
  // :::::::::::: Note style
  // Type  : rounded, bordered, rounded-bordered
  // Color : danger, grey

  return (
    <button
      className={`button-style ${props.type} ${props.color} ${props.size} ${props.className}`}
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      type={props.typeButton}
    >
      {props.loading ? "MEMPROSES..." : `${props.label}`}
    </button>
  );
};

export default ButtonDefault;
