import React from "react";
import { Link } from "react-router-dom";

const ButtonIconLeft = (props) => {
  // :::::::::::: Note style
  // Type  : rounded, bordered, rounded-bordered, unbordered
  // Color : danger, grey

  return props.link ? (
    <Link
      className={`button-icon ${props.type} ${props.color} ${props.size} ${props.className}`}
      to={props.link}
    >
      <img src={props.image} alt="icon" />
      <span>{props.label}</span>
    </Link>
  ) : (
    <button
      className={`button-icon ${props.type} ${props.color} ${props.size} ${props.className}`}
      disabled={props.disabled || props.loading}
      onClick={props.onClick}
      type={props.typeButton}
    >
      {props.loading ? (
        "MEMPROSES..."
      ) : (
        <>
          <img src={props.image} alt="icon" />
          <span>{props.label}</span>
        </>
      )}
    </button>
  );
};

export default ButtonIconLeft;
