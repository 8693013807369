import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Header } from "../../_metronic/layout/components/header/Header";
import HeaderMobileLanding from "../components/header/HeaderMobileLanding";
import { AsideClient } from "../../_metronic/layout/components/aside/AsideClient";
import { SubHeader } from "../../_metronic/layout/components/subheader/SubHeader";
import { usePageTracking } from "../helpers/pageTracking";
import { checkDevice } from "../helpers/deviceDetect";
import { getSettingSiteAction } from "../../redux/action/landingpageAction";

function DashboardClientLayout({ children, title, menu, mobileTitle }) {
  usePageTracking();
  const { users, isLoggined } = useSelector((state) => state.auth);
  const { dataGetSettingSite, loadingGetSettingSite } = useSelector(
    (state) => state.landingpage
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const [isMobile, setIsMobile] = useState(checkDevice());

  useEffect(() => {
    if (dataGetSettingSite === null) {
      dispatch(getSettingSiteAction());
    }
    setIsMobile(checkDevice);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (users === null && !isLoggined) {
      history.push("/client/login");
    }
    if (
      users &&
      (users?.user?.type === "pm" || users?.user?.type === "general_pm")
    ) {
      history.push("/admin/pm/list-project");
    }
    if (users && users?.user?.type === "writer") {
      history.push("/dashboard");
    }
    if (users && users?.user?.type === "finance") {
      history.push("/admin/finance/list-invoice");
    }
  });

  return (
    <div className="d-flex flex-column flex-root">
      <div className="d-flex flex-row flex-column-fluid page">
        <AsideClient menu={menu} />
        <div className="d-flex flex-column flex-row-fluid wrapper">
          {isMobile ? (
            <HeaderMobileLanding
              dataSite={dataGetSettingSite}
              loading={loadingGetSettingSite}
            />
          ) : (
            <Header title={title} mobileTitle={mobileTitle} type="client" />
          )}
          <div className="content d-flex flex-column flex-column-fluid content-dashboard">
            <SubHeader title={title} />
            <div className="d-flex flex-column-fluid">
              <div className="container-fluid client">{children}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardClientLayout;
