import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import DetailArticleModule from "../../modules/User/DetailArticle/DetailArticleModule";
import HeaderDashboardUser from "../../components/header/HeaderDashboardUser";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";

import {
  getDetailArticleAction,
  getListArticleAction,
} from "../../../redux/action/landingpageAction";
import { titlePage } from "../../helpers/titleHead";

const DetailArticlePage = () => {
  const dispatch = useDispatch();
  const QueryParam = useParams();

  const { users, isLoggedIn } = useSelector((state) => state.auth);
  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - Detail Artikel",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getDetailArticleAction(QueryParam.articleId));
    dispatch(getListArticleAction());
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [QueryParam.articleId]);
  return (
    <div className="landing-page">
      {isLoggedIn ? (
        <HeaderDashboardUser
          dataSite={dataGetSettingSite}
          loading={loadingGetSettingSite}
          users={users}
        />
      ) : (
        <Header dataSite={dataGetSettingSite} loading={loadingGetSettingSite} />
      )}
      <DetailArticleModule />
      <Footer dataSite={dataGetSettingSite} loading={loadingGetSettingSite} />
    </div>
  );
};

export default DetailArticlePage;
