import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Dialog } from "primereact/dialog";

import EditIcon from "../../../../assets/img/edit.svg";
import ButtonDefault from "../../../../components/button/ButtonDefault";
import DialogAlert from "../../../../components/dialog/DialogAlert";

import {
  approvePayoutAction,
  resetStateFinanceAction,
} from "../../../../../redux/action/financeAction";
import { uploadImageAction } from "../../../../../redux/action/utilsAction";

const EditPayoutPopup = (props) => {
  const { data } = props;
  const dispatch = useDispatch();

  const [statusState, statusStateChange] = useState({ value: "", error: "" });
  const [fileUrl, fileUrlChange] = useState("");
  const [imageState, imangeStateChange] = useState({
    value: null,
    name: "",
    error: "",
  });
  const [openDialog, setOpenDialog] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const { users } = useSelector((state) => state.auth);
  const {
    loadingUploadImage,
    successUploadImage,
    dataUploadImage,
  } = useSelector((state) => state.utils);

  const { loadingApprovePayout, successApprovePayout } = useSelector(
    (state) => state.finance
  );

  const statusList = [
    { name: "Proses", code: "process" },
    { name: "Selesai", code: "success" },
    { name: "Tolak", code: "reject" },
  ];

  useEffect(() => {
    if (successUploadImage) {
      fileUrlChange(dataUploadImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    if (successApprovePayout) {
      setOpenDialog(false);
      setOpenAlert(true);
    }
  }, [successApprovePayout]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        imangeStateChange({
          ...imageState,
          error: "Ukuran file lebih dari 1MB",
        });
      } else if (acceptedFiles.length > 1) {
        imangeStateChange({ ...imageState, error: "Silakan pilih satu file" });
      } else {
        imangeStateChange({
          value: acceptedFiles[0],
          error: "",
          name: acceptedFiles[0].name,
        });

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/payout/${times}/${users.uid}/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
      }
    },
  });

  const onStatusChange = (e) => {
    statusStateChange({ value: e.value, error: "" });
  };

  const approvePayout = () => {
    let dataQuery = {
      status: statusState?.value?.code,
    };

    if (fileUrl?.length > 0) {
      dataQuery = { ...dataQuery, image: fileUrl };
    } else {
      dataQuery = { ...dataQuery };
    }

    if (statusState?.value?.length < 1) {
      statusStateChange({ ...statusState, error: "Status harus diisi" });
    } else {
      dispatch(approvePayoutAction(data?._id, dataQuery));
    }
  };

  const closeSuccess = () => {
    setOpenAlert(false);
    dispatch(resetStateFinanceAction());
  };

  return (
    <div className="normal-dialog d-inline-block">
      <ButtonDefault
        onClick={() => setOpenDialog(true)}
        label="Approve"
        className="p-button p-button-primary button-table"
        size="fit"
      >
        <img src={EditIcon} alt="" />
      </ButtonDefault>

      <Dialog
        header={`Approve Payout ${data?.writerName}`}
        visible={openDialog}
        style={{ width: "502px" }}
        onHide={() => setOpenDialog(false)}
      >
        <div className="edit-form">
          <div className="p-filed mb-3">
            <label>Nomor Rekening</label>
            <InputText
              value={data?.writerBankAccount}
              className="disabled"
              disabled
            />
          </div>
          <div className="p-filed mb-3">
            <label className="d-block">Status</label>
            <Dropdown
              value={statusState.value}
              options={statusList}
              onChange={onStatusChange}
              optionLabel="name"
              placeholder="Pilih Status"
              className={`dropdown-register ${statusState.error &&
                "p-invalid"}`}
            />
            {statusState.error && (
              <small id="error" className="p-error p-d-block">
                {statusState.error}
              </small>
            )}
          </div>
          <div className="p-filed mb-3">
            <label className="d-block">Bukti Pembayaran</label>
            <>
              <div {...getRootProps({ className: "file-upload" })}>
                <input {...getInputProps()} />
                <span className="file-name">
                  {imageState?.value !== null
                    ? imageState?.name
                    : "Upload Bukti Pembayaran"}
                </span>
                {loadingUploadImage ? (
                  <span className="file-button">Mengunggah...</span>
                ) : (
                  <span className="file-button" onClick={open}>
                    {imageState?.value !== null ? "Ubah File" : "Pilih File"}
                  </span>
                )}
              </div>
              {imageState?.error?.length > 0 && (
                <span className="p-col-12 text-helper text-danger">
                  {imageState?.error}
                </span>
              )}
            </>
          </div>
          <div className="p-d-flex p-jc-end p-align-center mt-3">
            <ButtonDefault
              label="Batal"
              onClick={() => setOpenDialog(false)}
              type="rounded-bordered"
              className="mr-3"
              disabled={loadingApprovePayout}
            />

            <ButtonDefault
              label="Simpan"
              onClick={() => approvePayout()}
              type="rounded"
              loading={loadingApprovePayout}
            />
          </div>
        </div>
      </Dialog>

      <DialogAlert
        type="success"
        visible={openAlert}
        closeDialog={() => closeSuccess()}
        title="Update Status Berhasil"
        text="Status payout berhasil diubah"
      />
    </div>
  );
};

export default EditPayoutPopup;
