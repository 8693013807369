import {
  GET_PIC_LIST_REQUEST,
  GET_PIC_DETAIL_REQUEST,
  ADD_PIC_REQUEST,
  UPDATE_PIC_REQUEST,
  DELETE_PIC_REQUEST,
  ASSIGN_PIC_REQUEST,
  RESET_PIC_STATE
} from "../constant/clientPicConstant";

export const getPICListAction = (params) => {
  return {
    type: GET_PIC_LIST_REQUEST,
    params,
  };
};

export const getPICDetailAction = (id) => {
  return {
    type: GET_PIC_DETAIL_REQUEST,
    id,
  };
};

export const addPICAction = (data) => {
  return {
    type: ADD_PIC_REQUEST,
    data,
  };
};

export const updatePICAction = (id, data) => {
  return {
    type: UPDATE_PIC_REQUEST,
    id,
    data,
  };
};

export const deletePICAction = (id) => {
  return {
    type: DELETE_PIC_REQUEST,
    id,
  };
};

export const assignPICAction = (id, data) => {
  return {
    type: ASSIGN_PIC_REQUEST,
    id,
    data,
  };
};

export const resetPICAction = () => {
  return {
    type: RESET_PIC_STATE,
  };
};
