/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";

import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ActionBody from "../../../components/datatableBody/ActionBody";
import DateBody from "../../../components/datatableBody/DateBody";
import TextBody from "../../../components/datatableBody/TextBody";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import StatusBadgeBody from "../../../components/datatableBody/StatusBadgeBody";
import TableLoader from "../../../components/loading/tableLoader";
import AdminPaginate from "../../../components/paging/pagination";
import DialogAlert from "../../../components/dialog/DialogAlert";

import FilterUser from "./Components/FilterUser";
import {
  getUsersAction,
  resetStateUserAction,
  updateStatusApplicantAction,
} from "../../../../redux/action/userAction";

const ListUserApplicantsModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogSuccess, setDialogSuccess] = useState(false);

  const {
    dataGetUsers,
    loadingGetUsers,
    totalPage,
    userParams,
    successUpdateStatusApplicant,
    loadingUpdateStatusApplicant,
  } = useSelector((state) => state.user);

  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successUpdateStatusApplicant) {
      setTimeout(() => {
        const params = {
          ...userParams,
          type: "applicant",
        };
        dispatch(getUsersAction(params));
        dispatch(resetStateUserAction());
      }, 850);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateStatusApplicant]);

  const dateBodyTemplate = (item) => {
    return (
      <DateBody
        date={item?.createdAt}
        link={`/admin/pm/detail-user/${item?._id}`}
      />
    );
  };

  const nameBodyTemplate = (item) => {
    return (
      <TextBody text={item?.name} link={`/admin/pm/detail-user/${item?._id}`} />
    );
  };

  const emailBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.email}
        link={`/admin/pm/detail-user/${item?._id}`}
      />
    );
  };

  const phoneBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.phone?.replace("+62", "0") || "-"}
        link={`/admin/pm/detail-user/${item?._id}`}
      />
    );
  };

  const statusBodyTemplate = (item) => {
    return (
      <StatusBadgeBody
        status={item?.status}
        link={`/admin/pm/detail-user/${item?._id}`}
        userType={users?.user?.type}
      />
    );
  };

  const actionBodyTemplate = (item) => {
    return (
      <ActionBody
        linkDetail={`/admin/pm/detail-user/${item?._id}`}
        linkEdit={`/admin/pm/edit-user/${item?._id}`}
        id={item?._id}
        name={item?.name || item?.email}
        onDelete={() => updateStatus(item?._id, "deleted")}
        loading={loadingUpdateStatusApplicant}
        isSuccess={successUpdateStatusApplicant}
        disableButton={item?.nProjectJoined > 0}
        deleteOnly
      />
    );
  };

  const updateStatus = (id, data) => {
    dispatch(updateStatusApplicantAction(id, { status: data }));
  };
  const closeSuccess = () => {
    dispatch(resetStateUserAction());
    setDialogSuccess(false);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search,
      page: page + 1,
      perpage: 10,
      orderField: parsed.sort,
      orderDir: parsed.order,
      type: "applicant",
    };
    history.push(
      `/admin/pm/list-user/applicants?search=${parsed.search ||
        ""}&page=${page + 1}`
    );
    dispatch(getUsersAction(params));
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Pemohon
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterUser parsed={parsed} type="applicant" />
          {loadingGetUsers ? (
            <TableLoader />
          ) : (
            <>
              {dataGetUsers?.length < 1 || dataGetUsers === null ? (
                <DataEmpty />
              ) : (
                <>
                  <DataTable value={dataGetUsers} className="no-padding">
                    <Column header="Tanggal Daftar" body={dateBodyTemplate} />
                    <Column header="Nama" body={nameBodyTemplate} />
                    <Column header="Email" body={emailBodyTemplate} />
                    <Column
                      header="Telepon"
                      body={phoneBodyTemplate}
                      field="nohp"
                    />
                    <Column header="Status" body={statusBodyTemplate} />

                    <Column
                      headerClassName="action"
                      body={actionBodyTemplate}
                    />
                  </DataTable>
                </>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Kirim Ulang"}
        text={"Tautan berhasil dikirim ke email user"}
      />
    </>
  );
};

export default ListUserApplicantsModule;
