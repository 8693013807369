import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { RadioButton } from "primereact/radiobutton";

import { getOrderAction } from "../../../../redux/action/orderAction";

function PopupFilter(props) {
  const { show, parsed, closePopup } = props;
  const dispatch = useDispatch();
  const history = useHistory();

  const [category, setCategory] = useState("");
  const [status, setStatus] = useState("");

  const submitFilter = () => {
    const params = {
      q: parsed.search,
      category: category,
      status: status,
      orderField: parsed?.order || "",
      orderDir: parsed?.order === "title" ? "asc" : "desc",
      page: 1,
      perpage: 10,
    };
    dispatch(getOrderAction(params));
    closePopup();
    history.push(
      `/client/my-order/process?search=${parsed.search ||
        ""}&category=${category}&status=${status}&order=${parsed?.order ||
        ""}&page=1`
    );
  };

  const resetFilter = () => {
    if (category?.length > 0 && status?.length > 0) {
      const params = {
        q: parsed.search,
        category: "",
        status: "",
        orderField: parsed?.order || "",
        orderDir: parsed?.order === "createdAt" ? "desc" : "asc",
        page: 1,
        perpage: 10,
      };
      dispatch(getOrderAction(params));
    }

    setCategory("");
    setStatus("");
    closePopup();
  };

  return (
    <div className={`popup-myrelease-filter ${!show && "d-none"}`}>
      <div className="filter-body">
        <div className="sort-by">
          <h3 className="p-mb-3 p-mt-4">Kategori Pesanan</h3>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category1"
              name="category"
              value=""
              onChange={(e) => setCategory(e.value)}
              checked={category === ""}
            />
            <label htmlFor="category1" className="p-mb-0">
              Semua
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category2"
              name="category"
              value="article"
              onChange={(e) => setCategory(e.value)}
              checked={category === "article"}
            />
            <label htmlFor="category2" className="p-mb-0">
              Artikel
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category3"
              name="category"
              value="pr"
              onChange={(e) => setCategory(e.value)}
              checked={category === "pr"}
            />
            <label htmlFor="category3" className="p-mb-0">
              Press Release
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="category4"
              name="category"
              value="distribution_media"
              onChange={(e) => setCategory(e.value)}
              checked={category === "distribution_media"}
            />
            <label htmlFor="category4" className="p-mb-0">
              Media Distribusi
            </label>
          </div>
          <h3 className="p-mb-3 p-mt-4">Status Pesanan</h3>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status1"
              name="status"
              value=""
              onChange={(e) => setStatus(e.value)}
              checked={status === ""}
            />
            <label htmlFor="status1" className="p-mb-0">
              Semua
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status2"
              name="status"
              value="payment_waiting"
              onChange={(e) => setStatus(e.value)}
              checked={status === "payment_waiting"}
            />
            <label htmlFor="status2" className="p-mb-0">
              Belum Dibayar
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status3"
              name="status"
              value="confirmation_waiting"
              onChange={(e) => setStatus(e.value)}
              checked={status === "confirmation_waiting"}
            />
            <label htmlFor="status3" className="p-mb-0">
              Menunggu Konfirmasi
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status4"
              name="status"
              value="confirmed"
              onChange={(e) => setStatus(e.value)}
              checked={status === "confirmed"}
            />
            <label htmlFor="status4" className="p-mb-0">
              Terkonfirmasi
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status5"
              name="status"
              value="rejected"
              onChange={(e) => setStatus(e.value)}
              checked={status === "rejected"}
            />
            <label htmlFor="status5" className="p-mb-0">
              Ditolak
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status6"
              name="status"
              value="published_waiting"
              onChange={(e) => setStatus(e.value)}
              checked={status === "published_waiting"}
            />
            <label htmlFor="status6" className="p-mb-0">
              Menunggu Publish
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status7"
              name="status"
              value="ongoing"
              onChange={(e) => setStatus(e.value)}
              checked={status === "ongoing"}
            />
            <label htmlFor="status7" className="p-mb-0">
              Berjalan
            </label>
          </div>
          <div className="p-field-radiobutton">
            <RadioButton
              inputId="status8"
              name="status"
              value="done"
              onChange={(e) => setStatus(e.value)}
              checked={status === "done"}
            />
            <label htmlFor="status8" className="p-mb-0">
              Selesai
            </label>
          </div>
        </div>
      </div>
      <div className="filter-footer">
        <div className="button">
          <button className="p-btn reset" onClick={() => resetFilter()}>
            {category?.length > 0 && status?.length > 0 ? "Reset" : "Batal"}
          </button>
          <button className="p-btn btn-primary" onClick={() => submitFilter()}>
            Terapkan
          </button>
        </div>
      </div>
    </div>
  );
}

export default PopupFilter;
