import apiStrapi from "./apiStrapi";

const responseApi = async (url, data) => {
  try {
    const response = await apiStrapi(`${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: JSON.stringify(data),
    });

    return response.data;
  } catch (error) {
    console.error("Error saving talking point:", error);
    throw error;
  }
};

export const saveHeadline = async (data) => {
  return responseApi("/headlines", data);
};
