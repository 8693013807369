import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { titlePage } from "../../helpers/titleHead";

import {
  getBannerAction,
  getHowDoesItWorkAction,
  getBenefitAction,
  getTestimonialAction,
  getTopWriterAction,
  getListArticleAction,
} from "../../../redux/action/landingpageAction";

import LandingLayout from "../../layouts/landingLayout";
import LandingpageClientModule from "../../modules/Client/LandingPage/LandingPageClientModule";

const LandingPage = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    titlePage({
      title: "IRIS - Client",
    });

    dispatch(getBannerAction());
    dispatch(getHowDoesItWorkAction());
    dispatch(getBenefitAction());
    dispatch(getTestimonialAction());
    dispatch(getTopWriterAction());
    dispatch(getListArticleAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {
    loadingGetBanner,
    dataGetBanner,
    loadingGetHowDoesItWork,
    dataGetHowDoesItWork,
    loadingGetBenefit,
    dataGetBenefit,
    loadingGetTestimonial,
    dataGetTestimonial,
    loadingGetTopWriter,
    dataGetTopWriter,
    dataGetArticles,
  } = useSelector((state) => state.landingpage);

  return (
    <LandingLayout title="Client">
      <LandingpageClientModule
        dataBanner={dataGetBanner}
        loadingBanner={loadingGetBanner}
        dataHowDoesItWork={dataGetHowDoesItWork}
        loadingHowDoesItWork={loadingGetHowDoesItWork}
        dataBenefit={dataGetBenefit}
        loadingBenefit={loadingGetBenefit}
        dataTestimonial={dataGetTestimonial}
        loadingTestimonial={loadingGetTestimonial}
        dataTopWriter={dataGetTopWriter}
        loadingTopWriter={loadingGetTopWriter}
        dataArticle={dataGetArticles}
      />
    </LandingLayout>
  );
};

export default LandingPage;
