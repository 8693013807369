/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

import { Checkbox } from "primereact/checkbox";
import Dropdown from "react-bootstrap/Dropdown";

import TitleSection from "./Components/TitleSection";
import ContentSection from "./Components/ContentSection";

import iconDownload from "../../../assets/img/download-blue.svg";
import FullContentLoading from "../../../components/loading/fullContentLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";
import RichtextWithoutToolbar from "../../../components/richtext/richtextWithoutToolbar";

import firebase from "../../../../redux/service/firebase/firebase";
import {
  sendChatAction,
  sendNotifAction,
  unpinChatAction,
  sendChatClientAction,
  sendChatClientFsAction,
} from "../../../../redux/action/chatAction";
import {
  updateStatusProjectAction,
  resetStateProjectAction,
  updateArticleAction,
  getArticleAction,
  submitArticleAction,
} from "../../../../redux/action/projectAction";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import { getToken } from "../../../../redux/service/firebase/auth";
import emptyImage from "../../../assets/img/empty-list.png";
import ButtonDefault from "../../../components/button/ButtonDefault";
import StatusBadge from "../../../components/badge/StatusBadge";

const PreviewProjectModule = (props) => {
  const { articleData, chatId, projectData } = props;
  const firestore = firebase.firestore();
  const history = useHistory();
  const dispatch = useDispatch();

  const [message, setMessage] = useState("");
  const [messageClient, setMessageClient] = useState("");
  const [renderChats, setRenderChats] = useState(null);
  const [renderChatsClient, setRenderChatsClient] = useState(null);
  const [dialogAlert, setDialogAlert] = useState({
    dialog: false,
    type: null,
    title: null,
    desc: null,
  });
  const [typeStatus, setTypeStatus] = useState(null);
  const [pinned, setPinned] = useState(false);
  const [tab, setTab] = useState("writer");
  const [isEdit, setIsEdit] = useState(false);

  const [titleValue, setTitleValue] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [contentValue, setContentValue] = useState("");
  const [contentCount, setContentCount] = useState("");

  const { users } = useSelector((state) => state.auth);
  const {
    loadingGetArticle,
    loadingUpdateStatusProject,
    successUpdateStatusProject,
    errUpdateStatusProject,
    successSubmitArticleProject,
    errSubmitArticleProject,
  } = useSelector((state) => state.project);
  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );

  const isAssigned =
    users?.user?.type === "pm" || articleData?.pmData?.pmId === users?.uid;
  const isWinnerFull = projectData?.totalWinner === projectData?.winnerQuota;

  useEffect(() => {
    if (successUploadImage) {
      setImageValue(dataUploadImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    const arrayStatus = ["revision", "draft", "unpublished"];
    if (arrayStatus?.includes(articleData?.status)) {
      setIsEdit(true);
    } else if (
      articleData?.userData?.type === "pm" &&
      articleData?.clientStatus === "revision" &&
      articleData?.status === "pm_approved"
    ) {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }

    if (articleData?.userData?.type === "pm") {
      setTab("client");
    } else {
      setTab("writer");
    }
  }, [articleData]);

  useEffect(() => {
    if (isEdit) {
      setTitleValue(articleData?.articleTitle);
      setContentValue(articleData?.articleContent);
      setImageValue(articleData?.articleImage);
      const counter = articleData?.articleContent
        ?.replace(/<[^>]*>/g, " ")
        .replace(/\s+/g, " ")
        .trim();
      setContentValue(counter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [articleData, isEdit]);

  useEffect(() => {
    if (chatId) {
      if (tab === "writer") {
        getChatList(chatId);
        const notif = {
          isRead: false,
          userType: "pm",
        };
        dispatch(updateArticleAction(chatId, { chat: notif }));
      } else {
        getChatListClient(chatId);

        const notif = {
          isRead: false,
          userType: "pm",
        };
        dispatch(updateArticleAction(chatId, { chatClient: notif }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatId, tab]);

  useEffect(() => {
    if (successSubmitArticleProject) {
      setDialogAlert({
        dialog: true,
        type: "success",
        title: "BERHASIL!",
        desc: "Berhasil ubah dan publish Tulisan",
      });
      setIsEdit(false);
      dispatch(getArticleAction(props.chatId));
    }

    if (errSubmitArticleProject) {
      setDialogAlert({
        dialog: true,
        type: "error",
        title: "GAGAL!",
        desc: "Tulisan gagal ubah dan publish",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSubmitArticleProject, errSubmitArticleProject]);

  useEffect(() => {
    if (successUpdateStatusProject) {
      if (typeStatus === "published") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          desc: "Berhasil Publish Tulisan",
        });
      } else if (typeStatus === "winner") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          desc:
            "Berhasil memilih pemenang, sistem akan mengirimkan notifikasi ke email penulis",
        });
      } else if (typeStatus === "send_client") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          desc:
            "Berhasil mengirim ke klien, sistem akan mengirimkan notifikasi ke klien",
        });
      } else if (typeStatus === "revision") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          desc: "Permintaan revisi berhasil dikirim",
        });
      } else if (typeStatus === "removed") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          desc: "Penulis berhasil dinonaktifkan dari project",
        });
      } else if (typeStatus === "unpublished") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          desc: "Tulisan gagal di unpublish",
        });
      } else {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "BERHASIL!",
          desc:
            "Berhasil tolak tulisan,Sistem akan mengirimkan notifikasi ke email penulis",
        });
      }
      dispatch(getArticleAction(props.chatId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateStatusProject]);

  useEffect(() => {
    if (errUpdateStatusProject) {
      if (typeStatus === "published") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "GAGAL!",
          desc: "Tulisan gagal dipublikasikan",
        });
      } else if (typeStatus === "winner") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "GAGAL!",
          desc: isWinnerFull
            ? "Kuota pemenang terpenuhi"
            : "Project sudah melewati deadline",
        });
      } else if (typeStatus === "revision") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "GAGAL!",
          desc: "Permintaan Revisi gagal",
        });
      } else if (typeStatus === "send_client") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "GAGAL!",
          desc: "Tulisan gagal dikirim ke klien",
        });
      } else if (typeStatus === "unpublished") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "GAGAL!",
          desc: "Gagal unpublish tulisan",
        });
      } else if (typeStatus === "removed") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "GAGAL!",
          desc: "Penulis berhasil dinonaktifkan dari project",
        });
      } else {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "GAGAL!",
          desc: "Project tidak bisa ditolak",
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errUpdateStatusProject]);

  const updateStatus = (dataProject) => {
    const query = dataProject.query ? dataProject.query : "";
    dispatch(
      updateStatusProjectAction(dataProject.id, dataProject.data, query)
    );
    setTypeStatus(dataProject.type);
  };

  const closeAlert = () => {
    setDialogAlert({
      dialog: false,
      type: null,
      title: null,
      desc: null,
      loading: null,
      actionText: null,
      actionDialog: null,
      cancelText: null,
    });
    setTypeStatus(null);
    dispatch(resetStateProjectAction());
  };

  const getChatList = async (id) =>
    await firestore
      .collection("writerProjects")
      .doc(id)
      .collection("chats")
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            _id: doc.id,
          })
        );

        let chatList = [];
        const receiverChats = temp.filter(
          (chat) => chat?.user?.id !== users?.uid
        );
        const receiverPhoto =
          receiverChats[receiverChats?.length - 1]?.user?.photoURL;
        const receiverName =
          receiverChats[receiverChats?.length - 1]?.user?.name;
        temp.map((chat) => {
          if (chat?.user?.id !== users?.uid) {
            chat.user.photoURL = receiverPhoto;
            chat.user.name = receiverName;
          }
          return chatList.push(chat);
        });
        setRenderChats(chatList);
      });

  const sendingChat = () => {
    const dataChat = {
      createdAt: new Date().getTime(),
      message: message,
      isRead: false,
      user: {
        id: users?.uid,
        name: users?.user?.name,
        type: users?.user?.type,
      },
      type: "message",
    };
    if (message.trim() !== "" && message !== "<p><br></p>") {
      if (pinned) {
        const data = {
          ...dataChat,
          isPinned: pinned,
          pinnedAt: new Date().getTime(),
        };
        dispatch(sendChatAction(props.chatId, data));
        dispatch(sendNotifAction(props.chatId, data));
        setPinned(false);
      } else {
        dispatch(sendChatAction(props.chatId, dataChat));
        dispatch(sendNotifAction(props.chatId, dataChat));
      }

      const notif = {
        isRead: true,
        userType: "pm",
      };
      dispatch(updateArticleAction(props.chatId, { chat: notif }));
      setMessage("");
      dispatch(resetUtilsStateAction());
    }
  };

  const getChatListClient = async (id) =>
    await firestore
      .collection("writerProjects")
      .doc(id)
      .collection("clientChats")
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            _id: doc.id,
          })
        );

        let chatList = [];
        const receiverChats = temp.filter(
          (chat) => chat?.user?.id !== users?.uid
        );
        const receiverPhoto =
          receiverChats[receiverChats?.length - 1]?.user?.photoURL;
        const receiverName =
          receiverChats[receiverChats?.length - 1]?.user?.name;
        temp.map((chat) => {
          if (chat?.user?.id !== users?.uid) {
            chat.user.photoURL = receiverPhoto;
            chat.user.name = receiverName;
          }
          return chatList.push(chat);
        });
        setRenderChatsClient(chatList);
      });

  const sendingChatClient = () => {
    const dataChat = {
      createdAt: new Date().getTime(),
      message: messageClient,
      isRead: false,
      user: {
        id: users?.uid,
        name: users?.user?.name,
        type: users?.user?.type,
      },
      type: "message",
    };
    if (messageClient.trim() !== "" && messageClient !== "<p><br></p>") {
      dispatch(sendChatClientAction(props.chatId, dataChat));
      dispatch(sendChatClientFsAction(props.chatId, dataChat));

      const notif = {
        isRead: true,
        userType: "pm",
      };
      dispatch(updateArticleAction(props.chatId, { clientChat: notif }));
      setMessageClient("");
      dispatch(resetUtilsStateAction());
    }
  };

  const unpinned = (data) => {
    dispatch(unpinChatAction(props.chatId, data));
  };

  const downloadFile = async (type) => {
    const token = await getToken();
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1/chats/users/article/${props.chatId}?token=${token}&type=${type}`,
      "_blank"
    );
  };

  const openAlertKickWriter = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Anda Yakin Ingin Menonaktifkan Penulis Ini?",
      desc:
        "Penulis yang dinonaktifkan tidak dapat mengirim tulisan ke project ini",
      actionDialog: () =>
        updateStatus({
          type: "removed",
          id: props.chatId,
          data: { status: "removed" },
        }),
      actionText: "NONAKTIFKAN",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const openAlertForceApprove = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Apakah Anda Yakin Memilih Tulisan Ini Secara Langsung?",
      desc: "Penulis akan mendapatkan pemberitahuan ketika tulisannya terpilih",
      actionDialog: () =>
        updateStatus({
          type: "winner",
          id: props.chatId,
          data: { status: "winner", clientStatus: "pm_approved" },
          query: "?mode=direct",
        }),
      actionText: "PILIH LANGSUNG",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const openAlertApprove = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Apakah Anda Yakin Memilih Tulisan Ini?",
      desc: "Penulis akan mendapatkan pemberitahuan ketika tulisannya diterima",
      actionDialog: () =>
        updateStatus({
          type: "winner",
          id: props.chatId,
          data: { status: "winner" },
        }),
      actionText: "PILIH TULISAN",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const openAlertReject = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Apakah Anda Yakin Tolak Tulisan Ini?",
      desc: "Penulis akan mendapatkan pemberitahuan ketika tulisannya ditolak",
      actionDialog: () =>
        updateStatus({
          type: "reject",
          id: props.chatId,
          data: { status: "reject" },
        }),
      actionText: "TOLAK",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const openAlertSend = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Anda Yakin Preview Ke Klien Tulisan Ini?",
      desc: "Tulisan ini akan ditampilkan ke klien",
      actionDialog: () =>
        updateStatus({
          type: "send_client",
          id: props.chatId,
          data: {
            status: "pm_approved",
          },
        }),
      actionText: "PREVIEW",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const openAlertRevision = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Anda Yakin Ajukan Revisi Tulisan Ini?",
      desc: "Penulis akan memndapatkan pemberitahuan revisi",
      actionDialog: () =>
        updateStatus({
          type: "revision",
          id: props.chatId,
          data: { status: "revision" },
        }),
      actionText: "REVISI",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const openAlertPublish = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Anda Yakin Publikasikan Tulisan Ini?",
      desc: "Tulisan akan ditampilkan di halaman depan",
      actionDialog: () =>
        updateStatus({
          type: "published",
          id: props.chatId,
          data: { status: "published" },
        }),
      actionText: "PUBLISH",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const openAlertUnPublish = () => {
    setDialogAlert({
      dialog: true,
      type: "warning",
      title: "Unpublish Tulisan?",
      desc: "Tulisan tidak akan ditampilkan di homepage",
      actionDialog: () =>
        updateStatus({
          type: "unpublished",
          id: props.chatId,
          data: { status: "unpublished" },
        }),
      actionText: "UNPUBLISH",
      cancelText: "BATAL",
      loading: loadingUpdateStatusProject,
    });
  };

  const isEmptyArticle =
    (!articleData?.articleTitle && !articleData?.articleContent) ||
    (articleData?.articleTitle === "<p></p>" &&
      articleData?.articleContent === "<p></p>") ||
    (articleData?.articleTitle === "<p></p>" &&
      articleData?.articleContent === "<p><br></p>");

  const totalWords = articleData?.articleContent
    ?.replace(/<[^>]*>/g, " ")
    .replace(/\s+/g, " ")
    .trim()
    ?.split(" ")?.length;

  const changeMessage = (data) => {
    setMessage(data);
  };

  const changeMessageClient = (data) => {
    setMessageClient(data);
  };

  // Article Function
  const changeTitle = (value) => {
    setTitleValue(value);
  };
  const changeContent = (value) => {
    setContentValue(value);
    const counter = value
      ?.replace(/<[^>]*>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    setContentCount(counter);
  };

  const deleteImage = () => {
    setImageValue(null);
  };

  // Status Project Writer
  let statusPm;
  let statusClient;
  switch (articleData?.status) {
    case "removed":
      statusPm = 1;
      break;
    case "sent":
      statusPm = 2;
      break;
    case "revision":
      statusPm = 3;
      break;
    case "pm_approved":
      statusPm = 4;
      break;
    case "reject":
      statusPm = 5;
      break;
    case "winner":
      statusPm = 6;
      break;
    case "published":
      statusPm = 7;
      break;
    case "unpublished":
      statusPm = 8;
      break;
    default:
      statusPm = 0;
  }

  switch (articleData?.clientStatus) {
    case "revision":
      statusClient = 1;
      break;
    case "client_approved":
      statusClient = 2;
      break;
    case "pm_approved":
      statusClient = 3;
      break;
    case "send_client":
      statusClient = 4;
      break;
    default:
      statusClient = 0;
  }

  const renderButtonHeader = () => {
    let articleStatus;

    if (isAssigned && statusPm === 4 && statusClient === 3) {
      articleStatus = "pm_approved";
    } else if (isAssigned && statusPm === 3) {
      articleStatus = "revision";
    } else if (isAssigned && statusPm === 7) {
      articleStatus = "published";
    } else if (isAssigned && statusPm === 1) {
      articleStatus = "removed";
    } else if (!isAssigned) {
      articleStatus = "only_view";
    } else if (isAssigned && statusPm === 6) {
      articleStatus = "winner";
    } else if (isAssigned && statusPm === 8) {
      articleStatus = "unpublished";
    } else {
      articleStatus = null;
    }

    return (
      <div className="p-d-flex p-jc-start p-ai-center">
        {articleStatus !== null && (
          <StatusBadge status={articleStatus} className="mr-3" />
        )}
        {isAssigned && statusPm >= 6 && (
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic" className="btn-download">
              <img src={iconDownload} alt="icon" />
              Unduh Tulisan
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item onClick={() => downloadFile("doc")}>
                Format Doc
              </Dropdown.Item>
              <Dropdown.Item onClick={() => downloadFile("pdf")}>
                Format Pdf
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
        {isAssigned && !isEdit && (
          <>
            {statusPm !== 1 && (
              <Dropdown>
                <Dropdown.Toggle
                  className="button-style-dropdown"
                  id="dropdown-basic"
                >
                  Menu Aksi
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item
                    as="button"
                    onClick={openAlertForceApprove}
                    disabled={statusPm <= 1 || statusPm >= 3}
                  >
                    Pilih Tulisan Langsung
                  </Dropdown.Item>
                  <Dropdown.Item
                    as="button"
                    onClick={openAlertSend}
                    disabled={statusPm !== 2}
                  >
                    Preview Tulisan Ke Klien
                  </Dropdown.Item>
                  {statusClient === 2 && (
                    <Dropdown.Item
                      as="button"
                      onClick={openAlertApprove}
                      disabled={statusPm >= 6}
                    >
                      Pilih Tulisan
                    </Dropdown.Item>
                  )}
                  {statusClient < 2 && (
                    <Dropdown.Item as="button" onClick={openAlertRevision}>
                      Ajukan Revisi
                    </Dropdown.Item>
                  )}
                  <Dropdown.Item
                    as="button"
                    onClick={openAlertReject}
                    disabled={statusPm !== 2}
                  >
                    Tolak Tulisan
                  </Dropdown.Item>
                  {statusPm !== 7 ? (
                    <Dropdown.Item
                      as="button"
                      onClick={openAlertPublish}
                      disabled={statusPm !== 6}
                    >
                      Publish Tulisan
                    </Dropdown.Item>
                  ) : (
                    <Dropdown.Item as="button" onClick={openAlertUnPublish}>
                      Unpublish Tulisan
                    </Dropdown.Item>
                  )}
                  {isEmptyArticle && statusPm < 1 && (
                    <Dropdown.Item as="button" onClick={openAlertKickWriter}>
                      Nonaktifkan Penulis
                    </Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            )}
          </>
        )}
      </div>
    );
  };

  const submitAction = (type) => {
    if (titleValue === "") {
      setDialogAlert({
        dialog: true,
        type: "warning",
        title: "Judul Tulisan Kosong",
        text: "Silahkan tulis judul Tulisan terlebih dahulu",
        closeDialog: () => closeAlert(),
      });
    } else if (contentCount?.split(" ").length < 300) {
      setDialogAlert({
        dialog: true,
        type: "warning",
        title: "Tulisan Terlalu Pendek",
        text: "Silahkan tulis Tulisan minimal 300 karakter",
        closeDialog: () => closeAlert(),
      });
    } else {
      if (type === "revisionAdmin") {
        const submitAction = () => {
          const data = {
            articleTitle: titleValue,
            articleContent: contentValue,
            articleImage:
              imageValue !== undefined || imageValue === [] ? imageValue : null,
            status: "pm_approved",
          };

          dispatch(submitArticleAction(articleData.id, data));
        };

        setDialogAlert({
          dialog: true,
          type: "warning",
          title: "Kirim Revisi?",
          desc: "Pastikan Tulisan anda sudah sesuai",
          actionDialog: () => submitAction(),
          actionText: "KIRIM",
          cancelText: "BATAL",
          loading: loadingUpdateStatusProject,
        });
      } else if (type === "rePublish") {
        const submitAction = () => {
          const data = {
            articleTitle: titleValue,
            articleContent: contentValue,
            articleImage:
              imageValue !== undefined || imageValue === [] ? imageValue : null,
            status: "published",
          };

          dispatch(submitArticleAction(articleData.id, data));
        };

        setDialogAlert({
          dialog: true,
          type: "warning",
          title: "Publish Tulisan?",
          desc: "Pastikan Tulisan anda sudah benar",
          actionDialog: () => submitAction(),
          actionText: "PUBLISH",
          cancelText: "BATAL",
          loading: loadingUpdateStatusProject,
        });
      }
    }
  };

  const ballonChat = (item) => {
    let userType;
    switch (item?.user?.type) {
      case "writer":
        userType = "Penulis";
        break;
      case "pm":
        userType = "Superuser";
        break;
      case "general_pm":
        userType = "Project Manager";
        break;
      case "client":
        userType = "Klien";
        break;
      case "pic":
        userType = "PIC";
        break;
      default:
        userType = "Sistem";
        break;
    }
    return (
      <div
        className={`comment-list ${item?.isPinned ? "pinned" : ""}`}
        key={item._id}
      >
        <div className="comment-date">
          {dayjs(item?.createdAt).format("DD/MM/YYYY HH:mm")}
        </div>
        <div className="comment-content">
          <div className="comment-header">
            <div className={`chat-avatar ${item?.user?.type}`}>
              {item?.user?.type === "system"
                ? "S"
                : item?.user?.name?.substring(0, 1)}
            </div>
            <div className="comment-user">
              {item?.user?.type === "system" ? "Sistem" : item?.user?.name}{" "}
              {item?.user?.type !== "system" && <span>{`(${userType})`}</span>}
            </div>
          </div>
          <div
            className={`comment-text ${
              item?.user?.type === "system" ? "blue" : ""
            }`}
          >
            <div
              className="message-inner"
              dangerouslySetInnerHTML={{
                __html: `${item?.message}`,
              }}
            />
          </div>
        </div>
        {item?.isPinned && (
          <div className="comment-action">
            <span
              className="unpinned-button"
              onClick={() => unpinned(item._id)}
            >
              Lepaskan semat
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className="card card-custom-detail full-width"
        style={{ marginTop: `${users?.user?.type !== "pm" ? "40px" : "0"}` }}
      >
        <div className="card-header border-0 py-5 p-jc-between">
          <div className="p-d-flex p-jc-start p-ai-center">
            <div
              onClick={() =>
                history.push(
                  `admin/pm/detail-project/${articleData?.projectId}`
                )
              }
              className="button-back"
            >
              <span className="icon-back"></span>
            </div>

            <h3 className="card-title align-items-start flex-column ml-3">
              <span className="card-label font-weight-bolder text-dark">
                {users?.user?.type === "pm"
                  ? articleData?.userData?.name
                  : articleData?.title}
              </span>
            </h3>
          </div>
          {renderButtonHeader()}
        </div>
        <div className="card-body pt-0 pb-3">
          {loadingGetArticle ? (
            <FullContentLoading />
          ) : (
            <>
              <div className="section-progress">
                <div className="step-add preview">
                  <ul>
                    <li className="active">
                      <div className="step-number">
                        <span className="done"></span>
                      </div>
                      <div className="step-text">Bergabung</div>
                      <div className="step-date">
                        {articleData?.createdAt
                          ? dayjs(articleData?.createdAt?.toDate())
                              .locale("id")
                              .format("DD MMMM YYYY")
                          : "Belum tersedia"}
                      </div>
                    </li>
                    <li className={articleData?.submitAt ? "active" : ""}>
                      <div className="step-number">
                        <span className="done"></span>
                      </div>
                      <div className="step-text">On Progress</div>

                      <div className="step-date">
                        {articleData?.submitAt
                          ? dayjs(articleData?.submitAt?.toDate())
                              .locale("id")
                              .format("DD MMMM YYYY")
                          : "Belum tersedia"}
                      </div>
                    </li>
                    {articleData?.lastRevisionAt && (
                      <li className="active">
                        <div className="step-number">
                          <span className="done"></span>
                        </div>
                        <div className="step-text">Revisi</div>

                        <div className="step-date">
                          {dayjs(articleData?.lastRevisionAt?.toDate())
                            .locale("id")
                            .format("DD MMMM YYYY")}
                        </div>
                      </li>
                    )}
                    <li
                      className={
                        articleData?.doneAt ||
                        (articleData?.status !== "object"
                          ? articleData?.status === "reject"
                          : articleData?.status?.status === "reject")
                          ? "active"
                          : ""
                      }
                    >
                      <div className="step-number">
                        <span className="done"></span>
                      </div>
                      <div className="step-text">Selesai</div>

                      <div className="step-date">
                        {articleData?.doneAt
                          ? dayjs(articleData?.doneAt?.toDate())
                              .locale("id")
                              .format("DD MMMM YYYY")
                          : "Belum tersedia"}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <>
                {isEmptyArticle || articleData.status === "ongoing" ? (
                  <div className="empty-article">
                    <img src={emptyImage} alt="kosong" />
                    {users?.user?.type === "pm" ? (
                      <h1>Penulis Belum Membuat Tulisan</h1>
                    ) : (
                      <>
                        <h1>Anda Belum Membuat Tulisan</h1>
                        <button
                          className="p-button p-button-outlined"
                          onClick={() =>
                            history.push(
                              `/my-project/${articleData?.id}/article`
                            )
                          }
                        >
                          MULAI MEMBUAT TULISAN
                        </button>
                      </>
                    )}
                  </div>
                ) : (
                  <>
                    {isEdit ? (
                      <div className="section-article full-no-scroll">
                        <div className="section-article-box">
                          <div className="section-article-header">
                            <TitleSection
                              titleValue={titleValue}
                              imageValue={imageValue}
                              changeTitle={(e) => changeTitle(e.target.value)}
                              deleteImage={() => deleteImage()}
                            />
                          </div>
                          <div className="section-article-body">
                            <ContentSection
                              contentValue={contentValue}
                              changeContent={changeContent}
                            />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="section-article">
                        <div className="article-box">
                          <div
                            className="article-view-title"
                            dangerouslySetInnerHTML={{
                              __html: `${articleData?.articleTitle}`,
                            }}
                          />
                        </div>
                        <div className="article-view-body">
                          {articleData?.articleImage && (
                            <div className="article-view-cover">
                              <img
                                src={articleData?.articleImage}
                                alt="photo-article"
                              />
                            </div>
                          )}
                          <div
                            className="article-view-content"
                            dangerouslySetInnerHTML={{
                              __html: `${articleData?.articleContent}`,
                            }}
                          />
                        </div>
                      </div>
                    )}
                    <div className="section-note">
                      {isEdit ? (
                        <div className="write-count">
                          {contentCount === ""
                            ? 0
                            : contentCount?.split(" ").length}
                          /<strong>300</strong> Kata
                        </div>
                      ) : (
                        <p>
                          Ditulis oleh{" "}
                          <strong>{articleData?.userData?.name}</strong>, dengan
                          jumlah <strong>{totalWords}</strong> Kata
                        </p>
                      )}
                      {isEdit && (
                        <ButtonDefault
                          label={
                            articleData?.status === "unpublished"
                              ? "Simpan & Publish Tulisan"
                              : "Kirim ke Klien"
                          }
                          className="ml-2"
                          onClick={() =>
                            submitAction(
                              articleData?.status === "unpublished"
                                ? "rePublish"
                                : "revisionAdmin"
                            )
                          }
                        />
                      )}
                    </div>
                  </>
                )}
              </>

              <div className="tab-user-type">
                {articleData?.userData?.type !== "pm" && (
                  <button
                    className={`tab-button-user ${
                      tab === "writer" ? "active" : ""
                    }`}
                    onClick={() => setTab("writer")}
                  >
                    PENULIS
                  </button>
                )}
                <button
                  className={`tab-button-user ${
                    tab === "client" ? "active" : ""
                  }`}
                  onClick={() => setTab("client")}
                >
                  KLIEN
                </button>
              </div>

              {articleData?.userData?.type !== "pm" && (
                <>
                  {tab === "writer" && (
                    <div className="section-comment">
                      {isAssigned && statusPm !== 1 && statusPm < 6 ? (
                        <div className="comment-box">
                          <label>Komentar ke penulis</label>
                          <RichtextWithoutToolbar
                            contentValue={message}
                            changeContent={changeMessage}
                            placeholder="Tulis komentar anda"
                          />

                          <div className="p-d-flex p-jc-between w-100">
                            <div className="pinned-wrap">
                              <Checkbox
                                inputId="pin"
                                value="New York"
                                onChange={() => setPinned(!pinned)}
                                checked={pinned}
                              ></Checkbox>
                              <label htmlFor="pin" className="p-checkbox-label">
                                Sematkan Komentar
                              </label>
                            </div>
                            <ButtonDefault
                              onClick={() => sendingChat()}
                              label="Kirim"
                            />
                          </div>
                        </div>
                      ) : (
                        <div />
                      )}
                      <div className="comment-title">Log Aktivitas Penulis</div>
                      {renderChats
                        ?.filter((item) => item.isPinned)
                        .map((item, isPinned) => {
                          return ballonChat(item, isPinned);
                        })}

                      {renderChats
                        ?.filter((item) => !item.isPinned)
                        .map((item) => {
                          return ballonChat(item);
                        })}
                    </div>
                  )}
                </>
              )}

              {tab === "client" && (
                <div className="section-comment">
                  {isAssigned && statusPm !== 1 && statusPm < 6 ? (
                    <div className="comment-box">
                      <label>Komentar ke klien</label>
                      <RichtextWithoutToolbar
                        contentValue={messageClient}
                        changeContent={changeMessageClient}
                        placeholder="Tulis komentar anda"
                      />
                      <div className="p-d-flex p-jc-end w-100">
                        <ButtonDefault
                          label="Kirim"
                          onClick={() => sendingChatClient()}
                          disabled={!isAssigned}
                        />
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                  <div className="comment-title">Log Aktivitas Klien</div>
                  {renderChatsClient?.length >= 1 ? (
                    renderChatsClient
                      ?.filter((item) => !item.isPinned)
                      .map((item) => {
                        return ballonChat(item);
                      })
                  ) : (
                    <div className="comment-list">
                      <div className="comment-content text-center w-100">
                        Log Aktivitas belum tersedia
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeAlert()}
        title={dialogAlert.title}
        text={dialogAlert.desc}
        loading={loadingUpdateStatusProject}
        cancelText={dialogAlert.cancelText}
        actionDialog={dialogAlert.actionDialog}
        actionText={dialogAlert.actionText}
      />
    </>
  );
};

export default PreviewProjectModule;
