/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ImageProjectBody from "../../../components/datatableBody/ImageProjectBody";
import TitleProjectBody from "../../../components/datatableBody/TitleProjectBody";
import ActionBody from "../../../components/datatableBody/ActionBody";
import DateBody from "../../../components/datatableBody/DateBody";
import NumberBody from "../../../components/datatableBody/NumberBody";
import TextBody from "../../../components/datatableBody/TextBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import StatusBadge from "../../../components/badge/StatusBadge";
import { TableLoad } from "../../../components/loading/loadingContent";
import DialogAlert from "../../../components/dialog/DialogAlert";
import FilterListProject from "./Components/FilterListProject";
import {
  getProjectPmAction,
  deleteProjectAction,
  resetStateProjectAction,
} from "../../../../redux/action/projectAction";

const ListProjectModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dataTable, setDataTable] = useState([]);
  const [deletedItem, setDeletedItem] = useState(null);

  const {
    dataGetProjectPm,
    totalPage,
    loadingGetProjectPm,
    loadingDeleteProject,
    successDeleteProject,
  } = useSelector((state) => state.project);

  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successDeleteProject) {
      setDialogSuccess(true);
    }
  }, [successDeleteProject]);

  useEffect(() => {
    if (dataGetProjectPm) {
      setDataTable(dataGetProjectPm);
    }
  }, [dataGetProjectPm]);

  const imageBodyTemplate = (item) => {
    return (
      <ImageProjectBody
        image={item?.thumbnail}
        link={`/admin/pm/detail-project/${item?._id}`}
      />
    );
  };

  const nameBodyTemplate = (item) => {
    return (
      <Link to={`/admin/pm/detail-project/${item?._id}`}>
        <TitleProjectBody
          name={item?.title}
          owner={item?.jobNumber}
          link={`/admin/pm/detail-project/${item?._id}`}
        />
      </Link>
    );
  };
  const dateBodyTemplate = (item) => {
    return (
      <DateBody
        date={item?.deadlineDate}
        link={`/admin/pm/detail-project/${item?._id}`}
      />
    );
  };

  const writerBodyTemplate = (item) => {
    return (
      <NumberBody
        number={item?.nWriter || 0}
        link={`/admin/pm/detail-project/${item?._id}`}
      />
    );
  };

  const creatorBodyTemplate = (item) => {
    return (
      <TextBody
        text={`${item?.createdBy?.name} (${item?.createdBy?.type})` || "-"}
        link={`/admin/pm/detail-project/${item?._id}`}
      />
    );
  };

  const priceBodyTemplate = (item) => {
    return (
      <NumberBody
        number={item?.price || 0}
        isPrice
        link={`/admin/pm/detail-project/${item?._id}`}
      />
    );
  };

  const statusBodyTemplate = (item) => {
    return (
      <StatusBadge
        status={item?.category}
        link={`/admin/pm/detail-project/${item?._id}`}
        className="fit"
      />
    );
  };

  const statusProjectBodyTemplate = (item) => {
    return (
      <StatusBadge
        status={item?.status}
        userType={users?.user?.type}
        link={`/admin/pm/detail-project/${item?._id}`}
      />
    );
  };

  const actionBodyTemplate = (item) => {
    return (
      <ActionBody
        id={item?._id}
        name={item?.title}
        onDelete={() => handleDelete(item?._id)}
        loading={loadingDeleteProject}
        isSuccess={successDeleteProject}
        disableButton={item?.nWriter > 0}
        deleteOnly
      />
    );
  };

  const handleDelete = (id) => {
    dispatch(deleteProjectAction(id));
    setDeletedItem(id);
  };

  const closeSuccess = () => {
    const dataAfterRemoved = dataTable?.filter(
      (item) => item?._id !== deletedItem
    );
    setDataTable(dataAfterRemoved);
    dispatch(resetStateProjectAction());
    setDialogSuccess(false);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      category: parsed?.category || "",
      type: parsed?.type || "",
      orderField: parsed?.order || "",
      orderDir:
        parsed?.order === "createdAt" || !parsed?.order ? "desc" : "asc",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getProjectPmAction(params));

    history.push(
      `/admin/pm/list-project?search=${parsed?.search ||
        ""}&type=${parsed?.type || ""}&category=${parsed.category ||
        ""}&order=${parsed?.order || ""}&page=${page + 1}`
    );
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Project
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterListProject parsed={parsed} />
          {loadingGetProjectPm ? (
            <TableLoad />
          ) : (
            <>
              {dataTable?.length < 1 || dataTable === null ? (
                <DataEmpty />
              ) : (
                <DataTable
                  value={dataTable}
                  className="no-padding table-project"
                >
                  <Column
                    body={imageBodyTemplate}
                    headerClassName="thumbnail"
                  ></Column>
                  <Column
                    header="Nama Project"
                    body={nameBodyTemplate}
                    headerClassName="project-name"
                  ></Column>
                  <Column
                    header="Deadline"
                    headerClassName="project-date"
                    body={dateBodyTemplate}
                  ></Column>
                  <Column
                    header="Penulis"
                    headerClassName="project-writer"
                    body={writerBodyTemplate}
                  ></Column>
                  <Column
                    header="Dibuat Oleh"
                    headerClassName="project-creator"
                    body={creatorBodyTemplate}
                  ></Column>
                  <Column
                    header="Kategori"
                    headerClassName="project-category"
                    body={statusBodyTemplate}
                  ></Column>
                  <Column
                    header="Harga"
                    headerClassName="project-price"
                    body={priceBodyTemplate}
                  ></Column>
                  <Column
                    header="Status"
                    headerClassName="project-status"
                    body={statusProjectBodyTemplate}
                  ></Column>
                  <Column
                    headerClassName="action delete-only"
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Menghapus Project"}
        text={"Project telah dihapus dari list"}
      />
    </>
  );
};

export default ListProjectModule;
