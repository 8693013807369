import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import OtpInput from "react-otp-input";
import firebase from "firebase/app";

import { Button } from "primereact/button";

import ButtonLoader from "../../../components/button/ButtonLoader";

import {
  phoneLoginAction,
  loginVerifyOtpAction,
} from "../../../../redux/action/authAction";

const LoginOtp = (props) => {
  const dispatch = useDispatch();

  const [otpValue, setOtpValue] = useState("");
  const [timeLeft, setTimeLeft] = useState(61);
  const [resendOtpView, setResendOtpView] = useState(false);
  const [otpError, setOtpError] = useState(null);

  const {
    verificationOtp,
    loadingOtpLogin,
    errOtpLogin,
    dataInput,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (!timeLeft) {
      setResendOtpView(true);
      return;
    }

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [timeLeft]);

  useEffect(() => {
    if (errOtpLogin) {
      setOtpError("Kode OTP tidak valid atau kadaluarsa");
    }
  }, [errOtpLogin]);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "grecaptcha-button",
      {
        size: "invisible",
      }
    );
  }, []);

  const resendOtpHandler = (e) => {
    e.preventDefault();
    setTimeLeft(61);
    setResendOtpView(false);
    dispatch(phoneLoginAction(window.recaptchaVerifier, dataInput));
  };

  const submitOtp = (e) => {
    e.preventDefault();
    dispatch(loginVerifyOtpAction(verificationOtp, otpValue, dataInput));
  };

  return (
    <>
      <p>
        Silakan masukan kode OTP yang kami kirimkan ke nomor{" "}
        <strong>
          {dataInput?.phone_number?.length > 0 &&
            "*".repeat(dataInput?.phone_number?.length - 4) +
            dataInput?.phone_number
              .replace(/-/g, "")
              .replace(/_/g, "")
              .slice(-3)}
        </strong>
      </p>

      <div id="grecaptcha-button" />
      <form onSubmit={submitOtp}>
        <div className="p-field otp-code">
          <OtpInput
            value={otpValue}
            onChange={(value) => setOtpValue(value)}
            numInputs={6}
            containerStyle="otp-wrapp"
            inputStyle="p-inputtext"
            isInputNum
          />
        </div>

        {otpError !== null && (
          <small id="otp-number" className="p-error p-d-block p-text-right">
            {otpError}
          </small>
        )}

        {loadingOtpLogin ? (
          <ButtonLoader />
        ) : (
          <>
            <Button
              label="Konfirmasi"
              className="p-button-rounded p-button-lg p-mt-3"
              type="submit"
              onClick={submitOtp}
            />

            <div className="footer-form">
              {!resendOtpView ? (
                <span className="time-otp">
                  Tersisa <strong>{timeLeft} detik</strong> lagi
                </span>
              ) : (
                <p>
                  Tidak menerima kode OTP?{" "}
                  <a href="/#" onClick={resendOtpHandler}>
                    Kirim Ulang
                  </a>
                </p>
              )}
            </div>
          </>
        )}
      </form>
    </>
  );
};

export default LoginOtp;
