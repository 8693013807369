import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import { titlePage } from "../../helpers/titleHead";
import MediaShareDetailModule from "../../modules/Client/MediaShare/MediaShareDetailModule";
import {
  getDetailReleaseAction,
  statsMediaReleaseAction,
} from "../../../redux/action/releaseAction";

const ClientMediaShareDetailPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useParams();

  useEffect(() => {
    history.push(`/client/detail-release/${queryParams?.releaseId}`);
    titlePage({
      title: "IRIS - Manajemen Media Distribusi",
    });
    const params = {
      page: 1,
      perpage: 10,
    };
    dispatch(getDetailReleaseAction(queryParams?.releaseId, params));
    dispatch(statsMediaReleaseAction(queryParams?.releaseId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardClientLayout
      title="Manajemen Media Distribusi"
      menu="distribution"
    >
      <MediaShareDetailModule releaseId={queryParams?.releaseId} />
    </DashboardClientLayout>
  );
};

export default ClientMediaShareDetailPage;
