import React from "react";
import NumberFormat from "react-number-format";
import { useHistory } from "react-router-dom";

const KpiBody = (props) => {
  const { number, link } = props;
  const history = useHistory();
  return (
    <div
      className={`number-table ${link ? "link" : "no-link"}`}
      onClick={() => history.push(link)}
    >
      <NumberFormat
        thousandSeparator={"."}
        decimalSeparator={","}
        displayType="text"
        value={number}
        renderText={(value) => <p className="mb-0">{`${value}`}</p>}
      />
    </div>
  );
};

export default KpiBody;
