import React from "react";

const RegisterBenefitModule = () => {
  return (
    <div className="col-lg-6 col-md-6 col-sm-0 col-xs-0 p-lg-0 p-md-0">
      <div className="auth-content left">
        <div className="box-content">
          <h1>Keuntungan Yang Kamu Dapatkan Bergabung Bersama IRIS</h1>
          <p>Wujudkan impian menjadi penulis profesional dari sekarang!</p>
          <ul>
            <li>
              <span className="icon-deal"></span>Menerima penawaran kerjaan
              secara rutin dari berbagai brand ternama
            </li>
            <li>
              <span className="icon-happy"></span>Bekerja sesuai passion dan
              bidang yang dikuasai
            </li>
            <li>
              <span className="icon-connected"></span>Terhubung ke banyak media lokal, nasional baik online maupun offline
            </li>
            <li>
              <span className="icon-pen"></span>Mengasah kemampuan menulis dan membangun portofolio secara profesional dengan standar pembuatan rilis yang kredibel
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RegisterBenefitModule;
