import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import PreviewArticleModule from "../../modules/Client/PreviewArticle/PreviewArticleModule";

import { getArticleAction } from "../../../redux/action/projectAction";

function PreviewArticlePage() {
  const dispatch = useDispatch();
  const queryParams = useParams();

  const { dataGetArticle } = useSelector((state) => state.project);

  useEffect(() => {
    titlePage({
      title: "IRIS - Preview Artikel",
    });
    dispatch(getArticleAction(queryParams?.articleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardClientLayout
      title="Project Preview"
      mobileTitle="Approval Release"
      menu="project"
    >
      <div className="preview-article-page pb-0">
        <PreviewArticleModule
          articleData={dataGetArticle}
          articleId={queryParams?.articleId}
        />
      </div>
    </DashboardClientLayout>
  );
}

export default PreviewArticlePage;
