import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import TitleSection from "./Components/TitleSection";
import ContentSection from "./Components/ContentSection";
import ButtonDefault from "../../../components/button/ButtonDefault";
import DialogAlert from "../../../components/dialog/DialogAlert";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import {
  postInfoAction,
  resetStateAdminAction,
} from "../../../../redux/action/adminAction";

const CreateInfoModule = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [titleValue, setTitleValue] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [contentValue, setContentValue] = useState("");
  const [contentCount, setContentCount] = useState("");
  const [typeSubmit, setTypeSubmit] = useState("");
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogError, setDialogError] = useState({
    dialog: false,
    title: "",
    text: "",
  });
  const [dialogSuccess, setDialogSuccess] = useState({
    dialog: false,
    title: "",
    text: "",
  });

  const { loadingPostInfo, successPostInfo, errorPostInfo } = useSelector(
    (state) => state.admin
  );

  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );

  useEffect(() => {
    if (successUploadImage) {
      setImageValue(dataUploadImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    const isPublish = typeSubmit === "publish";
    if (successPostInfo) {
      setTimeout(() => {
        setDialogWarning(false);
        setDialogSuccess({
          dialog: true,
          title: isPublish
            ? "Berhasil Publish Informasi"
            : "Berhasil Simpan Draft",
          text: isPublish
            ? "Informasi terbaru berhasil di publish"
            : "Informasi terbaru berhasil di simpan",
        });
      }, 1500);
    }
    if (errorPostInfo) {
      setDialogWarning(false);
      setDialogError({
        dialog: true,
        title: isPublish ? "Gagal Publish Informasi" : "Gagal Simpan Draft",
        text: isPublish
          ? "Informasi terbaru gagal di publish"
          : "Informasi terbaru gagal di simpan",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successPostInfo, errorPostInfo]);

  // Function Write Article
  const changeTitle = (value) => {
    setTitleValue(value);
  };
  const changeContent = (value) => {
    setContentValue(value);
    const counter = value
      ?.replace(/<[^>]*>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    setContentCount(counter);
  };

  const deleteImage = () => {
    setImageValue(null);
  };

  const closeSuccess = () => {
    setDialogSuccess({ ...dialogSuccess.dialog, dialog: false });
    dispatch(resetUtilsStateAction());
    dispatch(resetStateAdminAction());
    history.push("/admin/pm/list-information");
  };

  const closeDialogError = () => {
    setDialogError({ ...dialogError.dialog, dialog: false });
  };

  const submitArticle = () => {
    if (titleValue === "") {
      setDialogError({
        dialog: true,
        title: "Judul Informasi Kosong",
        text: "Silahkan tulis judul informasi terlebih dahulu",
      });
    } else if (contentCount?.split(" ").length < 1) {
      setDialogError({
        dialog: true,
        title: "Informasi Kosong",
        text: "Silahkan tulis informasi terlebih dahulu",
      });
    } else {
      const image =
        imageValue !== undefined || imageValue === []
          ? { type: "image", url: imageValue }
          : null;
      const data = {
        title: titleValue,
        content: contentValue,
        attachments: image,
        status: "publish",
        cover:
          imageValue !== undefined || imageValue === [] ? imageValue : null,
      };
      dispatch(postInfoAction(data));
      setTypeSubmit("publish");
    }
  };

  const saveDraf = () => {
    const image =
      imageValue !== undefined || imageValue === []
        ? { type: "image", url: imageValue }
        : null;
    const data = {
      title: titleValue,
      content: contentValue,
      attachments: image,
      status: "draft",
      cover: imageValue !== undefined || imageValue === [] ? imageValue : null,
    };
    dispatch(postInfoAction(data));
    setTypeSubmit("draft");
  };

  return (
    <>
      <div className="card-header">
        <div className="p-d-flex p-jc-start p-ai-center">
          <button
            href="#"
            onClick={() => history.goBack()}
            className="button-back"
          >
            <span className="icon-back"></span>
          </button>

          <h3 className="card-title align-items-start flex-column mb-0 ml-3">
            <span className="card-label font-weight-bolder text-dark">
              Tulis Informasi
            </span>
          </h3>
        </div>
      </div>
      <div className="card-body p-0">
        <div className="article-preview">
          <div className={`section-article no-scroll w-100`}>
            <div className="section-article-box">
              <div className="section-article-header">
                <TitleSection
                  titleValue={titleValue}
                  imageValue={imageValue}
                  changeTitle={(e) => changeTitle(e.target.value)}
                  deleteImage={() => deleteImage()}
                />
              </div>
              <div className="section-article-body">
                <ContentSection
                  contentValue={contentValue}
                  changeContent={changeContent}
                />
              </div>
            </div>
            <div className="section-article-footer">
              <div className="write-note">
                <div className="write-count">
                  {contentCount === "" ? 0 : contentCount?.split(" ").length}{" "}
                  Kata
                </div>
              </div>
              <div className="write-button">
                <ButtonDefault
                  onClick={() => saveDraf()}
                  label="SIMPAN DRAF"
                  type="rounded-bordered"
                  className="mr-3"
                  loading={typeSubmit === "draft" && loadingPostInfo}
                  disabled={typeSubmit === "publish" && loadingPostInfo}
                />
                <ButtonDefault
                  onClick={() => setDialogWarning(true)}
                  label="PUBLISH INFORMASI"
                  type="rounded"
                  loading={typeSubmit === "publish" && loadingPostInfo}
                  disabled={typeSubmit === "draft" && loadingPostInfo}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DialogAlert
        type={"warning"}
        actionDialog={() => submitArticle()}
        actionText={"PUBLISH"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Publish Informasi?"}
        text={"Pastikan Informasi yang anda tulis sudah sesuai"}
        loading={loadingPostInfo}
      />
      <DialogAlert
        type={"error"}
        visible={dialogError.dialog}
        closeDialog={() => closeDialogError()}
        title={dialogError.title}
        text={dialogError.text}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess.dialog}
        closeDialog={() => closeSuccess()}
        title={dialogSuccess.title}
        text={dialogSuccess.title}
      />
    </>
  );
};

export default CreateInfoModule;
