import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import DetailMyOrderModule from "../../modules/Client/Order/DetailMyOrderModule";

import { orderClientDetailAction } from "../../../redux/action/orderAction";
import { clientDataProjectAction } from "../../../redux/action/utilsAction";

function DetailMyOrderPage() {
  const dispatch = useDispatch();
  const queryParams = useParams();

  useEffect(() => {
    titlePage({
      title: "IRIS - Invoice",
    });
    dispatch(orderClientDetailAction(queryParams.projectId));
    dispatch(clientDataProjectAction(null));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Invoice" menu="invoice">
      <DetailMyOrderModule projectId={queryParams.projectId} />
    </DashboardClientLayout>
  );
}

export default DetailMyOrderPage;
