import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const myFirebaseStrapi = firebase.initializeApp(
  {
    apiKey: "AIzaSyDHjknBFMg5fIKn0N1sxtTYzJbwlBMnD6Y",
    authDomain: "iris-strapi-staging.firebaseapp.com",
    /* databaseURL: process.env.REACT_APP_FIREBASE_DB_URL, */
    projectId: "iris-strapi-staging",
    storageBucket: "iris-strapi-staging.appspot.com",
    messagingSenderId: "201401982153",
    appId: "1:201401982153:web:947aafda27a305b8ba4118",
    measurementId: "G-HEZDN09X4V",
  },
  "secondary"
);

export default myFirebaseStrapi;
