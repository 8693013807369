import { combineReducers } from "redux";

import { authReducer } from "./authReducer";
import { landingpageReducer } from "./landingpageReducer";
import { projectReducer } from "./projectReducer";
import { utilsReducer } from "./utilsReducer";
import { usersReducer } from "./userReducer";
import { chatReducer } from "./chatReducer";
import { serviceReducer } from "./serviceReducer";
import { clientReducer } from "./clientReducer";
import { orderReducer } from "./orderReducer";
import { picReducer } from "./clientPicReducer";
import { helpReducer } from "./helpReducer";
import { financeReducer } from "./financeReducer";
import { transactionReducer } from "./transactionReducer";
import { adminReducer } from "./adminReducer";
import { releaseReducer } from "./releaseReducer";
import { saveReducer } from "./saveReducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  landingpage: landingpageReducer,
  project: projectReducer,
  utils: utilsReducer,
  user: usersReducer,
  chat: chatReducer,
  service: serviceReducer,
  client: clientReducer,
  order: orderReducer,
  save: saveReducer,
  clientPic: picReducer,
  help: helpReducer,
  finance: financeReducer,
  transaction: transactionReducer,
  admin: adminReducer,
  release: releaseReducer,
});
