import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getPICDetailAction } from "../../../redux/action/clientPicAction";
import { getOrderAction } from "../../../redux/action/orderAction";
import { titlePage } from "../../helpers/titleHead";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import PICEditModule from "../../modules/Client/PICManagement/PICEditModule";

const EditPICPage = () => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const { dataGetDetailPIC } = useSelector((state) => state.clientPic);
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Tim",
    });
    if (!dataGetDetailPIC) {
      dispatch(getPICDetailAction(QueryParams.picId));
      dispatch(getOrderAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="myrelease-page">
      <DashboardClientLayout
        title="Manajemen Tim"
        mobileTitle="Edit Tim"
        menu="team"
      >
        <PICEditModule />
      </DashboardClientLayout>
    </div>
  );
};

export default EditPICPage;
