/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ActionBody from "../../../../components/datatableBody/ActionBody";
import TextBody from "../../../../components/datatableBody/TextBody";
import NumberBody from "../../../../components/datatableBody/NumberBody";
import DataEmpty from "../../../../components/emptyState/dataEmpty";
import TableLoader from "../../../../components/loading/tableLoader";
import AdminPaginate from "../../../../components/paging/pagination";
import FilterProduct from "./Components/FilterProduct";
import ImageProjectBody from "../../../../components/datatableBody/ImageProjectBody";

import {
  getProductAction,
  deleteProductAction,
  resetStateServiceAction,
} from "../../../../../redux/action/serviceAction";

const ProductListModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [loadingState, setLoadingState] = useState(false);

  const {
    dataGetProduct,
    loadingGetProduct,
    totalPage,
    successDeleteProduct,
  } = useSelector((state) => state.service);

  useEffect(() => {
    if (successDeleteProduct) {
      setTimeout(() => {
        setLoadingState(false);
        const params = {
          q: parsed.search,
          page: parsed.page,
          perpage: 10,
        };
        dispatch(getProductAction(params));
        dispatch(resetStateServiceAction());
      }, 2500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDeleteProduct]);

  const nameBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.name}
        link={`/admin/pm/detail-product/${item._id}`}
      />
    );
  };
  const noteBodyTemplate = (item) => {
    return (
      <TextBody
        text={
          item?.description?.length > 50
            ? `${item?.description?.substring(0, 50)}...`
            : item?.description
        }
        link={`/admin/pm/detail-product/${item._id}`}
      />
    );
  };
  const priceBodyTemplate = (item) => {
    return (
      <NumberBody
        isPrice={true}
        number={item?.price}
        link={`/admin/pm/detail-product/${item._id}`}
      />
    );
  };
  const categoryBodyTemplate = (item) => {
    let categoryName;
    if (item?.category === "pr") {
      categoryName = "Press Release";
    } else if (item?.category === "distribution_media") {
      categoryName = "Media Distribusi";
    } else {
      categoryName = "Artikel";
    }
    return (
      <TextBody
        text={categoryName}
        link={`/admin/pm/detail-product/${item._id}`}
      />
    );
  };
  const imageBodyTemplate = (item) => {
    return (
      <ImageProjectBody
        link={`/admin/pm/detail-product/${item._id}`}
        image={item?.image || process.env.REACT_APP_IMAGE_ARTICLE}
      />
    );
  };
  const actionBodyTemplate = (item) => {
    return (
      <div className="p-d-flex p-jc-end">
        <ActionBody
          id={item?._id}
          deleteOnly={true}
          name={item?.name}
          onDelete={() => deleteProduct(item?._id)}
          loading={loadingState}
          isSuccess={successDeleteProduct}
        />
      </div>
    );
  };

  const deleteProduct = (id) => {
    dispatch(deleteProductAction(id));
    setLoadingState(true);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search,
      page: page + 1,
      perpage: 10,
    };
    history.push(
      `/admin/pm/list-product?search=${parsed.search || ""}&page=${page + 1}`
    );
    dispatch(getProductAction(params));
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Produk
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterProduct />
          {loadingGetProduct ? (
            <TableLoader />
          ) : (
            <>
              {dataGetProduct?.length < 1 || dataGetProduct === null ? (
                <DataEmpty />
              ) : (
                <>
                  <DataTable value={dataGetProduct} className="no-padding">
                    <Column
                      body={imageBodyTemplate}
                      headerClassName="thumbnail"
                    />
                    <Column header="Nama Produk" body={nameBodyTemplate} />
                    <Column
                      header="Kategori Produk"
                      body={categoryBodyTemplate}
                    />
                    <Column
                      header="Keterangan Produk"
                      body={noteBodyTemplate}
                    />
                    <Column header="Harga" body={priceBodyTemplate} />
                    <Column
                      headerClassName="action delete-only"
                      body={actionBodyTemplate}
                    />
                  </DataTable>
                </>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ProductListModule;
