import React from "react";
import ContentLoader from "react-content-loader";

const BannerLoader = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 100% 650"
      height={650}
      width={"100%"}
      backgroundColor="#c7c7c7"
      foregroundColor="#fafafa"
      backgroundOpacity={0.6}
      foregroundOpacity={0.8}
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="400" />
    </ContentLoader>
  );
};

export default BannerLoader;
