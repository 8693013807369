/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import ProfileModule from "../../modules/User/Profile/ProfileModule";

import { titlePage } from "../../helpers/titleHead";

const ProfilePage = (props) => {
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    titlePage({
      title: "IRIS - Profile",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardUserLayout>
      <div className="p-d-flex p-justify-center p-mt-5 profile-module">
        <div className="p-grid p-d-flex p-justify-center">
          <ProfileModule user={users?.user} />
        </div>
      </div>
    </DashboardUserLayout>
  );
};

export default ProfilePage;
