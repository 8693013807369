import React from "react";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

const DateBody = (props) => {
  const { date, link, isHour } = props;
  const history = useHistory();
  return (
    <div
      className={`date-table ${link ? "link" : "no-link"}`}
      onClick={() => history.push(link)}
    >
      {isHour ? (
        <p>
          {dayjs(date)
            .locale("id")
            .format("DD MMM YYYY, HH:mm")}
        </p>
      ) : (
        <p>
          {dayjs(date)
            .locale("id")
            .format("DD MMM YYYY")}
        </p>
      )}
    </div>
  );
};

export default DateBody;
