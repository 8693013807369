import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import firebase from "firebase/app";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import ButtonLoader from "../../../components/button/ButtonLoader";

import { formPhoneNumber } from "../../../helpers/phoneNumber";

import { phoneRegisterAction } from "../../../../redux/action/authAction";

const RegisterPhoneModule = (props) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState({ value: "", error: null });

  const { loadingPhoneRegister } = useSelector((state) => state.auth);

  useEffect(() => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "grecaptcha-button",
      {
        size: "invisible",
      }
    );
  }, []);

  const submitPhone = (e) => {
    e.preventDefault();

    if (!formPhoneNumber(phone.value)) {
      setPhone({ ...phone, error: "Nomor telepon tidak valid" });
    } else {
      const data = { phone_number: phone.value };
      dispatch(phoneRegisterAction(window.recaptchaVerifier, data));
    }
  };

  return (
    <>
      <div className="p-field p-mt-0">
        <InputText
          id="phone"
          aria-describedby="phone-number"
          className={`${phone.error !== null && "p-invalid"} p-d-block`}
          placeholder="Masukkan Nomor HP"
          value={phone.value}
          onChange={(e) =>
            setPhone({ ...phone, value: e.target.value, error: null })
          }
        />
        {phone.error !== null && (
          <small id="phone-number" className="p-error p-d-block p-text-right">
            {phone.error}
          </small>
        )}
      </div>
      {loadingPhoneRegister ? (
        <ButtonLoader />
      ) : (
        <Button
          label="Daftar"
          className="p-button-rounded p-button-lg"
          onClick={submitPhone}
        />
      )}

      <div id="grecaptcha-button" />
    </>
  );
};

export default RegisterPhoneModule;
