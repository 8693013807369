import api from "./api";

export const writerWithdraw = async (token, data) => {
  return api.post(`/writer/payout`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getWriterTransaction = async (token, params) => {
  return api.get(`/writer/payout`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getWriterPayment = async (token, params) => {
  return api.get(`/finance/payment`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const detailWriterPayment = async (token, id) => {
  return api.get(
    `/finance/payment/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getFinancePayout = async (token, params) => {
  return api.get(`/finance/payout`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const approvePayout = async (token, id, data) => {
  return api.put(`/finance/payout/${id}/update`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const approveAllPayout = async (token) => {
  return api.put(
    `/finance/payout/approve-all`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
