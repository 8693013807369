/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import ButtonDefault from "../../../../components/button/ButtonDefault";

import { UploadThumbnail } from "../../../../components/upload/uploadThumbnail";
import {
  dataAddDetailAction,
  dataUpdateDetailAction,
} from "../../../../../redux/action/utilsAction";
import { detailClientAction } from "../../../../../redux/action/clientAction";
import { detailUserAction } from "../../../../../redux/action/userAction";

const AddDetail = (props) => {
  const dispatch = useDispatch();
  const [companyOptions, setCompanyOptions] = useState([]);
  const [titleState, setTitleState] = useState({ value: "", error: null });
  const [categoryState, setCategoryState] = useState({
    value: null,
    error: null,
  });
  const [typeState, setTypeState] = useState({
    value: null,
    error: null,
  });
  const [imageState, setImageState] = useState({ value: null, error: null });
  const [descriptionState, setDescriptionState] = useState({
    value: "",
    error: null,
  });
  const [priceState, setPriceState] = useState({ value: "", error: null });
  const [quotaState, setQuotaState] = useState({ value: 1, error: null });
  const [dateState, setDateState] = useState({ value: null, error: null });
  const [managerState, setManagerState] = useState({
    value: null,
    error: null,
  });
  const [companyState, setCompanyState] = useState({
    value: null,
    error: null,
  });
  const [companyDetail, setCompanyDetail] = useState({
    name: "",
    business: "",
    background: "",
    clientName: "",
    clientEmail: "",
  });
  const [pmName, setPmName] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);

  const category = [
    { name: "Publik", code: "public" },
    { name: "Privat", code: "private" },
  ];
  const type = [
    { name: "Artikel", code: "article" },
    { name: "Press Release", code: "pr" },
  ];

  const {
    dataUploadImage,
    successUploadImage,
    dataAddProject,
    dataUpdateProject,
  } = useSelector((state) => state.utils);
  const { dataAutoCompleteCompanies, dataDetailClient } = useSelector(
    (state) => state.client
  );
  const { dataDetailUser, dataGetUsers } = useSelector((state) => state.user);
  const { users } = useSelector((state) => state.auth);

  const listUsers =
    users?.user?.type === "pm"
      ? dataGetUsers
      : [{ name: users?.user?.name, _id: users?.uid }];
  const pmList = listUsers?.map((pm) => ({
    name: pm?.name,
    code: pm?._id,
  }));
  const pmOptions = pmList?.concat({ name: users?.user?.name, code: null });

  useEffect(() => {
    setCompanyState({
      value: process.env.REACT_APP_CLIENT_INTERNAL,
      error: null,
    });
  }, []);

  useEffect(() => {
    if (dataAutoCompleteCompanies !== null) {
      const companies = dataAutoCompleteCompanies?.map((company) => ({
        name: company?.companyName,
        code: company?._id,
      }));
      setCompanyOptions(companies);
    }
  }, [dataAutoCompleteCompanies]);

  useEffect(() => {
    if (
      titleState?.value?.length > 0 &&
      categoryState?.value !== null &&
      typeState?.value !== null &&
      dateState?.value !== null &&
      (priceState?.value?.length > 0 || priceState?.value > 0) &&
      quotaState?.value > 0 &&
      companyState?.value !== null &&
      descriptionState?.value?.length > 0
    ) {
      setIsDisabled(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categoryState.value,
    dateState.value,
    priceState.value,
    titleState.value,
    typeState.value,
    quotaState.value,
    descriptionState.value,
    companyState.value,
  ]);

  useEffect(() => {
    if (companyState?.value?.length > 0) {
      dispatch(detailClientAction(companyState?.value));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyState?.value]);

  useEffect(() => {
    if (dataDetailClient) {
      dispatch(detailUserAction(dataDetailClient?.client?.clientId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailClient]);

  useEffect(() => {
    if (dataDetailClient && dataDetailUser) {
      setCompanyDetail({
        name: dataDetailClient?.companyName,
        business: dataDetailClient?.businessField,
        background: dataDetailClient?.companyBackground,
        clientName: dataDetailUser?.name,
        clientEmail: dataDetailUser?.email,
      });
    }
  }, [dataDetailClient, dataDetailUser]);

  useEffect(() => {
    if (
      props.type === "add" &&
      dataAddProject?.detail === null &&
      users?.user?.type === "general_pm"
    ) {
      setManagerState({
        value: users?.uid,
        error: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAddProject.detail]);

  useEffect(() => {
    if (props.type === "add" && dataAddProject?.detail !== null) {
      setTitleState({
        value: dataAddProject?.detail?.title,
        error: null,
      });
      setCategoryState({
        value: dataAddProject?.detail?.category,
        error: null,
      });
      setTypeState({
        value: dataAddProject?.detail?.type,
        error: null,
      });
      setImageState({
        value: dataAddProject?.detail?.thumbnail || null,
        error: null,
      });
      setDescriptionState({
        value: dataAddProject?.detail?.description,
        error: null,
      });
      setPriceState({
        value: dataAddProject?.detail?.price,
        error: null,
      });
      setQuotaState({
        value: dataAddProject?.detail?.quota || 1,
        error: null,
      });
      setDateState({
        value: dayjs(dataAddProject?.detail?.deadlineDate).toDate(),
        error: null,
      });
      if (dataAddProject?.detail?.company) {
        setCompanyState({
          value: dataAddProject?.detail?.company?.companyId,
          error: null,
        });
      } else {
        setCompanyState({
          value: process.env.REACT_APP_CLIENT_INTERNAL,
          error: null,
        });
      }
      setManagerState({
        value:
          users?.user?.type === "pm"
            ? dataAddProject?.detail?.pm?.pmId
            : users?.uid,
        error: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataAddProject.detail]);

  useEffect(() => {
    if (managerState?.value?.length > 0) {
      if (users?.user?.type === "pm") {
        const manager = dataGetUsers
          ?.filter((pm) => pm._id === managerState?.value)
          .reduce((pm) => pm);
        setPmName(manager?.name);
      } else {
        setPmName(users?.user?.name);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [managerState]);

  useEffect(() => {
    if (props.type === "edit" && dataUpdateProject?.detail !== null) {
      setTitleState({
        value: dataUpdateProject?.detail?.title,
        error: null,
      });
      setCategoryState({
        value: dataUpdateProject?.detail?.category,
        error: null,
      });
      setTypeState({
        value: dataUpdateProject?.detail?.type,
        error: null,
      });
      setImageState({
        value: dataUpdateProject?.detail?.thumbnail,
        error: null,
      });
      setDescriptionState({
        value: dataUpdateProject?.detail?.description,
        error: null,
      });
      setPriceState({
        value: dataUpdateProject?.detail?.price,
        error: null,
      });
      setQuotaState({
        value: dataUpdateProject?.detail?.quota || 1,
        error: null,
      });
      setDateState({
        value: dayjs(dataUpdateProject?.detail?.deadlineDate).toDate(),
        error: null,
      });
      setCompanyState({
        value: dataUpdateProject?.detail?.company?.companyId,
        error: null,
      });
      setManagerState({
        value: dataUpdateProject?.detail?.pm?.pmId,
        error: null,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataUpdateProject.detail]);

  useEffect(() => {
    if (successUploadImage) {
      setImageState({ value: dataUploadImage, error: null });
    }
  }, [dataUploadImage, successUploadImage]);

  const submitDetail = () => {
    const thisPm =
      managerState?.value === undefined
        ? { pmName: null, pmId: null }
        : {
            pmName: pmName,
            pmId: managerState?.value,
          };
    const data = {
      title: titleState?.value,
      category: categoryState.value,
      description: descriptionState.value,
      price: priceState.value,
      deadlineDate: dayjs(dateState.value)
        .hour(23)
        .minute(59)
        .second(59)
        .toDate(),
      thumbnail:
        imageState.value === null
          ? process.env.REACT_APP_IMAGE_ARTICLE
          : imageState.value,
      type: typeState.value,
      quota: quotaState.value,
      company: {
        name: companyDetail?.name,
        business: companyDetail?.business,
        companyId: companyState?.value,
        clientName: companyDetail?.clientName,
        clientEmail: companyDetail?.clientEmail,
        background: companyDetail?.background,
      },
      pm: thisPm,
    };

    if (titleState?.value?.length < 1) {
      setTitleState({ ...titleState, error: "Judul project harus diisi" });
    }
    if (categoryState?.value?.length < 1) {
      setCategoryState({
        ...categoryState,
        error: "Kategori project harus diisi",
      });
    }
    if (priceState?.value < 1) {
      setPriceState({ ...priceState, error: "Harga harus diisi" });
    }
    if (dateState?.value === null) {
      setDateState({
        ...dateState,
        error: "Tanggal deadline harus diisi",
      });
    }
    if (typeState?.value?.length < 1) {
      setTypeState({ ...typeState, error: "Jenis project harus diisi" });
    }
    if (quotaState?.value < 1) {
      setQuotaState({ ...quotaState, error: "Kuota pemenang harus diisi" });
    }
    if (companyState?.value < 1) {
      setCompanyState({ ...companyState, error: "Perusahaan harus diisi" });
    }
    if (descriptionState?.value?.length < 1) {
      setDescriptionState({
        ...descriptionState,
        error: "Deskripsi harus diisi",
      });
    }

    if (
      titleState?.value?.length > 0 &&
      categoryState?.value?.length > 0 &&
      priceState?.value > 0 &&
      dateState?.value !== null &&
      quotaState?.value > 0 &&
      typeState?.value?.length > 0 &&
      companyState?.value !== null &&
      descriptionState?.value?.length > 0
    ) {
      if (props?.type === "add") {
        dispatch(dataAddDetailAction(data));
      } else {
        dispatch(dataUpdateDetailAction(data));
      }

      props.setBrief();
    }
  };

  const minDeadlineDate = new Date();
  minDeadlineDate.setDate(minDeadlineDate.getDate() + 2);

  return (
    <>
      <div className="card card-custom">
        <div className="card-body pt-8 pb-4">
          <div className="form-add-project">
            <div className="p-field">
              <label className="p-d-block">
                Judul Project <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                placeholder="Masukkan Judul Project"
                onChange={(e) =>
                  setTitleState({ value: e.target.value, error: null })
                }
                value={titleState.value}
              />
              {titleState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {titleState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Jenis Project <span className="text-danger">*</span>
              </label>
              <Dropdown
                value={typeState.value}
                options={type}
                onChange={(e) =>
                  setTypeState({ value: e.target.value, error: null })
                }
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Jenis"
              />
              {typeState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {typeState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Kategori Project <span className="text-danger">*</span>
              </label>
              <Dropdown
                value={categoryState.value}
                options={category}
                onChange={(e) =>
                  setCategoryState({ value: e.target.value, error: null })
                }
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Kategori Project"
              />
              {categoryState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {categoryState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label>Foto Thumbnail Project</label>
              <UploadThumbnail
                attachment={imageState.value}
                deleteImage={() => setImageState({ value: null, error: null })}
                path={"project-asset"}
                errorText={imageState.error}
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Deskripsi Project <span className="text-danger">*</span>
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                onChange={(e) =>
                  setDescriptionState({ value: e.target.value, error: null })
                }
                value={descriptionState.value}
              />
              {descriptionState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {descriptionState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Harga (Rp) <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block width-half"
                placeholder="Masukkan Harga Project"
                onChange={(e) =>
                  setPriceState({ value: e.target.value, error: null })
                }
                type="number"
                value={priceState.value}
              />
              {priceState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {priceState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Jumlah Tulisan yang Diinginkan{" "}
                <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block width-half"
                placeholder="Masukkan Kuota Pemenang"
                type="number"
                onChange={(e) =>
                  setQuotaState({ value: e.target.value, error: null })
                }
                value={quotaState.value}
              />
              {quotaState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {quotaState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label>
                Tanggal Deadline <span className="text-danger">*</span>
              </label>
              <Calendar
                id="icon"
                className="date-input"
                value={dateState.value}
                onChange={(e) => setDateState({ value: e.value, error: null })}
                showIcon
                dateFormat="dd/mm/yy"
                minDate={minDeadlineDate}
              />
              {dateState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {dateState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Nama Perusahaan <span className="text-danger">*</span>
              </label>
              <Dropdown
                value={companyState.value}
                options={companyOptions}
                filter
                filterBy="name"
                onChange={(e) =>
                  setCompanyState({ value: e.target.value, error: null })
                }
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Perusahaan"
                disabled={props.isClient}
              />

              {companyState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {companyState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Nama Klien <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                value={companyDetail?.clientName}
                disabled
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Email Klien <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                value={companyDetail?.clientEmail}
                disabled
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Bidang Usaha <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                value={companyDetail?.business}
                disabled
              />
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Latar Belakang Perusahaan atau Brand{" "}
                <span className="text-danger">*</span>
              </label>
              <InputTextarea
                rows={5}
                cols={30}
                value={companyDetail?.background}
                disabled
              />
            </div>

            <div className="p-field">
              <label className="p-d-block">
                Manajer Project <span className="text-danger">*</span>
              </label>
              <Dropdown
                value={managerState.value}
                options={pmOptions}
                filter
                filterBy="name"
                onChange={(e) =>
                  setManagerState({ value: e.target.value, error: null })
                }
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Manager"
                disabled={users?.user?.type === "general_pm"}
              />
              {managerState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {managerState.error}
                </small>
              )}
            </div>
          </div>
          <div className="footer-form">
            <div className="form-add-project p-d-flex p-jc-end p-ai-center">
              <ButtonDefault
                onClick={submitDetail}
                label="Selanjutnya"
                disabled={isDisabled}
                className="p-button p-button-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDetail;
