import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import MediaShareFormModule from "../../../modules/Admin/MediaShare/MediaShareFormModule";
import { getMediaAction } from "../../../../redux/action/releaseAction";

const MediaShareAddPage = () => {
  const dispatch = useDispatch();
  const queryParams = useParams();
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Media Distribusi",
    });
    dispatch(getMediaAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Media Distribusi" menu="distribution">
      <MediaShareFormModule typeForm="add" releaseId={queryParams.releaseId} />
    </DashboardLayout>
  );
};

export default MediaShareAddPage;
