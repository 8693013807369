/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { formUrl } from "../../../../helpers/url";

import {
  uploadPortfolioAction,
  resetPortfolioAction,
} from "../../../../../redux/action/userAction";

const DialogPortfolio = (props) => {
  const dispatch = useDispatch();

  const [yearValue, setYearValue] = useState("");
  const [yearError, setYearError] = useState("");
  const [titleValue, setTitleValue] = useState("");
  const [linkValue, setLinkValue] = useState("");
  const [linkError, setLinkError] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [isDisabled, setIsDisabled] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [errorImage, setErrorImage] = useState("");

  const { users } = useSelector((state) => state.auth);
  const {
    loadingUploadPortfolio,
    successUploadPortfolio,
    dataUploadPortfolio,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (
      yearValue?.length > 0 &&
      titleValue?.length > 0 &&
      (linkValue?.length > 0 || imageValue !== null)
    ) {
      setIsDisabled(false);
    }
  });

  useEffect(() => {
    if (successUploadPortfolio) {
      setImageValue(dataUploadPortfolio);
    }
  }, [successUploadPortfolio]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: ".pdf",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 5000000) {
        setErrorImage("Ukuran file lebih dari 5MB");
      } else if (acceptedFiles.length > 1) {
        setErrorImage("Silakan pilih satu file");
      } else {
        setErrorImage("");

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/user-data/${times}/${users.uid}/${fileName}`;

        dispatch(uploadPortfolioAction(acceptedFiles[0], path));
      }
    },
  });

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="Batal"
          onClick={props.closeDialog}
          className="p-button-secondary p-button-outlined btn-wide"
        />
        <Button
          label="Tambah"
          onClick={() => submitData()}
          disabled={isDisabled}
          className=" btn-wide"
        />
      </div>
    );
  };

  const changeUrl = (value) => {
    setLinkError("");
    setLinkValue(value);
  };

  const submitData = () => {
    if (yearValue.length !== 4 || !yearValue.match(/^[0-9]+$/)) {
      setYearError("Tahun tidak valid");
    } else if (linkValue?.length > 0 && !formUrl(linkValue)) {
      setLinkError("Url tidak valid");
    } else {
      const newData = {
        year: yearValue,
        title: titleValue,
        linkPortofolio: linkValue || imageValue,
      };

      props.setData(props?.data?.concat(newData));

      setTimeout(() => {
        props.closeDialog();
        setYearValue("");
        setTitleValue("");
        setLinkValue("");
        setImageValue(null);
        setLinkError("");
        dispatch(resetPortfolioAction());
      }, 100);
    }
  };

  return (
    <Dialog
      header="Tambah Portofolio"
      visible={props.visible}
      style={{ width: "50vw" }}
      footer={renderFooter("displayBasic")}
      onHide={props.closeDialog}
    >
      <div className="p-field">
        <label className="p-d-block">Tahun Portofolio*</label>
        <InputText
          className="p-d-block"
          placeholder="Contoh: 2020"
          value={yearValue}
          onChange={(e) => setYearValue(e.target.value)}
        />
        {yearError?.length > 0 && (
          <span className="p-col-12 text-helper text-danger">{yearError}</span>
        )}
      </div>
      <div className="p-field">
        <label className="p-d-block">Judul Portofolio*</label>
        <InputText
          className="p-d-block"
          placeholder="Judul Portfolio"
          value={titleValue}
          onChange={(e) => setTitleValue(e.target.value)}
        />
      </div>
      <div className="p-field p-grid">
        <label className="p-d-block p-col-12">Portofolio* </label>
        <div className="p-col-6">
          <InputText
            className="p-d-block"
            placeholder="Masukan Link Portfolio"
            value={linkValue}
            type="link"
            onChange={(e) => changeUrl(e.target.value)}
          />
          {linkError?.length > 0 && (
            <span className="p-col-12 text-helper text-danger">
              {linkError}
            </span>
          )}
        </div>

        <div className="p-col-6">
          <div {...getRootProps({ className: "file-upload" })}>
            <input {...getInputProps()} />
            <span className="file-name">
              {imageValue !== null ? imageValue : "Masukkan PDF Portofolio"}
            </span>
            {loadingUploadPortfolio ? (
              <span className="file-button">Mengupload...</span>
            ) : (
              <span className="file-button" onClick={open}>
                {imageValue !== null ? "Ubah File" : "Pilih File"}
              </span>
            )}
          </div>
        </div>
        <span className="p-col-12 text-helper-italic">
          *Pilih salah satu, gunakan link atau upload file
        </span>
      </div>
    </Dialog>
  );
};

export default DialogPortfolio;
