/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import { uploadImageAction } from "../../../redux/action/utilsAction";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

export const UploadImageComment = (props) => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    if (props.errorText) {
      props.setError(props.errorText);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.errorText]);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/png, image/jpg, image/jpeg, .pdf",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (
        acceptedFiles[0]?.type === "application/pdf" &&
        acceptedFiles[0]?.size > 5000000
      ) {
        props.setError("Ukuran dokumen lebih dari 5MB");
      } else if (
        acceptedFiles[0]?.type !== "application/pdf" &&
        acceptedFiles[0]?.size > 1000000
      ) {
        props.setError("Ukuran gambar lebih dari 1MB");
      } else if (acceptedFiles.length > 1) {
        props.setError("Silakan pilih satu file");
      } else {
        props.setError("");
        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/ticket-data/${times}/${users.uid}/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
        props.setType(acceptedFiles[0]?.type);
      }
    },
  });

  return (
    <div className="editor-wrapp">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <aside style={thumbsContainer}>
          <span className="icon-attachment" onClick={open}></span>
        </aside>
      </div>
    </div>
  );
};
