import React from "react";
import { useSelector } from "react-redux";

import TeamImage from "../../../assets/img/product-team.svg";

import ButtonLink from "../../../components/button/ButtonLink";

function ProductModule() {
  const { dataTypeProduct } = useSelector((state) => state.landingpage);

  return (
    <div className="p-d-flex p-jc-center">
      <div className="container product-page col-lg-10 col-md-12 col-sm-12">
        <div className="top-section">
          <div className="text-section">
            <h1>Layanan IRIS</h1>
            <p>
              IRIS memiliki visi menjadi platform penyebaran media rilis secara
              terintegrasi yang terkemuka di Indonesia. Menjadi pelopor dalam
              pertumbuhan media online dengan memberikan solusi berbasis hasil
              untuk membantu bisnis Anda lebih terpercaya dan kredibel di mata
              masyarakat.
            </p>
            <ButtonLink link="/create-account" type="rounded" label="Daftar" />
          </div>
          <div className="image-section">
            <img src={TeamImage} alt="image_team" />
          </div>
        </div>
        <div className="homepage-service-section">
          {dataTypeProduct?.map((item) => (
            <div className="homepage-service-item" key={item?.id}>
              <img
                className="image-service-section"
                src={item?.image}
                alt={`banner ${item.title}`}
              />
              <div className="text-section">
                <h4>{item?.title}</h4>
                <p>{item?.description}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-section">
          <h2>Daftar Gratis Sekarang!</h2>
          <ButtonLink link="/create-account" type="rounded" label="Daftar" />
        </div>
      </div>
    </div>
  );
}

export default ProductModule;
