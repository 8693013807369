import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getAboutAction } from "../../../redux/action/landingpageAction";

import { titlePage } from "../../helpers/titleHead";
import StaticLayout from "../../layouts/staticLayout";
import { TextLoad } from "../../components/loading/loadingContent";

const AboutUsPage = () => {
  const dispatch = useDispatch();
  const { loadingGetAbout, dataGetAbout } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - About Us",
    });
    dispatch(getAboutAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StaticLayout>
      <div className="static-content-wrapper">
        {loadingGetAbout ? (
          <TextLoad />
        ) : (
          <>
            <div className="title-static">
              <h1>About Us</h1>
            </div>
            <div className="body">
              <div
                dangerouslySetInnerHTML={{
                  __html: `${dataGetAbout?.aboutUs}`,
                }}
              />
            </div>
          </>
        )}
      </div>
    </StaticLayout>
  );
};

export default AboutUsPage;
