import React from "react";
import ContentLoader from "react-content-loader";

export const TitleLoad = () => {
  return (
    <ContentLoader
      width={"55%"}
      height={20}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="5" width="100%" height="20" />
    </ContentLoader>
  );
};

export const DetailPageLoad = () => {
  return (
    <>
      <ContentLoader
        width={"100%"}
        height={320}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
        <rect x="120" y="0" rx="0" ry="0" width="65%" height="30" />
        <rect x="120" y="40" rx="0" ry="0" width="80%" height="25" />
        <rect x="120" y="75" rx="0" ry="0" width="80%" height="25" />

        <rect x="0" y="150" rx="0" ry="0" width="90%" height="10" />
        <rect x="0" y="170" rx="0" ry="0" width="70%" height="10" />
        <rect x="0" y="190" rx="0" ry="0" width="80%" height="10" />
        <rect x="0" y="210" rx="0" ry="0" width="100%" height="10" />

        <rect x="0" y="250" rx="0" ry="0" width="90%" height="10" />
        <rect x="0" y="270" rx="0" ry="0" width="70%" height="10" />
        <rect x="0" y="290" rx="0" ry="0" width="80%" height="10" />
        <rect x="0" y="310" rx="0" ry="0" width="100%" height="10" />
      </ContentLoader>
    </>
  );
};

export const HeaderLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={40}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="40" height="40" />
      <rect x="65" y="10" rx="2" ry="2" width="25%" height="5" />
      <rect x="65" y="20" rx="2" ry="2" width="45%" height="10" />
    </ContentLoader>
  );
};

export const HeaderContentLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={80}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      <rect x="90" y="0" rx="2" ry="2" width="60%" height="30" />
      <rect x="90" y="40" rx="2" ry="2" width="80%" height="15" />
      <rect x="90" y="65" rx="2" ry="2" width="80%" height="15" />
    </ContentLoader>
  );
};

export const TableLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={240}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="5" ry="5" width="5%" height="20" />
      <rect x="8%" y="0" rx="5" ry="5" width="20%" height="20" />
      <rect x="31%" y="0" rx="5" ry="5" width="26%" height="20" />
      <rect x="59%" y="0" rx="5" ry="5" width="41%" height="20" />

      <rect x="0" y="40" rx="5" ry="5" width="5%" height="20" />
      <rect x="8%" y="40" rx="5" ry="5" width="20%" height="20" />
      <rect x="31%" y="40" rx="5" ry="5" width="26%" height="20" />
      <rect x="59%" y="40" rx="5" ry="5" width="41%" height="20" />

      <rect x="0" y="80" rx="5" ry="5" width="5%" height="20" />
      <rect x="8%" y="80" rx="5" ry="5" width="20%" height="20" />
      <rect x="31%" y="80" rx="5" ry="5" width="26%" height="20" />
      <rect x="59%" y="80" rx="5" ry="5" width="41%" height="20" />

      <rect x="0" y="120" rx="5" ry="5" width="5%" height="20" />
      <rect x="8%" y="120" rx="5" ry="5" width="20%" height="20" />
      <rect x="31%" y="120" rx="5" ry="5" width="26%" height="20" />
      <rect x="59%" y="120" rx="5" ry="5" width="41%" height="20" />

      <rect x="0" y="160" rx="5" ry="5" width="5%" height="20" />
      <rect x="8%" y="160" rx="5" ry="5" width="20%" height="20" />
      <rect x="31%" y="160" rx="5" ry="5" width="26%" height="20" />
      <rect x="59%" y="160" rx="5" ry="5" width="41%" height="20" />

      <rect x="0" y="200" rx="5" ry="5" width="5%" height="20" />
      <rect x="8%" y="200" rx="5" ry="5" width="20%" height="20" />
      <rect x="31%" y="200" rx="5" ry="5" width="26%" height="20" />
      <rect x="59%" y="200" rx="5" ry="5" width="41%" height="20" />
    </ContentLoader>
  );
};

export const FormLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={340}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="2" ry="12" width="120" height="15" />
      <rect x="0" y="25" rx="8" ry="8" width="100%" height="40" />
      <rect x="0" y="90" rx="2" ry="12" width="120" height="15" />
      <rect x="0" y="115" rx="8" ry="8" width="100%" height="40" />
      <rect x="0" y="180" rx="2" ry="12" width="120" height="15" />
      <rect x="0" y="205" rx="8" ry="8" width="100%" height="40" />
      <rect x="0" y="270" rx="2" ry="12" width="120" height="15" />
      <rect x="0" y="295" rx="8" ry="8" width="100%" height="40" />
    </ContentLoader>
  );
};

export const LandingHeaderLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={40}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="40" height="40" />
      <rect x="60" y="10" rx="2" ry="2" width="30%" height="5" />
      <rect x="60" y="20" rx="2" ry="2" width="45%" height="10" />
    </ContentLoader>
  );
};

export const LandingPageLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={700}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="100%" height="350" />

      <rect x="0" y="390" rx="10" ry="10" width="32%" height="200" />
      <rect x="34%" y="390" rx="10" ry="10" width="32%" height="200" />
      <rect x="67.9%" y="390" rx="10" ry="10" width="32%" height="200" />

      <rect x="0" y="600" rx="10" ry="10" width="25%" height="25" />
      <rect x="34%" y="600" rx="10" ry="10" width="25%" height="25" />
      <rect x="67.9%" y="600" rx="10" ry="10" width="25%" height="25" />

      <rect x="0" y="640" rx="10" ry="10" width="32%" height="10" />
      <rect x="34%" y="640" rx="10" ry="10" width="32%" height="10" />
      <rect x="67.9%" y="640" rx="10" ry="10" width="32%" height="10" />

      <rect x="0" y="660" rx="10" ry="10" width="32%" height="10" />
      <rect x="34%" y="660" rx="10" ry="10" width="32%" height="10" />
      <rect x="67.9%" y="660" rx="10" ry="10" width="32%" height="10" />

      <rect x="0" y="680" rx="10" ry="10" width="32%" height="10" />
      <rect x="34%" y="680" rx="10" ry="10" width="32%" height="10" />
      <rect x="67.9%" y="680" rx="10" ry="10" width="32%" height="10" />
    </ContentLoader>
  );
};

export const ListLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={100}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="10" ry="10" width="100" height="100" />
      <rect x="110" y="0" rx="2" ry="2" width="70%" height="25" />
      <rect x="110" y="45" rx="2" ry="2" width="100%" height="10" />
      <rect x="110" y="60" rx="2" ry="2" width="100%" height="10" />
      <rect x="110" y="75" rx="2" ry="2" width="100%" height="10" />
      <rect x="110" y="90" rx="2" ry="2" width="60%" height="10" />
    </ContentLoader>
  );
};

export const TextLoad = () => {
  return (
    <ContentLoader
      width={"100%"}
      height={200}
      backgroundColor="#f3f3f3"
      foregroundColor="#ecebeb"
    >
      <rect x="0" y="0" rx="2" ry="2" width="50%" height="15" />
      <rect x="0" y="25" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="40" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="55" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="70" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="85" rx="2" ry="2" width="70%" height="10" />

      <rect x="0" y="110" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="125" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="140" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="155" rx="2" ry="2" width="100%" height="10" />
      <rect x="0" y="185" rx="2" ry="2" width="70%" height="10" />
    </ContentLoader>
  );
};
