import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { titlePage } from "../../helpers/titleHead";


import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import MyOrderModule from "../../modules/Client/Order/MyOrderModule";

import { getOrderAction } from "../../../redux/action/orderAction";

const MyOrderPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { orderParams } = useSelector((state) => state.order);
  useEffect(() => {
    titlePage({
      title: "IRIS - Invoice",
    });
    history.push("/client/my-order/process");
    dispatch(getOrderAction(orderParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="myrelease-page">
      <DashboardClientLayout title="Invoice" mobileTitle="Daftar Invoice">
        <MyOrderModule />
      </DashboardClientLayout>
    </div>
  );
};

export default MyOrderPage;
