import {
  POST_SAVE_CLIENT_REQUEST,
  POST_SAVE_CLIENT_SUCCESS,
  POST_SAVE_CLIENT_FAILURE,
  GET_SAVE_CLIENT_REQUEST,
  GET_SAVE_CLIENT_SUCCESS,
  GET_SAVE_CLIENT_FAILURE,
  GET_ORDER_SAVE_DETAIL_REQUEST,
  GET_ORDER_SAVE_DETAIL_SUCCESS,
  GET_ORDER_SAVE_DETAIL_FAILURE,
  ORDER_CLIENT_SAVE_DETAIL_REQUEST,
  ORDER_CLIENT_SAVE_DETAIL_SUCCESS,
  ORDER_CLIENT_SAVE_DETAIL_FAILURE,
  RESET_SAVE_STATE_REQUEST,
} from "../constant/orderConstant";

const initialStateSave = {
  orderParams: {
    perpage: 10,
    page: 1,
  },
  totalPage: null,

  loadingSaveOrderBrief: false,
  successSaveOrderBrief: false,
  errSaveOrderBrief: null,

  loadingGetSaveClient: false,
  dataGetSaveClient: null,
  errGetSaveClient: null,

  loadingDetailOrderSave: false,
  dataDetailOrderSave: null,
  errDetailOrderSave: null,

  loadingDetailOrderSaveClient: false,
  dataDetailOrderSaveClient: null,
  errDetailOrderSaveClient: null,
};

export const saveReducer = (state = initialStateSave, action) => {
  switch (action.type) {
    case RESET_SAVE_STATE_REQUEST:
      return {
        ...state,
        successSaveOrderBrief: false,
        loadingSaveOrderBrief: false,
        errSaveOrderBrief: null,
        loadingGetSaveClient: false,
        dataGetSaveClient: null,
        errGetSaveClient: null,
        loadingDetailOrderSave: false,
        dataDetailOrderSave: null,
        errDetailOrderSave: null,
        loadingDetailOrderSaveClient: false,
        dataDetailOrderSaveClient: null,
        errDetailOrderSaveClient: null,
      };


    case POST_SAVE_CLIENT_REQUEST:
      return {
        ...state,
        loadingSaveOrderBrief: true,
        successSaveOrderBrief: false,
        errSaveOrderBrief: null,
      };
    case POST_SAVE_CLIENT_SUCCESS:
      return {
        ...state,
        loadingSaveOrderBrief: false,
        successSaveOrderBrief: action.data,
        errSaveOrderBrief: null,
      };
    case POST_SAVE_CLIENT_FAILURE:
      return {
        ...state,
        loadingSaveOrderBrief: false,
        successSaveOrderBrief: false,
        errSaveOrderBrief: action.data,
      };

    case GET_SAVE_CLIENT_REQUEST:
      return {
        ...state,
        loadingGetSaveClient: true,
        errGetSaveClient: null,
      };
    case GET_SAVE_CLIENT_SUCCESS:
      return {
        ...state,
        loadingGetSaveClient: false,
        dataGetSaveClient: action.data.data,
        errGetSaveClient: null,
        totalPage: action.data.totalpage,
      };
    case GET_SAVE_CLIENT_FAILURE:
      return {
        ...state,
        loadingGetSaveClient: false,
        errGetSaveClient: action.data,
      };
      
    case GET_ORDER_SAVE_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetailOrderSave: true,
        errDetailOrderSave: null,
      };
    case GET_ORDER_SAVE_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetailOrderSave: false,
        dataDetailOrderSave: action.data,
        errDetailOrderSave: null,
      };
    case GET_ORDER_SAVE_DETAIL_FAILURE:
      return {
        ...state,
        loadingDetailOrderSave: false,
        errDetailOrderSave: action.err,
      };
      
    case ORDER_CLIENT_SAVE_DETAIL_REQUEST:
      return {
        ...state,
        loadingDetailOrderSaveClient: true,
        errDetailOrderSaveClient: null,
      };
    case ORDER_CLIENT_SAVE_DETAIL_SUCCESS:
      return {
        ...state,
        loadingDetailOrderSaveClient: false,
        dataDetailOrderSaveClient: action.data,
        errDetailOrderSaveClient: null,
      };
    case ORDER_CLIENT_SAVE_DETAIL_FAILURE:
      return {
        ...state,
        loadingDetailOrderSaveClient: false,
        errDetailOrderSaveClient: action.err,
      };
  
    default:
      return state;
  }
};
