/* eslint-disable no-restricted-imports */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import * as relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/id";
import _ from "lodash";

import { Button } from "primereact/button";
import Dropdown from "react-bootstrap/Dropdown";

import TitleSection from "./Components/TitleSection";
import ContentSection from "./Components/ContentSection";
import PreviewContent from "./Components/PreviewSection";
import DialogAlert from "../../../components/dialog/DialogAlert";
import FullPageContent from "../../../components/loading/fullContentLoader";
import RichtextWithoutToolbar from "../../../components/richtext/richtextWithoutToolbar";

import firebase from "../../../../redux/service/firebase/firebase";
import { getToken } from "../../../../redux/service/firebase/auth";
import {
  sendChatAction,
  sendNotifAction,
} from "../../../../redux/action/chatAction";
import {
  updateArticleAction,
  submitArticleAction,
  resetStateProjectAction,
  saveAsDraftAction,
  getDraftAction,
  getArticleAction,
} from "../../../../redux/action/projectAction";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";

const CreateArticleModule = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { projectId, hashId } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const firestore = firebase.firestore();

  const [titleValue, setTitleValue] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [contentValue, setContentValue] = useState("");
  const [contentCount, setContentCount] = useState("");
  const [sendChat, setSendChat] = useState(false);

  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogErrorText, setDialogErrorText] = useState({
    title: "",
    text: "",
  });

  const [message, setMessage] = useState("");
  const [renderChats, setRenderChats] = useState(null);
  const [dialogDeadline, setDialogDeadline] = useState(false);

  const {
    dataGetDetailProject,
    dataGetArticle,
    loadingUpdateArticle,
    successUpdateArticle,
    loadingGetArticle,
    loadingSubmitArticleProject,
    successSubmitArticleProject,
    errSubmitArticleProject,
    dataGetDraft,
    successSaveDraft,
    loadingSaveDraft,
  } = useSelector((state) => state.project);

  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    if (hashId !== null) {
      getChatList(hashId);
      dispatch(getDraftAction(hashId));

      if (dataGetArticle?.chat?.userType === "pm") {
        const notif = {
          isRead: false,
          userType: "writer",
        };
        dispatch(updateArticleAction(hashId, { chat: notif }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashId, dataGetArticle?.chat?.userType]);

  useEffect(() => {
    if (
      dataGetArticle !== null &&
      (dataGetArticle?.status === "revision" ||
        dataGetArticle?.status === "ongoing")
    ) {
      autoSaveRef.current(contentValue, titleValue, imageValue);
    }
  }, [contentValue, dataGetArticle, imageValue, titleValue]);

  useEffect(() => {
    if (!dataGetDraft?.articleTitle || dataGetDraft?.articleTitle === "") {
      setTitleValue("");
    } else {
      setTitleValue(dataGetDraft?.articleTitle);
    }

    if (!dataGetDraft?.articleContent || dataGetDraft?.articleContent === "") {
      setContentValue("<p></p>");
      setContentCount("");
    } else {
      setContentValue(dataGetDraft?.articleContent);
      const counter = dataGetDraft?.articleContent
        ?.replace(/<[^>]*>/g, " ")
        .replace(/\s+/g, " ")
        .trim();
      setContentCount(counter);
    }

    setImageValue(dataGetDraft?.articleImage);
  }, [dataGetDraft]);

  useEffect(() => {
    if (successUploadImage) {
      setImageValue(dataUploadImage);
      const data = {
        articleTitle: titleValue,
        articleContent: contentValue,
        articleImage: dataUploadImage,
      };
      dispatch(saveAsDraftAction(hashId, data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    if (successUpdateArticle && sendChat) {
      setSendChat(false);
    }
  }, [successUpdateArticle, sendChat]);

  useEffect(() => {
    if (successUpdateArticle) {
      dispatch(resetUtilsStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdateArticle]);

  useEffect(() => {
    if (successSubmitArticleProject) {
      setDialogWarning(false);
      setDialogSuccess(true);
    }
  }, [successSubmitArticleProject]);

  useEffect(() => {
    if (errSubmitArticleProject?.message === "Invalid project state") {
      setDialogWarning(false);
      setDialogError(true);
      setDialogErrorText({
        title: "Gagal Mengirim Artikel",
        text: "Anda telah dinonaktifkan dari project ini",
      });
    } else if (errSubmitArticleProject) {
      setDialogWarning(false);
      setDialogError(true);
      setDialogErrorText({
        title: "Gagal Mengirim Artikel",
        text: "Deadline project sudah terlewati",
      });
    }
  }, [errSubmitArticleProject]);

  // Function Write Article
  const changeTitle = (value) => {
    setTitleValue(value);
  };
  const changeContent = (value) => {
    setContentValue(value);
    const counter = value
      ?.replace(/<[^>]*>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    setContentCount(counter);
  };

  const autoSaveRef = useRef(
    _.debounce((contentValue, titleValue, imageValue) => {
      const data = {
        articleTitle: titleValue,
        articleContent: contentValue,
        articleImage: imageValue !== undefined ? imageValue : null,
      };

      setContentValue(contentValue);
      dispatch(saveAsDraftAction(hashId, data));
    }, 2000)
  );

  const deleteImage = () => {
    setImageValue(null);
    const data = {
      articleTitle: titleValue,
      articleContent: contentValue,
      articleImage: null,
    };
    dispatch(saveAsDraftAction(hashId, data));
  };

  const closeSuccess = () => {
    setDialogSuccess(false);
    dispatch(resetStateProjectAction());
    dispatch(getArticleAction(hashId));
  };

  const closeDialogError = () => {
    setDialogError(false);
    dispatch(resetStateProjectAction());
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    const data = {
      articleTitle: titleValue,
      articleContent: contentValue,
      articleImage:
        imageValue !== undefined || imageValue === [] ? imageValue : null,
    };
    dispatch(submitArticleAction(hashId, data));
    dispatch(updateArticleAction(hashId, data));
    dispatch(saveAsDraftAction(hashId, data));
  };

  const submitArticle = () => {
    if (titleValue === "") {
      setDialogError(true);
      setDialogErrorText({
        title: "Judul Artikel Kosong",
        text: "Silahkan tulis judul artikel terlebih dahulu",
      });
    } else if (contentCount?.split(" ").length < 300) {
      setDialogError(true);
      setDialogErrorText({
        title: "Artikel Terlalu Pendek",
        text: "Silahkan tulis artikel minimal 300 karakter",
      });
    } else {
      if (dataGetDetailProject?.deadlineDate?.toDate() < new Date()) {
        setDialogDeadline(true);
      } else {
        setDialogWarning(true);
      }
    }
  };

  const saveDraf = () => {
    const data = {
      articleTitle: titleValue,
      articleContent: contentValue,
      articleImage: imageValue !== undefined ? imageValue : null,
    };
    dispatch(saveAsDraftAction(hashId, data));
  };

  // Function Comment
  const getChatList = async (id) =>
    await firestore
      .collection("writerProjects")
      .doc(id)
      .collection("chats")
      .orderBy("createdAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            _id: doc.id,
          })
        );
        setRenderChats(temp);
      });

  const sendingChat = () => {
    if (message.trim() !== "" && message !== "<p><br></p>") {
      const data = {
        createdAt: new Date().getTime(),
        message: message,
        isRead: false,
        user: {
          id: users?.uid,
          name: users?.user?.name,
          type: users?.user?.type,
        },
        type: "message",
      };
      const notif = {
        isRead: true,
        userType: "writer",
      };

      dispatch(sendChatAction(hashId, data));
      dispatch(sendNotifAction(hashId, data));
      dispatch(updateArticleAction(hashId, { chat: notif }));
      setMessage("");
      dispatch(resetUtilsStateAction());
      setSendChat(true);
    }
  };

  const downloadFile = async (type) => {
    const token = await getToken();
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1/chats/users/article/${props.hashId}?token=${token}&type=${type}`,
      "_blank"
    );
  };

  const date = dataGetArticle?.deadline?.toDate();
  const deadline = dayjs(date).format("YYYY-MM-DD");
  dayjs.extend(relativeTime);
  const deadlineCount = dayjs(deadline)
    .locale("id")
    .fromNow();

  let autoSaveText;
  if (loadingSaveDraft) {
    autoSaveText = "Simpan ke Draf";
  }
  if (successSaveDraft) {
    autoSaveText = "Tersimpan";
  }

  const fullWeight =
    dataGetArticle?.status === "ongoing" && renderChats?.length < 2;

  const changeMessage = (data) => {
    setMessage(data);
  };
  return (
    <>
      <div className="card-header">
        <div className="p-d-flex p-jc-start p-ai-center">
          <button
            href="#"
            onClick={() => history.goBack()}
            className="button-back"
          >
            <span className="icon-back"></span>
          </button>

          <h3 className="card-title align-items-start flex-column mb-0 ml-3">
            <span className="card-label font-weight-bolder text-dark">
              {dataGetArticle?.title}
            </span>
          </h3>
        </div>
        <div className="p-d-flex p-jc-start p-ai-center">
          {(dataGetArticle?.status === "published" ||
            dataGetArticle?.status === "winner") && (
            <Dropdown>
              <Dropdown.Toggle id="dropdown-basic" className="btn-download">
                <span className="icon-download mr-2" />
                Unduh Artikel
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item onClick={() => downloadFile("doc")}>
                  Format Doc
                </Dropdown.Item>
                <Dropdown.Item onClick={() => downloadFile("pdf")}>
                  Format Pdf
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </div>
      </div>
      <div className="card-header p-jc-center">
        {loadingGetArticle ? (
          <>
            <FullPageContent />
          </>
        ) : (
          <div className="section-progress writer">
            <div className="step-add preview">
              <ul>
                <li className="active">
                  <div className="step-number">
                    <span className="done"></span>
                  </div>
                  <div className="step-text">Bergabung</div>
                  <div className="step-date">
                    {dataGetArticle?.createdAt
                      ? dayjs(dataGetArticle?.createdAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </div>
                </li>
                <li className={dataGetArticle?.submitAt ? "active" : ""}>
                  <div className="step-number">
                    <span className="done"></span>
                  </div>
                  <div className="step-text">On Progress</div>

                  <div className="step-date">
                    {dataGetArticle?.submitAt
                      ? dayjs(dataGetArticle?.submitAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </div>
                </li>
                {dataGetArticle?.lastRevisionAt && (
                  <li className="active">
                    <div className="step-number">
                      <span className="done"></span>
                    </div>
                    <div className="step-text">Revisi</div>

                    <div className="step-date">
                      {dayjs(dataGetArticle?.lastRevisionAt?.toDate())
                        .locale("id")
                        .format("DD MMMM YYYY")}
                    </div>
                  </li>
                )}
                <li
                  className={
                    dataGetArticle?.doneAt ||
                    (dataGetArticle?.status !== "object"
                      ? dataGetArticle?.status === "reject"
                      : dataGetArticle?.status?.status === "reject")
                      ? "active"
                      : ""
                  }
                >
                  <div className="step-number">
                    <span className="done"></span>
                  </div>
                  <div className="step-text">Selesai</div>

                  <div className="step-date">
                    {dataGetArticle?.doneAt
                      ? dayjs(dataGetArticle?.doneAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        )}
      </div>
      <div className="card-body p-0">
        {loadingGetArticle ? (
          <div className="p-5">
            <FullPageContent />
            <FullPageContent />
          </div>
        ) : (
          <div className="article-preview">
            <div
              className={`section-article no-scroll ${
                fullWeight ? "w-100" : ""
              }`}
            >
              {dataGetArticle?.status === "revision" ||
              dataGetArticle?.status === "ongoing" ? (
                <div className="section-article-box">
                  <div className="section-article-header">
                    <TitleSection
                      titleValue={titleValue}
                      imageValue={imageValue}
                      changeTitle={(e) => changeTitle(e.target.value)}
                      deleteImage={() => deleteImage()}
                    />
                  </div>
                  <div className="section-article-body">
                    <ContentSection
                      contentValue={contentValue}
                      changeContent={changeContent}
                    />
                  </div>
                </div>
              ) : (
                <PreviewContent articleData={dataGetArticle} />
              )}
              {dataGetArticle?.status === "revision" ||
              dataGetArticle?.status === "ongoing" ? (
                <div className="section-article-footer">
                  <div className="write-note">
                    <div className="write-count">
                      {contentCount === ""
                        ? 0
                        : contentCount?.split(" ").length}
                      /<strong>300</strong> Kata
                    </div>
                    <div className="write-save">{autoSaveText}</div>
                  </div>
                  <div className="write-button">
                    <Button
                      onClick={() => saveDraf()}
                      className="p-button-rounded p-button p-button-outlined mr-3"
                    >
                      SIMPAN DRAF
                    </Button>

                    <Button
                      onClick={() => submitArticle()}
                      disabled={successSubmitArticleProject}
                      className="p-button-rounded p-button p-button-primary"
                    >
                      {dataGetArticle?.status !== "revision"
                        ? "KIRIM TULISAN"
                        : "KIRIM REVISI"}
                    </Button>
                  </div>
                </div>
              ) : (
                <div className="section-article-footer">
                  <div className="write-note">
                    <div className="write-count">
                      {contentCount === ""
                        ? 0
                        : contentCount?.split(" ").length}{" "}
                      Kata
                    </div>
                  </div>
                </div>
              )}{" "}
            </div>
            <div
              className={`section-comment-writer ${fullWeight ? "d-none" : ""}`}
            >
              <div className="comment-writer-head">Catatan PM</div>
              <div className="comment-writer-body note-pm">
                <div className="comment-list-wraper">
                  {renderChats?.filter((item) => item.isPinned)?.length < 1 ? (
                    <div className="comment-list empty">Belum ada catatan</div>
                  ) : (
                    <>
                      {renderChats
                        ?.filter((item) => item.isPinned)
                        .map((item) => (
                          <div className="comment-list" key={item._id}>
                            <div className="comment-image">
                              <div
                                className={`chat-avatar ${item?.user?.type}`}
                              >
                                {item?.user?.type === "system"
                                  ? "S"
                                  : item?.user?.name?.substring(0, 1)}
                              </div>
                            </div>
                            <div className="comment-content">
                              <div className="comment-user">
                                {item?.user?.type === "system"
                                  ? "Sistem"
                                  : item?.user?.name}{" "}
                                {item?.user?.type !== "system" && (
                                  <span>
                                    (
                                    {item?.user?.type === "pm"
                                      ? "Manajer Project"
                                      : "Penulis"}
                                    )
                                  </span>
                                )}
                              </div>
                              <div
                                className={`comment-text ${
                                  item?.user?.type === "system" ? "blue" : ""
                                }`}
                              >
                                <div
                                  className="message-inner"
                                  dangerouslySetInnerHTML={{
                                    __html: `${item?.message}`,
                                  }}
                                />
                              </div>
                              <div className="comment-date">
                                {dayjs(item?.createdAt)
                                  .locale("id")
                                  .format("DD MMMM YYYY HH:mm")}
                              </div>
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                </div>
              </div>
              <div className="comment-writer-box">
                <label>Komentar</label>
                <RichtextWithoutToolbar
                  contentValue={message}
                  changeContent={changeMessage}
                  placeholder="Tulis komentar anda"
                />
                <div className="p-d-flex p-jc-end w-100">
                  <Button
                    className="button-link p-button-rounded"
                    onClick={() => sendingChat()}
                    disabled={dataGetArticle?.status === "removed"}
                  >
                    Kirim
                  </Button>
                </div>
              </div>
              <div className="comment-writer-head">Riwayat Aktivitas</div>
              <div
                className={`comment-writer-body ${
                  (dataGetArticle?.status === "revision" ||
                    dataGetArticle?.status === "ongoing") &&
                  (dataGetArticle.articleImage || imageValue !== null)
                    ? "fit-content"
                    : "comment-wrap"
                }`}
              >
                <div className="comment-list-wraper">
                  {renderChats
                    ?.filter((item) => !item.isPinned)
                    .map((item) => (
                      <div className="comment-list" key={item._id}>
                        <div className="comment-image">
                          <div className={`chat-avatar ${item?.user?.type}`}>
                            {item?.user?.type === "system"
                              ? "S"
                              : item?.user?.name?.substring(0, 1)}
                          </div>
                        </div>
                        <div className="comment-content">
                          <div className="comment-user">
                            {item?.user?.type === "system"
                              ? "Sistem"
                              : item?.user?.name}{" "}
                            {item?.user?.type !== "system" && (
                              <span>
                                (
                                {item?.user?.type === "pm"
                                  ? "Manajer Project"
                                  : "Penulis"}
                                )
                              </span>
                            )}
                          </div>
                          <div
                            className={`comment-text ${
                              item?.user?.type === "system" ? "blue" : ""
                            }`}
                          >
                            <div
                              className="message-inner"
                              dangerouslySetInnerHTML={{
                                __html: `${item?.message}`,
                              }}
                            />
                          </div>
                          <div className="comment-date">
                            {dayjs(item?.createdAt)
                              .locale("id")
                              .format("DD MMMM YYYY HH:mm")}
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      <DialogAlert
        type={"warning"}
        actionDialog={handlesubmit}
        actionText={"KIRIM"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Kirim Artikel?"}
        text={"Pastikan artikel anda sudah sesuai"}
        loading={loadingSubmitArticleProject}
      />
      <DialogAlert
        type={"warning"}
        actionDialog={handlesubmit}
        actionText={"KIRIM"}
        visible={dialogDeadline}
        closeDialog={() => setDialogDeadline(false)}
        title={"Kirim Artikel?"}
        text={"Anda telah melewati deadline"}
        loading={loadingSubmitArticleProject}
      />
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={() => closeDialogError()}
        title={dialogErrorText.title}
        text={dialogErrorText.text}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={`Berhasil ${
          dataGetArticle?.status !== "revision" ? `Mengirimkan` : `Merevisi`
        } Artikel`}
        text={"Artikel berhasil dikirim dan akan segera direview"}
      />
    </>
  );
};

export default CreateArticleModule;
