import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import HeaderMobileLanding from "../../components/header/HeaderMobileLanding";

import { getProvincesAction } from "../../../redux/action/utilsAction";
import { titlePage } from "../../helpers/titleHead";
import { checkDevice } from "../../helpers/deviceDetect";

import { getSettingSiteAction } from "../../../redux/action/landingpageAction";
import SelectRoleModule from "../../modules/User/Register/SelectRoleModule";
import Header from "../../components/header/Header";

const RegisterSelectRolePage = (props) => {
  const dispatch = useDispatch();

  const [isMobile, setIsMobile] = useState(checkDevice());

  const { loadingGetSettingSite, dataGetSettingSite } = useSelector(
    (state) => state.landingpage
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - Halaman Registrasi",
    });

    dispatch(getProvincesAction());
    setIsMobile(checkDevice);
    !dataGetSettingSite && dispatch(getSettingSiteAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="auth-page">
      <div className="header-landing-client">
        {isMobile ? (
          <HeaderMobileLanding
            dataSite={dataGetSettingSite}
            loading={loadingGetSettingSite}
          />
        ) : (
          <Header
            dataSite={dataGetSettingSite}
            loading={loadingGetSettingSite}
          />
        )}
      </div>
      <div className="auth-wrapper">
        <div className="container">
          <div className="p-grid">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-lg-0 p-md-0">
              <div className="auth-form select-role p-d-flex p-jc-center">
                <SelectRoleModule />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterSelectRolePage;
