import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getOrderAction } from "../../../redux/action/orderAction";
import { titlePage } from "../../helpers/titleHead";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import PICAddModule from "../../modules/Client/PICManagement/PICAddModule";

const AddPICPage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Tim",
    });
    dispatch(getOrderAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="pic-page">
      <DashboardClientLayout
        title="Manajemen Tim"
        mobileTitle="Tambah Tim"
        menu="team"
      >
        <PICAddModule />
      </DashboardClientLayout>
    </div>
  );
};

export default AddPICPage;
