import api from "./api";

export const getInfo = async (token, params) => {
  return api.get("/pm/information", params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postInfo = async (token, data) => {
  return api.post("/pm/information", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateInfo = async (token, id, data) => {
  return api.put(`/pm/information/${id}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteInfo = async (token, id) => {
  return api.delete(
    `/pm/information/${id}`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
