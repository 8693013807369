/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";

import { TabView, TabPanel } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Checkbox } from "primereact/checkbox";

import CoinImage from "../../../assets/img/icon-coin.svg";
import FolderIcon from "../../../assets/img/icon-folder.svg";
import ClockImage from "../../../assets/img/icon-clock.svg";
import iconDownload from "../../../assets/img/download-blue.svg";

import FullContentLoader from "../../../components/loading/fullContentLoader";
import StatusProject from "../../../components/badge/StatusProject";

import { getToken } from "../../../../redux/service/firebase/auth";

const DetailMyOrderModule = (props) => {
  const { loadingDetailOrderClient, dataDetailOrderClient } = useSelector(
    (state) => state.order
  );

  let categoryName;
  if (dataDetailOrderClient?.productCategory === "pr") {
    categoryName = "Press Release";
  } else if (dataDetailOrderClient?.productCategory === "distribution_media") {
    categoryName = "Media Distribusi";
  } else {
    categoryName = "Artikel";
  }

  const downloadFile = async () => {
    const token = await getToken();
    return window.open(
      `${process.env.REACT_APP_STORAGE_URL}/v1/client/orders/${props.projectId}/invoice?token=${token}`,
      "_blank"
    );
  };

  return (
    <>
      <div className="card card-custom-detail">
        {loadingDetailOrderClient ? (
          <div className="card-body pt-0 pb-3">
            <FullContentLoader />
            <FullContentLoader />
            <FullContentLoader />
            <FullContentLoader />
          </div>
        ) : (
          <>
            <div className="card-header p-d-flex p-jc-between border-0 py-5 ">
              <div className="p-d-flex p-jc-start p-ai-center">
                <Link to="/client/my-order/process" className="button-back">
                  <span className="icon-back"></span>
                </Link>

                <h3 className="card-title align-items-start flex-column ml-3">
                  <span className="card-label font-weight-bolder text-dark">
                    {dataDetailOrderClient?.title}
                  </span>
                </h3>
              </div>
              <a
                className="btn-download"
                onClick={() => downloadFile()}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={iconDownload} />
                Unduh Invoice
              </a>
            </div>
            <div className="card-body pt-0 pb-3">
              <div className="section-project mb-0">
                <div className="p-d-flex p-jc-between">
                  <div className="p-d-flex">
                    <div className="project-image">
                      <img
                        src={
                          dataDetailOrderClient?.thumbnail ||
                          process.env.REACT_APP_IMAGE_ARTICLE
                        }
                        alt="tumbnail"
                      />
                    </div>
                    <div className="project-info">
                      <div className="info">
                        <img src={CoinImage} alt="" />
                        <NumberFormat
                          prefix={"Rp "}
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataDetailOrderClient?.totalPrice}
                          renderText={(value) => <p>{value}</p>}
                        />
                        <p>{}</p>
                      </div>
                      <div className="info">
                        <img src={ClockImage} alt="" />
                        <p>
                          {`Tanggal Pesan 
                          ${dayjs(dataDetailOrderClient?.createdAt?.toDate())
                            .locale("id")
                            .format("DD MMMM YYYY")}`}
                        </p>
                      </div>
                      <div className="info">
                        <img src={FolderIcon} alt="" />
                        <NumberFormat
                          thousandSeparator={"."}
                          decimalSeparator={","}
                          displayType="text"
                          value={dataDetailOrderClient?.quantity || 0}
                          renderText={(value) => (
                            <p>{`x${value} ${categoryName}`}</p>
                          )}
                        />
                      </div>
                      <div className="info">
                        <StatusProject
                          status={dataDetailOrderClient?.status}
                          userType="client"
                          className=""
                        />
                      </div>
                    </div>
                  </div>

                  <div className="project-button client" />
                </div>
              </div>
              {dataDetailOrderClient?.status === "payment_waiting" && (
                <div className="section-project border-0">
                  <div className="project-detail-alert">
                    <h3>PEMBAYARAN BELUM TERKONFIRMASI</h3>
                    <p>
                      Silahkan periksa kembali tagihan anda agar pesanan bisa
                      kami diproses
                    </p>
                    <Link
                      className="p-button p-button-rounded p-button-primary"
                      to={`/client/invoice/${props.projectId}`}
                    >
                      {" "}
                      KONFIRMASI PEMBAYARAN
                    </Link>
                  </div>
                </div>
              )}
              {dataDetailOrderClient?.status === "confirmation_waiting" &&
                !dataDetailOrderClient?.briefData && (
                  <div className="section-project border-0">
                    <div className="project-detail-alert">
                      <h3>PESANAN BELUM TERKONFIRMASI</h3>
                      <p>
                        Pembayaran untuk pesanan {dataDetailOrderClient?.title}{" "}
                        sedang diproses oleh tim IRIS, mohon menunggu maksimal 3
                        hari kerja. Silahkan lengkapi brief pesanan terlebih
                        dahulu.
                      </p>
                      <Link
                        className="p-button p-button-rounded"
                        to={`/client/create-brief/${props.projectId}`}
                      >
                        {" "}
                        BUAT BRIEF
                      </Link>
                    </div>
                  </div>
                )}
              {dataDetailOrderClient?.status !== "confirmation_waiting" &&
                dataDetailOrderClient?.status !== "payment_waiting" &&
                !dataDetailOrderClient?.briefData && (
                  <div className="section-project border-0">
                    <div className="project-detail-alert">
                      <h3>BRIEF PROYEK BELUM LENGKAP</h3>
                      <p>
                        Silahkan lengkapi brief pesanan terlebih dahulu agar
                        pesanan bisa kami proses.
                      </p>
                      <Link
                        className="p-button p-button-rounded p-button-primary"
                        to={`/client/create-brief/${props.projectId}`}
                      >
                        {" "}
                        BUAT BRIEF
                      </Link>
                    </div>
                  </div>
                )}
              {dataDetailOrderClient?.briefData && (
                <>
                  <div className="section-project pt-3">
                    <h6>Deskripsi Project:</h6>
                    <p>{dataDetailOrderClient?.briefData?.description}</p>
                  </div>
                  <div className="tab-project">
                    <TabView>
                      <TabPanel header="Ringkasan Project">
                        <div className="tab-info">
                          <div className="p-field p-col-8">
                            <label>Kategori Project</label>
                            <InputText
                              value={dataDetailOrderClient?.briefData?.category}
                              className="p-d-block"
                              disabled
                            />
                          </div>
                          <div className="p-field p-col-8">
                            <label>Jenis Project</label>
                            <InputText
                              value={categoryName}
                              className="p-d-block"
                              disabled
                            />
                          </div>
                          <div className="p-field p-col-8">
                            <label>Tanggal Deadline</label>
                            <InputText
                              value={dayjs(
                                dataDetailOrderClient?.briefData?.deadlineDate?.toDate()
                              ).format("DD/MM/YYYY")}
                              className="p-d-block"
                              disabled
                            />
                          </div>
                        </div>
                      </TabPanel>
                      <TabPanel header="Press Release">
                        <div className="tab-info">
                          <div className="p-field p-col-8">
                            <label>Kata Kunci Pencarian</label>
                            <InputText
                              value={
                                dataDetailOrderClient?.briefData?.searchKeyword
                              }
                              className="p-d-block"
                              disabled
                            />
                          </div>
                          {dataDetailOrderClient?.briefData?.type === "pr" && (
                            <>
                              <div className="p-field p-col-8">
                                <label>Kategori Rilis</label>
                                <InputText
                                  value={
                                    dataDetailOrderClient?.briefData
                                      ?.releaseCategory
                                  }
                                  className="p-d-block"
                                  disabled
                                />
                              </div>
                            </>
                          )}
                          <div className="p-field p-col-8">
                            <label>Target Pembaca</label>
                            <div className="p-d-flex p-mb-3">
                              <label className="p-d-block p-col-4 p-pl-0 grey">
                                Jenis Kelamin
                              </label>
                              <InputText
                                value={
                                  dataDetailOrderClient?.briefData?.target?.sex
                                }
                                className="p-d-block p-col-8"
                                disabled
                              />
                            </div>
                            <div className="p-d-flex p-mb-3">
                              <label className="p-d-block p-col-4 p-pl-0 grey">
                                Usia
                              </label>
                              <InputText
                                value={
                                  dataDetailOrderClient?.briefData?.target
                                    ?.age || "Belum diatur"
                                }
                                className="p-d-block p-col-8"
                                disabled
                              />
                            </div>
                            <div className="p-d-flex p-mb-3">
                              <label className="p-d-block p-col-4 p-pl-0 grey">
                                SES
                              </label>
                              <InputText
                                value={
                                  dataDetailOrderClient?.briefData?.target
                                    ?.ses || "Belum diatur"
                                }
                                className="p-d-block p-col-8"
                                disabled
                              />
                            </div>
                            <div className="p-d-flex p-mb-3">
                              <label className="p-d-block p-col-4 p-pl-0 grey">
                                Stakeholder
                              </label>
                              <div className="p-d-block p-col-8">
                                {dataDetailOrderClient?.briefData?.target?.stakeholders?.map(
                                  (item) => (
                                    <div className="mb-2">
                                      <Checkbox inputId={`cb${item}`} checked />
                                      <label
                                        htmlFor={`cb${item}`}
                                        className="p-checkbox-label"
                                      >
                                        {item}
                                      </label>
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="p-field p-col-8">
                            <label>
                              Objektif Komunikasi Yang Ingin Dicapai
                            </label>
                            <InputTextarea
                              value={
                                dataDetailOrderClient?.briefData?.objective
                              }
                              className="p-d-block text-capitalize"
                              readOnly
                            />
                          </div>
                          <div className="p-field p-col-8">
                            <label>
                              Atribut Quote Dan Pesan Yang Ingin Disampaikan
                            </label>
                            <InputTextarea
                              value={
                                dataDetailOrderClient?.briefData?.attribution
                              }
                              className="p-d-block text-capitalize"
                              readOnly
                            />
                          </div>
                          <div className="p-field p-col-8">
                            <label>
                              Hal Yang Diperhatikan Dalam Penulisan Release
                            </label>
                            <InputTextarea
                              value={
                                dataDetailOrderClient?.briefData?.releaseNote
                              }
                              className="p-d-block text-capitalize"
                              readOnly
                            />
                          </div>
                        </div>
                      </TabPanel>
                    </TabView>
                  </div>
                </>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default DetailMyOrderModule;
