/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";

import { useParams } from "react-router-dom";

import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";

import { clientDataProjectAction } from "../../../../../redux/action/utilsAction";
import ButtonDefault from "../../../../components/button/ButtonDefault";
import { getProductDetailAction } from "../../../../../redux/action/serviceAction";

const AddDetail = (props) => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const [titleState, setTitleState] = useState({ value: "", error: null });
  const [categoryState, setCategoryState] = useState({
    value: null,
    error: null,
  });
  const [typeState, setTypeState] = useState({
    value: null,
    error: null,
  });
  const [descriptionState, setDescriptionState] = useState({
    value: "",
    error: null,
  });
  const [quotaState, setQuotaState] = useState({ value: 1, error: null });
  const [dateState, setDateState] = useState({ value: null, error: null });
  const [isDisabled, setIsDisabled] = useState(true);

  const category = [
    { name: "Public", code: "public" },
    { name: "Private", code: "private" },
  ];

  const type = [
    { name: "Artikel", code: "article" },
    { name: "Press Release", code: "pr" },
    // Hide Until Next Sprint
    // { name: "Media Distribusi", code: "distribution_media" },
  ];

  useEffect(() => {
    dispatch(getProductDetailAction(QueryParams.productId))
  }, []);

  const { dataAddProjectClient } = useSelector((state) => state.utils);
  const { dataDetailProduct } = useSelector(
    (state) => state.service
  );

  useEffect(() => {
    if (titleState?.value?.length < 1) {
      setTitleState({ ...titleState, error: "Judul project harus diisi" });
    }
    if (categoryState?.value?.length < 1) {
      setCategoryState({
        ...categoryState,
        error: "Kategori project harus diisi",
      });
    }
    if (dateState?.value === null) {
      setDateState({
        ...dateState,
        error: "Tanggal deadline harus diisi",
      });
    }
    if (typeState?.value?.length < 1) {
      setTypeState({ ...typeState, error: "Jenis project harus diisi" });
    }
    if (quotaState?.value < 1) {
      setQuotaState({ ...quotaState, error: "Kuota pemenang harus diisi" });
    }
    if (descriptionState?.value?.length < 1) {
      setDescriptionState({
        ...descriptionState,
        error: "Deskripsi project harus diisi",
      });
    }
    if (
      titleState?.value?.length > 0 &&
      categoryState?.value?.length > 0 &&
      dateState?.value !== null &&
      quotaState?.value > 0 &&
      typeState?.value?.length > 0 &&
      descriptionState?.value?.length > 0
    ) {
      setIsDisabled(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    titleState.value,
    categoryState.value,
    dateState.value,
    quotaState.value,
    typeState.value,
    dataAddProjectClient,
  ]);

  useEffect(() => {
    if (dataAddProjectClient !== null) {
      setTitleState({
        value: dataAddProjectClient?.title,
        error: null,
      });
      setCategoryState({
        value: dataAddProjectClient?.category,
        error: null,
      });
      setTypeState({
        value: dataDetailProduct?.category ? dataDetailProduct?.category : dataAddProjectClient?.type,
        error: null,
      });
      setDescriptionState({
        value: dataAddProjectClient?.desc,
        error: null,
      });
      setQuotaState({
        value: dataAddProjectClient?.quota || 1,
        error: null,
      });

      setDateState({
        value:
          dataAddProjectClient?.deadline !== null
            ? dayjs(dataAddProjectClient?.deadline).toDate()
            : null,
        error: null,
      });
    }
  }, [dataAddProjectClient]);

  const textarea = document.querySelector("textarea");
  const autosize = () => {
    let el = textarea;
    setTimeout(() => {
      if (el) {
        el.style.cssText = "height:auto; padding:0";
        el.style.cssText = "height:" + el.scrollHeight + "px";
      }
    }, 0);
  };
  if (window.screen.width < 768) {
    textarea && textarea.addEventListener("keydown", autosize);
  }

  const submitDetail = (e) => {
    e.preventDefault();
    const data = {
      keyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: dataAddProjectClient.storyAngles,
      title: titleState.value,
      category: categoryState.value,
      type: typeState.value,
      quota: quotaState.value,
      desc: descriptionState.value,
      deadline: dayjs(dateState.value)
        .hour(23)
        .minute(59)
        .second(59)
        .toDate(),
      releaseCategory: dataAddProjectClient?.releaseCategory,
      objective: dataAddProjectClient?.objective,
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient.releaseNote,
      // releaseCategoryQuestion: dataAddProjectClient.releaseCategoryQuestion,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
    };
    const dataArticle = {
      keyword: dataAddProjectClient.keyword,
      title: titleState.value,
      category: categoryState.value,
      type: typeState.value,
      quota: quotaState.value,
      desc: descriptionState.value,
      deadline: dayjs(dateState.value)
        .hour(23)
        .minute(59)
        .second(59)
        .toDate(),
      objective: dataAddProjectClient?.objective,
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient.releaseNote,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
    };

    if (titleState?.value?.length < 1) {
      setTitleState({ ...titleState, error: "Judul project harus diisi" });
    }
    if (categoryState?.value?.length < 1) {
      setCategoryState({
        ...categoryState,
        error: "Kategori project harus diisi",
      });
    }
    if (dateState?.value === null) {
      setDateState({
        ...dateState,
        error: "Tanggal deadline harus diisi",
      });
    }
    if (typeState?.value?.length < 1) {
      setTypeState({ ...typeState, error: "Jenis project harus diisi" });
    }
    if (quotaState?.value < 1) {
      setQuotaState({ ...quotaState, error: "Kuota pemenang harus diisi" });
    }
    if (descriptionState?.value?.length < 1) {
      setDescriptionState({
        ...descriptionState,
        error: "Deskripsi project harus diisi",
      });
    }
    if (dataDetailProduct?.category === "pr") {
      if (
        titleState?.value?.length > 0 &&
        categoryState?.value?.length > 0 &&
        // dateState?.value !== null &&
        quotaState?.value > 0 &&
        typeState?.value?.length > 0
      ) {
        dispatch(clientDataProjectAction(data));
        props.submitData();
      }
    } else if (dataDetailProduct?.category === "article") {
      if (
        titleState?.value?.length > 0 &&
        categoryState?.value?.length > 0 &&
        // dateState?.value !== null &&
        quotaState?.value > 0 &&
        typeState?.value?.length > 0
      ) {
        dispatch(clientDataProjectAction(dataArticle));
        props.setBrief();
      }
    }
  };

  const minDeadlineDate = new Date();
  minDeadlineDate.setDate(minDeadlineDate.getDate() + 2);

  return (
    <>
      <div className="card card-custom add-detail-brief">
        <div className="card-body pt-8 pb-4">
          <div className="form-add-project brief">
            <div className="p-field">
              <label className="p-d-block">
                Judul Project <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block"
                placeholder="Masukkan Judul Project"
                onChange={(e) =>
                  setTitleState({ value: e.target.value, error: null })
                }
                value={titleState.value}
              />
              {titleState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {titleState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Jenis Project <span className="text-danger">*</span>
              </label>
              <Dropdown
                value={typeState.value}
                options={type}
                onChange={(e) =>
                  setTypeState({ value: e.target.value, error: null })
                }
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Jenis"
                disabled
              />
              {typeState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {typeState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Kategori Project <span className="text-danger">*</span>
              </label>
              <Dropdown
                value={categoryState.value}
                options={category}
                onChange={(e) =>
                  setCategoryState({ value: e.target.value, error: null })
                }
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Kategori Project"
              />
              {categoryState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {categoryState.error}
                </small>
              )}
            </div>

            <div className="p-field">
              <label className="p-d-block">
                Deskripsi Project <span className="text-danger">*</span>
              </label>
              <InputTextarea
                rows={1}
                cols={30}
                onChange={(e) =>
                  setDescriptionState({ value: e.target.value, error: null })
                }
                value={descriptionState.value}
              />
              {descriptionState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {descriptionState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label className="p-d-block">
                Jumlah Tulisan yang Diinginkan{" "}
                <span className="text-danger">*</span>
              </label>
              <InputText
                className="p-d-block width-half"
                placeholder="Masukkan Kuota Pemenang"
                type="number"
                onChange={(e) =>
                  setQuotaState({ value: e.target.value, error: null })
                }
                value={quotaState.value}
                disabled
              />
              {quotaState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {quotaState.error}
                </small>
              )}
            </div>
            <div className="p-field">
              <label>
                Tanggal Deadline <span className="text-danger">*</span>
              </label>
              <Calendar
                id="icon"
                className="date-input"
                value={dateState.value}
                onChange={(e) => setDateState({ value: e.value, error: null })}
                showIcon
                dateFormat="dd/mm/yy"
                minDate={minDeadlineDate}
              />
              {dateState.error && (
                <small id="name" className="p-error p-d-block p-text-right">
                  {dateState.error}
                </small>
              )}
            </div>
          </div>
          <div className="footer-form">
            <div className="form-add-project brief p-d-flex p-jc-between p-ai-center">
              <ButtonDefault
                label="Kembali"
                onClick={props.goBack}
                type="rounded"
                color="grey"
                className="p-button p-button-secondary"
              />
              <ButtonDefault
                onClick={submitDetail}
                label="Selanjutnya"
                type="rounded"
                disabled={isDisabled}
                className="p-button p-button-primary"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddDetail;
