import React from "react";
import { useHistory } from "react-router-dom";

import CompanyDetailForm from "./Components/CompanyDetailForm";

const CompanyEditModule = (props) => {
  const history = useHistory();
  return (
    <>
      <div className="p-d-flex p-jc-center">
        <div className="card card-custom mb-4 col-8">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Edit Perusahaan
                </span>
              </button>
            </h3>
          </div>
          <CompanyDetailForm isDisabled={false} companyId={props.companyId} />
        </div>
      </div>
    </>
  );
};

export default CompanyEditModule;
