/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

import DialogAlert from "../../../../components/dialog/DialogAlert";
import ListLoader from "../../../../components/loading/listLoader";
import {
  resetStateUserAction,
  deleteUserAction,
  updateStatusUserAction,
} from "../../../../../redux/action/userAction";
import ButtonDefault from "../../../../components/button/ButtonDefault";

const PMDetailModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loadingState, setLoadingState] = useState(false);
  const [dialogWarning, setDialogWarning] = useState({
    dialog: false,
    title: "",
    text: "",
    action: null,
    actionText: "",
    loading: false,
  });
  const [dialogSucsess, setDialogSuccess] = useState({
    dialog: false,
    title: "",
    text: "",
  });

  const {
    loadingDetailUser,
    dataDetailUser,
    loadingDeleteUser,
    successDeleteUser,
    loadingUpdateStatusUser,
    successUpdateStatusUser,
  } = useSelector((state) => state.user);

  useEffect(() => {
    if (successUpdateStatusUser) {
      const active = dataDetailUser?.status === "active";
      setTimeout(() => {
        setLoadingState(false);
        closeDialogWarning();
        setDialogSuccess({
          dialog: true,
          title: `PM Berhasil ${active ? "Dinonaktifkan" : "Diaktifkan"}`,
          text: `${dataDetailUser?.name} sudah ${
            active ? "dinonaktifkan" : "diaktifkan"
          }`,
        });
      }, 1000);
    }
    if (successDeleteUser) {
      setTimeout(() => {
        setLoadingState(false);
        closeDialogWarning();
        setDialogSuccess({
          dialog: true,
          title: "Data Berhasil Dihapus",
          text: `${dataDetailUser?.name} sudah dihapus`,
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDeleteUser, successUpdateStatusUser]);

  const confirmDelete = () => {
    setDialogWarning({
      dialog: true,
      title: `Hapus ${dataDetailUser?.name}?`,
      text: "Data yang dihapus tidak dapat dikembalikan",
      action: () => deleteData(),
      actionText: "HAPUS",
      loading: loadingState,
    });
  };

  const deleteData = () => {
    dispatch(deleteUserAction(props.userId));
    setLoadingState(true);
  };

  const confirmStatus = () => {
    const active = dataDetailUser?.status === "active";
    setDialogWarning({
      dialog: true,
      title: `${active ? "Nonaktifkan" : "Aktifkan"} ${dataDetailUser?.name}?`,
      text: `${
        active
          ? "Pm tidak bisa login setelah dinonaktifkan"
          : "Pm bisa kembali login setelah diaktifkan"
      }`,
      action: () => updateStatus(),
      actionText: `${active ? "NONAKTIFKAN" : "AKTIFKAN"}`,
      loading: loadingUpdateStatusUser,
    });
  };

  const updateStatus = () => {
    const status =
      dataDetailUser?.status === "active" ? "non_active" : "active";
    dispatch(updateStatusUserAction(props.userId, status));
    setLoadingState(true);
  };

  const closeDialogWarning = () => {
    setDialogWarning({
      dialog: false,
      title: "",
      text: "",
      action: null,
      actionText: "",
      loading: false,
    });
  };

  const closeSuccess = () => {
    dispatch(resetStateUserAction());
    setDialogSuccess({ dialog: false, title: "", text: "" });
    history.push("/admin/pm/list-pm");
  };

  return (
    <>
      <div className="p-d-flex p-jc-center detail-pm">
        <div className="card card-custom col-8 mb-4">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Detail Project Manager
                </span>
              </button>
            </h3>
            <div className="p-d-flex action-span">
              <button
                onClick={() => confirmDelete()}
                disabled={
                  dataDetailUser?.nProjectHandled > 0 || loadingDeleteUser
                }
                className="btn px-0 py-o mr-1 text-primary"
              >
                <strong className="text-danger">Hapus</strong>
              </button>
            </div>
          </div>
          {loadingDetailUser ? (
            <ListLoader />
          ) : (
            <div className="card-body card-pm">
              <div className="form-detail-pm col-10">
                <div className="p-field">
                  <label className="p-d-block">Tanggal Daftar</label>
                  <p>
                    {dayjs(dataDetailUser?.createdAt?.toDate())
                      .locale("id")
                      .format("DD MMMM YYYY")}
                  </p>
                </div>

                <div className="p-field">
                  <label className="p-d-block">Nama Project Manager</label>
                  <p>{dataDetailUser?.name}</p>
                </div>
                <div className="p-field">
                  <label className="p-d-block">Email</label>
                  <p>{dataDetailUser?.email}</p>
                </div>
                <div className="p-field">
                  <label className="p-d-block">No. HP</label>
                  <p>{dataDetailUser?.phone?.replace("+62", "0")}</p>
                </div>
                <div className="p-field">
                  <label className="p-d-block">Jumlah Project Berjalan</label>
                  <p>{dataDetailUser?.nProjectHandled || "0"} Project</p>
                </div>
                <div className="p-field status-dropdown">
                  <label className="p-d-block">Status</label>
                  <span
                    className={`badge wide ${
                      dataDetailUser?.status === "active"
                        ? "verified"
                        : "rejected"
                    }`}
                  >
                    {dataDetailUser?.status === "active" ? "Aktif" : "Nonaktif"}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="pm footer-form">
            <div className="form-add-project p-d-flex p-jc-end p-ai-center">
              <ButtonDefault
                onClick={() => confirmStatus()}
                disabled={
                  dataDetailUser?.nProjectHandled > 0 || loadingDetailUser
                }
                label={`${
                  dataDetailUser?.status !== "active"
                    ? "AKTIFKAN"
                    : "NONAKTIFKAN"
                } STATUS`}
                color={
                  dataDetailUser?.status !== "active" ? "primary" : "danger"
                }
                className="btn-wide ml-4"
                loading={loadingUpdateStatusUser}
              />
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSucsess.dialog}
        closeDialog={() => closeSuccess()}
        title={dialogSucsess.title}
        text={dialogSucsess.text}
      />

      <DialogAlert
        type={"warning"}
        visible={dialogWarning.dialog}
        closeDialog={() => closeDialogWarning()}
        title={dialogWarning.title}
        text={dialogWarning.text}
        actionDialog={dialogWarning.action}
        loading={dialogWarning.loading}
        actionText={dialogWarning.actionText}
      />
    </>
  );
};

export default PMDetailModule;
