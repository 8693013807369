import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import NumberBody from "../../../components/datatableBody/NumberBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import ButtonLink from "../../../components/button/ButtonLink";
import FilterPayment from "./Components/FilterPayment";
import { listWriterPaymentAction } from "../../../../redux/action/transactionAction";

const ListPaymentModule = (props) => {
  const { isFinance } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const {
    loadingListWriterPayment,
    dataListWriterPayment,
    totalPage,
  } = useSelector((state) => state.transaction);

  const pendapatanTemplate = (item) => {
    return (
      <NumberBody
        number={item?.totalBalance || 0}
        isPrice
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-payment/${
          item.id
        }`}
      />
    );
  };
  const withdrawTemplate = (item) => {
    return (
      <NumberBody
        number={item?.totalWithdraw || 0}
        isPrice
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-payment/${
          item.id
        }`}
      />
    );
  };
  const saldoTemplate = (item) => {
    return (
      <NumberBody
        number={item?.balance || 0}
        isPrice
        link={`/admin/${isFinance ? "finance" : "pm"}/detail-payment/${
          item.id
        }`}
      />
    );
  };

  const actionBodyTemplate = (item) => {
    return (
      <div className="action-table">
        <ButtonLink
          link={`/admin/${isFinance ? "finance" : "pm"}/detail-payment/${
            item.id
          }`}
          label="Lihat Detail"
          size="fit"
          className="text-capitalize"
        />
      </div>
    );
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      page: page + 1,
      perpage: 10,
    };
    dispatch(listWriterPaymentAction(params));

    history.push(
      `/admin/${
        isFinance ? "finance" : "pm"
      }/list-payment?search=${parsed?.search || ""}&page=${page + 1}`
    );
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              List Pembayaran Penulis
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterPayment parsed={parsed} isFinance />
          {loadingListWriterPayment ? (
            <TableLoader />
          ) : (
            <>
              {dataListWriterPayment?.length < 1 ||
              dataListWriterPayment === null ? (
                <DataEmpty />
              ) : (
                <DataTable value={dataListWriterPayment} className="no-padding">
                  <Column header="Nama Penulis" field="name"></Column>
                  <Column
                    header="Jumlah Pendapatan"
                    body={pendapatanTemplate}
                  ></Column>
                  <Column header="Withdraw" body={withdrawTemplate}></Column>
                  <Column header="Sisa Saldo" body={saldoTemplate}></Column>
                  <Column
                    bodyClassName="d-flex justify-content-end"
                    body={actionBodyTemplate}
                  ></Column>
                </DataTable>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListPaymentModule;
