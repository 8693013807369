import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AuthLayout from "../../layouts/authLayout";
import RegisterBenefitModule from "../../modules/Client/Register/RegisterBenefitModule";
import RegisterClientModule from "../../modules/Client/Register/RegisterClientModule";

import { getBusinessAction } from "../../../redux/action/utilsAction";
import { getCompaniesRegisterAction } from "../../../redux/action/clientAction";
import { titlePage } from "../../helpers/titleHead";

const ClientRegisterPage = () => {
  const dispatch = useDispatch();
  const { dataGetSettingSite } = useSelector((state) => state.landingpage);

  useEffect(() => {
    titlePage({
      title: "IRIS - Halaman Registrasi",
    });
    dispatch(getCompaniesRegisterAction());
    dispatch(getBusinessAction());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthLayout>
      <div className="auth-wrapper">
        <div className="container">
          <div className="p-grid">
            <RegisterBenefitModule />
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lg-0 p-md-0">
              <div className="auth-form auth-register p-d-flex p-jc-center">
                <div className="form-reg register">
                  <div className="p-text-center register-title">
                    <img src={dataGetSettingSite?.logo} alt="logo" />
                    <h2>Registrasi Akun Klien</h2>
                  </div>
                  <RegisterClientModule />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default ClientRegisterPage;
