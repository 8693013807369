import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";

import { loginEmailAction } from "../../../../../redux/action/authAction";
import ButtonLoader from "../../../../components/button/ButtonLoader";

const PasswordConfirmation = (props) => {
  const dispatch = useDispatch();
  const [passwordState, setPasswordState] = useState({
    value: "",
    error: null,
  });

  const {
    users,
    loadingEmailLogin,
    successEmailLogin,
    errEmailLogin,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    if (successEmailLogin || errEmailLogin) {
      setPasswordState({ ...passwordState, value: "" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successEmailLogin, errEmailLogin]);

  const renderFooter = () => {
    return (
      <div>
        <Button
          label="BATAL"
          onClick={props.closeDialog}
          className="p-button-secondary p-button-outlined p-button-rounded mr-3"
          disabled={loadingEmailLogin}
        />
        {loadingEmailLogin ? (
          <ButtonLoader />
        ) : (
          <Button
            label="KONFIRMASI"
            onClick={() => handleConfirmation()}
            className="p-button-primary p-button-rounded"
            autoFocus
            disabled={loadingEmailLogin}
          />
        )}
      </div>
    );
  };

  const handleConfirmation = () => {
    if (passwordState?.value?.length < 1) {
      setPasswordState({
        ...passwordState,
        error: "Password harus diisi",
      });
    } else {
      dispatch(loginEmailAction(users?.user?.email, passwordState.value));
    }
  };

  return (
    <div className="normal-dialog no-border-footer rounded">
      <Dialog
        header="Konfirmasi Kata Sandi"
        visible={props.visible}
        className="update-setting-dialog confirmation"
        footer={renderFooter()}
        onHide={props.closeDialog}
      >
        <div className="confirm-note">
          Silahkan konfirmasi kata sandi anda untuk melanjutkan
        </div>
        <div className="form-setting p-px-4">
          <div className="p-field">
            <InputText
              type="password"
              placeholder="Kata Sandi Anda"
              className="p-d-block"
              value={passwordState.value}
              onChange={(e) =>
                setPasswordState({ ...passwordState, value: e.target.value })
              }
            />
            {passwordState.error && (
              <smal className="p-error p-d-block p-text-right">
                {passwordState.error}
              </smal>
            )}
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default PasswordConfirmation;
