import {
  GET_LIST_INFO_REQUEST,
  POST_INFO_REQUEST,
  GET_DETAIL_INFO_REQUEST,
  RESET_STATE_ADMIN,
  UPDATE_INFO_REQUEST,
  DELETE_INFO_REQUEST,
} from "../constant/adminConstant";

export const getListInfoAction = (params) => {
  return {
    type: GET_LIST_INFO_REQUEST,
    params,
  };
};

export const postInfoAction = (data) => {
  return {
    type: POST_INFO_REQUEST,
    data,
  };
};

export const getDetailInfoAction = (id) => {
  return {
    type: GET_DETAIL_INFO_REQUEST,
    id,
  };
};

export const updateInfoAction = (id, data) => {
  return {
    type: UPDATE_INFO_REQUEST,
    id,
    data,
  };
};

export const deleteInfoAction = (id) => {
  return {
    type: DELETE_INFO_REQUEST,
    id,
  };
};

export const resetStateAdminAction = () => {
  return {
    type: RESET_STATE_ADMIN,
  };
};
