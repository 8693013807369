import { call, put } from "redux-saga/effects";
import {
  GET_SERVICE_FAILURE,
  GET_SERVICE_SUCCESS,
  GET_PRODUCT_FAILURE,
  GET_PRODUCT_SUCCESS,
  ADD_PRODUCT_FAILURE,
  ADD_PRODUCT_SUCCESS,
  UPDATE_PRODUCT_FAILURE,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  DELETE_PRODUCT_SUCCESS,
  GET_PRODUCT_DETAIL_FAILURE,
  GET_PRODUCT_DETAIL_SUCCESS,
} from "../constant/serviceConstant";
import {
  getService,
  createProduct,
  updateProduct,
  deleteProduct,
  getProductDetail,
} from "../service/firebase/service";
import { getProduct } from "../service/api/serviceApi";

export function* getProductSaga(action) {
  const { ok, data } = yield call(getProduct, action.params);
  if (ok) {
    const resultData = { data: data?.data, totalpage: data?.meta?.total };
    yield put({ type: GET_PRODUCT_SUCCESS, data: resultData });
  } else {
    yield put({ type: GET_PRODUCT_FAILURE, data });
  }
}

export function* getServiceSaga() {
  try {
    const res = yield call(getService);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_SERVICE_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_SERVICE_FAILURE, err });
  }
}

export function* createProductSaga(action) {
  try {
    yield call(createProduct, action.data);
    yield put({ type: ADD_PRODUCT_SUCCESS });
  } catch (err) {
    yield put({ type: ADD_PRODUCT_FAILURE, err });
  }
}

export function* updateProductSaga(action) {
  try {
    yield call(updateProduct, action.id, action.data);
    yield put({ type: UPDATE_PRODUCT_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_PRODUCT_FAILURE, err });
  }
}

export function* deleteProductSaga(action) {
  try {
    yield call(deleteProduct, action.id);
    yield put({ type: DELETE_PRODUCT_SUCCESS });
  } catch (err) {
    yield put({ type: DELETE_PRODUCT_FAILURE, err });
  }
}

export function* getDetailProductSaga(action) {
  try {
    const snapshot = yield call(getProductDetail, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_PRODUCT_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_PRODUCT_DETAIL_FAILURE, err });
  }
}
