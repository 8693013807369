import React from "react";
import { Link } from "react-router-dom";

const ButtonLink = (props) => {
  // :::::::::::: Note style
  // Type  : rounded, bordered, rounded-bordered
  // Color : danger, grey

  return (
    <Link
      className={`button-style ${props.type} ${props.color} ${props.size} ${props.className}`}
      to={props.link}
    >
      {props.label}
    </Link>
  );
};

export default ButtonLink;
