import { call, put } from "redux-saga/effects";
import {
  INVITE_USER_SUCCESS,
  INVITE_USER_FAILURE,
  RE_INVITE_USER_SUCCESS,
  RE_INVITE_USER_FAILURE,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_DETAIL_USER_FAILURE,
  GET_DETAIL_USER_SUCCESS,
  DELETE_USER_FAILURE,
  DELETE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  UPDATE_USER_SUCCESS,
  UPLOAD_PORTFOLIO_IMAGE_SUCCESS,
  UPLOAD_PORTFOLIO_IMAGE_FAILURE,
  GET_PORTFOLIO_FAILURE,
  GET_PORTFOLIO_SUCCESS,
  UPDATE_STATUS_APPLICANT_SUCCESS,
  UPDATE_STATUS_APPLICANT_FAILURE,
  POST_PM_SUCCESS,
  POST_PM_FAILURE,
  UPDATE_STATUS_USER_SUCCESS,
  UPDATE_STATUS_USER_FAILURE,
  UPLOAD_COMPANY_PROFILE_SUCCESS,
  UPLOAD_COMPANY_PROFILE_FAILURE,
  POST_WRITER_BANK_SUCCESS,
  POST_WRITER_BANK_FAILURE,
  GET_WRITER_BANK_SUCCESS,
  GET_WRITER_BANK_FAILURE,
  UPDATE_WRITER_BANK_SUCCESS,
  UPDATE_WRITER_BANK_FAILURE,
} from "../constant/userConstant";

import {
  inviteUsers,
  reInviteUsers,
  getUsers,
  deleteUsers,
  getPortfolioFile,
  editStatusApplicant,
  addPm,
  editStatusUser,
  postWriterBank,
  getWriterBank,
  updateWriterBank,
} from "../service/api/userApi";
import { getToken, updateUser, getUserByUid } from "../service/firebase/auth";
import { singleUpload } from "../service/firebase/storage";

export function* inviteUserSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(inviteUsers, token, action.data);
  if (ok) {
    yield put({ type: INVITE_USER_SUCCESS });
  } else {
    yield put({ type: INVITE_USER_FAILURE, data });
  }
}

export function* reInviteUserSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(reInviteUsers, token, action.id);
  if (ok) {
    yield put({ type: RE_INVITE_USER_SUCCESS });
  } else {
    yield put({ type: RE_INVITE_USER_FAILURE, data });
  }
}

export function* getUsersSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getUsers, token, action.params);
  if (ok) {
    yield put({ type: GET_USER_SUCCESS, data });
  } else {
    yield put({ type: GET_USER_FAILURE, data });
  }
}

export function* deleteUserSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(deleteUsers, token, action.id);
  if (ok) {
    yield put({ type: DELETE_USER_SUCCESS });
  } else {
    yield put({ type: DELETE_USER_FAILURE, data });
  }
}

export function* getDetailUserSaga(action) {
  try {
    const snapshot = yield call(getUserByUid, action.id);
    const data = snapshot.data();
    yield put({
      type: GET_DETAIL_USER_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: GET_DETAIL_USER_FAILURE, err });
  }
}

export function* updateUserSaga(action) {
  try {
    yield call(updateUser, action.id, action.data);
    yield put({ type: UPDATE_USER_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_USER_FAILURE, err });
  }
}

export function* uploadPortfolioSaga(action) {
  try {
    const data = yield call(singleUpload, action.file, action.path);
    yield put({ type: UPLOAD_PORTFOLIO_IMAGE_SUCCESS, data });
  } catch (err) {
    yield put({ type: UPLOAD_PORTFOLIO_IMAGE_FAILURE, err });
  }
}

export function* uploadCompanyProfileSaga(action) {
  try {
    const data = yield call(singleUpload, action.file, action.path);
    yield put({ type: UPLOAD_COMPANY_PROFILE_SUCCESS, data });
  } catch (err) {
    yield put({ type: UPLOAD_COMPANY_PROFILE_FAILURE, err });
  }
}

export function* getPortfolioFileSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    getPortfolioFile,
    token,
    action.uid,
    action.portfolioId
  );
  if (ok) {
    yield put({ type: GET_PORTFOLIO_SUCCESS, data });
  } else {
    yield put({ type: GET_PORTFOLIO_FAILURE, data });
  }
}

export function* updateStatusApplicantSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    editStatusApplicant,
    token,
    action.uid,
    action.data
  );
  if (ok) {
    yield put({ type: UPDATE_STATUS_APPLICANT_SUCCESS });
  } else {
    yield put({ type: UPDATE_STATUS_APPLICANT_FAILURE, data });
  }
}

export function* postPmSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(addPm, token, action.data);
  if (ok) {
    yield put({ type: POST_PM_SUCCESS });
  } else {
    yield put({ type: POST_PM_FAILURE, data });
  }
}

export function* updateStatusUserSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    editStatusUser,
    token,
    action.uid,
    action.data
  );
  if (ok) {
    yield put({ type: UPDATE_STATUS_USER_SUCCESS });
  } else {
    yield put({ type: UPDATE_STATUS_USER_FAILURE, data });
  }
}

export function* postWriterBankSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(postWriterBank, token, action.data);
  if (ok) {
    yield put({ type: POST_WRITER_BANK_SUCCESS });
  } else {
    yield put({ type: POST_WRITER_BANK_FAILURE, data });
  }
}

export function* getWriterBankSaga() {
  const token = yield call(getToken);
  const { ok, data } = yield call(getWriterBank, token);
  if (ok) {
    yield put({ type: GET_WRITER_BANK_SUCCESS, data });
  } else {
    yield put({ type: GET_WRITER_BANK_FAILURE, data });
  }
}

export function* updateWriterBankSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(updateWriterBank, token, action.data);
  if (ok) {
    yield put({ type: UPDATE_WRITER_BANK_SUCCESS });
  } else {
    yield put({ type: UPDATE_WRITER_BANK_FAILURE, data });
  }
}
