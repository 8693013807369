import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import ButtonDefault from "../../../../components/button/ButtonDefault";
import { updateFinanceOrderAction } from "../../../../../redux/action/financeAction";

const DeclinePopup = (props) => {
  const dispatch = useDispatch();
  const [displayBasic, setDisplayBasic] = useState(false);
  const [value1, setValue1] = useState("");

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };
  const onClick = (name) => {
    dialogFuncMap[`${name}`](true);
  };
  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
  };

  const rejectInvoice = () => {
    const data = {
      note: value1,
      status: "rejected",
    };
    dispatch(updateFinanceOrderAction(props?.invoiceId, data));
    onHide("displayBasic");
  };

  return (
    <div className="normal-dialog d-inline-block">
      <ButtonDefault
        label="Tolak"
        onClick={() => onClick("displayBasic")}
        className="p-mr-3 p-button sm-radius"
        type="bordered"
        color="danger"
      />
      <Dialog
        header="Tolak Invoice"
        visible={displayBasic}
        onHide={() => onHide("displayBasic")}
      >
        <div className="decline-form">
          <p className="p-mt-3">Masukkan alasan untuk menolak invoice ini</p>
          <InputTextarea
            header="Tolak Invoice"
            value={value1}
            onChange={(e) => setValue1(e.target.value)}
            rows={7}
            cols={30}
          />
          <div className="p-d-flex p-jc-end p-align-center">
            <ButtonDefault
              label="Batal"
              className="p-mr-3 p-mt-3"
              type="bordered"
              color="grey"
              onClick={() => onHide("displayBasic")}
            />
            <ButtonDefault
              label="Tolak"
              className="sm-radius p-button-primary text-uppercase p-d-inline-block p-mt-3"
              onClick={() => rejectInvoice()}
            />
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default DeclinePopup;
