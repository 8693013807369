import { call, put } from "redux-saga/effects";
import {
  UPLOAD_SINGLE_IMAGE_SUCCESS,
  UPLOAD_SINGLE_IMAGE_FAILURE,
  DELETE_SINGLE_IMAGE_FAILURE,
  DELETE_SINGLE_IMAGE_SUCCESS,
  GET_AGE_SUCCESS,
  GET_AGE_FAILURE,
  GET_GENDER_SUCCESS,
  GET_GENDER_FAILURE,
  GET_SES_SUCCESS,
  GET_SES_FAILURE,
  GET_STAKEHOLDER_SUCCESS,
  GET_STAKEHOLDER_FAILURE,
  GET_CATEGORY_RELEASE_SUCCESS,
  GET_CATEGORY_RELEASE_FAILURE,
  GET_DATA_PROVINCES_FAILURE,
  GET_DATA_PROVINCES_SUCCESS,
  GET_DATA_DISTRICT_FAILURE,
  GET_DATA_DISTRICT_SUCCESS,
  GET_NOTIFICATIONS_SUCCESS,
  GET_NOTIFICATIONS_FAILURE,
  UPDATE_NOTIFICATION_FAILURE,
  UPDATE_NOTIFICATION_SUCCESS,
  GET_HELP_CATEGORY_FAILURE,
  GET_HELP_CATEGORY_SUCCESS,
  ANNOUNCEMENTS_FAILURE,
  ANNOUNCEMENTS_SUCCESS,
  UPLOAD_BRIEF_SUCCESS,
  UPLOAD_BRIEF_FAILURE,
  GET_PAYMENT_ACCOUNT_SUCCESS,
  GET_PAYMENT_ACCOUNT_FAILURE,
  PAYMENT_ACCOUNT_DETAIL_SUCCESS,
  PAYMENT_ACCOUNT_DETAIL_FAILURE,
  GET_BUSINESS_SUCCESS,
  GET_BUSINESS_FAILURE,
} from "../constant/utilsConstant";

import { singleUpload, singleDelete } from "../service/firebase/storage";
import {
  getAge,
  getGender,
  getSes,
  getStakeHolder,
  getReleaseCategory,
  getProvinces,
  getDistricts,
  getNotifications,
  getNotificationsPM,
  updateNotifications,
  announcements,
  paymentAccount,
  paymentAccountDetail,
  getHelpCategory,
} from "../service/firebase/utils";

import { companyBusiness } from "../service/firebase/landingpage";

export function* deleteImageSaga(action) {
  try {
    yield call(singleDelete, action.url);
    yield put({ type: DELETE_SINGLE_IMAGE_SUCCESS, url: action.url });
  } catch (err) {
    yield put({ type: DELETE_SINGLE_IMAGE_FAILURE, err });
  }
}

export function* uploadImageSaga(action) {
  try {
    const data = yield call(singleUpload, action.file, action.path);
    yield put({ type: UPLOAD_SINGLE_IMAGE_SUCCESS, data });
  } catch (err) {
    yield put({ type: UPLOAD_SINGLE_IMAGE_FAILURE, err });
  }
}

export function* getAgeSaga() {
  try {
    const res = yield call(getAge);
    let data = res._value;
    data = data.replace(/'/g, '"');
    data = JSON.parse(data);

    yield put({ type: GET_AGE_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_AGE_FAILURE, err });
  }
}

export function* getGenderSaga() {
  try {
    const res = yield call(getGender);
    let data = res._value;
    data = data.replace(/'/g, '"');
    data = JSON.parse(data);

    yield put({ type: GET_GENDER_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_GENDER_FAILURE, err });
  }
}

export function* getSesSaga() {
  try {
    const res = yield call(getSes);
    let data = res._value;
    data = data.replace(/'/g, '"');
    data = JSON.parse(data);

    yield put({ type: GET_SES_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_SES_FAILURE, err });
  }
}

export function* getHelpCategorySaga() {
  try {
    const res = yield call(getHelpCategory);
    let rawData = res._value;
    rawData = rawData.replace(/'/g, '"');
    rawData = JSON.parse(rawData);
    let data = rawData.map((item) => {
      return { name: item, code: item };
    });
    yield put({ type: GET_HELP_CATEGORY_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_HELP_CATEGORY_FAILURE, err });
  }
}

export function* getCategoryReleaseSaga() {
  try {
    const res = yield call(getReleaseCategory);
    let data = res._value;
    data = data.replace(/'/g, '"');
    data = JSON.parse(data);

    yield put({ type: GET_CATEGORY_RELEASE_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_CATEGORY_RELEASE_FAILURE, err });
  }
}

export function* getStakeholderSaga() {
  try {
    const res = yield call(getStakeHolder);
    let data = res._value;
    data = data.replace(/'/g, '"');
    data = JSON.parse(data);

    yield put({ type: GET_STAKEHOLDER_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_STAKEHOLDER_FAILURE, err });
  }
}

export function* getProvincesSaga() {
  try {
    const res = yield call(getProvinces);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_DATA_PROVINCES_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_DATA_PROVINCES_FAILURE, err });
  }
}

export function* getDistrictSaga(action) {
  try {
    const res = yield call(getDistricts, action.id);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_DATA_DISTRICT_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_DATA_DISTRICT_FAILURE, err });
  }
}

export function* getNotificationSaga(action) {
  try {
    let res;
    if (action.userType === "writer") {
      res = yield call(getNotifications, action.uid);
    } else {
      res = yield call(getNotificationsPM, action.uid, action.userType);
    }

    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_NOTIFICATIONS_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_NOTIFICATIONS_FAILURE, err });
  }
}

export function* updateNotificationSaga(action) {
  try {
    yield call(updateNotifications, action.id);
    yield put({ type: UPDATE_NOTIFICATION_SUCCESS });
  } catch (err) {
    yield put({ type: UPDATE_NOTIFICATION_FAILURE, err });
  }
}

export function* getAnnouncementsSaga() {
  try {
    const res = yield call(announcements);
    let result = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      result.push(item);
    }

    yield put({ type: ANNOUNCEMENTS_SUCCESS, result });
  } catch (err) {
    yield put({ type: ANNOUNCEMENTS_FAILURE, err });
  }
}

export function* uploadBriefSaga(action) {
  try {
    const data = yield call(singleUpload, action.file, action.path);
    yield put({ type: UPLOAD_BRIEF_SUCCESS, data });
  } catch (err) {
    yield put({ type: UPLOAD_BRIEF_FAILURE, err });
  }
}

export function* getPaymentAccountSaga() {
  try {
    const res = yield call(paymentAccount);
    let result = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      result.push(item);
    }
    yield put({ type: GET_PAYMENT_ACCOUNT_SUCCESS, result });
  } catch (err) {
    yield put({ type: GET_PAYMENT_ACCOUNT_FAILURE, err });
  }
}

export function* getPaymentAccountDetailSaga(action) {
  try {
    const snapshot = yield call(paymentAccountDetail, action.id);
    const data = snapshot.data();
    yield put({
      type: PAYMENT_ACCOUNT_DETAIL_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: PAYMENT_ACCOUNT_DETAIL_FAILURE, err });
  }
}

export function* getBusinessSaga() {
  try {
    const snapshot = yield call(companyBusiness);
    const data = snapshot.data();
    yield put({ type: GET_BUSINESS_SUCCESS, data: data.businessField });
  } catch (err) {
    yield put({ type: GET_BUSINESS_FAILURE, err });
  }
}
