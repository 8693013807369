/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import DeleteIcon from "../../../assets/img/trash.svg";

import TitleProjectBody from "../../../components/datatableBody/TitleProjectBody";
import DateBody from "../../../components/datatableBody/DateBody";
import NumberBody from "../../../components/datatableBody/NumberBody";
import AdminPaginate from "../../../components/paging/pagination";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";

import {
  getListArticlesPmAction,
  deleteArticleProjectAction,
  resetStateProjectAction,
} from "../../../../redux/action/projectAction";
import ArticleTitleBody from "../../../components/datatableBody/ArticleTitleBody";
import FilterListArticles from "./Components/FilterListArticles";
import TextBody from "../../../components/datatableBody/TextBody";
import StatusBadge from "../../../components/badge/StatusBadge";

const ListArticlePMModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dataTable, setDataTable] = useState([]);

  const [dialogAlert, setDialogAlert] = useState({
    dialog: false,
    type: null,
    title: null,
    text: null,
  });

  const {
    totalPage,
    dataListArticles,
    loadingListArticles,
    successDeleteArticleProject,
    projectParams,
  } = useSelector((state) => state.project);

  useEffect(() => {
    if (dataListArticles) {
      setDataTable(dataListArticles);
    }
  }, [dataListArticles]);

  useEffect(() => {
    if (successDeleteArticleProject) {
      setDialogAlert({
        type: "success",
        dialog: true,
        title: `Berhasil Hapus Artikel`,
        text: `Artikel berhasil dihapus`,
      });
    }
  }, [successDeleteArticleProject]);

  const deadlineTemplate = (item) => {
    return (
      <DateBody
        date={item?.deadline}
        link={`/admin/pm/detail-project/${item?.projectId}/preview?writer=${item?._id}`}
      />
    );
  };
  const updatedAtTemplate = (item) => {
    return (
      <DateBody
        date={item?.updatedAt}
        link={`/admin/pm/detail-project/${item?.projectId}/preview?writer=${item?._id}`}
      />
    );
  };

  const projectNameTemplate = (item) => {
    return (
      <TitleProjectBody
        name={item?.jobNumber}
        owner={item?.clientName}
        link={`/admin/pm/detail-project/${item?.projectId}/preview?writer=${item?._id}`}
      />
    );
  };
  const articleTitleTemplate = (item) => {
    return (
      <ArticleTitleBody
        title={item?.articleTitle || "-"}
        link={`/admin/pm/detail-project/${item?.projectId}/preview?writer=${item?._id}`}
      />
    );
  };
  const writerBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.userData?.name}
        link={`/admin/pm/detail-project/${item?.projectId}/preview?writer=${item?._id}`}
      />
    );
  };
  const priceBodyTemplate = (item) => {
    return (
      <NumberBody
        number={item?.price || 0}
        isPrice
        link={`/admin/pm/detail-project/${item?.projectId}/preview?writer=${item?._id}`}
      />
    );
  };

  const statusProjectBodyTemplate = (item) => {
    return (
      <StatusBadge
        status={item?.status}
        link={`/admin/pm/detail-project/${item?.projectId}/preview?writer=${item?._id}`}
      />
    );
  };

  const deleteBodyTemplate = (item) => {
    return (
      <div className="action-table p-jc-center w-100">
        <button onClick={() => deleteArticle(item)} className="btn btn-action">
          <img src={DeleteIcon} alt="" />
        </button>
      </div>
    );
  };

  const deleteArticle = (data) => {
    const deleteAction = () => {
      dispatch(deleteArticleProjectAction(data?._id));
    };
    setDialogAlert({
      type: "warning",
      dialog: true,
      title: `Yakin Ingin Hapus Tulisan?`,
      text: `Tulisan yang sudah dihapus tidak dapat di batalkan`,
      action: () => deleteAction(),
      actionText: "hapus",
      cancelText: "batal",
    });
  };

  const closeSuccess = () => {
    dispatch(resetStateProjectAction());
    setDialogAlert({ dialog: false, type: null, title: null, text: null });
    if (successDeleteArticleProject) {
      setTimeout(() => {
        dispatch(getListArticlesPmAction(projectParams));
      }, 1500);
    }
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed?.search || "",
      category: parsed?.category || "",
      type: parsed?.type || "",
      orderField: "updatedAt",
      orderDir: parsed?.order ? parsed?.order : "desc",
      page: page + 1,
      perpage: 10,
    };
    dispatch(getListArticlesPmAction(params));

    history.push(
      `/admin/pm/list-articles?search=${parsed?.search ||
        ""}&order=${parsed?.order || ""}&page=${page + 1}`
    );
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Tulisan
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterListArticles parsed={parsed} />
          {loadingListArticles ? (
            <TableLoader />
          ) : (
            <>
              {dataTable?.length < 1 || dataTable === null ? (
                <DataEmpty />
              ) : (
                <DataTable value={dataTable} className="no-padding">
                  <Column
                    header="ID Project"
                    body={projectNameTemplate}
                    headerClassName="list-article-project"
                  />
                  <Column
                    header="Judul Tulisan"
                    body={articleTitleTemplate}
                    headerClassName="article-name"
                  />
                  <Column header="Nama Penulis" body={writerBodyTemplate} />
                  <Column
                    header="Deadline"
                    headerClassName="deadline-list"
                    body={deadlineTemplate}
                  />
                  <Column
                    header="Terakhir Diperbarui"
                    headerClassName="updated-at"
                    body={updatedAtTemplate}
                  />
                  <Column
                    header="Harga"
                    headerClassName="price"
                    body={priceBodyTemplate}
                  />
                  <Column
                    header="Status"
                    headerClassName="price"
                    body={statusProjectBodyTemplate}
                  />
                  <Column body={deleteBodyTemplate} />
                </DataTable>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={dialogAlert?.type}
        visible={dialogAlert?.dialog}
        closeDialog={() => closeSuccess()}
        title={dialogAlert?.title}
        text={dialogAlert?.text}
        actionDialog={dialogAlert?.action}
        actionText={dialogAlert?.actionText}
        cancelText={dialogAlert?.cancelText}
      />
    </>
  );
};

export default ListArticlePMModule;
