import React from "react";
import ImageCompress from "quill-image-compress";
import ReactQuill, { Quill } from "react-quill";
import "react-quill/dist/quill.snow.css";

Quill.register("modules/imageCompress", ImageCompress);

const ContentSection = (props) => {
  const toolbarOptions = [
    ["bold", "italic", "underline"],
    [{ header: [1, 2, 3, 4, 5, false] }],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image"],
  ];

  const imagesSize = {
    quality: 0.5,
    maxWidth: 500,
    maxHeight: 500,
    imageType: "image/jpeg",
    debug: true,
  };

  const modules = {
    toolbar: toolbarOptions,
    imageCompress: imagesSize,
  };

  return (
    <div className="form-write">
      <ReactQuill
        className="textarea-content"
        value={props.contentValue}
        onChange={props.changeContent}
        modules={modules}
        placeholder="Mulai Menulis"
      />
    </div>
  );
};
export default ContentSection;
