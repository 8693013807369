/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import IconIg from "../../assets/img/icon-ig.png";
import IconFb from "../../assets/img/icon-fb.png";
import IconLinkedIn from "../../assets/img/linked_in.svg";
import IconTwitter from "../../assets/img/icon-twitter.png";
import { Link } from "react-router-dom";

import LogoLoader from "../loading/logoLoader";

const Footer = (props) => {
  const { loading, dataSite } = props;

  return (
    <div>
      <div className="footer-menu p-d-flex p-justify-center">
        <div className="p-grid p-justify-center">
          <div className="col-lg-4 col-md-4 col-sm-12">
            {loading ? (
              <LogoLoader />
            ) : (
              <Link className="footer-logo" to="/">
                <img src={dataSite?.logoFooter} alt="logo" />
              </Link>
            )}
            <address>
              <p>{dataSite?.companyAddress}</p>
            </address>
            <div className="contact-text">
              <p>{`${dataSite?.companyEmail} | ${dataSite?.companyPhone}`}</p>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12">
            <div className="static-page-menu mt-3">
              <ul>
                <li>
                  <Link to="/about-us">About Us</Link>
                </li>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/terms">Terms &amp; Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 footer-section">
            <div className="social-media">
              <h4>Find Us</h4>
              <ul>
                <li>
                  <a href={dataSite?.instagramUrl} target="_blank">
                    <img src={IconIg} alt="instagram" />
                  </a>
                </li>
                <li>
                  <a href={dataSite?.facebookUrl} target="_blank">
                    <img src={IconFb} alt="facebook" />
                  </a>
                </li>
                <li>
                  <a href={dataSite?.linkedinUrl} target="_blank">
                    <img src={IconLinkedIn} alt="linkedIn" />
                  </a>
                </li>
                <li>
                  <a href={dataSite?.twitterUrl} target="_blank">
                    <img src={IconTwitter} alt="twitter" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-landing p-d-flex p-justify-center">
        <div className="p-grid">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p>&copy; {new Date().getFullYear()} Copyright IRIS</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
