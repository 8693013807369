/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-restricted-imports */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import DataEmpty from "../../../assets/img/data-empty.svg";
import BoxLoader from "../../../components/loading/boxLoader";

import Dropdown from "react-bootstrap/Dropdown";
import { RadioButton } from "primereact/radiobutton";
import { Button } from "primereact/button";

import { getLastLocation } from "../../../../_metronic/_helpers/RouterHelpers";
import firebase from "../../../../redux/service/firebase/firebase";

const WriterListProject = (props) => {
  const history = useHistory();
  const firestore = firebase.firestore();
  const [projectList, setProjectList] = useState([]);
  const [myProject, setMyProject] = useState([]);
  const [filter, setFilter] = useState({ category: "all", status: "all" });
  const [openFilter, setOpenFilter] = useState(false);

  const { loadingGetMyProject } = useSelector((state) => state.project);

  const { users } = useSelector((state) => state.auth);

  const getListProject = async () => {
    return await firestore
      .collection("writerProjects")
      .where("writerId", "==", users?.uid)
      .orderBy("updatedAt", "desc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            id: doc.id,
          })
        );
        setProjectList(temp);
      });
  };

  useEffect(() => {
    if (users?.uid) {
      getListProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users?.uid]);

  useEffect(() => {
    if (filter?.category === "all" && filter?.status === "all") {
      setMyProject(projectList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectList, filter.category, filter.status]);

  const goToChat = (data) => {
    history.push(`/chat?project=${data.project}&writer=${data.writer}`);
  };

  const changeFilter = () => {
    if (filter?.category === "all" && filter?.status === "all") {
      setMyProject(projectList);
    } else if (filter?.category === "all" && filter?.status !== "all") {
      setMyProject(
        projectList?.filter((project) => project.status === filter?.status)
      );
    } else if (filter?.category !== "all" && filter?.status === "all") {
      setMyProject(
        projectList?.filter((project) => project.category === filter?.category)
      );
    } else {
      setMyProject(
        projectList?.filter(
          (project) =>
            project.category === filter?.category &&
            project.status === filter?.status
        )
      );
    }
    setOpenFilter(false);
  };

  return (
    <div className="box-info chat">
      <div className="header-box umkm-chat px-5 p-d-flex p-ai-center">
        <a onClick={() => history.push(getLastLocation())} className="btn-back">
          <span className="icon-back"></span>
        </a>
        <h3 className="p-mb-0 p-ml-2">Inbox</h3>
        <Dropdown
          className="left-dropdown"
          onToggle={() => setOpenFilter(!openFilter)}
          show={openFilter}
        >
          <Dropdown.Toggle id="dropdown-filter" className="dropdown-filter">
            <span className="icon-filter"></span>
          </Dropdown.Toggle>

          <Dropdown.Menu className="filter-dropdown">
            <h5>Kategori</h5>
            <div className="p-field-radiobutton ">
              <RadioButton
                inputId="categoryAll"
                value="all"
                checked={filter?.category === "all"}
                onChange={(e) =>
                  setFilter({ ...filter, category: e.target.value })
                }
              />
              <label htmlFor="categoryAll" className="p-mb-0">
                Semua
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="categoryPrivate"
                value="private"
                checked={filter?.category === "private"}
                onChange={(e) =>
                  setFilter({ ...filter, category: e.target.value })
                }
              />
              <label htmlFor="categoryPrivate" className="p-mb-0">
                Private
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="categoryPublic"
                value="public"
                checked={filter?.category === "public"}
                onChange={(e) =>
                  setFilter({ ...filter, category: e.target.value })
                }
              />
              <label htmlFor="categoryPublic" className="p-mb-0">
                Public
              </label>
            </div>
            <h5>Status Project</h5>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="statusAll"
                value="all"
                checked={filter?.status === "all"}
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
              />
              <label htmlFor="statusAll" className="p-mb-0">
                Semua
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="statusOnGoing"
                value="ongoing"
                checked={filter?.status === "ongoing"}
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
              />
              <label htmlFor="statusOnGoing" className="p-mb-0">
                Ongoing
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="statusSent"
                value="sent"
                checked={filter?.status === "sent"}
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
              />
              <label htmlFor="statusSent" className="p-mb-0">
                Sent
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="statusRevision"
                value="revision"
                checked={filter?.status === "revision"}
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
              />
              <label htmlFor="statusRevision" className="p-mb-0">
                Revision
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="statusWinner"
                value="winner"
                checked={filter?.status === "winner"}
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
              />
              <label htmlFor="statusWinner" className="p-mb-0">
                Approved
              </label>
            </div>
            <div className="p-field-radiobutton">
              <RadioButton
                inputId="statusReject"
                value="reject"
                checked={filter?.status === "reject"}
                onChange={(e) =>
                  setFilter({ ...filter, status: e.target.value })
                }
              />
              <label htmlFor="statusReject" className="p-mb-0">
                Reject
              </label>
            </div>
            <Button
              label="Terapkan"
              className="p-button-rounded p-button-primary"
              onClick={() => changeFilter()}
            />
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <div className="box-inner-left">
        <div className="inboxlist">
          {loadingGetMyProject ? (
            <>
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
              <BoxLoader />
            </>
          ) : (
            <div className="card">
              {myProject?.length > 0 ? (
                myProject?.map((item) => (
                  <div
                    className={`inbox-item ${
                      props.projectIdUrl === item?.projectId ? "active" : ""
                    }`}
                    key={item?.id}
                    onClick={() =>
                      goToChat({ project: item?.projectId, writer: item?.id })
                    }
                  >
                    <div className="pict-container">
                      <img
                        src={item?.thumbnail}
                        alt=""
                        className="image-profile"
                      />
                    </div>
                    <div className="inbox-item-detail">
                      <h5 className="p-mb-2">
                        {item?.title}{" "}
                        {item?.chat?.isRead && item?.chat?.userType === "pm" ? (
                          <span className="dot-inbox" />
                        ) : (
                          <div />
                        )}
                      </h5>
                      {typeof item?.status !== "object" ? (
                        <span
                          className={`badge ${item?.status} font-weight-semibold md wide`}
                        >
                          {item?.status === "winner" ||
                          item?.status === "published"
                            ? "Approved"
                            : item?.status}
                        </span>
                      ) : (
                        <span
                          className={`badge ${item?.status?.status} font-weight-semibold md wide`}
                        >
                          {item?.status?.status === "winner" ||
                          item?.status.status === "published"
                            ? "Approved"
                            : item?.status?.status}
                        </span>
                      )}
                      <span className="badge private font-weight-semibold md p-ml-2 wide">
                        {item?.category}
                      </span>
                    </div>
                  </div>
                ))
              ) : (
                <div className="empty-state">
                  <div>
                    <img src={DataEmpty} alt="empty" />
                    <p>Data Tidak Ditemukan</p>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WriterListProject;
