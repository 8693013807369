import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import DetailInfoModule from "../../../modules/Admin/Information/DetailInfoModule";

import { getDetailInfoAction } from "../../../../redux/action/adminAction";

const DetailInfoPage = () => {
  const dispatch = useDispatch();
  const queryParams = useParams();

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Informasi Terbaru",
    });
    dispatch(getDetailInfoAction(queryParams?.articleId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Informasi Terbaru" menu="info">
      <DetailInfoModule articleId={queryParams?.articleId} />
    </DashboardLayout>
  );
};

export default DetailInfoPage;
