import {
  GET_ORDER_DETAIL_REQUEST,
  GET_ORDER_REQUEST,
  POST_ORDER_CLIENT_REQUEST,
  RESET_ORDER_STATE_REQUEST,
  ORDER_CLIENT_DETAIL_REQUEST,
  UPDATE_ORDER_REQUEST,
  POST_ORDER_BRIEF_REQUEST,
  GET_PROJECT_CLIENT_REQUEST,
  POST_SAVE_CLIENT_REQUEST,
  GET_SAVE_CLIENT_REQUEST,
  GET_ORDER_SAVE_DETAIL_REQUEST,
  ORDER_CLIENT_SAVE_DETAIL_REQUEST,
  RESET_SAVE_STATE_REQUEST,
} from "../constant/orderConstant";

export const getOrderAction = (params) => {
  return {
    type: GET_ORDER_REQUEST,
    params,
  };
};

export const getOrderDetailAction = (id) => {
  return {
    type: GET_ORDER_DETAIL_REQUEST,
    id,
  };
};

export const getOrderSaveDetailAction = (id) => {
  return {
    type: GET_ORDER_SAVE_DETAIL_REQUEST,
    id,
  };
};

export const postOrderClientAction = (data) => {
  return {
    type: POST_ORDER_CLIENT_REQUEST,
    data,
  };
};

export const resetOrderStateAction = () => {
  return {
    type: RESET_ORDER_STATE_REQUEST,
  };
};

export const resetSaveStateAction = () => {
  return {
    type: RESET_SAVE_STATE_REQUEST,
  };
};

export const orderClientDetailAction = (id) => {
  return {
    type: ORDER_CLIENT_DETAIL_REQUEST,
    id,
  };
};

export const orderClientSaveDetailAction = (id) => {
  return {
    type: ORDER_CLIENT_SAVE_DETAIL_REQUEST,
    id,
  };
};

export const updateOrderAction = (id, data) => {
  return {
    type: UPDATE_ORDER_REQUEST,
    id,
    data,
  };
};

export const postOrderBriefAction = (id, data) => {
  return {
    type: POST_ORDER_BRIEF_REQUEST,
    id,
    data,
  };
};

export const postSaveBriefAction = (id, data) => {
  return {
    type: POST_SAVE_CLIENT_REQUEST,
    id,
    data,
  };
};

export const getProjectClientAction = (params) => {
  return {
    type: GET_PROJECT_CLIENT_REQUEST,
    params,
  };
};

export const getSaveProjectClientAction = (params) => {
  return {
    type: GET_SAVE_CLIENT_REQUEST,
    params,
  };
};
