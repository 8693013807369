import React, { useEffect } from "react";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
import AddPMModule from "../../../modules/Admin/Superadmin/PMManagement/AddPMModule";

const AddPMPage = () => {
  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen PM",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Project Manager" menu="pm">
      <AddPMModule />
    </DashboardLayout>
  );
};

export default AddPMPage;
