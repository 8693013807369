/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";

export function SubHeader({ title }) {
  return (
    <div className="subheader client py-2 py-lg-4">
      <div className="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
        <div className="d-flex align-items-center flex-wrap mr-1">
          <div className="d-flex align-items-baseline mr-5">
            <h5 className="text-dark font-weight-bold my-2 mx-5 col-sm-0">{title}</h5>
          </div>
        </div>
      </div>
    </div>
  );
}
