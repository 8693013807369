/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import OtpInput from "react-otp-input";

import ButtonLoader from "../../../../components/button/ButtonLoader";
import DialogAlert from "../../../../components/dialog/DialogAlert";
import OTPConfirmation from "./OTPConfirmation";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../../helpers/phoneNumber";
import {
  checkUpdatePhoneAction,
  updatePhoneAction,
  checkPhoneAction,
  editWriterProfileAction,
  resetStateAuthAction,
  logoutAction,
} from "../../../../../redux/action/authAction";

function UpdatePhone() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [phoneState, setPhoneState] = useState({
    value: "",
    error: null,
  });
  const [openOtp, setOpenOtp] = useState(false);
  const [otpState, setOtpState] = useState({
    value: "",
    error: null,
  });
  const [layout, setLayout] = useState("otp");
  const [timeLeft, setTimeLeft] = useState(61);
  const [dialogAlert, setDialogAlert] = useState({
    type: null,
    text: null,
    title: null,
    dialog: false,
  });

  const {
    users,
    dataCheckPhone,
    loadingCheckPhone,
    loadingCheckUpdatePhone,
    successCheckUpdatePhone,
    verificationPhoneNumber,
    loadingUpdatePhone,
    successUpdatePhone,
    errUpdatePhone,
  } = useSelector((state) => state.auth);

  useEffect(() => {
    setOpenOtp(true);
  }, []);

  useEffect(() => {
    if (
      dataCheckPhone !== null &&
      !dataCheckPhone?.exists &&
      phoneState?.value > 0
    ) {
      dispatch(
        checkUpdatePhoneAction(
          window.recaptchaVerifier,
          formattedPhoneNumber(phoneState.value)
        )
      );
    }
    if (
      dataCheckPhone !== null &&
      dataCheckPhone?.exists &&
      phoneState?.value > 0
    ) {
      setPhoneState({
        ...phoneState,
        error: "Nomor telepon sudah terdaftar, silakan gunakan nomor lainnya",
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataCheckPhone]);

  useEffect(() => {
    if (successCheckUpdatePhone) {
      setLayout("otp");
    } else {
      setLayout("phone");
    }
  }, [successCheckUpdatePhone]);

  // useEffect(() => {
  //   if (successUpdatePhone) {
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [successUpdatePhone]);

  useEffect(() => {
    if (layout === "otp") {
      const intervalId = setInterval(() => {
        timeLeft > 1 && setTimeLeft(timeLeft - 1);
      }, 1000);
      return () => clearInterval(intervalId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout, timeLeft]);

  useEffect(() => {
    if (successUpdatePhone) {
      dispatch(
        editWriterProfileAction({
          phone: formattedPhoneNumber(phoneState.value),
        })
      );
      setDialogAlert({
        type: "success",
        title: "Berhasil Mengubah Nomor telepon",
        text: "Silakan login ulang dengan nomor telepon Baru",
        dialog: true,
      });
    }
    if (errUpdatePhone) {
      setDialogAlert({
        type: "error",
        title: "Gagal Mengubah Nomor telepon",
        text: "Nomor telepon gagal diubah",
        dialog: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUpdatePhone, errUpdatePhone]);

  const submitPhone = () => {
    if (phoneState?.value?.length < 1) {
      setPhoneState({
        ...phoneState,
        error: "Nomor Telepon harus diisi",
      });
    } else if (!formPhoneNumber(phoneState?.value)) {
      setPhoneState({
        ...phoneState,
        error: "Nomor Telepon tidak valid",
      });
    } else {
      dispatch(
        checkPhoneAction({ phone: formattedPhoneNumber(phoneState.value) })
      );
    }
  };

  const resendOtpHandler = (e) => {
    e.preventDefault();
    setTimeLeft(61);
    dispatch(
      checkUpdatePhoneAction(
        window.recaptchaVerifier,
        formattedPhoneNumber(phoneState.value)
      )
    );
  };

  const submitOtp = () => {
    dispatch(updatePhoneAction(verificationPhoneNumber, otpState.value));
  };

  const closeDialog = () => {
    if (dialogAlert.type === "success") {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
      dispatch(resetStateAuthAction());
      dispatch(logoutAction());
    } else {
      setDialogAlert({
        type: null,
        text: null,
        title: null,
        dialog: false,
      });
    }
  };

  return (
    <>
      <div className="section bg-grey p-py-5 setting">
        <div className="p-d-flex p-jc-center">
          <div className="col-sm-12 col-md-6 col-lg-6 mt-6">
            <div className="card card-custom-detail setting w-full has-shadow mb-5">
              <div className="card-header border-0 py-5 d-flex justify-content-between align-items-center">
                <div className="card-title">
                  <div
                    className="card-title-back"
                    onClick={() => history.goBack()}
                  >
                    <span className="icon-back"></span>
                  </div>
                  <h2 className="card-title-text">Ubah Telepon</h2>
                </div>
              </div>
              {layout === "phone" ? (
                <>
                  <div className="form-update-setting">
                    <div className="p-field">
                      <label className="p-d-block font-weight-bolder">
                        No. Handphone Saat Ini
                      </label>
                      <InputText
                        type="text"
                        placeholder="No. Handphone Lama"
                        className="p-d-block"
                        value={users?.user?.phone}
                        disabled
                      />
                    </div>
                    <div className="p-field">
                      <label className="p-d-block font-weight-bolder">
                        No. Handphone Baru
                      </label>
                      <InputText
                        type="text"
                        placeholder="No. Handphone Baru"
                        className={`p-d-block ${phoneState?.error &&
                          "p-invalid"}`}
                        value={phoneState.value}
                        onChange={(e) =>
                          setPhoneState({ value: e.target.value, error: null })
                        }
                      />
                      {phoneState.error && (
                        <smal className="p-error p-d-block p-text-right">
                          {phoneState.error}
                        </smal>
                      )}
                    </div>
                  </div>
                  <div id="grecaptcha-button" />
                  <div className="udpate-setting-footer p-d-flex p-jc-end">
                    <Button
                      className="p-button p-button-outlined"
                      onClick={() => history.goBack()}
                    >
                      BATAL
                    </Button>
                    {loadingCheckPhone || loadingCheckUpdatePhone ? (
                      <ButtonLoader />
                    ) : (
                      <Button
                        label="UBAH"
                        onClick={() => submitPhone()}
                        className="p-button-primary p-button p-button-rounded"
                        disabled={loadingCheckPhone || loadingCheckUpdatePhone}
                      />
                    )}
                  </div>
                </>
              ) : (
                <div className="form-setting p-px-4">
                  <div className="otp-wrapper text-center">
                    <p>
                      Silakan masukan kode OTP yang kami kirimkan ke nomor baru
                      anda di{" "}
                      <strong>
                        {phoneState?.value?.length > 0 &&
                          "*".repeat(phoneState?.value?.length - 4) +
                            phoneState?.value
                              ?.replace(/-/g, "")
                              .replace(/_/g, "")
                              .slice(-3)}
                      </strong>
                    </p>
                    <form onSubmit={submitOtp}>
                      <div className="p-field otp-code">
                        <OtpInput
                          value={otpState?.value}
                          onChange={(value) =>
                            setOtpState({ value: value, error: null })
                          }
                          numInputs={6}
                          containerStyle="otp-wrapp"
                          inputStyle="p-inputtext"
                          isInputNum
                        />
                      </div>
                      <div className="p-field">
                        <Button
                          label="Konfirmasi"
                          className="p-button-primary p-button-rounded"
                          onClick={() => submitOtp()}
                          disabled={loadingUpdatePhone}
                          type="submit"
                        />
                        <div className="p-py-3 p-text-center">
                          <span className="time">
                            Tersisa <strong>{timeLeft} detik</strong> lagi
                          </span>
                        </div>
                      </div>
                      <div id="grecaptcha-button" />
                      <div className="footer-otp">
                        <p>
                          Tidak menerima kode OTP?{" "}
                          <a href="#" onClick={resendOtpHandler}>
                            Kirim Ulang
                          </a>
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <OTPConfirmation
        visible={openOtp}
        closeDialog={() => setOpenOtp(false)}
      />

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
      />
    </>
  );
}

export default UpdatePhone;
