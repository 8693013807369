/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import Slider from "react-slick";
import Check from "../../assets/img/bi_check.svg";

const CarouselAuthor = (props) => {
  const { topWriter } = props;
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    slidesPerRow: 1,
    centerMode: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const writers = topWriter?.sort((a, b) => a.sortNumber - b.sortNumber);

  return (
    <div className="carouser-wrapper author-carousel">
      <Slider {...settings}>
        {writers?.map((item) => (
          <div className="carousel-item" key={item?.id}>
            <div className="author-wrapper p-shadow-2">
              <div className="author-info p-d-flex p-jc-center p-ai-center">
                <div className="info-wrapp">
                  <div className="pict-wrapper">
                    <div className="pict">
                      <img src={item?.imageUrl} alt="pict" />
                    </div>
                    <div className="author-number">
                      <span>{item?.sortNumber}</span>
                    </div>
                  </div>
                  <h3>{item?.name}</h3>
                  <p>
                    <img src={Check} alt="rated" />
                    TOP RATED
                  </p>
                  <div className="author-desc">
                    {item?.description?.length >= 55
                      ? `${item?.description.substring(0, 55)}...`
                      : item?.description}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselAuthor;
