import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import ListProjectModule from "../../../modules/Admin/ProjectPM/ListProjectModule";
import DashboardLayout from "../../../layouts/dashboardLayout";

import {
  getProjectPmAction,
  resetProjectDetailData,
} from "../../../../redux/action/projectAction";
import { titlePage } from "../../../helpers/titleHead";

const ListProjectPM = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const { projectParams } = useSelector((state) => state.project);
  const { page, perpage } = projectParams;

  useEffect(() => {
    dispatch(
      getProjectPmAction({
        page: parsed?.page ? parsed?.page : page,
        perpage,
        orderDir: "desc",
        orderField: "createdAt",
      })
    );
    dispatch(resetProjectDetailData());
    titlePage({
      title: "IRIS - Daftar Project",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectParams]);

  return (
    <DashboardLayout title="Manajemen Project" menu="project">
      <ListProjectModule />
    </DashboardLayout>
  );
};

export default ListProjectPM;
