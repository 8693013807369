/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { InputText } from "primereact/inputtext";

import DialogAlert from "../../../../components/dialog/DialogAlert";

import {
  resetStateUserAction,
  postPmAction,
} from "../../../../../redux/action/userAction";

import {
  formPhoneNumber,
  formattedPhoneNumber,
} from "../../../../helpers/phoneNumber";
import { formEmail } from "../../../../helpers/email";
import ButtonDefault from "../../../../components/button/ButtonDefault";

const AddPMModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [dialogAlert, setDialogAlert] = useState({
    type: "",
    dialog: false,
    title: "",
    text: "",
  });
  const [loadingState, setLoadingState] = useState(false);

  const [emailState, setEmailState] = useState({ value: "", error: null });
  const [phoneState, setPhoneState] = useState({ value: "", error: null });
  const [nameState, setNameState] = useState({ value: "", error: null });

  const { loadingCheckEmail } = useSelector((state) => state.auth);
  const { loadingPostPm, successPostPm, errPostPm } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    if (successPostPm) {
      setTimeout(() => {
        setDialogAlert({
          type: "success",
          dialog: true,
          title: "Berhasil Tambah Data!",
          text: "Data project manajer berhasil ditambahkan",
        });
        setLoadingState(false);
      }, 1000);
    }
  }, [successPostPm]);

  useEffect(() => {
    if (errPostPm) {
      setDialogAlert({
        type: "error",
        dialog: true,
        title: "Gagal Tambah Data!",
        text: "Email sudah digunakan oleh akun lain",
      });
      setLoadingState(false);
    }
  }, [errPostPm]);

  const closeSuccess = () => {
    if (dialogAlert?.type === "success") {
      history.push("/admin/pm/list-pm");
    }
    dispatch(resetStateUserAction());
    setDialogAlert({ type: "", dialog: false, title: "", text: "" });
  };

  const submitData = () => {
    if (!formEmail(emailState.value)) {
      setEmailState({ ...emailState, error: "Email tidak Valid" });
    }

    if (emailState?.value?.length < 1) {
      setEmailState({ ...emailState, error: "Email harus diisi" });
    }
    if (nameState?.value?.length < 1) {
      setNameState({ ...nameState, error: "Nama harus diisi" });
    }

    if (
      nameState?.value?.length > 0 &&
      emailState?.value?.length > 0 &&
      formEmail(emailState?.value)
    ) {
      if (phoneState?.value?.length > 0 && formPhoneNumber(phoneState.value)) {
        const data = {
          email: emailState.value,
          name: nameState.value,
          phone: formattedPhoneNumber(phoneState.value),
          nHandledProject: 0,
        };

        dispatch(postPmAction(data));
        setLoadingState(true);
      } else if (
        phoneState?.value?.length > 0 &&
        !formPhoneNumber(phoneState.value)
      ) {
        setPhoneState({ ...phoneState, error: "Nomor telepon tidak Valid" });
      } else {
        const data = {
          email: emailState.value,
          name: nameState.value,
          nHandledProject: 0,
        };

        dispatch(postPmAction(data));
        setLoadingState(true);
      }
    }
  };

  return (
    <>
      <div className="p-d-flex p-jc-center detail-pm">
        <div className="card card-custom col-8 mb-4">
          <div className="card-header border-0 py-5">
            <h3 className="card-title align-items-start flex-column">
              <button onClick={() => history.goBack()} className="button-back">
                <span className="card-label font-weight-bolder text-dark">
                  <span className="icon-back mr-2"></span>Tambah Project Manager
                </span>
              </button>
            </h3>
          </div>
          <div className="card-body card-pm">
            <div className="form-detail-pm col-10">
              <div className="p-field">
                <label className="p-d-block">
                  Nama Project Manager <span className="text-danger">*</span>
                </label>
                <InputText
                  type="text"
                  className={`p-d-block ${nameState.error && "p-invalid"}`}
                  placeholder="Masukkan Nama"
                  value={nameState.value}
                  onChange={(e) =>
                    setNameState({ value: e.target.value, error: null })
                  }
                />
                {nameState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {nameState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">
                  Email <span className="text-danger">*</span>
                </label>
                <InputText
                  className={`p-d-block ${nameState.error && "p-invalid"}`}
                  type="email"
                  placeholder="Masukkan Email"
                  value={emailState.value}
                  onChange={(e) =>
                    setEmailState({ value: e.target.value, error: null })
                  }
                />
                {emailState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {emailState.error}
                  </small>
                )}
              </div>
              <div className="p-field">
                <label className="p-d-block">Nomor Telepon</label>
                <InputText
                  type="text"
                  className={`p-d-block ${phoneState.error && "p-invalid"}`}
                  placeholder="Masukkan Nomor Telepon"
                  value={phoneState.value}
                  onChange={(e) =>
                    setPhoneState({ value: e.target.value, error: null })
                  }
                />
                {phoneState.error && (
                  <small className="p-error p-d-block p-text-right">
                    {phoneState.error}
                  </small>
                )}
              </div>
            </div>
          </div>
          <div className="footer-form">
            <div className="form-add-project col-10 pm-add p-d-flex p-jc-end p-ai-center">
              <ButtonDefault
                className="p-button-outlined p-button p-button-secondary btn-wide"
                label="BATAL"
                onClick={() => history.goBack()}
                type="bordered"
                disabled={loadingCheckEmail || loadingPostPm || loadingState}
              />

              <ButtonDefault
                onClick={() => submitData()}
                label="TAMBAH"
                className="btn-wide ml-4 p-button p-button-primary"
                loading={loadingCheckEmail || loadingPostPm || loadingState}
              />
            </div>
          </div>
        </div>
      </div>
      <DialogAlert
        type={dialogAlert?.type}
        visible={dialogAlert?.dialog}
        closeDialog={() => closeSuccess()}
        title={dialogAlert?.title}
        text={dialogAlert?.text}
      />
    </>
  );
};

export default AddPMModule;
