import { call, put } from "redux-saga/effects";
import {
  GET_WINNER_RELEASE_FAILURE,
  GET_WINNER_RELEASE_SUCCESS,
  DETAIL_RELEASE_FAILURE,
  DETAIL_RELEASE_SUCCESS,
  FS_MEDIA_FAILURE,
  FS_MEDIA_SUCCESS,
  ADD_MEDIA_RELEASE_FAILURE,
  ADD_MEDIA_RELEASE_SUCCESS,
  PUT_MEDIA_RELEASE_FAILURE,
  PUT_MEDIA_RELEASE_SUCCESS,
  DELETE_MEDIA_RELEASE_FAILURE,
  DELETE_MEDIA_RELEASE_SUCCESS,
  FS_DETAIL_MEDIA_FAILURE,
  FS_DETAIL_MEDIA_SUCCESS,
  STATS_MEDIA_RELEASE_FAILURE,
  STATS_MEDIA_RELEASE_SUCCESS,
  STATS_RELEASE_FAILURE,
  STATS_RELEASE_SUCCESS,
} from "../constant/releaseConstant";
import {
  getWinnerRelease,
  getDetailRelease,
  postRelease,
  updateRelease,
  deleteRelease,
  statsMediaRelease,
  statsRelease,
} from "../service/api/releaseApi";
import { getToken } from "../service/firebase/auth";
import { getMedia } from "../service/firebase/utils";
import { getDetailMedia } from "../service/firebase/project";

export function* getWinnerReleaseSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(getWinnerRelease, token, action.params);
  if (ok) {
    yield put({ type: GET_WINNER_RELEASE_SUCCESS, data });
  } else {
    yield put({ type: GET_WINNER_RELEASE_FAILURE, data });
  }
}

export function* getDetailReleaseSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    getDetailRelease,
    token,
    action.id,
    action.params
  );
  if (ok) {
    yield put({ type: DETAIL_RELEASE_SUCCESS, data });
  } else {
    yield put({ type: DETAIL_RELEASE_FAILURE, data });
  }
}

export function* getMediaSaga() {
  try {
    const res = yield call(getMedia);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: FS_MEDIA_SUCCESS, data });
  } catch (err) {
    yield put({ type: FS_MEDIA_FAILURE, err });
  }
}

export function* postReleaseSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(postRelease, token, action.data);
  if (ok) {
    yield put({ type: ADD_MEDIA_RELEASE_SUCCESS });
  } else {
    yield put({ type: ADD_MEDIA_RELEASE_FAILURE, data });
  }
}

export function* updateReleaseSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(updateRelease, token, action.id, action.data);
  if (ok) {
    yield put({ type: PUT_MEDIA_RELEASE_SUCCESS });
  } else {
    yield put({ type: PUT_MEDIA_RELEASE_FAILURE, data });
  }
}

export function* deleteReleaseSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(deleteRelease, token, action.id);
  if (ok) {
    yield put({ type: DELETE_MEDIA_RELEASE_SUCCESS });
  } else {
    yield put({ type: DELETE_MEDIA_RELEASE_FAILURE, data });
  }
}

export function* getDetailMediaSaga(action) {
  try {
    const snapshot = yield call(getDetailMedia, action.id);
    const data = snapshot.data();
    yield put({
      type: FS_DETAIL_MEDIA_SUCCESS,
      data,
    });
  } catch (err) {
    yield put({ type: FS_DETAIL_MEDIA_FAILURE, err });
  }
}

export function* statsReleaseSaga() {
  const token = yield call(getToken);
  const { ok, data } = yield call(statsRelease, token);
  if (ok) {
    yield put({ type: STATS_RELEASE_SUCCESS, data });
  } else {
    yield put({ type: STATS_RELEASE_FAILURE, data });
  }
}

export function* statsMediaReleaseSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(statsMediaRelease, token, action.id);
  if (ok) {
    yield put({ type: STATS_MEDIA_RELEASE_SUCCESS, data });
  } else {
    yield put({ type: STATS_MEDIA_RELEASE_FAILURE, data });
  }
}
