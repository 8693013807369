export const LOGIN_WITH_EMAIL_REQUEST = "LOGIN_WITH_EMAIL_REQUEST";
export const LOGIN_WITH_EMAIL_SUCCESS = "LOGIN_WITH_EMAIL_SUCCESS";
export const LOGIN_WITH_EMAIL_FAILURE = "LOGIN_WITH_EMAIL_FAILURE";

export const LOGIN_WITH_LINK_REQUEST = "LOGIN_WITH_LINK_REQUEST";
export const LOGIN_WITH_LINK_SUCCESS = "LOGIN_WITH_LINK_SUCCESS";
export const LOGIN_WITH_LINK_FAILURE = "LOGIN_WITH_LINK_FAILURE";

export const PHONE_REGISTER_REQUEST = "PHONE_REGISTER_REQUEST";
export const PHONE_REGISTER_SUCCESS = "PHONE_REGISTER_SUCCESS";
export const PHONE_REGISTER_FAILURE = "PHONE_REGISTER_FAILURE";

export const VERIFY_OTP_REQUEST = "VERIFY_OTP_REQUEST";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAILURE = "VERIFY_OTP_FAILURE";

export const LOGOUT_REQUEST = "LOGOUT_REQUEST";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

export const PHONE_LOGIN_REQUEST = "PHONE_LOGIN_REQUEST";
export const PHONE_LOGIN_SUCCESS = "PHONE_LOGIN_SUCCESS";
export const PHONE_LOGIN_FAILURE = "PHONE_LOGIN_FAILURE";

export const LOGIN_VERIFY_OTP_REQUEST = "LOGIN_VERIFY_OTP_REQUEST";
export const LOGIN_VERIFY_OTP_SUCCESS = "LOGIN_VERIFY_OTP_SUCCESS";
export const LOGIN_VERIFY_OTP_FAILURE = "LOGIN_VERIFY_OTP_FAILURE";

export const CHECK_PHONE_REQUEST = "CHECK_PHONE_REQUEST";
export const CHECK_PHONE_SUCCESS = "CHECK_PHONE_SUCCESS";
export const CHECK_PHONE_FAILURE = "CHECK_PHONE_FAILURE";

export const CHECK_EMAIL_REQUEST = "CHECK_EMAIL_REQUEST";
export const CHECK_EMAIL_SUCCESS = "CHECK_EMAIL_SUCCESS";
export const CHECK_EMAIL_FAILURE = "CHECK_EMAIL_FAILURE";

export const EMAIL_REGISTER_REQUEST = "EMAIL_REGISTER_REQUEST";
export const EMAIL_REGISTER_SUCCESS = "EMAIL_REGISTER_SUCCESS";
export const EMAIL_REGISTER_FAILURE = "EMAIL_REGISTER_FAILURE";

export const APPLICANT_REGISTER_REQUEST = "APPLICANT_REGISTER_REQUEST";
export const APPLICANT_REGISTER_SUCCESS = "APPLICANT_REGISTER_SUCCESS";
export const APPLICANT_REGISTER_FAILURE = "APPLICANT_REGISTER_FAILURE";

export const GOOGLE_LOGIN_REQUEST = "GOOGLE_LOGIN_REQUEST";
export const GOOGLE_LOGIN_SUCCESS = "GOOGLE_LOGIN_SUCCESS";
export const GOOGLE_LOGIN_FAILURE = "GOOGLE_LOGIN_FAILURE";

export const MY_PROFILE_REQUEST = "MY_PROFILE_REQUEST";
export const MY_PROFILE_SUCCESS = "MY_PROFILE_SUCCESS";
export const MY_PROFILE_FAILURE = "MY_PROFILE_FAILURE";

export const EDIT_WRITER_PROFILE_REQUEST = "EDIT_WRITER_PROFILE_REQUEST";
export const EDIT_WRITER_PROFILE_SUCCESS = "EDIT_WRITER_PROFILE_SUCCESS";
export const EDIT_WRITER_PROFILE_FAILURE = "EDIT_WRITER_PROFILE_FAILURE";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_FAILURE = "UPDATE_PASSWORD_FAILURE";

export const UPDATE_EMAIL_REQUEST = "UPDATE_EMAIL_REQUEST";
export const UPDATE_EMAIL_SUCCESS = "UPDATE_EMAIL_SUCCESS";
export const UPDATE_EMAIL_FAILURE = "UPDATE_EMAIL_FAILURE";

export const CHECK_UPDATE_PHONE_REQUEST = "CHECK_UPDATE_PHONE_REQUEST";
export const CHECK_UPDATE_PHONE_SUCCESS = "CHECK_UPDATE_PHONE_SUCCESS";
export const CHECK_UPDATE_PHONE_FAILURE = "CHECK_UPDATE_PHONE_FAILURE";

export const UPDATE_PHONE_REQUEST = "UPDATE_PHONE_REQUEST";
export const UPDATE_PHONE_SUCCESS = "UPDATE_PHONE_SUCCESS";
export const UPDATE_PHONE_FAILURE = "UPDATE_PHONE_FAILURE";

export const CLIENT_REGISTER_REQUEST = "CLIENT_REGISTER_REQUEST";
export const CLIENT_REGISTER_SUCCESS = "CLIENT_REGISTER_SUCCESS";
export const CLIENT_REGISTER_FAILURE = "CLIENT_REGISTER_FAILURE";

export const VERIFY_CLIENT_REQUEST = "VERIFY_CLIENT_REQUEST";
export const VERIFY_CLIENT_SUCCESS = "VERIFY_CLIENT_SUCCESS";
export const VERIFY_CLIENT_FAILURE = "VERIFY_CLIENT_FAILURE";

export const SEND_UPDATE_EMAIL_CLIENT_REQUEST = "SEND_UPDATE_EMAIL_CLIENT_REQUEST";
export const SEND_UPDATE_EMAIL_CLIENT_SUCCESS = "SEND_UPDATE_EMAIL_CLIENT_SUCCESS";
export const SEND_UPDATE_EMAIL_CLIENT_FAILURE = "SEND_UPDATE_EMAIL_CLIENT_FAILURE";

export const SEND_UPDATE_EMAIL_PM_REQUEST = "SEND_UPDATE_EMAIL_PM_REQUEST";
export const SEND_UPDATE_EMAIL_PM_SUCCESS = "SEND_UPDATE_EMAIL_PM_SUCCESS";
export const SEND_UPDATE_EMAIL_PM_FAILURE = "SEND_UPDATE_EMAIL_PM_FAILURE";

export const SEND_UPDATE_EMAIL_WRITER_REQUEST =
  "SEND_UPDATE_EMAIL_WRITER_REQUEST";
export const SEND_UPDATE_EMAIL_WRITER_SUCCESS =
  "SEND_UPDATE_EMAIL_WRITER_SUCCESS";
export const SEND_UPDATE_EMAIL_WRITER_FAILURE =
  "SEND_UPDATE_EMAIL_WRITER_FAILURE";

export const UPDATE_EMAIL_CLIENT_REQUEST = "UPDATE_EMAIL_CLIENT_REQUEST";
export const UPDATE_EMAIL_CLIENT_SUCCESS = "UPDATE_EMAIL_CLIENT_SUCCESS";
export const UPDATE_EMAIL_CLIENT_FAILURE = "UPDATE_EMAIL_CLIENT_FAILURE";

export const UPDATE_EMAIL_PM_REQUEST = "UPDATE_EMAIL_PM_REQUEST";
export const UPDATE_EMAIL_PM_SUCCESS = "UPDATE_EMAIL_PM_SUCCESS";
export const UPDATE_EMAIL_PM_FAILURE = "UPDATE_EMAIL_PM_FAILURE";

export const UPDATE_EMAIL_WRITER_REQUEST = "UPDATE_EMAIL_WRITER_REQUEST";
export const UPDATE_EMAIL_WRITER_SUCCESS = "UPDATE_EMAIL_WRITER_SUCCESS";
export const UPDATE_EMAIL_WRITER_FAILURE = "UPDATE_EMAIL_WRITER_FAILURE";

export const UPDATE_USER_FIREBASE_REQUEST = "UPDATE_USER_FIREBASE_REQUEST";
export const UPDATE_USER_FIREBASE_SUCCESS = "UPDATE_USER_FIREBASE_SUCCESS";
export const UPDATE_USER_FIREBASE_FAILURE = "UPDATE_USER_FIREBASE_FAILURE";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const CREATE_NEW_PASSWORD_REQUEST = "CREATE_NEW_PASSWORD_REQUEST";
export const CREATE_NEW_PASSWORD_SUCCESS = "CREATE_NEW_PASSWORD_SUCCESS";
export const CREATE_NEW_PASSWORD_FAILURE = "CREATE_NEW_PASSWORD_FAILURE";

export const RESET_STATE_AUTH = "RESET_STATE_AUTH";
