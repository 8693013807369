import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import TextBody from "../datatableBody/TextBody";
import StatusBadge from "../badge/StatusBadge";
import DateBody from "../datatableBody/DateBody";
import DataEmpty from "../emptyState/dataEmpty";

const TabsUserProject = (props) => {
  const { writerList, link, userType } = props;
  // eslint-disable-next-line no-unused-vars

  const statusBodyTemplate = (item) => {
    return (
      <StatusBadge
        status={item?.writerId === item?.clientId ? "draft" : item?.status}
        link={`${link?.writer}${item?.id}`}
        user={userType ? userType : "pm"}
      />
    );
  };
  const dateBodyTemplate = (item) => {
    return (
      <DateBody
        date={item?.updatedAt?.toDate()}
        link={`${link?.writer}${item?.id}`}
        isHour
      />
    );
  };
  const nameBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.userData?.name}
        link={`${link?.writer}${item?.id}`}
      />
    );
  };

  const titleBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.articleTitle || "Judul Belum Tersedia"}
        link={`${link?.writer}${item?.id}`}
      />
    );
  };

  return (
    <>
      <div className="detail-release-tab">
        <>
          {writerList?.length < 1 ? (
            <DataEmpty />
          ) : (
            <DataTable value={writerList} className="no-padding">
              <Column body={titleBodyTemplate} header="Judul Tulisan" />
              <Column body={nameBodyTemplate} header="Penulis" />
              <Column body={statusBodyTemplate} header="Status" />
              <Column body={dateBodyTemplate} header="Update Terakhir" />
            </DataTable>
          )}
        </>
      </div>
    </>
  );
};

export default TabsUserProject;
