import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import { Button } from "primereact/button";

import EmptyImage from "../../../assets/img/ticket_empty.svg";

function HelpListModule() {
  const history = useHistory();
  const { dataGetHelpList } = useSelector((state) => state.help);
  const [isEmptyHelp, setIsEmptyHelp] = useState(true);

  useEffect(() => {
    if (dataGetHelpList?.length > 0) {
      setIsEmptyHelp(false);
    } else {
      setIsEmptyHelp(true);
    }
  }, [dataGetHelpList]);

  return (
    <div>
      {isEmptyHelp ? (
        <div>
          <div className="empty-help-list">
            <div>
              <img src={EmptyImage} alt="ticket_empty" />
              <h3>Anda Belum Memiliki List Pertanyaan</h3>
              <p>Klik tombol di bawah ini untuk menambahkan pertanyaan</p>
              <Button
                className="p-button-primary p-button-rounded sm-rounded"
                label="MULAI BERTANYA"
                onClick={() => history.push("/client/help/add")}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="card card-custom">
          <div className="card-header p-d-flex p-jc-between border-0 py-5">
            <h3 className="card-title align-items-center">
              <span className="card-label font-weight-bolder text-dark">
                List Bantuan
              </span>
            </h3>
            <Button
              className="p-button-primary p-button-rounded sm-rounded"
              label="TAMBAH PERTANYAAN"
              onClick={() => history.push("/client/help/add")}
            />
          </div>
          <div className="card-body pt-0 pb-3">
            {dataGetHelpList?.map((ticket) => (
              <div
                onClick={() =>
                  history.push(`/client/help/detail/${ticket?.id}`)
                }
                key={ticket?.id}
                className={`ticket-item ${ticket?.category}`}
              >
                <div className="p-d-flex p-jc-between">
                  <h3>{ticket?.title}</h3>
                  <span>
                    {dayjs(ticket?.createdAt.toDate())
                      .locale("id")
                      .format("DD MMMM YYYY")}
                  </span>
                </div>
                <div className="p-d-flex p-jc-between">
                  <span>{ticket?.category}</span>
                  <span
                    className={`status ${
                      ticket?.status === "closed" ? "closed" : "open"
                    }`}
                  >
                    {ticket?.status}
                  </span>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

export default HelpListModule;
