import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { InputText } from "primereact/inputtext";
import { listWriterPaymentAction } from "../../../../../redux/action/transactionAction";

const FilterPayment = (props) => {
  const isFinance = { props };
  const dispatch = useDispatch();
  const history = useHistory();

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        page: 1,
        perpage: 10,
      };

      history.push(
        `/admin/${
          isFinance ? "finance" : "pm"
        }/list-payment?search=${search}&page=1`
      );
      dispatch(listWriterPaymentAction(params));
    }, 1000)
  ).current;

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="filter">
        <div className="filter-field">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block search-inputtext"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
          <p>
            Cari berdasarkan <span>Nama Penulis</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default FilterPayment;
