/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react";
import emptyImage from "../../../../assets/img/empty-list.png";

const PreviewContent = (props) => {
  const { articleData } = props;

  const isEmptyArticle =
    (!articleData?.title && !articleData?.content) ||
    (articleData?.title === "" && articleData?.content === "<p></p>") ||
    (articleData?.title === "<p></p>" && articleData?.content === "<p></p>") ||
    (articleData?.title === "<p></p>" &&
      articleData?.content === "<p><br></p>");

  return (
    <div className="section-article-box no-scroll">
      {isEmptyArticle ? (
        <div className="empty-article">
          <img src={emptyImage} alt="kosong" />
          <h1>Tulisan Masih Kosong</h1>
        </div>
      ) : (
        <>
          <div className="article-box">
            <div
              className="article-view-title"
              dangerouslySetInnerHTML={{
                __html: `${articleData?.articleTitle}`,
              }}
            />
          </div>
          <div className="article-view-body">
            {articleData?.attachments && (
              <div className="article-view-cover">
                <img src={articleData?.articleImage} alt="photo-article" />
              </div>
            )}
            <div
              className="article-view-content"
              dangerouslySetInnerHTML={{
                __html: `${articleData?.articleContent}`,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default PreviewContent;
