import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import {
  GET_BANNER_FAILURE,
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BENEFIT_FAILURE,
  GET_BENEFIT_REQUEST,
  GET_BENEFIT_SUCCESS,
  GET_HOW_DOES_IT_WORK_FAILURE,
  GET_HOW_DOES_IT_WORK_REQUEST,
  GET_HOW_DOES_IT_WORK_SUCCESS,
  GET_TESTIMONIAL_FAILURE,
  GET_TESTIMONIAL_REQUEST,
  GET_TESTIMONIAL_SUCCESS,
  GET_SETTING_SITE_FAILURE,
  GET_SETTING_SITE_REQUEST,
  GET_SETTING_SITE_SUCCESS,
  GET_TOP_WRITER_FAILURE,
  GET_TOP_WRITER_REQUEST,
  GET_TOP_WRITER_SUCCESS,
  GET_ARTICLES_FAILURE,
  GET_ARTICLES_REQUEST,
  GET_ARTICLES_SUCCESS,
  GET_DETAIL_ARTICLE_FAILURE,
  GET_DETAIL_ARTICLE_REQUEST,
  GET_DETAIL_ARTICLE_SUCCESS,
  GET_FOOTER_REQUEST,
  GET_FOOTER_SUCCESS,
  GET_FOOTER_FAILURE,
  GET_ABOUT_REQUEST,
  GET_ABOUT_SUCCESS,
  GET_ABOUT_FAILURE,
  GET_TERM_REQUEST,
  GET_TERM_SUCCESS,
  GET_TERM_FAILURE,
  GET_PRIVACY_REQUEST,
  GET_PRIVACY_SUCCESS,
  GET_PRIVACY_FAILURE,
  GET_FAQ_REQUEST,
  GET_FAQ_SUCCESS,
  GET_FAQ_FAILURE,
  TYPE_PRODUCT_REQUEST,
  TYPE_PRODUCT_SUCCESS,
  TYPE_PRODUCT_FAILURE,
  GET_STATIC_PAGES_REQUEST,
  GET_STATIC_PAGES_SUCCESS,
  GET_STATIC_PAGES_FAILURE,
} from "../constant/landingpageConstant";

const initialStateLandingpage = {
  // Banner
  loadingGetBanner: false,
  dataGetBanner: null,
  errGetBanner: null,

  // Benefit
  loadingGetBenefit: false,
  dataGetBenefit: null,
  errGetBenefit: null,

  // Testimonial
  loadingGetTestimonial: false,
  dataGetTestimonial: null,
  errGetTestimonial: null,

  // How does it work
  loadingGetHowDoesItWork: false,
  dataGetHowDoesItWork: null,
  errGetHowDoesItWork: null,

  // Footer
  loadingGetFooter: false,
  dataGetFooter: null,
  errGetFooter: null,

  loadingGetAbout: false,
  dataGetAbout: null,
  errGetAbout: null,

  loadingGetTerm: false,
  dataGetTerm: null,
  errGetTerm: null,

  loadingGetPrivacy: false,
  dataGetPrivacy: null,
  errGetPrivacy: null,

  // Setting site
  loadingGetSettingSite: false,
  dataGetSettingSite: null,
  errGetSettingSite: null,

  // Top writer
  loadingGetTopWriter: false,
  dataGetTopWriter: null,
  errGetTopWriter: null,

  loadingGetArticles: false,
  dataGetArticles: null,
  errGetArticles: null,

  loadingGetDetailArticle: false,
  dataGetDetailArticle: null,
  errGetDetailArticle: null,

  loadingGetFaq: false,
  dataGetFaq: null,
  errGetFaq: null,

  loadingTypeProduct: false,
  dataTypeProduct: null,
  errTypeProduct: null,

  loadingStaticPage: false,
  dataStaticPage: null,
  errStaticPage: null,
};

export const landingpageReducer = persistReducer(
  { storage, key: "v711-demo1-lp", whitelist: ["dataGetSettingSite"] },
  (state = initialStateLandingpage, action) => {
    switch (action.type) {
      // Banner
      case GET_BANNER_REQUEST:
        return {
          ...state,
          loadingGetBanner: true,
          dataGetBanner: null,
          errGetBanner: null,
        };
      case GET_BANNER_SUCCESS:
        return {
          ...state,
          loadingGetBanner: false,
          dataGetBanner: action.data,
          errGetBanner: null,
        };
      case GET_BANNER_FAILURE:
        return {
          ...state,
          loadingGetBanner: false,
          dataGetBanner: null,
          errGetBanner: action.err,
        };

      // Benefit
      case GET_BENEFIT_REQUEST:
        return {
          ...state,
          loadingGetBenefit: true,
          dataGetBenefit: null,
          errGetBenefit: null,
        };
      case GET_BENEFIT_SUCCESS:
        return {
          ...state,
          loadingGetBenefit: false,
          dataGetBenefit: action.data,
          errGetBenefit: null,
        };
      case GET_BENEFIT_FAILURE:
        return {
          ...state,
          loadingGetBenefit: false,
          dataGetBenefit: null,
          errGetBenefit: action.err,
        };

      // How does it work
      case GET_HOW_DOES_IT_WORK_REQUEST:
        return {
          ...state,
          loadingGetHowDoesItWork: true,
          dataGetHowDoesItWork: null,
          errGetHowDoesItWork: null,
        };
      case GET_HOW_DOES_IT_WORK_SUCCESS:
        return {
          ...state,
          loadingGetHowDoesItWork: false,
          dataGetHowDoesItWork: action.data,
          errGetHowDoesItWork: null,
        };
      case GET_HOW_DOES_IT_WORK_FAILURE:
        return {
          ...state,
          loadingGetHowDoesItWork: false,
          dataGetHowDoesItWork: null,
          errGetHowDoesItWork: action.err,
        };

      // Testimonial
      case GET_TESTIMONIAL_REQUEST:
        return {
          ...state,
          loadingGetTestimonial: true,
          dataGetTestimonial: null,
          errGetTestimonial: null,
        };
      case GET_TESTIMONIAL_SUCCESS:
        return {
          ...state,
          loadingGetTestimonial: false,
          dataGetTestimonial: action.data,
          errGetTestimonial: null,
        };
      case GET_TESTIMONIAL_FAILURE:
        return {
          ...state,
          loadingGetTestimonial: false,
          dataGetTestimonial: null,
          errGetTestimonial: action.err,
        };

      // Setting Site
      case GET_SETTING_SITE_REQUEST:
        return {
          ...state,
          loadingGetSettingSite: true,
          dataGetSettingSite: null,
          errGetSettingSite: null,
        };
      case GET_SETTING_SITE_SUCCESS:
        return {
          ...state,
          loadingGetSettingSite: false,
          dataGetSettingSite: action.data,
          errGetSettingSite: null,
        };
      case GET_SETTING_SITE_FAILURE:
        return {
          ...state,
          loadingGetSettingSite: false,
          dataGetSettingSite: null,
          errGetSettingSite: action.err,
        };

      // Top Writer
      case GET_TOP_WRITER_REQUEST:
        return {
          ...state,
          loadingGetTopWriter: true,
          dataGetTopWriter: null,
          errGetTopWriter: null,
        };
      case GET_TOP_WRITER_SUCCESS:
        return {
          ...state,
          loadingGetTopWriter: false,
          dataGetTopWriter: action.data,
          errGetTopWriter: null,
        };
      case GET_TOP_WRITER_FAILURE:
        return {
          ...state,
          loadingGetTopWriter: false,
          dataGetTopWriter: null,
          errGetTopWriter: action.err,
        };

      // Article
      case GET_ARTICLES_REQUEST:
        return {
          ...state,
          loadingGetArticles: true,
          dataGetArticles: null,
          errGetArticles: null,
        };
      case GET_ARTICLES_SUCCESS:
        return {
          ...state,
          loadingGetArticles: false,
          dataGetArticles: action.data,
          errGetArticles: null,
        };
      case GET_ARTICLES_FAILURE:
        return {
          ...state,
          loadingGetArticles: false,
          dataGetArticles: null,
          errGetArticles: action.err,
        };

      case GET_DETAIL_ARTICLE_REQUEST:
        return {
          ...state,
          loadingGetDetailArticle: true,
          dataGetDetailArticle: null,
          errGetDetailArticle: null,
        };
      case GET_DETAIL_ARTICLE_SUCCESS:
        return {
          ...state,
          loadingGetDetailArticle: false,
          dataGetDetailArticle: action.data,
          errGetDetailArticle: null,
        };
      case GET_DETAIL_ARTICLE_FAILURE:
        return {
          ...state,
          loadingGetDetailArticle: false,
          dataGetDetailArticle: null,
          errGetDetailArticle: action.err,
        };

      case GET_FOOTER_REQUEST:
        return {
          ...state,
          loadingGetFooter: true,
          dataGetFooter: null,
          errGetFooter: null,
        };
      case GET_FOOTER_SUCCESS:
        return {
          ...state,
          loadingGetFooter: false,
          dataGetFooter: action.data,
          errGetFooter: null,
        };
      case GET_FOOTER_FAILURE:
        return {
          ...state,
          loadingGetFooter: false,
          dataGetFooter: null,
          errGetFooter: action.err,
        };

      case GET_ABOUT_REQUEST:
        return {
          ...state,
          loadingGetAbout: true,
          dataGetAbout: null,
          errGetAbout: null,
        };
      case GET_ABOUT_SUCCESS:
        return {
          ...state,
          loadingGetAbout: false,
          dataGetAbout: action.data,
          errGetAbout: null,
        };
      case GET_ABOUT_FAILURE:
        return {
          ...state,
          loadingGetAbout: false,
          dataGetAbout: null,
          errGetAbout: action.err,
        };

      case GET_TERM_REQUEST:
        return {
          ...state,
          loadingGetTerm: true,
          dataGetTerm: null,
          errGetTerm: null,
        };
      case GET_TERM_SUCCESS:
        return {
          ...state,
          loadingGetTerm: false,
          dataGetTerm: action.data,
          errGetTerm: null,
        };
      case GET_TERM_FAILURE:
        return {
          ...state,
          loadingGetTerm: false,
          dataGetTerm: null,
          errGetTerm: action.err,
        };

      case GET_PRIVACY_REQUEST:
        return {
          ...state,
          loadingGetPrivacy: true,
          dataGetPrivacy: null,
          errGetPrivacy: null,
        };
      case GET_PRIVACY_SUCCESS:
        return {
          ...state,
          loadingGetPrivacy: false,
          dataGetPrivacy: action.data,
          errGetPrivacy: null,
        };
      case GET_PRIVACY_FAILURE:
        return {
          ...state,
          loadingGetPrivacy: false,
          dataGetPrivacy: null,
          errGetPrivacy: action.err,
        };

      case GET_FAQ_REQUEST:
        return {
          ...state,
          loadingGetFaq: true,
          dataGetFaq: null,
          errGetFaq: null,
        };
      case GET_FAQ_SUCCESS:
        return {
          ...state,
          loadingGetFaq: false,
          dataGetFaq: action.data,
          errGetFaq: null,
        };
      case GET_FAQ_FAILURE:
        return {
          ...state,
          loadingGetFaq: false,
          dataGetFaq: null,
          errGetFaq: action.err,
        };

      case TYPE_PRODUCT_REQUEST:
        return {
          ...state,
          loadingTypeProduct: true,
          dataTypeProduct: null,
          errTypeProduct: null,
        };
      case TYPE_PRODUCT_SUCCESS:
        return {
          ...state,
          loadingTypeProduct: false,
          dataTypeProduct: action.data,
          errTypeProduct: null,
        };
      case TYPE_PRODUCT_FAILURE:
        return {
          ...state,
          loadingTypeProduct: false,
          dataTypeProduct: null,
          errTypeProduct: action.err,
        };

      case GET_STATIC_PAGES_REQUEST:
        return {
          ...state,
          loadingStaticPage: true,
          dataStaticPage: null,
          errStaticPage: null,
        };
      case GET_STATIC_PAGES_SUCCESS:
        return {
          ...state,
          loadingStaticPage: false,
          dataStaticPage: action.data.length > 0 ? action.data[0] : null,
          errStaticPage: null,
        };
      case GET_STATIC_PAGES_FAILURE:
        return {
          ...state,
          loadingStaticPage: false,
          dataStaticPage: null,
          errStaticPage: action.err,
        };

      default:
        return state;
    }
  }
);
