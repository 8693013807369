import React from 'react'
import ProfileForm from './ProfileForm'
import backIcon from '../../../assets/img/arrow-back.svg'
import { Link } from 'react-router-dom'

function ProfileEditModule() {
    return (
        <div className='client-profile-module' >
            <div className="profile-title edit d-flex">
                <Link to='/client/profile' >
                    <img src={backIcon} alt="edit" />
                </Link>
                <span>Edit Profile</span>
            </div>
            <ProfileForm isEdit={true} />            
        </div>
    )
}

export default ProfileEditModule
