import React from "react";

function ResponsiveColumn({ header, headerClassName, body, data, index }) {

  return (
    <div>
      {index === 0 ? (
        <span className="p-column-title">{header}</span>
      ) : (
        body(data)
      )}
    </div>
  );
}

export default ResponsiveColumn;
