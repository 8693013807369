import { call, put } from "redux-saga/effects";
import {
  GET_BANNER_FAILURE,
  GET_BANNER_SUCCESS,
  GET_BENEFIT_FAILURE,
  GET_BENEFIT_SUCCESS,
  GET_TESTIMONIAL_FAILURE,
  GET_TESTIMONIAL_SUCCESS,
  GET_HOW_DOES_IT_WORK_FAILURE,
  GET_HOW_DOES_IT_WORK_SUCCESS,
  GET_SETTING_SITE_FAILURE,
  GET_SETTING_SITE_SUCCESS,
  GET_TOP_WRITER_FAILURE,
  GET_TOP_WRITER_SUCCESS,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAILURE,
  GET_DETAIL_ARTICLE_FAILURE,
  GET_DETAIL_ARTICLE_SUCCESS,
  GET_FOOTER_FAILURE,
  GET_FOOTER_SUCCESS,
  GET_ABOUT_FAILURE,
  GET_ABOUT_SUCCESS,
  GET_TERM_FAILURE,
  GET_TERM_SUCCESS,
  GET_PRIVACY_FAILURE,
  GET_PRIVACY_SUCCESS,
  GET_FAQ_FAILURE,
  GET_FAQ_SUCCESS,
  TYPE_PRODUCT_SUCCESS,
  TYPE_PRODUCT_FAILURE,
  GET_STATIC_PAGES_SUCCESS,
  GET_STATIC_PAGES_FAILURE,
} from "../constant/landingpageConstant";
import {
  getBanners,
  getBenefit,
  getHowDoesItWork,
  getSetting,
  getTestimoni,
  getTopWriters,
  getArticles,
  getDetailArticles,
  getFooter,
  aboutUs,
  privacyPolicy,
  termCondition,
  typeProduct,
  getStaticPage,
} from "../service/firebase/landingpage";

import { getFaq } from "../service/api/utilsApi";

// Banner
export function* getBannerSaga() {
  try {
    const res = yield call(getBanners);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_BANNER_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_BANNER_FAILURE, err });
  }
}

// Benefit
export function* getBenefitSaga() {
  try {
    const snapshot = yield call(getBenefit);
    const data = snapshot.data();
    yield put({ type: GET_BENEFIT_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_BENEFIT_FAILURE, err });
  }
}

export function* getFooterSaga() {
  try {
    const result = yield call(getFooter);
    const data = result.data();
    yield put({ type: GET_FOOTER_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_FOOTER_FAILURE, err });
  }
}

// How does it work
export function* getHowDoesItWorkSaga() {
  try {
    const snapshot = yield call(getHowDoesItWork);
    const data = snapshot.data();
    yield put({ type: GET_HOW_DOES_IT_WORK_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_HOW_DOES_IT_WORK_FAILURE, err });
  }
}

// Testimonial
export function* getTestimonialSaga() {
  try {
    const res = yield call(getTestimoni);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_TESTIMONIAL_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_TESTIMONIAL_FAILURE, err });
  }
}

// Setting Site
export function* getSettingSiteSaga() {
  try {
    const snapshot = yield call(getSetting);
    const data = snapshot.data();
    yield put({ type: GET_SETTING_SITE_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_SETTING_SITE_FAILURE, err });
  }
}

export function* getTopWriterSaga() {
  try {
    const snapshot = yield call(getTopWriters);
    const data = snapshot.data();
    yield put({ type: GET_TOP_WRITER_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_TOP_WRITER_FAILURE, err });
  }
}

export function* getArticlesSaga() {
  try {
    const res = yield call(getArticles);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_ARTICLES_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_ARTICLES_FAILURE, err });
  }
}

export function* getDetailArticleSaga(action) {
  try {
    const snapshot = yield call(getDetailArticles, action.id);
    const data = snapshot.data();
    yield put({ type: GET_DETAIL_ARTICLE_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_DETAIL_ARTICLE_FAILURE, err });
  }
}

export function* getAboutSaga() {
  try {
    const result = yield call(aboutUs);
    const data = result.data();
    yield put({ type: GET_ABOUT_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_ABOUT_FAILURE, err });
  }
}

export function* getTermSaga() {
  try {
    const result = yield call(termCondition);
    const data = result.data();
    yield put({ type: GET_TERM_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_TERM_FAILURE, err });
  }
}

export function* getPrivacySaga() {
  try {
    const result = yield call(privacyPolicy);
    const data = result.data();
    yield put({ type: GET_PRIVACY_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_PRIVACY_FAILURE, err });
  }
}

export function* getFaqSaga(action) {
  const { ok, data } = yield call(getFaq, action.query);
  if (ok) {
    yield put({ type: GET_FAQ_SUCCESS, data: data.data });
  } else {
    yield put({ type: GET_FAQ_FAILURE, data });
  }
}

export function* typeProductSaga() {
  try {
    const res = yield call(typeProduct);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: TYPE_PRODUCT_SUCCESS, data });
  } catch (err) {
    yield put({ type: TYPE_PRODUCT_FAILURE, err });
  }
}

export function* staticPageSaga(action) {
  try {
    const res = yield call(getStaticPage, action.slug);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_STATIC_PAGES_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_STATIC_PAGES_FAILURE, err });
  }
}
