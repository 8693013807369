import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";
// import ProductListModule from "../../../modules/Admin/Superadmin/ProductManagement/ProductListModule";

import { getProductAction } from "../../../../redux/action/serviceAction";

const ListTicketPMPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    titlePage({
      title: "IRIS - Manajemen Bantuan",
    });
    history.push("/admin/pm/list-ticket");
    const params = {
      page: 1,
      perpage: 10,
    };
    dispatch(getProductAction(params));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Bantuan" menu="ticket">
      <div />
    </DashboardLayout>
  );
};

export default ListTicketPMPage;
