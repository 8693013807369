/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

import { Card } from "primereact/card";

import CarouselAuthor from "../../../components/carousel/CarouselAuthor";
import CarouselSlide from "../../../components/carousel/Carousel";
// import CarouselTestimony from "../../../components/carousel/CarouselTestimony";

import ContentsLoader from "../../../components/loading/contentLoader";
import ButtonLink from "../../../components/button/ButtonLink";
import ButtonDefault from "../../../components/button/ButtonDefault";

const DEFAULT_IMAGE = process.env.REACT_APP_IMAGE_ARTICLE;

const LandingpageModule = (props) => {
  const [limitArticle, limitArticleChange] = useState(5);

  const {
    dataBanner,
    loadingBanner,
    dataHowDoesItWork,
    loadingHowDoesItWork,
    dataBenefit,
    loadingBenefit,
    loadingTestimonial,
    loadingTopWriter,
    dataArticle,
    dataTopWriter,
    // dataTestimonial,
  } = props;

  const articleTop = dataArticle
    ?.sort((a, b) => b?.createdAt - a?.createdAt)
    .slice(0, 3);

  const articleBottom = dataArticle
    ?.sort((a, b) => b?.createdAt - a?.createdAt)
    .slice(3, limitArticle);

  const cardHeader = (item) => {
    return (
      <div className="images">
        <img
          src={
            item?.cover || item?.cover !== null ? item?.cover : DEFAULT_IMAGE
          }
        />
      </div>
    );
  };

  const cardHeaderSm = (item) => {
    return (
      <div className="image-wrap">
        <img
          alt="Card"
          src={
            item?.cover || item?.cover !== null ? item?.cover : DEFAULT_IMAGE
          }
        />
      </div>
    );
  };

  const cardFooter = (item) => {
    return (
      <div className="p-d-flex p-jc-between p-ai-center">
        <span className="author">{item?.writerName}</span>
        <span className="date">
          {dayjs(item?.createdAt.toDate())
            .locale("id")
            .format("DD MMMM YYYY")}
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="section">
        {dataBanner?.length >= 2 ? (
          <CarouselSlide banners={dataBanner} loading={loadingBanner} />
        ) : (
          <div className="p-d-flex p-justify-center">
            {dataBanner?.slice(0, 1).map((banner) => (
              <div className="p-grid" key={banner?.title}>
                <div className="p-col-12 p-sm-12 p-md-5 p-lg-5">
                  <div className="hero-banner image">
                    <img src={banner?.image} alt="banner" />
                  </div>
                </div>
                <div className="p-col-12 p-sm-12 p-md-7 p-lg-7">
                  <div className="hero-banner">
                    <h1>{banner?.title}</h1>
                    <p>{banner?.description}</p>
                    {banner?.url && (
                      // eslint-disable-next-line jsx-a11y/anchor-is-valid
                      <a
                        href={banner?.url}
                        className="p-button p-button-primary"
                        // eslint-disable-next-line react/jsx-no-target-blank
                        target="_blank"
                      >
                        {banner?.buttonLabel || "Selengkapnya"}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      {loadingHowDoesItWork &&
      loadingBenefit &&
      loadingTestimonial &&
      loadingTopWriter ? (
        <div className="section about-section">
          <ContentsLoader />
        </div>
      ) : (
        <>
          <div className="section about-section">
            <div className="p-d-flex p-justify-center">
              <div className="p-grid p-justify-center">
                <div className="p-col-12 p-md-10">
                  <div className="p-text-center">
                    <h2 className="p-mb-0">{dataHowDoesItWork?.title}</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="section fiture">
            <div className="p-grid">
              {dataHowDoesItWork?.howdoesitworkitemResult?.map((item) => (
                <div className="col-lg-4 col-md-4 col-sm-6" key={item?.id}>
                  <div className="fiture-wrapper">
                    <div className="icon-wrapp">
                      <img src={item?.imageUrl} alt={item?.title} />
                    </div>
                    <h5>{item?.title}</h5>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="section join-action bg-primary">
            <div className="p-grid p-justify-center">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12">
                <div className="p-text-center">
                  <h2>{dataBenefit?.title}</h2>
                  <p>{dataBenefit?.content}</p>
                </div>
              </div>
              {dataBenefit?.benefitItemResult?.map((item) => (
                <div className="col-lg-3 col-md-3 col-sm-12 col-12">
                  <div className="benefit-box">
                    <div className="benefit-image">
                      <img src={item?.imageUrl} alt={item?.title} />
                    </div>
                    <p>{item?.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {dataTopWriter?.topWriterItemResult?.length >= 4 && (
            <div className="section author-section">
              <div className="p-d-flex p-justify-center">
                <div className="p-grid p-justify-center">
                  <div className="p-col-10">
                    <div className="p-text-center">
                      <h2>{dataTopWriter?.title}</h2>
                      <p>{dataTopWriter?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
              <CarouselAuthor topWriter={dataTopWriter?.topWriterItemResult} />
            </div>
          )}
          <div className="section article-section">
            <div className="p-d-flex p-justify-center">
              <div className="p-grid p-justify-center mb-5">
                <div className="p-col-12 p-md-10">
                  <div className="p-text-center">
                    <h2>Informasi Terbaru</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="p-d-flex p-justify-center">
              <div className="p-grid mb-2">
                {articleTop?.map((item) => (
                  <Link
                    to={`/article/${item?.id}`}
                    className="p-col-12 col-md-4 col-lg-14"
                    key={item?.id}
                  >
                    <Card
                      className="card-md card-article-column"
                      footer={cardFooter(item)}
                      header={cardHeader(item)}
                    >
                      <div className="p-card-title">
                        <div
                          className="title-text"
                          dangerouslySetInnerHTML={{
                            __html: `${item?.title}`,
                          }}
                        />
                      </div>
                    </Card>
                  </Link>
                ))}
              </div>
            </div>
            <div className="p-d-flex p-justify-center">
              <div className="p-grid mb-2">
                {articleBottom?.map((item, key) => (
                  <Link
                    key={key}
                    to={`/article/${item?.id}`}
                    className="col-lg-6 col-md-6 col-sm-12 col-xs-12"
                  >
                    <Card
                      footer={cardFooter(item)}
                      header={cardHeaderSm(item)}
                      className="card-sm mt-5 card-article-row"
                    >
                      <div className="p-card-title">
                        <Link to={`/article/${item?.id}`}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `${
                                item?.title?.length > 50
                                  ? `${item?.title?.substring(0, 50)}...`
                                  : item?.title
                              }`,
                            }}
                          />
                        </Link>
                      </div>
                    </Card>
                  </Link>
                ))}
              </div>
            </div>
            <div className="p-d-flex p-justify-center">
              {limitArticle < dataArticle?.length && (
                <ButtonDefault
                  label="Lihat Lebih Banyak"
                  onClick={() => limitArticleChange(limitArticle + 10)}
                  className="p-button mt-5"
                  type="rounded-bordered"
                />
              )}
            </div>
          </div>
          <div className="bottom-section">
            <h2>Siap Meningkatkan Citra Perusahaan?</h2>
            <ButtonLink
              link="/create-account"
              type="rounded"
              label="Daftar Gratis"
            />
          </div>

          {/* <div className="section testimony-section">
            <CarouselTestimony testimonial={dataTestimonial} />
          </div> */}
        </>
      )}
    </>
  );
};

export default LandingpageModule;
