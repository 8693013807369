/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import md5 from "crypto-js/md5";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import ProjectDetailModule from "../../modules/User/Project/ProjectDetailModule";

import { getDetailProjectAction, getLogArticleAction, getMyProjectAction } from "../../../redux/action/projectAction";
import { titlePage } from "../../helpers/titleHead";

const ProjectDetail = (props) => {
  const dispatch = useDispatch();
  const QueryParams = useParams();
  const { users } = useSelector((state) => state.auth);
  const hashId = md5(QueryParams.projectId + users?.uid);


  useEffect(() => {
    dispatch(getDetailProjectAction(QueryParams.projectId));
    dispatch(getMyProjectAction(users?.uid));
    dispatch(getLogArticleAction(hashId.toString()));
    titlePage({
      title: "IRIS - Project Detail",
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardUserLayout>
      <ProjectDetailModule projectId={QueryParams.projectId} />
    </DashboardUserLayout>
  );
};

export default ProjectDetail;
