import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import DashboardModule from "../../modules/User/Dashboard/DashboardModule";

import { getMyProjectAction } from "../../../redux/action/projectAction";
import { announcementsAction } from "../../../redux/action/utilsAction";
import { titlePage } from "../../helpers/titleHead";

const DashboardUser = () => {
  const dispatch = useDispatch();
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    dispatch(getMyProjectAction(users?.uid));
    dispatch(announcementsAction());

    titlePage({
      title: "IRIS - Dashboard",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardUserLayout>
      <DashboardModule />
    </DashboardUserLayout>
  );
};

export default DashboardUser;
