import React from "react";
import { useHistory } from "react-router-dom";

function ServiceListCard(props) {
  const { item } = props;
  const history = useHistory();
  return (
    <div className="service-cards" key={item._id}>
      <div className="image-cards">
        <img
          src={item?.image || process.env.REACT_APP_IMAGE_ARTICLE}
          alt="empty"
        />
      </div>
      <div className="cards-content">
        <div className="content-title">{item?.name}</div>
        <div className="desc-card">
          {item?.description?.length > 80
            ? `${item?.description.slice(0, 80)}...`
            : item?.description}
        </div>
        <div className="service-card-footer w-100">
          <button
            className="p-button p-button-primary"
            onClick={() => history.push(`/client/create-brief/${item._id}`)}
          >
            Create Project
          </button>
        </div>
      </div>
    </div>
  );
}

export default ServiceListCard;
