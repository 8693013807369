export const GET_LIST_INFO_REQUEST = "GET_LIST_INFO_REQUEST";
export const GET_LIST_INFO_SUCCESS = "GET_LIST_INFO_SUCCESS";
export const GET_LIST_INFO_FAILURE = "GET_LIST_INFO_FAILURE";

export const POST_INFO_REQUEST = "POST_INFO_REQUEST";
export const POST_INFO_SUCCESS = "POST_INFO_SUCCESS";
export const POST_INFO_FAILURE = "POST_INFO_FAILURE";

export const GET_DETAIL_INFO_REQUEST = "GET_DETAIL_INFO_REQUEST";
export const GET_DETAIL_INFO_SUCCESS = "GET_DETAIL_INFO_SUCCESS";
export const GET_DETAIL_INFO_FAILURE = "GET_DETAIL_INFO_FAILURE";

export const UPDATE_INFO_REQUEST = "UPDATE_INFO_REQUEST";
export const UPDATE_INFO_SUCCESS = "UPDATE_INFO_SUCCESS";
export const UPDATE_INFO_FAILURE = "UPDATE_INFO_FAILURE";

export const DELETE_INFO_REQUEST = "DELETE_INFO_REQUEST";
export const DELETE_INFO_SUCCESS = "DELETE_INFO_SUCCESS";
export const DELETE_INFO_FAILURE = "DELETE_INFO_FAILURE";

export const RESET_STATE_ADMIN = "RESET_STATE_ADMIN";
