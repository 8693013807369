import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router";

import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import DetailMyReleaseModule from "../../modules/Client/Release/DetailMyReleaseModule";

import {
  getOrderDetailAction,
  orderClientDetailAction,
} from "../../../redux/action/orderAction";
import { clientDataProjectAction } from "../../../redux/action/utilsAction";

function DetailMyReleasePage() {
  const dispatch = useDispatch();
  const queryParams = useParams();

  useEffect(() => {
    titlePage({
      title: "IRIS - Pesanan",
    });
    dispatch(getOrderDetailAction(queryParams.projectId));
    dispatch(orderClientDetailAction(queryParams.projectId));
    dispatch(clientDataProjectAction(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <DashboardClientLayout title="Detail Release" menu="project">
      <DetailMyReleaseModule projectId={queryParams.projectId} />
    </DashboardClientLayout>
  );
}

export default DetailMyReleasePage;
