import React from "react";
import ContentLoader from "react-content-loader";

const ChatLoader = (props) => {
  return (
    <ContentLoader
      viewBox="0 0 400 160"
      height={160}
      width={"100%"}
      backgroundColor="#c7c7c7"
      foregroundColor="#fafafa"
      backgroundOpacity={0.6}
      foregroundOpacity={0.8}
      {...props}
    >
      <rect x="0" y="12" rx="5" ry="5" width="220" height="10" />
      <rect x="0" y="29" rx="5" ry="5" width="220" height="10" />
      <rect x="179" y="76" rx="5" ry="5" width="220" height="10" />
      <rect x="179" y="58" rx="5" ry="5" width="220" height="10" />
    </ContentLoader>
  );
};

export default ChatLoader;
