/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import NumberFormat from "react-number-format";
import * as dayjs from "dayjs";
import "dayjs/locale/id";
import md5 from "crypto-js/md5";

import { Button } from "primereact/button";
import { DetailPageLoad } from "../../../components/loading/loadingContent";
import ProjectDetailAccordion from "../../../components/accordion/ProjectDetailAccordion";

import DialogAlert from "../../../components/dialog/DialogAlert";
import DialogReview from "./Components/PopupReview";

import CoinImage from "../../../assets/img/icon-coin.svg";
import ClockImage from "../../../assets/img/icon-clock.svg";
import WinnerImage from "../../../assets/img/winner.svg";
import DocImage from "../../../assets/img/document_icon.svg";
import {
  joinProjectAction,
  resetStateProjectAction,
  submitArticleAction,
  getArticleAction,
} from "../../../../redux/action/projectAction";
import StatusBadge from "../../../components/badge/StatusBadge";
import ButtonLoader from "../../../components/button/ButtonLoader";

const ProjectDetailModule = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const {
    dataGetArticle,
    dataGetDetailProject,
    loadingGetDetailProject,
    successSubmitArticleProject,
    errSubmitArticleProject,
    loadingSubmitArticleProject,
    loadingJoinProject,
    successJoinProject,
    dataLogArticle,
    errJoinProject,
  } = useSelector((state) => state.project);

  const { users } = useSelector((state) => state.auth);

  const [openReview, setOpenReview] = useState(false);
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogError, setDialogError] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [dialogConfirm, setDialogConfirm] = useState(false);
  const [dialogErrorText, setDialogErrorText] = useState({
    title: "",
    text: "",
  });

  const isJoined = dataGetDetailProject?.writerJoinedIds?.find(
    (item) => item === users?.uid
  );

  const deadline = new Date(dataGetDetailProject?.deadlineDate?.toDate());
  const isExpired =
    deadline < new Date() ||
    dataGetDetailProject?.isDone ||
    dataGetDetailProject?.status === "done";

  useEffect(() => {
    if (successJoinProject) {
      dispatch(resetStateProjectAction());
      history.push(`/my-project/${props.projectId}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successJoinProject]);

  useEffect(() => {
    if (errJoinProject) {
      if (errJoinProject?.message === "Winner Already Full") {
        setDialogError(true);
        setDialogErrorText({
          title: "Gagal Bergabung Project",
          text: "Kuota penulis terpenuhi",
        });
      } else {
        setDialogError(true);
        setDialogErrorText({
          title: "Gagal Bergabung Project",
          text: errJoinProject?.message,
        });
      }
    }
  }, [errJoinProject]);

  useEffect(() => {
    if (successSubmitArticleProject) {
      setDialogWarning(false);
      setDialogSuccess(true);
    }
  }, [successSubmitArticleProject]);

  useEffect(() => {
    if (errSubmitArticleProject) {
      setDialogWarning(false);
      setDialogError(true);
      setDialogErrorText({
        title: "Gagal Mengirimkan Artikel",
        text: "Deadline project sudah terlewati",
      });
    }
  }, [errSubmitArticleProject]);
  const submit = (e) => {
    e.preventDefault();
    if (isExpired) {
      setDialogError(true);
      setDialogErrorText({
        title: "Deadline project sudah berakhir",
        text: "",
      });
    } else {
      dispatch(joinProjectAction(props.projectId));
    }
  };

  const handlesubmit = (e) => {
    e.preventDefault();
    const data = {
      articleTitle: dataGetArticle.articleTitle,
      articleContent: dataGetArticle.articleContent,
      articleImage: dataGetArticle.articleImage,
    };
    dispatch(submitArticleAction(props?.hashId, data));
  };

  const closeSuccess = () => {
    setDialogSuccess(false);
    dispatch(resetStateProjectAction());
  };

  const closeError = () => {
    setDialogError(false);
    setDialogConfirm(false);
    dispatch(resetStateProjectAction());
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const hashId = md5(props.projectId + users?.uid);
  useEffect(() => {
    if (users?.uid) {
      dispatch(getArticleAction(hashId.toString()));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users?.uid]);

  const latestActivity =
    dataLogArticle && dataLogArticle[dataLogArticle?.length - 1];

  const projectStatus = (data) => {
    const date = new Date();
    const yesterday = date.setDate(date.getDate() - 1);
    const newState = [
      dayjs(yesterday).format("DD MM YYYY"),
      dayjs().format("DD MM YYYY"),
    ];
    const isNew = newState.includes(
      dayjs(data?.createdAt?.toDate()).format("DD MM YYYY")
    );
    const deadline = new Date(data?.deadlineDate.toDate());
    const isExpired =
      deadline < new Date() || data?.isDone || data?.status === "done";
    let isVisible;
    let state;
    if (isNew && !isExpired) {
      state = isJoined ? "join" : "new";
      isVisible = true;
    } else if (isJoined) {
      state = "join";
      isVisible = true;
    } else if (isNew && isExpired) {
      state = "done";
      isVisible = true;
    } else if (isExpired) {
      state = "done";
      isVisible = true;
    } else {
      state = data.status;
      isVisible = false;
    }

    return isVisible && <StatusBadge status={state} user="writer" />;
  };

  return (
    <>
      <div className="p-d-flex p-justify-center project-detail">
        <div className="p-grid">
          <div className="p-col-12">
            <div className="box-content box-project box-write p-mb-4 p-mt-4">
              {loadingGetDetailProject ? (
                <div className="write-detail">
                  <DetailPageLoad />
                </div>
              ) : (
                <div className="write-wrapper">
                  <div className="attr-write">
                    <div className="card-title">
                      <div
                        className="card-title-back"
                        onClick={() => history.push("/project")}
                      >
                        <span className="icon-back"></span>
                      </div>
                      <h2 className="card-title-text large">Detail Project</h2>
                    </div>
                    <div>
                      <div className="p-d-flex p-jc-end">
                        {projectStatus(dataGetDetailProject)}
                      </div>
                      {latestActivity && (
                        <div className="history-list">
                          <strong>
                            {dayjs(latestActivity?.date?.toDate())
                              .locale("id")
                              .format("DD MMMM YYYY HH:mm")}
                          </strong>{" "}
                          - {latestActivity?.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="write-detail detail p-mb-0">
                    <div className="summary">
                      <div className="d-flex flex-row bd-highlight mb-3">
                        <div className="mr-auto p-2 bd-highlight author-wrapp">
                          <div className="pict detail">
                            <img
                              src={dataGetDetailProject?.thumbnail}
                              alt="photo"
                            />
                          </div>
                          <div className="desc mb-6">
                            <h3>{dataGetDetailProject?.title}</h3>
                            <h6>
                              Nomor Pekerjaan: {dataGetDetailProject?.jobNumber}
                            </h6>
                            <div className="project-info-wrapp">
                              <div className="info-row">
                                <div className="info">
                                  <img src={CoinImage} alt="" />
                                  <NumberFormat
                                    prefix={"Rp "}
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    displayType="text"
                                    value={dataGetDetailProject?.price}
                                    renderText={(value) => <p>{value}</p>}
                                  />
                                </div>
                                <div className="info">
                                  <img src={ClockImage} alt="" />
                                  <p>
                                    {`Deadline ${dayjs(
                                      dataGetDetailProject?.deadlineDate?.toDate()
                                    )
                                      .locale("id")
                                      .format("DD MMMM YYYY")}`}
                                  </p>
                                </div>
                              </div>
                              <div className="info-row">
                                <div className="info">
                                  <img src={DocImage} alt="" />
                                  <p>
                                    {dataGetDetailProject?.type === "pr"
                                      ? "Press Release"
                                      : "Artikel"}
                                  </p>
                                </div>
                                <div className="info">
                                  <img src={WinnerImage} alt="" />
                                  <NumberFormat
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    displayType="text"
                                    value={dataGetDetailProject?.winnerQuota}
                                    renderText={(value) => (
                                      <p>{`${value} Quota Tulisan`}</p>
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p>{dataGetDetailProject?.description}</p>
                      <div className="p-d-flex p-jc-end p-ai-center footer-attr">
                        <span>
                          {`Diposting ${dayjs(
                            dataGetDetailProject?.createdAt.toDate()
                          )
                            .locale("id")
                            .format("DD MMMM YYYY")}`}
                        </span>
                      </div>
                      <div className="action-wrapper">
                        <div className="action-row">
                          {isJoined && (
                            <div className="article-status">
                              <p>Status Tulisan:</p>
                              <StatusBadge
                                status={dataGetArticle?.status}
                                user="writer"
                              />
                            </div>
                          )}
                        </div>
                        <div className="action-row right">
                          {isJoined && (
                            <Link
                              to={`/my-project/${dataGetArticle?.projectId}/article`}
                              className="btn btn-preview"
                            >
                              <span className="icon-show-blue"></span>
                              Lihat Artikel
                            </Link>
                          )}
                          {!isJoined && !isExpired ? (
                            loadingJoinProject ? (
                              <ButtonLoader />
                            ) : (
                              <Button
                                className="p-button-rounded p-text-uppercase ml-3 p-button-primary"
                                label={"Gabung Project"}
                                onClick={() => setDialogConfirm(true)}
                                disabled={isJoined}
                              />
                            )
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <ProjectDetailAccordion
                    activeIndex={activeIndex}
                    onTabChange={(e) => setActiveIndex(e.index)}
                    dataProject={dataGetDetailProject}
                  />
                </div>
              )}
              <DialogReview
                dialogReview={openReview}
                closeReview={() => setOpenReview(false)}
              />
              <DialogAlert
                type={"warning"}
                actionDialog={handlesubmit}
                actionText={"KIRIM"}
                visible={dialogWarning}
                closeDialog={() => setDialogWarning(false)}
                title={"Kirim Artikel?"}
                text={"Pastikan artikel anda sudah sesuai"}
                loading={loadingSubmitArticleProject}
              />
              <DialogAlert
                type={"error"}
                visible={dialogError}
                closeDialog={() => setDialogError(false)}
                title={"Preview Belum Tersedia"}
                text={"Silakan membuat artikel atau konten terlebih dahulu"}
              />
              <DialogAlert
                type={"error"}
                visible={dialogError}
                closeDialog={() => setDialogError(false)}
                title={dialogErrorText.title}
                text={dialogErrorText.text}
              />
              <DialogAlert
                type={"success"}
                visible={dialogSuccess}
                closeDialog={() => closeSuccess()}
                title={"Berhasil Mengirimkan Artikel"}
                text={"Artikel berhasil dikirim dan akan segera direview"}
              />
            </div>
          </div>
        </div>
        <DialogReview
          dialogReview={openReview}
          closeReview={() => setOpenReview(false)}
        />
        <DialogAlert
          type={"error"}
          visible={dialogError}
          closeDialog={closeError}
          title={dialogErrorText.title}
          text={dialogErrorText.text}
        />
      </div>
      <DialogAlert
        type={"warning"}
        actionDialog={submit}
        actionText={"YA"}
        cancelText={"TIDAK"}
        visible={dialogConfirm}
        closeDialog={() => setDialogConfirm(false)}
        title={"Yakin Ingin Bergabung?"}
        text={"Minimal tulisan 300 kata agar dapat disubmit"}
        loading={loadingJoinProject}
      />
    </>
  );
};

export default ProjectDetailModule;
