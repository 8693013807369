import React from "react";
import Slider from "react-slick";

const CarouselTestimony = (props) => {
  const showSlide = props?.testimonial?.length < 4 ? props?.testimonial?.length : 4;
  const showSlideMd = props?.testimonial?.length < 3 ? props?.testimonial?.length : 3;
  const showSlideXs = props?.testimonial?.length < 2 ? props?.testimonial?.length : 2;
  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    slidesToShow: showSlide,
    slidesToScroll: 1,
    slidesPerRow: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: showSlideMd,
          slidesToScroll: showSlideMd,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: showSlideXs,
          slidesToScroll: showSlideXs,
          initialSlide: showSlideXs,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carouser-wrapper">
      <Slider {...settings}>
        {props?.testimonial?.map((item) => (
          <div className="carousel-item" key={item?.id}>
            <div className="testimony-wrapper">
              <div className="testimony-info p-d-flex p-jc-center p-ai-center">
                <div className="testimony-wrapp">
                  <div className="pict-wrapper">
                    <div className="pict">
                      <img src={item?.image} alt="pict" />
                    </div>
                  </div>
                  <p>{item?.content}</p>
                  <h3>{item?.name}</h3>
                  <span>{item?.occupation}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CarouselTestimony;
