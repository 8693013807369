import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import UserDetailModule from "../../../modules/Admin/UserPM/UserDetailModule";
import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import { detailUserAction } from "../../../../redux/action/userAction";

const UserDetail = () => {
  const dispatch = useDispatch();
  const QueryParam = useParams();
  useEffect(() => {
    dispatch(detailUserAction(QueryParam.userId));

    titlePage({
      title: "IRIS - Detail Penulis",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Penulis">
      <UserDetailModule userId={QueryParam.userId} />
    </DashboardLayout>
  );
};

export default UserDetail;
