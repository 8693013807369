import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import { titlePage } from "../../helpers/titleHead";
import { orderClientDetailAction } from "../../../redux/action/orderAction";
import {
  clientDataProjectAction,
  getAgeAction,
  getGenderAction,
  getCategoryReleaseAction,
  getSesAction,
  getStakeholderAction,
} from "../../../redux/action/utilsAction";
import { detailClientAction } from "../../../redux/action/clientAction";
import { getProductDetailAction } from "../../../redux/action/serviceAction";
import IdeationProject from "../../modules/Client/Ideation/IdeationModule";
import { resetProjectDetailData, resetStateProjectAction } from "../../../redux/action/projectAction";

function ClientCreateIdeation() {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const { dataDetailOrderClient } = useSelector((state) => state.order);
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    titlePage({
      title: "IRIS - Ideation",
    });
    dispatch(getProductDetailAction(queryParams.productId))
    dispatch(orderClientDetailAction(queryParams.projectId));
    dispatch(resetStateProjectAction());
    dispatch(resetProjectDetailData());
    dispatch(getAgeAction());
    dispatch(getGenderAction());
    dispatch(getCategoryReleaseAction());
    dispatch(getSesAction());
    dispatch(getStakeholderAction());
    if (users) {
      dispatch(detailClientAction(users?.user?.companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  useEffect(() => {
    if (dataDetailOrderClient) {
      const data = {
        title: dataDetailOrderClient?.productName,
        category: null,
        type: dataDetailOrderClient?.productCategory,
        storyAngles: "",
        ideationFeature: "",
        thumbnail: null,
        quota: dataDetailOrderClient?.quantity,
        desc: "",
        deadline: null,
        releaseCategory: null,
        releaseCategoryQuestion: "",
        objective: "",
        attribution: "",
        releaseNote: "",
        target: {
          sex: null,
          age: null,
          ses: null,
          stakeholders: [],
        },
        keyword: "",
      };
      dispatch(clientDataProjectAction(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailOrderClient]);

  return (
    <DashboardClientLayout>
      <IdeationProject projectId={queryParams.productId} />
    </DashboardClientLayout>
  );
}

export default ClientCreateIdeation;
