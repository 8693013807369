import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import WriteArticleModule from "../../modules/User/WriteArticle/WriteArticleModule";

import { getArticleAction } from "../../../redux/action/projectAction";
import { resetUtilsStateAction } from "../../../redux/action/utilsAction";
import { titlePage } from "../../helpers/titleHead";

const WritePageUser = (props) => {
  const dispatch = useDispatch();
  const QueryParams = useParams();

  useEffect(() => {
    dispatch(getArticleAction(QueryParams.projectId));
    dispatch(resetUtilsStateAction());

    titlePage({
      title: "IRIS - Tulis Artikel",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardUserLayout>
      <div className="p-d-flex p-justify-center write-article-wrapper">
        <div className="p-grid">
          <div className="p-col p-justify-center">
            <div className="box-content box-write writing-wrapper p-mt-4 p-mb-4">
              <WriteArticleModule />
            </div>
          </div>
        </div>
      </div>
    </DashboardUserLayout>
  );
};

export default WritePageUser;
