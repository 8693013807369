/* eslint-disable no-unused-expressions */
import React from "react";

function ResponsiveTable({ value, children, mobileTitle }) {
  let rows = [];
  if (value && value[0] !== value[1]) {
    value?.unshift(value[0])
  }
  for (let i = 0; i < children?.length; i++) {
    for (let j = 0; j < value?.length; j++) {
      rows.push({
        ...children[i],
        props: { ...children[i].props, data: value[j], index: j },
      });
    }
  }

  const mobileTitleChild = children?.find(child => child?.props?.header === mobileTitle)
  const mobileChildren = children?.filter(child => child?.props?.header !== mobileTitle)
  mobileChildren?.unshift(mobileTitleChild)

  return (
    <div>
      <div className="table-large">
        <div className="p-component p-datatable">
          <div className="p-datatable-wrapper">
            <table role="grid">
              <thead className="p-datatable-thead">
                <tr>
                  {rows
                    ?.filter((row) => row?.props?.index === 0)
                    ?.map((row, key) => (
                      <th key={key} className={row?.props?.headerClassName}>
                        {row}
                      </th>
                    ))}
                </tr>
              </thead>
              <tbody className="p-datatable-tbody">
                {value?.map((item, idx) => (
                  <tr key={idx}>
                    {rows
                      ?.filter(
                        (row) =>
                          row?.props?.index === idx && row?.props?.index !== 0
                      )
                      ?.map((row, key) => (
                        <td key={key}>{row}</td>
                      ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="mobile-table">
        {value?.slice(1).map((item, keyValue) => (
          <div key={keyValue} className="mobile-table-item row p-jc-between">
            {mobileChildren?.map((child, keyChildren) => {
              return child?.props?.header === mobileTitle ? (
                <div key={`${keyValue}-${keyChildren}`} className="col-12">
                  <h3>{child?.props?.body(item)}</h3>
                </div>
              ) : (
                <div key={`${keyValue}-${keyChildren}`} className="col-6">
                  <label>{child?.props?.header}</label>
                  <div>{child?.props?.body(item)}</div>
                </div>
              );
            })}
          </div>
        ))}
      </div>
    </div>
  );
}

export default ResponsiveTable;
