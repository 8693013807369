import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";
import CreateProjectModule from "../../modules/Client/CreateProject/CreateProjectModule";
import { titlePage } from "../../helpers/titleHead";
import {
  clientDataProjectAction,
  getAgeAction,
  getGenderAction,
  getCategoryReleaseAction,
  getSesAction,
  getStakeholderAction,
} from "../../../redux/action/utilsAction";
import { detailClientAction } from "../../../redux/action/clientAction";
import { getProjectDetailClientAction } from "../../../redux/action/projectAction";

function ClientUpdateProject() {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const { users } = useSelector((state) => state.auth);

  useEffect(() => {
    titlePage({
      title: "IRIS - Update Project",
    });
    dispatch(getProjectDetailClientAction(queryParams.projectId));
    dispatch(getAgeAction());
    dispatch(getGenderAction());
    dispatch(getCategoryReleaseAction());
    dispatch(getSesAction());
    dispatch(getStakeholderAction());
    if (users) {
      dispatch(detailClientAction(users?.user?.companyId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const {
    dataGetProjectDetailClient
  } = useSelector((state) => state.project);

  console.log(dataGetProjectDetailClient)

  useEffect(() => {
    if (dataGetProjectDetailClient) {
      const data = {
        title: dataGetProjectDetailClient?.title,
        category: dataGetProjectDetailClient?.category,
        type: dataGetProjectDetailClient?.type,
        storyAngles: dataGetProjectDetailClient?.storyAngles,
        ideationFeature: dataGetProjectDetailClient?.ideationFeature,
        // thumbnail: null,
        // quota: dataGetProjectDetailClient?.quota,
        desc: dataGetProjectDetailClient?.description,
        deadline: dataGetProjectDetailClient?.deadlineDate,
        releaseCategory: dataGetProjectDetailClient?.releaseCategory,
        objective: dataGetProjectDetailClient?.objective,
        attribution: dataGetProjectDetailClient?.attribution ? dataGetProjectDetailClient?.attribution : "",
        releaseNote: dataGetProjectDetailClient?.releaseNote ? dataGetProjectDetailClient?.releaseNote : "",
        target: {
          sex: dataGetProjectDetailClient?.target?.sex,
          age: dataGetProjectDetailClient?.target?.age,
          ses: dataGetProjectDetailClient?.target?.ses,
          stakeholders: dataGetProjectDetailClient?.target?.stakeholders,
        },
        keyword: dataGetProjectDetailClient?.searchKeyword,
      };
      dispatch(clientDataProjectAction(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataGetProjectDetailClient]);

  return (
    <DashboardClientLayout title="Update Brief" menu="product">
      <CreateProjectModule projectId={queryParams.projectId} />
    </DashboardClientLayout>
  );
}

export default ClientUpdateProject;
