/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ActionBody from "../../../../components/datatableBody/ActionBody";
import TextBody from "../../../../components/datatableBody/TextBody";
import DataEmpty from "../../../../components/emptyState/dataEmpty";
import TableLoader from "../../../../components/loading/tableLoader";
import AdminPaginate from "../../../../components/paging/pagination";
import DialogAlert from "../../../../components/dialog/DialogAlert";

import {
  getClientAction,
  deleteClientAction,
  resetClientStateAction,
} from "../../../../../redux/action/clientAction";
import FilterCompany from "./Components/FilterCompany";

const CompanyManagementModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogSuccess, setDialogSuccess] = useState(false);
  const {
    dataGetClient,
    loadingGetClient,
    totalPage,
    loadingDeleteClient,
    successDeleteClient,
  } = useSelector((state) => state.client);

  useEffect(() => {
    if (successDeleteClient) {
      setTimeout(() => {
        const params = {
          q: "",
          page: 1,
          perpage: 10,
        };
        dispatch(getClientAction(params));
        dispatch(resetClientStateAction());
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successDeleteClient]);

  const nameBodyTemplate = (item) => {
    return (
      <TextBody
        text={item.companyName}
        link={`/admin/pm/company-detail/${item?._id}`}
      />
    );
  };

  const backgroundBodyTemplate = (item) => {
    return (
      <TextBody
        text={
          item?.companyBackground?.length > 30
            ? `${item?.companyBackground?.substring(0, 30)}...`
            : item?.companyBackground
        }
        link={`/admin/pm/company-detail/${item?._id}`}
      />
    );
  };

  const emailBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.companyEmail !== "-" ? item?.companyEmail : "Belum diatur"}
        link={`/admin/pm/company-detail/${item?._id}`}
      />
    );
  };

  const fieldBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.businessField}
        link={`/admin/pm/company-detail/${item?._id}`}
      />
    );
  };

  const phoneBodyTemplate = (item) => {
    return (
      <TextBody
        text={item?.companyPhone?.replace("+62", "0") || "Belum diatur"}
        link={`/admin/pm/company-detail/${item?._id}`}
      />
    );
  };

  const actionBodyTemplate = (item) => {
    return (
      <div className="p-d-flex p-jc-end">
        <ActionBody
          linkDetail={`/admin/pm/company-detail/${item?._id}`}
          linkEdit={`/admin/pm/edit-company/${item?._id}`}
          id={item?._id}
          name={item?.companyName}
          onDelete={() => deleteUser(item?._id)}
          loading={loadingDeleteClient}
          isSuccess={successDeleteClient}
          deleteOnly
        />
      </div>
    );
  };

  const deleteUser = (id) => {
    dispatch(deleteClientAction(id));
  };

  const closeSuccess = () => {
    dispatch(resetClientStateAction());
    setDialogSuccess(false);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search || "",
      page: page + 1,
      perpage: 10,
    };
    history.push(
      `/admin/pm/company-list?search=${parsed.search || ""}&page=${page + 1}`
    );
    dispatch(getClientAction(params));
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Perusahaan
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterCompany />
          {loadingGetClient ? (
            <TableLoader />
          ) : (
            <>
              {dataGetClient?.length < 1 || dataGetClient === null ? (
                <DataEmpty />
              ) : (
                <>
                  <DataTable value={dataGetClient} className="no-padding">
                    <Column
                      header="Nama Perusahaan"
                      body={nameBodyTemplate}
                    ></Column>
                    <Column
                      header="Email Perusahaan"
                      body={emailBodyTemplate}
                      field="commpanyEmail"
                    ></Column>
                    <Column
                      header="No. Telepon Perusahaan"
                      body={phoneBodyTemplate}
                      field="nohp"
                    ></Column>
                    <Column
                      header="Detail Perusahaan"
                      body={backgroundBodyTemplate}
                      field="background"
                    ></Column>
                    <Column
                      header="Bidang Usaha"
                      body={fieldBodyTemplate}
                      field="field"
                    ></Column>
                    <Column
                      headerClassName="action"
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Kirim Ulang"}
        text={"Tautan berhasil dikirim ke email user"}
      />
    </>
  );
};

export default CompanyManagementModule;
