/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import "dayjs/locale/id";

import { Card } from "primereact/card";
import FullContent from "../../../components/loading/fullContentLoader";

const DEFAULT_IMAGE = process.env.REACT_APP_IMAGE_USER;

const DetailArticleModule = () => {
  const {
    dataGetDetailArticle,
    loadingGetDetailArticle,
    dataGetArticles,
  } = useSelector((state) => state.landingpage);

  const articles = dataGetArticles
    ?.sort((a, b) => b?.createdAt - a?.createdAt)
    .slice(0, 3);

  const cardHeader = (item) => {
    return (
      <div className="images">
        <img
          src={
            item?.cover || item?.cover !== null ? item?.cover : DEFAULT_IMAGE
          }
        />
      </div>
    );
  };

  const cardFooter = (item) => {
    return (
      <div className="p-d-flex p-jc-between p-ai-center">
        <span className="author">{item?.writerName}</span>
        <span className="date">
          {dayjs(item?.createdAt.toDate())
            .locale("id")
            .format("DD MMMM YYYY")}
        </span>
      </div>
    );
  };

  return (
    <div className="section article-view">
      <div className="article-view-card">
        {loadingGetDetailArticle ? (
          <>
            <FullContent />
            <FullContent />
            <FullContent />
          </>
        ) : (
          <>
            <div className="article-view-header">
              <div className="article-view-title">
                <h1>{dataGetDetailArticle?.title}</h1>
              </div>

              <div className="article-view-about">
                <div className="writer-name">
                  <div className="pict">
                    <img
                      src={
                        dataGetDetailArticle?.writerIcon
                          ? dataGetDetailArticle?.writerIcon
                          : DEFAULT_IMAGE
                      }
                      alt="profile"
                    />
                  </div>
                  <div className="name">{dataGetDetailArticle?.writerName}</div>
                </div>
                <div className="hr-article"></div>
                <div className="publish-date">
                  Dipublikasikan tanggal{" "}
                  {dayjs(dataGetDetailArticle?.createdAt.toDate())
                    .locale("id")
                    .format("DD MMMM YYYY")}
                </div>
              </div>
            </div>
            <div className="article-view-body">
              {dataGetDetailArticle?.cover && (
                <div className="article-view-cover">
                  <img src={dataGetDetailArticle?.cover} alt="photo-article" />
                </div>
              )}
              <div
                className="article-view-content"
                dangerouslySetInnerHTML={{
                  __html: `${dataGetDetailArticle?.content}`,
                }}
              />
            </div>
          </>
        )}
      </div>
      <div className="article-view-list">
        <div className="title-list">PILIHAN ARTIKEL LAINNYA</div>
        <div className="article-view-list-wrapp">
          <div className="p-grid mb-2">
            {articles?.map((item) => (
              <Link
                to={`/article/${item?.id}`}
                className="p-col-12 col-md-4 col-lg-14"
                key={item?.id}
              >
                <Card
                  className="card-md card-article-column"
                  footer={cardFooter(item)}
                  header={cardHeader(item)}
                >
                  <div className="p-card-title">
                    <div
                      className="title-text"
                      dangerouslySetInnerHTML={{
                        __html: `${item?.title}`,
                      }}
                    />
                  </div>
                </Card>
              </Link>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailArticleModule;
