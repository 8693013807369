import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import NumberFormat from "react-number-format";

import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";

import ButtonDefault from "../../../components/button/ButtonDefault";
import DialogAlert from "../../../components/dialog/DialogAlert";
import FullContentLoader from "../../../components/loading/fullContentLoader";

import {
  postOrderClientAction,
  resetOrderStateAction,
} from "../../../../redux/action/orderAction";

function PaymentConfirmationModule() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [isShowHint, setIsShowHint] = useState(true);
  const [loadingState, setLoadingState] = useState(false);
  const [qty, setQty] = useState({ value: 1, error: null });
  const [dialogAlert, setDialogAlert] = useState({
    type: "",
    dialog: false,
    title: "",
    text: "",
    loading: false,
  });

  const { dataDetailProduct, loadingDetailProduct } = useSelector(
    (state) => state.service
  );
  const { users } = useSelector((state) => state.auth);
  const { dataDetailClient } = useSelector((state) => state.client);
  const { successPostOrderClient, dataPostOrderClient } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    if (successPostOrderClient) {
      setTimeout(() => {
        setLoadingState(false);
        setDialogAlert({
          type: "success",
          dialog: true,
          title: "PESANAN BERHASIL DIBUAT",
          text: "Invoice anda berhasil dibuat",
          actionDialog: () => openInvoice(),
          actionText: "Lihat Invoice",
          loading: false,
        });
      }, 750);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successPostOrderClient]);

  const closeDialog = () => {
    dispatch(resetOrderStateAction());
    if (dialogAlert.type === "success") {
      setDialogAlert({
        type: "",
        dialog: false,
        title: "",
        text: "",
        loading: false,
      });
      history.push(`/client/my-order-process`);
    } else {
      setDialogAlert({
        type: "",
        dialog: false,
        title: "",
        text: "",
        loading: false,
      });
    }
  };

  const openInvoice = () => {
    setDialogAlert({ type: "", dialog: false, title: "", text: "" });
    dispatch(resetOrderStateAction());
    history.push(`/client/invoice/${dataPostOrderClient.uid}`);
  };

  let categoryName;
  if (dataDetailProduct?.category === "pr") {
    categoryName = "Press Release";
  } else if (dataDetailProduct?.category === "distribution_media") {
    categoryName = "Media Distribusi";
  } else {
    categoryName = "Artikel";
  }

  const submitOrder = () => {
    if (qty.value < 1) {
      setQty({ ...qty, error: `Jumlah minimal pesanan 1 ${categoryName}` });
    } else if (qty.value > 100) {
      setQty({ ...qty, error: `Jumlah maksimal pesanan 100 ${categoryName}` });
    } else if (!qty.value) {
      setQty({ ...qty, error: `Masukan jumlah pesanan ${categoryName}` });
    } else {
      setDialogAlert({
        type: "warning",
        dialog: true,
        title: "PESANAN SUDAH BENAR?",
        text: "Silahkan pilih pesan jika pesanan anda sudah benar",
        actionDialog: () => orderProducts(),
        actionText: "Pesan",
        loading: loadingState,
      });
    }
  };

  const orderProducts = () => {
    const paymentDeadline = new Date();
    paymentDeadline.setDate(paymentDeadline.getDate() + 1);
    const data = {
      clientName: users?.user?.name,
      clientId: users?.uid,
      clientEmail: users?.user?.email,
      companyAddress: dataDetailClient?.companyAddress,
      companyId: users?.user?.companyId,
      companyName: dataDetailClient?.companyName,
      companyBusinessField: dataDetailClient?.businessField,
      quantity: qty.value,
      totalPrice: Math.ceil(
        parseInt(dataDetailProduct?.price) * qty.value +
          parseInt(dataDetailProduct?.price) * qty.value * 0.11
      ),
      ppn: Math.ceil(parseInt(dataDetailProduct?.price) * qty.value * 0.11),
      phone: users?.user?.phone,
      productName: dataDetailProduct?.name,
      productPrice: parseInt(dataDetailProduct?.price),
      productCategory: dataDetailProduct?.category,
      productDetail: dataDetailProduct?.description,
      paymentDeadline: paymentDeadline,
      paymentMethod: {
        bankName: null,
        bankId: null,
      },
    };

    dispatch(postOrderClientAction(data));
    setLoadingState(true);
  };

  return (
    <>
      <div className="card card-custom mb-4 payment-confirmation">
        <div className="card-header border-0 py-5 mb-0">
          <h3 className="card-title align-items-start flex-column">
            <Link>
              <span
                className="card-label font-weight-bolder text-dark"
                onClick={() => history.goBack()}
              >
                <span className="icon-back mr-7"></span>Konfirmasi Pesanan
              </span>
            </Link>
          </h3>
        </div>
        <div className="card-body">
          <h3> Detail Pesanan</h3>
          {loadingDetailProduct ? (
            <div className="card-body pt-0 pb-3">
              <FullContentLoader />
              <FullContentLoader />
            </div>
          ) : (
            <>
              <div className="order-detail">
                <div className="item">
                  <label>NAMA PRODUK</label>
                  <br />
                  <div className="divider"></div>
                  <p>{dataDetailProduct?.name}</p>
                </div>
                <div className="item">
                  <label>KATEGORI PRODUK</label>
                  <br />
                  <p className="category">{categoryName}</p>
                </div>
                <div className="item">
                  <label>HARGA PRODUK</label>
                  <br />
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={dataDetailProduct?.price}
                    renderText={(value) => <p>{value}</p>}
                  />
                </div>
              </div>
              <div className="table-content item p-d-flex">
                <span>{`Jumlah ${categoryName} yang ingin dipesan`}</span>
                <div className="item-total p-d-flex">
                  <button
                    className={`icon-wrapper ${qty.value > 1 ? "active" : ""}`}
                    onClick={() =>
                      setQty({ value: qty.value - 1, error: null })
                    }
                    disabled={qty.value <= 1}
                  >
                    <RemoveIcon />
                  </button>
                  <input
                    className="input-number-qty"
                    type="number"
                    value={qty.value}
                    onChange={(e) =>
                      setQty({ value: parseInt(e.target.value), error: null })
                    }
                    min="0"
                    max="100"
                  />
                  <button
                    className={`icon-wrapper ${
                      qty.value < 100 ? "active" : ""
                    }`}
                    onClick={() =>
                      setQty({ value: qty.value + 1, error: null })
                    }
                    disabled={qty.value >= 100}
                  >
                    <AddIcon />
                  </button>
                </div>
                <div className={`hint ${!isShowHint ? "d-none" : ""}`}>
                  <div className="tail"></div>
                  <p>{`Tentukan jumlah ${categoryName} yang Anda butuhkan`}</p>
                  <CloseIcon onClick={() => setIsShowHint(!isShowHint)} />
                </div>
              </div>
              <div className="table-footer p-d-flex p-jc-between">
                <span>TOTAL HARGA</span>
                <NumberFormat
                  prefix={"Rp "}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={dataDetailProduct?.price * qty.value}
                  renderText={(value) => <span>{value}</span>}
                />
              </div>
              <div className="table-footer p-d-flex p-jc-between mt-2">
                <span>PPN (11%)</span>
                <NumberFormat
                  prefix={"Rp "}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={Math.ceil(dataDetailProduct?.price * qty.value * 0.11)}
                  renderText={(value) => <span>{value}</span>}
                />
              </div>
              <div className="table-footer p-d-flex p-jc-between mt-2">
                <span>
                  <strong>TOTAL TAGIHAN</strong>
                </span>
                <NumberFormat
                  prefix={"Rp "}
                  thousandSeparator={"."}
                  decimalSeparator={","}
                  displayType="text"
                  value={Math.ceil(
                    dataDetailProduct?.price * qty.value +
                      dataDetailProduct?.price * qty.value * 0.11
                  )}
                  renderText={(value) => <strong>{value}</strong>}
                />
              </div>
            </>
          )}{" "}
        </div>
        <div className="card-footer p-d-flex p-jc-between p-ai-center">
          <div className="text-note text-danger mr-4">{qty.error}</div>
          <ButtonDefault
            label="BAYAR"
            className="p-button p-button-primary"
            type="rounded"
            loading={loadingState}
            onClick={() => submitOrder()}
            disabled={loadingDetailProduct}
          />
        </div>
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={() => closeDialog()}
        title={dialogAlert.title}
        text={dialogAlert.text}
        actionText={dialogAlert.actionText}
        actionDialog={dialogAlert.actionDialog}
        loading={loadingState}
      />
    </>
  );
}

export default PaymentConfirmationModule;
