/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory, Link } from "react-router-dom";
import queryString from "query-string";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

import ShowIcon from "../../../assets/img/icon-show.svg";
import DateBody from "../../../components/datatableBody/DateBody";
import TextBody from "../../../components/datatableBody/TextBody";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import TableLoader from "../../../components/loading/tableLoader";
import AdminPaginate from "../../../components/paging/pagination";

import FilterUser from "./Components/FilterUser";
import { getUsersAction } from "../../../../redux/action/userAction";

const ListUserClientModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const { dataGetUsers, loadingGetUsers, totalPage } = useSelector(
    (state) => state.user
  );

  const dateBodyTemplate = (item) => {
    return <DateBody date={item?.createdAt} />;
  };

  const nameBodyTemplate = (item) => {
    return <TextBody text={item?.name || item?.email} />;
  };

  const phoneBodyTemplate = (item) => {
    return <TextBody text={item?.phone || "-"} />;
  };

  const actionBodyTemplate = (item) => {
    return (
      <div className="action-table">
        <Link
          to={`/admin/pm/detail-user/${item?._id}`}
          className="btn btn-action"
        >
          <img src={ShowIcon} alt="" />
        </Link>
      </div>
    );
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search,
      page: page + 1,
      perpage: 10,
      orderField: parsed.sort,
      orderDir: parsed.order,
      type: "client",
    };
    history.push(
      `/admin/pm/list-user/clients?search=${parsed.search || ""}&page=${page +
        1}`
    );
    dispatch(getUsersAction(params));
  };

  return (
    <>
      <div className="card card-custom">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              Daftar Klien
            </span>
          </h3>
        </div>
        <div className="card-body pt-0 pb-3">
          <FilterUser parsed={parsed} type="client" />
          {loadingGetUsers ? (
            <TableLoader />
          ) : (
            <>
              {dataGetUsers?.length < 1 || dataGetUsers === null ? (
                <DataEmpty />
              ) : (
                <>
                  <DataTable value={dataGetUsers} className="no-padding">
                    <Column
                      header="Tanggal Daftar"
                      body={dateBodyTemplate}
                    ></Column>
                    <Column header="Nama" body={nameBodyTemplate}></Column>
                    <Column header="Email" field="email"></Column>
                    <Column
                      header="Telepon"
                      body={phoneBodyTemplate}
                      field="nohp"
                    ></Column>
                    <Column
                      headerClassName="action"
                      body={actionBodyTemplate}
                    ></Column>
                  </DataTable>
                </>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default ListUserClientModule;
