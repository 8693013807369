import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router";

import { titlePage } from "../../helpers/titleHead";
import DashboardClientLayout from "../../layouts/dashboardClientLayout";

import PaymentMethodModule from "../../modules/Client/Payment/PaymentMethodModule";
import PaymentDetailsModule from "../../modules/Client/Payment/PaymentDetailsModule";

import { paymentAccountAction } from "../../../redux/action/utilsAction";
import {
  orderClientDetailAction,
  resetOrderStateAction,
} from "../../../redux/action/orderAction";

function PaymentMethodPage() {
  const dispatch = useDispatch();
  const queryParams = useParams();
  const [layout, setLayout] = useState("choose");

  const { dataDetailOrderClient, successUpdateOrder } = useSelector(
    (state) => state.order
  );

  useEffect(() => {
    titlePage({
      title: "IRIS - Pembayaran",
    });
    dispatch(orderClientDetailAction(queryParams.orderId));
    dispatch(paymentAccountAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dataDetailOrderClient?.paymentMethod?.bankName || successUpdateOrder) {
      setLayout("confirm");
      dispatch(orderClientDetailAction(queryParams.orderId));
      dispatch(resetOrderStateAction());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailOrderClient?.paymentMethod?.bankName, successUpdateOrder]);

  return (
    <DashboardClientLayout
      title="Pembayaran"
      mobileTitle={
        layout === "choose" ? "Pilih Metode Pembayaran" : "Transfer Bank"
      }
      menu="invoice"
    >
      {layout === "choose" ? (
        <PaymentMethodModule orderId={queryParams.orderId} />
      ) : (
        <PaymentDetailsModule orderId={queryParams.orderId} />
      )}
    </DashboardClientLayout>
  );
}

export default PaymentMethodPage;
