import React, { useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { Accordion, AccordionTab } from "primereact/accordion";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { getFaqAction } from "../../../../redux/action/landingpageAction";

function FAQModule() {
  const dispatch = useDispatch();

  const [activeWriter, setActiveWriter] = useState(null);
  const [activeClient, setActiveClient] = useState(null);
  const { dataGetFaq } = useSelector((state) => state.landingpage);
  const searchingHandler = useRef(
    _.debounce((search) => {
      const query = {
        orderDir: "asc",
        q: search,
      };
      dispatch(getFaqAction(query));
    }, 1000)
  ).current;

  return (
    <div className="faq-module container">
      <h1>Butuh Bantuan?</h1>
      <div className="box-search p-mt-4">
        <span className="p-input-icon-right" id="search-bar">
          <InputText
            placeholder="Cari Bantuan"
            onChange={(e) => searchingHandler(e.target.value)}
          />
        </span>
      </div>
      <div className="tab-project">
        <TabView>
          <TabPanel header="Penulis">
            <Accordion
              activeIndex={activeWriter}
              onTabChange={(e) => setActiveWriter(e.index)}
            >
              {dataGetFaq
                ?.filter((item) => item.type === "writer")
                .sort((a, b) => a?.sortNumber - b?.sortNumber)
                .map((item) => (
                  <AccordionTab header={item?.question} key={item?.question}>
                    <p>{item?.answer}</p>
                  </AccordionTab>
                ))}
            </Accordion>
          </TabPanel>
          <TabPanel header="Klien">
            <Accordion
              activeIndex={activeClient}
              onTabChange={(e) => setActiveClient(e.index)}
            >
              {dataGetFaq
                ?.filter((item) => item.type === "client")
                .sort((a, b) => a?.sortNumber - b?.sortNumber)
                .map((item) => (
                  <AccordionTab header={item?.question} key={item?.question}>
                    <p>{item?.answer}</p>
                  </AccordionTab>
                ))}
            </Accordion>
          </TabPanel>
        </TabView>
      </div>
    </div>
  );
}

export default FAQModule;
