/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";

import TitleSection from "./Components/TitleSection";
import ContentSection from "./Components/ContentSection";

import DialogAlert from "../../../components/dialog/DialogAlert";
import ButtonDefault from "../../../components/button/ButtonDefault";

import {
  resetStateProjectAction,
  superuserJoinProjectAction,
} from "../../../../redux/action/projectAction";

const AddArticleModule = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [titleValue, setTitleValue] = useState("");
  const [imageValue, setImageValue] = useState(null);
  const [contentValue, setContentValue] = useState("");
  const [contentCount, setContentCount] = useState("");
  const [projectActive, setProjectActive] = useState({});
  const [projectState, setprojectState] = useState({ value: "", error: "" });
  const [submitType, setSubmitType] = useState(null);

  const [dialogAlert, setDialogAlert] = useState({
    dialog: false,
    title: null,
    desc: null,
  });

  const { users } = useSelector((state) => state.auth);
  const {
    dataProjectActive,
    loadingProjectActive,
    loadingSuperuserJoinProject,
    successSuperuserJoinProject,
    errSuperuserJoinProject,
    idSuperuserJoinProject,
  } = useSelector((state) => state.project);
  const { dataUploadImage, successUploadImage } = useSelector(
    (state) => state.utils
  );

  useEffect(() => {
    if (!loadingProjectActive && dataProjectActive) {
      const list = dataProjectActive?.map((item) => ({
        code: item.id,
        name: `${item.jobNumber} - ${item.title}`,
      }));
      setProjectActive(list);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataProjectActive]);

  useEffect(() => {
    if (successUploadImage) {
      setImageValue(dataUploadImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    if (successSuperuserJoinProject) {
      if (submitType === "draft") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Simpan Draft",
          text:
            "Artikel berhasil disimpan ke draft, anda bisa melanjutkan menulis dihalaman selanjutnya",
          closeDialog: () => closeSuccess(idSuperuserJoinProject),
        });
      } else if (submitType === "winner") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Submit Artikel",
          text:
            "Artikel berhasil dipilih sebagai pemenang dan notifikasi telah dikirim ke klien",
          closeDialog: () => closeSuccess(idSuperuserJoinProject),
        });
      } else if (submitType === "pm_approved") {
        setDialogAlert({
          dialog: true,
          type: "success",
          title: "Berhasil Kirim Artikel",
          text: "Artikel berhasil dikirim ke klien untuk direview",
          closeDialog: () => closeSuccess(idSuperuserJoinProject),
        });
      }
    }
    if (errSuperuserJoinProject) {
      const winnerFull = errSuperuserJoinProject === "Winner Already Full";
      if (submitType === "draft") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "Gagal Simpan Draft",
          text: "Artikel gagal disimpan ke draft",
          closeDialog: () => closeAlert(),
        });
      } else if (submitType === "winner") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "Gagal Submit Artikel",
          text: winnerFull
            ? "Jumlah pemenang sudah terpenuhi"
            : "Artikel gagal disubmit",
          closeDialog: () => closeAlert(),
        });
      } else if (submitType === "pm_approved") {
        setDialogAlert({
          dialog: true,
          type: "error",
          title: "Gagal Kirim Artikel",
          text: winnerFull
            ? "Jumlah pemenang sudah terpenuhi"
            : "Artikel gagal dikirim ke klien",
          closeDialog: () => closeAlert(),
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSuperuserJoinProject, errSuperuserJoinProject]);

  const changeTitle = (value) => {
    setTitleValue(value);
  };
  const changeContent = (value) => {
    setContentValue(value);
    const counter = value
      ?.replace(/<[^>]*>/g, " ")
      .replace(/\s+/g, " ")
      .trim();
    setContentCount(counter);
  };

  const deleteImage = () => {
    setImageValue(null);
  };

  const closeAlert = () => {
    setDialogAlert({
      dialog: false,
      title: null,
      desc: null,
    });
    setSubmitType(null);
    dispatch(resetStateProjectAction());
  };

  const closeSuccess = (idWriter) => {
    setDialogAlert({
      dialog: false,
      title: null,
      desc: null,
    });
    setSubmitType(null);
    dispatch(resetStateProjectAction());
    history.push(
      `/admin/pm/detail-project/${projectState?.value}/preview?writer=${idWriter}`
    );
  };

  const submitArticle = (value) => {
    setSubmitType(value);
    const data = {
      articleTitle: titleValue,
      articleContent: contentValue,
      articleImage:
        imageValue !== undefined || imageValue === [] ? imageValue : null,
      status: value,
    };
    if (projectState?.value?.length < 1) {
      setDialogAlert({
        dialog: true,
        type: "warning",
        title: "Project Belum Dipilih",
        text: "Silahkan pilih project terlebih dahulu",
        closeDialog: () => closeAlert(),
      });
    } else if (titleValue === "") {
      setDialogAlert({
        dialog: true,
        type: "warning",
        title: "Judul Artikel Kosong",
        text: "Silahkan tulis judul artikel terlebih dahulu",
        closeDialog: () => closeAlert(),
      });
    } else if (contentCount?.split(" ").length < 300) {
      setDialogAlert({
        dialog: true,
        type: "warning",
        title: "Artikel Terlalu Pendek",
        text: "Silahkan tulis artikel minimal 300 karakter",
        closeDialog: () => closeAlert(),
      });
    } else {
      dispatch(superuserJoinProjectAction(projectState?.value, data));
    }
  };

  return (
    <>
      <div
        className="card card-custom-detail full-width"
        style={{ marginTop: `${users?.user?.type !== "pm" ? "40px" : "0"}` }}
      >
        <div className="card-header border-0 py-5 p-jc-between">
          <div className="p-d-flex p-jc-start p-ai-center">
            <div onClick={() => history.goBack()} className="button-back">
              <span className="icon-back"></span>
            </div>

            <h3 className="card-title align-items-start flex-column ml-3">
              <span className="card-label font-weight-bolder text-dark">
                Tambah Tulisan
              </span>
            </h3>
          </div>
        </div>
        <div className="card-body pt-0 pb-3">
          <div className="section-progress pb-0">
            <div className="p-field short">
              <Dropdown
                value={projectState.value}
                onChange={(e) =>
                  setprojectState({ value: e.target.value, error: null })
                }
                options={projectActive}
                optionLabel="name"
                optionValue="code"
                placeholder="Pilih Project"
              />
            </div>
          </div>
          <div className="section-article-box">
            <div className="section-article-header">
              <TitleSection
                titleValue={titleValue}
                imageValue={imageValue}
                changeTitle={(e) => changeTitle(e.target.value)}
                deleteImage={() => deleteImage()}
              />
            </div>
            <div className="section-article-body">
              <ContentSection
                contentValue={contentValue}
                changeContent={changeContent}
              />
            </div>
          </div>
          <div className="section-article-footer">
            <div className="write-note">
              <div className="write-count">
                {contentCount === "" ? 0 : contentCount?.split(" ").length}/
                <strong>300</strong> Kata
              </div>
            </div>
            <div className="write-button">
              <ButtonDefault
                label="SIMPAN & PILIH ARTIKEL"
                className="ml-2"
                onClick={() => submitArticle("winner")}
              />
              <ButtonDefault
                label="PREVIEW KE KLIEN"
                className="ml-2"
                onClick={() => submitArticle("pm_approved")}
              />
            </div>
          </div>
        </div>
      </div>

      <DialogAlert
        type={dialogAlert.type}
        visible={dialogAlert.dialog}
        closeDialog={
          dialogAlert?.closeDialog
            ? dialogAlert?.closeDialog
            : () => closeAlert()
        }
        title={dialogAlert.title}
        text={dialogAlert.text}
        loading={loadingSuperuserJoinProject}
        cancelText={dialogAlert.cancelText}
        actionDialog={dialogAlert.actionDialog}
        actionText={dialogAlert.actionText}
      />
    </>
  );
};

export default AddArticleModule;
