import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { titlePage } from "../../helpers/titleHead";

import DashboardClientLayout from "../../layouts/dashboardClientLayout";

import { getSaveProjectClientAction } from "../../../redux/action/orderAction";
import MyDraftModule from "../../modules/Client/DraftRelease/MyDraftModule";

const MyDraftPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { saveParams } = useSelector((state) => state.save);
  useEffect(() => {
    titlePage({
      title: "IRIS - Project",
    });
    history.push("/client/my-order/draft");
    dispatch(getSaveProjectClientAction(saveParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="myrelease-page">
      <DashboardClientLayout activeMenu="my-release" title="Project Draft">
        <MyDraftModule />
      </DashboardClientLayout>
    </div>
  );
};

export default MyDraftPage;
