/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-restricted-imports */
// import React, { useEffect, useState, useRef } from "react";
import React from "react";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/id";

const WriterDetailProject = (props) => {
  const { dataGetDetailProject, dataGetArticle } = useSelector(
    (state) => state.project
  );
  return (
    <div className="box-info">
      <div className="header-box client-chat bordered p-d-flex p-ai-center">
        <button
          onClick={() => props.goBack()}
          className="button-back detail-chat"
        >
          <span className="card-label font-weight-bolder text-dark">
            <span className="icon-back mr-2"></span>
          </span>
        </button>
        <h3 className="p-mb-0">
          Nomor Project: <span>{dataGetDetailProject?.jobNumber}</span>
        </h3>
        {typeof dataGetArticle?.status !== "object" ? (
          <span className={`status ${dataGetArticle?.status} weight-semibold`}>
            {dataGetArticle?.status === "winner" ||
            dataGetArticle?.status === "published"
              ? "Approved"
              : dataGetArticle?.status}
          </span>
        ) : (
          <span
            className={`status ${dataGetArticle?.status?.status} weight-semibold`}
          >
            {dataGetArticle?.status?.status === "winner" ||
            dataGetArticle?.status?.status === "published"
              ? "Approved"
              : dataGetArticle?.status?.status}
          </span>
        )}
      </div>
      <div className="box-inner-right chat-pm">
        <div className="detail-project">
          <p>
            {dataGetDetailProject?.description?.length > 30
              ? `${dataGetDetailProject?.description?.slice(0, 30)}...`
              : dataGetDetailProject?.description}{" "}
          </p>
          <p>
            <a
              href={`/my-project/${dataGetArticle?.projectId}`}
              target="_blank"
            >
              Lihat Selengkapnya
            </a>
          </p>

          <div className="publish-date">
            Dipublish{" "}
            {dayjs(dataGetDetailProject?.createdAt?.toDate())
              .locale("id")
              .format("DD MMMM YYYY")}
          </div>

          <div className="user-progress">
            <ul>
              <li className="active">
                <p>Bergabung Dalam Project</p>
                <span>
                  {dayjs(dataGetArticle?.createdAt?.toDate())
                    .locale("id")
                    .format("DD MMMM YYYY")}
                </span>
              </li>
              {dataGetArticle?.submitAt && (
                <li className={dataGetArticle?.submitAt ? "active" : ""}>
                  <p>On Progress</p>
                  <span>
                    {dataGetArticle?.submitAt
                      ? dayjs(dataGetArticle?.submitAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </span>
                </li>
              )}
              {dataGetArticle?.lastRevisionAt && (
                <li className={dataGetArticle?.lastRevisionAt ? "active" : ""}>
                  <p>Revisi</p>
                  <span>
                    {dataGetArticle?.lastRevisionAt
                      ? dayjs(dataGetArticle?.lastRevisionAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </span>
                </li>
              )}
              {dataGetArticle?.doneAt && (
                <li
                  className={
                    dataGetArticle?.doneAt ||
                    (dataGetArticle?.status !== "object"
                      ? dataGetArticle?.status === "reject"
                      : dataGetArticle?.status?.status === "reject")
                      ? "active"
                      : ""
                  }
                >
                  <p>Selesai</p>
                  <span>
                    {dataGetArticle?.doneAt
                      ? dayjs(dataGetArticle?.doneAt?.toDate())
                          .locale("id")
                          .format("DD MMMM YYYY")
                      : "Belum tersedia"}
                  </span>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WriterDetailProject;
