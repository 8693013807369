/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import dayjs from "dayjs";
import { random } from "lodash";

import CameraIcon from "../../assets/img/icon-camera.svg";

import { uploadImageAction } from "../../../redux/action/utilsAction";
import { avatarLabel } from "../../helpers/profileHelper";

const thumbsContainer = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  marginTop: 16,
};

export const UploadProfileClient = (props) => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const { users } = useSelector((state) => state.auth);
  const { loadingUploadImage } = useSelector((state) => state.utils);

  const { getRootProps, getInputProps, open } = useDropzone({
    accept: "image/*",
    multiple: true,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    onDrop: (acceptedFiles) => {
      if (acceptedFiles[0]?.size > 1000000) {
        setError("Ukuran gambar lebih dari 1MB");
      } else if (acceptedFiles.length > 1) {
        setError("Silakan pilih satu gambar");
      } else {
        setError("");

        const date = new Date();
        const fileName = date.getTime().toString() + random(0, 100000);
        const times = dayjs(date).format("YYYY-MM");
        const path = `/${props.path}/${times}/${users.uid}/${fileName}`;

        dispatch(uploadImageAction(acceptedFiles[0], path));
      }
    },
  });

  return (
    <div className="editor-wrapp">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <aside style={thumbsContainer}>
          {error.length > 0 && (
            <span className="box-input-file-error justify-content-start mb-3">
              {error}
            </span>
          )}

          <div className="photo-profile-warp client">
            <div className={`button-wrap ${props.isEdit ? "edit" : "view"}`}>
              <button
                className="camera-btn"
                disabled={loadingUploadImage}
                onClick={open}
              >
                <img src={CameraIcon} alt="" />
              </button>
            </div>
            <div className="image-thumb display-only client-profile">
              {!props.attachment ? (
                <div className="photo-profile-warp">
                  <div className="image-thumb">
                    <div className="letter-pics client">
                      {avatarLabel(users)}
                    </div>
                  </div>
                </div>
              ) : (
                <img src={props.attachment} alt="profile" />
              )}
            </div>
          </div>
        </aside>
      </div>
    </div>
  );
};
