/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { resetStateAuthAction } from "../../../redux/action/authAction";

const HeaderAuth = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleBack = () => {
    history.push("/");
    dispatch(resetStateAuthAction());
  };
  return (
    <div className="header-auth p-d-flex p-jc-center">
      <div className="p-grid p-d-flex p-jc-between p-ai-center">
        <a href="#" onClick={handleBack} className="back p-d-flex p-ai-center">
          <span className="icon-back p-mr-3"></span>
          <span>
            <p className="m-0">Halaman Utama</p>
          </span>
        </a>
      </div>
    </div>
  );
};

export default HeaderAuth;
