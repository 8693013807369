import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import IsoLogin from "../../assets/img/iso-login.svg";
import AuthLayout from "../../layouts/authLayout";
import LoginPhone from "../../modules/User/Login/LoginPhoneModule";
import LoginEmail from "../../modules/User/Login/LoginEmailModule";
import LoginOtp from "../../modules/User/Login/LoginOtpModule";

import { titlePage } from "../../helpers/titleHead";
import { resetStateAuthAction } from "../../../redux/action/authAction";

const LoginPage = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [otpLayout, setOtpLayout] = useState(false);
  const [tabs, setTabs] = useState("email");

  const {
    successPhoneLogin,
    isLoggedIn,
    successOtpLogin,
    verificationOtp,
    successEmailLogin,
    users,
  } = useSelector((state) => state.auth);

  const { dataGetSettingSite } = useSelector((state) => state.landingpage);
  const { dataAddProjectClient } = useSelector((state) => state.utils);

  useEffect(() => {
    titlePage({
      title: "IRIS - Masuk",
    });
  }, []);

  useEffect(() => {
    if (successPhoneLogin && verificationOtp !== null) {
      setOtpLayout(true);
      setTabs("otp");
    }
  }, [successPhoneLogin, verificationOtp]);

  useEffect(() => {
    if ((successOtpLogin || successEmailLogin) && isLoggedIn) {
      if (users?.user?.type === "writer") {
        history.push("/dashboard");
      }
      if (users?.user?.type === "client" || users?.user?.type === "pic") {
        history.push("/client/dashboard");
      }
      if (dataAddProjectClient) {
        history.push("/client/ideation");
      }
      dispatch(resetStateAuthAction());
    }
  }, [
    successOtpLogin,
    successEmailLogin,
    isLoggedIn,
    users,
    dataAddProjectClient,
    history,
    dispatch,
  ]);

  return (
    <AuthLayout>
      <div className="auth-wrapper login">
        <div className="container">
          <div className="p-grid">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-lg-0 p-md-0">
              <div className="auth-form p-d-flex p-jc-center">
                <div className="form-reg">
                  <div className="p-text-center client-login-header">
                    <h2>Selamat Datang di IRIS</h2>
                    <div className="iris-logo">
                      <img src={dataGetSettingSite?.logo} alt="" />
                    </div>
                  </div>
                  {!otpLayout && (
                    <div className="button-tab-wrapper">
                      <button
                        className={`tab-auth ${
                          tabs === "email" ? "active" : ""
                        }`}
                        onClick={() => setTabs("email")}
                      >
                        Email
                      </button>
                      <button
                        className={`tab-auth ${
                          tabs === "phone" ? "active" : ""
                        }`}
                        onClick={() => setTabs("phone")}
                      >
                        Nomor Telepon
                      </button>
                    </div>
                  )}
                  {tabs === "phone" && <LoginPhone />}
                  {tabs === "email" && <LoginEmail />}
                  {otpLayout && <LoginOtp />}
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-0 col-xs-0 p-lg-0 p-md-0">
              <div className="iso-login">
                <img src={IsoLogin} alt="iso-login" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </AuthLayout>
  );
};

export default LoginPage;
