/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect } from "react";
import SettingPmModule from "../../../modules/Admin/Settings/SettingModule";
import DashboardLayout from "../../../layouts/dashboardLayout";

import { titlePage } from "../../../helpers/titleHead";

const SettingPmPage = (props) => {
  useEffect(() => {
    titlePage({
      title: "IRIS - Pengaturan",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Pengaturan">
      <SettingPmModule />
    </DashboardLayout>
  );
};

export default SettingPmPage;
