/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

// import DataEmpty from "../../../../components/emptyState/dataEmpty";
// import TableLoader from "../../../../components/loading/tableLoader";

// import FilterListUser from "./Components/FilterListUser";
import {
  resetStateUserAction,
} from "../../../../redux/action/userAction";
import FilterPIC from "./Components/FilterPIC";
import TextBody from "../../../components/datatableBody/TextBody";
import DialogAlert from "../../../components/dialog/DialogAlert";
import AdminPaginate from "../../../components/paging/pagination";
import DateBody from "../../../components/datatableBody/DateBody";
import TableLoader from "../../../components/loading/tableLoader";
import DataEmpty from "../../../components/emptyState/dataEmpty";
import { getPICListAction } from "../../../../redux/action/clientPicAction";
import ResponsiveTable from "../../../components/table/ResponsiveTable";
import ResponsiveColumn from "../../../components/table/ResponsiveColumn";

const PICListModule = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const parsed = queryString.parse(location.search);

  const [dialogSuccess, setDialogSuccess] = useState(false);

  const { dataGetPICList, loadingGetPICList, totalPage } = useSelector(
    (state) => state.clientPic
  );

  const nameBodyTemplate = (item) => {
    return (
      <div onClick={() => history.push(`/client/pic/detail/${item?._id}`)}>
        <TextBody text={item?.name || item?.email} />
      </div>
    );
  };

  const emailBodyTemplate = (item) => {
    return (
      <div onClick={() => history.push(`/client/pic/detail/${item?._id}`)}>
        <span>{item?.email}</span>
      </div>
    );
  };

  const dateBodyTemplate = (item) => {
    return (
      <div onClick={() => history.push(`/client/pic/detail/${item?._id}`)}>
        <DateBody date={item?.deadlineDate} />
      </div>
    );
  };

  const phoneBodyTemplate = (item) => {
    return (
      <div onClick={() => history.push(`/client/pic/detail/${item?._id}`)}>
        <TextBody text={item?.phone || "-"} />
      </div>
    );
  };

  const closeSuccess = () => {
    dispatch(resetStateUserAction());
    setDialogSuccess(false);
  };

  const paginationHandler = (page) => {
    const params = {
      q: parsed.search,
      page: page + 1,
      perpage: 10,
      orderField: parsed.sort,
      orderDir: parsed.order,
      status: "verified",
    };
    history.push(
      `/admin/pm/list-user/verified?search=${parsed.search ||
        ""}&sort=${parsed.sort || ""}&order=${parsed.order || ""}&page=${page +
        1}`
    );
    dispatch(getPICListAction(params));
  };

  return (
    <>
      <div className="card card-custom list-pic">
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              List Tim
            </span>
          </h3>
        </div>
        <div className="card-body list-pic pt-0 pb-3">
          <FilterPIC parsed={parsed} />
          {loadingGetPICList ? (
            <TableLoader />
          ) : (
            <>
              {dataGetPICList?.length < 1 || dataGetPICList === null ? (
                <DataEmpty />
              ) : (
                <>
                  <ResponsiveTable mobileTitle="Nama" value={dataGetPICList} className="no-padding">
                    <ResponsiveColumn
                      header="Tanggal"
                      headerClassName="pic-date"
                      body={dateBodyTemplate}
                    ></ResponsiveColumn>
                    <ResponsiveColumn header="Nama" body={nameBodyTemplate}></ResponsiveColumn>
                    <ResponsiveColumn header="Email" body={emailBodyTemplate} field="email"></ResponsiveColumn>
                    <ResponsiveColumn
                      header="No. Handphone"
                      body={phoneBodyTemplate}
                      field="nohp"
                    ></ResponsiveColumn>
                  </ResponsiveTable>
                </>
              )}
            </>
          )}
          {totalPage > 1 && (
            <AdminPaginate
              active={parsed?.page || 1}
              total={totalPage}
              clicked={paginationHandler}
              perpage={10}
            />
          )}
        </div>
      </div>
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={() => closeSuccess()}
        title={"Berhasil Kirim Ulang"}
        text={"Tautan berhasil dikirim ke email user"}
      />
    </>
  );
};

export default PICListModule;
