import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import NumberFormat from "react-number-format";
import { Link, useHistory } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { Accordion, AccordionTab } from "primereact/accordion";
import { Toast } from "primereact/toast";

import { paymentAccountDetailAction } from "../../../../redux/action/utilsAction";
import ButtonDefault from "../../../components/button/ButtonDefault";

function PaymentDetailsModule(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [activeIndex, setActiveIndex] = useState(0);
  const [copies, setCopies] = useState(false);
  const { dataDetailOrderClient } = useSelector((state) => state.order);
  const { dataPaymentAccountDetail } = useSelector((state) => state.utils);

  useEffect(() => {
    if (copies) {
      showToast("success", "Berhasil", "Nomor Rekening tersalin di clipboard");
      setTimeout(() => {
        setCopies(false);
      }, 300);
    }
  }, [copies]);

  useEffect(() => {
    if (dataDetailOrderClient?.paymentMethod?.bankId !== null) {
      dispatch(
        paymentAccountDetailAction(dataDetailOrderClient?.paymentMethod?.bankId)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataDetailOrderClient?.paymentMethod?.bankId]);

  const myToast = useRef(null);

  const showToast = (severityValue, summaryValue, detailValue) => {
    myToast.current.show({
      severity: severityValue,
      summary: summaryValue,
      detail: detailValue,
    });
  };

  return (
    <>
      <Toast ref={myToast} />
      <div className="p-d-flex p-jc-center">
        <div className="col-lg-8 col-md-8 col-sm-12">
          <div className="card card-custom mb-4 payment-detail">
            <div className="card-header border-0 py-5">
              <h3 className="card-title align-items-start flex-column">
                <Link onClick={() => history.goBack()}>
                  <span className="card-label font-weight-bolder text-dark">
                    <span className="icon-back mr-7"></span>Transfer Bank
                  </span>
                </Link>
              </h3>
            </div>
            <div className="card-body">
              <div className="helper-blue p-d-flex p-jc-between">
                <span className="icon-info"></span>
                <p>
                  Anda memilih melakukan pembayaran melalui transfer bank.
                  Silakan melakukan transfer ke{" "}
                  <strong> Nomor Rekening </strong>yang tertera di bawah ini,
                  kemudian masukkan nominal yang tertera pada{" "}
                  <strong> Total Tagihan</strong>, lalu upload bukti transfer.
                </p>
              </div>
              <div className="transfer-content">
                <div className="bank-details">
                  <div>Nama Bank</div>
                  <div className="info">
                    {dataPaymentAccountDetail?.paymentName}
                  </div>
                </div>
                <div className="bank-details">
                  <div>Nomor Rekening</div>
                  <div className="p-d-flex p-jc-between">
                    <div className="info">
                      {dataPaymentAccountDetail?.accountNumber}
                    </div>
                    <CopyToClipboard
                      text={dataPaymentAccountDetail?.accountNumber}
                      onCopy={() => setCopies(true)}
                    >
                      <div className="paste">Salin</div>
                    </CopyToClipboard>
                    {copies?.copy && "disalin"}
                  </div>
                </div>
                <div className="bank-details">
                  <div>Nama Akun</div>
                  <div className="p-d-flex p-jc-between">
                    <div className="info">
                      {dataPaymentAccountDetail?.accountName}
                    </div>
                  </div>
                </div>
                <div className="bank-details">
                  <div>Total Tagihan</div>
                  <NumberFormat
                    prefix={"Rp "}
                    thousandSeparator={"."}
                    decimalSeparator={","}
                    displayType="text"
                    value={dataDetailOrderClient?.totalPrice}
                    renderText={(value) => <div className="info">{value}</div>}
                  />
                </div>
              </div>
              <Accordion
                multiple
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                <AccordionTab header="Cara Pembayaran Bank Transfer">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: `${dataPaymentAccountDetail?.paymentInstructions}`,
                    }}
                  />
                </AccordionTab>
              </Accordion>
            </div>
            <div className="card-footer p-d-flex p-jc-end">
              <ButtonDefault
                type="rounded"
                label="UPLOAD BUKTI PEMBAYARAN"
                onClick={() =>
                  history.push(`/client/payment/${props.orderId}/confirm`)
                }
                className="p-mr-3 p-button p-button-primary"
              />
              <ButtonDefault
                type="rounded-bordered"
                label="TUTUP"
                onClick={() => history.push("/client/my-order/process")}
                className="p-button p-button-secondary"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentDetailsModule;
