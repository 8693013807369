/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-restricted-imports */
/* eslint-disable jsx-a11y/anchor-is-valid */

import "dayjs/locale/id";
import AddHeadline from "./Components/AddHeadline";
import AddStory from "./Components/AddStory";
import AddDetail from "./Components/ServiceAddDetail";
import ProjectConfirmation from "./Components/ProjectConfirmation";
import React, { useState, useEffect } from "react";
import { 
  useDispatch, 
  useSelector 
} from "react-redux";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import {
  postOrderBriefAction,
  postSaveBriefAction,
  resetOrderStateAction,
  resetSaveStateAction,
} from "../../../../redux/action/orderAction";

import { clientDataProjectAction } from "../../../../redux/action/utilsAction";
import DialogAlert from "../../../components/dialog/DialogAlert";

const IdeationProject = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [layout, setLayout] = useState("headline");
  const [dialogWarning, setDialogWarning] = useState(false);
  const [dialogSuccess, setDialogSuccess] = useState(false);
  const [isSave, setDialogSave] = useState(false);
  const [dialogSuccessSave, setDialogSuccessSave] = useState(false);
  const [loadingState, setLoadingState] = useState(false);

  const [qty, setQty] = useState({ value: 1, error: null });

  const { dataAddProjectClient } = useSelector((state) => state.utils);
  const { successPostOrderBrief } = useSelector((state) => state.order);
  const { successSaveOrderBrief } = useSelector((state) => state.save);
  const { dataDetailClient } = useSelector((state) => state.client);

  const { users } = useSelector((state) => state.auth);
  const closeSuccess = (e) => {
    e.preventDefault();
    dispatch(clientDataProjectAction(null));
    dispatch(resetOrderStateAction());
    dispatch(resetSaveStateAction());
    setDialogSuccess(false);
    history.push(`/client/invoice/${successPostOrderBrief?.data?.id}`);
  };

  const closeSuccessSave = (e) => {
    e.preventDefault();
    dispatch(clientDataProjectAction(null));
    dispatch(resetOrderStateAction());
    dispatch(resetSaveStateAction());
    setDialogSuccessSave(false);
    history.push("/client/dashboard");
  };

  useEffect(() => {
    if (dataAddProjectClient !== null) {
      setQty({
        value: dataAddProjectClient.quantity,
        error: null,
      })
    }
  },[dataAddProjectClient])


  useEffect(() => {
    if (successPostOrderBrief) {
      setLoadingState(false);
      setDialogWarning(false);
      setDialogSuccess(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successPostOrderBrief]);

  useEffect(() => {
    if (successSaveOrderBrief) {
      setTimeout(() => {
        setLoadingState(false);
        setDialogSave(false);
        setDialogSuccessSave(true);
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSaveOrderBrief]);

  const handleSubmit = () => {
    setLoadingState(true);
    const paymentDeadline = new Date();
    paymentDeadline.setDate(paymentDeadline.getDate() + 1);
    const data = {
      searchKeyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: dataAddProjectClient.storyAngles,
      title: dataAddProjectClient?.title,
      category: dataAddProjectClient?.category,
      type: dataAddProjectClient?.type,
      thumbnail: process.env.REACT_APP_IMAGE_ARTICLE,
      description: dataAddProjectClient?.desc,
      deadlineDate: dayjs(dataAddProjectClient?.deadline).toDate(),
      releaseCategory: dataAddProjectClient?.releaseCategory,
      // releaseCategoryQuestion: dataAddProjectClient?.releaseCategoryQuestion,
      objective: dataAddProjectClient?.objective,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient?.releaseNote,
      winnerQuota: parseInt(dataAddProjectClient?.quota),
      clientName: dataDetailClient?.client?.name,
      clientEmail: users?.user?.email,
      clientId : dataDetailClient?.client?.clientId,
      companyAddress : dataDetailClient?.companyAddress,
      companyBusinessField : dataDetailClient?.businessField,
      companyId : users?.user?.companyId,
      companyName : users?.user?.companyName,
      paymentDeadline: paymentDeadline,
      phone : users?.user?.phone,
      ppn: Math.ceil(15000000 * qty.value * 0.11),
      productCategory: "pr",
      productDetail: "Layanan pembuatan dan penyebaran release dengan target 5 media mainstream. Format release dalam Bahasa Indonesia.",
      productName : "Premium Press Release",
      productPrice : 15000000,
      quantity : qty.value,
      totalPrice: Math.ceil(
        15000000 * qty.value +
        15000000 * qty.value * 0.11
      ),
    };
    dispatch(postOrderBriefAction("zjKXmkFIXIdE6kfJVEBg", data));
  };

  const handleSave = () => {
    setLoadingState(true);
    const paymentDeadline = new Date();
    paymentDeadline.setDate(paymentDeadline.getDate() + 1);
    const data = {
      searchKeyword: dataAddProjectClient.keyword,
      ideationFeature: dataAddProjectClient.ideationFeature,
      storyAngles: dataAddProjectClient.storyAngles,
      title: dataAddProjectClient?.title,
      category: dataAddProjectClient?.category,
      type: dataAddProjectClient?.type,
      thumbnail: process.env.REACT_APP_IMAGE_ARTICLE,
      description: dataAddProjectClient?.desc,
      deadlineDate: dayjs(dataAddProjectClient?.deadline).toDate(),
      releaseCategory: dataAddProjectClient?.releaseCategory,
      // releaseCategoryQuestion: dataAddProjectClient?.releaseCategoryQuestion,
      objective: dataAddProjectClient?.objective,
      target: {
        sex: dataAddProjectClient?.target?.sex,
        age: dataAddProjectClient?.target?.age,
        stakeholders: dataAddProjectClient?.target?.stakeholders,
        ses: dataAddProjectClient?.target?.ses,
      },
      attribution: dataAddProjectClient?.attribution,
      releaseNote: dataAddProjectClient?.releaseNote,
      winnerQuota: parseInt(dataAddProjectClient?.quota),
      clientName: dataDetailClient?.client?.name,
      clientEmail: users?.user?.email,
      clientId : dataDetailClient?.client?.clientId,
      companyAddress : dataDetailClient?.companyAddress,
      companyBusinessField : dataDetailClient?.businessField,
      companyId : users?.user?.companyId,
      companyName : users?.user?.companyName,
      paymentDeadline: paymentDeadline,
      phone : users?.user?.phone,
      ppn: Math.ceil(15000000 * qty.value * 0.11),
      productCategory: "pr",
      productDetail: "Layanan pembuatan dan penyebaran release dengan target 5 media mainstream. Format release dalam Bahasa Indonesia.",
      productName : "Premium Press Release",
      productPrice : 15000000,
      quantity : qty.value,
      totalPrice: Math.ceil(
        15000000 * qty.value +
        15000000 * qty.value * 0.11
      ),
    };
    dispatch(postSaveBriefAction("zjKXmkFIXIdE6kfJVEBg", data));
  };

  return (
    <div className="p-d-flex">
      <div className={`card card-custom mb-4 brief-step ${layout === "story" || layout === "detail" ? "w-50" : "w-100"}`}>
        <div className="card-header border-0 py-5">
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label font-weight-bolder text-dark">
              {layout === "story" ? (
                <>
                  <span 
                    onClick={() => setLayout("headline")} 
                    className="icon-back mr-7">
                  </span>
                  Story Angle
                </>
              ) : (
                  "Story Angle"
              )}
            </span>
          </h3>
        </div>
        <div className="card-body pb-4">
          <div className="step-add">
            <ul>
              <li className="active">
                <div className="step-number">
                  <span>1</span>
                </div>
                {layout === "detail" || layout === "checkout" ? "Detail Project" : "Headline"}
              </li>
              <li
                className={
                  layout === "story" || layout === "checkout"
                    ? "active"
                    : "none"
                }
              >
                <div className="step-number">
                  <span>2</span>
                </div>
                {layout === "checkout" || layout === "detail" ? "Checkout": "Story Angle"}
              </li>
            </ul>
          </div>
        </div>
        {layout === "headline" && 
          <AddHeadline submitDetail={() => setLayout("story")} onSave={() => setDialogSave(true)}/>
        }
        {layout === "story" && (
          <AddStory 
            handleBack={() => setLayout("headline")}
            submitDetail={() => setLayout("detail")}
          />
        )}
        {layout === "detail" && (
          <AddDetail 
            goBack={() => setLayout("story")}
            submitData={() => setLayout("checkout")}
          />
        )}
        {layout === "checkout" && (
          <ProjectConfirmation 
            goBack={() => setLayout("detail")}
            onSubmit={() => setDialogWarning(true)}
            onSave={() => setDialogSave(true)}
          />
        )}
      </div>
      <DialogAlert
        type={"warning"}
        actionDialog={handleSubmit}
        actionText={"KONFIMASI"}
        visible={dialogWarning}
        closeDialog={() => setDialogWarning(false)}
        title={"Konfirmasi Pemesanan Layanan"}
        text={loadingState ? "Draft Sedang Dibuat. Mohon Tunggu.." : "Anda yakin ingin melakukan konfirmasi pemesanan layanan?"}
        loading={loadingState || successPostOrderBrief}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccess}
        closeDialog={closeSuccess}
        // title={"Berhasil Menyimpan"}
        actionText={"LIHAT INVOICE"}
        text={"Pesanan Layanan Berhasil Dibuat"}
      />
      <DialogAlert
        type={"warning"}
        actionDialog={handleSave}
        actionText={"KONFIMASI"}
        visible={isSave}
        closeDialog={() => setDialogSave(false)}
        title={"Simpan brief?"}
        text={"Anda yakin ingin menyimpan brief terlebih dahulu?"}
        loading={loadingState || successSaveOrderBrief}
      />
      <DialogAlert
        type={"success"}
        visible={dialogSuccessSave}
        closeDialog={closeSuccessSave}
        title={"Berhasil Menyimpan"}
        // actionText={"LIHAT INVOICE"}
        text={"Pesanan Layanan Berhasil Disimpan"}
      />
      {/* <div className="w-50 text-dark px-10 py-6"> */}
        {/* <div className="h2 mb-5">Konsultasi Gratis</div>
        <ul className="mb-5">
          <li className="mb-5">Lorem Ipsum. Lorem ipsum dolor sit amet, Qui dolore amet eos consequatur culpa non praesentium distinctio ea voluptatem quisquam.</li>
          <li className="mb-5">Lorem Ipsum. Lorem ipsum dolor sit amet, Qui dolore amet eos consequatur culpa non praesentium distinctio ea voluptatem quisquam.</li>
          <li className="mb-5">Lorem Ipsum. Lorem ipsum dolor sit amet, Qui dolore amet eos consequatur culpa non praesentium distinctio ea voluptatem quisquam.</li>
        </ul> */}
      {/* </div> */}
    </div>
  );
};

export default IdeationProject;
