import React, { useEffect } from "react";

import DashboardUserLayout from "../../layouts/dashboardUserLayout";
import SettingsModule from "../../modules/User/Settings/SettingModule";
import { titlePage } from "../../helpers/titleHead";

const SettingsPage = () => {
  useEffect(() => {
    titlePage({
      title: "IRIS - Pengaturan",
    });
  }, []);

  return (
    <DashboardUserLayout>
      <SettingsModule />
    </DashboardUserLayout>
  );
};

export default SettingsPage;
