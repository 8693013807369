export const GET_ORDER_REQUEST = "GET_ORDER_REQUEST";
export const GET_ORDER_SUCCESS = "GET_ORDER_SUCCESS";
export const GET_ORDER_FAILURE = "GET_ORDER_FAILURE";

export const GET_ORDER_DETAIL_REQUEST = "GET_ORDER_DETAIL_REQUEST";
export const GET_ORDER_DETAIL_SUCCESS = "GET_ORDER_DETAIL_SUCCESS";
export const GET_ORDER_DETAIL_FAILURE = "GET_ORDER_DETAIL_FAILURE";

export const GET_ORDER_SAVE_DETAIL_REQUEST = "GET_ORDER_SAVE_DETAIL_REQUEST";
export const GET_ORDER_SAVE_DETAIL_SUCCESS = "GET_ORDER_SAVE_DETAIL_SUCCESS";
export const GET_ORDER_SAVE_DETAIL_FAILURE = "GET_ORDER_SAVE_DETAIL_FAILURE";

export const POST_SAVE_CLIENT_REQUEST = "POST_SAVE_CLIENT_REQUEST";
export const POST_SAVE_CLIENT_SUCCESS = "POST_SAVE_CLIENT_SUCCESS";
export const POST_SAVE_CLIENT_FAILURE = "POST_SAVE_CLIENT_FAILURE";

export const GET_SAVE_CLIENT_REQUEST = "GET_SAVE_CLIENT_REQUEST";
export const GET_SAVE_CLIENT_SUCCESS = "GET_SAVE_CLIENT_SUCCESS";
export const GET_SAVE_CLIENT_FAILURE = "GET_SAVE_CLIENT_FAILURE";

export const POST_ORDER_CLIENT_REQUEST = "POST_ORDER_CLIENT_REQUEST";
export const POST_ORDER_CLIENT_SUCCESS = "POST_ORDER_CLIENT_SUCCESS";
export const POST_ORDER_CLIENT_FAILURE = "POST_ORDER_CLIENT_FAILURE";

export const RESET_ORDER_STATE_REQUEST = "RESET_ORDER_STATE_REQUEST";
export const RESET_SAVE_STATE_REQUEST = "RESET_SAVE_STATE_REQUEST";

export const ORDER_CLIENT_DETAIL_REQUEST = "ORDER_CLIENT_DETAIL_REQUEST";
export const ORDER_CLIENT_DETAIL_SUCCESS = "ORDER_CLIENT_DETAIL_SUCCESS";
export const ORDER_CLIENT_DETAIL_FAILURE = "ORDER_CLIENT_DETAIL_FAILURE";

export const ORDER_CLIENT_SAVE_DETAIL_REQUEST = "ORDER_CLIENT_SAVE_DETAIL_REQUEST";
export const ORDER_CLIENT_SAVE_DETAIL_SUCCESS = "ORDER_CLIENT_SAVE_DETAIL_SUCCESS";
export const ORDER_CLIENT_SAVE_DETAIL_FAILURE = "ORDER_CLIENT_SAVE_DETAIL_FAILURE";

export const UPDATE_ORDER_REQUEST = "UPDATE_ORDER_REQUEST";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_FAILURE = "UPDATE_ORDER_FAILURE";

export const POST_ORDER_BRIEF_REQUEST = "POST_ORDER_BRIEF_REQUEST";
export const POST_ORDER_BRIEF_SUCCESS = "POST_ORDER_BRIEF_SUCCESS";
export const POST_ORDER_BRIEF_FAILURE = "POST_ORDER_BRIEF_FAILURE";

export const GET_PROJECT_CLIENT_REQUEST = "GET_PROJECT_CLIENT_REQUEST";
export const GET_PROJECT_CLIENT_SUCCESS = "GET_PROJECT_CLIENT_SUCCESS";
export const GET_PROJECT_CLIENT_FAILURE = "GET_PROJECT_CLIENT_FAILURE";
