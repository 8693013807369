export const WRITER_WITHDRAW_REQUEST = "WRITER_WITHDRAW_REQUEST";
export const WRITER_WITHDRAW_SUCCESS = "WRITER_WITHDRAW_SUCCESS";
export const WRITER_WITHDRAW_FAILURE = "WRITER_WITHDRAW_FAILURE";

export const WRITER_TRANSACTION_REQUEST = "WRITER_TRANSACTION_REQUEST";
export const WRITER_TRANSACTION_SUCCESS = "WRITER_TRANSACTION_SUCCESS";
export const WRITER_TRANSACTION_FAILURE = "WRITER_TRANSACTION_FAILURE";

export const LIST_WRITER_PAYMENT_REQUEST = "LIST_WRITER_PAYMENT_REQUEST";
export const LIST_WRITER_PAYMENT_SUCCESS = "LIST_WRITER_PAYMENT_SUCCESS";
export const LIST_WRITER_PAYMENT_FAILURE = "LIST_WRITER_PAYMENT_FAILURE";

export const DETAIL_WRITER_PAYMENT_REQUEST = "DETAIL_WRITER_PAYMENT_REQUEST";
export const DETAIL_WRITER_PAYMENT_SUCCESS = "DETAIL_WRITER_PAYMENT_SUCCESS";
export const DETAIL_WRITER_PAYMENT_FAILURE = "DETAIL_WRITER_PAYMENT_FAILURE";

export const LIST_PAYOUT_FINANCE_REQUEST = "LIST_PAYOUT_FINANCE_REQUEST";
export const LIST_PAYOUT_FINANCE_SUCCESS = "LIST_PAYOUT_FINANCE_SUCCESS";
export const LIST_PAYOUT_FINANCE_FAILURE = "LIST_PAYOUT_FINANCE_FAILURE";

export const RESET_STATE_TRANSACTION = "RESET_STATE_TRANSACTION";
