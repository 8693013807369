import { call, put } from "@redux-saga/core/effects";
import {
  ADD_HELP_FAILURE,
  ADD_HELP_SUCCESS,
  GET_HELP_LIST_SUCCESS,
  GET_HELP_LIST_FAILURE,
  GET_HELP_DETAIL_SUCCESS,
  GET_HELP_DETAIL_FAILURE,
  POST_HELP_CHAT_SUCCESS,
  POST_HELP_CHAT_FAILURE,
  POST_HELP_NOTIF_SUCCESS,
  POST_HELP_NOTIF_FAILURE,
} from "../constant/helpConstant";
import {
  createHelp,
  getHelpList,
  getHelpDetail,
  postHelpChats,
} from "../service/firebase/help";
import { getToken } from "../service/firebase/auth";

import { sendNotifTicket } from "../service/api/chat";

export function* addHelpSaga(action) {
  try {
    yield call(createHelp, action.data);
    yield put({ type: ADD_HELP_SUCCESS });
  } catch (err) {
    yield put({ type: ADD_HELP_FAILURE, err });
  }
}

export function* getListHelpSaga() {
  try {
    const res = yield call(getHelpList);
    let data = [];
    for (let index = 0; index < res.docs.length; index++) {
      let item = res.docs[index].data();
      item.id = res.docs[index].id;
      data.push(item);
    }
    yield put({ type: GET_HELP_LIST_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_HELP_LIST_FAILURE, err });
  }
}

export function* getHelpDetailSaga(action) {
  try {
    const snapshot = yield call(getHelpDetail, action.id);
    const data = snapshot.data();
    yield put({ type: GET_HELP_DETAIL_SUCCESS, data });
  } catch (err) {
    yield put({ type: GET_HELP_DETAIL_FAILURE, err });
  }
}

export function* postHelpChatSaga(action) {
  try {
    yield call(postHelpChats, action.id, action.data);
    yield put({ type: POST_HELP_CHAT_SUCCESS });
  } catch (err) {
    yield put({ type: POST_HELP_CHAT_FAILURE, err });
  }
}

export function* postHelpNotifSaga(action) {
  const token = yield call(getToken);
  const { ok, data } = yield call(
    sendNotifTicket,
    token,
    action.id,
    action.data
  );
  if (ok) {
    yield put({ type: POST_HELP_NOTIF_SUCCESS });
  } else {
    yield put({ type: POST_HELP_NOTIF_FAILURE, data });
  }
}
