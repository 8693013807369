import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import DashboardLayout from "../../../layouts/dashboardLayout";
import { titlePage } from "../../../helpers/titleHead";

import { detailUserAction } from "../../../../redux/action/userAction";
import { getBusinessAction } from "../../../../redux/action/utilsAction";
import AddCompanyModule from "../../../modules/Admin/Superadmin/CompanyManagement/AddCompanyModule";

const AddCompanyPage = () => {
  const dispatch = useDispatch();
  const QueryParam = useParams();
  useEffect(() => {
    dispatch(detailUserAction(QueryParam.userId));
    dispatch(getBusinessAction());

    titlePage({
      title: "IRIS - Tambah Perusahaan",
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout title="Manajemen Klien" menu="company">
      <AddCompanyModule />
    </DashboardLayout>
  );
};

export default AddCompanyPage;
