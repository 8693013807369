import api from "./api";

export const postClientOrder = async (token, data) => {
  return api.post("/client/orders", data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const postClientBrief = async (token, id, data) => {
  return api.post(`/client/orders`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getOrders = async (token, params) => {
  return api.get(`/client/orders`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getFinanceOrders = async (token, params) => {
  return api.get(`/finance/orders`, params, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};

export const putFinanceOrders = async (token, id, data) => {
  return api.put(`/finance/orders/${id}/update-status`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });
};
