import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import ServiceListCard from "./Components/ServiceListCard";
import ColumnLoader from "../../../components/loading/columnLoader";

function ServiceModule() {
  const [article, setArticle] = useState([]);
  const [pressRelease, setPressRelease] = useState([]);
  const [media, setMedia] = useState([]);

  const { loadingGetProduct, dataGetProduct } = useSelector(
    (state) => state.service
  );

  useEffect(() => {
    if (dataGetProduct) {
      setArticle(
        dataGetProduct?.filter((item) => item?.category === "article")
      );
      setPressRelease(
        dataGetProduct?.filter((item) => item?.category === "pr")
      );
      setMedia(
        dataGetProduct?.filter(
          (item) => item?.category === "distribution_media"
        )
      );
    }
  }, [dataGetProduct]);

  const serviceData = [
    { category: "Artikel", services: article },
    { category: "Press Release", services: pressRelease },
    { category: "Media Distribusi", services: media },
  ];

  return (
    <div className="service-module">
      <div className="title-module">Daftar Produk</div>
      {serviceData?.map((service) => (
        <div className="body-module">
          {service?.services?.length > 0 ? (
            <div className="list-services" key={service?.category}>
              <h3>{service.category}</h3>
              <div className="cards-list">
                {loadingGetProduct ? (
                  <ColumnLoader style={{ margin: "0 20px" }} />
                ) : (
                  <>
                    {service?.services?.map((item) => (
                      <ServiceListCard item={item} />
                    ))}
                  </>
                )}
              </div>
            </div>
          ) : (
            <div key={null} />
          )}
        </div>
      ))}
    </div>
  );
}

export default ServiceModule;
