/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import dayjs from "dayjs";
import "dayjs/locale/id";
import { InputText } from "primereact/inputtext";

import { ButtonUpload } from "./ButtonUpload";
import ChatLoader from "../../../components/loading/chatLoader";
import DialogAlert from "../../../components/dialog/DialogAlert";
import DialogPreview from "../../../components/dialog/DialogImagePreview";

import { sendChatAction } from "../../../../redux/action/chatAction";
import { updateArticleAction } from "../../../../redux/action/projectAction";
import { resetUtilsStateAction } from "../../../../redux/action/utilsAction";
import firebase from "../../../../redux/service/firebase/firebase";

const Discussion = (props) => {
  const firestore = firebase.firestore();
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [imageChat, setImageChat] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [renderChats, setRenderChats] = useState(null);
  const [loadingChat, setLoadingChat] = useState(true);
  const [dialogError, setDialogError] = useState(false);
  const [dialogPreview, setDialogPreview] = useState({
    dialog: false,
    images: null,
  });

  const { users } = useSelector((state) => state.auth);
  const { successSendChat } = useSelector((state) => state.chat);
  const { successUploadImage, dataUploadImage } = useSelector(
    (state) => state.utils
  );
  const { dataGetArticle } = useSelector((state) => state.project);

  const bottomRef = createRef();

  const getChatList = async (id) =>
    await firestore
      .collection("writerProjects")
      .doc(id)
      .collection("chats")
      .orderBy("createdAt", "asc")
      .onSnapshot((docs) => {
        const temp = [];
        docs.forEach((doc) =>
          temp.push({
            ...doc.data(),
            _id: doc.id,
          })
        );
        setLoadingChat(true);
        let chatList = [];
        const receiverChats = temp.filter(
          (chat) => chat?.user?.id !== users?.uid
        );
        const receiverPhoto =
          receiverChats[receiverChats?.length - 1]?.user?.photoURL;
        const receiverName =
          receiverChats[receiverChats?.length - 1]?.user?.name;
        temp.map((chat) => {
          if (chat?.user?.id !== users?.uid) {
            chat.user.photoURL = receiverPhoto;
            chat.user.name = receiverName;
          }
          return chatList.push(chat);
        });
        setRenderChats(chatList);
      });

  useEffect(() => {
    if (props.chatId && props.chatId === dataGetArticle?.id) {
      getChatList(props.chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.chatId, dataGetArticle?.id]);

  useEffect(() => {
    if (
      props.chatId &&
      props.chatId === dataGetArticle?.id &&
      users?.user?.type !== dataGetArticle?.chat?.userType
    ) {
      const notif = {
        isRead: false,
        userType: users?.user?.type,
      };
      dispatch(updateArticleAction(props.chatId, { chat: notif }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dataGetArticle?.id,
    props.chatId,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    dataGetArticle?.chat?.userType,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    users?.user.type,
  ]);

  useEffect(() => {
    if (successSendChat) {
      getChatList(props.chatId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successSendChat]);

  useEffect(() => {
    if (renderChats !== null) {
      setLoadingChat(false);
    }
  }, [renderChats]);

  useEffect(() => {
    if (successUploadImage) {
      setImageChat(dataUploadImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUploadImage]);

  useEffect(() => {
    if (imageError !== null) {
      setDialogError(true);
    }
  }, [imageError]);

  useEffect(() => {
    setTimeout(() => {
      bottomRef.current && bottomRef.current.scrollIntoView();
    }, 100);
  }, [renderChats, bottomRef]);

  const hidePreview = () => {
    setImageChat(null);
    dispatch(resetUtilsStateAction());
  };

  const sendingChat = () => {
    if (message.trim() !== "" || imageChat) {
      const media =
        imageChat !== null
          ? {
              url: imageChat,
              type: "image",
            }
          : null;
      const data = {
        createdAt: new Date().getTime(),
        message: message,
        isRead: false,
        user: {
          id: users?.uid,
          name: users?.user?.name,
          type: users?.user?.type,
          photoURL: users?.user?.photoURL || null,
        },
        type: "message",
        attachments: media,
      };
      const notif = {
        isRead: true,
        userType: users?.user?.type,
      };

      dispatch(sendChatAction(props.chatId, data));
      dispatch(updateArticleAction(props.chatId, { chat: notif }));
      setMessage("");
      setImageChat(null);
      dispatch(resetUtilsStateAction());
    }
  };

  let isDone;
  let textDone;
  if (
    dataGetArticle?.status === "winner" ||
    dataGetArticle?.status === "published"
  ) {
    isDone = "winner";
    textDone = "Selamat anda terpilih di project ini";
  } else if (dataGetArticle?.status === "reject") {
    isDone = "reject";
    textDone = "Mohon maaf, anda belum terpilih di project ini";
  } else {
    isDone = null;
  }

  return (
    <>
      <div
        className={`discussion-wrapper ${
          users?.user?.type === "pm" ? "chat-pm" : ""
        }`}
      >
        <div className="discussion-box">
          <div className="discussion-message">
            {loadingChat ? (
              <>
                <ChatLoader />
                <ChatLoader />
                <ChatLoader />
              </>
            ) : (
              <>
                <div className="welcome">
                  <span>Selamat Datang</span>
                </div>
                {renderChats?.length < 1 ? (
                  <div className="date-wrapper">
                    <div className="date">Chat Masih Kosong</div>
                  </div>
                ) : (
                  <>
                    {renderChats?.map((chat, idx) => (
                      <div
                        className={`message-block ${
                          chat?.user?.id === users?.uid ? "right" : "left"
                        }`}
                        key={chat?._id}
                      >
                        <div className="msg-wrapper">
                          <div className="msg-box">
                            <div
                              className={`p-d-flex p-flex-column p-ai-${
                                chat?.user?.id === users?.uid ? "end" : "start"
                              }`}
                            >
                              {chat?.attachments !== null && (
                                <img
                                  onClick={() =>
                                    setDialogPreview({
                                      dialog: true,
                                      images: chat?.attachments?.url,
                                    })
                                  }
                                  src={chat?.attachments?.url}
                                  alt="pict"
                                />
                              )}
                              {chat?.message}
                              <div className="msg-time">
                                <span className="date">
                                  {dayjs(chat?.createdAt).format(
                                    "DD/MM/YYYY hh:mm"
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                    <div className="status-wrapper">
                      <div className={`status ${isDone}`}>{textDone}</div>
                    </div>
                  </>
                )}

                <div ref={bottomRef}></div>
              </>
            )}
            {imageChat !== null && (
              <div className="preview-images-wrap">
                <div className="preview-images-header">
                  <a className="close" onClick={() => hidePreview()}>
                    <span className="pi pi-times" />
                  </a>
                </div>
                <div className="preview-images">
                  <img src={imageChat} alt="" />
                </div>
              </div>
            )}
            <div className="form-chat">
              <InputText
                placeholder="Tulis pesan Anda ..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === "Enter" && sendingChat()}
                disabled={isDone !== null}
              />
              <div className="chat-attr">
                <ButtonUpload
                  setImageError={setImageError}
                  isDisable={isDone !== null}
                />
                <button
                  className="btn-send"
                  onClick={() => sendingChat()}
                  disabled={isDone !== null}
                >
                  <span className="icon-send" />
                </button>
              </div>
            </div>
          </div>
          <div />
        </div>
      </div>
      <DialogAlert
        type={"error"}
        visible={dialogError}
        closeDialog={() => setDialogError(false)}
        title={"Gagal pilih gambar"}
        text={imageError}
      />

      <DialogPreview
        visible={dialogPreview.dialog}
        closeDialog={() =>
          setDialogPreview({
            dialog: false,
            images: null,
          })
        }
        images={dialogPreview.images}
      />
    </>
  );
};

export default Discussion;
