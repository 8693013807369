import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";

import { InputText } from "primereact/inputtext";

import { getBriefProjectPmAction } from "../../../../../../redux/action/projectAction";

const FilterListProjectBrief = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { parsed } = props;

  const searchingHandler = useRef(
    _.debounce((search) => {
      const params = {
        q: search,
        category: parsed?.category || "",
        type: parsed?.type || "",
        orderField: parsed?.order || "",
        orderDir: parsed?.order === "createdAt" ? "desc" : "asc",
        page: 1,
        perpage: 10,
      };

      history.push(
        `/admin/pm/brief-release-client?search=${search}&type=${parsed?.type ||
          ""}&category=${parsed?.category || ""}&order=${parsed?.order ||
          ""}&page=1`
      );
      dispatch(getBriefProjectPmAction(params));
    }, 1000)
  ).current;

  return (
    <div className="p-d-flex p-jc-between p-mb-3">
      <div className="search-list-project">
        <div className="d-flex">
          <InputText
            id="search"
            aria-describedby="search-help"
            className="p-d-block"
            placeholder="Cari"
            onChange={(e) => searchingHandler(e.target.value)}
          />
        </div>
        <p>
          Cari berdasarkan<span> Nama Perusahaan</span>
        </p>
      </div>
    </div>
  );
};

export default FilterListProjectBrief;
